import { API } from './index';

// Need to send data with a key of data to backend as strong params looks for the key
export const fetchMany = (endpoint, params) => {
    return API.get(`/${endpoint}`, params);
};

export const fetch = (endpoint, id) => {
    return API.get(`/${endpoint}/${id}`);
};

export const update = (endpoint, id, attributes) => {
    return API.put(`/${endpoint}/${id}`, attributes);
}

export const create = (endpoint, attributes) => {
    return API.post(`/${endpoint}`, attributes);
}

export const destroy = (endpoint, id) => {
    return API.delete(`/${endpoint}/${id}`);
}

export const findOrCreate = (endpoint, attributes) => {
    return API.get(`/${endpoint}/`, {params: attributes}).then((response) => {
        if (response.data.length === 0) {
            return create(endpoint, attributes);
        }
        return {
            ...response,
            data: response.data[0],
        }
    });
}
