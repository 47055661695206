import React, { Component } from 'react';
import { connect } from 'react-redux';
import ACTList from './ACTList';
import * as actActions from '../../../modules/act/action';
import '../sat/HomeSATs.scss';
import {NewTopicButton, ShowDragHandleButton} from "../../helper/commonMethods";
import * as temporaryActions from "../../../modules/storeTemporary/action";
import { withRouter, Prompt } from "react-router-dom";
import * as userActActions from "../../../modules/userAct/action";
import * as userActTestProgressActions from "../../../modules/userActTestProgress/action";
import * as userProgressActions from "../../../modules/userProgress/action";
import * as assignmentActions from "../../../modules/assignment/action";
import * as dialogActions from '../../../modules/dialog/action';
import {FORM_DIALOG_BOX} from "../../dialogs/dialogs";

const mapStateToProps = state => {
    return ({
        acts: state.acts,
        account: state.account,
        actsUser: state.temporaryData.actsUser
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        getCurrentUserActs: () => dispatch(actActions.getCurrentUserActs( { isPublish: true })),
        createACT: (attributes) => dispatch(actActions.createRealAct(attributes)),
        openDialog: (config, options) => dispatch(dialogActions.open(config, options)),
        getUserActs: () => dispatch(userActActions.getUserActs()),
        getUserActScores: () => dispatch(userActActions.getAllActsScore()),
        getUserSatTestScores: () => dispatch(userActTestProgressActions.getUserAllActTestScores()),
        getAllAssignments: () => dispatch(assignmentActions.getAllAssignments()),
        getUserActsProgress: () => dispatch(userProgressActions.getUserActsProgress()),
        setActsUserData: (acts) => dispatch(temporaryActions.setActsUserData(acts)),
        clearActsUserData: () => dispatch(temporaryActions.clearActsUserData()),
        updateUserActsPositions: (acts) => dispatch(actActions.updateUserActsPositions(acts))
    })
}

class HomeACTs extends Component {

    constructor(props) {
        super(props)
        this.state = {
            acts: [],
            showDragHandle: false,
            loading: false
        }
    }

    async componentDidMount() {
        window.scrollTo(0,0);
        const {
            getCurrentUserActs, getUserActs, getUserActScores, clearActsUserData, getUserActsProgress, getUserSatTestScores,
            getAllAssignments
        } = this.props;
        await clearActsUserData();
        await getCurrentUserActs();
        await getUserActs();
        await getUserActScores();
        await getUserSatTestScores();
        await getUserActsProgress();
        await getAllAssignments();
    }

    componentDidUpdate(props) {
        const { acts, actsUser} = this.props;
        if (actsUser.length > 0 && actsUser !== props.actsUser) {
            this.setState({ acts: actsUser });
        } else if (acts !== props.acts) {
            this.setState({ acts });
        }
    }

    setReorderData = async (actsUser) =>{
        const { acts, updateUserActsPositions, clearActsUserData } = this.props;
        await this.setState({loading: true});
        if(actsUser !== acts){
            await updateUserActsPositions({acts: actsUser});
        }
        const { showDragHandle } = this.state;
        if(showDragHandle){
            window.removeEventListener("beforeunload", this.handleOnBeforeUnload)
        } else {
            window.addEventListener("beforeunload", this.handleOnBeforeUnload)
        }
        await clearActsUserData();
        await this.setState({showDragHandle: !showDragHandle, loading: false});
    }

    handleOnBeforeUnload = e => {
        e.preventDefault()
        e.returnValue = "";
    }

    createNewAct = () =>{
        const { createACT, openDialog } = this.props;
        const numberInput = {type: "number", pattern: /^[0-9]?$|^[1-2][0-9]?$|^[3][0-6]$/}
        openDialog(FORM_DIALOG_BOX, {
            title: ()=> "Create New ACT",
            options: {
                "Title": {type: "text", name: "title", value: "", pattern: /^\S.*/},
                "English": { ...numberInput, name: "english", value: "0", group: "group1"},
                "Math": { ...numberInput, name: "math",  value: "0", group: "group1"},
                "Reading": { ...numberInput, name: "reading", value: "0", group: "group2"},
                "Science": { ...numberInput, name: "science", value: "0", group: "group2"}
            },
            createAction: (params) => createACT(this.parseParams(params))
        });
    }

    parseParams = ({title, english, math, reading, science}) =>{
        return({title, marks: {
                english: parseInt(english), math: parseInt(math),
                reading: parseInt(reading), science: parseInt(science)
        }});
    }


    render() {
        const { acts, showDragHandle, loading } = this.state;
        const { setActsUserData, account } = this.props;
        return (
            <div className="row">
                <Prompt when={showDragHandle || loading}
                        message="Are you sure you want to leave Page without submit new order?"/>
                <div className="content-wrapper-before gradient-45deg-indigo-purple"/>
                <div className="col s12">
                    <div className="container">
                        <div className="dash_top_section">
                            <div className="dash_header d-flex flex-wrap align-items-center mb-2">
                                <h2 className="dash_title mr-auto w-auto my-2">ACTs</h2>
                                <NewTopicButton disabled={loading || showDragHandle}
                                                show={!account.tutor_login}
                                                topicType={'ACT'}
                                                onCLick={()=> this.createNewAct()} />
                                <ShowDragHandleButton value={showDragHandle}
                                                      disabled={loading}
                                                      type={'ACT'}
                                                      show={!account.tutor_login && acts.length > 1}
                                                      onCLick={()=> this.setReorderData(acts)} />
                            </div>
                            <div className="row vertical-modern-dashboard">
                                <div className="col s12 satAct_content_sec">
                                    <ACTList acts={acts}
                                             loading={loading}
                                             showDragHandle={showDragHandle}
                                             updateUserActsPositions={setActsUserData}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeACTs));
