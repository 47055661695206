import React, { Component } from 'react';
import BucketProblemSets from '../../bucket/BucketProblemSets';
import { connect } from 'react-redux'
import * as problemSetActions from '../../../modules/problemSet/action';
import * as dialogActions from '../../../modules/dialog/action';
import * as animationActions from '../../../modules/animation/action';
import { PROBLEM_SETS_DIALOG_BOX } from '../dialogs';
import SVG from "../../SVG";

class CreateBucketProblemSetModal extends Component {

	state = { loading: false };

	createProblemSet = async (problemSets) => {
		const {
			problemSetsList, createMultipleProblemSet, subSubtopic, openDialog, startAnimation, endAnimation,
			updateAfterSequencePublishUnpublish
		} = this.props
		const length = problemSetsList?.length ?? 0;
		let problemSetData = [];
		problemSets.map((problemSet, index) => {
			const problemData = {};
			problemData.position = [{ subsubtopic_id: subSubtopic._id, value: (length + index) }];
			problemData.subsubtopic_id = [subSubtopic._id];
			problemData.title = problemSet.title;
			problemData.problem_set_id = problemSet._id;
			problemData.notes = problemSet.notes;
			problemSetData.push(problemData);
		});

		await startAnimation();
		await createMultipleProblemSet({problem_sets: problemSetData})
		openDialog(PROBLEM_SETS_DIALOG_BOX, {
			subSubtopic,
			problemSetsList,
			updateAfterSequencePublishUnpublish: updateAfterSequencePublishUnpublish
		})
		await endAnimation();
	}

	createSatProblemSetData = async (problemSets) => {
		const {
			problemSetsList, createMultipleProblemSet, question, startAnimation, endAnimation,
			updateAfterPublishUnpublish
		} = this.props
		const length = problemSetsList?.length ?? 0;
		let problemSetData = [];
		problemSets.map((problemSet, index) => {
			const problemData = {};
			problemData.sat_position = [{ sat_question_id: question.id, value: (length + index) }];
			problemData.sat_question_id = [question.id];
			problemData.title = problemSet.title;
			problemData.problem_set_id = problemSet._id;
			problemData.notes = problemSet.notes;
			problemSetData.push(problemData);
		});
		await startAnimation();
		await createMultipleProblemSet({problem_sets: problemSetData})
		await updateAfterPublishUnpublish(question, true);
		await endAnimation();
	}

	createActProblemSetData = async (problemSets) => {
		const {
			problemSetsList, createMultipleProblemSet, question, startAnimation, endAnimation,
			updateAfterPublishUnpublish
		} = this.props
		const length = problemSetsList?.length ?? 0;
		const problemSetData = [];
		problemSets.map((problemSet, index) => {
			const problemData = {};
			problemData.act_position = [{ act_question_id: question.id, value: (length + index) }];
			problemData.act_question_id = [question.id];
			problemData.title = problemSet.title;
			problemData.problem_set_id = problemSet._id;
			problemData.notes = problemSet.notes;
			problemSetData.push(problemData);
		});

		await startAnimation();
		await createMultipleProblemSet({problem_sets: problemSetData})
		await updateAfterPublishUnpublish(question, true);
		await endAnimation()
	}

	createProblemSetData = async (problemSets) => {
		const { typeOf } = this.props;
		if (typeOf && typeOf === "SAT") {
			await this.createSatProblemSetData(problemSets);
		} else if(typeOf && typeOf === "ACT"){
			await this.createActProblemSetData(problemSets);
		}else {
			await this.createProblemSet(problemSets);
		}
	}

	render() {
		const { bucketProblemSet, loading } = this.props;
		return (
			<>
				{ loading &&
					<div className="loading-overlay">
						<SVG name="spin" />
					</div>
				}
				<BucketProblemSets bucketProblemSet={bucketProblemSet}
								   loading={loading}
								   createProblemSetData={this.createProblemSetData}/>
			</>
		);
	}
}

const mapStateToProps = state => {
	return ({
		loading: state.animation,
		bucketProblemSet: state.bucket.problemSet,
	})
}
const mapDispatchToProps = dispatch => {
	return ({
		openDialog: (config, options) => dispatch(dialogActions.open(config, options)),
		createMultipleProblemSet: (params) => dispatch(problemSetActions.createMultipleProblemSets(params)),
		getProblemSets: (params) => dispatch(problemSetActions.getAllProblemSets(params)),
		startAnimation: () => dispatch(animationActions.start()),
		endAnimation: () => dispatch(animationActions.end()),
	})
}

export const CreateBucketProblemSetDialog =  connect(mapStateToProps, mapDispatchToProps)(CreateBucketProblemSetModal);

