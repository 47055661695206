import React from 'react';
import {Route, Redirect} from 'react-router-dom';

function UnauthenticatedRoute ({component: Component, account, ...rest}) {
  return (
      <Route
        {...rest}
        render={(props) => account.loggedIn
          ?
            <Redirect to={{pathname: '/home', state: {from: props.location}}} />
            :
            <Component {...props} />}
      />
  )
}

export { UnauthenticatedRoute };
