import * as dbUtil from "../../api/dbUtil";
import {
    RECEIVE_ALL_USER_ACT_SCORES,
    RECEIVE_ALL_USER_ACTS,
    RECEIVE_USER_ACT,
    RECEIVE_USER_ACT_SCORE
} from "../constant";
import { checkSessionExpired } from "..";

const receiveAllUserActs = (data) => ({ type: RECEIVE_ALL_USER_ACTS, data });
const receiveUserAct = (data) => ({ type: RECEIVE_USER_ACT, data });
const receiveAllUserActsScore = (data) => ({ type: RECEIVE_ALL_USER_ACT_SCORES, data });
const receiveUserActScore = (data) => ({ type: RECEIVE_USER_ACT_SCORE, data });

export const getUserActs = (attributes) => {
    return (dispatch) => {
        return dbUtil.fetchMany('user_acts', attributes).then(({data}) => {
            dispatch(receiveAllUserActs(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const createUserAct = (attributes) => {
    return (dispatch) => {
        return dbUtil.create('user_acts', attributes).then(({data}) => {
            dispatch(receiveUserAct(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getActScore = (attributes) => {
    return (dispatch) => {
        return dbUtil.fetchMany('user_acts/get_act_score', attributes).then(({data}) => {
            dispatch(receiveUserActScore(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getAllActsScore = (attributes) => {
    return (dispatch) => {
        return dbUtil.fetchMany('user_acts/get_all_act_scores', attributes).then(({data}) => {
            dispatch(receiveAllUserActsScore(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}
