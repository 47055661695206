import {
    RECEIVE_ALL_ACTS,
    RECEIVE_ACT,
    UPDATE_ACT,
    REMOVE_ACT,
    PUBLISH_ACT,
    RECEIVE_ALL_PUBLISH_ACTS,
    RECEIVE_UPDATE_ACT_SCORE,
    RECEIVE_ACT_BUBBLE_SHEET_ANSWER,
    UPDATE_USER_ACT_POSITION
} from '../constant';

// Not considering creation of new test
export const actReducer = (state = [], action) => {
  let newState;
  switch (action.type) {
      case RECEIVE_ALL_ACTS:
          return Object.assign([], state, action.data)
      case RECEIVE_ALL_PUBLISH_ACTS:
          return action.data
      case RECEIVE_ACT:
          for (let i = 0; i < state.length; i++) {
              if (action.data.id === state[i].id) {
                  newState = state.map(obj => (action.data.id === obj.id) ? action.data : obj);
                  return newState;
              }
          }
          return Object.assign([], state, [...state, action.data])
      case UPDATE_ACT:
      case PUBLISH_ACT:
      case RECEIVE_UPDATE_ACT_SCORE:
      case RECEIVE_ACT_BUBBLE_SHEET_ANSWER:
          newState = state.map(obj => (action.data.id === obj.id) ? action.data : obj);
          return newState;
      case REMOVE_ACT:
          newState = state.filter(obj => (action.data.id !== obj.id));
          return newState;
      case UPDATE_USER_ACT_POSITION:
          let data = action?.data ?? [];
          let stateData = {};
          state.map((act) => stateData[act.id] = act)
          newState = data.map((obj)=> stateData[obj.act_id]);
          return newState;
      default:
          return state;
  }
}
