import React, { Component } from 'react';
import { Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropsRoute from '../../PropsRoute';
import AdminSATs from './sat/AdminSATs';
import AdminTopics from './topics/AdminTopics'
import AdminSubtopicMain from './topics/topic/AdminSubtopicMain';
import AdminSubSubtopicMain from './topics/topic/AdminSubSubtopicMain'
import * as topicActions from '../../../modules/topic/action';
import * as subtopicActions from '../../../modules/subTopic/action';
import * as subSubTopicActions from '../../../modules/subSubTopic/action';
import * as satTopicActions from '../../../modules/satTopic/action';
import * as grammarTopicActions from '../../../modules/grammarTopic/action';
import * as readingTopicActions from '../../../modules/readingTopic/action';
import AdminProblemSetDetail from './topics/problemSets/AdminProblemSetDetail'
import './AdminContent.scss'
import AdminSatQuestionMain from './topics/topic/AdminSatQuestionMain';
import AdminUsers from './users/AdminUsers'
import AdminSatTestMain from './sat/AdminSatTestMain'
import Settings from './settings/Settings';
import AdminACTs from './act/AdminACTs';
import AdminActQuestionMain from './topics/topic/AdminActQuestionMain';
import AdminActTestMain from './act/AdminActTestMain';
import PageNotFound from "../../PageNotFound";
import ReportProblems from "./reportProblems/ReportedProblems";

const mapStateToProps = state => {
    return ({
        mathTopics: state.topics,
        satTopics: state.satTopics,
        grammarTopics: state.grammarTopics,
        readingTopics: state.readingTopics,
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        getSatTopics: () => dispatch(satTopicActions.getAllSatTopics()),
        createSatTopic: (params) => dispatch(satTopicActions.createSatTopic(params)),
        updateSatTopic: (id, attributes) => dispatch(satTopicActions.updateSatTopic(id, attributes)),
        deleteSatTopic: (id) => dispatch(satTopicActions.deleteSatTopic(id)),
        getMathTopics: () => dispatch(topicActions.getAllTopics()),
        createMathTopic: (params) => dispatch(topicActions.createTopic(params)),
        updateMathTopic: (id, attributes) => dispatch(topicActions.updateTopic(id, attributes)),
        deleteMathTopic: (id) => dispatch(topicActions.deleteTopic(id)),
        getGrammarTopics: () => dispatch(grammarTopicActions.getAllGrammarTopics()),
        createGrammarTopic: (params) => dispatch(grammarTopicActions.createGrammarTopic(params)),
        updateGrammarTopic: (id, attributes) => dispatch(grammarTopicActions.updateGrammarTopic(id, attributes)),
        deleteGrammarTopic: (id) => dispatch(grammarTopicActions.deleteGrammarTopic(id)),
        getReadingTopics: () => dispatch(readingTopicActions.getAllReadingTopics()),
        createReadingTopic: (params) => dispatch(readingTopicActions.createReadingTopic(params)),
        updateReadingTopic: (id, attributes) => dispatch(readingTopicActions.updateReadingTopic(id, attributes)),
        deleteReadingTopic: (id) => dispatch(readingTopicActions.deleteReadingTopic(id)),
        getSubtopics: (params) => dispatch(subtopicActions.getAllSubTopics(params)),
        createSubtopic: (params) => dispatch(subtopicActions.createSubTopics(params)),
        updateSubtopic: (id, attributes) => dispatch(subtopicActions.updateSubTopics(id, attributes)),

        getSubSubtopics: (params) => dispatch(subSubTopicActions.getAllSubSubTopics(params)),
        createSubSubtopic: (params) => dispatch(subSubTopicActions.createSubSubTopic(params)),
        updateSubSubtopic: (id, attributes) => dispatch(subSubTopicActions.updateSubSubTopic(id, attributes)),
        deleteSubSubtopic: (id) => dispatch(subSubTopicActions.deleteSubSubTopic(id)),
    })
}

class AdminContent extends Component {
    render() {
        const {
            match,
            mathTopics,
            getMathTopics,
            createMathTopic,
            updateMathTopic,
            deleteMathTopic,
            satTopics,
            getSatTopics,
            createSatTopic,
            updateSatTopic,
            deleteSatTopic,
            grammarTopics,
            getGrammarTopics,
            createGrammarTopic,
            updateGrammarTopic,
            deleteGrammarTopic,
            readingTopics,
            getReadingTopics,
            createReadingTopic,
            updateReadingTopic,
            deleteReadingTopic,
            getSubtopics,
            createSubtopic,
            updateSubtopic,
            deleteSubtopic,
            getSubSubtopics,
            createSubSubtopic,
            updateSubSubtopic,
            deleteSubSubtopic
        } = this.props;
        // Most topics can flow through the AdminTopicsMain Component
        // Define specific methods for that topic type and pass in as props
        return (
            <div id="main" className="adminPage_content">
                <Switch>
                    <PropsRoute path={`${match.path}`} exact
                        component={AdminSATs} />

                    <PropsRoute path={`${match.path}/acts`} exact
                        component={AdminACTs} />

                    <PropsRoute path={`${match.path}/users`} exact
                        component={AdminUsers}
                        key={`/admin/users`}/>

                    <PropsRoute path={`${match.path}/settings`} exact
                        component={Settings}
                        key={`/admin/settings`}/>

                    <PropsRoute path={`${match.path}/reported_problems`}
                                exact
                                component={ReportProblems}
                                key={`/admin/reported_problems`}/>

                    <PropsRoute path={`/admin/not_found`} exact
                                component={PageNotFound}
                                topicType={`PageNotFound`}
                                key={`/admin/not_found`}/>

                    <PropsRoute path={`/admin/act/:actQuestionId`}
                        component={AdminActQuestionMain}
                        topicType={`actQuestionTopic`}
                        key={`/admin/sat/:actQuestionId`}/>

                    <PropsRoute path={`${match.path}/math-topics`} exact
                        component={AdminTopics}
                        getTopics={getMathTopics}
                        topics={mathTopics}
                        topicType={'Topic'}
                        title='Math'
                        createTopic={createMathTopic}
                        updateTopic={updateMathTopic}
                        deleteTopic={deleteMathTopic}
                        getSubtopics={getSubtopics}
                        createSubtopic={createSubtopic}
                        updateSubtopic={updateSubtopic}
                        deleteSubtopic={deleteSubtopic}
                        getSubSubtopics={getSubSubtopics}
                        createSubSubtopic={createSubSubtopic}
                        updateSubSubtopic={updateSubSubtopic}
                        deleteSubSubtopic={deleteSubSubtopic} />

                    <PropsRoute path={`${match.path}/sat-topics`} exact
                        component={AdminTopics}
                        getTopics={getSatTopics}
                        topics={satTopics}
                        topicType={'SatTopic'}
                        createTopic={createSatTopic}
                        updateTopic={updateSatTopic}
                        deleteTopic={deleteSatTopic}
                        getSubtopics={getSubtopics}
                        createSubtopic={createSubtopic}
                        updateSubtopic={updateSubtopic}
                        deleteSubtopic={deleteSubtopic}
                        getSubSubtopics={getSubSubtopics}
                        createSubSubtopic={createSubSubtopic}
                        updateSubSubtopic={updateSubSubtopic}
                        deleteSubSubtopic={deleteSubSubtopic} />

                    <PropsRoute path={`${match.path}/grammar-topics`} exact
                        component={AdminTopics}
                        getTopics={getGrammarTopics}
                        topics={grammarTopics}
                        topicType={'GrammarTopic'}
                        title='Grammar'
                        createTopic={createGrammarTopic}
                        updateTopic={updateGrammarTopic}
                        deleteTopic={deleteGrammarTopic}
                        getSubtopics={getSubtopics}
                        createSubtopic={createSubtopic}
                        updateSubtopic={updateSubtopic}
                        deleteSubtopic={deleteSubtopic}
                        getSubSubtopics={getSubSubtopics}
                        createSubSubtopic={createSubSubtopic}
                        updateSubSubtopic={updateSubSubtopic}
                        deleteSubSubtopic={deleteSubSubtopic} />

                    <PropsRoute path={`${match.path}/reading-topics`} exact
                        component={AdminTopics}
                        getTopics={getReadingTopics}
                        topics={readingTopics}
                        topicType={'ReadingTopic'}
                        title='Reading'
                        createTopic={createReadingTopic}
                        updateTopic={updateReadingTopic}
                        deleteTopic={deleteReadingTopic}
                        getSubtopics={getSubtopics}
                        createSubtopic={createSubtopic}
                        updateSubtopic={updateSubtopic}
                        deleteSubtopic={deleteSubtopic}
                        getSubSubtopics={getSubSubtopics}
                        createSubSubtopic={createSubSubtopic}
                        updateSubSubtopic={updateSubSubtopic}
                        deleteSubSubtopic={deleteSubSubtopic} />

                    <PropsRoute path={`/admin/subtopic/:subtopicId/:subsubtopicId`} exact
                                component={AdminSubSubtopicMain}
                                key={`/admin/subtopic/:subtopicId/:subsubtopicId`}/>

                    <PropsRoute path={`/admin/problemset/:problemset`} exact
                                component={AdminProblemSetDetail} />
                    {/* <PropsRoute path={`/admin/problemset`}
                    component={AdminProblemSet} /> */}

                    <PropsRoute path={`/admin/sat-test/:satId/real/:satType`} exact
                                component={AdminSatTestMain}
                                topicType={`AdminSatRealTest`}
                                key={`/admin/sat-test/:satId/real/:satType`}/>

                    <PropsRoute path={`/admin/sat-test/:satId/mock/:satType`} exact
                                component={AdminSatTestMain}
                                topicType={`AdminSatMockTest`}
                                key={`/admin/sat-test/:satId/mock/:satType`}/>

                    <PropsRoute path={`/admin/act-test/:actId/mock/:actType`} exact
                                component={AdminActTestMain}
                                topicType={`AdminActMockTest`}
                                key={`/admin/act-test/:actId/mock/:actType`}/>

                    <PropsRoute path={`/admin/act-test/:actId/real/:actType`} exact
                                component={AdminActTestMain}
                                topicType={`AdminActRealTest`}
                                key={`/admin/act-test/:actId/real/:actType`}/>

                    <PropsRoute path={`/admin/sat/:satQuestionId`} exact
                                component={AdminSatQuestionMain}
                                topicType={`satQuestionTopic`}
                                key={`/admin/sat/:satQuestionId`}/>

                    <PropsRoute path={`/admin/:subtopicId`} exact
                                component={AdminSubtopicMain}
                                topicType={`subTopic`}
                                key={`/admin/:subtopicId`}/>

                    <PropsRoute path={`/admin/*`}
                                component={PageNotFound}/>

                </Switch>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminContent));
