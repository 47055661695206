import {
  DELETED_RESTORE_RECORD,
  RECEIVE_DELETED_RECORDS,
  RESET_DELETED_RECORD,
  RESET_ALL_DELETED_RECORD
} from '../constant';

const defaultState = {problem_sets: [],acts: [],sats: [], subtopics: [],subsubtopics: [], topics: [],questions: []};
export const softDeletedReducer = (state = defaultState, action) => {
  let data
  const actionData = action.data?.data ?? [];
  switch (action.type) {
    case RECEIVE_DELETED_RECORDS:
      if (action.data.type === 'problem_set') {
        data = { ...state, problem_sets: [...state.problem_sets, ...actionData], total_problem_sets: action.data.count }
      } else if (action.data.type === 'act') {
        data = { ...state, acts: [...state.acts, ...actionData], total_acts: action.data.count }
      } else if (action.data.type === 'sat') {
        data = { ...state, sats: [...state.sats, ...actionData], total_sats: action.data.count }
      } else if (action.data.type === 'subtopic') {
        data = { ...state, subtopics: [...state.subtopics, ...actionData], total_subtopics: action.data.count }
      } else if (action.data.type === 'subsubtopic') {
        data = { ...state, subsubtopics: [...state.subsubtopics, ...actionData], total_subsubtopics: action.data.count }
      } else if (action.data.type === 'topic') {
        data = { ...state, topics: [...state.topics, ...actionData], total_topics: action.data.count }
      } else if (action.data.type === 'question') {
        data = { ...state, questions: [...state.questions, ...actionData], total_questions: action.data.count }
      }
      return data;
    case RESET_DELETED_RECORD:
      if (action.data.type === 'problem_set') {
        data = { ...state, problem_sets: [] }
      } else if (action.data.type === 'act') {
        data = { ...state, acts: [] }
      } else if (action.data.type === 'sat') {
        data = { ...state, sats: [] }
      } else if (action.data.type === 'subtopic') {
        data = { ...state, subtopics: [] }
      } else if (action.data.type === 'subsubtopic') {
        data = { ...state, subsubtopics: [] }
      } else if (action.data.type === 'topic') {
        data = { ...state, topics: [] }
      } else if (action.data.type === 'question') {
        data = { ...state, questions: [] }
      }
      return data;
    case RESET_ALL_DELETED_RECORD:
      return defaultState;
    case DELETED_RESTORE_RECORD:
      const record = action?.data?.data ?? {}
      if (action.data.type === 'problem_set') {
        let problem_sets = state.problem_sets.filter(obj => (record._id !== obj._id));
        data = { ...state, problem_sets, total_problem_sets: action.data.count }
      } else if (action.data.type === 'act') {
        let acts = state.acts.filter(obj => (record._id !== obj._id));
        data = { ...state, acts, total_acts: action.data.count }
      } else if (action.data.type === 'sat') {
        let sats = state.sats.filter(obj => (record._id !== obj._id));
        data = { ...state, sats, total_sats: action.data.count }
      } else if (action.data.type === 'subtopic') {
        let subtopics = state.subtopics.filter(obj => (record._id !== obj._id));
        data = { ...state, subtopics, total_subtopics: action.data.count }
      } else if (action.data.type === 'subsubtopic') {
        let subsubtopics = state.subsubtopics.filter(obj => (record._id !== obj._id));
        data = { ...state, subsubtopics, total_subsubtopics: action.data.count }
      } else if (action.data.type === 'topic') {
        let topics = state.topics.filter(obj => (record._id !== obj._id));
        data = { ...state, topics, total_topics: action.data.count }
      } else if (action.data.type === 'question') {
        let questions = state.questions.filter(obj => (record._id !== obj._id));
        data = { ...state, questions, total_questions: action.data.count }
      }
      return data;
    default:
      return state;
  }
}