import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as softDeleteActions from '../../../modules/softDeleted/action';
import * as animationActions from '../../../modules/animation/action';
import * as satActions from '../../../modules/sat/action';
import * as actActions from '../../../modules/act/action';
import * as topicActions from '../../../modules/topic/action';
import * as grammarTopicActions from '../../../modules/grammarTopic/action';
import * as readingTopicActions from '../../../modules/readingTopic/action';
import SVG from '../../SVG';
import ReadOnlyEditor from '../../editor/ReadOnlyEditor';
import * as alertActions from "../../../modules/alert/action";
import InfiniteScroll from 'react-infinite-scroll-component';
import {Loader} from "../../helper/commonMethods";
import "./RecycleBinDialog.scss";


class RecycleBinModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sat: false,
      act: false,
      topic: false,
      subtopic: false,
      subsubtopic: false,
      problem_set: false,
      question: false,
      loading: false
    }

    this.recordLimit = 20;
    this.loadData = this.loadData.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
    this.restoreRecord = this.restoreRecord.bind(this);

  }

  async componentDidMount() {
    await this.props.resetAllDeletedRecords();
    await this.loadData('sat');
  }

  componentWillUnmount() {
    this.props.resetAllDeletedRecords();
  }

  async loadData(type, limit = this.recordLimit){
    if(this.state[type]) return true;
    const { getDeletedRecords, startAnimation, endAnimation, softDeleted } = this.props;
    await startAnimation();
    let existingRecord = 0;
    if (type === 'problem_set') {
      existingRecord = softDeleted.problem_sets.length ?? 0;
    } else if (type === 'act') {
      existingRecord = softDeleted.acts.length ?? 0;
    } else if (type === 'sat') {
      existingRecord = softDeleted.sats.length ?? 0;
    } else if (type === 'subtopic') {
      existingRecord = softDeleted.subtopics.length ?? 0;
    } else if (type === 'subsubtopic') {
      existingRecord = softDeleted.subsubtopics.length ?? 0;
    } else if (type === 'topic') {
      existingRecord = softDeleted.topics.length ?? 0;
    } else if (type === 'question') {
      existingRecord = softDeleted.questions.length ?? 0;
    }

    await getDeletedRecords({ type , existing_record: existingRecord, limit: limit});
    await this.setState({[type]: true});
    await endAnimation()
  }

  flashMessage = (payload, timeout = 5000) => {
    const {alertPush, alertDelete} = this.props;
    alertPush(payload);
    setTimeout(() => alertDelete(),timeout);
  }

  async deleteRecord(id, type, title="") {
    const { deleteRecord, startAnimation, resetDeletedRecords, endAnimation } = this.props;
    let res = window.confirm("Are you sure you want to delete this permanently?")
    if (res) {
      startAnimation();
      this.setState({loading: true});
      await deleteRecord({ id: id, type: type });
      let topicType = '';
      if(type === 'sat'){
        topicType = '(Sat) ';
        await resetDeletedRecords({type: 'question'});
      } else if(type === 'act'){
        topicType = '(Act) ';
        await resetDeletedRecords({type: 'question'});
      } else if(type === 'topic'){
        topicType = '(Topic) ';
        await resetDeletedRecords({type: 'question'});
        await resetDeletedRecords({type: 'subtopic'});
        await resetDeletedRecords({type: 'subsubtopic'});
        this.setState({ subtopic: false, subsubtopic: false });
        // Reload Subtopic and SubSubTopic data after topic deleted
      } else if(type === 'subtopic'){
        topicType = '(Sub Topic) ';
        await resetDeletedRecords({type: 'question'});
        await resetDeletedRecords({type: 'subsubtopic'});
        this.setState({ subsubtopic: false });
        // Reload SubSubTopic data after Sub-topic deleted
      } else if(type === 'subsubtopic'){
        topicType = '(Sub Sub Topic) ';
        await resetDeletedRecords({type: 'question'});
      } else if(type === 'problem_set'){
        topicType = '(Problem Set) ';
        await resetDeletedRecords({type: 'question'});
      } else if(type === 'question'){
        topicType = '(Question) ';
      }
      this.flashMessage({message: `${topicType}'${title}' permanently deleted`, type: 'success'});
      this.setState({ problem_set: false, question: false, loading: false });
      endAnimation();
    }
  }

  async restoreRecord(id, type, title) {
    const {
      startAnimation, endAnimation, restoreRecord, getSATs, getACTs, getMathTopics, getGrammarTopics, getReadingTopics
    } = this.props
    let res = window.confirm(`Are you sure you want to restore this ${type}?`)
    if (res) {
      await startAnimation();
      const restoreData = await restoreRecord({ id: id, type: type });
      const restorePosition = restoreData?.restore_position;
      let topicType = '';
      if(type === 'sat'){
        topicType = '(Sat) ';
        await getSATs();
        // Reload SAT's data after topic restored
      } else if(type === 'act'){
        topicType = '(Act) ';
        await getACTs();
        // Reload ACT's data after topic restored
      } else if(type === 'topic'){
        topicType = '(Topic) ';
        await getMathTopics();
        await getGrammarTopics();
        await getReadingTopics();
        // Reload All Math Grammar And Reading Topic data after topic restored
      } else if(type === 'subtopic'){
        topicType = '(Sub Topic) ';
      } else if(type === 'subsubtopic'){
        topicType = '(Sub Sub Topic) ';
      } else if(type === 'problem_set'){
        topicType = '(Problem Set) ';
      } else if(type === 'question'){
        topicType = '(Question) ';
      }
      this.setState({ loading: false });
      this.flashMessage({message: `${topicType}${title ? `'${title}'` : ''} restored ${restorePosition ? `at position ${restorePosition}` : ""}`, type: 'success'});
      await endAnimation();
    }
  }

  renderList = (records, type) => {
    const { loading } = this.props;
    return records && records.map((record, i) => {
      return (
          <div className="list-row d-flex justify-content-between p-2" key={i}>
            {
              type === "question" ? (record.question ?
                <div style={{overflowY: "scroll", maxHeight: "200px", width: "65%"}}>
                  <ReadOnlyEditor content={record.question}/>
                </div> :
                record.question)
                :
                <div>{record.title}</div>
            }
            <div className="btn_grup">
              <button className="btn btn-danger mr-2" disabled={loading}
                      onClick={() => this.deleteRecord(record._id, type,record.title)}>
                Delete
              </button>
              <button className="btn btn-success mr-1" disabled={loading}
                      onClick={() => this.restoreRecord(record._id, type,record.title)}>
                Restore
              </button>
            </div>
          </div>
      )
    })
  }
  renderLoading() {
    const { loading } = this.props;
    if (loading) {
      return (
        <div className="loading-overlay">
          <SVG name="spin" />
        </div>
      )
    }

    return null;
  }

  loadNext = async (type) =>{
    await this.setState({[type]: false});
    await this.loadData(type)
  }

  render() {
    const { softDeleted, loading: loader } = this.props;
    const { loading } = this.state;
    
    return (
      <div className="preview-question--inner adjustUlInDialogExisting">
        <div className="">
          <ul className="nav nav-tabs" role="tablist">
            <li className="nav-item">
              <a className="nav-link active " data-toggle="tab" href="#SATs"
                 onClick={()=> this.loadData('sat')}>SATs</a>
            </li>
            <li className="nav-item">
              <a className="nav-link " data-toggle="tab" href="#ACTs"
                 onClick={()=> this.loadData('act')}>ACTs</a>
            </li>
            <li className="nav-item">
              <a className="nav-link " data-toggle="tab" href="#topics"
                 onClick={()=> this.loadData('topic')}>Topics</a>
            </li>
            <li className="nav-item">
              <a className="nav-link " data-toggle="tab" href="#subtopics"
                 onClick={()=> this.loadData('subtopic')}>Subtopics</a>
            </li>
            <li className="nav-item">
              <a className="nav-link " data-toggle="tab" href="#subsubtopics"
                 onClick={()=> this.loadData('subsubtopic')}>Subsubtopics</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#problemSets"
                 onClick={()=> this.loadData('problem_set')}>Problem Sets</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#questions"
                 onClick={()=> this.loadData('question')}>Questions</a>
            </li>
          </ul>
          <div className="tab-content">
            <Loader loading={loader} />
            <div id="SATs" className="container tab-pane active">
              <div className="card-body management-list pt-0 pl-1 pr-1">
                <RenderList loading={loading}
                            deleteRecord={this.deleteRecord}
                            restoreRecord={this.restoreRecord}
                            loadNext={this.loadNext}
                            records={softDeleted?.sats}
                            totalRecords={softDeleted?.total_sats}
                            type={'sat'}/>
              </div>
            </div>
            <div id="ACTs" className="container tab-pane">
              <div className="card-body management-list pt-0 pl-1 pr-1">
                <RenderList loading={loading}
                            deleteRecord={this.deleteRecord}
                            restoreRecord={this.restoreRecord}
                            loadNext={this.loadNext}
                            records={softDeleted?.acts}
                            totalRecords={softDeleted?.total_acts}
                            type={'act'}/>
              </div>
            </div>
            <div id="topics" className="container tab-pane">
              <div className="card-body management-list pt-0 pl-1 pr-1">
                <RenderList loading={loading}
                            deleteRecord={this.deleteRecord}
                            restoreRecord={this.restoreRecord}
                            loadNext={this.loadNext}
                            records={softDeleted?.topics}
                            totalRecords={softDeleted?.total_topics}
                            type={'topic'}/>
              </div>
            </div>
            <div id="subtopics" className="container tab-pane">
              <div className="card-body management-list pt-0 pl-1 pr-1">
                <RenderList loading={loading}
                            deleteRecord={this.deleteRecord}
                            restoreRecord={this.restoreRecord}
                            loadNext={this.loadNext}
                            records={softDeleted?.subtopics}
                            totalRecords={softDeleted?.total_subtopics}
                            type={'subtopic'}/>
              </div>
            </div>
            <div id="subsubtopics" className="container tab-pane">
              <div className="card-body management-list pt-0 pl-1 pr-1">
                <RenderList loading={loading}
                            deleteRecord={this.deleteRecord}
                            restoreRecord={this.restoreRecord}
                            loadNext={this.loadNext}
                            records={softDeleted?.subsubtopics}
                            totalRecords={softDeleted?.total_subsubtopics}
                            type={'subsubtopic'}/>
              </div>
            </div>
            <div id="problemSets" className="container tab-pane">
              <div className="card-body management-list pt-0 pl-1 pr-1">
                <RenderList loading={loading}
                            deleteRecord={this.deleteRecord}
                            restoreRecord={this.restoreRecord}
                            loadNext={this.loadNext}
                            records={softDeleted?.problem_sets}
                            totalRecords={softDeleted?.total_problem_sets}
                            type={'problem_set'}/>
              </div>
            </div>
            <div id="questions" className="container tab-pane">
              <div className="card-body management-list pt-0 pl-1 pr-1">
                <RenderList loading={loading}
                            deleteRecord={this.deleteRecord}
                            restoreRecord={this.restoreRecord}
                            loadNext={this.loadNext}
                            records={softDeleted?.questions}
                            totalRecords={softDeleted?.total_questions}
                            type={'question'}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const RenderListData = ({loading, records, type, deleteRecord, restoreRecord, pageNo = 1}) => {
  if(records){
   return records.map((record, i) => {
      return (
          <div className="list-row d-flex justify-content-between p-2" key={i}>
            {
              type === "question" ? (record.question ?
                  <div style={{overflowY: "scroll", maxHeight: "200px", width: "65%"}}>
                    <ReadOnlyEditor content={record.question}/>
                  </div> : record.question)
                  :
                  <div>{record.title}</div>
            }
            <div className="btn_grup">
              <button className="btn btn-danger mr-2" disabled={loading}
                      onClick={() => deleteRecord(record._id, type,record.title)}>
                Delete
              </button>
              <button className="btn btn-success mr-1" disabled={loading}
                      onClick={() => restoreRecord(record._id, type,record.title)}>
                Restore
              </button>
            </div>
          </div>
      )
    });

  }

  return null;
}


const RenderList = ({loadNext, totalRecords = 0, loading, records, type, deleteRecord, restoreRecord}) =>{
  const dataLength = records?.length ?? 0;
  const hasMore = dataLength < totalRecords;
  return(
      <InfiniteScroll
          dataLength={dataLength} //This is important field to render the next data
          next={()=> loadNext(type)}
          className={'infinite-component'}
          hasMore={hasMore}
          loader={""}
          endMessage={""}
          height={400}
          scrollableTarget={"infinite-scroll-component"}
      >
        <RenderListData loading={loading}
                        records={records}
                        type={type}
                        deleteRecord={deleteRecord}
                        restoreRecord={restoreRecord}/>
      </InfiniteScroll>
  )
}
const mapStateToProps = (state) => ({ softDeleted: state.softDeleted, loading: state.animation, })

const mapDispatchToProps = (dispatch) => {
  return ({
    resetDeletedRecords: (data) => dispatch(softDeleteActions.resetDeletedRecords(data)),
    resetAllDeletedRecords: (data) => dispatch(softDeleteActions.resetAllDeletedRecords()),
    getDeletedRecords: (params) => dispatch(softDeleteActions.getDeletedRecords(params)),
    deleteRecord: (params) => dispatch(softDeleteActions.hardDelete(params)),
    restoreRecord: (params) => dispatch(softDeleteActions.restoreRecord(params)),
    startAnimation: () => dispatch(animationActions.start()),
    endAnimation: () => dispatch(animationActions.end()),
    getSATs: () => dispatch(satActions.getAllTestSats()),
    getACTs: () => dispatch(actActions.getAllTestActs()),
    getMathTopics: () => dispatch(topicActions.getAllTopics()),
    getGrammarTopics: () => dispatch(grammarTopicActions.getAllGrammarTopics()),
    getReadingTopics: () => dispatch(readingTopicActions.getAllReadingTopics()),
    alertPush: (payload) => dispatch(alertActions.alertPush(payload)),
    alertDelete: () => dispatch(alertActions.alertDelete())
  })
}
export const RecycleBinDialog = connect(mapStateToProps, mapDispatchToProps)(RecycleBinModal);
