import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as dialogActions from '../../../modules/dialog/action';
import ToolTipEditor from "../../editor/plugins/tooltip/ToolTipEditor";

class AddToolTipModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            content: (props.value || ""),
            showRemoveButton: (props.value || "").length > 0,
            text: props.text
        }
    }

    submit = async () =>{
        const { content } = this.state;
        const { confirmToolTip, closeDialog } = this.props;
        await confirmToolTip(content)
        await closeDialog();
    }

    remove = async () =>{
        const { removeToolTip, closeDialog } = this.props;
        await removeToolTip();
        await closeDialog();
    }

    render() {
        const { text, content, showRemoveButton } = this.state;
        return (
            <div className="admin-problemSet-popup">
                <div className="admin-problemSet-popup">
                    <div className="comment_section mt-2">
                        <p><b>Enter Tooltip Content for : {text}</b></p>
                        <div className="center-align">
                            <ToolTipEditor
                                content = {content}
                                updateEditorState = {(content) => this.setState({content})}
                            />
                        </div>
                    </div>
                    <div className="submit_section text-right">
                        <button type="submit" className="btn btn-primary"
                                disabled={content.length === 0} onClick={this.submit}>
                            Submit
                        </button>
                        {
                            showRemoveButton &&
                            <button type="submit" className="btn btn-danger ml-1" onClick={this.remove}>
                                Remove
                            </button>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return ({
        openDialog: (config, options) => dispatch(dialogActions.open(config, options)),
    })
}

export const AddToolTipDialog =  withRouter(connect(null, mapDispatchToProps)(AddToolTipModal));

