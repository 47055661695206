import {combineReducers} from "redux";
import {RECEIVE_ALL_SAT_MOCK_TEST_QUESTIONS, RECEIVE_ALL_SAT_REAL_TEST_QUESTIONS} from "../constant";

const mockQuestionsReducer = (state = [], action) => {
    switch (action.type) {
        case RECEIVE_ALL_SAT_MOCK_TEST_QUESTIONS:
            let setobj = Object.assign({}, state)
            if ((action.data).length > 0) {
                let sat_id = action.data[0].sat_id
                if (action.data[0].sat_type === "Reading") {
                    setobj[sat_id] = { ...setobj[sat_id], 'Reading': action.data }
                } else if (action.data[0].sat_type === "MathCalc") {
                    setobj[sat_id] = { ...setobj[sat_id], 'MathCalc': action.data }
                } else if (action.data[0].sat_type === "MathNoCalc") {
                    setobj[sat_id] = { ...setobj[sat_id], 'MathNoCalc': action.data }
                } else if (action.data[0].sat_type === "Writing") {
                    setobj[sat_id] = { ...setobj[sat_id], 'Writing': action.data }
                }
            }
            return setobj;
        default:
            return state;
    }
}

const realQuestionsReducer = (state = [], action) => {
    switch (action.type) {
        case RECEIVE_ALL_SAT_REAL_TEST_QUESTIONS:
            let setobj = Object.assign({}, state)
            if ((action.data).length > 0) {
                let sat_id = action.data[0].sat_id
                if (action.data[0].sat_type === "Reading") {
                    setobj[sat_id] = { ...setobj[sat_id], 'Reading': action.data }
                } else if (action.data[0].sat_type === "MathCalc") {
                    setobj[sat_id] = { ...setobj[sat_id], 'MathCalc': action.data }
                } else if (action.data[0].sat_type === "MathNoCalc") {
                    setobj[sat_id] = { ...setobj[sat_id], 'MathNoCalc': action.data }
                } else if (action.data[0].sat_type === "Writing") {
                    setobj[sat_id] = { ...setobj[sat_id], 'Writing': action.data }
                }
            }
            return setobj;
        default:
            return state;
    }
}


export const satTestQuestionReducer = combineReducers({
    mock: mockQuestionsReducer,
    real: realQuestionsReducer,
});
