import React, { Component } from 'react';
import { connect } from 'react-redux';
import { resetPassword } from '../../modules';
import "./Login.scss";
import { PasswordInput } from "../helper/commonMethods";
import AccountTemplate from "./AccountTemplate";
import { ResetPasswordButtonTitle, ResetPasswordTitle } from "./AccountConstant";
import * as animationActions from "../../modules/animation/action";

const mapStateToProps = state => {
    return ({
        loading: state.animation,
        resetPasswordData: state.account.resetPassword,
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        resetPassword: (account) => dispatch(resetPassword(account)),
        startAnimation: () => dispatch(animationActions.start()),
        endAnimation: () => dispatch(animationActions.end()),
    })
}

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        let token = ''
        if (props.match && props.match.params && props.match.params.token) {
            token = props.match.params.token
        }
        this.state = {
            password: "",
            confirmPassword: "",
            serviceMessage: '',
            token,
        }

        this.resetPassword = this.resetPassword.bind(this);
    }

    async resetPassword(e) {
        e.preventDefault();
        const { password, confirmPassword, token } = this.state;
        if (password.length > 7) {
            if (confirmPassword === password) {
                const { startAnimation, endAnimation, resetPassword, history } =  this.props;
                await startAnimation();
                const successMessage = await resetPassword({ password, confirmPassword, reset_token: token });
                if(successMessage){
                    history.push({ pathname: '/', successMessage: successMessage});
                }

                await endAnimation();
            } else {
                this.setState({ serviceMessage: 'New Password & Confirm Password should match.' })
            }
        } else {
            this.setState({ serviceMessage: 'New Password should be minimum 8 characters.' })
        }
    }

    handleChange(prop) {
        return ({ target }) => this.setState({ [prop]: target.value, serviceMessage: '' })
    }

    render() {
        const { password, confirmPassword, serviceMessage } = this.state;
        const { loading } = this.props;
        return (
            <AccountTemplate title={ResetPasswordTitle} haveAccount={true} serviceMessage={serviceMessage}
                             buttonTitle={ResetPasswordButtonTitle} loader={loading} onSubmit={this.resetPassword}>
                <PasswordInput
                    title="Password"
                    name="password"
                    value={password}
                    placeholder="Enter new password"
                    onChange={this.handleChange('password')}
                />
                <PasswordInput
                    title="Confirm Password"
                    name="confirmPassword"
                    value={confirmPassword}
                    placeholder="Enter Confirm password"
                    onChange={this.handleChange('confirmPassword')}
                />
            </AccountTemplate>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);