import React, {Component} from 'react';
import {connect} from 'react-redux';
import {validSelect} from '../../../../../utilities/selectUtil';
import AddAnswer from './AddAnswer';
import * as answerActions from '../../../../../modules/answer/action';
import * as questionActions from '../../../../../modules/question/action';
import * as problemSetActions from '../../../../../modules/problemSet/action';
import SortableAnswers from './SortableAnswers';

import * as problemSetAnswerActions from '../../../../../modules/problemSetAnswer/action';
import * as problemSetQuestionActions from '../../../../../modules/problemSetQuestion/action';
import * as alertActions from "../../../../../modules/alert/action";

class AdminAnswers extends Component {
    state = {
        newAnswer: {},
        satProblemSets: []
    }

    updateAnswerPositions = async (answers) => {
        const { updateAnswerPositions } = this.props;
        const updated = await updateAnswerPositions({answers});
        return updated;
    }

    async componentDidMount() {
        const { questions, getProblemSets, topicType } = this.props
        if (topicType === 'SatTopic') {
            await getProblemSets({ sattopic_id: questions[0].test_id })
        }
        if (topicType === 'SatQuestionTopic') {
            getProblemSets({ sat_question_id: questions[0].id })
        }
    }


    createAndAddAnswer = async (answerData,payload) => {
        const { createAnswer } = this.props;
        await createAnswer(answerData);
        this.flashMessage(payload);
    }

    flashMessage = (payload, timeout = 5000) => {
        const { alertPush, alertDelete } = this.props;
        alertPush(payload);
        setTimeout(() => alertDelete(),timeout);
    }

    resetNewAnswer = () => {
        this.setState({ newAnswer: {} })
    }

    renderAnswers() {
        const { answers, questions, infoActive, updatedAnswers, resetUpdatedAnswers,
            section, subtopicId, topicType, problemsets, satQuestions, question } = this.props;
        const { newAnswer } = this.state;
        if (answers.length > 0) {
            return (
                <SortableAnswers question={question}
                                 section={section}
                                 createAndAddAnswer={this.createAndAddAnswer}
                                 newAnswer={newAnswer}
                                 resetNewAnswer={this.resetNewAnswer}
                                 answers={answers}
                                 subtopicId={subtopicId}
                                 updateAnswerPositions={this.updateAnswerPositions}
                                 questions={questions}
                                 infoActive={infoActive}
                                 updatedAnswers={updatedAnswers}
                                 resetUpdatedAnswers={resetUpdatedAnswers}
                                 topicType={topicType}
                                 satProblemSets={problemsets[satQuestions.id]}/>
            )
        }
    }

    render() {
        const { questionId, answers, section, subtopicId, topicType, problemsets, satQuestions, question } = this.props;
        if (!validSelect(questionId)) return null;
        return (
            <div className="admin-answers">
                <AddAnswer question={question}
                           section={section}
                           questionId={questionId}
                           answers={answers}
                           subtopicId={subtopicId}
                           topicType={topicType}
                           createAndAddAnswer={this.createAndAddAnswer}
                           satProblemSets={problemsets[satQuestions.id]}/>
                {/* {question.is_free_response ? <h2>Answer</h2> : <h2>Answers</h2>} */}
                {this.renderAnswers()}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        questions: state.questions,
        problemsets: state.problemsets,
        satQuestions: state.satQuestions
    })
}
const mapDispatchToProps = dispatch => {
    return ({
        createAnswer: (params) => dispatch(answerActions.createAnswer(params)),
        updateAnswer: (id, attributes) => dispatch(answerActions.updateAnswer(id, attributes)),
        updateAnswerPositions: (answers) => dispatch(answerActions.updateAnswerPositions(answers)),
        getQuestion: (id) => dispatch(questionActions.getQuestion(id)),
        getSubtopicQuestion: (id) => dispatch(questionActions.get('question_subsubtopics', id)),

        createProblemSetAnswer: (params) => dispatch(problemSetAnswerActions.createProblemSetAnswer( params)),
        getProblemSetQuestion: (params) => dispatch(problemSetQuestionActions.getProblemSetQuestion(params)),
        getProblemSets: (params) => dispatch(problemSetActions.getAllProblemSets(params)),

        alertPush: (payload) => dispatch(alertActions.alertPush(payload)),
        alertDelete: () => dispatch(alertActions.alertDelete())
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminAnswers);
