import {combineReducers} from "redux";
import {
    GET_ALL_USER_PROBLEM_SETS_PROGRESS, GET_USER_ACTS_PROGRESS, GET_USER_PROBLEM_SET_PROGRESS, GET_USER_PROGRESS,
    GET_USER_SATS_PROGRESS, RESET_USER_PROBLEM_SET_PROGRESS, RESET_USER_PROGRESS, SAVE_USER_PROBLEM_SET_PROGRESS
} from "../constant";

const defaultState = [];
const progressReducer = (state = defaultState, action) => {
    switch (action.type) {
        case GET_USER_PROGRESS:
            return action.data;
        case RESET_USER_PROGRESS:
            return action.data;
        default:
            return state;
    }
}

const problemSetProgressReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SAVE_USER_PROBLEM_SET_PROGRESS:
            return action.data;
        case GET_USER_PROBLEM_SET_PROGRESS:
            return action.data;
        case RESET_USER_PROBLEM_SET_PROGRESS:
            return action.data;
        default:
            return state;
    }
}

const allProblemSetProgressReducer = (state = defaultState, action) => {
    switch (action.type) {
        case GET_ALL_USER_PROBLEM_SETS_PROGRESS:
            return action.data;
        default:
            return state;
    }
}

const satsProgressReducer = (state = defaultState, action) => {
    switch (action.type) {
        case GET_USER_SATS_PROGRESS:
            return action.data.data;
        default:
            return state;
    }
}

const actsProgressReducer = (state = defaultState, action) => {
    switch (action.type) {
        case GET_USER_ACTS_PROGRESS:
            return action.data.data;
        default:
            return state;
    }
}

export const userProgressReducer = combineReducers({
    progressData: progressReducer,
    problemSet: problemSetProgressReducer,
    allProblemSets: allProblemSetProgressReducer,
    sats: satsProgressReducer,
    acts: actsProgressReducer,
})
