import {API} from '../../api';
import {
    GET_ALL_USER_PROBLEM_SETS_PROGRESS, GET_USER_ACTS_PROGRESS,
    GET_USER_PROBLEM_SET_PROGRESS,
    GET_USER_PROGRESS, GET_USER_SATS_PROGRESS, RESET_USER_PROBLEM_SET_PROGRESS,
    RESET_USER_PROGRESS,
    SAVE_USER_PROBLEM_SET_PROGRESS,
    SAVE_USER_PROGRESS
} from "../constant";
import { checkSessionExpired } from "..";

const receiveUserProgress = (data) => ({type: SAVE_USER_PROGRESS, data});
const userProgress = (data) => ({type: GET_USER_PROGRESS, data});
const userProgressReset = (data) => ({type: RESET_USER_PROGRESS, data});
const receiveUserProblemSetProgress = (data) => ({type: SAVE_USER_PROBLEM_SET_PROGRESS, data});
const userProblemSetProgress = (data) => ({type: GET_USER_PROBLEM_SET_PROGRESS, data});
const userProblemSetProgressReset = (data) => ({type: RESET_USER_PROBLEM_SET_PROGRESS, data});
const getProgressOfAllUserProblemSets = (data) => ({type: GET_ALL_USER_PROBLEM_SETS_PROGRESS, data});
const receiveUserSatsProgress = (data) => ({type: GET_USER_SATS_PROGRESS, data});
const receiveUserActsProgress = (data) => ({type: GET_USER_ACTS_PROGRESS, data});

export const saveUserProgress = (attributes) => {
    return (dispatch) => {
        return API.post('/user_progresses/save_user_progress', attributes).then(({data}) => {
            dispatch(receiveUserProgress(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getUserProgress = (attributes) => {
    return (dispatch) => {
        return API.post('/user_progresses/get_user_progress', attributes).then(({data}) => {
            dispatch(userProgress(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const resetUserProgress = (attributes) => {
    return (dispatch) => {
        return API.post('/user_progresses/reset_user_progress', attributes).then(({data}) => {
            dispatch(userProgressReset(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const saveUserProblemSetProgress = (attributes) => {
    return (dispatch) => {
        return API.post('/user_progresses/save_user_problemset_progress', attributes).then(({data}) => {
            dispatch(receiveUserProblemSetProgress(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}


export const getUserProblemSetProgress = (attributes) => {
    return (dispatch) => {
        return API.post('/user_progresses/get_user_problemset_progress', attributes).then(({data}) => {
            dispatch(userProblemSetProgress(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const resetUserProblemSetProgress = (attributes) => {
    return (dispatch) => {
        return API.post('/user_progresses/reset_user_problemset_progress', attributes).then(({data}) => {
            dispatch(userProblemSetProgressReset(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getAllUserProblemSetsProgress = (attributes) => {
    return (dispatch) => {
        return API.post('/user_progresses/get_all_user_problemsets_progress', attributes).then(({data}) => {
            dispatch(getProgressOfAllUserProblemSets(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getUserSatsProgress = (attributes) => {
    return (dispatch) => {
        return API.post('/user_progresses/get_user_sats_progress', attributes).then(({data}) => {
            dispatch(receiveUserSatsProgress(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getUserActsProgress = (attributes) => {
    return (dispatch) => {
        return API.post('/user_progresses/get_user_acts_progress', attributes).then(({data}) => {
            dispatch(receiveUserActsProgress(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}
