import React, { Component } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import AdminQuestion from './AdminQuestion';
import './SortableQuestions.scss';
import { connect } from 'react-redux';

const DragHandle = SortableHandle(() =>
    <span className="sortable-handle px-2 mr-2 d-flex">::</span>
)

const SortableItem = SortableElement((propsData) => {
    const {
        question, createQuestionFrom, createFromQuestion, postDeleteUpdate, sortableAddQuestion, postQuestionUpdate,
        id, section, updateOnDelete, key, indexPosition, showMainContent, createMultipleBucketQuestion
    } = propsData;
    return (
        <li key={key} className={`sortable-question sort-index-${indexPosition}`} id={`Q${id}`}>
            <DragHandle />
            <AdminQuestion showMainContent={showMainContent}
                           updateOnDelete={updateOnDelete}
                           section={section}
                           id={id}
                           question={question}
                           createQuestionFrom={createQuestionFrom}
                           createFromQuestion={createFromQuestion}
                           postDeleteUpdate={postDeleteUpdate}
                           createMultipleBucketQuestion={createMultipleBucketQuestion}
                           sortableAddQuestion={sortableAddQuestion}
                           postQuestionUpdate={postQuestionUpdate}/>
        </li>
    )
})

const SortableList = SortableContainer((propsData) => {
    const {
        questions, createQuestionFrom, createFromQuestion, postDeleteUpdate, sortableAddQuestion, postQuestionUpdate,
        section, updateOnDelete, showMainContent, createMultipleBucketQuestion
    } = propsData;
    return (
        <ul className="sortable-questions-list pl-0 w-100">
            {questions.map((question, index) => {
                return (
                    <SortableItem
                        updateOnDelete={updateOnDelete}
                        section={section}
                        showMainContent={showMainContent[index]}
                        key={index}
                        index={index}
                        indexPosition={index}
                        question={question}
                        id={question.id}
                        createQuestionFrom={createQuestionFrom}
                        createFromQuestion={createFromQuestion}
                        createMultipleBucketQuestion={createMultipleBucketQuestion}
                        postDeleteUpdate={postDeleteUpdate}
                        sortableAddQuestion={sortableAddQuestion}
                        postQuestionUpdate={postQuestionUpdate} />
                )
            })}
        </ul>
    )
})

class SortableProblemSetQuestions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            questions: [],
            problemset_questions: [],
            showMainContent: [],
            nodeData: ''
        }

        this.updateBeforeSortStart = this.updateBeforeSortStart.bind(this);
        this.onSortStart = this.onSortStart.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);
        this.postDeleteUpdate = this.postDeleteUpdate.bind(this);
        this.postQuestionUpdate = this.postQuestionUpdate.bind(this);
        this.sortableAddQuestion = this.sortableAddQuestion.bind(this);
    }

    componentDidMount() {
        const { problemset_questions } = this.props;
        this.setState({ questions: problemset_questions })
    }

    componentDidUpdate(prevProps) {

        if (this.props.problemset_questions[0].problem_set_id !== prevProps.problemset_questions[0].problem_set_id) {
            this.setState({ questions: this.props.problemset_questions })
        }

        if (this.props.problemset_questions !== prevProps.problemset_questions) {
            this.setState({ questions: this.props.problemset_questions })
        }

        // // Set to length of 1 so these methods are not invoked on creation of first question which would result in duplicative render of first question
        // if (Object.entries(this.props.newQuestion).length !== 0 && this.props.problemset_questions.length > 1) {
        //     this.sortableAddQuestion(this.props.newQuestion);
        //     this.props.resetNewQuestion();
        // }

        // if (Object.entries(this.props.newQuestion).length !== 0 && this.props.problemset_questions.length === 1) {
        //     this.props.resetNewQuestion();
        // }
    }

    // React Sortable Method used during mouse events to sort the list
    // Added functionality to this method in order to handle the question addition, deletion, and manipulation
    // mouseEvent is a param automatically passed in by React Sortable when moving questions
    // => we add the param here so we are able to set questions dynamically
    // When sorting the list, local state questions are used.
    // When adding to the list, a new copy of the questions state is used (see f(x)sortableAddQuestion)
    async onSortEnd(options, mouseEvent, questions = this.state.questions) {
        const { updateQuestionPositions } = this.props;
        const { oldIndex, newIndex } = options;
        this.setState({ showMainContent: [] })
        var offsetTop = document.getElementsByClassName(`sort-index-${newIndex}`)[0].offsetTop;
        window.scrollTo(0, offsetTop);
        if(oldIndex === newIndex) return null;
        const updatedQuestions = arrayMove(questions, oldIndex, newIndex)
        const x = await updateQuestionPositions(updatedQuestions);
        this.setState({ questions: x })
    }

    // Remove deleted question from local state and update positions
    async postDeleteUpdate(question) {
        const { updateQuestionPositions } = this.props;
        const { questions } = this.state;
        const copy = Object.assign([], questions)
        copy.splice(question.position, 1);
        const updatedQuestions = await updateQuestionPositions(copy);
        this.setState({ questions: updatedQuestions })
    }

    updateQuestionsAfterDelete = async () => {
        const { updateQuestionPositions, problemset_questions } = this.props;
        const updatedQuestions = await updateQuestionPositions(problemset_questions);
        this.setState({ questions: updatedQuestions })
    }

    // Update internal list after an update
    postQuestionUpdate(question) {
        const { questions } = this.state;
        const copy = Object.assign([], questions);
        copy[question.position] = question;
        this.setState({ questions: copy })
    }

    // Helps add questions for duplication, creation under a question, and creation at end of the list
    sortableAddQuestion(question) {
        const { questions } = this.state;
        let newState = Object.assign([], questions);
        newState.push(question);
        // Do not sort if question is set to last position
        if (question.position === newState.length - 1) {
            this.setState({ questions: newState })
        } else {
            this.onSortEnd({ oldIndex: newState.length - 1, newIndex: question.position }, null, newState)
        }
    }

    updateBeforeSortStart = ({ index }) =>{
        const { questions } = this.state;
        let showMainContent = questions.map((x,y)=> y >= index);
        this.setState({showMainContent: showMainContent, nodeData: ''});
    }

    onSortStart = ({ index }) =>{
        const { questions } = this.state;
        let showMainContent = questions.map(() => true);
        this.setState({showMainContent, positionSet: false});
        this.setCoordinateWithNode(index);
    }

    setCoordinateWithNode = (index) =>{
        if(index >= 0){
            var parentOffsetTop = document.getElementsByClassName(`main-sortable-questions`)[0]?.parentNode?.offsetTop;
            var offsetTop = document.getElementsByClassName(`sort-index-${index}`)[0].offsetTop;
            const { event } = window;
            let height = (offsetTop + parentOffsetTop) - event.clientY;
            window.scrollTo(0, height);
        }
    }

    render() {
        const { createQuestionFrom, createFromQuestion, section, createMultipleBucketQuestion } = this.props;
        const { questions, showMainContent } = this.state;
        if (questions.length === 0) return null;

        return (
            <div className="sortable-questions main-sortable-questions">
                <SortableList useDragHandle
                              section={section}
                              questions={questions}
                              showMainContent={showMainContent}
                              updateBeforeSortStart={this.updateBeforeSortStart}
                              onSortStart={this.onSortStart}
                              onSortEnd={this.onSortEnd}
                              distance={1}
                              createQuestionFrom={createQuestionFrom}
                              createFromQuestion={createFromQuestion}
                              createMultipleBucketQuestion={createMultipleBucketQuestion}
                              postDeleteUpdate={this.postDeleteUpdate}
                              sortableAddQuestion={this.sortableAddQuestion}
                              postQuestionUpdate={this.postQuestionUpdate}
                              updateOnDelete={this.updateQuestionsAfterDelete}
                              helperClass='sortableHelper'
                              lockAxis="y"
                              useWindowAsScrollContainer={true} />
            </div>
        );
    }
}

const mapStateToProps = ({ problemset_questions }) => ({
    problemset_questions
});

export default connect(mapStateToProps, null)(SortableProblemSetQuestions);
