import React, {Component} from 'react';
import './AdminSATList.scss';
import SortableSAT from './SortableSAT'

class SATList extends Component {
    render() {
        const {
            sats, deleteSAT, updateSAT, updateSatsPositions, publishUnPublishSat, satTopicModal, satTopic,
            updateScore, updateBubbleAnswers, updateSatProgressType
        } = this.props;

        if(sats?.length > 0) {
            return (
                <SortableSAT updateScore={updateScore}
                             updateBubbleAnswers={updateBubbleAnswers}
                             satTopicModal={satTopicModal}
                             satTopic={satTopic}
                             sats={sats}
                             updateSatsPositions={updateSatsPositions}
                             updateSatProgressType={updateSatProgressType}
                             updateSAT={updateSAT}
                             deleteSAT={deleteSAT}
                             publishUnPublishSat={publishUnPublishSat}/>
            )
        }
        return null;
    }
}

export default SATList;
