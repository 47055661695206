import React, {Component} from 'react';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import AdminAnswer from './AdminAnswer';
import './SortableAnswers.scss';

const DragHandle = SortableHandle(() =>
    <span className="sortable-handle">::</span>
)

const SortableItem = SortableElement(({
    answer,
    postDeleteUpdate,
    postAnswerUpdate,
    sortableAddAnswer,
    setNewAnswers,
    createAndAddAnswer,
    section,
    subtopicId,
    satProblemSets,
    topicType,
    question
}) => {
    return (
        <li className="sortable-answer">
            {question.is_free_response ? '' : <DragHandle />}
            <AdminAnswer question={question}
                         answer={answer}
                         postDeleteUpdate={postDeleteUpdate}
                         subtopicId={subtopicId}
                         postAnswerUpdate={postAnswerUpdate}
                         sortableAddAnswer={sortableAddAnswer}
                         setNewAnswers={setNewAnswers}
                         createAndAddAnswer={createAndAddAnswer}
                         section={section}
                         satProblemSets={satProblemSets}
                         topicType={topicType}/>
        </li>
    )
})

const SortableList = SortableContainer(({
    answers,
    postDeleteUpdate,
    postAnswerUpdate,
    sortableAddAnswer,
    setNewAnswers,
    createAndAddAnswer,
    section,
    subtopicId,
    satProblemSets,
    topicType,
    question
}) => {
    return (
        <ul className="admin-answer-list">
            {answers.map((answer, index) => {
                return (
                    <SortableItem question={question}
                                  key={answer.id}
                                  index={index}
                                  answer={answer}
                                  id={answer.id}
                                  postDeleteUpdate={postDeleteUpdate}
                                  postAnswerUpdate={postAnswerUpdate}
                                  sortableAddAnswer={sortableAddAnswer}
                                  setNewAnswers={setNewAnswers}
                                  createAndAddAnswer={createAndAddAnswer}
                                  section={section}
                                  subtopicId={subtopicId}
                                  satProblemSets={satProblemSets}
                                  topicType={topicType}/>
                )
            })}
        </ul>
    )
})

class SortableAnswers extends Component {
    state = {
        answers: [],
    }

    componentDidMount() {
        const { answers } = this.props;
        this.setState({ answers })
    }

    componentDidUpdate(prevProps) {
        const { newAnswer, answers, resetNewAnswer, updatedAnswers, resetUpdatedAnswers } = this.props;

        if (this.props.answers !== prevProps.answers) {
            this.setState({ answers: this.props.answers })
        }
        // Subsequent answers added to question
        if (Object.entries(newAnswer).length !== 0 && answers.length > 1) {
            this.sortableAddAnswer(newAnswer);
            resetNewAnswer();
        }

        // First answer added to question
        if (Object.entries(newAnswer).length !== 0 && answers.length === 1) {
            resetNewAnswer();
        }

        // Update answers when question linkage no longer exists
        if (updatedAnswers) {
            this.setNewAnswers(updatedAnswers);
            resetUpdatedAnswers();
        }
    }

    setNewAnswers = (answers) => {
        this.setState({ answers })
    }

    onSortEnd = async (options) => {
        const { updateAnswerPositions } = this.props;
        const { answers } = this.state;
        const { oldIndex, newIndex } = options;
        if(oldIndex === newIndex) return null;
        const updatedAnswers = arrayMove(answers, oldIndex, newIndex)
        const x = await updateAnswerPositions(updatedAnswers);
        this.setState({ answers: x })
    }

    postDeleteUpdate = async (answer) => {
        const { updateAnswerPositions } = this.props;
        const { answers } = this.state;
        // const copy = Object.assign([], answers);
        const index = answers.findIndex(x => x.id === answer.id)
        if (index > -1)
            answers.splice(index, 1);
        const updatedAnswers = await updateAnswerPositions(answers);
        this.setState({ answers: updatedAnswers })
    }

    postAnswerUpdate = (answer) => {
        const { answers } = this.state;
        const copy = Object.assign([], answers);
        copy[answer.position] = answer;
        this.setState({ answers: copy })
    }

    sortableAddAnswer = (answer) => {
        const { answers } = this.state;
        let newState = Object.assign([], answers)
        newState.push(answer);
        if (answer.position === newState.length - 1) {
            this.setState({ answers: newState })
        } else {
            this.onSortEnd({ oldIndex: newState.length - 1, newIndex: answer.position }, null, newState)
        }
    }

    render() {
        const { answers } = this.state;
        const { createAndAddAnswer, section, subtopicId, satProblemSets, topicType, question } = this.props;
        if (answers.length === 0) return null;
        return (
            <div className="sortable-answers">
                <SortableList question={question}
                              section={section}
                              onSortEnd={this.onSortEnd}
                              answers={answers}
                              postDeleteUpdate={this.postDeleteUpdate}
                              postAnswerUpdate={this.postAnswerUpdate}
                              sortableAddAnswer={this.sortableAddAnswer}
                              setNewAnswers={this.setNewAnswers}
                              createAndAddAnswer={createAndAddAnswer}
                              distance={1}
                              subtopicId={subtopicId}
                              satProblemSets={satProblemSets}
                              topicType={topicType}
                              helperClass='sortableHelper'
                              lockAxis="y"
                              useWindowAsScrollContainer={true}
                              useDragHandle />
            </div>
        );
    }
}

export default (SortableAnswers);
