import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import 'katex/dist/katex.min.css'
import * as questionActions from '../../../../../modules/question/action';
import * as answerActions from '../../../../../modules/answer/action';
import * as dialogActions from '../../../../../modules/dialog/action';
import { CREATE_QUESTION_DIALOG_BOX } from '../../../../dialogs/dialogs'
import CreateQuestionForm from './CreateQuestionForm';
import './AddQuestion.scss';

const mapStateToProps = state => {
    return ({
        questions: state.questions,
        problemset_questions: state.problemset_questions
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        createQuestion: (attributes) => dispatch(questionActions.createQuestion(attributes)),
        createAnswer: (attributes) => dispatch(answerActions.createAnswer(attributes)),
        openDialog: (config, options) => dispatch(dialogActions.open(config, options))
    })
}

class AddQuestion extends Component {
    state = {
        question: "",
        ready: false,
        formActive: false,
        editorState: {},
        position: this.props.position
    }

    componentDidUpdate(prevProps) {
        const { formActive } = this.state;
        if (this.props.topicId !== prevProps.topicId) {
            if (formActive) {
                this.toggleForm();
            }
        }

        if (this.props.fromQuestion && !formActive) {
            this.toggleForm();
        }
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({ position: props.position })
    }

    handleChange = (prop) => {
        return ({ target }) => this.setState({ [prop]: target.value })
    }

    createBucketQuestion = async (question, topicId, answers) => {
        const { createQuestionFrom } = this.props;
        question.position = this.state.position
        createQuestionFrom(question, topicId, answers);
    }

    createMultipleBucketQuestion = async (questions, topicId, answers) => {
        const { createMultipleBucketQuestion } = this.props;
        await createMultipleBucketQuestion(questions, topicId, answers);
    }

    openModal = () => {
        const { openDialog, topicId, createQuestionFrom } = this.props;
        openDialog(CREATE_QUESTION_DIALOG_BOX, {
            topicId,
            createQuestionFrom,
            toggleForm: this.toggleForm,
            fromQuestion: false,
            clickFnc: this.createBucketQuestion,
            createMultiple: this.createMultipleBucketQuestion,
            fncType: "createQuestion",
        })
    }

    clearForm() {
        this.setState({ question: "" });
    }

    updateEditorState = (editorState) => {
        this.setState({ editorState })
    }


    toggleForm = () => {
        const { resetQuestionData } = this.props;
        const { formActive } = this.state;
        if (formActive) {
            this.setState({ formActive: false })
            resetQuestionData();
        } else {
            this.setState({ formActive: true })
        }
    }

    renderForm() {
        const { formActive } = this.state;
        const {
            topicId, questions, problemset_questions, fromQuestionPosition, fromQuestion, updateQuestionPositions,
            createAndAddQuestion, resetQuestionData, topicType, section, satType, actType
        } = this.props;

        if (formActive) {
            const questionData = topicType === 'ProblemSet' ? problemset_questions : questions
            return (
                <CreateQuestionForm satType={satType}
                                    actType={actType}
                                    section={section}
                                    topicType={topicType}
                                    topicId={topicId}
                                    toggleForm={this.toggleForm}
                                    questions={questionData}
                                    fromQuestion={fromQuestion}
                                    fromQuestionPosition={fromQuestionPosition}
                                    updateQuestionPositions={updateQuestionPositions}
                                    createAndAddQuestion={createAndAddQuestion}
                                    resetQuestionData={resetQuestionData} />
            )
        } else {
            return (
                <button onClick={this.openModal} className="btn btn-default btn-primary create_btn">
                    <span className="btnTxt">Create Question</span>
                    <span className="ico ico_plus"/>
                </button>
            );
        }
    }

    render() {
        return (
            <div className='d-flex justify-content-end'>
                {this.renderForm()}
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddQuestion))
