import React, { Component } from 'react';
import ReadOnlyEditor from '../editor/ReadOnlyEditor';

class BucketAnswer extends Component {
    render() {
        const { answer } = this.props;
        if(answer.is_free_response){
            return (
                <div>
                        <span className="d-inline-flex">
                            <div>{answer.free_response_answer}</div>
                            <ReadOnlyEditor content={answer.symbol} />
                        </span>
                    <span className="text-success ml-2"> (Free Response) </span>
                </div>
            );
        }
        return (
            <div>
                <ReadOnlyEditor content={answer.answer} />
            </div>
        );
    }
}

export default BucketAnswer;
