import {
    RECEIVE_USER_ALL_ACT_TEST_SCORES,
    RECEIVE_USER_ACT_TEST_PROGRESS,
    RECEIVE_USER_ACT_TEST_PROGRESSES,
    RECEIVE_USER_ACT_TEST_SCORE,
    RESET_USER_ACT_TEST_PROGRESS
} from "../constant";

import { combineReducers } from "redux";

const defaultState = [];

const userActTestProgressDataReducer = (state = defaultState, action) => {
    let newState = state;
    switch (action.type) {
        case RECEIVE_USER_ACT_TEST_PROGRESS:
            if(action.data){
                newState = filterProgressState(newState, [action.data]);
                return Object.assign([], newState, [...newState, action.data])
            }
            return state;
        case RECEIVE_USER_ACT_TEST_PROGRESSES:
            if(action.data){
                newState = filterProgressState(state, action.data);
                return Object.assign([], newState, [...newState, ...action.data])
            }
            return state;
        case RESET_USER_ACT_TEST_PROGRESS:
            if(action.data){
                return filterProgressState(state, [action.data]);
            }
            return state;
        default:
            return state;
    }
}

const userActTestProgressScoreReducer = (state = defaultState, action) => {
    let newState = state;
    switch (action.type) {
        case RECEIVE_USER_ACT_TEST_SCORE:
            if(action.data){
                newState = filterScoreState(state, action.data);
                return Object.assign([], newState, [...newState, ...action.data])
            }
            return state;
        case RECEIVE_USER_ALL_ACT_TEST_SCORES:
            return action.data;
        case RESET_USER_ACT_TEST_PROGRESS:
            if(action.data){
                return filterScoreState(state, [action.data]);
            }
            return state;
        default:
            return state;
    }
}

function filterProgressState(state, records){
    for (let i = 0; i < records.length; i++) {
        const actionData = records[i];
        state = state.filter(data => !(data.act_id === actionData.act_id && !!data.real_test === !!actionData.real_test && data.act_type === actionData.act_type));
    }
    return state;
}

function filterScoreState(state, records){
    for (let i = 0; i < records.length; i++) {
        const actionData = records[i];
        state = state.filter(data => !(data.act_id === actionData.act_id && !!data.real_test === !!actionData.real_test));
    }
    return state;
}

export const userActTestProgressReducer = combineReducers({
    data: userActTestProgressDataReducer,
    scores: userActTestProgressScoreReducer
})

