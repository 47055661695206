import * as dbUtil from '../../api/dbUtil';
import {API} from '../../api';
import {
    RECEIVE_DELETED_RECORDS,
    DELETED_RESTORE_RECORD,
    RESET_DELETED_RECORD,
    RESET_ALL_DELETED_RECORD
} from '../constant'
import { checkSessionExpired } from "..";

const receiveAll = (data) => ({type: RECEIVE_DELETED_RECORDS, data})
const restoreDelete = (data) => ({type: DELETED_RESTORE_RECORD, data})
export const resetDeletedRecords = (data) => ({type: RESET_DELETED_RECORD, data})
export const resetAllDeletedRecords = () => ({type: RESET_ALL_DELETED_RECORD})

export const getDeletedRecords = (attributes) => {
    return (dispatch) => {
        return dbUtil.fetchMany('soft_delete/deleted_records', attributes).then(({data}) => {
            dispatch(receiveAll(data))
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const hardDelete = (attributes) => {
    return (dispatch) => {
        return API.post('soft_delete/hard_delete', attributes).then(({data}) => {
                dispatch(restoreDelete(data));
                return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const restoreRecord = (attributes) => {
    return (dispatch) => {
        return API.post('soft_delete/restore_record', attributes).then(({data}) => {
                dispatch(restoreDelete(data));
                return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}