import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as problemSetActions from '../../../modules/problemSet/action';
import './ProblemSetDialog.scss';
import '../../admin/content/common/AddNewTopic.scss';
import { SAT_SECTION_DIALOG_BOX, PROBLEM_SETS_DIALOG_BOX, ACT_SECTION_DIALOG_BOX } from '../dialogs';
import * as dialogActions from '../../../modules/dialog/action';
import { toWords } from '../../../utilities/custom';
import {SetModalTopScroll} from "../../helper/commonMethods";
import * as alertActions from "../../../modules/alert/action";
import {MultipleProblemSetSelection} from "../../helper/problemSet";

class AddExistingProblemSetModal extends Component {

    constructor(props) {
        super(props);
        this.selectProblemSet = this.selectProblemSet.bind(this);
    }

    state = {
        title: "",
        topicTitle: "",
        show: false,
        showCreate: false,
        showDropdown: false,
        problemSetlist: [],
        allmathsTopic: [],
        selectedProblemSetIds: []
    }

    toggle = (el) => {
        this.setState({ [el]: !this.state[el] })
    }

    async componentDidMount() {
        const { subSubtopic, satTopic, question } = this.props;
        let title = satTopic && satTopic.title
        if (question && question.id) {
            title = question.title
        }
        this.setState({ headerTitle: subSubtopic ? subSubtopic.title : title })
        this.toggle('show');
        SetModalTopScroll();
    }


    handleChange(prop) {
        return ({ target }) => this.setState({ [prop]: target.value })
    }

    openSatProblemSetDialog = async () => {
        const { satTopic, updateAfterPublishUnpublish, openDialog, sat, sats } = this.props;
        let newSat = sat, setTitle = sat?.title;
        if (!sat) {
            newSat = sats.find(dd => dd.id === satTopic.sat_id);
            setTitle = newSat.title;
        }
        openDialog(SAT_SECTION_DIALOG_BOX, {
            sat: newSat,
            question: satTopic,
            title: `${setTitle} ${toWords(satTopic.type)} Question #${satTopic.position + 1}`,
            topicType: 'SatQuestionTopic',
            updateAfterPublishUnpublish: (satTopic) => updateAfterPublishUnpublish(satTopic, true)
        })
    }

    openActProblemSetDialog = async () => { 
        const { actTopic, updateAfterPublishUnpublish, openDialog, act, acts } = this.props
        let newAct = act, setTitle = act?.title;
        if (!act) {
            newAct = acts.find(dd => dd.id === actTopic.act_id)
            setTitle = newAct.title
        }
        openDialog(ACT_SECTION_DIALOG_BOX, {
            act: newAct,
            question: actTopic,
            title: `${setTitle} ${toWords(actTopic.type)} Question #${actTopic.position + 1}`,
            topicType: 'ActQuestionTopic',
            updateAfterPublishUnpublish: (actTopic) => updateAfterPublishUnpublish(actTopic, true)
        })
    }

    openTopicProblemSetDialog = async () => {
        const { getProblemSets, openDialog, updateAfterSequencePublishUnpublish, mathTopic } = this.props
        const problemsets = await getProblemSets({ subsubtopic_id: mathTopic && mathTopic._id })
        openDialog(PROBLEM_SETS_DIALOG_BOX, {
            title: mathTopic.title,
            subSubtopic: mathTopic,
            problemsets: problemsets[mathTopic._id],
            updateAfterSequencePublishUnpublish: (a, b) => updateAfterSequencePublishUnpublish(a, b)
        })
    }

    selectProblemSet = (selected,remove) =>{
        let selectedProblemSetIds = this.state.selectedProblemSetIds;
        if(remove === true){
            selectedProblemSetIds = selectedProblemSetIds.filter(item => item !== selected._id);
        } else {
            selectedProblemSetIds.push(selected._id);
        }
        this.setState({selectedProblemSetIds});
    }

    getParams = () => {
        const {location: {pathname},  satTopic, mathTopic, actTopic }= this.props;
        if (pathname.includes("topic")) {
            return({ subsubtopic_id: mathTopic?._id })
        } else if (pathname.includes("acts")) {
            return({ act_question_id: actTopic?.id })
        } else {
            return({ sat_question_id: satTopic?.id })
        }
    }

    getDataHash = (props) =>{
        const {problemSet,position} = props;
        const { satTopic, location: {pathname}, mathTopic, actTopic } = this.props;
        let data = {};
        if (pathname.includes("topic")) {
            let subsubtopicIds = []
            if (problemSet.subsubtopic_id?.length > 0) {
                subsubtopicIds = Array.isArray(problemSet.subsubtopic_id) ? problemSet.subsubtopic_id : [problemSet.subsubtopic_id];
                problemSet.subsubtopic_id = subsubtopicIds
                subsubtopicIds.push(mathTopic._id)
            } else {
                subsubtopicIds = [mathTopic._id]
                problemSet.subsubtopic_id = []
            }
            data.seletedId = mathTopic._id
            if (Array.isArray(problemSet.position)) {
                data.position = problemSet.position
            } else {
                const filteredSubsubtopicIds = subsubtopicIds.filter((id) => id !== mathTopic._id)
                data.position = [...new Set(filteredSubsubtopicIds)].map(id => ({ subsubtopic_id: id, value: problemSet.position }))
            }
            data.position.push({ subsubtopic_id: mathTopic._id, value: position })
            data.subsubtopic_id = [...new Set(subsubtopicIds)]
        } else if (pathname.includes("acts")) {
            const actQuestionIds = this.getQuestionIds({question_id: actTopic.id,question_ids: problemSet?.act_question_id});
            data.seletedId = actTopic.id;
            if (Array.isArray(problemSet.act_position)) {
                data.act_position = problemSet.act_position;
            } else {
                const filteredActQuestionIds = actQuestionIds.filter((id) => id !== actTopic.id);
                data.act_position = [...new Set(filteredActQuestionIds)].map(id => ({ act_question_id: id, value: problemSet.sat_position }));
            }
            data.act_position.push({ act_question_id: actTopic.id, value: position });
            data.act_question_id = [...new Set(actQuestionIds)];
        } else {
            const satQuestionIds = this.getQuestionIds({question_id: satTopic.id,question_ids: problemSet?.sat_question_id});
            data.seletedId = satTopic.id;
            if (Array.isArray(problemSet.sat_position)) {
                data.sat_position = problemSet.sat_position;
            } else {
                const filteredSatQuestionIds = satQuestionIds.filter((id) => id !== satTopic.id);
                data.sat_position = [...new Set(filteredSatQuestionIds)].map(id => ({ sat_question_id: id, value: problemSet.sat_position }));
            }
            data.sat_position.push({ sat_question_id: satTopic.id, value: position });
            data.sat_question_id = [...new Set(satQuestionIds)];
        }
        data.title = problemSet.title
        return ({problemSet, data});
    }

    getQuestionIds = ({question_ids,question_id}) =>{
        if (question_ids?.length > 0) {
            return question_ids.concat([question_id])
        }
        return [question_id];
    }

    createExistingProblemSets = async () => {
        const { getProblemSets, linkExistingProblemSet, close, location: {pathname} } = this.props;
        const { selectedProblemSetIds } = this.state;
        const params = this.getParams();
        const problemSetsArr = await getProblemSets({ ...params });
        const position = problemSetsArr.result.length;
        const problemSets = this.getCurrentProblemSet();
        const selectedProblemSets = problemSets.filter((problemset) => selectedProblemSetIds.includes(problemset._id));
        let dataHash = selectedProblemSets.map((problemSet,index) => this.getDataHash({problemSet: problemSet,position: (position+index)}));
        dataHash.map(async ({problemSet, data}, index) => {
            await linkExistingProblemSet(problemSet._id, data);
            if(dataHash.length === index +1){
                if(pathname.includes("topic")){
                    await this.openTopicProblemSetDialog();
                } else if(pathname.includes("acts")){
                    await this.openActProblemSetDialog();
                } else {
                    await this.openSatProblemSetDialog();
                }
            }
        });
        this.setState({selectedProblemSetIds: []});
        await this.flashMessage({message: `Existing Problem Sets added`, type: "success"});
        close();
    }

    flashMessage = (payload, timeout = 5000) => {
        const {alertPush, alertDelete} = this.props;
        alertPush(payload);
        setTimeout(() => alertDelete(),timeout);
    }

    getCurrentProblemSet = () =>{
        const { problemsets, subSubtopic, satTopic, question } = this.props;
        return problemsets[question?.id ?? (subSubtopic ? subSubtopic._id : satTopic?.id)];
    }

    selectAllProblemSet = (problemSets) => {
        let selectedProblemSetIds = problemSets.map((pb)=> pb._id);
        this.setState({selectedProblemSetIds});
    }

    render() {
        const { headerTitle, selectedProblemSetIds } = this.state;
        const problemSets = this.getCurrentProblemSet();
        return (
            <div className="admin-problemSet-popup">
                <div className={"header-title-row"}>
                    <div className={"header-div"}>
                        <h1>{headerTitle}</h1>
                    </div>
                    {
                        problemSets?.length > 0 &&
                        <div className={"select-submit-button-div"}>
                            <button onClick={() => this.selectAllProblemSet(problemSets)}
                                    disabled={selectedProblemSetIds.length === problemSets?.length}
                                    className="btn btn-sm btn-primary">
                                Select All
                            </button>
                            <button onClick={() => this.createExistingProblemSets()}
                                    disabled={selectedProblemSetIds.length === 0}
                                    className="btn btn-sm btn-primary">
                                Add Problem Sets
                            </button>
                        </div>
                    }
                </div>

                <MultipleProblemSetSelection problemSets={problemSets}
                                             selectedProblemSetIds={selectedProblemSetIds}
                                             selectUnselect={this.selectProblemSet}/>

            </div>
        );
    }
}


const mapStateToProps = ({ problemsets, sats, acts }) => ({ problemsets, sats, acts })

const mapDispatchToProps = dispatch => {
    return ({
        linkExistingProblemSet: (id, attributes) => dispatch(problemSetActions.linkExistingProblemSet(id, attributes)),
        getProblemSets: (params) => dispatch(problemSetActions.getAllProblemSets(params)),
        openDialog: (config, options) => dispatch(dialogActions.open(config, options)),
        alertPush: (payload) => dispatch(alertActions.alertPush(payload)),
        alertDelete: () => dispatch(alertActions.alertDelete())
    })
}

export const AddExistingProblemSetDialog =  withRouter(connect(mapStateToProps, mapDispatchToProps)(AddExistingProblemSetModal));
