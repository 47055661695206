import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import FlexEditor from './FlexEditor';
import QuestionEditor from './QuestionEditor';
import QuestionRank from './QuestionRank';
import AdminAnswers from '../answer/AdminAnswers';
import UnlinkedAnswers from './UnlinkedAnswers';
import * as answerActions from '../../../../../modules/answer/action';
import * as questionActions from '../../../../../modules/question/action';
import * as problemSetQuestionActions from '../../../../../modules/problemSetQuestion/action';
import * as bucketActions from '../../../../../modules/bucket/action';
import * as dialogActions from '../../../../../modules/dialog/action';
import * as animationActions from '../../../../../modules/animation/action';
import * as problemSetAnswerActions from '../../../../../modules/problemSetAnswer/action';
import {CREATE_QUESTION_DIALOG_BOX, PREVIEW_QUESTION_DIALOG_BOX} from '../../../../dialogs/dialogs'
import './AdminQuestion.scss';
import ReadOnlyEditor from "../../../../editor/ReadOnlyEditor";
import {RenderCalcImage, RenderFreeResponseIcon, RenderHiddenQuestionIcon} from "../../../../helper/commonMethods";
import * as alertActions from "../../../../../modules/alert/action";

class AdminQuestion extends Component {
    state = {
        infoActive: false,
        showMainContent: false
    }

    componentDidMount() {
        const { question, location } = this.props;
        const queryString = new URLSearchParams(location.search);
        const questionId = (queryString && queryString.get('question_id'));
        if(question.id === questionId){
            this.toggleInfo();
        }
    }

    async componentDidUpdate(prevProps) {
        const { question, getAnswers, section, showMainContent, problemset_questions, questions } = this.props;
        const { infoActive } = this.state;
        if (section === 'problemSet') {
            if (prevProps.problemset_questions.length > problemset_questions.length && (infoActive || this.fetchAnswers().length > 0)) {
                const updatedAnswers = await getAnswers({ params: { question_id: question.id } })
                this.setState({ updatedAnswers })
            }
        } else {
            if (prevProps.questions.length > questions.length && (infoActive || this.fetchAnswers().length > 0)) {
                const updatedAnswers = await getAnswers({ params: { question_id: question.id } })
                this.setState({ updatedAnswers })
            }
        }
        if(prevProps.showMainContent !== showMainContent){
            this.setState({showMainContent});
        }

    }

    resetUpdatedAnswers = () => {
        this.setState({ updatedAnswers: null })
    }

    toggleInfo = () => {
        const { getAnswers, question } = this.props;
        const { infoActive } = this.state;
        if (infoActive) {
            this.setState({ infoActive: false })
        } else {
            getAnswers({ params: { question_id: question.id } })
            this.setState({ infoActive: true });
        }
    }

    openModal = () => {
        const { openDialog, question, section } = this.props;
        let id = '';
        if(section === 'problemSet') {
            id = question.problem_set_id;
        } else if(section === 'SatQuestionTopic') {
            id = question.sat_question_id;
        } else if(section === 'ActQuestionTopic') {
            id = question.act_question_id;
        } else if (section === 'AdminSatMockTest' || section === 'AdminSatRealTest') {
            id = question.sat_id;
        } else if(section === 'AdminActMockTest' || section === 'AdminActRealTest') {
            id = question.act_id;
        } else if(section === 'subsubTopic') {
            id = question.subsubtopic_id
        } else {
            id = question.subtopic_id
        }
        openDialog(CREATE_QUESTION_DIALOG_BOX, {
            invokedQuestion: question,
            topicId: id,
            clickFnc: this.addQuestionFromBucket,
            fncType: "createQuestionUnder",
            createMultiple: this.createMultipleBucketQuestion,
            toggleForm: this.createNextQuestion,
        })
    }

    createMultipleBucketQuestion = async (questions, topicId, answers, invokedQuestion) => {
        const { createMultipleBucketQuestion } = this.props;
        await createMultipleBucketQuestion(questions, topicId, answers, invokedQuestion);
    }

    addQuestionFromBucket = async (bucketQuestion, topicId, answers, invokedQuestion) => {
        const { createQuestionFrom, section, questions, match: { params: { satType, actType } } } = this.props;
        // added  "true" at the end similar to duplicate functionality
        // as new question should be under the specific question from where bucket is opened
        if ((section === 'AdminSatMockTest' || section === 'AdminSatRealTest') && questions.length === 20 && satType && satType === "MathNoCalc") {
            alert("You can't create more than 20 questions in this test!")
        } else if ((section === 'AdminSatMockTest' || section === 'AdminSatRealTest') && questions.length === 38 && satType && satType === "MathCalc") {
            alert("You can't create more than 38 questions in this test!")
        } else if ((section === 'AdminActMockTest' || section === 'AdminActRealTest') && questions.length >= 60 && actType && actType === "Mathematics") {
            alert("You can't create more than 60 questions in this test!")
        } else {
            createQuestionFrom(bucketQuestion, topicId, answers, invokedQuestion, true);
        }
    }

    // Checks database for present answers when duplication occurs
    async getOrFetchAnswers() {
        const { getAnswers, question } = this.props;
        let filtered = this.fetchAnswers();
        if (filtered.length === 0) {
            filtered = await getAnswers({ params: { question_id: question.id } })
        }

        return filtered;
    }

    // Fetch answers from current redux state
    fetchAnswers() {
        const { question, answers } = this.props;
        if (answers[question.id]) {
            return Object.values(answers[question.id]);
        }
        return [];
    }

    // Creates new question with answers then adds under original question
    duplicateQuestion = async () => {
        const { question, createQuestionFrom, startAnimation, endAnimation, section, questions, match: { params: { satType, actType } } } = this.props;
        if ((section === "AdminSatMockTest" || section === "AdminSatRealTest") && questions.length === 20 && satType && satType === "MathNoCalc") {
            alert("You can't create more than 20 questions in this test!");
        } else if ((section === "AdminSatMockTest" || section === "AdminSatRealTest") && questions.length === 38 && satType && satType === "MathCalc") {
            alert("You can't create more than 38 questions in this test!");
        } else if ((section === "AdminActMockTest" || section === "AdminActRealTest") && questions.length >= 60 && actType && actType === "Mathematics") {
            alert("You can't create more than 60 questions in this test!");
        } else {
            const filtered = await this.getOrFetchAnswers();
            let id;
            if (section === 'problemSet') {
                id = question.problem_set_id;
            } else if (section === 'subsubTopic') {
                id = question.subsubtopic_id;
            } else if (section === "SatQuestionTopic") {
                id = question.sat_question_id;
            } else if (section === "ActQuestionTopic") {
                id = question.act_question_id;
            } else if (section === "AdminSatMockTest" || section === "AdminSatRealTest") {
                id = question.sat_id;
            } else if (section === "AdminActMockTest" || section === "AdminActRealTest") {
                id = question.act_id;
            } else {
                id = question.subtopic_id;
            }
            // We add the same question as the fourth argument to invoke the invokedQuestion conditional
            // This allows us to use f(x)createQuestionFrom for duplication and bucket creation with respect to question or the list (main Create Question button)
            startAnimation();
            await createQuestionFrom(question, id, filtered, question, true)
            endAnimation();
        }
    }

    // Create a new question under the original question
    createNextQuestion = () => {
        const { createFromQuestion, question } = this.props;
        createFromQuestion(question.position);
    }

    deleteQuestion = async () => {
        const {
            section, deleteProblemSetsQuestion, deleteQuestion, question, startAnimation, endAnimation,
            deleteSubtopicQuestion, updateOnDelete
        } = this.props;
        let res = window.confirm("Are you sure you want to delete this question?");
        if (res) {
            startAnimation();
            if (section === "problemSet") {
                await deleteProblemSetsQuestion(question.id)
            } else if (section === 'subsubTopic') {
                await deleteSubtopicQuestion(question.id);
            } else {
                await deleteQuestion(question.id);
            }
            await updateOnDelete();
            endAnimation();
            this.flashMessage({message: `Question deleted`, type: 'success'});
            // postDeleteUpdate(question)
        } else {
            return null;
        }
    }

    previewQuestion = async () => {
        const { question, openDialog, getAnswers } = this.props;
        const previewAnswers = await getAnswers({ params: { question_id: question.id } })
        openDialog(PREVIEW_QUESTION_DIALOG_BOX, { question, previewAnswers });
    }

    bucketQuestionIsPresent(question) {
        const { bucketQuestions } = this.props;
        for (let i = 0; i < bucketQuestions.length; i++) {
            if (bucketQuestions[i].id === question.id) {
                return true;
            }
        }

        return false;
    }

    // Adds a question to the Redux Bucket
    addToBucket = () => {
        const { addBucketQuestion, question, startAnimation, endAnimation  } = this.props;
        if (!this.bucketQuestionIsPresent(question)) {
            startAnimation();
            addBucketQuestion(question);
            this.flashMessage({message: `Question added to Bucket`, type: 'success'});
            endAnimation();
        } else {
            this.flashMessage({message: `Question already added in bucket`, type: 'warning'});
        }
        return null;
    }

    flashMessage = (payload, timeout = 5000) => {
        const { alertPush, alertDelete } = this.props;
        alertPush(payload);
        setTimeout(() => alertDelete(),timeout);
    }

    copyQuestionToExp = async () => {
        const { question, updateQuestion, postQuestionUpdate, updateProblemSetQuestion, updateSubsubTopicQuestion } = this.props;
        let res = window.confirm("Overwrite question explanation?");
        if (res) {
            let updated
            if (question.problem_set_id) {
                updated = await updateProblemSetQuestion(question.id, { question_exp: question.question })
            } else if (question.subsubtopic_id) {
                updated = await updateSubsubTopicQuestion(question.id, { question_exp: question.question })
            } else {
                updated = await updateQuestion(question.id, { question_exp: question.question })
            }
            this.flashMessage({message: `Explanation copy from Question`, type: 'success'});
            postQuestionUpdate(updated)
        } else {
            return null;
        }
    }

    // Add back in after upwork work is completed
    render() {
        const { question, postDeleteUpdate, postQuestionUpdate, openDialog, problemsets, id, subtopicId,
            topicType, section, linkProblemSet, linkQuestion, questions, unLinkQuestion, unLinkProblemSet,
            startAnimation, endAnimation, subsubtopics, location, loading } = this.props;
        const { infoActive, showMainContent, updatedAnswers } = this.state;
        if(showMainContent === true){
            return(
                <AdminQuestionView question={question} location={location} loading={loading}/>
            )
        }

        const filtered = this.fetchAnswers();

        return (
            <div className="admin-question">
                <div className="top-section">
                    <div className="header">
                        <div className="left">
                            <QuestionRank rank={question.rank} visible={!(location.pathname.includes("problemset"))}/>
                            <RenderCalcImage question={question} />
                            <RenderFreeResponseIcon question={question} />
                            <RenderHiddenQuestionIcon question={question} location={location}/>
                        </div>
                        <UnlinkedAnswers question={question} />
                    </div>
                    <div className="editor-section">
                        <QuestionEditor subsubtopics={subsubtopics}
                                        disabled={loading}
                                        startAnimation={startAnimation}
                                        endAnimation={endAnimation}
                                        questions={questions}
                                        section={section}
                                        topicType={topicType}
                                        problemsets={problemsets}
                                        openDialog={openDialog}
                                        question={question}
                                        postDeleteUpdate={postDeleteUpdate}
                                        postQuestionUpdate={postQuestionUpdate}
                                        deleteQuestion={this.deleteQuestion}
                                        infoActive={infoActive}
                                        toggleInfo={this.toggleInfo}
                                        duplicateQuestion={this.duplicateQuestion}
                                        addToBucket={this.addToBucket}
                                        openModal={this.openModal}
                                        previewQuestion={this.previewQuestion}
                                        linkProblemSet={linkProblemSet}
                                        linkQuestion={linkQuestion}
                                        unLinkProblemSet={unLinkProblemSet}
                                        unLinkQuestion={unLinkQuestion}
                                        flashMessage={this.flashMessage}/>
                        <FlexEditor postQuestionUpdate={postQuestionUpdate}
                                    question={question}
                                    infoActive={infoActive}
                                    contentType="help"
                                    contentTitle="Help"
                                    flashMessage={this.flashMessage}
                                    content={question.help} />
                    </div>
                    <div className="editor-section">
                        <FlexEditor postQuestionUpdate={postQuestionUpdate}
                                    question={question}
                                    infoActive={infoActive}
                                    contentType="question_exp"
                                    contentTitle="Question Explanation"
                                    flashMessage={this.flashMessage}
                                    content={question.question_exp}
                                    copyQuestionToExp={this.copyQuestionToExp}/>
                        <FlexEditor postQuestionUpdate={postQuestionUpdate}
                                    question={question}
                                    infoActive={infoActive}
                                    contentType="help_exp"
                                    contentTitle="Help Explanation"
                                    flashMessage={this.flashMessage}
                                    content={question.help_exp} />
                    </div>
                </div>

                {/*Answer View*/}
                {
                    infoActive &&
                    <div>
                        <AdminAnswers question={question}
                                      section={section}
                                      answers={filtered}
                                      questionId={id}
                                      subtopicId={subtopicId}
                                      infoActive={infoActive}
                                      updatedAnswers={updatedAnswers}
                                      topicType={topicType}
                                      resetUpdatedAnswers={this.resetUpdatedAnswers} />
                    </div>
                }
            </div>
        );
    }
}

// this method is used to fix issue => not showing latex when change positions.
const AdminQuestionView = ({question, location}) => {
    return (
        <div className="admin-question">
            <div className='top-section'>
                <div className="header">
                    <div className="left">
                        <QuestionRank rank={question.rank} visible={!(location.pathname.includes("problemset"))}/>
                        <RenderCalcImage question={question}/>
                        <RenderFreeResponseIcon question={question}/>
                        <RenderHiddenQuestionIcon question={question} location={location}/>
                    </div>
                    <UnlinkedAnswers question={question}/>
                </div>
                <div className="editor-section">
                    <div className="question-editor">
                        <div className="question-editor-show hide">
                            <ReadOnlyEditor content={question.question}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return ({
        answers: state.answers,
        questions: state.questions,
        updatedAnswers: null,
        bucketQuestions: state.bucket.questions,
        loading: state.animation,
        problemset_questions: state.problemset_questions,
        problemset_answers: state.problemset_answers,
        subsubtopics: state.subsubtopics
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        getAnswers: (params) => dispatch(answerActions.getAllAnswer(params)),
        updateQuestion: (id, attributes) => dispatch(questionActions.updateQuestion(id, attributes)),
        deleteQuestion: (id) => dispatch(questionActions.deleteQuestion(id)),

        updateSubsubTopicQuestion: (id, attributes) => dispatch(questionActions.update('question_subsubtopics', id, attributes)),
        deleteSubtopicQuestion: (id) => dispatch(questionActions.destroy('question_subsubtopics', id)),

        addBucketQuestion: (data) => dispatch(bucketActions.addBucketQuestion(data)),

        openDialog: (config, options) => dispatch(dialogActions.open(config, options)),
        startAnimation: () => dispatch(animationActions.start()),
        endAnimation: () => dispatch(animationActions.end()),

        getProblemSetAnswer: (params) => dispatch(problemSetAnswerActions.getAllProblemSetAnswer(params)),
        updateProblemSetQuestion: (id, attributes) => dispatch(problemSetQuestionActions.updateProblemSetQuestion(id, attributes)),
        deleteProblemSetsQuestion: (id) => dispatch(problemSetQuestionActions.deleteProblemSetsQuestion(id)),

        linkProblemSet: (data) => dispatch(questionActions.linkProblemSet(data)),
        linkQuestion: (data) => dispatch(questionActions.linkQuestion(data)),

        unLinkProblemSet: (data) => dispatch(questionActions.unLinkProblemSet(data)),
        unLinkQuestion: (data) => dispatch(questionActions.unLinkQuestion(data)),

        alertPush: (payload) => dispatch(alertActions.alertPush(payload)),
        alertDelete: () => dispatch(alertActions.alertDelete())
    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminQuestion));
