import * as dbUtil from "../../api/dbUtil";
import {CREATE_ASSIGNMENT, GET_ASSIGNMENTS, UPDATE_ASSIGNMENT} from "../constant";
import {API} from "../../api";
import {alertPushMessage, checkSessionExpired} from "..";

const receiveAssignment = (data) => ({type: CREATE_ASSIGNMENT, data})
const receiveAssignments = (data) => ({type: GET_ASSIGNMENTS, data})
const updateAssignmentData = (data) => ({type: UPDATE_ASSIGNMENT, data})


export const createAssignment = (attributes) => {
    return(dispatch) => {
        return dbUtil.create("assignments", attributes).then(({data}) => {
            dispatch(receiveAssignment(data))
            return data
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const createMultipleAssignment = (attributes) => {
    return(dispatch) => {
        return dbUtil.create("assignments/create_multiple", attributes).then(({data}) => {
            dispatch(receiveAssignment(data))
            return data
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}


export const getAllAssignments = () => {
    return(dispatch) => {
        return dbUtil.fetchMany("assignments").then(({data}) => {
            dispatch(receiveAssignments(data))
            return data
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const deleteAssignment = (id) => {
    return(dispatch) => {
        return dbUtil.destroy("assignments", id).then(({data}) => {
            dispatch(receiveAssignments(data))
            dispatch(alertPushMessage({message: `Assignment Unassigned`, type: 'success'}));
            return data
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateAssignment = (id, attributes) => {
    return(dispatch) => {
        return dbUtil.update("assignments", id, attributes).then(({data}) => {
            dispatch(updateAssignmentData(data))
            return data
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const completeAssignment = (attributes) => {
    return(dispatch) => {
        return API.put(`/assignments/complete`, attributes).then(({data}) => {
            dispatch(updateAssignmentData(data))
            return data
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}