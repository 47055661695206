import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReadOnlyEditor from '../../../editor/ReadOnlyEditor';
import * as answerActions from '../../../../modules/answer/action';
import * as animationActions from '../../../../modules/animation/action';
import * as accountActions from '../../../../modules/account/action';
import './ViewSatTestQuestions.scss'
import _ from 'lodash'
import { checkCorrectOnFreeResponseAnswers } from '../../../../utilities/custom'
import {
    FreeResponse,
    FreeResponseAnswerView, GotoQuestionView,
    InstructionView,
    SaveAndNextQuestion,
    SubmitQuestionView
} from "./CommonQuestionView";
import IfView from "../../../helper/IfView";
import * as alertActions from "../../../../modules/alert/action";
import * as userActTestProgressActions from "../../../../modules/userActTestProgress/action";
import * as assignmentActions from "../../../../modules/assignment/action";

class ViewQuestions extends Component {

    constructor(props) {
        super(props)
        const {firstQuestion} = props;
        this.state = {
            viewQuestion: firstQuestion ?? '',
            answersGiven: [],
            instructions: true,
            actTestProgress: [],
            submitted: '',
            free_response_answer_value: ''
        }
    }

    componentDidMount = async () => {
        const { getAnswers, startAnimation, endAnimation, getUserActTestProgress, actType, topicId, realTest } = this.props
        await getUserActTestProgress({ act_id: topicId, act_type: actType, real_test: realTest })
        const { viewQuestion } = this.state;
        if (viewQuestion) {
            startAnimation()
            await getAnswers({ params: { question_id: viewQuestion.id } })
            endAnimation()
        }
    }

    componentDidUpdate(props) {
        const { userActTestProgress, answers, realTest, topicId, actType } = this.props
        const { viewQuestion } = this.state;
        if ((props.userActTestProgress !== userActTestProgress) || (props.answers !== answers)) {
            const userActProgress = userActTestProgress.find(test => test.real_test === realTest && test.act_id === topicId && test.act_type === actType);
            if (userActProgress && userActProgress.act_test_progress) {
                let actTestProgress = JSON.parse(userActProgress.act_test_progress)
                let submitted = userActProgress.submitted
                let progressData = actTestProgress.find(dd => dd.questionId === viewQuestion.id)
                this.setState({
                    actTestProgress,
                    answersGiven: progressData?.givenAnswerIds ?? [],
                    submitted,
                    free_response_answer_value: progressData?.freeResponseGivenAnswer ?? ''
                })
            }
        }

    }

    nextQuestion = async (id, marking, goToNext) => {
        const {
            questions, getAnswers, startAnimation, endAnimation, answers, saveUserActTestProgress, topicId, actType,
            realTest
        } = this.props;
        const { viewQuestion, answersGiven, actTestProgress, free_response_answer_value } = this.state;

        let progressData = {}
        let getCorrect = false
        if (viewQuestion.is_free_response) {

            let freeResponseAnswers = []
            if (viewQuestion.is_free_response) {
                if (_.keys(answers).length > 0 && viewQuestion && answers[viewQuestion.id]) {
                    freeResponseAnswers = answers[viewQuestion.id].filter(dd => dd.is_free_response === true)
                }
            }

            let sortedFreeResponseAnswers = _.orderBy(freeResponseAnswers, ['is_exact_match'], ['desc'])

            let breakCheck = false
            sortedFreeResponseAnswers.map(answer => {
                if (breakCheck === false) {
                    let ans = checkCorrectOnFreeResponseAnswers(free_response_answer_value, answer)
                    if (ans) {
                        getCorrect = ans
                        breakCheck = true
                    }
                }

            })

            progressData = {
                questionId: viewQuestion.id,
                correctAnswerIds: [],
                givenAnswerIds: [],
                isCorrect: getCorrect,
                isFreeResponse: true,
                freeResponseCorrectAnswer: freeResponseAnswers.map(d => d.free_response_answer),
                freeResponseGivenAnswer: free_response_answer_value,
                markUncertain: marking,
                isComplete: free_response_answer_value !== ''
            }
        } else {
            let allAnswers = answers[viewQuestion.id];

            let allCorrect = allAnswers.filter((val) => val.correct === true)
            let allCorrectIds = _.map(allCorrect, (d) => d.id)

            if (allCorrectIds.length === answersGiven.length) {
                getCorrect = _.isEqual(_.sortBy(allCorrectIds), _.sortBy(answersGiven))
            }

            progressData = {
                questionId: viewQuestion.id,
                correctAnswerIds: allCorrectIds,
                givenAnswerIds: answersGiven,
                isCorrect: getCorrect,
                isFreeResponse: false,
                freeResponseCorrectAnswer: null,
                freeResponseGivenAnswer: null,
                markUncertain: marking,
                isComplete: answersGiven && answersGiven.length > 0
            }
        }

        let actTestProgressIds = actTestProgress.map(dd => dd.questionId)
        let records = ''
        if (actTestProgressIds.includes(progressData.questionId)) {
            records = actTestProgress.map(dd => dd.questionId === progressData.questionId ? progressData : dd)
        } else {
            actTestProgress.push(progressData)
            records = actTestProgress
        }

        startAnimation()
        await saveUserActTestProgress({ act_id: topicId, act_type: actType, act_test_progress: JSON.stringify(records), submitted: false, real_test: realTest })

        if (id !== null && goToNext === true) {
            const nextQuestion = questions.filter((question) => question.id === id)[0]
            this.setState({ viewQuestion: nextQuestion, answersGiven: [] },
                async () => {
                    if (nextQuestion) {
                        await getAnswers({ params: { question_id: nextQuestion.id } })
                    }
                });
            this.flashMessage({message: 'Answer Saved', type: "success"});
        } else if (goToNext === false){
            this.flashMessage({message: `Question ${ marking === true ? "mark as ": "remove from"} uncertain`, type: 'success'});
        }
        endAnimation()

    }

    goToQuestion = async (id) => {
        const { questions, getAnswers, startAnimation, endAnimation } = this.props

        const nextQuestion = questions.filter((question) => question.id === id)[0]
        this.setState({ viewQuestion: nextQuestion, answersGiven: [] },
            async () => {
                if (nextQuestion) {
                    startAnimation()
                    await getAnswers({ params: { question_id: nextQuestion.id } })
                    endAnimation()
                }
            })
    }

    checkAnswer = (answerId) => {
        let answersGiven = this.state.answersGiven
        let filteredArr = []
        if (answersGiven.length === 0) {
            answersGiven.push(answerId)
            filteredArr = answersGiven
        } else {
            let checkValue = answersGiven.includes(answerId)
            if (checkValue) {
                filteredArr = answersGiven.filter(item => item !== answerId)
            } else {
                answersGiven.push(answerId)
                filteredArr = answersGiven
            }
        }

        this.setState({ answersGiven: filteredArr })
    }

    checkfreeResponseAnswer = (e) => {
        if (e.target.value) {
            this.setState({ free_response_answer_value: e.target.value })
        } else {
            this.setState({ free_response_answer_value: '' })
        }
    }

    finalSubmit = async () => {
        const {
            startAnimation, endAnimation, submitActTest, topicId, actType, createAssignment, questions, actQuestions,
            realTest
        } = this.props;
        let res = window.confirm("Are you sure you want to submit whole test?");
        if (res) {
            startAnimation()
            await submitActTest({ act_id: topicId, act_type: actType, real_test: realTest, submitted: true })
            // create assignments for current students but comment this feature as per client requirement on 09-12-2021
            let createAutoAssignments = false;
            const { userActTestProgress, history } = this.props;
            const userActProgress = userActTestProgress.find(test => test.real_test === realTest && test.act_id === topicId && test.act_type === actType);
            if (userActProgress && createAutoAssignments) {
                const act_type = userActProgress.act_type;
                const test_progress = JSON.parse(userActProgress.act_test_progress)
                let assignmentParams = { [act_type]: [] }
                let correct_questions = test_progress.filter(question => question.isCorrect === true)
                let correct_question_ids = correct_questions.map((progress) => progress.questionId)
                let incorrect_questions = questions.filter(question => !(correct_question_ids.includes(question.id)))
                let incorrect_questions_positions = incorrect_questions.map(question => question.position)
                let filtered_act_questions = actQuestions[userActProgress.act_id].filter(act_question => (act_type === act_question.type && incorrect_questions_positions.includes(act_question.position)))
                let createAssignmentParams = { ...assignmentParams, [act_type]: filtered_act_questions.map((act_question) => act_question.id) }
                await createAssignment({ selectedWrong: JSON.stringify(createAssignmentParams), topic_type: "act" })
            }
            endAnimation()
            const area = realTest ? 'real' : 'mock';
            this.flashMessage({message: 'Test submitted', type: "success"})
            history.push(`/home/acts?act=${topicId}&area=${area}`);
        } else {
            return null
        }

    }

    instructions = () => {
        this.setState({ instructions: true })
    }

    flashMessage = (payload, timeout = 5000) => {
        const {alertPush, alertDelete} = this.props;
        alertPush(payload);
        setTimeout(() => alertDelete(),timeout);
    }

    render() {
        const { answers, loading, questions } = this.props
        const { viewQuestion, answersGiven, instructions, actTestProgress, submitted, free_response_answer_value } = this.state

        let freeResponseAnswers = [];
        if (viewQuestion.is_free_response) {
            if (_.keys(answers).length > 0 && viewQuestion && answers[viewQuestion.id]) {
                freeResponseAnswers = answers[viewQuestion.id].filter(dd => dd.is_free_response === true)
            }
        }

        let previousQuestionData = _.find(questions, ['position', viewQuestion.position - 1])

        let nextQuestionData = _.find(questions, ['position', viewQuestion.position + 1])

        let currentQuestion = actTestProgress.find(dd => dd.questionId === viewQuestion.id)
        return (
            <div className={`view-question--inner ${loading ? 'loading-topics' : ''}`}>
                <InstructionView visible={instructions}
                    onClick={() => this.setState({ instructions: false })}
                    question={viewQuestion} />
                <IfView visible={!instructions}>
                    <IfView visible={viewQuestion}>
                        <div className="sat-test-center-container">
                            <ReadOnlyEditor viewAble={true} content={viewQuestion.question} />
                        </div>
                    </IfView>
                    <div className="view-question--ans sat-test-center-container">
                        <FreeResponse answers={freeResponseAnswers}
                                      show={viewQuestion.is_free_response}
                                      submitted={submitted}
                                      value={free_response_answer_value}
                                      onChange={(e) => this.checkfreeResponseAnswer(e)} />
                        <FreeResponseAnswerView answers={answers}
                                                question={viewQuestion}
                                                answersGiven={answersGiven}
                                                checkAnswer={(id) => this.checkAnswer(id)}
                                                submitted={submitted}/>
                    </div>

                    <SaveAndNextQuestion nextQuestion={this.nextQuestion} submitted={submitted}
                        answersGiven={answersGiven} nextQuestionData={nextQuestionData}
                        currentQuestion={currentQuestion} answerValue={free_response_answer_value} />

                    <GotoQuestionView question={viewQuestion}
                        nextQuestion={nextQuestionData}
                        goToQuestion={this.goToQuestion}
                        allQuestions={questions}
                        previousQuestion={previousQuestionData}
                        testProgress={actTestProgress} />

                    <div className="d-flex justify-content-center w-100 position-relative align-items-end mt-4">
                        <button onClick={() => this.instructions()} className="btn btn-info m-3">Instructions</button>
                        <button disabled={!!submitted} onClick={() => this.finalSubmit()} className="btn btn-success m-3">Submit whole test</button>
                    </div>

                    <SubmitQuestionView />
                </IfView>
            </div>
        );

    }
}

const mapStateToProps = state => {
    return ({
        answers: state.answers,
        loading: state.loading,
        account: state.account,
        userActTestProgress: state.userActTestProgress.data,
        actQuestions: state.actQuestions
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        startAnimation: () => dispatch(animationActions.start()),
        endAnimation: () => dispatch(animationActions.end()),
        getAnswers: (params) => dispatch(answerActions.getAllAnswer(params)),
        saveUserActTestProgress: (params) => dispatch(userActTestProgressActions.saveUserActTestProgress(params)),
        getUserActTestProgress: (params) => dispatch(userActTestProgressActions.getUserActTestProgress(params)),
        submitActTest: (params) => dispatch(userActTestProgressActions.submitActTest(params)),
        createAssignment: (params) => dispatch(assignmentActions.createAssignment(params)),
        alertPush: (payload) => dispatch(alertActions.alertPush(payload)),
        alertDelete: () => dispatch(alertActions.alertDelete())
    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewQuestions));
