import React, { Component } from 'react';
import HomeSubtopic from './HomeSubtopic';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import './HomeSubtopics.scss';

const SortableItem = SortableElement(({
    subtopic,updateSubtopic,deleteSubtopic,satTopicModal, satTopic
}) => {
    return (
        <li key={subtopic.id}>
            {/* {satTopicModal === false && <DragHandle />} */}
            <HomeSubtopic
                updateSubtopic={updateSubtopic}
                deleteSubtopic={deleteSubtopic}
                subtopic={subtopic} 
                satTopicModal={satTopicModal}
                satTopic={satTopic}/>
        </li>
    )
})


const SortableList = SortableContainer(({
    subtopics,updateSubtopic,deleteSubtopic, satTopicModal, satTopic
}) => {
    return (
        <ul className="sortable-home-topic-list">
            {subtopics.map((subtopic, index) => {
                return (
                    <SortableItem
                        key={subtopic.id}
                        index={index}
                        subtopic={subtopic}
                        id={subtopic.id}
                        updateSubtopic={updateSubtopic}
                        deleteSubtopic= {deleteSubtopic}
                        satTopicModal={satTopicModal}
                        satTopic={satTopic}
                    />
                )
            })}
        </ul>
    )
})

class HomeSubtopics extends Component {

    state = {
        subtopics: [],
    }

    componentDidMount() {

        const { subtopics } = this.props;
        this.setState({subtopics})
    }


    onSortEnd = async (options, mouseEvent, subtopics = this.props.subtopics) => {
        const {updateSubTopicPositions} = this.props;
        const {oldIndex, newIndex} = options;
        if(oldIndex === newIndex) return null;
        const updatedSubTopics = arrayMove(subtopics, oldIndex, newIndex)
        const updatedSubTopicData = {subtopics: updatedSubTopics, _id: updatedSubTopics[0].test_id};
        const x = await updateSubTopicPositions(updatedSubTopicData);
        this.setState({subtopics: x})
    }
    render() {
        const { subtopics, show, updateSubtopic, deleteSubtopic , satTopicModal , satTopic} = this.props;
        if (!show || !subtopics) return null;
        return (
            <div className="sortable-topics">
                <SortableList
                    useDragHandle
                    onSortEnd={this.onSortEnd}
                    subtopics={subtopics}
                    updateSubtopic={updateSubtopic}
                    deleteSubtopic= {deleteSubtopic}
                    satTopicModal={satTopicModal}
                    satTopic={satTopic}/>
            </div>
        );
    }
}

export default HomeSubtopics;
