import React, { Component } from 'react';

class CreateNewTopicDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      errTitle: ""
    }

    this.submit = this.submit.bind(this);
  }
  validate() {
    const { title } = this.state;

    let errTitleMessage = ''
    let isErrorPresent = false

    if (title.length < 1) {
      errTitleMessage = 'Please enter Title'
      isErrorPresent = true
    }

    this.setState({errTitle: errTitleMessage })

    return !isErrorPresent;
  }
  async submit(e) {
    e.preventDefault();
    const { createAction, close } = this.props;
    const { title } = this.state;
    if (this.validate()) {
      createAction({ title });
      close();
    }
  }
  handleChange(event) {
    let errorKey = ''
    if (event.target.name === "title") {
      errorKey = 'errTitle'
    }
    this.setState({ [event.target.name]: event.target.value, [errorKey]: '' })
  }

  render() {
    const { title } = this.state;
    const { tabLabel } = this.props
    return (
      <div className="p-0 pl-3 pr-3 mt-3">
        <h4 >{tabLabel}</h4>
        <form className="addUser_form pl-1 pt-1" onSubmit={this.submit}>
          <div className="form-row">
            <div className="form-group w-100">
              <label className="mb-0"><b>Title</b><span className="rqrd_str">*</span></label>
              <input
                className="form-control"
                type="text"
                name="title"
                value={title}
                onChange={(e) => (this.handleChange(e))} />
              <div className="errorCls">{this.state.errTitle}</div>
            </div>
          </div>
          <div className="form-row w-100">
            <div className="form-group btn-grup w-100 text-right">
              <button type="button" className="btn btn-default btn-blank modal-action modal-close" onClick={this.props.close}>
                <span className="btn_txt btn btn-default">Cancel</span>
              </button>
              <button type="submit" className="btn btn-default btn-primary" disabled={title === ""}>
                <span className="btn_txt">Submit</span>
                <i className="ico ico_tick_sml"/>
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export { CreateNewTopicDialog };
