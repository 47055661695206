import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HomeActTestQuestions from '../question/HomeActTestQuestions';
import * as animationActions from '../../../../modules/animation/action'
import * as actActions from '../../../../modules/act/action';
import './HomeSatTestQuestionMain.scss';
import SVG from '../../../SVG';

class HomeActTestQuestionMain extends Component {

    async componentDidMount() {
        window.scrollTo(0, 0);
        const { match: { params: { actId } }, getAct, topicType } = this.props;
        let error404 = false, published;
        const actTopic = await getAct(actId).catch(error=>{
            error404 = error.response?.status === 404;
        });
        if(topicType === 'HomeActRealTestQuestion'){
            published = actTopic?.math_real_test_publish;
        } else {
            published = actTopic?.math_mock_test_publish;
        }
        // if test is not published and if Act Topic is not published than redirect to not found page.
        if(error404 || !published || !actTopic?.isPublish){
            return this.props.history.push('/home/not_found')
        }

    }

    renderLoading() {
        const { loading } = this.props;
        if (loading) {
            return (
                <div className="loading-overlay">
                    <SVG name="spin" />
                </div>
            )
        }

        return null;
    }

    takeBack = () => {
        const { match: { params: { actId } }, topicType, history } = this.props;
        const area = topicType === 'HomeActMockTestQuestion' ? 'mock' : 'real';
        history.push(`/home/acts?act=${actId}&area=${area}`)
    }

    render() {
        const { match: { params: { actId, actType } }, acts, topicType } = this.props;
        let act = acts.find(dd => dd.id === actId);
        let typeTitle = actType.includes('English')
            ? 'English' : actType.includes('Mathematics')
                ? 'Mathematics' : actType.includes('Reading')
                    ? 'Reading' : 'Science';
        return (
            <div className="row">
                <div className="content-wrapper-before gradient-45deg-indigo-purple"/>
                <div className="col s12">
                    <div className="container">
                        <div className="dash_top_section">
                            <div className="row vertical-modern-dashboard">
                                <div className="col-12">
                                    <div className="card animate fadeLeft">
                                        <div className="question_sec card-content">
                                            <div className="dashtitle_sec d-flex flex-wrap align-items-center w-100">
                                                <button onClick={() => this.takeBack()} className="btn btn-default">
                                                    <i className="ico backicon"/>
                                                    <span className="btntxt">Back</span>
                                                </button>
                                            </div>
                                            <div className="quesHeader d-flex flex-wrap align-items-center text-center w-100">
                                                <h1 className=" quesTitle d-inline-block position-relative">
                                                    {act ? act.title + ' - ' + typeTitle : ''}
                                                </h1>
                                            </div>
                                            <HomeActTestQuestions topicType={topicType}
                                                                  topicId={actId}
                                                                  actType={actType}
                                                                  section={topicType}/>
                                            {this.renderLoading()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return ({
        loading: state.animation,
        acts: state.acts
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        startAnimation: () => dispatch(animationActions.start()),
        endAnimation: () => dispatch(animationActions.end()),
        getAct: (id) => dispatch(actActions.getTestAct(id))
    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeActTestQuestionMain));
