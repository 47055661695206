import React, { Component } from 'react';
import { connect } from 'react-redux'
import SectionQuestions from './SectionQuestions';
import * as actQuestionActions from '../../../modules/actQuestion/action';
import * as actProgressActions from '../../../modules/actProgress/action';
import '../sat/SATItem.scss'
import * as dialogActions from '../../../modules/dialog/action';
import _ from 'lodash'
import { withRouter } from 'react-router-dom';
import Switch from 'react-switch';
import {CircularProgressBar, SetScrollPosition} from "../../helper/commonMethods";
import IfView from "../../helper/IfView";
import IfElseView from "../../helper/IfElseView";
import * as alertActions from "../../../modules/alert/action";
import SVG from "../../SVG";
import * as actActions from "../../../modules/act/action";
import * as actProblemSetActions from "../../../modules/actProblemSet/action";
import * as userActsActions from "../../../modules/userAct/action";
import * as userActTestProgressActions from "../../../modules/userActTestProgress/action";
import * as assignmentActions from "../../../modules/assignment/action";
import {FORM_DIALOG_BOX} from "../../dialogs/dialogs";

const mapStateToProps = state => {
  const selectTopics = state?.actTopics?.map(topic => {
    return {
      value: topic.id,
      label: topic.title
    }
  })

  return ({
    selectTopics,
    actQuestions: state.actQuestions,
    account: state.account,
    actSequencesProgress: state.actProgress.sequences,
    actSequenceTypeProgress: state.actProgress.sequenceType,
    actProblemSetTypeProgress: state.actProgress.problemSetType,
    userActsData: state.userActs.data,
    userActsScores: state.userActs.scores,
    actTestScores: state.userActTestProgress.scores,
    userActsProgress: state.userProgresses.acts,
  })
}

const mapDispatchToProps = dispatch => {
  return ({
    getAct: (id) => dispatch(actActions.getTestAct(id)),
    updateACT: (id,attributes) => dispatch(actActions.updateRealAct(id,attributes)),
    deleteACT: (id) => dispatch(actActions.deleteRealAct(id)),
    getActQuestions: (params) => dispatch(actQuestionActions.getAllActQuestions(params)),
    updateActQuestion: (id, attributes) => dispatch(actQuestionActions.updateActQuestion(id, attributes)),
    openDialog: (config, options) => dispatch(dialogActions.open(config, options)),
    getUserActData: (params) => dispatch(userActsActions.getActScore(params)),
    saveUserActData: (params) => dispatch(userActsActions.createUserAct(params)),
    getUserAllActsTestProgress: (params) => dispatch(userActTestProgressActions.getUserAllActsTestProgress(params)),
    getUserActSequencesProgress: (params) => dispatch(actProgressActions.getUserActSequencesProgress(params)),
    getUserActSequenceTypeProgress: (params) => dispatch(actProgressActions.getUserActSequenceTypeProgress(params)),
    getUserActProblemSetTypeProgress: (params) => dispatch(actProgressActions.getUserActProblemSetTypeProgress(params)),
    createAssignment: (params) => dispatch(assignmentActions.createAssignment(params)),
    getActProblemSets: (id) => dispatch(actProblemSetActions.getActProblemSets(id)),
    alertPush: (payload) => dispatch(alertActions.alertPush(payload)),
    alertDelete: () => dispatch(alertActions.alertDelete())
  })
}

class ACTItem extends Component {
  constructor(props) {
    super(props);
    const { act, showDragHandle, userActsData } = this.props;
    let setData = userActsData.find(dd => dd.act_id === act.id);
    this.state = {
      hidden: true,
      receivedContent: false,
      // selectWrong: false,
      bubbleSheet: { 'English': false, 'Mathematics': false, 'Reading': false, 'Science': false },
      selectWrong: { 'English': false, 'Mathematics': false, 'Reading': false, 'Science': false },
      // selectUncertain: false,
      selectUncertain: { 'English': false, 'Mathematics': false, 'Reading': false, 'Science': false },
      bubbleSheetAnswer: setData?.bubbleSheetAnswer
          ? JSON.parse(setData.bubbleSheetAnswer) : { 'English': {}, 'Mathematics': {}, 'Reading': {}, 'Science': {} },
      wrongSelectedQuestions: setData?.selectedWrong
        ? JSON.parse(setData.selectedWrong) : { 'English': [], 'Mathematics': [], 'Reading': [], 'Science': [] },
      uncertainSelectedQuestions: setData?.selectedUncertain
        ? JSON.parse(setData.selectedUncertain) : { 'English': [], 'Mathematics': [], 'Reading': [], 'Science': [] },
      isMock: false,
      isTest: false,
      gradeSubmitted: setData?.grade_submitted ? setData.grade_submitted : { 'English': false, 'Mathematics': false, 'Reading': false, 'Science': false },
      timed: (setData?.timed && !(_.isEmpty(setData.timed))) ? setData.timed : { 'English': false, 'Mathematics': false, 'Reading': false, 'Science': false },
      grading_mode: false,
      actProgressLoad: false,
      loading: false,
      showDragHandle: showDragHandle ?? false
    }

    this.hideContent = this.hideContent.bind(this);
  }

  async componentDidMount() {
    const { act, location } = this.props;
    const queryString = new URLSearchParams(location.search);
    if (act.id === queryString?.get('act')) {
      await this.hideContent();
    }
    if ((queryString?.get('area') === 'mock' || queryString?.get('area') === 'real') && act.id === queryString?.get('act')) {
      this.setState({ isMock: false, isTest: true });
      await this.loadActTestProgress();
    }
  }

  componentDidUpdate(props) {
    const { userActsData, act, showDragHandle } = this.props;
    if (showDragHandle !== props.showDragHandle) {
      this.setState({showDragHandle})
    }
    if (userActsData !== props.userActsData) {
      let setData = userActsData.find(dd => dd.act_id === act.id);
      this.setState({
        bubbleSheetAnswer: setData?.bubbleSheetAnswer ? JSON.parse(setData.bubbleSheetAnswer) :
            { 'English': {}, 'Mathematics': {}, 'Reading': {}, 'Science': {} },
        wrongSelectedQuestions: setData?.selectedWrong ? JSON.parse(setData.selectedWrong) :
            { 'English': [], 'Mathematics': [], 'Reading': [], 'Science': [] },
        uncertainSelectedQuestions: setData?.selectedUncertain ? JSON.parse(setData.selectedUncertain) :
            { 'English': [], 'Mathematics': [], 'Reading': [], 'Science': [] },
        gradeSubmitted: setData?.grade_submitted ? setData.grade_submitted :
            { 'English': false, 'Mathematics': false, 'Reading': false, 'Science': false },
        timed: (setData?.timed && !(_.isEmpty(setData.timed))) ? setData.timed :
            { 'English': false, 'Mathematics': false, 'Reading': false, 'Science': false }
      })
    }
  }

  toggle = (el) => {
    this.setState({ [el]: !this.state[el] })
  }

  async hideContent() {
    const { hidden, showDragHandle } = this.state;
    const { act } = this.props;
    if(showDragHandle || act.type === "RealAct") return null;
    if(hidden){
      await this.setState({ loading: true });
      const {
        act, getAct, getActQuestions, actQuestions, getUserActSequencesProgress,
        getUserActSequenceTypeProgress, getActProblemSets, getUserActProblemSetTypeProgress
      } = this.props;
      await getAct(act.id);
      await getUserActSequencesProgress({act_id: act.id});
      await getUserActSequenceTypeProgress({act_id: act.id});
      if(act.progress_by_problemset){
        await getActProblemSets(act.id);
        await getUserActProblemSetTypeProgress({act_id: act.id});
      }
      const question = Object.keys(actQuestions)
      const index = question.findIndex(x => x === act.id)
      if (hidden && index === -1) {
        await getActQuestions({ params: { act_id: act.id } });
      }
    }
    this.setState({ hidden: !hidden, loading: false });
  }

  gradingModeToggleSwitch = () => {
    const { grading_mode } = this.state
    this.setState({ grading_mode: !grading_mode });
  }

  hiddenContent(recordHash) {
    const {
      selectWrong, selectUncertain, wrongSelectedQuestions, uncertainSelectedQuestions,  gradeSubmitted, timed,
      bubbleSheet, bubbleSheetAnswer, loading, showDragHandle
    } = this.state;
    const {
      selectTopics, updateActQuestion,  actSequencesProgress, getUserActSequencesProgress, getUserActSequenceTypeProgress
    } = this.props;
    const {
      isMock, isTest, act, grading_mode, hidden, sectionQuestions, correctTestQuestions, uncertainTestQuestions,
      sectionHeaders
    } = recordHash;

    if(showDragHandle) return null;

    if(loading){
      return (
          <React.Fragment>
            <div className="setTopBorder"/>
            <div className="questions mt-5" style={{height: 120}}>
              <div className="loading-overlay">
                <SVG name="spin" />
              </div>
            </div>
          </React.Fragment>
      )
    }

    if (!hidden) {
      return (
        <React.Fragment>
          <div className="setTopBorder"/>
          <div className="questions">
            {
              _.map(sectionQuestions, (questions, questionType) => {
                return (<SectionQuestions isTest={isTest}
                                          isMock={isMock}
                                          act={act}
                                          questions={questions}
                                          header={sectionHeaders[questionType]}
                                          questionType={questionType}
                                          selectTopics={selectTopics}
                                          openDialog={this.openDialog}
                                          updateActQuestion={updateActQuestion}
                                          actSequencesProgress={actSequencesProgress && actSequencesProgress[act.id]}

                                          selectWrong={selectWrong}
                                          selectUncertain={selectUncertain}

                                          getWrongSelectedQuestions={this.getWrongSelectedQuestions}
                                          wrongSelectedQuestions={wrongSelectedQuestions}

                                          getUncertainSelectedQuestions={this.getUncertainSelectedQuestions}
                                          uncertainSelectedQuestions={uncertainSelectedQuestions}

                                          correctTestQuestions={correctTestQuestions}
                                          uncertainTestQuestions={uncertainTestQuestions}

                                          bubbleSheet={bubbleSheet}
                                          bubbleSheetAnswer={bubbleSheetAnswer}
                                          selectBubbleSheet={this.selectBubbleSheet}
                                          submitBubbleSheet={this.submitBubbleSheet}

                                          getUserActSequencesProgress={getUserActSequencesProgress}
                                          getUserActSequenceTypeProgress={getUserActSequenceTypeProgress}
                                          selectionWrongArea={this.selectionWrongArea}
                                          selectionUncertainArea={this.selectionUncertainArea}
                                          submitSelectWrong={this.submitSelectWrong}
                                          submitSelectUncertain={this.submitSelectUncertain}
                                          gradeSubmitted={gradeSubmitted}
                                          saveTimedUnTimed={this.saveTimedUnTimed}
                                          timed={timed}
                                          grading_mode={grading_mode}
                                          key={questionType}
                                          isLastIndex={questionType === 'Science'} />
              )
            })}
          </div>
        </React.Fragment>
      )
    }

    return null;
  }

  openDialog = (config,options) =>{
    const { act, openDialog, location } = this.props;
    const queryString = new URLSearchParams(location.search);
    if (queryString && queryString.get('act') && (act.id === queryString.get('act'))) {
      if(queryString.get('act_question')){
        SetScrollPosition(queryString.get('act_question'), window.innerHeight/2);
      } else {
        SetScrollPosition(act.id, window.innerHeight/2);
      }
    }
    openDialog(config,options);
  }

  selectionWrongArea = (type) => {
    const { userActsData, act } = this.props;
    let setData = userActsData.find(dd => dd.act_id === act.id);
    this.setState({
      selectWrong: { ...this.state.selectWrong, [type]: !this.state.selectWrong[type] },
      wrongSelectedQuestions: setData?.selectedWrong ? JSON.parse(setData.selectedWrong)
          : { 'English': [], 'Mathematics': [], 'Reading': [], 'Science': [] }
    })
  }

  selectionUncertainArea = (type) => {
    const { userActsData, act } = this.props;
    let setData = userActsData.find(dd => dd.act_id === act.id);
    this.setState({
      selectUncertain: { ...this.state.selectUncertain[type], [type]: !this.state.selectUncertain[type] },
      uncertainSelectedQuestions: setData?.selectedUncertain ? JSON.parse(setData.selectedUncertain) :
          { 'English': [], 'Mathematics': [], 'Reading': [], 'Science': [] }
    });
  }

  submitSelectWrong = async (type) => {
    const { wrongSelectedQuestions, gradeSubmitted, selectWrong } = this.state;
    const { act, saveUserActData, getUserActData } = this.props;
    await saveUserActData({ 
      selectedWrong: JSON.stringify(wrongSelectedQuestions), 
      act_id: act.id,
      grade_submitted: { ...gradeSubmitted, [type]: selectWrong[type] }
    });
    await getUserActData({act_id: act.id});
    await this.flashMessage({message: "Wrong question submitted", type: 'success'});

    // Comment this feature as per client requirement on 09-12-2021
    // await this.props.createAssignment({ selectedWrong: JSON.stringify(wrongSelectedQuestions), user_id: account.id, topic_type: 'act' })

    this.setState({ selectWrong: { 'English': false, 'Mathematics': false, 'Reading': false, 'Science': false } });
  }

  submitSelectUncertain = async () => {
    const { uncertainSelectedQuestions } = this.state;
    const { act, saveUserActData } = this.props;
    await saveUserActData({selectedUncertain: JSON.stringify(uncertainSelectedQuestions), act_id: act.id});
    await this.flashMessage({message: "Mark uncertain submitted", type: 'success'});
    this.setState({ selectUncertain: { 'English': false, 'Mathematics': false, 'Reading': false, 'Science': false } })
  }

  getWrongSelectedQuestions = (questionId, type) => {
    const wrongSelectedQuestions = this.getQuestionValue(type, questionId, this.state.wrongSelectedQuestions);
    this.setState({ wrongSelectedQuestions });
  }

  getUncertainSelectedQuestions = (questionId, type) => {
    const uncertainSelectedQuestions = this.getQuestionValue(type, questionId, this.state.uncertainSelectedQuestions);
    this.setState({ uncertainSelectedQuestions });
  }

  getQuestionValue = (type, questionId, questions) =>{
    if (questions[type].length === 0) {
      questions[type].push(questionId)
    } else {
      let checkValue = questions[type].includes(questionId)
      if (checkValue) {
        questions[type] = questions[type].filter(item => item !== questionId)
      } else {
        questions[type].push(questionId)
      }
    }
    return (questions);
  }

  selectBubbleSheet = (type) => {
    const { bubbleSheet } = this.state;
    const { userActsData, act } = this.props;
    let setData = userActsData.find(dd => dd.act_id === act.id);
      this.setState({
          bubbleSheet: { ...bubbleSheet[type], [type]: !bubbleSheet[type] },
          bubbleSheetAnswer: setData?.bubbleSheetAnswer ? JSON.parse(setData.bubbleSheetAnswer) : {
              'English': {}, 'Mathematics': {}, 'Reading': {}, 'Science': {}
          }
      });
  }

  flashMessage = (payload, timeout = 5000) => {
    const {alertPush, alertDelete} = this.props;
    alertPush(payload);
    setTimeout(() => alertDelete(),timeout);
  }


  submitBubbleSheet = async ({questionType, bubbleSheetAnswer, wrongSelectedQuestions }) => {
    const { gradeSubmitted, bubbleSheet } = this.state;
    const { act, saveUserActData, getUserActData } = this.props;
    await saveUserActData({ 
      bubbleSheetAnswer: JSON.stringify(bubbleSheetAnswer), 
      selectedWrong: JSON.stringify(wrongSelectedQuestions), 
      act_id: act.id,
      grade_submitted: { ...gradeSubmitted, [questionType]: bubbleSheet[questionType] } });
    await getUserActData({act_id: act.id});
    await this.flashMessage({message: "Bubble Sheet submitted", type: 'success'});
    this.setState({ bubbleSheet: { 'English': false, 'Mathematics': false, 'Reading': false, 'Science': false } });
  }

  saveTimedUnTimed = (type) => {
    const { timed } = this.state
    const { act, saveUserActData } = this.props;
    saveUserActData({ timed: { ...timed, [type]: !timed[type] }, act_id: act.id });
  }

  toggleRealMockSwitch = () => {
    const { act } = this.props;
    if (act?.math_mock_test_publish && act?.math_real_test_publish){
      this.setState({ isMock: !this.state.isMock })
    } else if(act?.math_mock_test_publish){
      this.setState({ isMock: true });
    } else if(act?.math_real_test_publish){
      this.setState({ isMock: false });
    }
  }

  toggleTestSwitch = () => {
    const { act } = this.props;
    if (act?.math_mock_test_publish && act?.math_real_test_publish){
      this.setState({ isTest: !this.state.isTest, isMock: false  });
    } else if(act?.math_mock_test_publish){
      this.setState({ isTest: !this.state.isTest, isMock: true  });
    } else if(act?.math_real_test_publish){
      this.setState({ isTest: !this.state.isTest, isMock: false  });
    }

    return this.loadActTestProgress();
  }

  loadActTestProgress = async () =>{
    const { actProgressLoad } =  this.state;
    if(actProgressLoad) return true;
    const { getUserAllActsTestProgress, account, act } = this.props;
    await getUserAllActsTestProgress({ user_id: account.id, act_id: act.id })
    this.setState({actProgressLoad: true});
  }

  deleteAct = () =>{
    const { act, deleteACT } = this.props;
    let res = window.confirm(`Are you sure you want to delete ACT ${act.title}?`)
    if (res) {
      deleteACT(act.id);
    }
  }

  updateAct = () => {
    const { act, openDialog } = this.props;
    const { english, math, reading, science } = act.marks;
    const numberInput = {type: "number", pattern: /^[0-9]?$|^[1-2][0-9]?$|^[3][0-6]$/};
    openDialog(FORM_DIALOG_BOX, {
      title: ()=> "Edit ACT",
      options: {
        "Title": {type: "text", name: "title", value: act.title, pattern: /^\S.*/},
        "English": {...numberInput, name: "english", value: english.toString(), group: "group1"},
        "Math": {...numberInput, name: "math", value: math.toString(), group: "group1"},
        "Reading": {...numberInput, name: "reading", value: reading.toString(), group: "group2"},
        "Science": {...numberInput, name: "science", value: science.toString(), group: "group2"}
      },
      createAction: (params) => this.updateACT(params)
    });
  }

  updateACT(params) {
    const { act, updateACT } = this.props;
    const oldParams = this.parseParams({title: act.title, ...act.marks});
    const newParams = this.parseParams(params);
    if(!_.isEqual(oldParams,newParams)){
      updateACT(act.id,newParams);
    }
  }


  parseParams = ({title, english, math, reading, science}) =>{
    return({title, marks: {
        english: parseInt(english), math: parseInt(math),
        reading: parseInt(reading), science: parseInt(science)
      }});
  }

  render() {
    const { act, account, actProblemSetTypeProgress, actSequenceTypeProgress, userActsScores, userActsProgress } = this.props;
    const { selectWrong, bubbleSheet, selectUncertain, hidden, showDragHandle } = this.state;
    const recordHash = GetCommonHash(this);
    return (
      <div className={
        (Object.values(selectWrong).includes(true) || Object.values(selectUncertain).includes(true) || Object.values(bubbleSheet).includes(true)) ?
            "home-sat-item backDropShownCls" : 'home-sat-item'
      }>
        <div className={`satView_header d-flex align-items-center flex-wrap w-100 ${hidden ? "" : "open"}`}>
          <div className="hide-menu" onClick={this.hideContent} style={{marginRight: 5}}>
            <h2 className="sat_title">{act.title}</h2>
          </div>
          <ActHeaderView recordHash={recordHash}
                         userActsScores={userActsScores}
                         userActsProgress={userActsProgress}
                         gradingModeToggleSwitch={this.gradingModeToggleSwitch}
                         toggleTestSwitch={this.toggleTestSwitch}
                         toggleRealMockSwitch={this.toggleRealMockSwitch}
                         actProblemSetTypeProgress={actProblemSetTypeProgress}
                         actSequenceTypeProgress={actSequenceTypeProgress}/>

          {
            act.type === "RealAct" && !account.tutor_login &&
                <>
                  <span className="ml-auto margin-right-15">
                    <div className="row">
                      <SVG onClick={() => this.updateAct()} name="pencil" className="div_icon"/>
                      <SVG onClick={() => this.deleteAct()} name="trash" className="div_icon"/>
                    </div>
                  </span>
                </>
          }

          {
            !showDragHandle && act.type === "TestAct" &&
            <span className="icon ml-auto" onClick={this.hideContent} style={{ cursor: "pointer" }}>Arrow icon</span>
          }
        </div>
        {this.hiddenContent(recordHash)}
      </div>
    );
  }
}

function GetCommonHash({state, props}) {
  const { isMock, isTest, grading_mode, hidden } = state;
  const { act, actQuestions, actTestScores } = props;
  let EnglishArr = [], MathematicsArr = [], ReadingArr = [], ScienceArr = [];
  const currentActQuestions = actQuestions?.[act.id];
  if(currentActQuestions){
    EnglishArr = currentActQuestions.filter(x => x.act_id === act.id && x.type === 'English');
    MathematicsArr = currentActQuestions.filter(x => x.act_id === act.id && x.type === 'Mathematics');
    ReadingArr = currentActQuestions.filter(x => x.act_id === act.id && x.type === 'Reading');
    ScienceArr = currentActQuestions.filter(x => x.act_id === act.id && x.type === 'Science');
  }

  const sectionQuestions = { English: EnglishArr, Mathematics: MathematicsArr, Reading: ReadingArr, Science: ScienceArr };
  const sectionHeaders = { English: "English", Mathematics: "Mathematics", Reading: "Reading", Science: "Science" };

  let totalTestMathScore = 0, correctTestQuestions = [], uncertainTestQuestions = [];

  const actTestProgressData = actTestScores.find(dd => dd.act_id === act.id && dd.real_test === !isMock);
  if(actTestProgressData && isTest){
    totalTestMathScore = actTestProgressData.math_score;
    correctTestQuestions = actTestProgressData.correct_question_ids;
    uncertainTestQuestions = actTestProgressData.uncertain_question_ids;
  }

  return({
    isMock, act, actQuestions, totalTestMathScore, grading_mode, hidden, sectionQuestions,
    correctTestQuestions, uncertainTestQuestions, sectionHeaders, isTest
  })
}

const ActHeaderView = (props) =>{
  const {
    recordHash: {
      isMock, act, totalTestMathScore, grading_mode, hidden, isTest
    },
    toggleRealMockSwitch, gradingModeToggleSwitch, toggleTestSwitch, actProblemSetTypeProgress,
    actSequenceTypeProgress, userActsScores, userActsProgress
  } = props;

  const actScore = userActsScores.find(dd => dd.act_id === act.id);
  let totalEnglishScore  = 0, totalMathScore  = 0, totalReadingScore  = 0, totalScienceScore = 0, grandScore = 0;

  if(act.type === "RealAct") {
    const { english, math, reading, science } = act.marks;
    totalEnglishScore = english;
    totalMathScore = math;
    totalReadingScore = reading;
    totalScienceScore = science;
    grandScore = Math.round((english + math + reading + science)/4);
  } else if(actScore){
    totalEnglishScore = actScore.total_english_score;
    totalMathScore = actScore.total_math_score;
    totalReadingScore = actScore.total_reading_score;
    totalScienceScore = actScore.total_science_score;
    grandScore = actScore.grand_score;
  }
  let testMathScore = totalTestMathScore, englishScore = totalEnglishScore, mathScore = totalMathScore,
      readingScore = totalReadingScore, scienceScore = totalScienceScore, compositeScore = grandScore;

  const compositeScoreArray = [totalReadingScore, totalEnglishScore, totalMathScore, totalScienceScore];

  if(!(act?.score?.length > 0) && act.type === "TestAct"){
    testMathScore = `${totalTestMathScore}/60`;
    englishScore = `${totalEnglishScore}/75`;
    mathScore = `${totalMathScore}/60`;
    readingScore = `${totalReadingScore}/40`;
    scienceScore  = `${totalScienceScore}/40`;
    compositeScore = `${grandScore}/215`;
  }

  const { math_mock_test_publish, math_real_test_publish } = act;

  let overAllPercentage;
  if(act.progress_by_problemset){
    overAllPercentage = actProblemSetTypeProgress && actProblemSetTypeProgress[act.id] && actProblemSetTypeProgress[act.id]['OverAll'];
  } else {
    overAllPercentage = actSequenceTypeProgress && actSequenceTypeProgress[act.id] && actSequenceTypeProgress[act.id]['OverAll'];
  }

  if(!overAllPercentage){
    try {
      let data = userActsProgress.find(data => data['act_id'] === act.id);
      if(data){
        overAllPercentage = Number(data['percentage']);
      }
    } catch (e) {
      overAllPercentage = 0;
    }
  }
  overAllPercentage = overAllPercentage ?? 0;

  return(
      <>
        <IfView visible={(isTest ? (totalTestMathScore !== 0) : (totalEnglishScore !== 0 || totalMathScore !== 0 || totalReadingScore !== 0  || totalScienceScore !== 0  || grandScore !== 0))}>
          <div className="bar-divider"/>
          <div className="selectButtonsClass">
            <div className="overAllScoreCls d-inline-flex align-items-start ml-5 px-4">
              <div className="scoresVal">
                <IfElseView condition={isTest}>
                  <span> <b>Math:</b> { totalTestMathScore === 0 ? '-' : testMathScore} </span>
                  <>
                    <span> <b>English:</b> {totalEnglishScore === 0 ? '-' : englishScore} </span>
                    <span> <b>Math:</b> {totalMathScore === 0 ? '-' : mathScore} </span>
                    <span> <b>Reading:</b> {totalReadingScore === 0 ? '-' : readingScore} </span>
                    <span> <b>Science:</b> {totalScienceScore === 0 ? '-' : scienceScore} </span>
                    <span> <b>Composite:</b> {compositeScoreArray.includes(0) ? '-' :  compositeScore} </span>
                  </>
                </IfElseView>
              </div>
            </div>
          </div>
        </IfView>
        <IfView visible={!isTest && overAllPercentage > 0}>
          <div className="bar-divider"/>
          <div className="d-inline-flex align-items-center" style={{width: 40, height: 40}}>
            <CircularProgressBar percentage={overAllPercentage} />
          </div>`
        </IfView>
        <IfView visible={(!hidden && (math_mock_test_publish || math_real_test_publish))}>
          <div className="bar-divider"/>
          <div className="hdr_real_test_switch">
            <div className={"header-div"}>
              <h5 className="d-inline-block text-nowrap">Test mode:</h5>
            </div>
            <Switch checked={isTest}
                    onChange={toggleTestSwitch}
                    onColor="#00C789"
                    className="ml-2"
                    height={32}
                    width={50}
                    handleDiameter={24}/>
          </div>
        </IfView>
        <IfView visible={(!hidden && isTest && ((math_mock_test_publish || math_real_test_publish)))}>
          <div className="bar-divider" />
          <div className="hdr_switch">
            <Switch checked={isMock}
                    onChange={toggleRealMockSwitch}
                    uncheckedIcon={<i className="swtch_txt unchecked">Mock</i>}
                    checkedIcon={<i className="swtch_txt checked">Real</i>}
                    offColor="#00C789"
                    onColor="#F06150"
                    height={32}
                    width={50}
                    disabled={!(math_mock_test_publish && math_real_test_publish)}
                    handleDiameter={24} />
            <label className="font-weight-bold ml-2">{isMock ? "(Mock)" : "(Real)"} </label>
          </div>
        </IfView>
        <IfView visible={!hidden && !isTest}>
          <div className="bar-divider"/>
            <div className="d-inline-flex align-items-center" style={{order: 2}}>
              <h5 className="d-inline-block text-nowrap">Grading mode:</h5>
              <Switch checked={grading_mode}
                      onChange={gradingModeToggleSwitch}
                      onColor="#00C789"
                      className="ml-2"
                      height={32}
                      width={50}
                      handleDiameter={24}/>
            </div>
        </IfView>
      </>
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ACTItem));
