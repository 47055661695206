import React, {Component} from "react";
import {connect} from "react-redux";
import BucketQuestion from "./BucketQuestion";
import * as answerActions from "../../modules/answer/action";
import "./Bucket.scss";
import {Loader} from "../helper/commonMethods";

const mapStateToProps = (state) => {
  return {
    loading: state.animation,
    answers: state.answers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAnswers: (params) => dispatch(answerActions.getAllAnswer(params)),
  };
};

class BucketQuestions extends Component {

  state = {
    selectedQuestionIds: []
  }

  createQuestion(question, answers) {
    const { createQuestionFrom, close, topicId, viewOnly } = this.props;
    if (viewOnly) {
      return null;
    }
    createQuestionFrom(question, topicId, answers);
    close();
  }

  async fetchMultipleAnswers(questions) {
    let filteredArray = [];
    for(let question of questions){
      let filtered = await this.fetchAnswers(question);
      if(filtered?.length > 0){
        for(let data of filtered){
          filteredArray.push(data);
        }
      }
    }
    return filteredArray;
  }

  fetchAnswers = async (question) => {
    const { answers , getAnswers } = this.props;
    let filtered = [];
    if (answers[question.id]) {
      filtered = Object.values(answers[question.id]);
    }
    if (filtered.length === 0) {
      filtered = await getAnswers({ params: { question_id: question.id, problemSet_id: question._id } });
    }
    return filtered;
  }

  async handleFnc(bucketQuestion) {
    const { fncType, clickFnc, topicId, close, invokedQuestion } = this.props;
    let answers = await this.fetchAnswers(bucketQuestion);
    switch (fncType) {
      case "createQuestionUnder":
        clickFnc(bucketQuestion, topicId, answers, invokedQuestion);
        close();
        break;
      case "createQuestion":
        clickFnc(bucketQuestion, topicId, answers);
        close();
        break;
      case "bucketView":
        return null;
      default:
    }
  }

  async createQuestions() {
    const { fncType, createMultiple, topicId, close, questions, invokedQuestion } = this.props;
    const { selectedQuestionIds } = this.state;
    const selectedQuestions = questions.filter((question) => selectedQuestionIds.includes(question.id));
    let answers = [];
    switch (fncType) {
      case "createQuestion":
        answers = await this.fetchMultipleAnswers(selectedQuestions);
        await createMultiple(selectedQuestions, topicId, answers);
        await close();
        break;
      case "createQuestionUnder":
        answers = await this.fetchMultipleAnswers(selectedQuestions);
        await createMultiple(selectedQuestions, topicId, answers, invokedQuestion);
        await close();
        break;
      case "bucketView":
        return null;
      default:
    }
  }

  selectUnselect = (question, remove = false) => {
    const {fncType} = this.props;
    if (fncType === 'bucketView') {
      return null;
    }
    let selectedQuestionIds = this.state.selectedQuestionIds;
    if (remove === true) {
      selectedQuestionIds = selectedQuestionIds.filter(item => item !== question.id);
    } else {
      selectedQuestionIds.push(question.id);
    }
    this.setState({selectedQuestionIds});
  }

  handleFunctionEvent = async (question, remove) => {
    const { fncType } = this.props;
    if (fncType === 'createQuestion' || fncType === "createQuestionUnder"){
      return this.selectUnselect(question, remove);
    } else {
      await this.handleFnc(question);
    }
  }

  render() {
    const { questions, loading, fncType } = this.props;
    const { selectedQuestionIds } = this.state;
    return (
        <div className="bucket-question-main-div">
          {
              ((fncType === 'createQuestion' || fncType === 'createQuestionUnder') && questions?.length > 0) &&
              <div className={"header-div"}>
                <div className={"submit-button-div"}>
                  <button onClick={() => this.createQuestions()}
                          disabled={selectedQuestionIds.length === 0 || loading}
                          className="btn btn-sm btn-primary">
                    Add Questions
                  </button>
                </div>
              </div>
          }
          <Loader loading={loading}/>
          <ul className={"linked-list"}>
            <MultipleBucketQuestion questions={questions}
                                    onClick={this.handleFunctionEvent}
                                    selectedQuestionIds={selectedQuestionIds} />
          </ul>
      </div>
    );
  }
}

const MultipleBucketQuestion = ({questions, onClick, selectedQuestionIds }) =>{
  if (!questions?.length) {
    return (<div className="no-record-div">No record Found</div>)
  }

  return(questions?.map((question, index) => {
    const selected = selectedQuestionIds.includes(question.id);
    return (
        <li onClick={() => onClick(question, selected)}
            key={index} className={`${selected ? 'selected' : ''}`}>
          <BucketQuestion question={question} />
        </li>
    );
  }));
}

export default connect(mapStateToProps, mapDispatchToProps)(BucketQuestions);