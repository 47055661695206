import {
    RECEIVE_ALL_PROBLEM_SET_ANSWERS,
    RECEIVE_PROBLEM_SET_ANSWER,
    REMOVE_PROBLEM_SET_ANSWER,
    UPDATE_PROBLEM_SET_ANSWER
} from "../constant";

export const problemSetAnswerReducer = (state = {}, action) => {
    let newState;
    let data;
    switch (action.type) {
        case RECEIVE_ALL_PROBLEM_SET_ANSWERS:
            newState = Object.assign({}, state)
            if (action.data[0]) {
                newState[action.data[0].question_id] = action.data
            }
            return newState;
        case RECEIVE_PROBLEM_SET_ANSWER:
            newState = Object.assign({}, state)
            data = action.data;
            if (!newState[data.question_id]) {
                newState[`${data.question_id}`] = [];
            }

            newState[`${data.question_id}`].push(data);
            return newState;
        case UPDATE_PROBLEM_SET_ANSWER:
            newState = Object.assign({}, state)
            data = action.data;
            let question = newState[`${data.question_id}`]
            for (let i in question) {
                if (question[i].id === data.id) {
                    question[i] = data;
                    break;
                }
            }
            return newState;

        // Object.assign is only 1 layer deep copy
        // Should use something else here as its a nested array in an object
        case REMOVE_PROBLEM_SET_ANSWER:
            newState = Object.assign({}, state)
            data = action.data;
            let newQuestion = newState[data.question_id].filter(obj => (data.id !== obj.id))
            newState[data.question_id] = newQuestion
            return newState;
        default:
            return state;
    }
}
