import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AdminQuestions from '../topics/question/AdminQuestions';
import * as satActions from '../../../../modules/sat/action';
import './AdminSatTestMain.scss';

class AdminSatTestMain extends Component {
    state = {
        title: "",
        satType: "",
        satId: ""
    }
    async componentDidMount() {
        window.scrollTo(0, 0);
        let error404 = false;
        const { match: { params: { satId, satType } }, getSat } = this.props;
        await getSat(satId).then((dd) => {
            let typeTitle = satType.includes('Reading')
                ? 'Reading' : satType.includes('Writing')
                    ? '(Writing and Language)' : satType.includes('MathNoCalc')
                        ? '(Math - No Calculator)' : '(Math - Calculator)'

            const title = `${dd.title} - ${typeTitle}`;
            this.setState({ title, satType, satId });
        }).catch(error=>{
            error404 = error.response?.status === 404;
        });
        if(error404){
            return this.props.history.push('/admin/not_found')
        }
    }

    takeBack = () => {
        const { match: { params: { satId } } } = this.props;
        this.props.history.push('/admin?sat=' + satId)
    }

    render() {
        const { title, satId, satType } = this.state;
        const { topicType } = this.props

        return (
            <div className="row" >
                <div className="content-wrapper-before gradient-45deg-indigo-purple"/>
                <div className="col s12 contentCenter_wrap">
                    <div className="container">
                        <div className="dash_top_section"/>
                        <div className="row vertical-modern-dashboard">
                            <div className="col-12">
                                <div className="card animate fadeLeft">
                                    <div className="question_sec card-content">
                                        <div className="dashtitle_sec d-flex flex-wrap align-items-center w-100">
                                            <button onClick={() => this.takeBack()} className="btn btn-default">
                                                <i className="ico backicon"/>
                                                <span className="btntxt">Back</span>
                                            </button>
                                        </div>
                                        <div className="quesHeader d-flex flex-wrap align-items-center text-center w-100">
                                            <h1 className=" quesTitle d-inline-block position-relative">
                                                {title ? title : ''}
                                            </h1>
                                        </div>

                                        {topicType && <AdminQuestions
                                            topicType={topicType}
                                            topicId={satId}
                                            satType={satType}
                                            section={topicType}
                                        />}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        );
    }
}

const mapDispatchToProps = dispatch => {
    return ({
        getSat: (id) => dispatch(satActions.getTestSat(id))
    })
}

const mapStateToProps = ({ sats }) => ({ sats })

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminSatTestMain));
