import * as dbUtil from '../../api/dbUtil';
import {API} from '../../api';
import {
    RECEIVE_ALL_SUB_SUB_TOPICS,
    RECEIVE_SUB_SUB_TOPICS,
    UPDATE_SUB_SUB_TOPICS,
    REMOVE_SUB_SUB_TOPICS,
    PUBLISH_SUB_SUB_TOPIC,
    PUBLISH_SUB_SUB_TOPIC_SEQUENCE
} from '../constant'
import { checkSessionExpired, alertPushMessage } from "..";

const receiveAll = (data) => ({type: RECEIVE_ALL_SUB_SUB_TOPICS, data});
const receive = (data) => ({type: RECEIVE_SUB_SUB_TOPICS, data});
const updateTest = (data) => ({type: UPDATE_SUB_SUB_TOPICS, data});
const removeTest = (data) => ({type: REMOVE_SUB_SUB_TOPICS, data});
const subSubTopicPublishData = (data) => ({type: PUBLISH_SUB_SUB_TOPIC, data})
const subSubTopicSequencePublishData = (data) => ({type: PUBLISH_SUB_SUB_TOPIC_SEQUENCE, data})

export const getAllSubSubTopics = (params) => {
    return (dispatch) => {
        return dbUtil.fetchMany('subsubtopics',  params).then(({data}) => {
            dispatch(receiveAll(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getSubSubTopic = (id) => {
    return (dispatch) => {
        return dbUtil.fetch('subsubtopics', id).then(({data}) => {
            dispatch(receive(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateSubSubTopic = (id, attributes) => {
    return (dispatch) => {
        return dbUtil.update('subsubtopics', id, attributes).then(({data}) => {
            dispatch(updateTest(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const deleteSubSubTopic = (id) => {
    return (dispatch) => {
        return dbUtil.destroy('subsubtopics', id).then(({data}) => {
            dispatch(removeTest(data));
            dispatch(alertPushMessage({message: `'${data.title}' Sub-Sub-Topic deleted successfully`, type: "success"}));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const createSubSubTopic = (attributes) => {
    return (dispatch) => {
        return dbUtil.create('subsubtopics', attributes).then(({data}) => {
            dispatch(alertPushMessage({message: `${data.title} Subsubtopic created Successfully`, type: "success"}));
            dispatch(receive(data));
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateSubSubTopicPositions = (subsubtopics) => {
    return (dispatch) => {
        return API.put("/subsubtopics/update_positions", subsubtopics).then(({data}) => {
            dispatch(receiveAll(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const subSubTopicPublish = (attributes) => {
    return (dispatch) => {
        return API.post('/subsubtopics/publish_unpublish', attributes).then(({data}) => {
            dispatch(subSubTopicPublishData(data))
            dispatch(alertPushMessage({message: `'${data.title}' Sub-Sub-Topic ${!data.isPublish ? 'Published' : 'Unpublished'}`, type: "success"}));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}


export const subSubTopicSequencePublish = (attributes) => {
    return (dispatch) => {
        return API.post('/subsubtopics/sequence_publish_unpublish', attributes).then(({data}) => {
            dispatch(subSubTopicSequencePublishData(data))
            dispatch(alertPushMessage({message: `'${data.title}' Sub-Sub-Topic Sequence ${!data.isSequencePublish ? 'Published' : 'Unpublished'}`, type: "success"}));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}
