import React, { Component } from 'react';
import './HomeSATList.scss';
import SortableHomeSAT from './SortableSAT'

class SATList extends Component {
    render() {
        const { sats, loading, updateUserSatsPositions, showDragHandle } = this.props;
        if(sats.length > 0){
            return(<SortableHomeSAT sats={sats}
                                    loading={loading}
                                    showDragHandle={showDragHandle}
                                    updateUserSatsPositions={updateUserSatsPositions}/>);
        }
        return null;
    }
}

export default SATList;
