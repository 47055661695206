export default (style = {}) => {
    const customStyleMap = {
        'FONTSIZE_10': {
            fontSize: '10px',
        },
        'FONTSIZE_12': {
            fontSize: '12px',
        },
        'FONTSIZE_14': {
            fontSize: '14px',
        },
        'FONTSIZE_15': {
            fontSize: '15px',
        },
        'FONTSIZE_16': {
            fontSize: '16px',
        },
        'FONTSIZE_17': {
            fontSize: '17px',
        },
        'FONTSIZE_18': {
            fontSize: '18px',
        },
        'FONTSIZE_19': {
            fontSize: '19px',
        },
        'FONTSIZE_20': {
            fontSize: '20px',
        },
        'FONTSIZE_21': {
            fontSize: '21px',
        },
        'FONTSIZE_22': {
            fontSize: '22px',
        },
        'FONTSIZE_23': {
            fontSize: '23px',
        },
        'FONTSIZE_24': {
            fontSize: '24px',
        },
        'FONTSIZE_25': {
            fontSize: '25px',
        },
        'FONTSIZE_26': {
            fontSize: '26px',
        },
        'FONTSIZE_27': {
            fontSize: '27px',
        },
        'FONTSIZE_28': {
            fontSize: '28px',
        },
        'FONTSIZE_29': {
            fontSize: '29px',
        },
        'FONTSIZE_30': {
            fontSize: '30px',
        },
        'FONTSIZE_32': {
            fontSize: '32px',
        },
        'FONTSIZE_36': {
            fontSize: '36px',
        },
        'FONTSIZE_48': {
            fontSize: '48px',
        },
        'FONTSIZE_56': {
            fontSize: '56px',
        },
        'FONTSIZE_72': {
            fontSize: '72px',
        },
    }

    return {
        customStyleMap,
    }
}
