import React, { Component } from "react";
import _ from "lodash";
import { IN_A_ROW_CORRECT_COUNT } from "../../../constants";
import {Link, withRouter} from "react-router-dom";
import * as assignmentActions from "../../../modules/assignment/action";
import { connect } from "react-redux";
import { PaginationFooter } from "../../../utilities/admin.paginationFooter";
import * as alertActions from "../../../modules/alert/action";
import * as storeTemporaryActions from "../../../modules/storeTemporary/action";
import { Helmet } from "react-helmet";
import "./Assignment.scss";
import * as config from "../../../config";

class Assignment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            limit: 8
        }
        const { history, setAssignmentData } = props;
        if(history.location?.params?.completeAssignment === true){
            setAssignmentData("openedView","completed");
        }
        this.unAssignAssignment = this.unAssignAssignment.bind();
    }

    async componentDidMount() {
        const { getAllAssignments, history, setAssignmentDefaultData } = this.props;
        if(!(history.location?.params?.assignment)){
            setAssignmentDefaultData();
        }
        let assignments = await getAllAssignments();
        this.checkAndUpdateStatus(assignments);
    }

    checkAndUpdateStatus = (assignments) => {
        const { updateAssignment } = this.props;
        if (assignments && assignments.length > 0) {
            _.map(assignments, (assignment, i) => {
                if(assignment.progress && assignment.status !== "completed"){
                    let {questionCorrect, completePercentage} = GetVariable(assignment);
                    if(assignment.assignmentable_type === "ProblemSet"){
                        if(questionCorrect >= IN_A_ROW_CORRECT_COUNT){
                            updateAssignment(assignment.id,{status: "completed"});
                        }
                    } else {
                        if(completePercentage >= 100){
                            updateAssignment(assignment.id,{status: "completed"});
                        }
                    }
                }
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { assignments: Assignments, setAssignmentData, assignmentData: {currentPage, completedPage} } = this.props;
        if(prevProps.assignments !== Assignments){
            const { assignments, currentAssignments, completedAssignments } = getCurrentAndCompletedAssignment(Assignments);
            if (assignments?.length > 0) {
                const { limit } = this.state;
                if (currentAssignments?.length <= ((currentPage - 1) * limit)) {
                    setAssignmentData('currentPageNumber',currentPage > 0 ? currentPage : currentPage - 1 );
                }
                if (completedAssignments?.length <= ((completedPage - 1) * limit)) {
                    setAssignmentData('completedPageNumber',completedPage > 0 ? completedPage : completedPage - 1 );
                }
            }
        }
    }

    unAssignAssignment = async (id) => {
        const { deleteAssignment } = this.props;
        let res = window.confirm("Are you sure?")
        if (res) {
            await deleteAssignment(id);
        }
    }

    flashMessage = (payload, timeout = 5000) => {
        const {alertPush, alertDelete} = this.props;
        alertPush(payload);
        setTimeout(() => alertDelete(),timeout);
    }

    render() {
        const { account, assignments, setAssignmentData, assignmentData: {currentPage, completedPage, openedView} } = this.props;
        const { limit } = this.state;
        const { currentAssignments, completedAssignments } = getCurrentAndCompletedAssignment(assignments);
        return(
            <div className="card animate fadeLeft">
                <div className="assignment_sec card-content p-0">
                    <div className="assignmentTabs_wrap">
                        <div className="col s12 p-0">
                            <ul className="tabs nav-tabs">
                                <li className="tab col nav-item">
                                    <a className={openedView === 'current' ? "active" : ''}
                                       onClick={()=> setAssignmentData("openedView","current")}
                                       href="#current_assignments">
                                        Current Assignments
                                    </a>
                                </li>
                                <li className="tab col nav-item">
                                    <a className={openedView === 'completed' ? "active" : ''}
                                       onClick={()=> setAssignmentData("openedView","completed")}
                                       href="#completed_assignments">
                                        Completed Assignments
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div id="current_assignments" className="col s12 tabs_content">
                            <AssignmentTable type={"current"}
                                             account={account}
                                             show={openedView === 'current'}
                                             assignments={currentAssignments}
                                             pageNo={currentPage}
                                             setAssignmentData={setAssignmentData}
                                             unAssignAssignment={this.unAssignAssignment}
                                             limit={limit}/>
                        </div>
                        <div id="completed_assignments" className="col s12 tabs_content">
                            <AssignmentTable type={"completed"}
                                             account={account}
                                             show={openedView === 'completed'}
                                             assignments={completedAssignments}
                                             pageNo={completedPage}
                                             setAssignmentData={setAssignmentData}
                                             unAssignAssignment={this.unAssignAssignment}
                                             limit={limit}/>
                        </div>
                    </div>
                    <Helmet>
                        <script src={`${config.appUrl()}/js/custom-script.min.js`}/>
                    </Helmet>
                </div>
            </div>
        )
    }
}

const AssignmentTable = ({type, account, assignments, pageNo, setAssignmentData, unAssignAssignment, limit, show }) =>{
    if(!show) return null;
    return(
        <>
            <div className="col s12 p-0 dataTables_wrapper table table-responsive">
                <table className="table dt-responsive nowrap" style={{ width: "100%" }}>
                    <thead>
                    <tr>
                        <th>Title</th>
                        <th>Topic</th>
                        <th>Type</th>
                        <th className="text-center">Due</th>
                        <th className="text-center">Progress</th>
                        <th colSpan={account.is_super_admin ? 2 : 1}>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <AssignmentView account={account} assignments={assignments}
                                    pageNo={pageNo}
                                    limit={limit}
                                    unAssignAssignment={(id) => unAssignAssignment(id)}/>
                    </tbody>
                </table>
            </div>
            <div className="appCus_pagination d-inline-flex w-100">
                {assignments?.length > 0 &&
                <PaginationFooter
                    getPageData={(pageNo) => setAssignmentData((type === 'current' ? 'currentPageNumber' : 'completedPageNumber'), pageNo)}
                    pageNo={pageNo}
                    totalRecords={assignments?.length}
                    limit={limit}/>
                }
            </div>
        </>
    )
}

const AssignmentView = ({ account ,assignments, pageNo, limit, unAssignAssignment }) =>{
    if (assignments && assignments.length > 0) {
        let lastIndex = pageNo * limit, startIndex = ((pageNo - 1) * limit);
        let viewAbleAssignments = assignments.filter((data,index) => (index >= startIndex && index < lastIndex) );
        const todayDate = (new Date()).setHours(23, 59, 59, 999);
        return _.map(viewAbleAssignments, (assignment, i) => {
            const {
                title: assignmentTitle, topic_type, assignmentable_type, assignment_date,
                progress: assignmentProgress, id: assignmentId
            } = assignment;
            let formatedAssigmentDate = new Date(assignment_date);
            formatedAssigmentDate.setHours(23, 59, 59, 999);
            let { questionsNotCorrect, questionCorrect, completePercentage } = GetVariable(assignment);
            return (
                <tr key={i}>
                    <td>
                        {assignmentTitle}
                    </td>
                    <td>
                        {topic_type.toUpperCase()}
                    </td>
                    <td>
                        {assignmentable_type === "ProblemSet" ? "Problem set" : "Sequence"}
                    </td>
                    <td className="text-center">
                        <span className={`${formatedAssigmentDate < todayDate ? "label label-danger" : ''} word-wrap`}>
                            {assignment_date}
                        </span>
                    </td>
                    <td>
                        {assignmentProgress ?
                            <div className="d-flex justify-content-start align-items-center nowrap position-relative">
                                {
                                    assignmentable_type === "ProblemSet" ?
                                        <>
                                            <ProgressBox active={'active'} totalBox={questionCorrect && (questionCorrect >= IN_A_ROW_CORRECT_COUNT ? IN_A_ROW_CORRECT_COUNT : questionCorrect)}/>
                                            <ProgressBox totalBox={questionsNotCorrect}/>
                                            {questionCorrect >= IN_A_ROW_CORRECT_COUNT && <i className="fa fa-check-square-o checkMarkTrue ml-2" />}
                                        </>
                                        :
                                        <>
                                            {`${(completePercentage < 100 ? completePercentage : 100)}%`}
                                            {completePercentage >= 100 && <i className="fa fa-check-square-o checkMarkTrue ml-2" />}
                                        </>
                                }
                            </div>
                            :
                            <div className="text-center">-</div>
                        }
                    </td>
                    <td colSpan={account.is_super_admin ? 2 : 1}>
                        {(account.tutor_login || account.is_super_admin) &&
                            <button className="btn btn-default btn-sm m-1" onClick={() => unAssignAssignment(assignmentId)} disabled={!account.status}>
                                Unassign
                            </button>}
                        {!account.tutor_login && <AttemptAssignmentButton assignment={assignment} completePercentage={completePercentage} questionCorrect={questionCorrect} />}
                    </td>
                </tr>
            )
        });
    }

    return(
        <tr>
            <td colSpan={account.is_super_admin ? 7 : 6} className="text-center" style={{ color: "grey", fontSize: "15px" }}>
                No assignments found
            </td>
        </tr>
    )
}

function getCurrentAndCompletedAssignment(assignments){
    try {
        assignments = (assignments?.filter((data) => data.published ) ?? []);
    } catch (e) {
        assignments = [];
    }
    const currentAssignments = (assignments.filter((data) => data.status !== 'completed' )) ?? [];
    const completedAssignments = (assignments.filter((data) => data.status === 'completed' )) ?? [];
    return({assignments, currentAssignments, completedAssignments});
}
const ProgressBox = ({totalBox, active = ''}) =>{
    if(!totalBox) return null;
    return _.times(totalBox, (key) => {
        return <span key={key} className={`psIconCls ${active ?? ''}`}/>
    });
}

const AttemptAssignmentButton = ({assignment, completePercentage, questionCorrect}) => {
    let url
    if (assignment.topic_type === "sat") {
        url = `/home/sat/${assignment.assignmentable_id}`
    } else if (assignment.topic_type === "act") {
        url = `/home/act/${assignment.assignmentable_id}`
    } else if (["reading", "grammar", "math"].includes(assignment.topic_type)) {
        if (assignment.assignmentable_type === "Subsubtopic") {
            url = `/home/${assignment.topic_type}/subsubtopic/${assignment.assignmentable_id}/${assignment.subtopic_id}`
        } else {
            url = `/home/${assignment.topic_type}/${assignment.assignmentable_id}/${assignment.test_id}`
        }
    }
    if (assignment.assignmentable_type === "ProblemSet") {
        url = `/home/problemset/${assignment.assignmentable_id}/${(assignment.sat_question_id ?? (assignment.act_question_id ?? assignment.subsubtopic_id))}`
    }
    url = url + '?dashboard=true'
    let buttonLabel = "Start";
    if (assignment.progress) {
        if (assignment.assignmentable_type === "ProblemSet") {
            buttonLabel = questionCorrect >= IN_A_ROW_CORRECT_COUNT ? "Redo" : "Continue";
        } else {
            if (completePercentage < 100) {
                buttonLabel = "Continue";
            } else {
                return null;
            }
        }
    }
    return  <Link to={url} className="btn btn-default btn-sm"><span className="btntxt">{buttonLabel}</span> <i className="ico playicon"/> </Link>
}

function GetVariable(assignment){
    let questionsNotCorrect, questionCorrect = '', completePercentage = 0;
    if (assignment.progress) {
        questionCorrect = assignment.progress.total_correct
        completePercentage = assignment.progress.percent_complete
        questionsNotCorrect = IN_A_ROW_CORRECT_COUNT - questionCorrect
        completePercentage = completePercentage > 100 ? 100 : completePercentage;
    }
    return({questionsNotCorrect, questionCorrect, completePercentage})
}

const mapStateToProps = state => {
    return ({
        account: state.account,
        assignments: state.assignments,
        assignmentData: state.temporaryData.assignmentData
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        updateAssignment: (id,params) => dispatch(assignmentActions.updateAssignment(id, params)),
        getAllAssignments: () => dispatch(assignmentActions.getAllAssignments()),
        deleteAssignment: (id) => dispatch(assignmentActions.deleteAssignment(id)),
        alertPush: (payload) => dispatch(alertActions.alertPush(payload)),
        alertDelete: () => dispatch(alertActions.alertDelete()),
        setAssignmentData: (type,data) => dispatch(storeTemporaryActions.setAssignmentData(type,data)),
        setAssignmentDefaultData: () => dispatch(storeTemporaryActions.setAssignmentDefaultData())
    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Assignment));
