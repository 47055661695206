import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import * as settingActions from '../../../../modules/setting/action'
import Editor from '../../../editor/Editor'
import ReadOnlyEditor from '../../../editor/ReadOnlyEditor'
import './settings.scss';
import { sheetParsedData, ShowUploads, UploadExcel } from "../common/CommonMethod";
import {VIEW_CONVERTED_SCORE_DIALOG_BOX} from "../../../dialogs/dialogs";
import * as dialogActions from "../../../../modules/dialog/action";


class Settings extends Component {

  constructor(props) {
    super(props)
    this.state = {
      editorState: this.props.setting && this.props.setting.introduction_video ? this.props.setting.introduction_video : null,
      edit: false
    }

    this.showScores = this.showScores.bind(this);
  }

  async componentDidMount() {
    window.scrollTo(0,0);
    const { getSetting } = this.props
    await getSetting()
    this.setState({ showIntroVideo: true })
  }

  submitAddIntroVideo = async () => {
    const { editorState } = this.state;
    const { setSetting } = this.props;
    await setSetting({ introduction_video: editorState });
    this.showHideAddVideoSection()
  }
  showHideAddVideoSection = () => {
    this.setState({ edit: !this.state.edit })
  }
  updateEditorState = (editorState) => {
    this.setState({ editorState })
  }

  uploadSatPercentileScoreSheet = (event) => {
    const { setSetting } = this.props;
    let f = event.target.files[0];
    const reader = new FileReader();
    reader.onload = async (evt) => { // evt = on_file_select event
      const sat_percentile_score = sheetParsedData(evt.target.result);
      await setSetting({ sat_percentile_score });
    };
    reader.readAsArrayBuffer(f);
  }

  uploadActPercentileScoreSheet = (event) => {
    const { setSetting } = this.props;
    let f = event.target.files[0]
    const reader = new FileReader();
    reader.onload = async (evt) => { // evt = on_file_select event
      /* Parse data */
      const act_percentile_score = sheetParsedData(evt.target.result);
      await setSetting({ act_percentile_score });
    };
    reader.readAsArrayBuffer(f);
  }

  showScores = (score, title, columns) => {
    const { openDialog } = this.props;
    return openDialog(VIEW_CONVERTED_SCORE_DIALOG_BOX, {title, score, columns})
  }

  render() {
    const { account, setting } = this.props;
    console.log(setting, "settings");
    const { edit } = this.state;
    return (
      <div className="row">
        <div className="content-wrapper-before gradient-45deg-indigo-purple"/>
        <div className="col s12">
          <div className="container">
            <div className="dash_top_section">
              <div className="dash_header d-flex flex-wrap align-items-center">
                <h2 className="dash_title mr-auto w-auto my-2">App Settings</h2>
              </div>
              <div className="row vertical-modern-dashboard">
                <div className="col s12 satAct_content_sec">
                  <div className="card animate fadeLeft w-100">
                    <div className="users_sec card-content p-0 d-flex">
                      {edit ?
                        <div className="setting_contentWrap d-flex flex-wrap w-100 position-relative">
                          <div className="card_header d-flex flex-wrap w-100 position-relative">
                            <h3 className="d-inline-block mr-auto">Introduction Videos</h3>
                          </div>
                          <div className="videoEditorcls">
                            <Editor
                              content={this.props.setting && this.props.setting.introduction_video}
                              updateEditorState={this.updateEditorState} />
                            <div className="buttons mt-1">
                              <button className="btn btn-sm btn-primary" onClick={() => this.submitAddIntroVideo()}> Submit </button>
                              <button className="btn btn-sm btn-danger ml-2" onClick={() => this.showHideAddVideoSection()}> Cancel </button>
                            </div>
                          </div>
                        </div>
                        :
                        <div className="setting_contentWrap d-flex flex-wrap w-100 position-relative">
                          <div className="card_header d-flex flex-wrap w-100 position-relative">
                            <h3 className="d-inline-block mr-auto">Introduction Videos</h3>
                            <div className="btn-grup d-inline-flex">
                              <button className="btn btn-sm btn-primary" onClick={() => this.showHideAddVideoSection()}>
                                Edit
                              </button>
                            </div>
                          </div>
                          <div className="videoExpCls ">
                            <div className="view-question--inner">
                              {this.props.setting && this.props.setting.introduction_video ? <ReadOnlyEditor viewAble={true} content={this.props.setting.introduction_video} /> : <i>No videos available.</i>}
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                    <div className="users_sec card-content p-0 d-flex">
                      <div className="setting_contentWrap d-flex flex-wrap w-100 position-relative">
                        <div className="card_header d-flex flex-wrap w-100 position-relative">
                          <h3 className="d-inline-block mr-auto">Sat Percentile Score</h3>
                          <div className="btn-grup d-inline-flex">
                            <ShowUploads onViewButton={this.showScores}
                                         account={account}
                                         record={setting && setting.sat_percentile_score}
                                         title={"Sat Percentile Score"}
                                         columns={9}
                                         ViewButtonTitle={'View Score'} />
                            <UploadExcel onUpload={(e) => this.uploadSatPercentileScoreSheet(e)}/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="users_sec card-content p-0 d-flex">
                      <div className="setting_contentWrap d-flex flex-wrap w-100 position-relative">
                        <div className="card_header d-flex flex-wrap w-100 position-relative">
                          <h3 className="d-inline-block mr-auto">Act Percentile Score</h3>
                          <div className="btn-grup d-inline-flex">
                            <ShowUploads onViewButton={this.showScores}
                                         account={account}
                                         record={setting && setting.act_percentile_score}
                                         title={"Act Percentile Score"}
                                         columns={10}
                                         ViewButtonTitle={'View Score'} />
                            <UploadExcel onUpload={(e) => this.uploadActPercentileScoreSheet(e)}/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


const mapStateToProps = state => {
  return ({
    account: state.account,
    setting: state.setting
  })
}

const mapDispatchToProps = dispatch => {
  return ({
    setSetting: (params) => dispatch(settingActions.setSetting(params)),
    getSetting: () => dispatch(settingActions.getSetting()),
    openDialog: (config, options) => dispatch(dialogActions.open(config, options)),
  })
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Settings));