import {
    ActSectionDialog,
    AddExistingProblemSetDialog,
    AddToolTipDialog,
    AddUserDialog,
    AssignmentDialog,
    BucketDialog,
    CreateAnswerDialog,
    CreateBucketProblemSetDialog,
    CreateNewTopicDialog,
    CreateQuestionDialog,
    IntroVideoDialog,
    LinkedProblemSetDialog,
    LinkedQuestionDialog,
    LinkTutorWithStudentDialog,
    MathSectionDialog,
    PreviewQuestionDialog,
    ProblemSetDialog,
    ProblemSetNotesDialog,
    RecycleBinDialog,
    ReportProblemDialog,
    SatSectionDialog,
    ViewSatConvertedScoreDialog,
    FormDialog
} from "./components";

export const CREATE_QUESTION_DIALOG_BOX = {
    component: CreateQuestionDialog,
    title: () => 'Create a Question',
    className: 'create-question-dialog',
}

export const CREATE_ANSWER_DIALOG_BOX = {
    component: CreateAnswerDialog,
    title: () => 'Create an Answer',
    className: 'create-answer-dialog',
}

export const BUCKET_DIALOG_BOX = {
    component: BucketDialog,
    title: () => 'Bucket',
    className: 'bucket-dialog'
}

export const LINK_QUESTION_DIALOG_BOX = {
    component: LinkedQuestionDialog,
    title: () => 'Link Question',
    className: 'link-question-dialog'
}

export const PROBLEM_SETS_DIALOG_BOX = {
    component: ProblemSetDialog,
    title: () => 'Problem Sets',
    className: 'problem-set-dialog'
}

export const LINK_PROBLEM_SET_DIALOG_BOX = {
    component: LinkedProblemSetDialog,
    title: () => 'Link Problem Sets',
    className: 'problem-set-dialog'
}

export const PREVIEW_QUESTION_DIALOG_BOX = {
    component: PreviewQuestionDialog,
    title: () => 'Preview Question',
    className: 'preview-question'
}

export const MATH_SECTION_DIALOG_BOX = {
    component: MathSectionDialog,
    title: () => 'Existing Problem-set',
    className: 'problem-set-dialog'
}

export const EXISTING_PROBLEM_SET_DIALOG_BOX = {
    component: AddExistingProblemSetDialog,
    title: () => 'Add Existing Problem-set',
    className: 'problem-set-dialog'
}

export const SAT_SECTION_DIALOG_BOX = {
    component: SatSectionDialog,
    title: () => 'Preview ',
    className: 'problem-set-dialog'
}

export const CREATE_PROBLEM_SET_DIALOG_BOX = {
    component: CreateBucketProblemSetDialog,
    title: () => 'Bucket Problem Sets',
    className: 'problem-set-dialog'
}

export const PROBLEM_SET_NOTES_DIALOG_BOX = {
    component: ProblemSetNotesDialog,
    title: () => 'Problem Set Notes ',
    className: 'problem-set-dialog'
}

export const VIEW_CONVERTED_SCORE_DIALOG_BOX = {
    component: ViewSatConvertedScoreDialog,
    title: () => 'View SAT Converted Score',
    className: 'problem-set-dialog'
}

export const LINK_TUTOR_WITH_STUDENT_DIALOG_BOX = {
    component: LinkTutorWithStudentDialog,
    title: () => 'Link Tutor with Students',
    className: 'problem-set-dialog'
}

export const ASSIGNMENT_DIALOG_BOX = {
    component: AssignmentDialog,
    title: () => 'Select Assignment Date',
    className: 'problem-set-dialog'
}

export const INTRO_VIDEO_DIALOG_BOX = {
    component: IntroVideoDialog,
    title: () => 'Introduction Videos',
    className: 'problem-set-dialog'
}

export const ACT_SECTION_DIALOG_BOX = {
    component: ActSectionDialog,
    title: () => 'Preview ',
    className: 'problem-set-dialog'
}

export const RECYCLE_BIN_DIALOG_BOX = {
    component: RecycleBinDialog,
    title: () => 'Recycle Bin',
    className: 'recycle-bin-dialog'
}

export const ADD_USER_DIALOG_BOX = {
    component: AddUserDialog,
    title: () => 'Add New User',
    className: 'problem-set-dialog'
}

export const CREATE_NEW_DIALOG_BOX = {
    component: CreateNewTopicDialog,
    title: () => 'Create new',
    className: 'problem-set-dialog'
}

export const REPORT_PROBlEM_DIALOG_BOX = {
    component: ReportProblemDialog,
    title: () => 'Report error for this question',
    className: "report-problem"
}

export const OPEN_TOOLTIP_DIALOG_BOX = {
    component: AddToolTipDialog,
    title: () => 'ToolTip ',
    className: 'problem-set-dialog'
}

export const FORM_DIALOG_BOX = {
    component: FormDialog,
    title: () => 'Form',
    className: 'problem-set-dialog'
}
