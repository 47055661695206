import * as dbUtil from '../../api/dbUtil';
import {API} from '../../api';
import {
    PUBLISH_ACT_QUESTION,
    RECEIVE_ACT_QUESTION,
    RECEIVE_ALL_ACT_QUESTIONS,
    UPDATE_ACT_QUESTION,
    UPDATE_ACT_QUESTION_VIDEO_EXPLANATION
} from "../constant";
import { checkSessionExpired } from "..";

const receiveAll = (data) => ({type: RECEIVE_ALL_ACT_QUESTIONS, data});
const receive = (data) => ({type: RECEIVE_ACT_QUESTION, data});
const updateTest = (data) => ({type: UPDATE_ACT_QUESTION, data});
const actQuestionPublishUnpublish = (data) => ({type: PUBLISH_ACT_QUESTION, data});
const receiveUpdateActQuestionVideo = (data) => ({type: UPDATE_ACT_QUESTION_VIDEO_EXPLANATION, data});

export const getAllActQuestions = (params) => {
    return (dispatch) => {
        return dbUtil.fetchMany('act_questions', params).then(({data}) => {
            dispatch(receiveAll(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getActQuestion = (id) => {
    return (dispatch) => {
        return dbUtil.fetch("act_questions", id).then(({data}) => {
            dispatch(receive(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateActQuestion = (id, attributes) => {
    return (dispatch) => {
        return dbUtil.update('act_questions', id, attributes).then(({data}) => {
            dispatch(updateTest(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const publishUnPublishActQuestion = (attributes) => {
    return (dispatch) => {
        return API.post('/act_questions/publish_unpublish', attributes).then(({data}) => {
            dispatch(actQuestionPublishUnpublish(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}


export const updateActQuestionVideo = (attributes) => {
    return (dispatch) => {
        return API.post('/act_questions/update_video_explanation', attributes).then(({data}) => {
            dispatch(receiveUpdateActQuestionVideo(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}


