import React, {Component} from 'react';
import SVG from '../../SVG';
import buttonConfig from './buttonConfig'
import "./AddContentButton.scss";

class AddContentButton extends Component {
    state = {
      url: '',
      showPopover: false,
    };

    componentDidUpdate(prevProps) {
        const menuId = buttonConfig[this.props.type].menuId;
        if(prevProps.openMenu === menuId && (this.props.openMenu !== menuId)) {
            this.setState({showPopover: false})
        }
    }

    changeUrl = (evt) => {
      this.setState({ url: evt.target.value });
    }

    addVideo = () => {
        const { editorState, onChange, modifier } = this.props;
        const { url } = this.state;
        // video requires a src variable
        onChange(modifier(editorState, {src: url}))
        this.resetState();
    }

    addImage = () => {
        const { editorState, onChange } = this.props;
        onChange(this.props.modifier(editorState, this.state.url));
        this.resetState();
    }

    resetState = () => {
        this.setState({
            url: "",
            showPopover: false,
        })
    }

    togglePopover = () => {
        const {openMenuToggle, type, openMenu} = this.props;
        const {showPopover} = this.state;
        const menuId = buttonConfig[type].menuId;
        if (showPopover && openMenu === menuId) {
            openMenuToggle(null);
        } else if(showPopover) {
            this.setState({showPopover: false})
        } else {
            openMenuToggle(menuId);
            this.setState({showPopover: true})
        }

        this.setState({showPopover: showPopover ? false : true})
    }

    render() {
        const {type} = this.props;
        const {showPopover} = this.state;
        const buttonType = buttonConfig[type];
        let popoverClasses = ["popover-hide"];
        if(showPopover) {
            popoverClasses.push('show');
        }
        return (
            <div className="add-content-button">
                <SVG name={buttonType.icon} onClick={this.togglePopover}/>
                <div className={popoverClasses.join(" ")}>
                    <div className="arrow-up"></div>
                    <input
                        type="text"
                        placeholder={buttonType.placeholder}
                        onChange={this.changeUrl}
                        value={this.state.url}
                        />
                    <SVG name="plus" onClick={this[buttonType.fn]}/>
                </div>
            </div>
        );
    }
}

export default AddContentButton;
