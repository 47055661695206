import {
    SET_USER_DATA,
    LOG_OUT_USER,
    GET_USER_CORRECT_INCORRECT_QUESTIONS,
    GET_TIME_SPENT_BY_USER,
    GET_AVG_TIME_SPENT_BY_USER,
    SAVE_USER_QUESTIONS_PROGRESS,
    SAVE_USER_PROGRESS,
    GET_USER_PROGRESS,
    RESET_USER_PROGRESS,
    SAVE_USER_PROBLEM_SET_PROGRESS,
    GET_USER_PROBLEM_SET_PROGRESS,
    RESET_USER_PROBLEM_SET_PROGRESS,
    GET_ALL_USER_PROBLEM_SETS_PROGRESS, RESET_USER_DATA, LOGOUT_USER,
} from '../constant';

const defaultState = { loggedIn: false };

export const accountReducer = (state = defaultState, action) => {
    const { account } = action;
    switch (action.type) {
        case SET_USER_DATA:
            return { ...state, ...account };
        case RESET_USER_DATA:
            return account;
        case LOG_OUT_USER:
            localStorage.setItem("eventType", LOGOUT_USER);
            return { loggedIn: false };
        case SAVE_USER_PROGRESS:
            return { ...state, progress: action.data };
        case GET_USER_PROGRESS:
            return { ...state, progress: action.data };
        case RESET_USER_PROGRESS:
            return { ...state, progress: action.data };
        case SAVE_USER_PROBLEM_SET_PROGRESS:
            return { ...state, problemsetProgress: action.data };
        case GET_USER_PROBLEM_SET_PROGRESS:
            return { ...state, problemsetProgress: action.data };
        case RESET_USER_PROBLEM_SET_PROGRESS:
            return { ...state, problemsetProgress: action.data };
        case GET_ALL_USER_PROBLEM_SETS_PROGRESS:
            return { ...state, allProblemsetsProgress: action.data };
        case GET_USER_CORRECT_INCORRECT_QUESTIONS:
            return { ...state, userCorrectIncorrectQuestions: action.data }
        case GET_TIME_SPENT_BY_USER:
            return { ...state, timeSpentByUser: action.data }
        case GET_AVG_TIME_SPENT_BY_USER:
            return { ...state, avgTimeSpentByUser: action.data }
        case SAVE_USER_QUESTIONS_PROGRESS:
            return { ...state, userQuestionProgress: action.data };
        default:
            return state;
    }
};
