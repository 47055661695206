import {
    RECEIVE_ALL_ACT_MOCK_TEST_QUESTIONS,
    RECEIVE_ALL_ACT_REAL_TEST_QUESTIONS
} from "../constant";
import {combineReducers} from "redux";

const mockQuestionsReducer = (state = [], action) => {
    switch (action.type) {
        case RECEIVE_ALL_ACT_MOCK_TEST_QUESTIONS:
            let setobj = Object.assign({}, state)
            if ((action.data).length > 0) {
                let act_id = action.data[0].act_id
                if (action.data[0].act_type === "English") {
                    setobj[act_id] = { ...setobj[act_id], 'English': action.data }
                } else if (action.data[0].act_type === "Mathematics") {
                    setobj[act_id] = { ...setobj[act_id], 'Mathematics': action.data }
                } else if (action.data[0].act_type === "Reading") {
                    setobj[act_id] = { ...setobj[act_id], 'Reading': action.data }
                } else if (action.data[0].act_type === "Science") {
                    setobj[act_id] = { ...setobj[act_id], 'Science': action.data }
                }
            }
            return setobj;
        default:
            return state;
    }
}

const realQuestionsReducer = (state = [], action) => {
    switch (action.type) {
        case RECEIVE_ALL_ACT_REAL_TEST_QUESTIONS:
            let setobj = Object.assign({}, state)
            if ((action.data).length > 0) {
                let act_id = action.data[0].act_id
                if (action.data[0].act_type === "English") {
                    setobj[act_id] = { ...setobj[act_id], 'English': action.data }
                } else if (action.data[0].act_type === "Mathematics") {
                    setobj[act_id] = { ...setobj[act_id], 'Mathematics': action.data }
                } else if (action.data[0].act_type === "Reading") {
                    setobj[act_id] = { ...setobj[act_id], 'Reading': action.data }
                } else if (action.data[0].act_type === "Science") {
                    setobj[act_id] = { ...setobj[act_id], 'Science': action.data }
                }
            }
            return setobj;
        default:
            return state;
    }
}


export const actTestQuestionReducer = combineReducers({
    mock: mockQuestionsReducer,
    real: realQuestionsReducer,
});
