import React, { Component } from 'react';

// Draft JS
import { EditorState, convertFromRaw } from 'draft-js'
import katex from 'katex'
import Editor, { composeDecorators } from 'draft-js-plugins-editor'
import createImagePlugin from 'draft-js-image-plugin'
import createKatexPlugin from 'draft-js-katex-plugin'
import createMathjaxPlugin from 'draft-js-mathjax-plugin';
import createAlignmentPlugin from 'draft-js-alignment-plugin';
import createFocusPlugin from 'draft-js-focus-plugin';
import createResizeablePlugin from 'draft-js-resizeable-plugin';
import createVideoPlugin from 'draft-js-video-plugin';

// Custom Plugins
import createHighlightPlugin from '../../plugins/highlightPlugin';
import createTextColorPlugin from '../../plugins/textColorPlugin';
import createFontSizePlugin from '../../plugins/fontSizePlugin';

// CSS
import 'draft-js/dist/Draft.css';
import 'draft-js-image-plugin/lib/plugin.css'
import "./ToolTipReadOnlyEditor.scss"

const mathjaxConfig = {
    showProcessingMessages: false,
    showMathMenu: false,
    showMathMenuMSIE: false,
    preview: 'none',
}

export default class ToolTipReadOnlyEditor extends Component {
    constructor(props) {
        super(props);

        // Instantiate Editor
        const editorState = this.setEditor(this.props);
        this.state = {
            editorState
        }

        // Plugins
        const katexPlugin = createKatexPlugin({ katex });
        const focusPlugin = createFocusPlugin();
        const alignmentPlugin = createAlignmentPlugin();
        const resizeablePlugin = createResizeablePlugin();
        const mathjaxPlugin = createMathjaxPlugin(mathjaxConfig);
        const textColorPlugin = createTextColorPlugin();
        const highlightPlugin = createHighlightPlugin();
        const fontSizePlugin = createFontSizePlugin();

        const decorator = composeDecorators(
            resizeablePlugin.decorator,
            alignmentPlugin.decorator,
            focusPlugin.decorator,
        )

        const videoPlugin = createVideoPlugin({ decorator });
        const imagePlugin = createImagePlugin({ decorator });

        // Add plugins here to load into editor
        this.plugins = [
            imagePlugin,
            katexPlugin,
            mathjaxPlugin,
            focusPlugin,
            alignmentPlugin,
            resizeablePlugin,
            highlightPlugin,
            videoPlugin,
            textColorPlugin,
            fontSizePlugin,
        ];
    }

    setEditor = (props) =>{
        if (props.content) {
            return(EditorState.createWithContent(convertFromRaw(JSON.parse(props.content))));
        }
        return(EditorState.createEmpty());
    }


    componentDidUpdate(prevProps) {
        if (prevProps.content !== this.props.content) {
            const editorState = this.setEditor(this.props);
            this.setState({ editorState })
        }
    }

    // Allows Mathjax to render in ReadOnly mode
    handleChange = (editorState) => {
        this.setState({ editorState })
    }

    focus = () => {
        this.editor.focus();
    }

    render() {
        const { editorState } = this.state;
        return (
                <div className={"tooltip-read-editor-container"}
                     onClick={this.focus}>
                    <Editor
                        editorState={editorState}
                        onChange={this.handleChange}
                        readOnly={true}
                        plugins={this.plugins}
                        ref={(element) => { this.editor = element; }} />
                </div>
        );
    }
}

