import React, {Component} from 'react';
import './SectionQuestions.scss'

class SectionQuestions extends Component {
    render() {
        const {questions, header, prepareRoute} = this.props;
        return (
            <div className="section-questions">
                <h3>{header}</h3>
                <ul>
                    {questions.map(question => {
                        if(question.topic._id) {
                            return (
                                <li key={question.id}>
                                    <div onClick={() => prepareRoute(question.topic._id)}>
                                        {question.position + 1}
                                    </div>
                                </li>
                            )
                        } else {
                            return (
                                <li className="inactive" key={question.id}>
                                    <div>
                                        {question.position + 1}
                                    </div>
                                </li>
                            )
                        }
                    })}
                </ul>
            </div>
        );
    }
}

export default SectionQuestions;
