import {RECEIVE_ALL_SAT_MOCK_TEST_QUESTIONS, RECEIVE_ALL_SAT_REAL_TEST_QUESTIONS} from "../constant";
import * as dbUtil from "../../api/dbUtil";
import { checkSessionExpired } from "..";

const receiveAllSatRealTestQuestions = (data) => ({ type: RECEIVE_ALL_SAT_REAL_TEST_QUESTIONS, data });
const receiveAllSatMockTestQuestions = (data) => ({ type: RECEIVE_ALL_SAT_MOCK_TEST_QUESTIONS, data });


export const getAllSatRealTestQuestions = (params) => {
    return (dispatch) => {
        return dbUtil.fetchMany('questions', params).then(({data}) => {
            dispatch(receiveAllSatRealTestQuestions(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getAllSatMockTestQuestions = (params) => {
    return (dispatch) => {
        return dbUtil.fetchMany('questions', params).then(({data}) => {
            dispatch(receiveAllSatMockTestQuestions(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}
