import React, {Component} from 'react';
import {connect} from 'react-redux';
import SVG from '../SVG';
import * as bucketActions from '../../modules/bucket/action';
import './ClearBucket.scss';

const mapDispatchToProps = dispatch => {
    return({
        clearQuestionBucket: () => dispatch(bucketActions.clearQuestionBucket()),
        clearAnswerBucket: () => dispatch(bucketActions.clearAnswerBucket()),
        clearBucket: () => dispatch(bucketActions.clearBucket()),
    })
}

class ClearBucket extends Component {
    render() {
        return (
            <div className="clear-bucket">
                <SVG onClick={this.props.clearBucket} className="trash" name="trash"/>
            </div>
        );
    }
}

export default connect(null, mapDispatchToProps)(ClearBucket);
