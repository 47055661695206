import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import SVG from '../../../../SVG';
import * as problemSetActions from '../../../../../modules/problemSet/action';
import * as bucketActions from '../../../../../modules/bucket/action';
import * as dialogActions from '../../../../../modules/dialog/action';
import {PROBLEM_SET_NOTES_DIALOG_BOX} from '../../../../dialogs/dialogs';
import _ from 'lodash'
import {IN_A_ROW_CORRECT_COUNT} from '../../../../../constants'
import * as alertActions from "../../../../../modules/alert/action";
import EditTitle from "../../common/EditTitle";
import {ProblemSetProgressBar} from "../../common/CommonMethod";

class AdminProblemSet extends Component {
    state = {
        showEdit: false,
        allproblemsets: []
    }

    toggle = (el) => {
        this.setState({ [el]: !this.state[el] })
    }

    goToProblemSetDetailPage = () => {
        const { problemSet, close, question, location, subSubtopic } = this.props
        let queryString = ''
        if (location.pathname.includes('topics')) {
            queryString = subSubtopic ? '?subsubtopic=' + subSubtopic._id : ''
        } else if (location.pathname.includes('acts')){
            queryString = question ? '?act=' + question.act_id + '&act_question=' + question.id : ''
        }else {
            queryString = question ? '?sat=' + question.sat_id + '&sat_question=' + question.id : ''
        }
        this.props.history.push('/admin/problemset/' + problemSet._id + queryString);
        close()
    }

    goToProblemSetHomeDetailPage = () => {
        const { problemSet, selectedId, close, location, sat, allIds, act} = this.props

        let appendQueryString = ''
        if (allIds && allIds.topicId && allIds.subtopicId && allIds.subsubtopicId) {
            appendQueryString = "&topic=" + allIds.topicId + "&subtopic=" + allIds.subtopicId + "&subsubtopic=" + allIds.subsubtopicId
        }

        let redirect = ''
        if ((location.pathname).includes('math')) {
            redirect = 'math' + appendQueryString
        } else if ((location.pathname).includes('grammar')) {
            redirect = 'grammar' + appendQueryString
        } else if ((location.pathname).includes('reading')) {
            redirect = 'reading' + appendQueryString
        } else if ((location.pathname).includes('acts')) {
            redirect = 'acts&act=' + act.id + '&act_question=' + selectedId
        } else if ((location.pathname).includes('sats')) {
            redirect = 'sats&sat=' + sat.id + '&sat_question=' + selectedId
        }
        this.props.history.push('/home/problemset/' + problemSet._id + '/' + selectedId + '?page=' + redirect);
        close();
    }

    checkUnCheckProblemSet = (id, type) => {
        const {checkUnCheckProblemSet} = this.props;
        checkUnCheckProblemSet(id, type);
    }

    renderForm() {
        const {
            problemSet, updateProblemSet, topicType, selectedId, location, account, sat, act, selectedProblemSets,
            assignments
        } = this.props;
        const { showEdit } = this.state;
        const getProblemSetProgress = _.find(account.allProblemsetsProgress, (dd) => dd.reference_id === problemSet._id)
        const questionsNotCorrect = getProblemSetProgress ? (IN_A_ROW_CORRECT_COUNT - getProblemSetProgress.total_correct) : '';

        let problemsetTag = ''
        if (sat && sat.id) {
            if (problemSet.sat_question_id) {
                problemsetTag =  (problemSet.sat_question_id.includes(selectedId) && problemSet.sat_question_id[0] === selectedId) ? "Main" : "Existing";
            }
        } else if (act && act.id) {
            if (problemSet.act_question_id) {
                problemsetTag =  (problemSet.act_question_id.includes(selectedId) && problemSet.act_question_id[0] === selectedId) ? "Main" : "Existing";
            }
        } else {
            if (problemSet.subsubtopic_id) {
                problemsetTag =  (Array.isArray(problemSet.subsubtopic_id) === false || (problemSet.subsubtopic_id.includes(selectedId) && problemSet.subsubtopic_id[0] === selectedId)) ?
                    "Main" :  "Existing";
            }
        }

        if (!showEdit) {
            return (
                <div className="problemsetDataCls d-flex">
                    <div className="problemsetLeftDataCls pr-2">
                        <p className={(location.pathname).includes('home') ? "problemsetTitleCls mb-0" : 'problemsetTitleAdminCls title mb-0'} onClick={() =>
                            (location.pathname).includes('home') ? (account.tutor_login ? "" : this.goToProblemSetHomeDetailPage()) : this.goToProblemSetDetailPage()
                        }>
                            {problemSet.title}
                            {(location.pathname).includes('admin')
                                ? <span>(<span className="text-success">{problemsetTag}</span>)</span>
                                : ""}
                        </p>
                        {(location.pathname).includes('home') && getProblemSetProgress
                            ? <span className="problemsetSubTitleCls text-secondary" > Get 4 in a row to master </span>
                            : ''}
                    </div>
                    {(location.pathname).includes('home') && getProblemSetProgress ?
                        <>
                            <ProblemSetProgressBar progress={getProblemSetProgress}
                                                   notCorrect={questionsNotCorrect}
                                                   showMaxCorrectTick={true}
                                                   className={'problemsetRightDataCls'} />
                            <ReAssignButton account={account}
                                            assignments={assignments}
                                            problemSet={problemSet}
                                            selectedProblemSets={selectedProblemSets}
                                            show={getProblemSetProgress?.total_correct >= IN_A_ROW_CORRECT_COUNT}
                                            checkUnCheckProblemSet={(id,type)=> this.checkUnCheckProblemSet(id,type)}/>
                        </>
                        :
                            <AssignButton account={account}
                                          assignments={assignments}
                                          problemSet={problemSet}
                                          selectedProblemSets={selectedProblemSets}
                                          type={'assign'}
                                          checkUnCheckProblemSet={(id,type)=> this.checkUnCheckProblemSet(id,type)}/>
                    }
                </div>
            )
        } else {
            return (
                <>
                    <EditTitle onUpdate={updateProblemSet}
                               topic={problemSet}
                               show={showEdit}
                               problemSetTopic={topicType}
                               flashMessage={this.flashMessage}
                               topicType={"ProblemSet"}
                               selectedId={selectedId}
                               toggleForm={() => this.toggle('showEdit')}/>
                </>
            )
        }
    }

    deleteProblemSet = async () => {
        const {
            deleteProblemSet, problemSet, removeSatProblemSet, updateProblemSetPosition, updateProblemSetSatPosition,
            allProblemsets, topicType, selectedId, updateProblemSetActPosition
        } = this.props;
        let res = window.confirm("Are you sure you want to delete this problemSet?");
        if (res) {
            const index = allProblemsets.findIndex(x => x._id === problemSet._id)
            if (index > -1) {
                allProblemsets.splice(index, 1)
                if (problemSet.subsubtopic_id) {
                    problemSet.subsubtopic_id = Array.isArray(problemSet.subsubtopic_id) ? problemSet.subsubtopic_id : [problemSet.subsubtopic_id]
                } else {
                    problemSet.subsubtopic_id = []
                }

                if (topicType === 'SatQuestionTopic') {
                    if (problemSet.sat_question_id && problemSet.sat_question_id.includes(selectedId) && problemSet.sat_question_id[0] === selectedId && (problemSet.subsubtopic_id ? problemSet.subsubtopic_id.length < 1 : true) && (problemSet.act_question_id ? problemSet.act_question_id.length < 1 : true)) {
                        await deleteProblemSet(problemSet._id, selectedId);
                    } else {
                        let updatedProblemset = problemSet
                        if (updatedProblemset.sat_question_id && problemSet.sat_question_id.includes(selectedId) && updatedProblemset.sat_question_id[0] === selectedId) {
                            updatedProblemset = { ...updatedProblemset, sat_question_id: [], sat_position: [] }
                        } else {
                            const satIndex = updatedProblemset.sat_question_id.findIndex(x => x === selectedId)
                            const satPositionIndex = Array.isArray(updatedProblemset.sat_position) ? updatedProblemset.sat_position.findIndex(x => x["sat_question_id"] === selectedId) : -1
                            if (satIndex > -1) {
                                updatedProblemset.sat_question_id.splice(satIndex, 1)
                            }
                            if (satPositionIndex > -1) {
                                updatedProblemset.sat_position.splice(satPositionIndex, 1)
                            }
                        }
                        await removeSatProblemSet(updatedProblemset._id, updatedProblemset, selectedId)
                    }
                    await updateProblemSetSatPosition({ problem_sets: allProblemsets, _id: selectedId })
                }else if (topicType === 'ActQuestionTopic') {
                    if (problemSet.act_question_id && problemSet.act_question_id.includes(selectedId) && problemSet.act_question_id[0] === selectedId && (problemSet.subsubtopic_id ? problemSet.subsubtopic_id.length < 1 : true) && (problemSet.sat_question_id ? problemSet.sat_question_id.length < 1 : true)) {
                        await deleteProblemSet(problemSet._id, selectedId);
                    } else {
                        let updatedProblemset = problemSet
                        if (updatedProblemset.act_question_id && problemSet.act_question_id.includes(selectedId) && updatedProblemset.act_question_id[0] === selectedId) {
                            updatedProblemset = { ...updatedProblemset, act_question_id: [], act_position: [] }
                        } else {
                            const satIndex = updatedProblemset.act_question_id.findIndex(x => x === selectedId)
                            const satPositionIndex = Array.isArray(updatedProblemset.act_position) ? updatedProblemset.act_position.findIndex(x => x["act_question_id"] === selectedId) : -1
                            if (satIndex > -1) {
                                updatedProblemset.act_question_id.splice(satIndex, 1)
                            }
                            if (satPositionIndex > -1) {
                                updatedProblemset.act_position.splice(satPositionIndex, 1)
                            }
                        }
                        await removeSatProblemSet(updatedProblemset._id, updatedProblemset, selectedId)
                    }
                    await updateProblemSetActPosition({ problem_sets: allProblemsets, _id: selectedId })
                } else {
                    if (problemSet.sat_question_id) {
                        problemSet.sat_question_id = Array.isArray(problemSet.sat_question_id) ? problemSet.sat_question_id : [problemSet.sat_question_id]
                    } else {
                        problemSet.sat_question_id = []
                    }
                    if (problemSet.subsubtopic_id && problemSet.subsubtopic_id.includes(selectedId) && problemSet.subsubtopic_id[0] === selectedId && (problemSet.sat_question_id ? problemSet.sat_question_id.length < 1 : true) && (problemSet.act_question_id ? problemSet.act_question_id.length < 1 : true)) {
                        await deleteProblemSet(problemSet._id, selectedId);
                    } else {
                        let updatedProblemset = problemSet
                        if (problemSet.subsubtopic_id && problemSet.subsubtopic_id.includes(selectedId) && problemSet.subsubtopic_id[0] === selectedId) {
                            updatedProblemset = { ...problemSet, subsubtopic_id: [], position: [] }
                        } else {
                            const subSubtopicIndex = updatedProblemset.subsubtopic_id.findIndex(x => x === selectedId)
                            const PositionIndex = Array.isArray(updatedProblemset.position) ? updatedProblemset.position.findIndex(x => x["subsubtopic_id"] === selectedId) : -1
                            if (subSubtopicIndex > -1) {
                                updatedProblemset.subsubtopic_id.splice(subSubtopicIndex, 1)
                            }
                            if (PositionIndex > -1) {
                                updatedProblemset.position.splice(PositionIndex, 1)
                            }
                        }
                        await removeSatProblemSet(updatedProblemset._id, updatedProblemset, selectedId)
                    }
                    await updateProblemSetPosition({ problem_sets: allProblemsets, _id: selectedId })
                }
            }
        } else {
            return null;
        }
    }

    addProblemSet = async () => {
        const { problemSet, addBucketProblemSet } = this.props
        if (!this.bucketProblemSetIsPresent(problemSet)) {
            await addBucketProblemSet(problemSet);
            this.flashMessage({message: `ProblemSet '${problemSet.title}' added to bucket`, type: "success"});
        } else {
            this.flashMessage({message: `ProblemSet '${problemSet.title}' already added in bucket`, type: 'warning'});
        }
    }

    bucketProblemSetIsPresent(problemset) {
        const { bucketProblemSet } = this.props;
        for (let i = 0; i < bucketProblemSet.length; i++) {
            if (bucketProblemSet[i]._id === problemset._id) {
                return true;
            }
        }
        return false;
    }


    async pubishUnpublishProblemSet() {
        const { problemSet, publishUnPublishProblemSet, selectedId } = this.props;
        if (problemSet._id) {
            await publishUnPublishProblemSet({ id: problemSet._id, isPublish: !problemSet.isPublish, selectedId });
        } else {
            alert("Error: No ProblemSet id selected!!")
        }
    }

    saveProblemSetNotes = async (notes) => {
        const { problemSet, updateProblemSetNotes, selectedId, close,
            updateAfterPublishUnpublish, question, updateAfterSequencePublishUnpublish, subSubtopic } = this.props;
        await updateProblemSetNotes({ id: problemSet._id, notes, selectedId })
        close()
        if (question && question.id) {
            updateAfterPublishUnpublish(question, true)
        } else {
            updateAfterSequencePublishUnpublish(subSubtopic, true)
        }
    }

    openNotesDialog = (problemSet) => {
        const { openDialog } = this.props
        return openDialog(PROBLEM_SET_NOTES_DIALOG_BOX, {
            problemSet,
            title: `Notes of Problem Set : ${problemSet.title}`,
            saveProblemSetNotes: this.saveProblemSetNotes
        })
    }

    renderButtons() {
        const { showEdit } = this.state;
        const { problemSet, account } = this.props
        if (!showEdit) {
            const Publish = problemSet && problemSet.isPublish;
            return (
                <div className="options btn_grup">
                    <SVG onClick={() => this.toggle('showEdit')} name="pencil"/>
                    <SVG onClick={this.deleteProblemSet} name="trash"/>
                    <button className="subtopic-form btn btn-default" onClick={this.addProblemSet}>
                        <span className="btnTxt">Add To Bucket </span>
                    </button>
                    <button onClick={() => this.openNotesDialog(problemSet)} className="btn btn-default btn-notes">
                        <i className="fa fa-circle mr-1 notesIconCls"/> <span>Notes</span>
                    </button>
                    {
                        account.is_super_admin &&
                        <button onClick={() => this.pubishUnpublishProblemSet()}
                                className={`btn btn-sm ${Publish ? 'btn-danger' : 'btn-info'} ml-2`}>
                            {problemSet && problemSet.isPublish ? "Unpublish" : "Publish"}
                        </button>
                    }
                </div>
            )
        }
        return null;
    }

    flashMessage = (payload, timeout = 5000) => {
        const {alertPush, alertDelete} = this.props;
        alertPush(payload);
        setTimeout(() => alertDelete(),timeout);
    }

    render() {
        const { account, location } = this.props
        return (
            <div className="admin-subtopic w-100">
                {this.renderForm()}
                {account.userType === "Student" || (location.pathname).includes('home') ? '' : this.renderButtons()}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return ({
        account: state.account,
        assignments: state.assignments,
        bucketProblemSet: state.bucket.problemSet,
    })
}

const AssignButton = ({account, assignments, problemSet, selectedProblemSets, type, checkUnCheckProblemSet}) =>{
    return(
        <span className={"checkbox-icon"}>
        {
            (account.tutor_login && account.status && !(assignments.map((assignment) => assignment.assignmentable_id).includes(problemSet._id))) &&
                <span onClick={() => checkUnCheckProblemSet(problemSet._id, type)}>
                    <i className={`fa fa-${!!(selectedProblemSets && selectedProblemSets[problemSet._id]) ? 'check-' : ''}square-o ml-2 fa-lg`}/>
                </span>
        }
        </span>
    )
}

const ReAssignButton = ({account, assignments, problemSet, selectedProblemSets, show, checkUnCheckProblemSet}) =>{
    const filteredAssignments = assignments.filter(assignment => (assignment.assignmentable_id === problemSet._id));
    return(
        <span className={"checkbox-icon"}>
        {
            (account.tutor_login && show && account.status && filteredAssignments?.length > 0) &&
            <>
                <span onClick={() => checkUnCheckProblemSet(problemSet._id, "reassign")}>
                    <i className={`fa fa-${!!(selectedProblemSets && selectedProblemSets[problemSet._id]) ? 'check-' : ''}square-o ml-2 fa-lg`}/>
                </span>
            </>
        }
        </span>
    )
}

const mapDispatchToProps = (dispatch) => {
    return ({
        updateProblemSet: (id, attributes) => dispatch(problemSetActions.updateProblemSet( id, attributes)),
        deleteProblemSet: (id, parentId) => dispatch(problemSetActions.deleteProblemSet( id, parentId)),
        removeSatProblemSet: (id, attributes, satId) => dispatch(problemSetActions.removeSatProblemSet(id, attributes, satId)),
        updateProblemSetPosition: (problemSets) => dispatch(problemSetActions.updateProblemSetPosition(problemSets)),
        updateProblemSetSatPosition: (problemSets) => dispatch(problemSetActions.updateProblemSetSatPosition(problemSets)),
        addBucketProblemSet: (data) => dispatch(bucketActions.addBucketProblemSet(data)),
        publishUnPublishProblemSet: (data) => dispatch(problemSetActions.publishUnPublishProblemSet(data)),
        openDialog: (config, options) => dispatch(dialogActions.open(config, options)),
        updateProblemSetNotes: (params) => dispatch(problemSetActions.updateProblemSetNotes(params)),
        updateProblemSetActPosition: (problemSets) => dispatch(problemSetActions.updateProblemSetActPosition(problemSets)),
        alertPush: (payload) => dispatch(alertActions.alertPush(payload)),
        alertDelete: () => dispatch(alertActions.alertDelete())
    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminProblemSet))
