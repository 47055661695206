import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import TopicHeader from './TopicHeader';
import TopicFooter from './TopicFooter';
import QuestionContainer from './QuestionContainer';
import * as questionActions from '../../modules/question/action';
import * as answerActions from '../../modules/answer/action';
import * as testActions from '../../modules/test/action';
import * as questionProgActions from '../../modules/questionProg/action';
import * as testProgActions from '../../modules/testProg/action';
import * as accountActions from '../../modules/account/action';
import "./TopicContainer.scss";

class TopicContainer extends Component {
    state = {
        ready: false,
        selectedAnswer: {},
    }
    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        if(prevProps.match.params.topicId !== this.props.match.params.topicId) {
            this.loadData();
        }

        if (prevProps.match.params.questionId !== this.props.match.params.questionId && prevProps.match.params.questionId !== undefined) {
            this.loadQuestionData(prevProps)
        }
    }

    async loadQuestionData (prevProps) {
        const {getAnswers, findOrCreateQuestionProg, match, testProg, updateQuestionProg, updateTestProg} = this.props;
        const {questionId, topicId} = match.params;
        await getAnswers({params: {question_id: questionId}});
        const prevQuestionId = prevProps.match.params.questionId;
        const questionProg = await findOrCreateQuestionProg({
            question_id: questionId,
            test_id: topicId,
            test_prog_id: testProg.id,
        })

        await updateTestProg(testProg.id, {latest_question: questionId});

        if (!questionProg.prev_question && questionProg.next_question !== prevQuestionId) {
            await updateQuestionProg(questionProg.id, {prev_question: prevQuestionId})
        }

        this.setState({ready: true, selectedAnswer: {}})
    }

    async loadData(prevProps) {
        const {match, getQuestions, findOrCreateTestProg, getTopic, getAnswers, findOrCreateQuestionProg, updateTestProg} = this.props;
        const {topicId, questionId} = match.params;
        const questions = await getQuestions({params: {test_id: topicId}});
        const currentTopic = await getTopic(topicId);
        const testProg = await findOrCreateTestProg({test_id: topicId});
        let latestId = questionId;
        if (!questionId) {
            latestId = testProg.latest_question ? testProg.latest_question : questions[0].id
            this.props.history.replace(`/topic/${topicId}/question/${latestId}`);
        }

        if (!testProg.latest_question) {
            await updateTestProg(testProg.id, {latest_question: questions[0].id});
        }

        await getAnswers({params: {question_id: latestId}});
        await findOrCreateQuestionProg({
            question_id: latestId,
            test_id: topicId,
            test_prog_id: testProg.id,
        })

        this.setState({ready: true, currentTopic})
    }

    updateQuestion = () => {
        const {match, currentQuestionProg} = this.props;
        const {next_question} = currentQuestionProg;
        if (!next_question) {
            this.props.history.push('/home')
        } else {
            const nextQuestionPath = match.path.replace(':questionId', next_question).replace(':topicId', match.params.topicId);
            this.props.history.push(nextQuestionPath)
            this.setState({ready: false})
        }

    }

    setSelectedAnswer = (selectedAnswer) => {
        this.setState({selectedAnswer})
    }

    submitAnswer = async () => {
        const {updateQuestionProg, currentQuestionProg, validateCurrentUser} = this.props;
        const {selectedAnswer} = this.state;
        const data = {
            completed: true,
            next_question: selectedAnswer.next_question._id,
            selected_answer: selectedAnswer.id,
        }
        await updateQuestionProg(currentQuestionProg.id, data);
        await validateCurrentUser();
    }

    goPrevQuestion = () => {
        const {currentQuestionProg: {prev_question}, match} = this.props;
        const prevQuestionPath = match.path.replace(':questionId', prev_question).replace(':topicId', match.params.topicId);
        this.props.history.push(prevQuestionPath)
        this.setState({ready: false})
    }

    renderQuestion() {
        const {currentQuestionProg} = this.props;
        const {selectedAnswer} = this.state;
        return (
            <QuestionContainer
                setSelectedAnswer={this.setSelectedAnswer}
                selectedAnswer={selectedAnswer}
                currentQuestionProg={currentQuestionProg}/>
        )

    }

    render() {
        const {ready, selectedAnswer, currentTopic} = this.state;
        const {currentQuestionProg} = this.props;
        if (!ready) return null;
        return (
            <div className="topic-container">
                <TopicHeader
                    currentTopic={currentTopic}/>
                {this.renderQuestion()}
                <TopicFooter
                    currentQuestionProg={currentQuestionProg}
                    updateQuestion={this.updateQuestion}
                    submitAnswer={this.submitAnswer}
                    goPrevQuestion={this.goPrevQuestion}
                    completed={currentQuestionProg.completed}
                    prevQuestion={currentQuestionProg.prev_question}
                    selectedAnswer={selectedAnswer}/>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const currentTopicId = props.match.params.topicId;
    const currentQuestionId = props.match.params.questionId;
    const testProg = state.testProgs.find(x => x.test_id === currentTopicId);
    const currentQuestionProg = state.questionProgs.find(x => x.question_id === currentQuestionId);
    return({
        questions: state.questions,
        topics: state.topics,
        testProg,
        currentQuestionProg,
    })
}

const mapDispatchToProps = dispatch => {
    return({
        getQuestions: (params) => dispatch(questionActions.getAllQuestions(params)),
        getTopic: (id) => dispatch(testActions.getTest(id)),
        getAnswers: (params) => dispatch(answerActions.getAllAnswer(params)),
        findOrCreateTestProg: (params) => dispatch(testProgActions.findOrCreateTestProg(params)),
        updateTestProg: (id, attributes) => dispatch(testProgActions.updateTestProg(id, attributes)),
        findOrCreateQuestionProg: (params) => dispatch(questionProgActions.findOrCreateQuestionProg(params)),
        updateQuestionProg: (id, attributes) => dispatch(questionProgActions.updateQuestionProg(id, attributes)),
        validateCurrentUser: () => dispatch(accountActions.validateCurrentUser()),
    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopicContainer));
