import React, { Component } from 'react';
import { SAT_SECTION_DIALOG_BOX } from '../../dialogs/dialogs';
import * as satTestQuestionActions from '../../../modules/satTestQuestion/action'
import * as animationActions from '../../../modules/animation/action'
import * as userSatTestProgressActions from "../../../modules/userSatTestProgress/action";
import { toWords } from '../../../utilities/custom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import Switch from 'react-switch';
import IfView from "../../helper/IfView";
import IfElseView from "../../helper/IfElseView";
import BubbleSheet from "../../admin/content/common/bubbleSheet/BubbleSheet";
import DropDown, {DropDownOption} from "../../helper/Dropdown/DropDown";
import * as alertActions from "../../../modules/alert/action";
import {
    getAssignmentProgressData,
    SectionProgressDiv,
    SequenceProblemSetProgress
} from "../../helper/commonMethods";

class SectionQuestions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            question: {},
            formActive: false,
            topic: "",
            satTestQuestions: {}
        }

        this.toggleForm = this.toggleForm.bind(this);
        this.handler = this.handler.bind(this);
        this.updateQuestion = this.updateQuestion.bind(this);
    }

    showQuestion(question) {
        const { formActive } = this.state;
        this.setState({ question })
        if (formActive) this.toggleForm();
    }

    toggleForm() {
        const { formActive, question } = this.state;
        if (formActive) {
            this.setState({ formActive: false, title: question.title, topic: question.topic })
        } else {
            this.setState({ formActive: true, title: "", topic: "" })
        }
    }

    handler(e) {
        const linkedTopicId = e.value;
        this.setState({ topic: linkedTopicId });
    }

    handleChange(prop) {
        return ({ target }) => this.setState({ [prop]: target.value })
    }

    updateQuestion(e) {
        // e.preventDefault();
        const { updateSatQuestion } = this.props;
        const { topic, question } = this.state;
        const data = { topic };
        updateSatQuestion(question.id, data).then(question => {
            this.setState({ question })
        });
    }

    openDialogBox(question) {
        const { selectTopics, sat, isMock } = this.props
        this.setState({ question, topic: question.topic });
        return this.props.openDialog(SAT_SECTION_DIALOG_BOX, {
            sat,
            question,
            //title: `${(question.title).split('_')[0]} ${toWords(question.type)} Question #${question.position + 1}`,
            title: `${sat.title} ${toWords(question.type)} Question #${question.position + 1}`,
            handler: this.handler,
            selectTopics,
            updateQuestion: this.updateQuestion,
            isMock,
            afterReset: this.afterReset,
            openDialogAfterAssignAssignment: this.openDialogAfterAssignAssignment
        })
    }

    openDialogAfterAssignAssignment = () => {
        this.openDialogBox(this.state.question)
    }

    componentDidUpdate(prevProps) {
        const { isMock, isTest } = this.props;
        if(isTest !== prevProps.isTest || isMock !== prevProps.isMock ){
            this.fetchQuestions();
        }
    }

    fetchQuestions = async () =>{
        const { questionType, startAnimation, endAnimation, sat, getRealTestQuestions, getMockTestQuestions, isMock, isTest } = this.props;
        if(isTest){
            startAnimation();
            if(!isMock){
                await getRealTestQuestions({ params: { sat_id: sat.id, sat_type: questionType, question_type: 'AdminSatRealTest' }});
            } else {
                await getMockTestQuestions({ params: { sat_id: sat.id, sat_type: questionType, question_type: 'AdminSatMockTest' } });
            }
            endAnimation();
        }
    }

    async componentDidMount() {
        const { questions } = this.props
        const quesryString = new URLSearchParams(this.props.location.search);
        const getQuestion = questions.find(dd => dd.id === quesryString.get('sat_question'))
        if (quesryString && quesryString.get('sat_question') && getQuestion) {
            this.openDialogBox(getQuestion)
        }
        await this.fetchQuestions();
    }

    satTypeTestFlow = (header,testType) => {
        const { history, sat } = this.props;
        history.push(`/home/sat-test/${sat.id}/${testType}/${header}`)
    }

    openMockRefDialogBox = (key) => {
        const { questions } = this.props;
        let questionData = questions.find((dd, kk) => kk === key)
        this.openDialogBox(questionData)
    }

    resetSatTest = async (satId, userId, type, real_test) => {
        const { startAnimation, endAnimation, resetUserSatTestProgress, getUserSatTestScores } = this.props;
        let res = window.confirm("Are you sure you want to reset whole test?");
        if (res) {
            startAnimation();
            await resetUserSatTestProgress({ sat_id: satId, sat_type: type, real_test});
            await getUserSatTestScores({ sat_id: satId });
            endAnimation();
        } else {
            return null
        }
    }

    afterReset = () => {
        const { sat, getUserSatSequencesProgress, getUserSatSequenceTypeProgress } = this.props;
        getUserSatSequencesProgress({sat_id: sat.id })
        getUserSatSequenceTypeProgress({sat_id: sat.id})
    }

    toggleSwitch = (type) => {
        const {saveTimedUnTimed } = this.props;
        saveTimedUnTimed(type);
    }

    render() {
        const {
            questions, questionType, header, selectWrong, selectUncertain, getWrongSelectedQuestions, wrongSelectedQuestions,
            getUncertainSelectedQuestions, uncertainSelectedQuestions, sat, isMock, correctTestQuestions,
            uncertainTestQuestions, satTestProgress, account, satSequencesProgress, timed, grading_mode, isLastIndex,
            isTest, satRealTestQuestions, satMockTestQuestions, bubbleSheet, submitBubbleSheet, selectBubbleSheet,
            gradeSubmitted, selectionWrongArea, selectionUncertainArea, submitSelectWrong, submitSelectUncertain,
            bubbleSheetAnswer, satProblemSets, satSequenceTypeProgress, satProblemSetTypeProgress, assignments
        } = this.props;


        let correct = questions.length - (wrongSelectedQuestions[questionType]).length;
        if(bubbleSheet[questionType] === true){
            return(
                <BubbleSheet account={account}
                             questions={questions}
                             questionType={questionType}
                             bubbleSheetAnswer={bubbleSheetAnswer}
                             submitBubbleSheet={submitBubbleSheet}
                             selectBubbleSheet={selectBubbleSheet}
                             topic={sat}
                             wrongSelectedQuestions={wrongSelectedQuestions}
                             gradeSubmitted={gradeSubmitted}
                             selectWrong={selectWrong}
                             correct={correct}
                             topicType={"SAT"}/>
            )
        }


        if ((questionType === "Reading" || questionType === "Writing") && sat && sat.score) {
            for(let dd of sat.score){
                if (parseInt(correct) === parseInt(dd[0])) {
                    if(questionType === "Reading"){
                        correct = dd[2];
                    } else if(questionType === "Writing") {
                        correct = dd[3]
                    }
                    break;
                }
            }
        }

        let testCondition = isTest && (questionType === "Reading" || questionType === "Writing")

        let correctMock = 0;
        const satTestQuestions = !isMock ?  satRealTestQuestions : satMockTestQuestions;
        satTestQuestions && satTestQuestions[sat.id] && satTestQuestions[sat.id][questionType] && (satTestQuestions[sat.id][questionType]).map((dd) => {
                if (correctTestQuestions.includes(dd.id)) { correctMock++ }
        })

        let getProgress = satTestProgress.find(dd => (dd.sat_type === questionType && dd.real_test === !isMock && dd.sat_id === sat.id))

        let setShowProgressIndexForMock = [], assignedProblemSetProgress = {}, totalAssignedProblemSets = 0,
            topicProblemSetProgress = 0;
        const { assignmentProgress, assignmentIds } = getAssignmentProgressData(assignments);
        questions.map((question, k) => {
            satSequencesProgress && satSequencesProgress.map(dd => {
                if (dd.reference_id === question.id) {
                    setShowProgressIndexForMock.push({ index: k, percent: dd.percent_complete });
                }
                return '';
            })
            if(sat.progress_by_problemset){
                let problemSetProgress = 0;
                let problemSets = satProblemSets && satProblemSets[sat.id] && satProblemSets[sat.id][question.id];
                problemSets = (problemSets && problemSets.filter((pb)=> assignmentIds.includes(pb._id))) ?? [];
                let totalProblemSets = problemSets?.length ?? 0;
                if(totalProblemSets){
                    problemSets.map(({_id}) => {
                        problemSetProgress += (assignmentProgress[_id] ?? 0);
                        totalAssignedProblemSets +=1;
                        return '';
                    });
                    topicProblemSetProgress += problemSetProgress;
                    problemSetProgress = problemSetProgress/totalProblemSets;
                }
                assignedProblemSetProgress[question.id] = problemSetProgress;
            }
            return ''
        });
        if(totalAssignedProblemSets > 0 && topicProblemSetProgress > 0){
            topicProblemSetProgress = Math.round((topicProblemSetProgress/totalAssignedProblemSets));
        }

        return (
            <div className={testCondition ? "mockEnabledCls" : `ques_set ${selectWrong[questionType] || selectUncertain[questionType] ? "selectSectionCls" : ''}`}>
                <IfView visible={selectWrong[questionType]}>
                    <div className="submitCancelCls d-flex w-100 justify-content-center position-relative">
                        <button onClick={() => submitSelectWrong(questionType)} className="btn btn-light btn-default m-2">
                            <i className="fa fa-check text-success mr-1" />Submit</button>
                        <button onClick={() => selectionWrongArea(questionType)} className="btn btn-light btn-default m-2">
                            <i className="fa fa-times text-danger mr-1" />Cancel</button>
                    </div>
                </IfView>
                <IfView visible={selectUncertain[questionType]}>
                    <div className="submitCancelCls d-flex w-100 justify-content-center position-relative">
                        <button onClick={() => submitSelectUncertain(questionType)} className="btn btn-light btn-default m-2">
                            <i className="fa fa-check text-success mr-1" />Submit</button>
                        <button onClick={() => selectionUncertainArea(questionType)} className="btn btn-light  btn-default m-2">
                            <i className="fa fa-times text-danger mr-1" />Cancel</button>
                    </div>
                </IfView>
                <h3 className="satQuesIdTitleCls d-flex align-items-center">
                    {`${header}:`}
                    <IfElseView condition={isTest}>
                        <IfView visible={!(questionType === "Reading" || questionType === "Writing")}>
                            <span className="scoreOfHeadersCls">
                                {getProgress && getProgress.submitted ? correctMock : '__'}/{satTestQuestions && satTestQuestions[sat.id] && satTestQuestions[sat.id][questionType] && (satTestQuestions[sat.id][questionType]).length}
                            </span>
                            <button disabled={!!account.tutor_login}
                                    onClick={() => this.satTypeTestFlow(questionType,isMock? 'mock' : 'real')}
                                    className="btn btn-sm btn-success ml-2">
                                {getProgress ? getProgress.submitted ? 'Review Test' : 'Continue' : 'Take Test'}
                            </button>
                            <IfView visible={(account.is_admin) && getProgress && getProgress.submitted}>
                                <button disabled={!!account.tutor_login}
                                        onClick={() => this.resetSatTest(sat.id, account.id, questionType,!isMock)}
                                        className={"ml-2 btn btn-sm btn-danger"}>
                                    Reset Test
                                </button>
                            </IfView>
                        </IfView>
                         <span className="scoreOfHeadersCls mx-2 mt-1">
                                {correct && (gradeSubmitted[questionType] || selectWrong[questionType]) ? correct + '/' : '__/'}
                                {sat?.score?.length > 0 ? questionType === "Reading" || questionType === "Writing" ? '40' : questions.length : questions.length}
                         </span>
                    </IfElseView>

                    <IfElseView condition={sat.progress_by_problemset}>
                        <SectionProgressDiv progress={satProblemSetTypeProgress} topicId={sat.id} questionType={questionType}/>
                        <SectionProgressDiv progress={satSequenceTypeProgress} topicId={sat.id} questionType={questionType}/>
                    </IfElseView>

                    <IfView visible={!(!grading_mode || ((selectWrong[questionType] || selectUncertain[questionType]) || isTest)) }>
                        <div className="timedSwitch d-flex align-items-center ml-2">
                            <h5>Timed</h5>
                            <Switch
                                checked={timed[questionType]}
                                onChange={() => this.toggleSwitch(questionType)}
                                className="pl-2"
                                onColor="#00C789"
                                height={32}
                                width={50}
                                handleDiameter={24} />
                        </div>
                    </IfView>
                    <IfView visible={!(!grading_mode || ((selectWrong[questionType] || selectUncertain[questionType]) || isTest))} >
                        <div className="btn_grup ml-auto pl-3">
                            <DropDown title={'Action'} icon={'fa fa-ellipsis-v'}>
                                <DropDownOption onClick={() => selectBubbleSheet(questionType)}>
                                    Open Sheet
                                </DropDownOption>
                                <DropDownOption disabled={!!account.tutor_login} onClick={() => selectionWrongArea(questionType)}>
                                    Select Wrong
                                </DropDownOption>
                                <DropDownOption disabled={!!account.tutor_login} onClick={() => selectionUncertainArea(questionType)}>
                                    Select Uncertain
                                </DropDownOption>
                            </DropDown>
                        </div>
                    </IfView>
                </h3>

                <IfElseView condition={isTest}>
                    <ul className={getProgress && getProgress.submitted ? "" : "mockEnabledCls"}>
                        {satTestQuestions && satTestQuestions[sat.id] && satTestQuestions[sat.id][questionType] && satTestQuestions[sat.id][questionType].map((q, key) => {
                            const mockClasses = ["question"];
                            if (!(questionType === "Reading" || questionType === "Writing")) {
                                if (getProgress && getProgress.submitted) {
                                    if (!(correctTestQuestions.includes(q.id))) {
                                        mockClasses.push('activeWrongLightCls')
                                    }
                                    if (correctTestQuestions.includes(q.id)) {
                                        mockClasses.push('activeCorrectLightCls')
                                    }
                                    if (uncertainTestQuestions.includes(q.id)) {
                                        mockClasses.push('activeUncertainLightCls')
                                    }
                                }
                            }

                            let setPer = setShowProgressIndexForMock.find(d => d.index === key)
                            return (
                                <li className={mockClasses.join(" ")} onClick={() => this.openMockRefDialogBox(key)}
                                    key={q.id}>
                                    <span className="textShowIndCls">{key + 1}</span>
                                    {setPer && setPer.percent ?
                                        <>
                                            <span className="percentAboveCls"> {Math.round(setPer.percent)}% </span>
                                            <span className="progressVerticalCls" style={{ height: setPer.percent + "%" }}> </span>
                                        </>
                                        : ''}
                                </li>
                            )
                        })}
                    </ul>
                    <ul>
                    {questions?.map((question) => {
                        const classes = ["question"];
                        if (wrongSelectedQuestions[question.type].includes(question.id)) {
                            classes.push('activeWrongCls');
                        }
                        if (uncertainSelectedQuestions[question.type].includes(question.id)) {
                            classes.push('activeUncertainCls');
                        }

                        let sequenceProgress = satSequencesProgress && satSequencesProgress.find(dd => dd.reference_id === question.id)
                        return (
                            <li className={classes.join(" ")} onClick={() =>
                                selectWrong[questionType] ? getWrongSelectedQuestions(question.id, question.type)
                                    : selectUncertain[questionType] ? getUncertainSelectedQuestions(question.id, question.type)
                                        : this.openDialogBox(question)}
                                key={question.id}
                                id={`T${question.id}`}
                            >
                                <span className="textShowIndCls">{question.position + 1}</span>
                                <SequenceProblemSetProgress sequenceProgress={sequenceProgress}
                                                            showProgressByProblemSet={sat.progress_by_problemset}
                                                            problemSetProgress={assignedProblemSetProgress[question.id]} />
                            </li>
                        )
                    })}
                </ul>
                </IfElseView>
                {!testCondition && !isLastIndex && <div className="setBottomBorder"/>}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return ({
        account: state.account,
        assignments: state.assignments,
        satRealTestQuestions: state.satTestQuestions.real,
        satMockTestQuestions: state.satTestQuestions.mock,
        satTestProgress: state.userSatTestProgress.data,
        satSequenceTypeProgress: state.satProgress.sequenceType,
        satProblemSetTypeProgress: state.satProgress.problemSetType,
        satProblemSets: state.satProblemSets,
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        getRealTestQuestions: (params) => dispatch(satTestQuestionActions.getAllSatRealTestQuestions(params)),
        getMockTestQuestions: (params) => dispatch(satTestQuestionActions.getAllSatMockTestQuestions(params)),
        resetUserSatTestProgress: (params) => dispatch(userSatTestProgressActions.resetUserSatTestProgress(params)),
        getUserSatTestScores: (params) => dispatch(userSatTestProgressActions.getUserSatTestScores(params)),
        startAnimation: () => dispatch(animationActions.start()),
        endAnimation: () => dispatch(animationActions.end()),
        alertPush: (payload) => dispatch(alertActions.alertPush(payload)),
        alertDelete: () => dispatch(alertActions.alertDelete())
    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SectionQuestions));
