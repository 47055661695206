import { RECEIVE_ACT_PROBLEM_SETS, RESET_ACT_PROBLEM_SETS } from "../constant";

const defaultState = {};
export const actProblemSetReducer = (state = defaultState, action) => {
    switch (action.type) {
        case RECEIVE_ACT_PROBLEM_SETS:
            return { ...state, [action.data.act_id]: action.data.data };
        case RESET_ACT_PROBLEM_SETS:
            return defaultState;
        default:
            return state;
    }
}
