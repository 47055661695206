import React, {Component} from 'react'
import {ASSIGNMENT_DIALOG_BOX} from '../dialogs/dialogs'
import AdminProblemSets from "../admin/content/topics/problemSets/AdminProblemsets";
import * as userProgressActions from "../../modules/userProgress/action";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import * as alertActions from "../../modules/alert/action";
import * as accountActions from "../../modules/account/action";
import _ from "lodash";
import {IN_A_ROW_CORRECT_COUNT} from "../../constants";
import * as assignmentActions from "../../modules/assignment/action";

class AssignMultipleProblemSet extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedProblemSets: {}
        }
    }

    openDialogBox = async () => {
        const { openDialog } = this.props;
        openDialog(ASSIGNMENT_DIALOG_BOX, {
            assignAssignment: this.assignAssignment,
            dismiss: this.dismiss
        });
    }

    assignAssignment = async (date) => {
        const {
            selectedId, account, createMultipleAssignment, openDialogAfterAssignAssignment, close, location, sat, act,
            getAllUserProblemSetsProgress
        } = this.props
        const { selectedProblemSets } = this.state;
        const referenceType = "problem_set";
        let topicType = '';
        if (location.pathname.includes("math")){
            topicType = "math"
        }else if (location.pathname.includes("grammar")){
            topicType = "grammar"
        }else if(location.pathname.includes("reading")){
            topicType = "reading"
        }else if(location.pathname.includes("problemset")){
            topicType = "problemset"
        }else if(location.pathname.includes("acts")){
            topicType = "act"
        }else{
            topicType = "sat"
        }

        let topicName = sat?.id ?  "sat_question" : (act?.id ? "act_question" :  "subsubtopic");

        let reference_record = {};
        const { problemsets } = this.props;
        problemsets.map((pb) => {
            if(pb?.isPublish){
                if(["assign", 'reassign'].includes(selectedProblemSets[pb._id])){
                    reference_record[pb._id] = selectedProblemSets[pb._id];
                }
            }
        });

        let params = {
            reference_type: referenceType,
            reference_record: reference_record,
            user_id: account.id,
            sat_question_id: (["sat_question", "act_question"].includes(topicName) ? selectedId : ''),
            subsubtopic_id: (topicName === "subsubtopic" ? selectedId : ''),
            subtopic_id: (topicName === "subtopic" ? selectedId : ''),
            assignment_date: date,
            topic_type: topicType
        }

        const assigned = await createMultipleAssignment(params);
        if(assigned){
            const {topicType: TopicType} = this.props;
            let progressParams = {reference_type: 'HomeProblemSetQuestions', user_id: account.id};
            if(TopicType === "ActQuestionTopic"){
                progressParams['act_question_id'] = selectedId;
            } else if(TopicType === "SatQuestionTopic"){
                progressParams['sat_question_id'] = selectedId;
            } else {
                progressParams['subsubtopic_id'] = selectedId;
            }
            await getAllUserProblemSetsProgress(progressParams);
            await this.flashMessage({message: `ProblemSet assigned. Due date is ${date}`, type: "success"});
        }
        if (openDialogAfterAssignAssignment){
            openDialogAfterAssignAssignment()
        }else{
            close()
        }

    }

    flashMessage = (payload, timeout = 5000) => {
        const {alertPush, alertDelete} = this.props;
        alertPush(payload);
        setTimeout(() => alertDelete(),timeout);
    }

    dismiss = () => {
        const { openDialogAfterAssignAssignment, close } = this.props
        if (openDialogAfterAssignAssignment){
            openDialogAfterAssignAssignment()
        }else{
            close()
        }
    }

    checkUnCheckProblemSet = (id, type) =>{
        let { selectedProblemSets } = this.state;
        if(!selectedProblemSets[id]){
            selectedProblemSets[id] = type;
        } else {
            selectedProblemSets[id] = false;
        }
        this.setState({ selectedProblemSets });
    }

    selectAllProblemSet = async () =>{
        const { account, problemsets, assignments } = this.props;
        if(problemsets && problemsets.length > 0 && account.tutor_login){
            await this.setState({selectedProblemSets: {}});
            await problemsets.map(async ( problemSet )=>{
                if(problemSet.isPublish){
                    const getProblemSetProgress = _.find(account.allProblemsetsProgress, (dd) => dd.reference_id === problemSet._id);
                    if(getProblemSetProgress){
                        if(getProblemSetProgress?.total_correct >= IN_A_ROW_CORRECT_COUNT){
                            let filteredAssignments = assignments.filter(assignment => (assignment.assignmentable_id === problemSet._id));
                            if(filteredAssignments.length > 0){
                                this.checkUnCheckProblemSet(problemSet._id, "reassign");
                            }
                        }
                    } else {
                        if (!(assignments.map((assignment) => assignment.assignmentable_id).includes(problemSet._id))){
                            this.checkUnCheckProblemSet(problemSet._id, "assign");
                        }
                    }
                }
                return '';
            })
        }
    }


    render() {
        const {
            problemsets, close, sat, act, allIds, updateProblemSetSatPosition, topicType, selectedId, openDialog,
            openDialogAfterAssignAssignment, updateProblemSetPosition, account, assignments
        } = this.props;
        const { selectedProblemSets } = this.state;
        const showAssignButton = (selectedProblemSets && Object.values(selectedProblemSets)?.filter(val => val !== false).length > 0)
            return(
                <>
                    <div className={"d-flex align-items-center"}>
                        <div className="problem-set-title-div">
                            <h5>Problem Sets:</h5>
                        </div>
                        <div className="ml-auto assign-select-buttons float-right d-flex">
                            {
                                account.tutor_login && account.status &&
                                    <>
                                        {
                                            showAssignButton &&
                                            <button className="btn btn-default mb-2 mr-2" onClick={this.openDialogBox}>
                                                {Object.values(selectedProblemSets).includes("assign") && "Assign"}
                                                {Object.values(selectedProblemSets).includes("assign") && Object.values(selectedProblemSets).includes("reassign") && "/"}
                                                {Object.values(selectedProblemSets).includes("reassign") && "Reassign"}
                                                {" Multiple"}
                                            </button>
                                        }
                                        {
                                            IsProblemSetAssignPending(account, assignments, problemsets) &&
                                            <button className="btn btn-default mb-2" onClick={()=>this.selectAllProblemSet()}>
                                                Select All
                                            </button>
                                        }
                                    </>
                            }
                        </div>
                    </div>
                    <AdminProblemSets problemsets={problemsets}
                                      allIds={allIds}
                                      sat={sat}
                                      act={act}
                                      selectedProblemSets={selectedProblemSets}
                                      checkUnCheckProblemSet={(id, type) => this.checkUnCheckProblemSet(id, type)}
                                      updateProblemSetPosition={updateProblemSetPosition}
                                      updateProblemSetSatPosition={updateProblemSetSatPosition}
                                      topicType={topicType}
                                      selectedId={selectedId}
                                      openDialog={openDialog}
                                      close={close}
                                      openDialogAfterAssignAssignment={openDialogAfterAssignAssignment}/>
                </>
            )
    }
}


function IsProblemSetAssignPending(account, assignments, problemSets){
    let isAssignmentAvailable =  false;
    if(problemSets && problemSets.length > 0 && account.tutor_login){
        for(let problemSet of problemSets){
            if(problemSet && problemSet.isPublish){
                const getProblemSetProgress = _.find(account.allProblemsetsProgress, (dd) => dd.reference_id === problemSet._id);
                if(getProblemSetProgress){
                    if(getProblemSetProgress?.total_correct >= IN_A_ROW_CORRECT_COUNT){
                        let filterAssignments = assignments?.filter(assignment => (assignment.assignmentable_id === problemSet._id));
                        if(filterAssignments.length > 0){
                            isAssignmentAvailable =  true;
                            break;
                        }
                    }
                } else {
                    if (!(assignments.map((assignment) => assignment.assignmentable_id).includes(problemSet._id))){
                        isAssignmentAvailable =  true;
                        break;
                    }
                }
            }
        }
    }
    return(isAssignmentAvailable);
}

const mapStateToProps = (state) => {
    return ({
        account: state.account,
        assignments: state.assignments,
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        createMultipleAssignment: (params) => dispatch(assignmentActions.createMultipleAssignment(params)),
        getAllUserProblemSetsProgress: (params) => dispatch(userProgressActions.getAllUserProblemSetsProgress(params)),
        alertPush: (payload) => dispatch(alertActions.alertPush(payload)),
        alertDelete: () => dispatch(alertActions.alertDelete())
    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssignMultipleProblemSet));
