import React, { Component } from 'react';
import EditQuestion from './EditQuestion';
import ReadOnlyEditor from '../../../../editor/ReadOnlyEditor';
import './QuestionEditor.scss';
import { LINK_QUESTION_DIALOG_BOX, LINK_PROBLEM_SET_DIALOG_BOX } from '../../../../dialogs/dialogs';

const topicTypeArray = ["SatQuestionTopic","subTopic","GrammarTopic","ReadingTopic","Topic","ActQuestionTopic"];
class QuestionEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formActive: false,
            showButton: false,
            linkingType: ''
        }

        this.toggleForm = this.toggleForm.bind(this);
    }

    toggleForm() {
        const { formActive } = this.state;
        this.setState({ formActive: !formActive })
    }

    openRightLinkedDialog = () => {
        const { openDialog, questions, question } = this.props;
        // skip the selected question and show all others
        openDialog(LINK_QUESTION_DIALOG_BOX, {
            handleLinkedQuestion: this.handleRightLinkedQuestion,
            questions: questions.filter((currentValue) => currentValue.id !== question.id),
            question,
            questionType: "Right"
        })
    }

    handleRightLinkedQuestion = async (nextQuestionId) => {
        const { linkQuestion, question, startAnimation, endAnimation, flashMessage } = this.props
        startAnimation()
        await linkQuestion({ link_right_question_id: nextQuestionId, id: question.id, type: "Right" });
        await flashMessage({message: "Right Question linked successfully", type: "success"});
        endAnimation()
    }

    openWrongLinkedDialog = () => {
        const { openDialog, questions, question } = this.props;
        // skip the selected question and show all others
        openDialog(LINK_QUESTION_DIALOG_BOX, {
            handleLinkedQuestion: this.handleWrongLinkedQuestion,
            questions: questions.filter((currentValue) => currentValue.id !== question.id),
            question,
            questionType: "Wrong"
        })
    }

    handleWrongLinkedQuestion = async (nextQuestionId) => {
        const { linkQuestion, question, startAnimation, endAnimation, flashMessage } = this.props
        startAnimation()
        await linkQuestion({ link_wrong_question_id: nextQuestionId, id: question.id, type: "Wrong" })
        await flashMessage({message: "Wrong Question linked successfully", type: "success"});
        endAnimation()
    }


    openWrongLinkedProblemSet = async () => {
        const { openDialog, question } = this.props;

        if (!(question?.link_wrong_question_id)){
            let strConfirm = window.confirm("Are you sure you want to add Problemset without adding Wrong question?");
            if (strConfirm !== true) return true;
        }

        const problemSets = this.getProblemSet();

        openDialog(LINK_PROBLEM_SET_DIALOG_BOX, {
            handleLinkedProblemSet: this.handleWrongLinkedProblemSet,
            problemSets,
            problemsetId: question.link_wrong_problemset_id
        })
    }

    handleWrongLinkedProblemSet = async (problemset) => {
        const { linkProblemSet, question, startAnimation, endAnimation, flashMessage } = this.props
        startAnimation()
        await linkProblemSet({ link_wrong_problemset_id: problemset._id, id: question.id, type: "Wrong" })
        await flashMessage({message: "Wrong ProblemSet linked successfully", type: "success"});
        endAnimation()
    }

    openRightLinkedProblemSet = async () => {
        const { openDialog, question } = this.props;

        if (!(question?.link_right_question_id)){
            let strConfirm = window.confirm("Are you sure you want to add Problemset without adding right question?");
            if (strConfirm !== true) return true;
        }

        const problemSets = this.getProblemSet();

        openDialog(LINK_PROBLEM_SET_DIALOG_BOX, {
            handleLinkedProblemSet: this.handleRightLinkedProblemSet,
            problemSets,
            problemsetId: question.link_right_problemset_id
        })
    }

    getProblemSet = () =>{
        const {  problemsets, topicType, question, subsubtopics } = this.props;

        if (topicType === 'Topic' || topicType === 'GrammarTopic' || topicType === 'ReadingTopic') {
            return subsubtopics[question.subtopic_id];
        }

        if (topicType === 'subTopic' || topicType === 'SatTopic' || topicType === 'SatQuestionTopic' || topicType === 'ActQuestionTopic') {
            return [{ problemset: problemsets }];
        }

        return '';
    }

    handleRightLinkedProblemSet = async (problemset) => {
        const { linkProblemSet, question, startAnimation, endAnimation, flashMessage } = this.props
        startAnimation()
        await linkProblemSet({ link_right_problemset_id: problemset._id, id: question.id, type: "Right" })
        await flashMessage({message: "Right ProblemSet linked successfully", type: "success"});
        endAnimation()
    }

    unLinkQuestionAction = async (id, type) => {
        const { startAnimation, endAnimation, flashMessage, unLinkQuestion } = this.props
        let title = `${type} Question `;
        let res = window.confirm(`Are you sure you want to Unlink ${type} Question?`);
        if (res) {
            startAnimation();
            await unLinkQuestion({ id, type })
            await flashMessage({message: `${title} unlinked successfully`, type: "success"});
            endAnimation()
        } else {
            return null;
        }
    }

    unLinkProblemSetAction = async (id, type) => {
        let res = window.confirm(`Are you sure you want to Unlink ${type} Problem Set?`);
        if (res) {
            const { startAnimation, endAnimation, unLinkProblemSet, flashMessage} = this.props;
            await startAnimation()
            await unLinkProblemSet({ id, type })
            await flashMessage({message: `${type} ProblemSet unlinked successfully`, type: "success"});
            await endAnimation()
        } else {
            return null;
        }
    }

    render() {
        const {
            duplicateQuestion, addToBucket, openModal, deleteQuestion, previewQuestion, topicType, question,
            infoActive, postQuestionUpdate,  toggleInfo, disabled
        } = this.props;
        const { formActive } = this.state;

        return (
            <div className="question-editor">
                {
                    formActive ?
                        <div className="editQues_wrapper">
                            <EditQuestion
                                question={question}
                                toggleForm={this.toggleForm}
                                postQuestionUpdate={postQuestionUpdate} />
                        </div>
                        :
                        <div className={`question-editor-show ${infoActive ? '' : 'hide'}`} onClick={toggleInfo}>
                            <ReadOnlyEditor content={question.question}/>
                        </div>
                }

                <div className="my-2 btn-grup">
                    <button className="btn btn-default mr-2" onClick={duplicateQuestion} disabled={disabled}>Duplicate</button>
                    <button className="btn btn-default mr-2" onClick={addToBucket} disabled={disabled}>Add to Bucket</button>
                    <button className="btn btn-default mr-2" onClick={openModal} disabled={disabled}>Create Question</button>
                    <button className="btn btn-default mr-2" onClick={deleteQuestion} disabled={disabled}>Delete</button>
                    <button className="btn btn-default mr-2" onClick={previewQuestion} disabled={disabled}>Preview Question</button>
                    {infoActive && <button className="btn btn-default mr-2" onClick={this.toggleForm} disabled={disabled}>Edit</button>}
                </div>

                {
                    topicType && topicTypeArray.includes(topicType) &&
                        <>
                            <div className="my-2 btn-grup linkbtn-grup">

                                <ButtonWithIcon title="Link Right Question" show={true}
                                                onClick={() => this.openRightLinkedDialog()}
                                                disabled={disabled}
                                                iconClass={"fa fa-link text-success"} />

                                <ButtonWithIcon  onClick={() => this.unLinkQuestionAction(question.id, 'Right')}
                                                 title="Unlink Right Question"
                                                 iconClass={"fa fa-chain-broken text-danger"}
                                                 disabled={disabled}
                                                 show={question?.link_right_question_id}/>

                                <ButtonWithIcon title="Link Right Problem Set" show={true}
                                                onClick={() => this.openRightLinkedProblemSet()}
                                                disabled={disabled}
                                                iconClass={"fa fa-link text-success"} />

                                <ButtonWithIcon  onClick={() => this.unLinkProblemSetAction(question.id, 'Right')}
                                                 title="Unlink Right Problem Set"
                                                 iconClass={"fa fa-chain-broken text-danger"}
                                                 disabled={disabled}
                                                 show={question?.link_right_problemset_id}/>

                            </div>

                            <div className="my-2 btn-grup linkbtn-grup">

                                <ButtonWithIcon title="Link Wrong Question" show={true}
                                                onClick={() => this.openWrongLinkedDialog()}
                                                disabled={disabled}
                                                iconClass={"fa fa-link text-success"} />

                                <ButtonWithIcon  onClick={() => this.unLinkQuestionAction(question.id, 'Wrong')}
                                                 title="Unlink Wrong Question"
                                                 iconClass={"fa fa-chain-broken text-danger"}
                                                 disabled={disabled}
                                                 show={question?.link_wrong_question_id}/>

                                <ButtonWithIcon title="Link Wrong Problem Set" show={true}
                                                onClick={() => this.openWrongLinkedProblemSet()}
                                                disabled={disabled}
                                                iconClass={"fa fa-link text-success"} />

                                <ButtonWithIcon  onClick={() => this.unLinkProblemSetAction(question.id, 'Wrong')}
                                                 title="Unlink Wrong Problem Set"
                                                 iconClass={"fa fa-chain-broken text-danger"}
                                                 disabled={disabled}
                                                 show={question?.link_wrong_problemset_id}/>

                            </div>
                        </>
                }
            </div>
        );
    }
}

const ButtonWithIcon = ({title = "", show = false, onClick = () => {}, iconClass="", disabled = false}) => {
    if (!show) return null;
    return (
        <button className="btn btn-default mr-2" onClick={() => onClick()} disabled={disabled}>
            <i className={iconClass}/> {title}
        </button>
    )
}

export default QuestionEditor;
