import { API } from '../../api';
import {RECEIVE_ACT_PROBLEM_SETS, RESET_ACT_PROBLEM_SETS} from "../constant";
import { checkSessionExpired } from "..";

const receiveActProblemSets = (data) => ({type: RECEIVE_ACT_PROBLEM_SETS, data });
export const resetSatProblemSets = () => ({type: RESET_ACT_PROBLEM_SETS });

export const getActProblemSets = (id) => {
    return (dispatch) => {
        return API.get(`/acts/test_acts/${id}/problem_sets`).then(({ data }) => {
            dispatch(receiveActProblemSets(data))
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

