import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import * as dialogActions from '../../modules/dialog/action';

import './HomeSidebar.scss'
import { INTRO_VIDEO_DIALOG_BOX } from '../dialogs/dialogs';
import Logoduology from "../../images/logoduology.png"
import AppIcon from "../../images/app_icon.png"

const mapDispatchToProps = dispatch => {
    return ({
        openDialog: (config, options) => dispatch(dialogActions.open(config, options)),
    })
}

class HomeSidebar extends Component {
    openDialogBox = async () => {
        const { openDialog } = this.props
        openDialog(INTRO_VIDEO_DIALOG_BOX, {})
    }
    render() {
        const { match, account, location } = this.props;
        return (
                <aside className="sidenav-main nav-expanded nav-lock nav-collapsible sidenav-light sidenav-active-square" >
                    <div className="brand-sidebar">
                        <h1 className="logo-wrapper d-flex align-items-center position-relative">
                            <a className="navbar-toggler" >
                                <span className="hamburger_icon">Sidebar Toggle Button</span>
                            </a>
                            <a className="brand-logo" href="../index.html">
                                <img className="hide-on-collapse" src={Logoduology} alt="logo" />
                                <img className="show-on-collapse hide-on-full" src={AppIcon} alt="logo" />
                            </a>
                        </h1>
                    </div>
                    <ul className="sidenav sidenav-collapsible leftside-navigation collapsible sidenav-fixed menu-shadow" id="slide-out" data-menu="menu-navigation" data-collapsible="menu-accordion">
                        {account.userType === "Tutor" ?
                            <li className={`bold ${location.pathname ==="/home/students" ? "active" : ''}`}>
                                <NavLink className="nav_link" to={`${match.url}/students`}>
                                    <i className="navico student"/>
                                    <span className="menu-title">Students</span>
                                </NavLink>
                            </li>
                        :
                        <>
                        <li className={`bold ${location.pathname ==="/home" ? "active" : ''}`}>
                            <NavLink className="nav_link" to={`${match.url}`} exact>
                                <i className="navico dash"/>
                                <span className="menu-title">Dashboard</span>
                                {/* <!-- <span className="badge badge pill orange float-right mr-10">3</span> --> */}
                            </NavLink>
                        </li>
                        { account.is_tutor ? <li className={`bold ${location.pathname ==="/home/students" ? "active" : ''}`}>
                            <NavLink className="nav_link" to={`${match.url}/students`}>
                                <i className="navico student"/>
                                <span className="menu-title">Students</span>
                            </NavLink>
                        </li> : ""}
                        <li  className={`bold ${location.pathname ==="/home/sats" ? "active" : ''}`}>
                            <NavLink className="nav_link"to={`${match.url}/sats`}>
                                <i className="navico SAT course"/>
                                <span className="menu-title">SATs</span>
                            </NavLink>
                        </li>
                        <li  className={`bold ${location.pathname ==="/home/acts" ? "active" : ''}`}>
                            <NavLink className="nav_link" to={`${match.url}/acts`}>
                                <i className="navico ACT course"/>
                                <span className="menu-title">ACTs</span>
                            </NavLink>
                        </li>
                        <li  className={`bold ${location.pathname ==="/home/math" ? "active" : ''}`}>
                            <NavLink className="nav_link" to={`${match.url}/math`}>
                                <i className="navico math course"/>
                                <span className="menu-title">Math</span>
                            </NavLink>
                        </li>
                        <li  className={`bold ${location.pathname ==="/home/grammar" ? "active" : ''}`}>
                            <NavLink className="nav_link" to={`${match.url}/grammar`}>
                                <i className="navico grmr course"/>
                                <span className="menu-title">Grammar</span>
                            </NavLink>
                        </li>
                        <li  className={`bold ${location.pathname ==="/home/reading" ? "active" : ''}`}>
                            <NavLink className="nav_link" to={`${match.url}/reading`}>
                                <i className="navico read course"/>
                                <span className="menu-title">Reading</span>
                            </NavLink>
                        </li>
                        <li  className="bold">
                            <a className="nav_link" onClick={this.openDialogBox} href="#">
                                <i className="navico help"/>
                                <span className="menu-title">Help</span>
                            </a>
                        </li>
                        </>}
                    </ul>
                    <div className="navigation-background"/>
                    <a className="sidenav-trigger btn-sidenav-toggle btn-floating btn-medium waves-light hide-on-large-only" href="#" data-target="slide-out"><i className="material-icons">menu</i></a>
            </aside>
        );
    }
}

export default withRouter(connect(null, mapDispatchToProps)(HomeSidebar));
