import { API } from '../../api';
import * as Config from "../../config";
import { alertPushMessage } from "..";
import {
    SET_USER_DATA,
    GET_USER_CORRECT_INCORRECT_QUESTIONS,
    GET_TIME_SPENT_BY_USER,
    GET_AVG_TIME_SPENT_BY_USER,
    SAVE_USER_QUESTIONS_PROGRESS,
    LOG_OUT_USER,
    RESET_USER_DATA,
    LOGIN_USER,
    LOGIN_BACK_FROM_STUDENT,
    STUDENT_LOGIN,
    STUDENT_UNLINKED
} from "../constant";
import {studentUnlinkedEvent} from "../../components/helper/CustomEvent";

const setUserData = (account) =>  ({ type: SET_USER_DATA, account })
const resetUserData = (account) =>  ({ type: RESET_USER_DATA, account })
const logOutUser = () =>  ({ type: LOG_OUT_USER })
const receiveUserCorrectIncorrectQuestions = (data) =>  ({ type: GET_USER_CORRECT_INCORRECT_QUESTIONS, data });
const receiveTimeSpentByUser = (data) =>  ({ type: GET_TIME_SPENT_BY_USER, data });
const receiveAvgTimeSpentByUser = (data) => ({ type: GET_AVG_TIME_SPENT_BY_USER, data });
const receiveUserQuestionsProgress = (data) => ({ type: SAVE_USER_QUESTIONS_PROGRESS, data })

export const checkSessionExpired = (message) => {
    if(!message) return null;
    return (dispatch) => {
        if(message.includes("Session expired") || message.includes("Account not active, Please contact admin.")){
            dispatch(logOutUser());
            dispatch(alertPushMessage({message: message, type: "error"}));
        } if(message.includes("Student unlinked from your account")) {
            localStorage.setItem("eventType", STUDENT_UNLINKED);
            window.dispatchEvent(studentUnlinkedEvent);
        } else {
            dispatch(alertPushMessage({message: message, type: "error"}));
        }
    }
}

export const login = (attributes) => {
    return (dispatch) => {
        return API.post("/sessions", attributes).then(async ({ data, headers }) => {
            const auth = headers[Config.userToken()];
            await dispatch(setUserData({ ...data, auth, loggedIn: true, timestamp: new Date() }));
            await localStorage.setItem("eventType", LOGIN_USER);
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
};

export const studentLogin = (id) =>{
    return (dispatch) => {
        return API.put("/sessions/login_as_student", {id: id}).then(async ({ data, headers }) => {
            const auth = headers[Config.userToken()];
            await dispatch(resetUserData({ ...data, auth, loggedIn: true,timestamp: new Date() }));
            await localStorage.setItem("eventType", STUDENT_LOGIN);
            return true;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}


export const backToTutorLogin = (history) =>{
    return (dispatch) => {
        return API.put("/sessions/login_back_to_tutor").then(async ({ data, headers }) => {
            const auth = headers[Config.userToken()];
            await dispatch(setUserData({ ...data, auth, loggedIn: true }));
            await history.push({pathname: '/home/students', params: { backToTutor: true}});
            await dispatch(resetUserData({ ...data, auth, loggedIn: true, timestamp: new Date() }));
            await localStorage.setItem("eventType", LOGIN_BACK_FROM_STUDENT);
            return true;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const logout = () => {
    return (dispatch) => {
        return API.delete("/sessions").then(({ data }) => {
            dispatch(logOutUser());
            dispatch(alertPushMessage({message: data.message, type: "success"}));
        }).catch((error)=>{
            dispatch(logOutUser());
            dispatch(checkSessionExpired(error.message));
        });
    }
};

export const forgotPassword = (attributes) => {
    return (dispatch) => {
        return API.put("/accounts/forgot_password", attributes).then(({ data }) => {
            dispatch(alertPushMessage({message: data.message, type: "success"}));
            return data.message;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const resetPassword = (getData) => {
    return (dispatch) => {
        return API.put("/accounts/reset_password", getData).then(({ data }) => {
            dispatch(alertPushMessage({message: data.message, type: "success"}));
            return data.message;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const validateCurrentUser = () => {
    return (dispatch) => {
        return API.put('/accounts/set_current_user').then(({ data }) => {
            dispatch(setUserData(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
        })
    }
}

export const registerAccount = (attributes) => {
    return (dispatch) => {
        return API.post('/accounts',attributes).then(({ data }) => {
            dispatch(alertPushMessage({message: data.message, type: "success"}));
            return data.message;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        })
    }
}

export const getUserCorrectIncorrectQuestions = (endpoint, attributes) =>{
    return(dispatch) => {
        return API.post(endpoint, attributes).then(({ data }) => {
            dispatch(receiveUserCorrectIncorrectQuestions(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
        })
    }
}

export const getTimeSpentByUser = (endpoint, attributes) =>{
    return(dispatch) => {
        return API.post(endpoint, attributes).then(({ data }) => {
            dispatch(receiveTimeSpentByUser(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
        })
    }
}

export const getAvgTimeSpentByUser = (endpoint, attributes) =>{
    return(dispatch) => {
        return API.post(endpoint, attributes).then(({ data }) => {
            dispatch(receiveAvgTimeSpentByUser(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
        })
    }
}

export const saveUserQuestionsProgress = (endpoint, attributes) => {
      return (dispatch) => {
        return API.post(endpoint, attributes).then(({ data }) => {
            dispatch(receiveUserQuestionsProgress(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
        })
    }
}
