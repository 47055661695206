import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import AccountMenu from '../account/AccountMenu';
import './QuestionHeader.scss';

class TopicHeader extends Component {

    render() {
        const {currentTopic} = this.props;
        return (
            <div className="question-header">
                <Link to="/home">Home</Link>
                <h2>{currentTopic.title}</h2>
                <AccountMenu />
            </div>
        );
    }
}

export default (TopicHeader);
