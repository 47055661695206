import React, { Component } from 'react';
import Logoduology from "../../images/logoduology.png"
import AppIcon from "../../images/app_icon.png"
import Avatar from "../../images/avatar/user_placehoder_avatar@2x.png"
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as dialogActions from '../../modules/dialog/action';
import * as accountActions from '../../modules/account/action';
import AccountDropdown from '../account/AccountDropdown';

class Navbar extends Component {
  logout = (evt) => {
    this.props.logout()
  }

  render() {
    const { account, match, backToTutor } = this.props
    return (
      <>
        <header className="page-topbar" id="header">
          <div className="navbar navbar-fixed">
            <nav className="navbar-main nav-collapsible sideNav-lock">
              <div className="nav-wrapper">
                <div className="brand-sidebar">
                  <h1 className="logo-wrapper d-flex align-items-center position-relative">
                    <a className="navbar-toggler" >
                      <span className="hamburger_icon">Sidebar Toggle Button</span>
                    </a>
                    <a className="brand-logo" href="#">
                      <img className="hide-on-collapse" src={Logoduology} alt="logo" />
                      <img className="show-on-collapse hide-on-full" src={AppIcon} alt="logo" />
                    </a>
                  </h1>
                </div>
                <ul className="navbar-list right">
                  <li>
                    <a className="waves-effect waves-block waves-light profile-button" data-target="profile-dropdown">
                      <span className="user-avatar avatar-status avatar-online">
                        <img src={Avatar} alt="avatar" />
                      </span>
                      <span className="avatar-name mr-3">
                        <span className="text-truncate">{account.username}</span>
                        <i className="ico_arrow"/>
                      </span>
                    </a>
                  </li>
                </ul>
                {/* <!-- profile-dropdown--> */}
                <AccountDropdown logout={this.logout}
                                 account={account}
                                 match={match}
                                 backToTutor={backToTutor} />
              </div>
            </nav>

          </div>

        </header>
      </>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return ({
    openDialog: (config, options) => dispatch(dialogActions.open(config, options)),
    logout: () => dispatch(accountActions.logout()),
  })
}

export default withRouter(connect(null, mapDispatchToProps)(Navbar));