import { RECEIVE_ALL_TOPICS, RECEIVE_TOPIC, UPDATE_TOPIC, REMOVE_TOPIC, PUBLISH_TOPIC } from '../constant';

export const topicReducer = (state = [], action) => {
    let newState;
    switch (action.type) {
        case RECEIVE_ALL_TOPICS:
            return action.data
        case RECEIVE_TOPIC:
            newState = Object.assign([], state);
            for (let i = 0; i < newState.length; i++) {
                if (action.data.id === newState[i].id) {
                    newState[i] = action.data
                    return newState;
                }
            }
            return Object.assign([], state, [...state, action.data])
        case UPDATE_TOPIC:
            newState = state.map(obj => (action.data.id === obj.id) ? action.data : obj);
            return newState;
        case REMOVE_TOPIC:
            newState = state.filter(obj => (action.data.id !== obj.id));
            return newState;
        case PUBLISH_TOPIC:
            newState = state.map(obj => (action.data.id === obj.id) ? action.data : obj);
            return newState;
        default:
            return state;
    }
}
