import {Modifier} from 'draft-js';
import {OrderedSet} from 'immutable';


export const getCurrentStyleWithPrefix = (editorState, stylePrefix) => {
    return getAllStylesWithPrefixInCurrentSelection(editorState, stylePrefix);
}

// Remove old styles before adding new styles
export const getResetStateForMultiOptionStyle = (editorState, stylePrefix) => {
    const selection = editorState.getSelection();
    const currentContent = editorState.getCurrentContent();
    const stylesToBeRemoved = getAllStylesWithPrefixInCurrentSelection(editorState, stylePrefix);

    const nextContentState = stylesToBeRemoved.reduce((contentState, style) => {
        return Modifier.removeInlineStyle(contentState, selection, style);
    }, currentContent)

    return nextContentState;
}

// Find styles that match our stylePrefix
const getAllStylesWithPrefixInCurrentSelection = (editorState, stylePrefix) => {
    const blocks = getSelectionBlockMap(editorState);

    // union is an OrderedSet method
    const characterStyles = blocks.reduce((memo, block) => {
        memo = memo.union(getCharacterStylesWithPrefix(block, stylePrefix));
        return memo;
    }, OrderedSet());

    return characterStyles.toSet();
}

// Return all contentBlock's within the Selection range
const getSelectionBlockMap = (editorState) => {
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    const endKey = selection.getEndKey();

    const currentContent = editorState.getCurrentContent();
    let currentKey = startKey;
    const selectionBlockMap = [];
    while(true) {
        const contentBlock = currentContent.getBlockForKey(currentKey);
        selectionBlockMap.push(contentBlock);
        if(currentKey === endKey) {
            break;
        } else {
            currentKey = currentContent.getKeyAfter(currentKey);
        }
    }

    return selectionBlockMap;
}

// Check characters for style matching the stylePrefix
const getCharacterStylesWithPrefix = (block, stylePrefix) => {
    const characterList = block.getCharacterList();
    let characterStyles = [];

    characterList.forEach(char => {
        const styles = char.getStyle().toObject();
        const keys = Object.keys(styles);
        keys.forEach(key => {
            const re = new RegExp(stylePrefix);
            if (re.exec(key)) {
                characterStyles.push(key);
            }
        })
    })

    return characterStyles;
}
