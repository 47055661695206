import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { validSelect } from '../../../../utilities/selectUtil';
import * as animationActions from '../../../../modules/animation/action';
import * as problemSetQuestionActions from '../../../../modules/problemSetQuestion/action';
import * as userProgressActions from "../../../../modules/userProgress/action";
import ViewProblemSetQuestions from './ViewProblemSetQuestions'
import './HomeProblemSetQuestions.scss'
import _ from 'lodash'

const mapStateToProps = state => {
    return ({
        account: state.account,
        problemsetQuestions: state.problemset_questions
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        startAnimation: () => dispatch(animationActions.start()),
        endAnimation: () => dispatch(animationActions.end()),
        resetProblemSetQuestions: () => dispatch(problemSetQuestionActions.resetProblemSetQuestions()),
        getProblemSetQuestions: (params) => dispatch(problemSetQuestionActions.getAllProblemSetQuestions(params)),
        getUserProblemsetProgress: (params) => dispatch(userProgressActions.getUserProblemSetProgress(params)),
    })
}

class HomeProblemSetQuestions extends Component {

    async componentDidMount() {
        const {
            getProblemSetQuestions, topicId, startAnimation, endAnimation, topicType, getUserProblemsetProgress,
            account, resetProblemSetQuestions
        } = this.props;
        await startAnimation();
        await resetProblemSetQuestions();
        await getUserProblemsetProgress({ reference_id: topicId, reference_type: topicType});
        await getProblemSetQuestions({ problem_set_id: topicId, position: 'asc' });
        await endAnimation();
    }

    render() {
        const { topicId, topicType, section, loading, account, satQuestionId, takeBack, problemsetQuestions } = this.props;
        if (!validSelect(topicId)) return null;

        let userProblemsetProgress = '', userProblemsetProgressQuestion = '';
        if (_.keys(account.problemsetProgress).length > 0) {
            userProblemsetProgress = account.problemsetProgress
            userProblemsetProgressQuestion = _.find(problemsetQuestions, (qq) => qq.id === userProblemsetProgress.question_id)
        }

        return (
            <div className={`home-problemset-questions home-questions ${loading ? 'loading-topics' : ''}`}>
                {
                    problemsetQuestions && problemsetQuestions.length > 0 &&
                        <ViewProblemSetQuestions topicId={topicId}
                                                satQuestionId={satQuestionId}
                                                topicType={topicType}
                                                section={section}
                                                userProblemsetProgress={userProblemsetProgress}
                                                problemsetQuestions={problemsetQuestions}
                                                firstProblemSetQuestion={userProblemsetProgress && userProblemsetProgressQuestion ? userProblemsetProgressQuestion : problemsetQuestions ? problemsetQuestions[0] : ''}
                                                takeBack={takeBack}/>
                }
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeProblemSetQuestions));
