import React, {Component} from 'react';
import {connect} from 'react-redux';
import BucketAnswers from '../../bucket/BucketAnswers';

class CreateAnswerModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formActive: false,
        }
    }

    toggleBucket = () => {
        const { bucketActive } = this.state;
        this.setState({ bucketActive: !bucketActive })
    }

    toggleForm = () => {
        const { toggleForm, close } = this.props;
        toggleForm();
        close();
    }

    renderContent() {
        const { close, questionId, bucketAnswers, answers, createAndAddAnswer, question } = this.props;
        const { bucketActive } = this.state;
        if (bucketActive) {
            return (
                <React.Fragment>
                    <BucketAnswers createAndAddAnswer={createAndAddAnswer}
                                   answers={answers}
                                   bucketAnswers={bucketAnswers}
                                   fncType={"createAnswer"}
                                   questionId={questionId}
                                   question={question}
                                   close={close} />
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <button className="btn btn-primary" onClick={this.toggleForm}>Create new</button>
                    <button className="btn btn-primary" onClick={this.toggleBucket}>Create from bucket</button>
                </React.Fragment>
            )
        }
    }

    render() {
        return (
            <React.Fragment>
                <div>
                    {this.renderContent()}
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return ({
        bucketAnswers: state.bucket.answers,
    })
}

export const CreateAnswerDialog = connect(mapStateToProps, null)(CreateAnswerModal);
