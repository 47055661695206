import React, {Component} from 'react';
import {EditorState, RichUtils} from 'draft-js';
import "./ToolTipSelection.scss";
import * as dialogActions from "../../../../modules/dialog/action";
import {connect} from "react-redux";
import {OPEN_TOOLTIP_DIALOG_BOX} from "../../../dialogs/dialogs";

class ToolTipSelection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showURLInput: false,
            tooltipValue: '',
        };

        this.promptForToolTip = this._promptForToolTip.bind(this);
        this.onURLChange = (e) => this.setState({tooltipValue: e.target.value});
        this.confirmToolTip = this._confirmToolTip.bind(this);
        this.removeToolTip = this._removeToolTip.bind(this);
    }

    // this.onChange = (editorState) => this.setState({editorState});


    _promptForToolTip() {
        const {editorState, openDialog} = this.props;
        const selectedText = getCurrentTextSelection(editorState);
        if(selectedText === '' || selectedText === undefined) return alert("Please select text first");
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            const contentState = editorState.getCurrentContent();
            const startKey = selection.getStartKey();
            const startOffset = selection.getStartOffset(startKey);
            const blockWithToolTipAtBeginning = contentState.getBlockForKey(startKey);
            const linkKey = blockWithToolTipAtBeginning.getEntityAt(startOffset);

            let tooltip = '';
            if (linkKey) {
                const linkInstance = contentState.getEntity(linkKey);
                tooltip = linkInstance.getData().tooltip;
            }

            openDialog(OPEN_TOOLTIP_DIALOG_BOX, {
                text: selectedText,
                value: tooltip,
                confirmToolTip: this.confirmToolTip,
                removeToolTip: this.removeToolTip,
            });
        }
    }

    _confirmToolTip(tooltipValue = "") {
        const {editorState, onChange} = this.props;
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            'TOOLTIP',
            'MUTABLE',
            {tooltip: tooltipValue}
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, {currentContent: contentStateWithEntity});
        onChange(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey));
    }

    _removeToolTip() {
        const {editorState, onChange} = this.props;
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            onChange(RichUtils.toggleLink(editorState, selection, null));
        }
    }

    render() {
        return (
            <>
                <div className="tip-selection">
                    <div className="current-size"  onClick={this.promptForToolTip}>
                        <p className="tooltip-box">
                            T
                        </p>
                    </div>
                </div>
            </>
        );
    }
}

const getCurrentTextSelection = (editorState) => {
    const selectionState = editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    const currentContent = editorState.getCurrentContent();
    const currentContentBlock = currentContent.getBlockForKey(anchorKey);
    const start = selectionState.getStartOffset();
    const end = selectionState.getEndOffset();
    return currentContentBlock.getText().slice(start, end);
};

const mapDispatchToProps = dispatch => {
    return ({
        openDialog: (config, options) => dispatch(dialogActions.open(config, options)),
    })
}

export default connect(null, mapDispatchToProps)(ToolTipSelection);
