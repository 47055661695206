export const defaultConfig = {
    REACT_APP_PUBLIC_BASE_URL: 'http://localhost:3000/',
    REACT_APP_API_URL: 'http://localhost:5000/api/v1/',
    MS_ALERT_DISPLAY_TIME: 5000,
    TABLE_PAGE_LIMIT: 10,
    USER_TOKEN: "x-user-token"
};

export const Duology = {
    config: defaultConfig,
};

Duology.config = { ...defaultConfig, ...process.env, ...window.env };

export const apiUrl = () => Duology.config.REACT_APP_API_URL;
export const appUrl = () => Duology.config.REACT_APP_PUBLIC_BASE_URL;
export const msAlertDisplayTime = () => Duology.config.MS_ALERT_DISPLAY_TIME;
export const tablePageLimit = () => Duology.config.TABLE_PAGE_LIMIT;
export const userToken = () => Duology.config.USER_TOKEN;
