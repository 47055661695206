import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AdminQuestions from '../question/AdminQuestions';
import * as satQuestionActions from '../../../../../modules/satQuestion/action';
import * as problemSetActions from '../../../../../modules/problemSet/action';
import './AdminSubtopicMain.scss';

class AdminSatQuestionMain extends Component {
    state = {
        title: "",
        topicTitle: "",
        showTopics: false,
        testId: "",
        satId: ""
    }
    async componentDidMount() {
        window.scrollTo(0, 0);
        let error404 = false;
        const { match: { params: { satQuestionId } }, getSatQuestions } = this.props;
        const satTopic = await getSatQuestions(satQuestionId).catch((error)=>{
            error404 = error.response?.status === 404;
        });
        const { getSatProblemSets } = this.props
        await getSatProblemSets({ sat_question_id: satQuestionId })
        this.setState({ topicTitle: satTopic?.title, satId: satTopic?.sat_id })
        if((!satTopic?.sat_id && satTopic?.act_id) || error404){
            this.props.history.push('/admin/not_found');
        }
    }

    takeBack = () => {
        const { match: { params: { satQuestionId } } } = this.props;
        const { satId } = this.state
        this.props.history.push('/admin?sat=' + satId + '&sat_question=' + satQuestionId)
    }

    toggleForm = () => {
        const { showTopics } = this.state
        this.setState({ showTopics: !showTopics })
    }
    render() {
        const { match: { params: { satQuestionId } }, problemsets } = this.props;
        const { topicTitle } = this.state;
        let topicType = 'SatQuestionTopic';

        // alert(topicType)
        return (
            <>
                <div className="row" >
                    <div className="content-wrapper-before gradient-45deg-indigo-purple"/>
                    <div className="col s12 contentCenter_wrap">
                        <div className="container">
                            <div className="dash_top_section"/>
                            <div className="row vertical-modern-dashboard">
                                <div className="col-12">
                                    <div className="card animate fadeLeft">
                                        <div className="question_sec card-content">
                                            <div className="dashtitle_sec d-flex flex-wrap align-items-center w-100">
                                                <button onClick={() => this.takeBack()} className="btn btn-default">
                                                    <i className="ico backicon"/>
                                                    <span className="btntxt">Back</span>
                                                </button>
                                            </div>
                                            <div className="quesHeader d-flex flex-wrap align-items-center text-center w-100">
                                                <h1 className=" quesTitle d-inline-block position-relative">
                                                    {topicTitle}
                                                </h1>
                                            </div>

                                            <AdminQuestions
                                                topicType={topicType}
                                                topicId={satQuestionId}
                                                section="SatQuestionTopic"
                                                problemsets={problemsets[satQuestionId]}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            </>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return ({
        // getSubtopic: (id) => dispatch(subtopicActions.getSubTopic(id)),
        // getSubSubtopics: (params) => dispatch(subSubTopicActions.getAllSubSubTopics(params)),
        // getTest: (id) => dispatch(testActions.getTest(id))
        getSatQuestions: (id) => dispatch(satQuestionActions.getSatQuestion(id)),
        getSatProblemSets: (params) => dispatch(problemSetActions.getSatProblemSets(params)),
    })
}

const mapStateToProps = ({ subsubtopics, subtopics, problemsets }) => ({ subsubtopics, subtopics, problemsets })

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminSatQuestionMain));
