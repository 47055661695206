import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ReadOnlyEditor from '../../editor/ReadOnlyEditor';
import './PreviewQuestionDialog.scss'

class PreviewQuestionModal extends Component {

    render() {
        const { question, previewAnswers } = this.props
        const j = 65;
        return (
            <div className="preview-question--inner">
                <ReadOnlyEditor content={question.question} />
                <div className="preview-question--ans">
                    <ul>
                        {

                            previewAnswers.map((answer, index) => {
                                return (
                                    <li key={index}>
                                        <span>{String.fromCharCode(index + j)}</span>
                                        <div className="ans-content">
                                            <PreviewAnswer answer={answer}/>
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        );
    }
}

const PreviewAnswer = ({answer}) =>{
    if(answer.is_free_response){
        return<span className="d-inline-flex">
            <div>{answer.free_response_answer}</div>
            <ReadOnlyEditor content={answer.symbol} />
        </span>
    }
    return <ReadOnlyEditor content={answer.answer} />;
}

export const PreviewQuestionDialog =  withRouter(PreviewQuestionModal);
