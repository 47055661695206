import React, { Component } from 'react';
import './EditQuestionRank.scss';

const colorMapping = {
    0: "blue",
    1: "red",
    2: "yellow",
    3: "green",
    4: "orange"
}

class EditQuestionRank extends Component {
    state = {
        activeRank: null
    }

    componentDidMount() {
        const { rank } = this.props;
        this.setState({ activeRank: rank })
    }

    handleClick(idx) {
        const { updateRank } = this.props;
        this.setState({ activeRank: idx })
        updateRank(idx);
    }

    render() {
        const { activeRank } = this.state;
        const { disabled, hidden } = this.state;
        if(hidden) return null;
        const ranking = [0, 1, 2, 3, 4];
        return (
            <div className="edit-question-rank">
                <ul>
                    {ranking.map((x, idx) => {
                        let classes = [];
                        if (idx === activeRank) {
                            classes.push(colorMapping[idx])
                        } else {
                            classes.push('inactive')
                        }
                        return (
                            <li key={`${colorMapping[idx]}${idx}`} onClick={() => this.handleClick(idx)} className={classes.join(' ')} disabled={disabled}>
                                {idx + 1}
                            </li>
                        )
                    })}
                </ul>
            </div>
        );
    }
}

export default EditQuestionRank;
