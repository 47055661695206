import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as accountActions from '../../modules/account/action';
import * as userSatsActions from '../../modules/userSat/action';
import * as userActsActions from '../../modules/userAct/action';
import SharedGraph from "./SharedGraph"
import Assignment from "./dashboard/Assignment";
import * as userProgressActions from "../../modules/userProgress/action";
import * as userSatTestProgressActions from "../../modules/userSatTestProgress/action";
import * as userActTestProgressActions from "../../modules/userActTestProgress/action";
import * as DashboardHelper from './dashboard/DashboardHelper';
import * as settingActions from "../../modules/setting/action";

const { monthNames } = DashboardHelper;
class Dashboard extends Component {

    state = {
        selectedYear: new Date().getFullYear(),
        selectedMonth: monthNames[new Date().getMonth()],
        monthOptions: [],
        yearOptions: [],
        actTimedType: "All",
        satTimedType: "All",
        actTestType: "All",
        satTestType: "All",
        satScoreType: "Scores",
        actScoreType: "Scores",
    }

    async componentDidMount() {
        window.scrollTo(0,0);
        const {
            account, getSetting, getUserSatScores, getUserSatTestScores, getUserSatsProgress, getUserActScores,
            getUserActTestScores, getUserActsProgress
        } = this.props
        await getSetting();
        await getUserSatScores();
        await getUserActScores();
        await getUserSatTestScores();
        await getUserActTestScores({ user_id: account.id });
        await getUserSatsProgress();
        await getUserActsProgress();
    }

    onSelectTimedFilter = (topic_type, value) => {
        if (topic_type === "act") {
            this.setState({ actTimedType: value })
        } else {
            this.setState({ satTimedType: value })
        }
    }

    onSelectTestFilter = (topic_type, value) => {
        if (topic_type === "act") {
            this.setState({ actTestType: value })
        } else {
            this.setState({ satTestType: value })
        }
    }

    render() {
        const { actTimedType, satTimedType, actTestType, satTestType, satScoreType, actScoreType } = this.state;
        const {
            account, userSatScores, userActScores, userSatsProgress, userActsProgress, setting,
            userSatTestScores, userActTestScores
        } = this.props;
        const correctIncorrectData = account && account.userCorrectIncorrectQuestions;
        const timeSpentByUserData = account && account.timeSpentByUser;
        const avgTimeSpentByUserData = account && account.avgTimeSpentByUser;
        const satPercentileScore = setting && setting.sat_percentile_score;
        const actPercentileScore = setting && setting.act_percentile_score;

        // User ACT's scores Data
        const satScoreFinalData = DashboardHelper.getSatScoreData(userSatScores, satTimedType, satScoreType, satPercentileScore);
        // User ACT's scores Data
        const actScoreFinalData = DashboardHelper.getActScoreData(userActScores, actTimedType, actScoreType, actPercentileScore);
        // User SAT's Progress Data
        const satProgressFinalData = DashboardHelper.getProgressData(userSatsProgress, "Sat");
        // User ACT's Progress Data
        const actProgressFinalData = DashboardHelper.getProgressData(userActsProgress, "Act");
        // User SAT's test scores Data
        const satTestScoreFinalData = DashboardHelper.getFinalTestScoreData(userSatTestScores, satTestType,'sat');
        // User ACT's test scores Data
        const actTestScoreFinalData = DashboardHelper.getFinalTestScoreData(userActTestScores, actTestType,'act');
        // User Correct Incorrect Data
        const correctIncorrectFinalData = DashboardHelper.getCorrectIncorrectData(correctIncorrectData);
        // User Time Spent By user Data
        const timeSpentFinalData = DashboardHelper.getTimeSpentData(timeSpentByUserData);
        // User average Time Spent By user Data
        const avgTimeSpentFinalData = DashboardHelper.getAvgTimeSpentData(avgTimeSpentByUserData);

        return (
            <>
                <div className="row">
                    <div className="content-wrapper-before gradient-45deg-indigo-purple"/>
                    <div className="col s12">
                        <div className="container">
                            <div className="dash_top_section">
                                <h2 className="dash_title">Dashboard</h2>
                                <div className="row vertical-modern-dashboard">
                                    <div className="col-12">
                                        {/* <!-- Current Balance --> */}
                                        <Assignment />
                                    </div>
                                    <div className="col-12 animate fadeRight">
                                        <div className="card">
                                            <div className="dashGrapg_sec card-content">
                                                <SharedGraph data={correctIncorrectFinalData}
                                                             title="Completed Questions"
                                                             yAxisTitle="Number of questions"
                                                             onChangeFilterAction={this.props.getUserCorrectIncorrectQuestions}
                                                             selectedMonth={this.state.selectedMonth}
                                                             selectedYear={this.state.selectedYear}
                                                             monthOptions={this.state.monthOptions}
                                                             yearOptions={this.state.yearOptions}
                                                             monthFilter={this.monthFilter}
                                                             account={account}
                                                             monthNames={monthNames}
                                                             filters={true}/>
                                            </div>
                                        </div>
                                    </div>
                                    {account.tutor_login ? <>
                                        <div className="col-12 animate fadeRight">
                                            <div className="card">
                                                <div className="dashGrapg_sec card-content">
                                                    <SharedGraph data={timeSpentFinalData}
                                                                 title="Total Time Spent"
                                                                 yAxisTitle="Time Spent (Hours)"
                                                                 onChangeFilterAction={this.props.getTimeSpentByUser}
                                                                 selectedMonth={this.state.selectedMonth}
                                                                 selectedYear={this.state.selectedYear}
                                                                 monthOptions={this.state.monthOptions}
                                                                 yearOptions={this.state.yearOptions}
                                                                 monthFilter={this.monthFilter}
                                                                 account={account}
                                                                 monthNames={monthNames}
                                                                 filters={true}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 animate fadeRight">
                                            <div className="card">
                                                <div className="dashGrapg_sec card-content">
                                                    <SharedGraph data={avgTimeSpentFinalData}
                                                                 title="Average Time Spent Per Question"
                                                                 yAxisTitle="Time Spent (Minutes)"
                                                                 onChangeFilterAction={this.props.getAvgTimeSpentByUser}
                                                                 selectedMonth={this.state.selectedMonth}
                                                                 selectedYear={this.state.selectedYear}
                                                                 monthOptions={this.state.monthOptions}
                                                                 yearOptions={this.state.yearOptions}
                                                                 monthFilter={this.monthFilter}
                                                                 account={account}
                                                                 monthNames={monthNames}
                                                                 filters={true}/>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                        : ""}
                                    <div className="col-12 animate fadeRight">
                                        {/* <!-- Total Transaction --> */}
                                        <div className="card">
                                            <div className="dashGrapg_sec card-content">
                                                <SharedGraph data={satScoreFinalData}
                                                             title="SAT Scores"
                                                             yAxisTitle={satScoreType}
                                                             filters={false}
                                                             onChangeFilterAction={this.onSelectTimedFilter}
                                                             topic_type="sat"
                                                             timed_type={satTimedType}
                                                             toggleOptions={satPercentileScore && {options: ["Scores", "Percentile"], checked: satScoreType}}
                                                             onToggleChange={(satScoreType) => this.setState({satScoreType})}
                                                             options={satScoreType === "Percentile" ? {  yAsixMax: 100 } : {yAsixMax: 1600}}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 animate fadeRight">
                                        {/* <!-- Total Transaction --> */}
                                        <div className="card">
                                            <div className="dashGrapg_sec card-content">
                                                <SharedGraph data={satTestScoreFinalData}
                                                             title="SAT Test Scores"
                                                             yAxisTitle="Scores"
                                                             filters={false}
                                                             onChangeFilterAction={this.onSelectTestFilter}
                                                             topic_type="sat"
                                                             test_type={satTestType}
                                                             options={{ yAsixMax: 800 }}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 animate fadeRight">
                                        {/* <!-- Total Transaction --> */}
                                        <div className="card">
                                            <div className="dashGrapg_sec card-content">
                                                <SharedGraph data={satProgressFinalData}
                                                             title="SATs Progress"
                                                             yAxisTitle="Percentage"
                                                             filters={false}
                                                             options={{yAsixMax: 100}}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 animate fadeRight">
                                        {/* <!-- Total Transaction --> */}
                                        <div className="card">
                                            <div className="dashGrapg_sec card-content">
                                                <SharedGraph data={actScoreFinalData}
                                                             title="ACT Scores"
                                                             yAxisTitle={actScoreType}
                                                             filters={false}
                                                             onChangeFilterAction={this.onSelectTimedFilter}
                                                             topic_type="act"
                                                             timed_type={actTimedType}
                                                             toggleOptions={actPercentileScore && {options: ["Scores", "Percentile"], checked: actScoreType}}
                                                             onToggleChange={(actScoreType) => this.setState({actScoreType})}
                                                             options={actScoreType === "Percentile" ? {yAsixMax: 100} : {yAsixMax: 36, stepSize: 4}}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 animate fadeRight">
                                        {/* <!-- Total Transaction --> */}
                                        <div className="card">
                                            <div className="dashGrapg_sec card-content">
                                                <SharedGraph data={actTestScoreFinalData}
                                                             title="ACT Test Scores"
                                                             yAxisTitle="Scores"
                                                             filters={false}
                                                             onChangeFilterAction={this.onSelectTestFilter}
                                                             topic_type="aat"
                                                             test_type={actTestType}
                                                             options={{yAsixMax: 36, stepSize: 4}}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 animate fadeRight">
                                        {/* <!-- Total Transaction --> */}
                                        <div className="card">
                                            <div className="dashGrapg_sec card-content">
                                                <SharedGraph data={actProgressFinalData}
                                                             title="ACTs Progress"
                                                             yAxisTitle="Percentage"
                                                             filters={false}
                                                             options={{ yAsixMax: 100 }}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ >
        );
    }
}


const mapStateToProps = state => {
    return ({
        account: state.account,
        setting: state.setting,
        userActScores: state.userActs.scores.filter(userAct => userAct.score_present),
        userSatScores: state.userSats.scores.filter(userSat => userSat.score_present),
        userSatsProgress: state.userProgresses.sats,
        userActsProgress: state.userProgresses.acts,
        userSatTestScores: state.userSatTestProgress.scores.filter(userSat => userSat.score_present),
        userActTestScores: state.userActTestProgress.scores.filter(userAct => userAct.score_present),
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        getSetting: () => dispatch(settingActions.getSetting()),
        getUserSatScores: () => dispatch(userSatsActions.getAllSatsScore()),
        getUserActScores: () => dispatch(userActsActions.getAllActsScore()),
        getUserSatsProgress: () => dispatch(userProgressActions.getUserSatsProgress()),
        getUserActsProgress: () => dispatch(userProgressActions.getUserActsProgress()),
        getUserCorrectIncorrectQuestions: (params) => dispatch(accountActions.getUserCorrectIncorrectQuestions('/user_questions/correct_incorrect_questions', params)),
        getTimeSpentByUser: (params) => dispatch(accountActions.getTimeSpentByUser('/user_questions/time_spent_by_user', params)),
        getAvgTimeSpentByUser: (params) => dispatch(accountActions.getAvgTimeSpentByUser('/user_questions/average_time_spent_by_user', params)),
        getUserSatTestScores: () => dispatch(userSatTestProgressActions.getUserAllSatTestScores()),
        getUserActTestScores: () => dispatch(userActTestProgressActions.getUserAllActTestScores()),
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
