import React, {Component} from 'react';
import './QuestionRank.scss';

const colorMapping = {
    0: "blue",
    1: "red",
    2: "yellow",
    3: "green",
    4: "orange"
}

class QuestionRank extends Component {
    render() {
        const { rank, visible } = this.props;
        if(!visible) return null;
        const ranking = [0, 1, 2, 3, 4];
        return (
            <div className="question-rank">
                <ul>
                    {ranking.map((x, idx) => {
                        let classes = (idx === rank ? [colorMapping[idx]] : ['inactive']);
                        return (
                            <li key={`${colorMapping[idx]}${idx}`} className={classes.join(' ')}>
                                {idx + 1}
                            </li>
                        )
                    })}
                </ul>
            </div>
        );
    }
}

export default QuestionRank;
