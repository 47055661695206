import {
    RECEIVE_ALL_PROBLEM_SET,
    RECEIVE_PROBLEM_SET,
    UPDATE_PROBLEM_SET,
    REMOVE_PROBLEM_SET,
    LINK_PROBLEM_SET_TO_SAT,
    PUBLISH_UNPUBLISH_PROBLEM_SET,
    RECEIVE_UPDATED_PROBLEM_SET_NOTES,
    RECEIVE_MULTIPLE_PROBLEM_SET
} from '../constant';

export const problemSetReducer = (state = {}, action) => {
    let newState;
    let data;
    let id;
    switch (action.type) {
        case RECEIVE_ALL_PROBLEM_SET:
            newState = Object.assign({}, state)
            data = action.data
            if (action?.data?.result[0]?.subsubtopic_id && data.topicType !== 'SatQuestionTopic') {
                delete action.data.topicType
            } else if (action?.data?.result[0]?.sattopic_id && data.topicType === 'SatTopic') {
                delete action.data.topicType
            } else if (action?.data?.result[0]?.sat_question_id && data.topicType === 'SatQuestionTopic') {
                delete action.data.topicType
                delete action.data.sat_question_id
            } else if ((action.data.result).length === 0 && data.topicType === 'SatQuestionTopic') {
                delete action.data.topicType
                delete action.data.sat_question_id
            }
            newState = { ...state, [action.data.setId]: action.data.result }
            return newState;
        case RECEIVE_MULTIPLE_PROBLEM_SET:
            newState = Object.assign({}, state)
            for(let data of action.data){
                id = (data?.subsubtopic_id?.length > 0) ? data.subsubtopic_id[0] : data.sattopic_id ? data.sattopic_id : (data?.sat_question_id?.length > 0) ? data.sat_question_id[0] : data.act_question_id[0];
                if (!newState[id]) {
                    newState[`${id}`] = [];
                }
                let newData = newState[id]
                newState =  { ...newState, [id]: [...newData, data] };
            }
            return newState;
        case RECEIVE_PROBLEM_SET:
            newState = Object.assign({}, state)
            data = action.data;
            id = (data?.subsubtopic_id?.length > 0) ? data.subsubtopic_id[0] : data.sattopic_id ? data.sattopic_id : (data?.sat_question_id?.length > 0) ? data.sat_question_id[0] : data.act_question_id[0];
            if (!newState[id]) {
                newState[`${id}`] = [];
            }
            let newData = newState[id]
            return { ...newState, [id]: [...newData, data] };
        case UPDATE_PROBLEM_SET:
            data = action.data;
            id = data.updateId
            newState = state[id].map(obj => (action.data._id === obj._id) ? action.data : obj);
            return { ...state, [id]: newState };
        case REMOVE_PROBLEM_SET:
            newState = Object.assign({}, state)
            data = action.data;
            id = (data.subsubtopic_id && data.subsubtopic_id.length > 0) ? data.subsubtopic_id[0] : data.parentId
            let problemSet = newState[id].filter(obj => (data._id !== obj._id))
            newState[id] = problemSet
            return newState
        case LINK_PROBLEM_SET_TO_SAT:
            newState = Object.assign({}, state)
            data = action.data;
            id = data.updatedId
            if (!newState[id]) {
                newState[id] = []
            }
            newState[id].push(data)
            return newState;
        case PUBLISH_UNPUBLISH_PROBLEM_SET:
            data = action.data.result;
            newState = state[action.data.selectedId].map(obj => (data._id === obj._id) ? data : obj);
            return { ...state, [action.data.selectedId]: newState };
        case RECEIVE_UPDATED_PROBLEM_SET_NOTES:
            data = action.data.result;
            newState = state[action.data.selectedId].map(obj => (data._id === obj._id) ? data : obj);
            return { ...state, [action.data.selectedId]: newState };
        default:
            return state;
    }
}
