import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom'
import { connect } from 'react-redux';
import { BUCKET_DIALOG_BOX, RECYCLE_BIN_DIALOG_BOX } from '../../dialogs/dialogs';
import * as dialogActions from '../../../modules/dialog/action';
import './AdminSidebar.scss'
import Logoduology from "../../../images/logoduology.png"
import AppIcon from "../../../images/app_icon.png"

const mapStateToProps = state => {
    return ({
        account: state.account
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        openDialog: (config, options) => dispatch(dialogActions.open(config, options)),
    })
}

class AdminSidebar extends Component {
    openBucket = () => {
        const { openDialog } = this.props;
        openDialog(BUCKET_DIALOG_BOX, {})
    }

    openRecycleBin = () => {
        const { openDialog } = this.props;
        openDialog(RECYCLE_BIN_DIALOG_BOX, {})
    }

    render() {
        const { match, account } = this.props;
        return (
            <aside className="sidenav-main nav-expanded nav-lock nav-collapsible sidenav-light sidenav-active-square" >
                <div className="brand-sidebar">
                    <h1 className="logo-wrapper d-flex align-items-center position-relative">
                        <a className="navbar-toggler" >
                            <span className="hamburger_icon">Sidebar Toggle Button</span>
                        </a>
                        <a className="brand-logo" href="../index.html">
                            <img className="hide-on-collapse" src={Logoduology} alt="logo" />
                            <img className="show-on-collapse hide-on-full" src={AppIcon} alt="logo" />
                        </a>
                    </h1>
                </div>
                <ul className="sidenav sidenav-collapsible leftside-navigation collapsible sidenav-fixed menu-shadow" id="slide-out" data-menu="menu-navigation" data-collapsible="menu-accordion">
                    <li className="bold">
                        <NavLink className="nav_link" to='/' exact>
                            <i className="navico dash"/>
                            <span className="menu-title">Dashboard</span>

                        </NavLink>
                    </li>
                    {
                        account?.is_super_admin &&
                        <li className="bold">
                            <NavLink className="nav_link" to={`${match.url}/users`}>
                                <i className="navico user"/>
                                <span className="menu-title">Users</span>
                            </NavLink>
                        </li>
                    }
                    <li className="bold">
                        <NavLink className="nav_link" to={`${match.url}`} exact>
                            <i className="navico SAT course"/>
                            <span className="menu-title">SATs</span>
                        </NavLink>
                    </li>
                    <li className="bold">
                        <NavLink className="nav_link" to={`${match.url}/acts`}>
                            <i className="navico ACT course"/>
                            <span className="menu-title">ACTs</span>
                        </NavLink>
                    </li>
                    <li className={`bold`}>
                        <NavLink className="nav_link" to={`${match.url}/math-topics`}>
                            <i className="navico math course"/>
                            <span className="menu-title">Math</span>
                        </NavLink>
                    </li>
                    <li className="bold">
                        <NavLink className="nav_link" to={`${match.url}/grammar-topics`}>
                            <i className="navico grmr course"/>
                            <span className="menu-title">Grammar</span>
                        </NavLink>
                    </li>
                    <li className="bold">
                        <NavLink className="nav_link" to={`${match.url}/reading-topics`}>
                            <i className="navico read course"/>
                            <span className="menu-title">Reading</span>
                        </NavLink>
                    </li>
                    <li className="bold">
                        <a className="nav_link" onClick={this.openRecycleBin}>
                            <i className="navico recyclebeen"/>
                            <span className="menu-title">Recycle Bin</span>
                        </a>
                    </li>
                    <li className="bold">
                        <a className="nav_link" onClick={this.openBucket}>
                            <i className="navico bucket"/>
                            <span className="menu-title">Bucket</span>
                        </a>
                    </li>
                    {
                        account?.is_super_admin &&
                        <li className="bold">
                            <NavLink className="nav_link" to={`${match.url}/reported_problems`}>
                                <i className="navico reported-problems course"/>
                                <span className="menu-title">Reported Problems</span>
                            </NavLink>
                        </li>
                    }
                    <li className="bold">
                        <NavLink className="nav_link" to={`${match.url}/settings`}>
                            <i className="navico settings"/>
                            <span className="menu-title">Settings</span>
                        </NavLink>
                    </li>
                </ul>
                <div className="navigation-background"/>
                <a className="sidenav-trigger btn-sidenav-toggle btn-floating btn-medium waves-light hide-on-large-only" href="#" data-target="slide-out">
                    <i className="material-icons">menu</i>
                </a>
            </aside>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminSidebar));
