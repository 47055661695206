import * as dbUtil from '../../api/dbUtil';
import { API } from '../../api';
import {
    RECEIVE_ALL_PROBLEM_SET_QUESTIONS,
    RECEIVE_MULTIPLE_PROBLEM_SET_QUESTION,
    RECEIVE_PROBLEM_SET_QUESTION,
    REMOVE_PROBLEM_SET_QUESTION,
    RESET_PROBLEM_SET_QUESTION,
    UPDATE_PROBLEM_SET_QUESTION
} from "../constant";
import { checkSessionExpired } from "..";

const receiveAll = (data) => ({ type: RECEIVE_ALL_PROBLEM_SET_QUESTIONS, data });
const receive = (data) => ({ type: RECEIVE_PROBLEM_SET_QUESTION, data });
const receiveMultiple = (data) => ({ type: RECEIVE_MULTIPLE_PROBLEM_SET_QUESTION, data });
const updateQuestion = (data) => ({ type: UPDATE_PROBLEM_SET_QUESTION, data });
const removeQuestion = (data) => ({ type: REMOVE_PROBLEM_SET_QUESTION, data });
export const resetProblemSetQuestions = () => ({ type: RESET_PROBLEM_SET_QUESTION });

export const getAllProblemSetQuestions = (params) => {
    return (dispatch) => {
        return dbUtil.fetchMany('problemset_questions', params).then(({ data }) => {
            dispatch(receiveAll(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getProblemSetQuestion = (id) => {
    return (dispatch) => {
        return dbUtil.fetch('problemset_questions', id).then(({ data }) => {
            dispatch(receive(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const createProblemSetQuestion = (attributes) => {
    return (dispatch) => {
        return dbUtil.create('problemset_questions', attributes).then(({ data }) => {
            dispatch(receive(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const createMultipleProblemSetQuestion = (attributes) => {
    return (dispatch) => {
        return dbUtil.create('problemset_questions/create_multiple', attributes).then(({ data }) => {
            dispatch(receiveMultiple(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateProblemSetQuestion = (id, attributes) => {
    return (dispatch) => {
        return dbUtil.update('problemset_questions', id, attributes).then(({ data }) => {
            dispatch(updateQuestion(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateProblemSetQuestionPositions = (questions) => {
    return (dispatch) => {
        return API.put("/problemset_questions/update_positions", questions).then(({ data }) => {
            dispatch(receiveAll(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return questions;
        });
    }
}

export const getAllProblemSetQuestion = (data) => {
    return (dispatch) => {
        return API.post("/problemset_questions/get_problemset_questions", data).then(({ data }) => {
            dispatch(receiveAll(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const deleteProblemSetsQuestion = (id) => {
    return (dispatch) => {
        return dbUtil.destroy("problemset_questions", id).then(({ data }) => {
            dispatch(removeQuestion(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}
