import React, {Component} from 'react';
import {GithubPicker} from 'react-color';
import {RichUtils, EditorState} from 'draft-js';
import {getResetStateForMultiOptionStyle} from '../../editorUtils';
import presetColors from './presetColors';
import reactCSS from 'reactcss';
import SVG from '../../../SVG';
import './ColorPicker.scss';

class ColorPicker extends Component {
    constructor(props) {
        super();
        const currentColor = props.type === "TEXT" ? "#17252a" : "#fccb00";
        this.state = {
            currentColor,
            displayColorPicker: false,
        }
    }

    handleChange = (color, event) => {
        this.setState({currentColor: color.hex})
        this.handleClose();
    }

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    // Apply color to usable pallette in editor
    applyColor = () => {
        const {editorState, onChange, type} = this.props;
        const {currentColor} = this.state;

        // Remove similar styles for TEXT & HIGHLIGHT as to not overlap
        const nextContentState = getResetStateForMultiOptionStyle(editorState, type)

        const nextEditorState = EditorState.push(editorState, nextContentState);
        onChange(RichUtils.toggleInlineStyle(nextEditorState, `${type}_${currentColor}`))
    }

    renderPicker() {
        const {displayColorPicker, currentColor} = this.state;
        if (displayColorPicker) {
            return (
                <div className="popover">
                    <div className="cover" onClick={ this.handleClose }/>
                    <GithubPicker
                        color={currentColor}
                        colors={presetColors}
                        onChange={this.handleChange}/>
                </div>
            )
        }

        return null;
    }

    renderIcon() {
        const {type} = this.props;
        if(type === "HIGHLIGHT") {
            return (
                <SVG name={type.toLowerCase()} onClick={this.applyColor}/>
            )
        } else {
            return (
                <div className="text-icon" onClick={this.applyColor}>
                    <p>
                        A
                    </p>
                </div>
            )
        }
    }

    render() {
        const {currentColor} = this.state;

        // Add dynamic colors to swatch
        const styles = reactCSS({
            'default': {
                color: {
                    background: `${currentColor}`,
                },
            },
        });

        return (
            <div className="color-picker">
                <div className="swatch">
                    {this.renderIcon()}
                    <div className="bottom-section" onClick={ this.handleClick }>
                        <div
                            className="color"
                            style={ styles.color }/>
                    </div>
                </div>
                {this.renderPicker()}
            </div>
        );
    }
}

export default ColorPicker;
