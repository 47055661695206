import {RECEIVE_ALL_ACT_MOCK_TEST_QUESTIONS, RECEIVE_ALL_ACT_REAL_TEST_QUESTIONS} from "../constant";
import * as dbUtil from "../../api/dbUtil";
import { checkSessionExpired } from "..";

const receiveAllActRealTestQuestions = (data) => ({ type: RECEIVE_ALL_ACT_REAL_TEST_QUESTIONS, data });
const receiveAllActMockTestQuestions = (data) => ({ type: RECEIVE_ALL_ACT_MOCK_TEST_QUESTIONS, data });

export const getAllActRealTestQuestions = (params) => {
    return (dispatch) => {
        return dbUtil.fetchMany('questions', params).then(({data}) => {
            dispatch(receiveAllActRealTestQuestions(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getAllActMockTestQuestions = (params) => {
    return (dispatch) => {
        return dbUtil.fetchMany('questions', params).then(({data}) => {
            dispatch(receiveAllActMockTestQuestions(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}
