import React, { Component } from 'react';
import SATItem from './SATItem';
import "./SortableSAT.scss";
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';


const DragHandle = SortableHandle(() =>
    <span className="sortable-handle px-2 mr-2 d-flex">::</span>
)

const SortableItem = SortableElement(({ sat, showDragHandle = false , loading = false}) => {
    return (
        <li className="sortable-sat w-100" id={`T${sat.id}`} style={{ listStyle: "none", display: "flex", alignItems: "flex-start" }}>
            <div className="card animate fadeLeft w-100">
                <div className="users_sec card-content p-0 d-flex">
                    {showDragHandle === true && loading === false && <DragHandle />}
                    <SATItem sat={sat}
                             showDragHandle={showDragHandle}/>
                </div>
            </div>
        </li>
    )
})

const SortableList = SortableContainer(({sats, showDragHandle, loading }) => {
    return (
        <ul className="sortable-home-sat-list pl-0 w-100">
            {sats.map((sat, index) => {
                return (
                    <SortableItem key={sat.id}
                                  index={index}
                                  showDragHandle={showDragHandle}
                                  loading={loading}
                                  sat={sat}
                                  id={sat.id}/>
                )
            })}
        </ul>
    )
})

class SortableHomeSAT extends Component {

    state = {
        sats: [],
    }

    componentDidMount() {
        const { sats } = this.props;
        this.setState({ sats })
    }

    onSortEnd = async (options, mouseEvent, sats = this.props.sats) => {
        const { updateUserSatsPositions } = this.props;
        const { oldIndex, newIndex } = options;
        if(oldIndex === newIndex) return null;
        const updatedSats = arrayMove(sats, oldIndex, newIndex)
        // set in parent controller
        const x = await updateUserSatsPositions(updatedSats);
        this.setState({ sats: x })
    }

    render() {
        const { sats, loading, showDragHandle } = this.props;
        return (
            <div className="sortable-sats w-100">
                    <SortableList useDragHandle
                                  onSortEnd={this.onSortEnd}
                                  sats={sats}
                                  loading={loading}
                                  showDragHandle={showDragHandle}
                                  helperClass='sortableHelper'
                                  lockAxis="y"
                                  useWindowAsScrollContainer={true}/>
            </div >
        );
    }
}

export default SortableHomeSAT;
