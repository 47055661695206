import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import './PreviewQuestionDialog.scss';
import '../../admin/content/common/AddNewTopic.scss';
import * as topicActions from '../../../modules/topic/action';
import * as grammarTopicActions from '../../../modules/grammarTopic/action';
import * as readingTopicActions from '../../../modules/readingTopic/action';
import * as animationActions from '../../../modules/animation/action';
import * as satActions from '../../../modules/sat/action'
import * as actActions from '../../../modules/act/action'
import SortableSAT from '../../admin/content/sat/SortableSAT'
import SortableTopics from '../../admin/content/topics/SortableTopics'
import SortableACT from '../../admin/content/act/SortableACT';
import {SetModalTopScroll} from "../../helper/commonMethods";

class MathSectionModal extends Component {
    state = {
        show: false,
        showEdit: false,
        showCreate: false,
        allmathsTopic: [],
        hasMore: true,
        offset: 0
    }

    componentWillMount() {
        const { getMathTopics, startAnimation, endAnimation, getSATs, getGrammarTopics, getReadingTopics, getACTs } = this.props
        startAnimation()
        getSATs();
        getMathTopics();
        getGrammarTopics();
        getReadingTopics();
        endAnimation();
        getACTs();
        SetModalTopScroll();
    }

    render() {
        const { topics, grammarTopics, readingTopics, sats, satTopic, updateAfterPublishUnpublish, updateAfterSequencePublishUnpublish, mathTopic, actTopic, acts} = this.props
        return (
            <div className="preview-question--inner adjustUlInDialogExisting">
                <div className="">
                    <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" data-toggle="tab" href="#SATs">SATs</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link " data-toggle="tab" href="#ACTs">ACTs</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="tab" href="#Math">Math</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="tab" href="#Grammar">Grammar</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="tab" href="#Reading">Reading</a>
                        </li>

                    </ul>
                    <div className="tab-content">
                        <div id="SATs" className="container tab-pane active">
                            <div className="card-body management-list pt-0">
                                <div className="pr-2 overflow-scroll">
                                    <SortableSAT sats={sats}
                                                 satTopicModal={true}
                                                 satTopic={satTopic}
                                                 actTopic={actTopic}
                                                 fromPopUp={true}
                                                 updateAfterSequencePublishUnpublish={updateAfterSequencePublishUnpublish}
                                                 mathTopic={mathTopic}
                                                 updateAfterPublishUnpublish={updateAfterPublishUnpublish}/>
                                </div>
                            </div>
                        </div>
                        <div id="ACTs" className="container tab-pane fade">
                            <div className="card-body management-list pt-0">
                                <div className="pr-2 overflow-scroll">
                                    <SortableACT
                                        acts={acts}
                                        actTopicModal={true}
                                        satTopic={satTopic}
                                        actTopic={actTopic}
                                        fromPopUp={true}
                                        updateAfterSequencePublishUnpublish={updateAfterSequencePublishUnpublish}
                                        mathTopic={mathTopic}
                                        updateAfterPublishUnpublish = {updateAfterPublishUnpublish}
                                    />
                                </div>
                            </div>
                        </div>
                        <div id="Math" className="container tab-pane fade">
                            <div className="card-body management-list pt-0">
                                <div className="pr-2 overflow-scroll">
                                    <SortableTopics
                                        topics={topics}
                                        satTopicModal={true}
                                        satTopic={satTopic}
                                        updateAfterPublishUnpublish = {updateAfterPublishUnpublish}
                                        mathTopic={mathTopic}
                                        actTopic={actTopic}
                                    />
                                </div>
                            </div>
                        </div>
                        <div id="Grammar" className="container tab-pane fade">
                            <div className="card-body management-list pt-0">
                                <div className="pr-2 overflow-scroll">
                                    <SortableTopics
                                        topics={grammarTopics}
                                        topicType='GrammarTopic'
                                        satTopicModal={true}
                                        satTopic={satTopic}
                                        updateAfterPublishUnpublish = {updateAfterPublishUnpublish}
                                        mathTopic={mathTopic}
                                        actTopic={actTopic}
                                    />
                                </div>
                            </div>
                        </div>
                        <div id="Reading" className="container tab-pane fade">
                            <div className="card-body management-list pt-0">
                                <div className="pr-2 overflow-scroll">
                                    <SortableTopics
                                        topics={readingTopics}
                                        topicType='ReadingTopic'
                                        satTopicModal={true}
                                        satTopic={satTopic}
                                        updateAfterPublishUnpublish = {updateAfterPublishUnpublish}
                                        mathTopic={mathTopic}
                                        actTopic={actTopic}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ sats, topics, grammarTopics, readingTopics, acts}) => ({ sats, topics, grammarTopics, readingTopics, acts })

const mapDispatchToProps = (dispatch) => {
    return ({
        getMathTopics: () => dispatch(topicActions.getAllTopics()),
        getGrammarTopics: () => dispatch(grammarTopicActions.getAllGrammarTopics()),
        getReadingTopics: () => dispatch(readingTopicActions.getAllReadingTopics()),
        startAnimation: () => dispatch(animationActions.start()),
        endAnimation: () => dispatch(animationActions.end()),
        getSATs: () => dispatch(satActions.getAllTestSats()),
        getACTs: () => dispatch(actActions.getAllTestActs())
    })
}

export const MathSectionDialog = withRouter(connect(mapStateToProps, mapDispatchToProps)(MathSectionModal));
