import React, { Component } from 'react';

class EditTitle extends Component {
    state = {
        title: "",
        duplicateTitle: "",
    }

    componentDidMount() {
        this.setTitle();
    }

    setTitle = () =>{
        const { topic } = this.props;
        this.setState({ title: topic.title, duplicateTitle: topic.title });
    }

    componentDidUpdate(prevProps) {
        if(this.props.topic.title !== prevProps.topic.title){
            this.setTitle();
        }
    }

    handleChange(prop) {
        return ({target}) => {
            if (/^(?![\s-])[\w\s-]+$/.test(target.value) || target.value === "") {
                this.setState({[prop]: target.value});
            }
        };
    }

    updateTitle = async (e) => {
        e.preventDefault();
        const { onUpdate, topic, toggleForm, flashMessage, topicType,  selectedId, problemSetTopic } = this.props;
        const { title, duplicateTitle } = this.state;
        if (title) {
            if (title !== duplicateTitle) {
                const id = topic.id ?? topic._id;
                let data = { title };
                if(topicType === 'ProblemSet'){
                    data = {title, topicType: problemSetTopic, selectedId};
                }
                const updateData = await onUpdate(id, data);
                if(updateData){
                    this.setState({duplicateTitle: title});
                    flashMessage({message: `${topicType} title Updated Successfully`, type: 'success'});
                }
                toggleForm();
            } else {
                toggleForm();
            }
        } else {
            alert(`Please enter ${topicType} title`)
        }

    }

    render() {
        const { title } = this.state;
        const { toggleForm, show, disabled } = this.props;
        if(show){
            return(
                <div className='edit-title d-flex w-100 ml-3 mr-3'>
                    <form className="d-flex w-100" onSubmit={this.updateTitle}>
                        <input type={"text"}
                               value={title}
                               onChange={this.handleChange('title')}/>
                        <button className="btn btn-primary mx-2" disabled={disabled || !validTitle(title)}>Save</button>
                    </form>
                    <button className="btn btn-default" onClick={toggleForm} disabled={disabled}>Cancel</button>
                </div>
            )
        }
        return  null;
    }
}

const validTitle = (title) =>{
    return(/^(?![\s-])[\w\s-]+$/.test(title));
}

export default (EditTitle);
