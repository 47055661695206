import React, { Component } from 'react';

class LinkedProblemSetDialog extends Component {
    state = {
        linkedProblemSet: {},
        ready: false,
        problemSets: [],
    }

    componentDidMount() {
        // let updatedproblemSets;
        const { problemSets, preview } = this.props;
        if (!preview) {
            // updatedproblemSets = problemSets.filter(x => x.id !== currentQuestion)
            this.setState({ problemSets: problemSets })
        }
    }

    linkProblemSet = async (problemSet) =>{
        const { handleLinkedProblemSet, close } = this.props;
        await handleLinkedProblemSet(problemSet);
        await close();
    }

    render() {
        const { problemsetId: selectedId, problemSets } = this.props;

        return(
            <div className="link-problem-set-main-div">
                <ProblemSetList problemSets={problemSets}
                                onClick={this.linkProblemSet}
                                selectedId={selectedId}/>
            </div>
        )
    }
}

const ProblemSetList = ({problemSets, selectedId, onClick }) =>{
    if (!problemSets?.length) {
        return (<div className="no-record-div">No Problem Set Found</div>)
    }

    return problemSets?.map((question, index) => {
        const problemSetQuestion = question?.problemset ?? [];
        return (
            <div key={index}>
                {question.title}
                <ul className="problem-set-lists">
                    {
                        problemSetQuestion?.map((problemSet) => {
                            const {_id: id, title } = problemSet;
                            return (
                                <li onClick={() => onClick(problemSet)} key={id}>
                                    {selectedId === id && <i className="fa fa-check text-primary" />}
                                    <div>{title}</div>
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
        );
    });
}

export { LinkedProblemSetDialog };
