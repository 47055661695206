import React, { Component } from 'react';
import { connect } from 'react-redux';
import Switch from 'react-switch';
import * as answerActions from '../../../../../modules/answer/action';
import * as dialogActions from '../../../../../modules/dialog/action';
import * as questionActions from '../../../../../modules/question/action';
import {LINK_QUESTION_DIALOG_BOX, LINK_PROBLEM_SET_DIALOG_BOX, PREVIEW_QUESTION_DIALOG_BOX} from '../../../../dialogs/dialogs';
import Editor from '../../../../editor/Editor';
import * as alertActions from "../../../../../modules/alert/action";
import * as animationActions from "../../../../../modules/animation/action";

const mapDispatchToProps = dispatch => {
    return ({
        updateAnswer: (id, attributes) => dispatch(answerActions.updateAnswer(id, attributes)),
        openDialog: (config, options) => dispatch(dialogActions.open(config, options)),
        getQuestion: (id) => dispatch(questionActions.getQuestion(id)),
        getAnswers: (params) => dispatch(answerActions.getAllAnswer(params)),
        startAnimation: () => dispatch(animationActions.start()),
        endAnimation: () => dispatch(animationActions.end()),
        alertPush: (payload) => dispatch(alertActions.alertPush(payload)),
        alertDelete: () => dispatch(alertActions.alertDelete())
    })
}

const mapStateToProps = state => {
    return ({
        questions: state.questions,
        subsubtopics: state.subsubtopics
    })
}

class EditAnswer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            answerTitle: "",
            nextQuestionId: "",
            explanation: "",
            correct: false,
            isExactMatch: false,
            answerState: {},
            explanationState: {},
            problemState: {},
            free_response_answer: '',
            symbol: '',
            symbolPositionLeft: false
        }

        if (props.answer.is_free_response) {
            const {free_response_answer, symbol, correct,is_exact_match: isExactMatch, symbol_position_left: symbolPositionLeft } = props.answer;
            this.state = {...this.state,...{free_response_answer, symbol, symbolPositionLeft, isExactMatch, correct}}
        } else {
            const {answer: answerTitle, next_question: {_id: nextQuestionId}, correct,explanation} = props.answer;
            this.state = {...this.state,...{answerTitle, nextQuestionId, explanation, correct}}
        }
    }

    componentDidMount() {
        const { answer } = this.props;
        if (answer.is_free_response) {
            this.setState({
                free_response_answer: answer.free_response_answer,
                symbol: answer.symbol,
                correct: answer.correct,
                isExactMatch: answer.is_exact_match,
                symbolPositionLeft: answer.symbol_position_left
            })
        } else {
            this.setState({
                answerTitle: answer.answer,
                nextQuestionId: answer.next_question._id,
                explanation: answer.explanation,
                correct: answer.correct,
            })
        }

    }

    updateAnswer = async (e) => {
        e.preventDefault();
        const { updateAnswer, answer, toggleForm, startAnimation, endAnimation } = this.props;
        const { answerState, explanationState, nextQuestionId, correct, problemState, free_response_answer, symbol, isExactMatch, symbolPositionLeft } = this.state;
        let data = {};
        if (answer.is_free_response) {
            data = {
                free_response_answer,
                symbol,
                is_exact_match: isExactMatch,
                symbol_position_left: symbolPositionLeft
            };
        } else {
            data = {
                answer: answerState,
                next_question: nextQuestionId,
                explanation: explanationState,
                correct: correct,
                link_problemset: problemState
            };
        }

        startAnimation();
        await updateAnswer(answer.id, data);
        this.flashMessage({message: `Answer value updated`, type: 'success'});
        endAnimation();
        // await getQuestion(answer.question_id)
        // postAnswerUpdate(updatedAnswer);
        toggleForm();
    }

    flashMessage = (payload, timeout = 5000) => {
        const { alertPush, alertDelete } = this.props;
        alertPush(payload);
        setTimeout(() => alertDelete(),timeout);
    }

    // State updates
    handleChange = (prop) => {
        return ({ target }) => this.setState({ [prop]: target.value })
    }

    updateAnswerState = (answerState) => {
        this.setState({ answerState })
    }

    updateExplanationState = (explanationState) => {
        this.setState({ explanationState })
    }

    swapCorrect = () => {
        const { correct } = this.state;
         this.setState({ correct: !correct });
    }

    swapExactAnswer = () => {
        const { isExactMatch } = this.state;
        this.setState({ isExactMatch: !isExactMatch })
    }

    updateSymbolState = (symbol) => {
        this.setState({ symbol })
    }


    handleLinkedQuestion = (nextQuestionId) => {
        this.setState({ nextQuestionId })
    }

    handlelinkedProblemSet = (problemState) => {
        this.setState({ problemState: problemState._id })
    }

    swapSymbolPosition = () => {
        const { symbolPositionLeft } = this.state;
        this.setState({ symbolPositionLeft: !symbolPositionLeft })
    }

    // Dialogs

    openLinkedDialog = () => {
        const { openDialog, questions, answer } = this.props;
        openDialog(LINK_QUESTION_DIALOG_BOX, {
            handleLinkedQuestion: this.handleLinkedQuestion,
            questions,
            currentQuestion: answer.question_id,
        })
    }

    openLinkedPreview = async () => {
        const { openDialog, questions, getAnswers } = this.props;
        const { nextQuestionId } = this.state;
        const preview = questions.find((question => {
            return question.id === nextQuestionId
        }))
        let updatedAnswers = preview.id ? await getAnswers({ params: { question_id: preview.id } }) : [];
        openDialog(PREVIEW_QUESTION_DIALOG_BOX, { question: preview.question, previewAnswers: updatedAnswers ?? [] });
    }

    openLinkedProblemSet = async () => {
        const { openDialog, subsubtopics, questions, section, subtopicId, satProblemSets, topicType } = this.props;
        let problemSets = subsubtopics[questions[0].subtopic_id]
        if (section === 'subsubTopic') {
            const index = subsubtopics[subtopicId].findIndex(x => x._id === questions[0].subsubtopic_id)
            problemSets = []
            if (index > -1)
                problemSets.push(subsubtopics[subtopicId][index])

        }
        if (topicType === 'SatTopic') {
            problemSets = [{ problemset: satProblemSets }]
        }

        if (topicType === 'SatQuestionTopic') {
            problemSets = [{ problemset: satProblemSets }]
        }

        openDialog(LINK_PROBLEM_SET_DIALOG_BOX, {
            handleLinkedProblemSet: this.handlelinkedProblemSet,
            problemSets
        })
    }



    render() {
        const { toggleForm, answer, section, question } = this.props;
        const { correct, nextQuestionId, free_response_answer, symbol, isExactMatch, symbolPositionLeft } = this.state;
        let disabled = true;
        if (nextQuestionId) {
            disabled = false;
        }

        let disabledLinking = false
        if (question.link_right_problemset_id || question.link_right_question_id
            || question.link_wrong_problemset_id || question.link_wrong_question_id) {
            disabledLinking = true
        }

        if (answer && answer.is_free_response) {
            return (
                <div className="edit-answer">
                    <div className="edit-answer-form">
                        <div className="editor-container w-100">
                            <label className="mr-2">Answer:</label>
                            <input type="text" value={free_response_answer} onChange={(e) => this.setState({ free_response_answer: e.target.value })} />
                        </div>
                        <div className="mt-1 editor-container w-100">
                            <label className="mr-2">Symbol:</label>
                            <Editor content={symbol} updateEditorState={this.updateSymbolState} style={{minHeight: 80}}/>
                        </div>
                    </div>
                    <div className="ques_attributes">
                        <div className="linkedques_container">
                            <label className="mr-2">Symbol Position Left</label>
                            <Switch checked={symbolPositionLeft}
                                    onChange={this.swapSymbolPosition}
                                    label="Symbol Position Left" />
                        </div>

                        <div className="linkedques_container">
                            <label>Exact Match?</label>
                            <Switch
                                checked={isExactMatch}
                                onChange={this.swapExactAnswer}
                                label="Exact Match?" />
                        </div>
                        <div className="buttons btn_grup">
                            <button className="btn btn-success" onClick={this.updateAnswer}>Save</button>
                            <button className="btn btn-default" onClick={() => toggleForm()}>Cancel</button>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="edit-answer">

                    <EditorWithLabel className="mr-2" answer={answer.answer} title={"Answer"}
                                     updateEditorState={this.updateAnswerState} />

                    <EditorWithLabel className="mr-2" answer={answer.explanation} title={"Explanation"}
                                     updateEditorState={this.updateExplanationState} />

                    {((section.match(/subTopic/g)) || (section.match(/SatQuestionTopic/g))) &&
                        <div className="linkedques_container">
                            <label>Linked Question</label>
                            <button className="btn btn-default" disabled={disabledLinking} onClick={this.openLinkedDialog}>Link Question</button>
                            <button className="btn btn-default" onClick={this.openLinkedPreview} disabled={disabled}>Preview Linked Question</button>
                            <button className="btn btn-default" disabled={disabledLinking} onClick={this.openLinkedProblemSet} >Link Problem Set </button>
                        </div>
                    }
                    <div className="ques_attributes">
                        <div className="linkedques_container">
                            <label>Correct Answer?</label>
                            <Switch
                                checked={correct}
                                onChange={this.swapCorrect} />
                        </div>
                        <div className="buttons btn_grup">
                            <button className="btn btn-success" onClick={this.updateAnswer}>Save</button>
                            <button className="btn btn-default" onClick={() => toggleForm()}>Cancel</button>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

const EditorWithLabel = ({answer, className, title, updateEditorState}) =>{
    if (answer !== "NA") {
        return (
            <>
                <label className={className}>{title}</label>
                <Editor content={answer} updateEditorState={updateEditorState} style={{minHeight: 100}}/>
            </>
        )
    }
    return null;
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAnswer);
