import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Question from './Question';
import "./QuestionContainer.scss";

class QuestionContainer extends Component {
    render() {
        const {currentQuestionProg, currentQuestion, currentAnswers, setSelectedAnswer, selectedAnswer} = this.props;
        return (
            <div className="question-container">
                <Question
                    question={currentQuestion}
                    answers={currentAnswers}
                    selectedAnswer={selectedAnswer}
                    setSelectedAnswer={setSelectedAnswer}
                    currentQuestionProg={currentQuestionProg}/>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const currentTopicId = props.match.params.topicId;
    const currentQuestionId = props.match.params.questionId;
    const currentQuestion = state.questions.find(x => x.id === currentQuestionId)
    const testProg = state.testProgs.find(x => x.test_id === currentTopicId);
    let currentAnswers = state.answers[currentQuestionId] ? state.answers[currentQuestionId] : [];
    return({
        currentQuestion,
        testProg,
        currentAnswers,
    })
}

export default withRouter(connect(mapStateToProps, null)(QuestionContainer));
