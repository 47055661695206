import {
    RECEIVE_ALL_USER_ACT_SCORES,
    RECEIVE_ALL_USER_ACTS,
    RECEIVE_USER_ACT,
    RECEIVE_USER_ACT_SCORE
} from '../constant';
import {combineReducers} from "redux";

const defaultState = [];

const userActsDataReducer = (state = defaultState, action) => {
    switch (action.type) {
        case RECEIVE_USER_ACT:
            for (let i = 0; i < state.length; i++) {
                if (action.data.sat_id === state[i].sat_id) {
                    return state.map(obj => (action.data.sat_id === obj.sat_id) ? action.data : obj);
                }
            }
            return Object.assign([], state, [...state, action.data])
        case RECEIVE_ALL_USER_ACTS:
            return action.data;
        default:
            return state;
    }
}

const userActsScoreReducer = (state = defaultState, action) => {
    switch (action.type) {
        case RECEIVE_USER_ACT_SCORE:
            for (let i = 0; i < state.length; i++) {
                if (action.data.sat_id === state[i].sat_id) {
                    return state.map(obj => (action.data.sat_id === obj.sat_id) ? action.data : obj);
                }
            }
            return Object.assign([], state, [...state, action.data])
        case RECEIVE_ALL_USER_ACT_SCORES:
            return action.data;
        default:
            return state;
    }
}

export const userActReducer = combineReducers({
    data: userActsDataReducer,
    scores: userActsScoreReducer
})
