import * as dbUtil from '../../api/dbUtil';
import {API} from '../../api';
import {
    RECEIVE_ALL_PROBLEM_SET,
    RECEIVE_PROBLEM_SET,
    UPDATE_PROBLEM_SET,
    REMOVE_PROBLEM_SET,
    LINK_PROBLEM_SET_TO_SAT,
    PUBLISH_UNPUBLISH_PROBLEM_SET,
    RECEIVE_UPDATED_PROBLEM_SET_NOTES,
    RECEIVE_MULTIPLE_PROBLEM_SET
} from '../constant'
import { checkSessionExpired, alertPushMessage } from "..";

const receiveAll = (data) => ({ type: RECEIVE_ALL_PROBLEM_SET, data });
const receive = (data) => ({ type: RECEIVE_PROBLEM_SET, data });
const receiveMultiple = (data) => ({ type: RECEIVE_MULTIPLE_PROBLEM_SET, data });
const updateTest = (data) => ({ type: UPDATE_PROBLEM_SET, data })
const linkProblemSetToSat = (data) => ({ type: LINK_PROBLEM_SET_TO_SAT, data });
const removeTest = (data) => ({ type: REMOVE_PROBLEM_SET, data });
const publishUnpublishProblemSet = (data) => ({ type: PUBLISH_UNPUBLISH_PROBLEM_SET, data });
const receiveUpdatedProblemSetNotes = (data) => ({ type: RECEIVE_UPDATED_PROBLEM_SET_NOTES, data });

export const getAllProblemSets = (params) => {
    const topicType = params.topicType
    delete params.topicType
    return (dispatch) => {
        return dbUtil.fetchMany('problem_sets', params).then(({data}) => {
            data.topicType = topicType
            dispatch(receiveAll(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getProblemSet = (id) => {
    return (dispatch) => {
        return dbUtil.fetch('problem_sets', id).then(({data}) => {
            dispatch(receive(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateProblemSet = (id, attributes) => {
    const topicType = attributes.topicType
    const updateId = attributes.selectedId
    delete attributes.topicType
    delete attributes.selectedId
    return (dispatch) => {
        return dbUtil.update('problem_sets', id, attributes).then(({data}) => {
            data.topicType = topicType
            data.updateId = updateId
            dispatch(updateTest(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const deleteProblemSet = (id, parentId) => {
    return (dispatch) => {
        return dbUtil.destroy('problem_sets', id).then(({data}) => {
                data.parentId = parentId
                dispatch(removeTest(data));
                dispatch(alertPushMessage({message: `ProblemSet '${data.title}' deleted`.message, type: "success"}));
                return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const createProblemSet = (attributes) => {
    return (dispatch) => {
        return dbUtil.create('problem_sets', attributes).then(({data}) => {
            dispatch(receive(data));
            dispatch(alertPushMessage({message: `ProblemSet ('${attributes.title}') created`, type: "success"}));
            return(data);
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const createMultipleProblemSets = (attributes) => {
    return (dispatch) => {
        return dbUtil.create('problem_sets/bucket_multiple_problem_sets', attributes).then(({data}) => {
            dispatch(receiveMultiple(data))
            dispatch(alertPushMessage({message: "Problem Set added From Bucket", type: "success"}))
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateProblemSetPosition = (problemSets) => {
    return (dispatch) => {
        return API.put("/problem_sets/update_positions", problemSets).then(({data}) => {
            data.topicType = 'SubTopic'
            dispatch(receiveAll(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return problemSets;
        });
    }
}

export const updateProblemSetSatPosition = (problemSets) => {
    return (dispatch) => {
        return API.put("/problem_sets/update_sat_positions", problemSets).then(({data}) => {
            data.topicType = 'SatQuestionTopic'
            dispatch(receiveAll(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return problemSets;
        });
    }
}

export const removeSatProblemSet = (id, attributes, satId) => {
    return (dispatch) => {
        return dbUtil.update('problem_sets', id, attributes).then(({data}) => {
            data.subsubtopic_id = null
            data.parentId = satId
            dispatch(removeTest(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const linkExistingProblemSet = (id, attributes) => {
    const updateId = attributes.selectedId
    delete attributes.topicType
    delete attributes.selectedId
    return (dispatch) => {
        return dbUtil.update('problem_sets', id, attributes).then(({data}) => {
            data.updateId = updateId
            dispatch(linkProblemSetToSat(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getSatProblemSets = (params) => {
    return (dispatch) => {
        return dbUtil.fetchMany('problem_sets', params).then(({data}) => {
            data.sat_question_id = params.sat_question_id
            data.topicType = 'SatQuestionTopic'
            dispatch(receiveAll(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const publishUnPublishProblemSet = (attributes) => {
    return (dispatch) => {
        return API.post('/problem_sets/publish_unpublish', attributes).then(({data}) => {
            dispatch(publishUnpublishProblemSet(data));
            const message = `ProblemSet ${attributes.isPublish ? 'Published' : 'Unpublished'} successfully`;
            dispatch(alertPushMessage({message: message, type: "success"}));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateProblemSetNotes = (attributes) => {
    return (dispatch) => {
        return API.post('/problem_sets/update_notes', attributes).then(({data}) => {
            dispatch(receiveUpdatedProblemSetNotes(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateProblemSetActPosition = (problemSets) => {
    return (dispatch) => {
        return API.put("/problem_sets/update_act_positions", problemSets).then(({data}) => {
            data.topicType = 'ActQuestionTopic'
            dispatch(receiveAll(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return problemSets;
        });
    }
}

export const removeActProblemSet = (id, attributes, actId) => {

    return (dispatch) => {
        return dbUtil.update('problem_sets', id, attributes).then(({data}) => {
            data.subsubtopic_id = null
            data.parentId = actId
            dispatch(removeTest(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}


export const getActProblemSets = (params) => {
    return (dispatch) => {
        return dbUtil.fetchMany('problem_sets', params).then(({data}) => {
            data.act_question_id = params.act_question_id
            data.topicType = 'ActQuestionTopic'
            dispatch(receiveAll(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}
