import React, { Component } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import AdminTopic from './topic/AdminTopic';
import "./SortableTopics.scss";

const DragHandle = SortableHandle(() =>
    <span className="sortable-handle">::</span>
)

const SortableItem = SortableElement(({
    topic,
    topicType,
    deleteTopic,
    updateTopic,
    satTopicModal,
    satTopic,
    updateAfterPublishUnpublish,
    mathTopic,
    actTopic
}) => {
    return (
        <li className="sortable-topic" id={`T${topic.id}`}>
            {satTopicModal === false && <DragHandle />}
            <AdminTopic
                topic={topic}
                topicType={topicType}
                deleteTopic={deleteTopic}
                updateTopic={updateTopic}
                satTopicModal={satTopicModal}
                satTopic={satTopic}
                updateAfterPublishUnpublish={updateAfterPublishUnpublish} 
                mathTopic={mathTopic}
                actTopic={actTopic}
            />
        </li>
    )
})

const SortableList = SortableContainer(({
    topics,
    topicType,
    deleteTopic,
    updateTopic,
    satTopicModal,
    satTopic,
    updateAfterPublishUnpublish,
    mathTopic,
    actTopic
}) => {
    return (
        <ul className="sortable-home-topic-list">
            {topics.map((topic, index) => {
                return (
                    <SortableItem
                        key={topic.id}
                        index={index}
                        topic={topic}
                        id={topic.id}
                        topicType={topicType}
                        deleteTopic={deleteTopic}
                        updateTopic={updateTopic}
                        satTopicModal={satTopicModal}
                        satTopic={satTopic}
                        updateAfterPublishUnpublish={updateAfterPublishUnpublish} 
                        mathTopic={mathTopic}
                        actTopic={actTopic}
                    />
                )
            })}
        </ul>
    )
})

class SortableAdminTopics extends Component {

    // componentDidUpdate(prevProps) {
    //     if(this.props.topics[0].subtopic_id !== prevProps.questions[0].subtopic_id) {
    //         this.setState({questions: this.props.questions})
    //     }
    //
    //     // Set to length of 1 so these methods are not invoked on creation of first question which would result in duplicative render of first question
    //     if(Object.entries(this.props.newQuestion).length !== 0 && this.props.questions.length > 1) {
    //         this.sortableAddQuestion(this.props.newQuestion);
    //         this.props.resetNewQuestion();
    //     }
    //
    //     if(Object.entries(this.props.newQuestion).length !== 0 && this.props.questions.length === 1) {
    //         this.props.resetNewQuestion();
    //     }
    // }

    // React Sortable Method used during mouse events to sort the list
    // Added functionality to this method in order to handle the question addition, deletion, and manipulation
    // mouseEvent is a param automatically passed in by React Sortable when moving questions
    // => we add the param here so we are able to set questions dynamically
    // When sorting the list, local state questions are used.
    // When adding to the list, a new copy of the questions state is used (see f(x)sortableAddQuestion)
    onSortEnd = async (options, mouseEvent, topics = this.props.topics) => {
        const { updateTopicPositions } = this.props;
        const { oldIndex, newIndex } = options;
        if(oldIndex === newIndex) return null;
        const updatedTopics = arrayMove(topics, oldIndex, newIndex)
        await updateTopicPositions(updatedTopics);
    }

    render() {
        const { topics, topicType, deleteTopic, updateTopic, satTopicModal, satTopic, updateAfterPublishUnpublish, mathTopic, actTopic } = this.props;
        return (
            <div className="sortable-topics">
                <SortableList
                    useDragHandle
                    onSortEnd={this.onSortEnd}
                    topics={topics}
                    deleteTopic={deleteTopic}
                    updateTopic={updateTopic}
                    topicType={topicType}
                    satTopicModal={satTopicModal}
                    satTopic={satTopic}
                    updateAfterPublishUnpublish={updateAfterPublishUnpublish}
                    mathTopic={mathTopic}
                    actTopic={actTopic}
                    lockAxis="y"
                    useWindowAsScrollContainer={true}
                />

            </div>
        );
    }
}

export default SortableAdminTopics;
