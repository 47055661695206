import {
    PUBLISH_ACT_QUESTION,
    RECEIVE_ACT_QUESTION,
    RECEIVE_ALL_ACT_QUESTIONS,
    UPDATE_ACT_QUESTION,
    UPDATE_ACT_QUESTION_VIDEO_EXPLANATION
} from "../constant";

// Not considering creation of new test
export const actQuestionReducer = (state = [], action) => {
  let newState;
  let data;
  switch (action.type) {
      case RECEIVE_ALL_ACT_QUESTIONS:
          newState = Object.assign({}, state)
          data = action.data;
          if (!newState[data[0].act_id]) {
              newState[`${data[0].act_id}`] = {};
          }

          newState[`${data[0].act_id}`] = data;
          return newState;
      case RECEIVE_ACT_QUESTION:
          for (let i = 0; i < state.length; i++) {
              if (action.data.id === state[i].id) {
                  return state;
              }
          }
          return action.data
      case UPDATE_ACT_QUESTION:
          newState = state
          newState[action.data.act_id] = state[action.data.act_id].map(obj => (action.data.id === obj.id) ? action.data : obj);
          return newState;
      case PUBLISH_ACT_QUESTION:
          newState = state
          newState[action.data.act_id] = state[action.data.act_id].map(obj => (action.data.id === obj.id) ? action.data : obj);
          return newState;
      case UPDATE_ACT_QUESTION_VIDEO_EXPLANATION:
          newState = state
          newState[action.data.act_id] = state[action.data.act_id].map(obj => (action.data.id === obj.id) ? action.data : obj);
          return newState;
      default:
          return state;
  }
}
