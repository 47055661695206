import React, { Component } from 'react';
import SVG from "../../../SVG";
import _ from "lodash";

export default class ReportQuestionClass extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            currentQuestion: undefined,
            problemSetProgressBar: undefined,
            view_full_explanation: false
        }
    }

    setCurrentQuestion = (question) =>{
        if(this.props.currentQuestion !== question){
            this.props.setCurrentQuestion(question);
        }
        return null;
    }

    handle_view_full_explanation = () => {
        const { view_full_explanation } = this.state;
        this.setState({ view_full_explanation: !view_full_explanation })
    }

    renderLoading() {
        const { loading } = this.props;
        if (loading) {
            return (
                <div className="loading-overlay">
                    <SVG name="spin" />
                </div>
            )
        }

        return null;
    }

    scrollToCheckButton = () => {
        window.location = "#check_button";
    }

    alertAnswerStatus(){
        const {getAnswersValue} = this.state;
        const {alertPush, alertDelete} = this.props;
        if (getAnswersValue === true){
            alertPush({message: "Correct Answer", type: 'success'});
            setTimeout(()=>{alertDelete()}, 5000);
        } else if (getAnswersValue === false){
            alertPush({message: "Incorrect Answer", type: 'error'});
            setTimeout(()=>{alertDelete()}, 5000);
        }
    }

    componentDidMount(){
        document.addEventListener('mousemove', () => this.resetIdleTime(), false);
        document.addEventListener('keypress', () => this.resetIdleTime(), false);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        clearInterval(this.idleInterval);
        document.removeEventListener('mousemove', () => this.resetIdleTime(), false);
        document.removeEventListener('keypress', () => this.resetIdleTime(), false);
    }

    flashMessage = (payload, timeout = 5000) => {
        const {alertPush, alertDelete} = this.props;
        alertPush(payload);
        setTimeout(() => alertDelete(),timeout);
    }

    counter() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds + 1;
        this.setState({seconds});
        if (this.state.idleTime >= 240) {
            this.resetIdleTime();
            const res = window.confirm("Are you still there? press OK to continue.")
            if (!res) {
                this.props.takeBack();
            }
        }
    }

    checkIdealTime() {
        this.setState({ idleTime: this.state.idleTime + 1 });
    }

    resetIdleTime() {
        this.setState({ idleTime: 0 });
    }

    getProblemSetProgress = () =>{
        const { account, problemset_questions } = this.props;
        let correctAnswers = 0, problemSetProgress = null , problemSetQuestion ;
        if (account?.problemsetProgress?.question_id) {
            problemSetProgress = (_.find(problemset_questions, (dd) => dd.id === account.problemsetProgress.question_id));
        }
        if(problemSetProgress){
            correctAnswers = account?.problemsetProgress?.total_correct ?? 0;
            problemSetQuestion = problemSetProgress;
        } else {
            problemSetQuestion = problemset_questions[0];
        }
        return({problemSetQuestion, problemSetCorrectAnswers: correctAnswers});
    }

    questionsComplete(addition = 0) {
        const { account} = this.props;
        if(account?.problemsetProgress?.questions_complete){
            return account.problemsetProgress.questions_complete + addition;
        }
        return 1;
    }

    //skip the current question if question is hidden
    checkAndSkipProblemSetQuestion = (question, problemSetQuestions) => {
        let nextProblemSetQuestionData = '';
        if(problemSetQuestions && problemSetQuestions.length > 0){
            nextProblemSetQuestionData = _.find(problemSetQuestions, ['position', question.position + 1]);
        }

        //skip the current question if question is hidden
        if (question && question.is_hidden) {
            question = nextProblemSetQuestionData;
        }
        // Below 'setCurrentQuestion' Method is used in inherited Class and displayed in Super Parent Container
        this.setCurrentQuestion(question);
        return question;
    }

}


