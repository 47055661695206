import { makeRequest } from './requestBuilder';

export const API = {
    get: async (url, params = {}) =>
        makeRequest({
            method: 'get',
            url,
            params
        }),

    post: async (url, body) =>
        makeRequest({
            method: 'post',
            body,
            url,
        }),

    patch: async (url, body) =>
        makeRequest({
            method: 'patch',
            body,
            url,
        }),

    put: async (url, body) =>
        makeRequest({
            method: 'put',
            body,
            url,
        }),

    delete: async (url) =>
        makeRequest({
            method: 'delete',
            url,
        }),
};
