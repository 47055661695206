import {
    RECEIVE_ALL_SUBTOPICS,
    RECEIVE_SUBTOPIC,
    UPDATE_SUBTOPIC,
    REMOVE_SUBTOPIC,
    PUBLISH_SUB_TOPIC,
    PUBLISH_SUB_TOPIC_SEQUENCE
} from '../constant';

export const subTopicReducer = (state = {}, action) => {
    let newState;
    let data;

    switch (action.type) {
        case RECEIVE_ALL_SUBTOPICS:
            newState = []
            if (action.data[0]) {
                newState[action.data[0].test_id] = action.data
            }
            return {...state, ...newState};
        case RECEIVE_SUBTOPIC:
            newState = Object.assign({}, state)
            data = action.data;
            if (!newState[data.test_id]) {
                newState[`${data.test_id}`] = [];
            }
            const index = newState[`${data.test_id}`].findIndex(x => x.id === data.id)
            if (index === -1)
                newState[`${data.test_id}`].push(data);
            else
                newState[`${data.test_id}`][index] = data;
            return newState;
        case UPDATE_SUBTOPIC:
            newState = Object.assign({}, state)
            data = action.data;
            let test = newState[`${data.test_id}`]
            for (let i in test) {
                if (test[i].id === data.id) {
                    test[i] = data;
                    break;
                }
            }
            return newState;

        // Object.assign is only 1 layer deep copy
        // Should use something else here as its a nested array in an object
        case REMOVE_SUBTOPIC:
            newState = Object.assign({}, state)
            data = action.data;
            let newQuestion = newState[data.test_id].filter(obj => (data.id !== obj.id))
            newState[data.test_id] = newQuestion
            return newState;
        case PUBLISH_SUB_TOPIC:
            newState = Object.assign({}, state)
            newState = state[action.data.test_id].map(obj => (action.data.id === obj.id) ? action.data : obj);
            return { ...state, [action.data.test_id]: newState };
        case PUBLISH_SUB_TOPIC_SEQUENCE:
            newState = Object.assign({}, state)
            newState = state[action.data.test_id].map(obj => (action.data.id === obj.id) ? action.data : obj);
            return { ...state, [action.data.test_id]: newState };
        default:
            return state;
    }
}
