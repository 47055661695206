import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './AdminproblemSetDetail.scss';
import AdminProblemSetQuestions from '../question/AdminProblemSetQuestions';
import * as problemSetActions from '../../../../../modules/problemSet/action';
import * as answerActions from '../../../../../modules/answer/action';
import * as subtopicActions from '../../../../../modules/subTopic/action';
import * as subSubTopicActions from '../../../../../modules/subSubTopic/action';
import * as testActions from '../../../../../modules/test/action';

class AdminProblemSetDetail extends Component {
    state = {
        problemSettitle: "",
        subtopic: '',
        topic: '',
        subsubtopicId: '',
        satId: '',
        satQuestionId: '',
        actId: ''
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        let error404 = false;
        const { match: { params: { problemset } }, getProblemSet, location, getSubSubtopic, getSubtopic, getTest } = this.props;
        const queryParams = new URLSearchParams(location.search)
        const subsubtopicId = queryParams && queryParams.get('subsubtopic');
        const satId = queryParams && queryParams.get('sat');
        const actId = queryParams && queryParams.get('act');
        const satQuestionId = queryParams && queryParams.get('sat_question');
        const actQuestionId = queryParams && queryParams.get('act_question');
        const getProblemSetDetail = await getProblemSet(problemset).catch(error=>{
            error404 = error.response?.status === 404;
        });
        if(error404){
            return this.props.history.push('/admin/not_found')
        }
        let subsubtopicDetails, subtopicDetails, topicDetails = ''
        if (subsubtopicId) {
            subsubtopicDetails = await getSubSubtopic(subsubtopicId);
            subtopicDetails = await getSubtopic(subsubtopicDetails.subtopic_id);
            topicDetails = await getTest(subtopicDetails.test_id);
        }

        this.setState({ problemSettitle: getProblemSetDetail.title, subtopic: subtopicDetails, topic: topicDetails, subsubtopicId: subsubtopicId, satId: satId, satQuestionId: satQuestionId, actId, actQuestionId })
    }

    takeBack = () => {
        const { subtopic, topic, subsubtopicId, satId, satQuestionId, actId, actQuestionId } = this.state
        let url = "/admin"
        let queryString = ''
        if (topic) {
            if (topic.type === "Topic") {
                url = "/admin/math-topics"
            } else if (topic.type === "ReadingTopic") {
                url = "/admin/reading-topics"
            } else if (topic.type === "GrammarTopic") {
                url = "/admin/grammar-topics"
            }
            queryString = '?topic=' + (topic.id || '') + '&subtopic=' + ((subtopic && subtopic.id) || '') + '&subsubtopic=' + (subsubtopicId)
        } else {
            if (actId && actId.length > 0) {
                queryString = (actId || actQuestionId) ? '/acts?act=' + (actId) + '&act_question=' + (actQuestionId) : ''
            } else {
                queryString = (satId || satQuestionId) ? '?sat=' + (satId) + '&sat_question=' + (satQuestionId) : ''
            }
        }
        this.props.history.push(url + queryString)
    }


    render() {
        let topicType = 'ProblemSet'
        const { match: { params: { problemset } }, createAnswer } = this.props;
        const { problemSettitle, topic, satId, actId } = this.state
        return (
            <div className="row" >
                <div className="content-wrapper-before gradient-45deg-indigo-purple"/>
                <div className="col s12 contentCenter_wrap">
                    <div className="container">
                        <div className="dash_top_section"/>
                        <div className="row vertical-modern-dashboard">
                            <div className="col-12">
                                <div className="card animate fadeLeft">
                                    <div className="question_sec card-content">
                                        <TakeBack condition={(topic || satId || actId)}
                                                      takeBack={()=> this.takeBack()}/>
                                        <div className="quesHeader d-flex flex-wrap align-items-center text-center w-100">
                                            <h1 className=" quesTitle d-inline-block position-relative">
                                                {problemSettitle}
                                            </h1>
                                        </div>

                                        <AdminProblemSetQuestions topicType={topicType}
                                                                  topicId={problemset}
                                                                  section="problemSet"/>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        )
    }
}

const TakeBack = ({condition, takeBack}) =>{
    if(!condition) return null;
    return(
            <div className="dashtitle_sec d-flex flex-wrap align-items-center w-100">
                <button onClick={() => takeBack()} className="btn btn-default">
                    <i className="ico backicon"/>
                    <span className="btntxt">Back</span>
                </button>
            </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return ({
        getSubSubtopic: (id) => dispatch(subSubTopicActions.getSubSubTopic(id)),
        getSubtopic: (id) => dispatch(subtopicActions.getSubTopic(id)),
        getTest: (id) => dispatch(testActions.getTest(id)),
        getProblemSet: (id) => dispatch(problemSetActions.getProblemSet(id)),
    })
}

export default withRouter(connect(null, mapDispatchToProps)(AdminProblemSetDetail))