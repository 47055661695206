import React, {Component} from "react";
import './index.scss';
import PropTypes from "prop-types";
import IfView from "../IfView";

class Select extends Component  {

    constructor(props) {
        super(props);
        this.state = { isOpen: false };
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.isOpen !== this.state.isOpen ){
            if(this.state.isOpen){
                document.addEventListener('mousedown', this.handleClickOutside);
            } else {
                document.removeEventListener('mousedown', this.handleClickOutside);
            }

        }
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({isOpen: false});
        }
    }

    toggling = () =>{
        const { isOpen } = this.state;
        this.setState({isOpen: !isOpen})
    }

    onChange = (option) =>{
        this.props.onChange(option);
        this.setState({isOpen: false});
    }

    render () {
        const { isOpen } = this.state;
        const { options, value, onChange } = this.props;
        return (
            <div className={"select-main-div"} ref={this.wrapperRef}>
                    <div onClick={this.toggling} className="select-header">
                        <div className={"header-text"}>
                            {value.label}
                        </div>
                        <span className="css-indicatorSeparator"/>
                        <div/>
                    </div>

                    {isOpen && (
                        <div className="select-list-container">
                            <ul className="select-list">
                                {
                                    options.map((option)=>{
                                        return(
                                            <li className={option.value === value.value ? "select-list-item active" : "select-list-item"} onClick={() => this.onChange(option)}
                                                key={option.value}>
                                                <div className="select-value">{option.label}</div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    )}
            </div>
        );
    }
}

Select.propTypes = {
    options: PropTypes.array.isRequired,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default Select;