import {API} from '../../api';
import {
    RECEIVE_USER_ACT_PROBLEM_SET_TYPE_PROGRESS,
    RECEIVE_USER_ACT_SEQUENCES_PROGRESS,
    RECEIVE_USER_ACT_TYPE_PROGRESS
} from "../constant";
import { checkSessionExpired } from "..";

// ------------- Start User Act Sequence ActQuestions Type Progress Actions -------------
const receiveUserActSequenceTypeProgress = (data) => ({type: RECEIVE_USER_ACT_TYPE_PROGRESS, data})

export const getUserActSequenceTypeProgress = (attributes) => {
    return (dispatch) => {
        return API.post('/user_progresses/get_act_type_progress', attributes).then(({data}) => {
            dispatch(receiveUserActSequenceTypeProgress(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}
// ------------- End User Act Sequence ActQuestions Type Progress Actions -------------

// ------------- Start User Act ProblemSet ActQuestions Type Progress Actions -------------
const receiveUserActProblemSetTypeProgress = (data) => ({type: RECEIVE_USER_ACT_PROBLEM_SET_TYPE_PROGRESS, data})

export const getUserActProblemSetTypeProgress = (attributes) => {
    return (dispatch) => {
        return API.post('/user_progresses/get_act_problem_set_type_progress', attributes).then(({data}) => {
            dispatch(receiveUserActProblemSetTypeProgress(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}
// ------------- End User Act ProblemSets ActQuestions Type Progress Actions -------------

// ------------- Start User Act ActQuestions Sequence Actions -------------
const receiveUserActSequencesProgress = (data) => ({type: RECEIVE_USER_ACT_SEQUENCES_PROGRESS, data})

export const getUserActSequencesProgress = (attributes) => {
    return (dispatch) => {
        return API.post('/user_progresses/get_user_act_sequences_progress', attributes).then(({data}) => {
            dispatch(receiveUserActSequencesProgress(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

// ------------- End User Act ActQuestions Sequence Actions -------------
