import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import { PaginationFooter } from '../../../utilities/admin.paginationFooter';
import * as studentActions from '../../../modules/student/action';
import * as accountActions from '../../../modules/account/action';
import * as config from "../../../config";

class StudentList extends Component {

  state = {
    limit: config.tablePageLimit(),
    studentPageNo: 1,
  }

  componentDidMount() {
    const { account, history } = this.props;
    if (!account.is_tutor){
      return history.push('/home/not_found')
    }
    window.scrollTo(0,0);
    this.getStudentsPageData(this.state.studentPageNo)
  }

  studentLogin = async (id) => {
    const login = await this.props.studentLogin(id);
    if(login){
      this.props.history.push("/")
    }
  }

  getStudentsPageData(studentPageNo) {
    const { limit } = this.state;
    this.setState({ studentPageNo });
    this.props.getMyStudents({ offset: studentPageNo, limit });
  }

  render() {
    const { students } = this.props;
    const { studentPageNo, limit } = this.state;
    return (
      <>
        <div className="row">
          <div className="content-wrapper-before gradient-45deg-indigo-purple"/>
          <div className="col s12">
            <div className="container">
              <div className="dash_top_section">
                <h2 className="dash_title">My Students</h2>
                <div className="row vertical-modern-dashboard">
                  <div className="col s12">
                    <div className="card animate fadeLeft">
                      <div className="users_sec card-content p-4">

                        <div className="col s12 tabs_content">
                          <div className="col s12 p-0 dataTables_wrapper table-responsive">
                            <table id="pagedata_table" className="dataTable display admins_table m-0">
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Status</th>
                                  <th className="actions">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                              <StudentView studentLogin={this.studentLogin} students={students.data} />
                              </tbody>
                            </table>
                          </div>
                          <div className="appCus_pagination d-inline-flex w-100">
                            {
                              students && students.count > 0 &&
                                <PaginationFooter getPageData={(data) => this.getStudentsPageData(data)}
                                                  pageNo={studentPageNo}
                                                  totalRecords={students.count}
                                                  limit={limit} />
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const StudentView = ({students, studentLogin}) =>{
  if(students && students.length){
    return(_.map(students, (student, i) => {
      return(<tr key={i}>
        <td>
          {student.username}
        </td>
        <td>
          {student.email}
        </td>
        <td>
          <span className={`tblbdg ${student.status ? 'active' : 'inactive'}`}>
            {student.status ? "Active" : "In-Active"}
          </span>
        </td>
        <td className="actions">
          <div className="actn_btngrp display-flex">
            <button className="btn btn-txt btn-default" type="button" onClick={() => studentLogin(student.id)}>
              <span className="txt">Login As</span>
            </button>
          </div>
        </td>
      </tr>);
    }))
  }

  return(<tr><td colSpan='5'>No Record Found</td></tr>)
}

const mapStateToProps = state => {
  return ({
    account: state.account,
    students: state.students
  })
}

const mapDispatchToProps = dispatch => {
  return ({
    getMyStudents: (params) => dispatch(studentActions.getMyStudents(params)),
    studentLogin: (id) => dispatch(accountActions.studentLogin(id))
  })
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StudentList));