import React, { Component } from 'react';
import _ from 'lodash'

class ViewSatConvertedScoreDialog extends Component {

    render() {
        const { score, columns } = this.props;

        return (
            <div className="card-body management-list p-3 text-center">
                <div className="pr-2 overflow-scroll">
                    <table className="table mb-3">
                        <thead className="thead-light">
                            <tr>
                                {_.map(score[0], (dd, key) => {return key < columns && <th scope="col">{dd}</th>})}
                            </tr>
                        </thead>
                        <tbody>
                        {
                            _.map(score, (dd, key) => {
                                return key !== 0 &&
                                <tr key={key}>
                                    {_.map(dd, (dr, key) => {return key < columns && <td>{dr}</td>})}
                                </tr>
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export { ViewSatConvertedScoreDialog };
