import { END_ANIMATION, START_ANIMATION } from "../constant";

export const animationReducer = (state = false, action) => {
    switch (action.type) {
        case START_ANIMATION:
            return true
        case END_ANIMATION:
            return false
        default:
            return state;
    }
}