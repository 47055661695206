import {
    createStyles,
    withStyles,
} from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { AlertMessage } from './AlertMessage';
import * as alertActions from "../../modules/alert/action";

const styles = (theme) => createStyles({
    wrapper: {
        position: 'fixed',
        right: 10,
        top: 50,
        zIndex: 10000,
    },
});
class AlertComponent extends React.Component  {

    deleteAlertByIndex = (key) => {
        this.props.alertDeleteByIndex(key);
    };

    render() {
        const { classes, alerts: {alerts: alertData} } = this.props;
        const recordData = [].concat(alertData).reverse();
        return (
            <div className={classes.wrapper}>
                {
                    alertData.map((record, k) => {
                        const index = recordData.findIndex(data => JSON.stringify(record) === JSON.stringify(data));
                            return(
                                <div key={k} onClick={() => this.deleteAlertByIndex(k)}>
                                    <MessageList key={k} k={k} message={record.message} type={record.type} show={k === (alertData.length - index - 1)}/>
                                </div>
                            )
                        })
                }
            </div>
        );
    }
}

const MessageList = (props) =>{
    const { message, type, k , show } = props;
    if(!show) return null;
    if(typeof message == 'object'){
        return message.map((msg,index)=> <MessageList message={msg} type={type} k={`${k}-${index}`} key={`${k}-${index}`} show={show} />)
    } else if (typeof message === 'string'){
        return <AlertMessage key={k} message={message} type={type}
                             data={['error' | 'snackbar' | 'success' | 'warning'].includes(type)} />;
    } else {
        return <div />;
    }
}

const mapStateToProps = (state) => ({
    alerts: state.alerts
});

const mapDispatchToProps = (dispatch) => ({
    alertDelete: () => dispatch(alertActions.alertDelete()),
    alertDeleteByIndex: payload => dispatch(alertActions.alertDeleteByIndex(payload)),
});

export const Alerts = withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(AlertComponent));
