import * as dbUtil from '../../api/dbUtil';
import {API} from '../../api';
import {
    RECEIVE_ALL_SUBTOPICS,
    RECEIVE_SUBTOPIC,
    UPDATE_SUBTOPIC,
    REMOVE_SUBTOPIC,
    PUBLISH_SUB_TOPIC,
    PUBLISH_SUB_TOPIC_SEQUENCE
} from '../constant'
import { checkSessionExpired, alertPushMessage } from "..";

const receiveAll = (data) => ({type: RECEIVE_ALL_SUBTOPICS, data});
const receive = (data) => ({type: RECEIVE_SUBTOPIC, data});
const updateTest = (data) => ({type: UPDATE_SUBTOPIC, data});
const removeTest = (data) => ({type: REMOVE_SUBTOPIC, data});
const publishSubTopicData = (data) => ({ type: PUBLISH_SUB_TOPIC, data });
const publishSubTopicSequenceData = (data) => ({ type: PUBLISH_SUB_TOPIC_SEQUENCE, data });

export const getAllSubTopics = (params) => {
    return (dispatch) => {
        return dbUtil.fetchMany('subtopics',params).then(({data}) => {
            dispatch(receiveAll(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getSubTopic = (id) => {
    return (dispatch) => {
        return dbUtil.fetch('subtopics',id).then(({data}) => {
            dispatch(receive(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateSubTopics = (id, attributes) => {
    return (dispatch) => {
        return dbUtil.update('subtopics',id, attributes).then(({data}) => {
            dispatch(updateTest(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const deleteSubTopics = (id) => {
    return (dispatch) => {
        return dbUtil.destroy('subtopics',id).then(({data}) => {
            dispatch(removeTest(data));
            dispatch(alertPushMessage({message: `'${data.title}' SubTopic deleted successfully`, type: "success"}));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const createSubTopics = (attributes) => {
    return (dispatch) => {
        return dbUtil.create('subtopics',attributes).then(({data}) => {
            dispatch(alertPushMessage({message: `${data.title} subtopic created Successfully`, type: "success"}));
            dispatch(receive(data));
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateSubTopicPositions = (subtopics) => {
    return (dispatch) => {
        return API.put("/subtopics/update_positions", subtopics).then(({data}) => {
            dispatch(receiveAll(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const publishSubTopic = (attributes) => {
    return (dispatch) => {
        return API.post('/subtopics/publish_unpublish', attributes).then(({data}) => {
            dispatch(publishSubTopicData(data));
            dispatch(alertPushMessage({message: `'${data.title}' SubTopic ${!data.isPublish ? 'Published' : 'Unpublished'}`, type: "success"}));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const publishSubTopicSequence = (attributes) => {
    return (dispatch) => {
        return API.post('/subtopics/sequence_publish_unpublish', attributes).then(({data}) => {
            dispatch(publishSubTopicSequenceData(data))
            dispatch(alertPushMessage({message: `'${data.title}' SubTopic Sequence ${!data.isSequencePublish ? 'Published' : 'Unpublished'}`, type: "success"}));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}