import * as dbUtil from '../../api/dbUtil';
import { checkSessionExpired } from "..";
import { GET_MY_STUDENTS } from "../constant";

const receiveMyStudents = (data) =>  ({ type: GET_MY_STUDENTS, data });

export const getMyStudents = (attributes) => {
    return (dispatch) => {
        return dbUtil.fetchMany("accounts/get_my_students", attributes).then(({ data }) => {
            dispatch(receiveMyStudents(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
        })
    }
}