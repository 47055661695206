import {
    RECEIVE_ALL_SAT_QUESTIONS,
    RECEIVE_SAT_QUESTION,
    UPDATE_SAT_QUESTION,
    PUBLISH_SAT_QUESTION,
    UPDATE_SAT_QUESTION_VIDEO_EXPLANATION,
} from '../constant';

// Not considering creation of new test
export const satQuestionReducer = (state = [], action) => {
    let newState, data = action.data;
    switch (action.type) {
        case RECEIVE_ALL_SAT_QUESTIONS:
            newState = Object.assign({}, state)
            data = action.data;
            if (!newState[data[0].sat_id]) {
                newState[`${data[0].sat_id}`] = {};
            }

            newState[`${data[0].sat_id}`] = data;
            return newState;
        case RECEIVE_SAT_QUESTION:
            for (let i = 0; i < state.length; i++) {
                if (action.data.id === state[i].id) {
                    return state;
                }
            }
            return action.data
        case UPDATE_SAT_QUESTION:
            newState = state
            newState[action.data.sat_id] = state[action.data.sat_id].map(obj => (action.data.id === obj.id) ? action.data : obj);
            return newState;
        case PUBLISH_SAT_QUESTION:
            newState = state
            newState[action.data.sat_id] = state[action.data.sat_id].map(obj => (action.data.id === obj.id) ? action.data : obj);
            return newState;
        case UPDATE_SAT_QUESTION_VIDEO_EXPLANATION:
            newState = state
            newState[action.data.sat_id] = state[action.data.sat_id].map(obj => (action.data.id === obj.id) ? action.data : obj);
            return newState;
        default:
            return state;
    }
}
