import React, { Component } from 'react';

class AssignmentDialog extends Component {
  constructor(props) {
    super(props)
    let today = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
    let dd = String(today.getDate()).padStart(2, '0')
    let mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    let yyyy = today.getFullYear()
    today = `${yyyy}-${mm}-${dd}`;
    this.state = {
      selectedDate: today
    }

  }

  onDateChange = (e) => {
    this.setState({ selectedDate: e.target.value })
  }
  render() {
    const { assignAssignment, dismiss, buttonTitle } = this.props;
    const { selectedDate } = this.state;

    return (
      <div className="p-5 form-group">
        <div>
          <input type="date" value={selectedDate} onChange={this.onDateChange} className="form-control" />
        </div>
        <div className="pt-2 d-flex justify-content-end">
          <div className="pl-2">
            <button className="btn btn-primary" onClick={() => assignAssignment(selectedDate)}>{buttonTitle ?? "Assign"}</button>
          </div>
          <div className="pl-2">
            <button className="btn btn-danger" onClick={dismiss}>Cancel</button>
          </div>
        </div>
      </div>

    )
  }
}

export { AssignmentDialog };