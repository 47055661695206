import React, { Component } from "react";
import PropTypes from 'prop-types';
import logoduology from "../../images/logoduology.png";
import { Link } from "react-router-dom";
import "./AccountTemplate.scss";

class AccountTemplate extends Component {
    render() {
        const {
            title,
            subtitle,
            buttonTitle,
            serviceMessage,
            successMessage,
            onSubmit,
            forgotPassword,
            haveAccount,
            newAccountLink,
            loader,
            children
        } = this.props;
        return (
            <section className="loginPage_sec container-fluid">
                <div className="container">
                    <div className="row flex-column">
                        <span className="d-flex justify-content-center mb-3">
                            <img src={logoduology} alt="logoduology" width="250" />
                        </span>
                        <div className="login_form d-flex flex-wrap w-100 position-relative">
                            <h2 className="title">{title}</h2>
                            {subtitle !== '' && <p className="subtitle">{subtitle}</p>}
                            <ShowMessage message={successMessage} type={"success"} />
                            <ShowMessage message={serviceMessage} type={"service"} />
                            <form className="form app_form w-100 form" onSubmit={onSubmit}>
                                {children}
                                <ForgetPassword condition={forgotPassword === true}/>
                                <div className="row mb-3">
                                    <div className="form-group align-items-center w-100 mb-0">
                                        <button className="btn btn-primary loginbtn" disabled={loader}>
                                            {loader && <i className="fa fa-refresh fa-spin" style={{ marginRight: "5px" }}/>}
                                            {buttonTitle}
                                            {!loader && <i className="ico" />}
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <LoginLink condition={haveAccount === true} />
                            <RegisterLink condition={newAccountLink === true} haveAccount={haveAccount}/>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const ShowMessage = ({message, type = 'success' || 'service'}) =>{
    if(message === '') return null;

    if(type === 'success') {
        return(
            <div className="row successMessage m-1">
                <div className="w-100 successCls successCommonCls">
                    {message}
                </div>
            </div>
        )
    } else {
        return(
            <div className="row serviceMessage m-1">
                <div className="w-100 errorCls errCommonCls">
                    {message}
                </div>
            </div>
        )
    }
}

const ForgetPassword = ({condition}) =>{
    if(condition){
        return (
            <div className="row">
                <div
                    className="form-group col-12 d-flex flex-row align-items-center justify-content-between flex-wrap">
                    <Link to={"/forgot-password"} className="txt_btn forgot_pass">Forgot Password?</Link>
                </div>
            </div>
        )
    }
    return null;
}

const LoginLink = ({condition}) => {
    if (condition) {
        return (
            <p className="createacc mt-5">
                Have an account? Log in <Link to="/" className="txt_btn forgot_pass">here</Link>
            </p>
        )
    }
    return null
}

const RegisterLink = ({condition, haveAccount}) => {
    if (condition) {
        return (
            <p className={`createacc ${haveAccount === true ? '' : 'mt-5'}`}>
                Not registered yet? <Link to="/register" className="txt_btn forgot_pass">Create an
                Account</Link>
            </p>

        )
    }
    return null
}



AccountTemplate.propTypes = {
    title: PropTypes.string,
    buttonTitle: PropTypes.string,
    subtitle: PropTypes.string,
    serviceMessage: PropTypes.string,
    successMessage: PropTypes.string,
    forgotPassword: PropTypes.bool,
    haveAccount: PropTypes.bool,
    newAccountLink: PropTypes.bool,
    loader: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired
}

AccountTemplate.defaultProps = {
    title: '',
    subtitle: '',
    buttonTitle1: 'Submit',
    serviceMessage: '',
    successMessage: '',
    forgotPassword: false,
    haveAccount: false,
    newAccountLink: false,
    loader: false,
    onSubmit: () => {
    }
}

export default AccountTemplate;