import React, { Component } from 'react';

class ProblemSetNotesDialog extends Component {

    constructor(props) {
        super(props)
        this.state = {
            notes: ''
        }
    }

    componentDidMount() {
        const { problemSet } = this.props
        this.setState({ notes: problemSet && problemSet.notes ? problemSet.notes : '' })
    }

    addNotes = () => {
        const { notes } = this.state
        this.props.saveProblemSetNotes(notes)
    }


    render() {
        const { notes } = this.state;
        return (
            <div className="form p-3">
                <div className="form-group">
                    <textarea value={notes} onChange={(e) => this.setState({ notes: e.target.value })}
                        className="form-control" placeholder="Type your notes..." />
                </div>
                <div className="text-right">
                    <button onClick={this.addNotes} className="btn btn-warning">Submit</button>
                </div>
            </div>

        );
    }
}

export { ProblemSetNotesDialog };
