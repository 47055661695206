import React, { Component } from 'react';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import _ from 'lodash'
import { connect } from "react-redux";
import { getAllStudentList } from "../../../modules";

class LinkTutorWithStudentModal extends Component {
    constructor(props) {
        super(props);
        const { linked_users } =  this.props;

        const studentList = linked_users ? _.map(linked_users, (st) => {
            return { value: st.id ?? st._id, label: st.username }
        }) : []
        this.state = {
            animatedComponents: makeAnimated(),
            setStudents: studentList,
            studentList: studentList
        }
    }

    async componentDidMount() {
        await this.props.getAllStudentList();
    }

    handleOnChange = (value) => {
        this.setState({ setStudents: value ?? []});
    }

    handleSubmit = async () => {
        const { setStudents, studentList } = this.state;
        const { linkUsers, close, tutorId } = this.props;
        if(!(_.isEqual(studentList,setStudents))){
            await linkUsers({ user_id: tutorId, linked_users: setStudents && setStudents.length > 0 ? setStudents.map(dd => dd.value) : [] });
        }
        close();
    }

    render() {
        const { students } = this.props;
        const { animatedComponents, setStudents } = this.state

        const getStudents = _.map(students.data, (st) => {
            return({ value: st.id ?? st._id, label: st.username });
        });
        const remainingStudents = _.difference(getStudents, setStudents);
        return (
            <div className="card-body management-list p-3 adjustStudentAreaCls">
                <label className="text-primary text-left font-weight-bold">List of Students:</label>
                <div className="mt-2 mb-5 d-flex">
                    <Select isMulti
                            className={"adjustSelectCls"}
                            maxMenuHeight={120}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            value={setStudents}
                            options={remainingStudents}
                            onChange={value => this.handleOnChange(value)}/>
                    <button onClick={() => this.handleSubmit()} className="btn btn-primary ml-5">Submit</button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return ({
        students: state.users.allStudents
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        getAllStudentList: (params) => dispatch(getAllStudentList(params)),
    })
}

export const LinkTutorWithStudentDialog = connect(mapStateToProps, mapDispatchToProps)(LinkTutorWithStudentModal);
