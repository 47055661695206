const presetFontSizes = [
    '10',
    '12',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '32',
    '36',
    '48',
    '56',
    '72',
];

export default presetFontSizes;
