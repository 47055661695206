import React, { Component } from 'react';
import { PaginationFooter } from '../../../../../utilities/admin.paginationFooter'
import { TYPE_STUDENT } from '../../../../../constants'

class StudentUserList extends Component {

    getStudentsPageData = async (pageNo = this.props.pageNo) => {
        const { fetchStudents, changePageNo } = this.props;
        await changePageNo(pageNo);
        await fetchStudents();
    }

    deleteStudent = async ({id, username}) => {
        const { deleteStudent, fetchTutors, fetchStudents } = this.props;
        let res = window.confirm(`Are you sure you want to delete student ${username} ?`);
        if (res) {
            let resp = window.confirm(`Do you really want to delete student ${username} ?`);
            if (resp) {
                const data = await deleteStudent(id);
                if(data){
                    fetchStudents();
                    fetchTutors();
                }
            }
        }
        return null
    }

    activateDeactivateStudent = async ({id, status, username}) => {
        const { updateStudentStatus } = this.props;
        const statusName = status ? "deactivate" : "activate";
        let res = window.confirm(`Are you sure you want to (${statusName}) ${username}?`);
        if (res) {
            const data = {status: !status};
            await updateStudentStatus(id, data);
        }
        return null
    }

    render() {
        const { pageNo, limit, students, openDialogBox, createStudent, fetchStudents } = this.props;

        return (
            <>
                <div className="addbutton text-right pb-2">
                    <button className="btn btn-default btn-primary"
                            onClick={() => openDialogBox({
                                userType: TYPE_STUDENT, tabLabel: "Create Student", createUser: createStudent,
                                afterAction: fetchStudents
                            })}>
                        <span className="btnTxt">Create Student</span>
                        <i className="ico ico_plus"/>
                    </button>
                </div>
                <div className="col s12 p-0 dataTables_wrapper table-responsive">
                    <table id="pagedata_table2" className="dataTable display students_table">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>User Type</th>
                            <th>Code</th>
                            <th>Status</th>
                            <th className="actions">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            students.data && students.data.length > 0 ?
                                students.data.map((student, i) => {
                                    return <tr key={i}>
                                        <td>
                                            {student.username}
                                        </td>
                                        <td>
                                            {student.email}
                                        </td>
                                        <td>
                                            {student.userType}
                                        </td>
                                        <td>
                                            {student.code ? student.code : "-"}
                                        </td>
                                        <td>
                                            <span className={`tblbdg ${student.status ? 'active' : 'inactive'}`}>
                                                {student.status ? "Active" : "In-Active"}
                                            </span>
                                        </td>
                                        <td className="actions">
                                            <div className="actn_btngrp display-flex">
                                                <button className="btn btn-ico" type="button" onClick={() => this.deleteStudent(student)}>
                                                    <span className="ico delete"/>
                                                </button>
                                                <button className="btn btn-ico" type="button" onClick={() => this.activateDeactivateStudent(student)}>
                                                    <span className={`ico ${student.status ? "cross" : "tick"}`}/>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>;
                                })
                                :
                                <tr>
                                    <td colSpan={"6"}>No Record Found</td>
                                </tr>
                        }
                        </tbody>
                    </table>
                </div>
                <PaginationFooterList getPageData={(data) => this.getStudentsPageData(data)}
                                      pageNo={pageNo}
                                      totalRecords={students?.count}
                                      limit={limit}/>
            </>
        );
    }
}

const PaginationFooterList = (props) => {
    if (props?.totalRecords > 0) {
        return (<PaginationFooter {...props} />)
    }
    return null;
}

export { StudentUserList };
