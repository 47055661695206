import {
    RECEIVE_ALL_SUB_SUB_TOPICS,
    RECEIVE_SUB_SUB_TOPICS,
    UPDATE_SUB_SUB_TOPICS,
    REMOVE_SUB_SUB_TOPICS,
    PUBLISH_SUB_SUB_TOPIC,
    PUBLISH_SUB_SUB_TOPIC_SEQUENCE
} from '../constant';

export const subSubTopicReducer = (state = {}, action) => {
    let newState;
    let data;
    switch (action.type) {
        case RECEIVE_ALL_SUB_SUB_TOPICS:
            newState = []
            if (action.data[0]) {
                newState[action.data[0].subtopic_id] = action.data
            }
            return {...state, ...newState};
        case RECEIVE_SUB_SUB_TOPICS:
            newState = Object.assign({}, state)
            data = action.data;
            if (!newState[data.subtopic_id]) {
                newState[`${data.subtopic_id}`] = [];
            }
            const index = newState[`${data.subtopic_id}`].findIndex(x => x._id === data._id)
            if (index === -1)
                newState[`${data.subtopic_id}`].push(data);
            else
                newState[`${data.subtopic_id}`][index] = data
            return newState;
        case UPDATE_SUB_SUB_TOPICS:
            newState = Object.assign({}, state)
            data = action.data;
            let test = newState[`${data.subtopic_id}`]
            for (let i in test) {
                if (test[i]._id === data._id) {
                    test[i] = data;
                    break;
                }
            }
            return newState;

        // Object.assign is only 1 layer deep copy
        // Should use something else here as its a nested array in an object
        case REMOVE_SUB_SUB_TOPICS:
            newState = Object.assign({}, state)
            data = action.data;
            let newQuestion = newState[data.subtopic_id].filter(obj => (data._id !== obj._id))
            newState[data.subtopic_id] = newQuestion
            return newState;
        case PUBLISH_SUB_SUB_TOPIC:
            newState = Object.assign({}, state)
            newState = state[action.data.subtopic_id].map(obj => (action.data._id === obj._id) ? action.data : obj);
            return { ...state, [action.data.subtopic_id]: newState };
        case PUBLISH_SUB_SUB_TOPIC_SEQUENCE:
            newState = Object.assign({}, state)
            newState = state[action.data.subtopic_id].map(obj => (action.data._id === obj._id) ? action.data : obj);
            return { ...state, [action.data.subtopic_id]: newState };
        default:
            return state;
    }
}
