import React, { Component } from 'react';
import { connect } from 'react-redux';
import AdminSidebar from './sidebar/AdminSidebar'
import AdminContent from './content/AdminContent'
import { Helmet } from "react-helmet";
import Navbar from '../home/Navbar';
import './Admin.scss';
import Footer from '../Footer';
import * as config from "../../config";

const mapStateToProps = state => {
    return ({
        account: state.account,
        dialog: state.dialog,
    })
}

class Admin extends Component {
    render() {
        const { account, match, dialog } = this.props;
        return (
            <>
                <div className={`pageMain_body adminPage_body vertical-layout vertical-menu-collapsible page-header-dark vertical-modern-menu preload-transitions 2-columns${dialog && dialog.open ? " modal-open" : ""}`}
                    data-open="click" data-menu="vertical-modern-menu" data-col="2-columns">
                    <Helmet>
                        <script src={config.appUrl() + "/js/vendors.min.js"}/>
                        <script src={config.appUrl() + "/js/plugins.min.js"}/>
                    </Helmet>
                    <Navbar account={account}
                        match={match}
                    />
                    <AdminSidebar
                        account={account}
                        match={match} />
                    <AdminContent />
                    <Footer />
                </div>
            </>
        )
    }
}

export default connect(mapStateToProps, null)(Admin);
