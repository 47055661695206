import React, {Component} from "react";
import './DropDown.scss';

export default class DropDown extends Component  {

    constructor(props) {
        super(props);
        this.state = { isOpen: false };
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({isOpen: false});
        }
    }

    onOptionClicked = (value) => () => {
        console.log(value);
    };

    toggling = () =>{
        const { isOpen } = this.state;
        this.setState({isOpen: !isOpen})
    }

    render () {
        const { isOpen } = this.state;
        const { children, title, icon } = this.props;
        return (
            <div className="dropdown-main-div" ref={this.wrapperRef}>
                <div onClick={this.toggling} className="dropdown-header">
                    {icon && <i className={`${icon} fa-lg`} aria-hidden="true"/>}
                </div>
                {isOpen && (
                    <div className="dropdown-list-container">
                        <ul className="dropdown-list">
                            {children}
                        </ul>
                    </div>
                )}
            </div>
        );
    }
}


export const DropDownOption = ({className, onClick = function () {},children, disabled}) =>{
    return(
        <li className={`dropdown-list-item ${className}`} onClick={() => disabled ? onClick : onClick()}
            key={Math.random()}>
            {children}
        </li>
    )
}