import { RECEIVE_ALL_REPORT_PROBLEMS } from '../constant';

export const reportedProblemReducer = (state = {data: [],count: 0}, action) => {
    switch (action.type) {
        case RECEIVE_ALL_REPORT_PROBLEMS:
            return action.data;
        default:
            return state;
    }
}
