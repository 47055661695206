import React, { Component } from 'react';
import {TYPE_ADMIN, TYPE_STUDENT, TYPE_TUTOR} from '../../../constants'
import { validateInputs } from '../../../utilities/validation'
import {Loader} from "../../helper/commonMethods";

class AddUserDialog extends Component {
  constructor(props) {
    super(props);
    const { status, userType } =  this.props;
    this.state = {
      name: "",
      email: "",
      userType: userType ?? "",
      status: status ?? false,
      errEmail: '',
      errName: '',
    }

    this.submit = this.submit.bind(this);
  }
  validate() {
    const { email, name } = this.state;
    let errEmailMessage = ''
    let errNameMessage = ''
    let isErrorPresent = false
    if (validateInputs("email", email) === 'empty') {
      errEmailMessage = 'Please enter email'
      isErrorPresent = true
    } else if (validateInputs("email", email) === false) {
      errEmailMessage = 'Invalid email'
      isErrorPresent = true
    }

    if (name.length < 1) {
      errNameMessage = 'Please enter name'
      isErrorPresent = true
    }

    this.setState({ errEmail: errEmailMessage, errName: errNameMessage })

    return !isErrorPresent;
  }
  async submit(e) {
    e.preventDefault();
    const { createUser, close, afterAction } = this.props;
    const { name, email, userType, status } = this.state;
    if (this.validate()) {
      let nameText = name.trim();
      let emailText = email.trim();
      if (userType.length > 0) {
        await this.setState({loading: true});
        let response;
        if (userType === TYPE_ADMIN || userType === TYPE_TUTOR || userType === TYPE_STUDENT) {
          response = await createUser({ username: nameText, email: emailText, userType, status });
        }
        if(response){
          await afterAction();
          await this.setState({ name: '', email: '', loading: false })
          await close();
        } else {
          await this.setState({ loading: false});
        }
      } else {
        await this.setState({ serviceMessage: 'User type not specified', loading: false })
      }
    } else {
      await this.setState({ serviceMessage: 'Please enter name and valid email', loading: false })
    }
  }
  handleChange(event) {
    let errorKey = ''
    if (event.target.name === "name") {
      errorKey = 'errName'
    }
    if (event.target.name === "email") {
      errorKey = 'errEmail'
    }
    this.setState({ [event.target.name]: event.target.value, [errorKey]: '' })
  }

  render() {
    const { name, email, loading } = this.state;
    const { tabLabel, close: closeModal } = this.props;
    return (
      <div className="pl-1 pr-1 mt-3">
        <h4 >{tabLabel}</h4>
        <form className="addUser_form pl-1 pt-1" onSubmit={this.submit}>
          <div className="form-row">
            <div className="form-group w-100">
              <label className="mb-0"><b>Name</b><span className="rqrd_str">*</span></label>
              <input
                className="form-control"
                placeholder="Ex. Scott Willson"
                type="text"
                name="name"
                value={name}
                onChange={(e) => (this.handleChange(e))} />
              <div className="errorCls">{this.state.errName}</div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group w-100">
              <label className="mb-0"><b>Email</b><span className="rqrd_str">*</span></label>
              <input
                className="form-control"
                placeholder="Ex. example@domain.com"
                name="email"
                type="text"
                value={email}
                onChange={(e) => (this.handleChange(e))} />
              <div className="errorCls">{this.state.errEmail}</div>
            </div>
          </div>
          <Loader loading={loading} />
          <div className="form-row w-100">
            <div className="form-group btn-grup w-100 text-right">
              <button type="button" className="btn btn-default btn-blank modal-action modal-close" onClick={closeModal} disabled={loading}>
                <span className="btn_txt">Cancel</span>
              </button>
              <button type="submit" className="btn btn-default btn-primary" disabled={loading}>
                <span className="btn_txt">Submit</span>
                <i className="ico ico_tick_sml"/>
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export { AddUserDialog };
