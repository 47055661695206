import { GET_SETTING } from '../constant';

export const settingReducer = (state = "", action) => {
  switch (action.type) {
    case GET_SETTING:
      return action.data
    default:
      return state;
  }
}
