import React, { Component } from 'react';
import SelectInput from './SelectInput';
import { EXISTING_PROBLEM_SET_DIALOG_BOX, SAT_SECTION_DIALOG_BOX } from '../../../dialogs/dialogs';
import { connect } from 'react-redux';
import { toWords } from '../../../../utilities/custom'
import * as problemSetActions from '../../../../modules/problemSet/action'
import { withRouter } from 'react-router-dom';
import { PublishUnPublishTestButton } from "../common/CommonMethod";

class SectionQuestions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            question: {},
            formActive: false,
            topic: "",
            satTopicModal: this.props.satTopicModal ?? false
        }

        this.toggleForm = this.toggleForm.bind(this);
        this.handler = this.handler.bind(this);
        this.updateQuestion = this.updateQuestion.bind(this);
    }

    componentDidMount() {
        const { questions, satTopicModal } = this.props;
        const queryString = new URLSearchParams(this.props.location.search);
        const getQuestion = questions.find(dd => dd.id === queryString.get('sat_question'))
        if (!satTopicModal && queryString && queryString.get('sat_question') && getQuestion) {
            this.openDialogBox(getQuestion)
        }
    }

    showQuestion(question) {
        const { formActive } = this.state;
        this.setState({ question })
        if (formActive) this.toggleForm();
    }

    toggleForm() {
        const { formActive, question } = this.state;
        if (formActive) {
            this.setState({ formActive: false, title: question.title, topic: question.topic })
        } else {
            this.setState({ formActive: true, title: "", topic: "" })
        }
    }

    renderQuestion() {
        const { question } = this.state;
        if (Object.entries(question).length === 0 && question.constructor === Object) return null;
        return null
    }

    handler(e) {
        const linkedTopicId = e.value;
        this.setState({ topic: linkedTopicId });
    }

    handleChange(prop) {
        return ({ target }) => this.setState({ [prop]: target.value })
    }

    updateQuestion(e) {
        // e.preventDefault();
        const { updateSatQuestion } = this.props;
        const { topic, question } = this.state;
        const data = { topic };
        updateSatQuestion(question.id, data).then(question => {
            this.setState({ question })
        });
    }

    async openDialogBox(question, Existing = false) {
        const { selectTopics, openDialog, getSatProblemSets, problemsets, satTopic, sat, mathTopic, updateAfterSequencePublishUnpublish, actTopic, updateAfterPublishUnpublish} = this.props
        const { satTopicModal } = this.state
        this.setState({ question, topic: question.topic })
        if (satTopicModal === true && Existing === false) {
            await getSatProblemSets({ sat_question_id: question.id })
            // satTopic is now that question of sat from which you want to add the problem set.
            openDialog(EXISTING_PROBLEM_SET_DIALOG_BOX, {
                sat,
                question,
                problemsets,
                satTopic,
                updateAfterPublishUnpublish: updateAfterPublishUnpublish ? updateAfterPublishUnpublish : this.updateAfterPublishUnpublish,
                mathTopic,
                updateAfterSequencePublishUnpublish,
                actTopic
            })
        } else {
            openDialog(SAT_SECTION_DIALOG_BOX, {
                sat,
                question,
                //title: `${(question.title).split('_')[0]} ${toWords(question.type)} Question #${question.position + 1}`,
                title: `${sat.title} ${toWords(question.type)} Question #${question.position + 1}`,
                handler: this.handler,
                selectTopics,
                updateQuestion: this.updateQuestion,
                updateAfterPublishUnpublish: this.updateAfterPublishUnpublish
            })
            this.setState({ satTopicModal: false })
        }
    }

    updateAfterPublishUnpublish = (question, Existing = false) => {
        const { satQuestions } = this.props
        let getUpdatedQuestion = satQuestions[question.sat_id].filter((data) => data.id === question.id)
        if (Existing) {
            this.openDialogBox(getUpdatedQuestion[0], Existing)
        } else {
            this.openDialogBox(getUpdatedQuestion[0])
        }
    }

    renderForm() {
        const { selectTopics } = this.props;
        const { question } = this.state;
        const placeholder = question.topic.title;
        const type = "Topic"
        return (
            <div>
                <form onSubmit={this.updateQuestion}>
                    <SelectInput options={selectTopics}
                                 handler={this.handler}
                                 placeholder={placeholder}
                                 type={type} />
                    <button>Save Changes</button>
                </form>
                <button onClick={this.toggleForm}>Cancel</button>
            </div>
        )
    }

    renderContent() {
        const { formActive } = this.state;
        if (formActive) {
            return this.renderForm()
        } else {
            this.renderQuestion();
        }
    }

    satTypeTest = (header,type) => {
        const { history, sat } = this.props
        let satType = header.includes('Reading')
            ? 'Reading' : header.includes('Writing')
                ? 'Writing' : header.includes('Math - No Calculator')
                    ? 'MathNoCalc' : 'MathCalc'
        // history.push('/admin/sat-test/' + sat.id + '/' + satType)
        history.push(`/admin/sat-test/${sat.id}/${type}/${satType}`)
    }

    publishUnPublishSatTest = async (satType, testType = 'mock') => {
        const { sat: {id: sat_id, math_no_calc_mock_test_publish, math_no_calc_real_test_publish,
            math_calc_mock_test_publish, math_calc_real_test_publish}, updateSAT, flashMessage } = this.props
        if (satType === "MathNoCalc"){
            if(testType === 'real'){
                const updateData = await updateSAT(sat_id, {math_no_calc_real_test_publish: !math_no_calc_real_test_publish})
                if(updateData){
                    await flashMessage({message: `Math No Calculator Real Test ${!math_no_calc_real_test_publish ? 'Published' : 'Unpublished'}`, type: 'success'});
                }
            } else {
                const updateData = await updateSAT(sat_id, {math_no_calc_mock_test_publish: !math_no_calc_mock_test_publish})
                if(updateData){
                    await flashMessage({message: `Math No Calculator Mock Test ${!math_no_calc_mock_test_publish ? 'Published' : 'Unpublished'}`, type: 'success'});
                }
            }
        } else {
            if(testType === 'real'){
                const updateData = await updateSAT(sat_id, {math_calc_real_test_publish: !math_calc_real_test_publish})
                if(updateData){
                    await flashMessage({message: `Math Calculator Real Test ${!math_calc_real_test_publish ? 'Published' : 'Unpublished'}`, type: 'success'});
                }
            } else {
                const updateData = await updateSAT(sat_id, {math_calc_mock_test_publish: !math_calc_mock_test_publish})
                if(updateData) {
                    await flashMessage({message: `Math Calculator Mock Test ${!math_calc_mock_test_publish ? 'Published' : 'Unpublished'}`, type: 'success'});
                }
            }
        }
    }

    render() {
        const { questions, header, fromPopUp, sat, isLastIndex } = this.props;
        const { question } = this.state;

        let satType = header.includes('Reading')
            ? 'Reading' : header.includes('Writing')
                ? 'Writing' : header.includes('Math - No Calculator')
                    ? 'MathNoCalc' : 'MathCalc';

        return (
            <div className="ques_set marginBottom20">
                <h3 className="satQuesIdTitleCls d-flex align-items-center">
                    {/* <span className="mr-3 satTypeTitleCls">{header}</span> */}
                    <span className="d-inline-block mr-2">{header}</span>
                    {
                        (['MathNoCalc','MathCalc'].includes(satType) && fromPopUp !== true) &&
                            <>
                                <button onClick={() => this.satTypeTest(header,"Real")} className="btn btn-sm btn-success mr-2">
                                    Real Test
                                </button>
                                <PublishUnPublishTestButton publish={satType === 'MathNoCalc' ? sat?.math_no_calc_real_test_publish : sat?.math_calc_real_test_publish}
                                                            onClick={()=> this.publishUnPublishSatTest(satType,"real")}/>
                                <button onClick={() => this.satTypeTest(header,"Mock")} className="btn btn-sm btn-success mr-2">
                                    Mock Test
                                </button>
                                <PublishUnPublishTestButton publish={satType === 'MathNoCalc' ? sat?.math_no_calc_mock_test_publish : sat?.math_calc_mock_test_publish}
                                                            onClick={()=> this.publishUnPublishSatTest(satType,"mock")}/>
                            </>
                    }
                </h3>
                <ul>
                    {questions?.map(q => {
                        return (
                            <li className={`question ${question.id === q.id? 'active': ''}`}
                                onClick={() => this.openDialogBox(q)} key={q.id} id={`T${q.id}`}>
                                {q.position + 1}
                            </li>
                        )
                    })}
                </ul>
                {/* {this.renderContent()} */}
                {!isLastIndex && <div className="setBottomBorder"/>}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return ({
        satQuestions: state.satQuestions,
        problemsets: state.problemsets
    })
}

const mapDispatchToProps = (dispatch) => {
    return ({
        getSatProblemSets: (params) => dispatch(problemSetActions.getSatProblemSets(params)),
    })
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SectionQuestions));
