import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { saveState, loadState } from '../utilities/localStorageHelper';
import rootReducer from './rootReducer';

const persistedState = loadState();

const configureStore = createStore(
    rootReducer,
    persistedState,
    applyMiddleware(thunk)
);

configureStore.subscribe(() => {
    const { account, assignments, students } = configureStore.getState();
    saveState({ account, assignments, students });
})

export default configureStore;
