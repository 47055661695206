import { GET_MY_STUDENTS } from '../constant';

const defaultState = { data: [], count: 0 };

export const studentReducer = (state = defaultState, action) => {
    switch (action.type) {
        case GET_MY_STUDENTS:
            return action.data;
        default:
            return state;
    }
};
