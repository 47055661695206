import * as dbUtil from '../../api/dbUtil';
import {RECEIVE_ALL_TESTS, RECEIVE_TEST, REMOVE_TEST, UPDATE_TEST} from "../constant";
import { checkSessionExpired } from "..";

const receiveAll = (data) => ({type: RECEIVE_ALL_TESTS, data});
const receive = (data) => ({type: RECEIVE_TEST, data});
const updateTestData = (data) => ({type: UPDATE_TEST, data});
const removeTest = (data) => ({type: REMOVE_TEST, data});

export const getAllTests = (params) => {
    return (dispatch) => {
        return dbUtil.fetchMany('tests', params).then(({data}) => {
            dispatch(receiveAll(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getTest = (id) => {
    return (dispatch) => {
        return dbUtil.fetch('tests', id).then(({data}) => {
            dispatch(receive(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateTest = (id, attributes) => {
    return (dispatch) => {
        return dbUtil.update('tests', id, attributes).then(({data}) => {
            dispatch(updateTestData(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const deleteTest = (id) => {
    return (dispatch) => {
        return dbUtil.destroy('tests', id).then(({data}) => {
                dispatch(removeTest(data));
                return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const createTest = (attributes) => {
    return (dispatch) => {
        return dbUtil.create('test', attributes).then(({data}) => {
            dispatch(receive(data))
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}
