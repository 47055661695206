import React, {Component} from 'react';
import {CREATE_NEW_DIALOG_BOX} from '../../../dialogs/dialogs';
import './AddNewTopic.scss';
import * as dialogActions from "../../../../modules/dialog/action";
import { connect } from "react-redux";

const mapDispatchToProps = dispatch => {
    return ({
        openDialog: (config, options) => dispatch(dialogActions.open(config, options)),
    })
}

class AddTopic extends Component {

    createAction = async ({title}) => {
        const { createTopic } = this.props;
        await createTopic({title});
    }

    openDialogBox(options) {
        return this.props.openDialog(CREATE_NEW_DIALOG_BOX, {
            createAction: this.createAction,
            title: `Create new ${this.props.topicType}`
        })
    }

    render() {
        const { topicType, alignButtonLeft, disabled } = this.props;
        return (
            <div className="add-sat p-0">
                <div className={`newActForm ${alignButtonLeft ? "" : "d-flex w-100 flex-wrap justify-content-end"}`}>
                    <button type="button" className="btn btn-default btn-primary" onClick={() => this.openDialogBox()} disabled={disabled}>
                        <span className="btnTxt">Create {topicType}</span>
                        <i className="ico ico_plus"/>
                    </button>
                </div>
            </div>
        );
    }
}

export default connect(null, mapDispatchToProps)(AddTopic);














