export default (style = {}) => {

    // Add additional colors here & prefix with HIGHLIGHT
    const customStyleMap = {
        'HIGHLIGHT_transparent': {
            backgroundColor: 'transparent',
        },
        'HIGHLIGHT_#17252a': {
            backgroundColor: '#17252a',
        },
        'HIGHLIGHT_#ffffff': {
            backgroundColor: '#ffffff',
        },
        'HIGHLIGHT_#b80000': {
            backgroundColor: '#b80000',
        },
        'HIGHLIGHT_#db3e00': {
            backgroundColor: '#db3e00',
        },
        'HIGHLIGHT_#fccb00': {
            backgroundColor: '#fccb00',
        },
        'HIGHLIGHT_#008b02': {
            backgroundColor: '#008b02',
        },
        'HIGHLIGHT_#006B76': {
            backgroundColor: '#006B76',
        },
        'HIGHLIGHT_#1273de': {
            backgroundColor: '#1273de',
        },
        'HIGHLIGHT_#004dcf': {
            backgroundColor: '#004dcf',
        },
        'HIGHLIGHT_#5300eb': {
            backgroundColor: '#5300eb',
        },
        'HIGHLIGHT_#eb9694': {
            backgroundColor: '#eb9694',
        },
        'HIGHLIGHT_#fad0c3': {
            backgroundColor: '#fad0c3',
        },
        'HIGHLIGHT_#fef3bd': {
            backgroundColor: '#fef3bd',
        },
        'HIGHLIGHT_#c1e1c5': {
            backgroundColor: '#c1e1c5',
        },
        'HIGHLIGHT_#bedadc': {
            backgroundColor: '#bedadc',
        },
        'HIGHLIGHT_#c4def6': {
            backgroundColor: '#c4def6',
        },
        'HIGHLIGHT_#bed3f3': {
            backgroundColor: '#bed3f3',
        },
        'HIGHLIGHT_#d4c4fb': {
            backgroundColor: '#d4c4fb',
        },
        'HIGHLIGHT_#0099cc': {
            backgroundColor: '#0099cc',
        },
        'HIGHLIGHT_#ff6699': {
            backgroundColor: '#ff6699',
        },
        'HIGHLIGHT_#9966ff': {
            backgroundColor: '#9966ff',
        },
        'HIGHLIGHT_#66cc00': {
            backgroundColor: '#66cc00',
        },
        'HIGHLIGHT_#18ac04': {
            backgroundColor: '#18ac04'
        }
    }

    return {
          customStyleMap,
      };
};
