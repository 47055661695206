import React, {Component} from 'react';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import SATItem from './SATItem';
import "./SortableSAT.scss";


const DragHandle = SortableHandle(() =>
    <span className="sortable-handle px-2 mr-2 d-flex">::</span>
)

const SortableItem = SortableElement(({
                                          sat, deleteSAT, updateSAT, publishUnPublishSat, satTopicModal, satTopic,
                                          updateScore, fromPopUp, mathTopic, updateAfterSequencePublishUnpublish,
                                          actTopic, updateAfterPublishUnpublish, updateBubbleAnswers,
                                          updateSatProgressType
}) => {
    return (
        <li className="sortable-sat w-100" id={`T${sat.id}`} style={{ listStyle: "none", display: "flex", alignItems: "flex-start" }}>
            <div className="card animate fadeLeft w-100">
                <div className="users_sec card-content p-0 d-flex">
                    {satTopicModal === false && <DragHandle />}
                    <SATItem fromPopUp={fromPopUp}
                             satTopicModal={satTopicModal}
                             satTopic={satTopic}
                             sat={sat}
                             deleteSAT={deleteSAT}
                             updateSAT={updateSAT}
                             updateSatProgressType={updateSatProgressType}
                             publishUnPublishSat={publishUnPublishSat}
                             updateScore={updateScore}
                             updateBubbleAnswers={updateBubbleAnswers}
                             mathTopic={mathTopic}
                             updateAfterSequencePublishUnpublish={updateAfterSequencePublishUnpublish}
                             actTopic={actTopic}
                             updateAfterPublishUnpublish={updateAfterPublishUnpublish}/>
                </div>
            </div>
        </li>
    )
})

const SortableList = SortableContainer(({
                                            sats, deleteSAT, updateSAT, publishUnPublishSat, satTopicModal, satTopic,
                                            updateScore, fromPopUp, mathTopic, updateAfterSequencePublishUnpublish,
                                            actTopic, updateAfterPublishUnpublish, updateBubbleAnswers,
                                            updateSatProgressType
}) => {
    return (
        <ul className="sortable-home-sat-list pl-0 w-100">
            {sats.map((sat, index) => {
                return (
                    <SortableItem
                        satTopicModal={satTopicModal}
                        satTopic={satTopic}
                        key={sat.id}
                        index={index}
                        sat={sat}
                        id={sat.id}
                        deleteSAT={deleteSAT}
                        updateSAT={updateSAT}
                        updateSatProgressType={updateSatProgressType}
                        publishUnPublishSat={publishUnPublishSat}
                        updateScore={updateScore}
                        updateBubbleAnswers={updateBubbleAnswers}
                        fromPopUp={fromPopUp}
                        mathTopic={mathTopic}
                        updateAfterSequencePublishUnpublish={updateAfterSequencePublishUnpublish}
                        actTopic={actTopic}
                        updateAfterPublishUnpublish={updateAfterPublishUnpublish}
                    />
                )
            })}
        </ul>
    )
})

class SortableAdminSAT extends Component {
    state = {
        sats: [],
    }

    componentDidMount() {
        const { sats } = this.props;
        this.setState({ sats })
    }


    // React Sortable Method used during mouse events to sort the list
    // Added functionality to this method in order to handle the question addition, deletion, and manipulation
    // mouseEvent is a param automatically passed in by React Sortable when moving questions
    // => we add the param here so we are able to set questions dynamically
    // When sorting the list, local state questions are used.
    // When adding to the list, a new copy of the questions state is used (see f(x)sortableAddQuestion)
    onSortEnd = async (options, mouseEvent, sats = this.props.sats) => {
        const { updateSatsPositions } = this.props;
        const { oldIndex, newIndex } = options;
        if(oldIndex === newIndex) return null;
        const updatedSats = arrayMove(sats, oldIndex, newIndex)
        const x = await updateSatsPositions({sats: updatedSats});
        this.setState({ sats: x })
    }

    render() {
        const {
            sats, deleteSAT, updateSAT, publishUnPublishSat, satTopicModal, satTopic, updateScore, fromPopUp, mathTopic,
            updateAfterSequencePublishUnpublish, actTopic, updateAfterPublishUnpublish, updateBubbleAnswers,
            updateSatProgressType
        } = this.props;
        return (
            <div className="sortable-sats">
                <SortableList useDragHandle
                              onSortEnd={this.onSortEnd}
                              sats={sats}
                              deleteSAT={deleteSAT}
                              updateSAT={updateSAT}
                              updateSatProgressType={updateSatProgressType}
                              publishUnPublishSat={publishUnPublishSat}
                              satTopicModal={satTopicModal}
                              satTopic={satTopic}
                              updateScore={updateScore}
                              updateBubbleAnswers={updateBubbleAnswers}
                              fromPopUp={fromPopUp}
                              mathTopic={mathTopic}
                              updateAfterSequencePublishUnpublish={updateAfterSequencePublishUnpublish}
                              actTopic={actTopic}
                              updateAfterPublishUnpublish={updateAfterPublishUnpublish}
                              helperClass='sortableHelper'
                              lockAxis="y"
                              useWindowAsScrollContainer={true}/>
            </div>
        );
    }
}

export default SortableAdminSAT;
