import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./HomeSubtopic.scss"
import * as dialogActions from '../../../../modules/dialog/action';
import * as problemSetActions from '../../../../modules/problemSet/action'
import { PROBLEM_SETS_DIALOG_BOX, EXISTING_PROBLEM_SET_DIALOG_BOX } from '../../../dialogs/dialogs';
import {SetScrollPosition} from "../../../helper/commonMethods";

class HomeSubSubtopic extends Component {
    state = {
        showEdit: false,
        problemsets: [],
    }

    toggle = (el) => {
        this.setState({ [el]: !this.state[el] })
    }

    openProblemSetDialog = async () => {
        const { openDialog, subSubtopic, getProblemSets, problemsets, satTopicModal, satTopic, location, topicId } = this.props;
        await getProblemSets({ subsubtopic_id: subSubtopic._id })
        if (satTopicModal === true) {
            openDialog(EXISTING_PROBLEM_SET_DIALOG_BOX, {
                subSubtopic,
                problemsets,
                satTopic
            });
        } else {
            let allIds = { topicId, subtopicId: subSubtopic.subtopic_id, subsubtopicId: subSubtopic._id }
            let params = { subSubtopic, problemsets, allIds }
            if ((location.pathname).includes('home')) {
                params = { subSubtopic, problemsets, title: `${subSubtopic.title}`, allIds, openDialogAfterAssignAssignment: this.openDialogAfterAssignAssignment }
            }
            openDialog(PROBLEM_SETS_DIALOG_BOX, params);
        }
    }

    openDialogAfterAssignAssignment = () => {
        this.openProblemSetDialog()
    }

    componentDidMount() {
        const { subSubtopic } = this.props
        const queryString = new URLSearchParams(this.props.location.search);
        if (queryString?.get('subsubtopic') && (subSubtopic._id === queryString.get('subsubtopic'))) {
            this.openProblemSetDialog();
            SetScrollPosition(subSubtopic._id);
        }
    }


    render() {
        const { subSubtopic } = this.props;
        return (
            <div className="home-subtopic">
                <p className="subsubtopicTitle" onClick={this.openProblemSetDialog} id={`T${subSubtopic._id}`}>
                    {subSubtopic.title}
                </p>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return ({
        account: state.account
    })
}


const mapDispatchToProps = (dispatch) => {
    return ({
        getProblemSets: (params) => dispatch(problemSetActions.getAllProblemSets(params)),
        openDialog: (config, options) => dispatch(dialogActions.open(config, options)),
    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeSubSubtopic));

