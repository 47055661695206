import { Component } from "react";
import PropTypes from 'prop-types';

class IfView extends Component {
    render() {
        const { visible, children } = this.props;
        if (visible) return children;
        return null;
    }
}

IfView.propTypes = {
    children: PropTypes.any,
    visible: PropTypes.any.isRequired // check true false conditions
}

IfView.defaultProps = {
    children: null,
    visible: false // check true false conditions
}

export default IfView;