import React, { Component } from 'react';
import {
    LOGIN_BACK_FROM_STUDENT,
    LOGIN_USER,
    LOGOUT_USER,
    STUDENT_LOGIN,
    STUDENT_UNLINKED
} from "../../modules/constant";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import * as accountActions from "../../modules/account/action";

class AccountChangeAlert extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lastEvent: '',
            alertText: ''
        }
        this.storageChange = this.storageChange.bind(this);
        this.studentUnlinked = this.studentUnlinked.bind(this);
    }

    storageChange = (event) =>{
        if (event.storageArea !== localStorage) return;
        if(!event.newValue) return;
        if(event.key !== 'eventType') return;
        if(event.key === 'eventType'){
            const { lastEvent } = this.state;
            this.props.showAlertBox(true);
            this.setState({lastEvent: event.newValue});
            let alertText = "";
            switch (event.newValue) {
                case LOGIN_USER:
                    if(lastEvent === LOGOUT_USER){
                        alertText = "Your account token has been changed. \nPlease refresh page or click the button below";
                    } else {
                        alertText = "You are already logged in. \nPlease refresh page or click the button below";
                    }
                    break;
                case STUDENT_LOGIN:
                    alertText = "You are logged In as student. \nPlease refresh page or click the button below";
                    break;
                case LOGIN_BACK_FROM_STUDENT:
                    if(lastEvent === STUDENT_LOGIN){
                        alertText = "Your account token has been changed. \nPlease refresh page or click the button below";
                    } else {
                        alertText = "You are logged in back to tutor. \nPlease refresh page or click the button below";
                    }
                    break;
                case LOGOUT_USER:
                    alertText = "You are logged out. \nPlease refresh page or click the button below";
                    break;
                case STUDENT_UNLINKED:
                    alertText = "Student Unlinked by Admin. \nPlease Select the valid option";
                    break;
                default:
                    alertText = "";
                    break;
            }

            this.setState({alertText});
        }
    }

    studentUnlinked = () =>{
            this.props.showAlertBox(true);
            this.setState({alertText: "Student Unlinked by Admin. \nPlease Select the valid option", lastEvent: STUDENT_UNLINKED});
    }

    async componentDidMount() {
        window.addEventListener('storage', this.storageChange, false);
        window.addEventListener('studentUnlinked', this.studentUnlinked, false);
    }

    componentWillUnmount() {
        window.addEventListener('storage', this.storageChange, false);
        window.addEventListener('studentUnlinked', this.studentUnlinked, false);
    }

    logout = async () => {
        const { logout, showAlertBox } = this.props;
        await showAlertBox(false);
        await logout();
    }

    backToTutor = async () => {
        const { backToTutorLogin, history, validateCurrentUser, showAlertBox } = this.props;
        const loginBack = await backToTutorLogin(history);
        if(loginBack){
            await showAlertBox(false);
            await validateCurrentUser();
        }
    }

    render() {
        const { show } = this.props;
        const { account: {is_student, tutor_login} } = this.props;

        const { alertText, lastEvent } = this.state;
        if(show && alertText !== ""){
            return (
                <React.Fragment>
                    <div className="shade" />
                    <div className={`dialog report-problem`} >
                        <div className="header">
                            Account Logout Alert
                        </div>
                        <div className="body">
                            <div className={"p-2"}>
                                <div>
                                    <h4>{alertText}</h4>
                                </div>
                                <div className="ml-auto float-right d-flex mt-5">
                                    {
                                        lastEvent === STUDENT_UNLINKED ?
                                            <div className={"width-100 d-flex"}>
                                                <button className="btn btn-danger mr-1 pt-7 pb-7"
                                                        onClick={()=> this.logout()}>
                                                    Logout
                                                </button>
                                                {
                                                    is_student && tutor_login &&
                                                    <button className="btn btn-success ml-2 mr-1 w100"
                                                            onClick={()=> this.backToTutor()}>
                                                        Back To Tutor
                                                    </button>
                                                }
                                            </div>
                                            :
                                            <button className="btn btn-success mr-1"
                                                    onClick={()=> window.location.reload()}>
                                                Reload
                                            </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        return null;
    }
}


const mapStateToProps = state => {
    return ({
        account: state.account
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        validateCurrentUser: () => dispatch(accountActions.validateCurrentUser()),
        backToTutorLogin: (history) => dispatch(accountActions.backToTutorLogin(history)),
        logout: () => dispatch(accountActions.logout()),
    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountChangeAlert));