import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as problemSetActions from '../../../modules/problemSet/action';
import * as problemSetQuestionActions from '../../../modules/problemSetQuestion/action';
import * as userProgressActions from '../../../modules/userProgress/action';
import * as bucketActions from '../../../modules/bucket/action';
import AdminProblemSets from '../../admin/content/topics/problemSets/AdminProblemsets'
import './ProblemSetDialog.scss';
import SVG from '../../SVG';
import '../../admin/content/common/AddNewTopic.scss'
import * as dialogActions from '../../../modules/dialog/action';
import { CREATE_PROBLEM_SET_DIALOG_BOX, MATH_SECTION_DIALOG_BOX } from "../dialogs";
import * as subSubTopicActions from '../../../modules/subSubTopic/action'
import _ from 'lodash'
import AssignButton from '../../home/AssignButton';
import * as questionActions from "../../../modules/question/action";
import * as alertActions from "../../../modules/alert/action";
import {IN_A_ROW_CORRECT_COUNT} from "../../../constants";
import {getProblemSetProgress, getUserProgressAndProgressQuestion} from "../../helper/commonMethods";
import {PublishSequenceButton, ResetSequenceBtn, RestartSequenceBtn, SequenceProgressBar} from "../container/DialogCommonMethods";
import AssignMultipleProblemSet from "../../home/AssignMultipleProblemSet";

class ProblemSetModal extends Component {
    state = {
        title: "",
        topicTitle: "",
        show: false,
        showCreate: false,
        showDropdown: false,
        problemSetlist: [],
        allmathsTopic: [],
        pageOffset: 0
    }

    toggle = (el) => {
        this.setState({ [el]: !this.state[el] })
    }

    async componentDidMount() {
        const { subSubtopic, satTopic, location, account } = this.props
        // const allProblemSets = await getProblemSets({})

        if (location.pathname.includes("home")) {
            await this.props.getUserProgress({ reference_id: subSubtopic._id, reference_type: 'HomeSubsubTopic', user_id: account.id })
            await this.props.getAllUserProblemsetsProgress({ subsubtopic_id: subSubtopic._id, reference_type: 'HomeProblemSetQuestions', user_id: account.id })
        }
        this.setState({
            headerTitle: subSubtopic ? subSubtopic.title : satTopic.title,
            // problemSetlist: allProblemSets , 
            // allmathsTopic, 
        })
        this.toggle('show');
    }

    goToSubtopic = () => {
        const { subSubtopic, close, satTopic, location, allIds } = this.props
        let appendQueryString = ''
        if (allIds && allIds.topicId && allIds.subtopicId && allIds.subsubtopicId) {
            appendQueryString = "?topic=" + allIds.topicId + "&subtopic=" + allIds.subtopicId + "&subsubtopic=" + allIds.subsubtopicId
        }
        if (location.pathname.includes("home/math")) {
            this.props.history.push('/home/math/subsubtopic/' + subSubtopic._id + '/' + subSubtopic.subtopic_id + appendQueryString);
        } else if (location.pathname.includes("home/grammar")) {
            this.props.history.push('/home/grammar/subsubtopic/' + subSubtopic._id + '/' + subSubtopic.subtopic_id + appendQueryString);
        } else if (location.pathname.includes("home/reading")) {
            this.props.history.push('/home/reading/subsubtopic/' + subSubtopic._id + '/' + subSubtopic.subtopic_id + appendQueryString);
        } else {
            if (window.location.href.match(/sat-topics/g)) {
                // const satTopicId = subtopics?.length > 0 ? subtopics?.[0].id : satTopic.id
                const id = subSubtopic ? subSubtopic.subtopic_id : satTopic.id
                const topicType = subSubtopic ? 'subTopic' : 'satTopic.id'
                // alert(satTopic.id)
                this.props.history.push('/admin/' + id, { topicType })
            } else {
                this.props.history.push('/admin/subtopic/' + subSubtopic.subtopic_id + '/' + subSubtopic._id, { topicType: 'subsubTopic' });
            }
        }
        close()
    }

    handleChange(prop) {
        return ({ target }) => this.setState({ [prop]: target.value })
    }

    handleSelectChange = selectedOption => {
        this.setState({ selectedOption });
    };

    createProblemSet = async (e) => {
        e.preventDefault();
        const { subSubtopic, getProblemSets, satTopic, createProblemSet } = this.props
        const id = subSubtopic ? subSubtopic._id : satTopic.id
        let data = {}
        let paramsData = { subsubtopic_id: id }
        if (window.location.href.match(/sat-topics/g)) {
            paramsData = { sattopic_id: id }
            data.sattopic_id = id
        } else {
            data.subsubtopic_id = [id]
        }
        const problemsetsArr = await getProblemSets(paramsData)
        const { title, selectedOption } = this.state;
        // data.position = problemsetsArr.result.length
        data.position = problemsetsArr && problemsetsArr.result && problemsetsArr.result.length ? [{ subsubtopic_id: id, value: problemsetsArr.result.length }] : [{ subsubtopic_id: id, value: 0 }]
        if (window.location.href.match(/sat-topics/g)){
            data.sat_position = problemsetsArr.length;
        }
        data.title = (selectedOption) ? selectedOption.label : title;
        await createProblemSet(data)
        if (selectedOption) {
            this.toggle('showDropdown')
        }

        if (selectedOption === undefined) {
            this.toggle('showCreate')
        }

        this.setState({ title: "", selectedOption: "" });
    }


    openMathsSection = () => {
        const { openDialog, satTopic, subSubtopic, updateAfterSequencePublishUnpublish } = this.props;
        const { allmathsTopic } = this.state;
        // const allProblemSets = await getProblemSets({})
        openDialog(MATH_SECTION_DIALOG_BOX, {
            allmathsTopic,
            satTopic,
            mathTopic: subSubtopic,
            updateAfterSequencePublishUnpublish: (a, b) => updateAfterSequencePublishUnpublish(a, b)
        })
    }

    addfromBucket = () => {
        const { openDialog, subSubtopic, problemsets, updateAfterSequencePublishUnpublish } = this.props;
        openDialog(CREATE_PROBLEM_SET_DIALOG_BOX, {
            problemSetsList: problemsets[subSubtopic._id],
            subSubtopic: subSubtopic,
            updateAfterSequencePublishUnpublish: updateAfterSequencePublishUnpublish
        });
    }

    sequencePubishUnpublishSubSubtopic = async () => {
        const { subSubtopic, subSubTopicSequencePublish, updateAfterSequencePublishUnpublish } = this.props;

        if (subSubtopic._id) {
            await subSubTopicSequencePublish({ id: subSubtopic._id, isSequencePublish: !subSubtopic.isSequencePublish });
            await updateAfterSequencePublishUnpublish(subSubtopic);
        } else {
            alert("Error: No subsubtopic id selected!!")
        }
    }

    resetProgress = async () => {
        const { account, subSubtopic, resetUserProgress } = this.props;
        let res = window.confirm("Are you sure, you want to Reset your Progress for this Sequence?");
        if (res) {
            await resetUserProgress({ reference_id: subSubtopic._id, reference_type: 'HomeSubsubTopic', user_id: account.id });
            this.flashMessage({message: "Sequence Progress Reset", type: 'success'});
        } else {
            return null
        }
    }

    restartSequence = async () => {
        const { account, resetUserProgress, subSubtopic } = this.props;
        let res = window.confirm("Are you sure you want to restart the sequence? Your progress will be reset as well.");
        if (res) {
            await resetUserProgress({ reference_id: subSubtopic._id, reference_type: 'HomeSubsubTopic', user_id: account.id });
            this.flashMessage({message: "Sequence Restart", type: 'success'});
            this.goToSubtopic();
        } else {
            return null
        }
    }

    checkSequenceSubmit = async () =>{
        const { topicType } = this.props;
        if (topicType === 'SatTopic') return this.goToSubtopic();
        const {
            getSubSubTopicQuestions, getProblemSetQuestions, account, subSubtopic, getUserProgress
        } = this.props;
        const insideProblemSet = (_.has(account, 'progress.next_problemset_id') && account?.progress?.next_problemset_id);
        if (insideProblemSet) {
            await getUserProgress({reference_id: subSubtopic._id, reference_type: "HomeSubsubTopic", user_id: account.id})
            await getSubSubTopicQuestions({params: {subsubtopic_id: subSubtopic._id}, order_by: {position: 'asc'}});
            await getProblemSetQuestions({problem_set_id: account?.progress?.next_problemset_id});
            const {subSubTopicQuestions, problemset_questions} = this.props;
            if (problemset_questions.length > 0 && subSubTopicQuestions?.length > 0) {
                const {userProgress, userProgressQuestion} = getUserProgressAndProgressQuestion({
                    account,
                    questions: subSubTopicQuestions
                });
                if (userProgress && (userProgressQuestion === '' || !userProgressQuestion)) {
                    let {problemSetCorrectAnswers} = getProblemSetProgress({account, problemset_questions});
                    if (problemSetCorrectAnswers >= IN_A_ROW_CORRECT_COUNT) {
                        this.flashMessage({message: `Sequence already completed`, type: 'success'});
                        return this.sequenceSubmit();
                    }
                }
            }
        }
        this.goToSubtopic();
    }

    sequenceSubmit = async () => {
        const { saveUserProgress, subSubtopic, account, subSubTopicQuestions } = this.props;
        const userProgress = {
            user_id: account.id,
            reference_type: 'HomeSubsubTopic',
            reference_id: subSubtopic._id,
            question_id: null,
            total_questions: subSubTopicQuestions.length,
            questions_complete: subSubTopicQuestions.length,
            started: false,
            percent_complete: 100,
            time_spent_after_answer: account?.progress?.time_spent_after_answer ?? 0,
        }
        return saveUserProgress(userProgress);
    }

    flashMessage = (payload, timeout = 5000) => {
        const {alertPush, alertDelete} = this.props;
        alertPush(payload);
        setTimeout(() => alertDelete(),timeout);
    }

    render() {
        const {
            problemsets, subSubtopic, satTopic, close, updateProblemSetPosition, updateProblemSetSatPosition,
            topicType, account, location, allIds, updateAfterSequencePublishUnpublish, openDialog, assignments,
            openDialogAfterAssignAssignment
        } = this.props;
        const { show, showCreate, title } = this.state;
        const id = topicType !== 'SatTopic' ? subSubtopic._id : satTopic.id
        const setPercent = setPercentage(account);

        return (
            <div className="admin-problemSet-popup">
                {
                    location.pathname.includes("home") &&
                    <div className="row">
                        {
                            subSubtopic.isSequencePublish === true &&
                            <div className="col-12 col-md-5 leftSideOfPopupCls">
                                <div className="master_hdr d-inline-flex flex-wrap align-items-center justify-content-center w-100">
                                    <div className="titleWrap d-flex flex-wrap align-items-center w-100">
                                        <h5 className="masterTitleCls d-inline-block mr-5">Master it all:</h5>
                                        <div className="contentRight d-inline-flex align-items-center btnGroup my-2">
                                            <SequenceButton subSubtopic={subSubtopic}
                                                            location={location}
                                                            account={account}
                                                            onClick={this.checkSequenceSubmit}/>
                                            <span>
                                                <AssignButton referenceType={"subsubtopic"}
                                                              reference_type={"HomeSubsubTopic"}
                                                              reassign={account.tutor_login && (account?.progress?.percent_complete >= 100) && (assignments?.filter(assignment => (assignment.assignmentable_id === subSubtopic._id &&  assignment.status === "completed")).length > 0)}
                                                              show={account.tutor_login && !(assignments.map((assignment) => assignment.assignmentable_id).includes(subSubtopic._id))}
                                                              referenceId={subSubtopic._id}
                                                              topicName={"subsubtopic"}
                                                              topicId={subSubtopic._id}
                                                              account={account}
                                                              openDialog={openDialog}
                                                              openDialogAfterAssignAssignment={openDialogAfterAssignAssignment}/>
                                            </span>
                                            <RestartSequenceBtn account={account} onClick={this.restartSequence} />
                                            <ResetSequenceBtn account={account} onClick={this.resetProgress} />
                                        </div>
                                    </div>
                                    <div className="progressAndResetDivCls d-inline-flex w-100 my-4">
                                        <SequenceProgressBar account={account} percentage={setPercent} />
                                    </div>
                                </div>
                            </div>
                        }
                        <div className={`col-12 col-md-7 rightSideOfPopupCls mt-2 ${subSubtopic.isSequencePublish === true ? 'side-problem-set-border' : ''}`}>
                            <AssignMultipleProblemSet problemsets={problemsets[id]}
                                                      allIds={allIds}
                                                      updateProblemSetPosition={updateProblemSetPosition}
                                                      updateProblemSetSatPosition={updateProblemSetSatPosition}
                                                      topicType={topicType}
                                                      selectedId={id}
                                                      openDialog={openDialog}
                                                      close={close}
                                                      openDialogAfterAssignAssignment={openDialogAfterAssignAssignment}/>
                        </div>
                    </div>
                }

                {
                    location.pathname.includes("admin") &&
                    <>
                        <div className="btnGroup">
                            <SequenceButton subSubtopic={subSubtopic}
                                            location={location}
                                            account={account}
                                            onClick={this.goToSubtopic}/>
                            <PublishSequenceButton publish={subSubtopic?.isSequencePublish} account={account}
                                                   className={'pubUnpubAdminCls'}
                                                   onClick={() => this.sequencePubishUnpublishSubSubtopic()}/>
                        </div>
                        <div className="linked_Sections">
                            {<h3 className="title">Problem sets:</h3>}
                            <AdminProblemSets problemsets={problemsets[id]}
                                              close={close}
                                              updateProblemSetPosition={updateProblemSetPosition}
                                              updateProblemSetSatPosition={updateProblemSetSatPosition}

                                              topicType={topicType}
                                              selectedId={id}
                                              updateAfterSequencePublishUnpublish={updateAfterSequencePublishUnpublish}
                                              subSubtopic={subSubtopic}/>
                        </div>
                    </>
                }


                {
                    !(account.userType === "Student" || (location.pathname).includes('home')) &&
                    <>
                        <div className="form-container mb-2">
                            {
                                show &&
                                <>
                                    {
                                        !showCreate ?
                                            <div>
                                                <div className="subtopic-form" onClick={() => this.toggle('showCreate')}>
                                                    <SVG name="plus" />
                                                    <p className="mb-0">Problem Set</p>
                                                </div>
                                            </div>
                                            :
                                            <div className="add-topic w-100 position-relative">
                                                <div className="form_wrap d-flex align-items-center justify-content-start w-100 position-relative">
                                                    <form onSubmit={this.createProblemSet}>
                                                        <input type="text"
                                                               value={title}
                                                               onChange={this.handleChange('title')} />
                                                        <button className="btn btn-primary" disabled={title === ""}>Create Problem Set</button>
                                                    </form>
                                                    <button className="btn btn-danger" onClick={() => this.toggle('showCreate')}>Cancel</button>
                                                </div>
                                            </div>
                                    }
                                </>
                            }
                            <div className="form-container">
                                <div>
                                    <div className="subtopic-form" onClick={this.addfromBucket}>
                                        <SVG name="plus" />
                                        <p className="mb-0">Add Problem Set From Bucket</p>
                                    </div>
                                </div>
                            </div>
                            <div className="form-container">
                                <div>
                                    <div className="subtopic-form" onClick={this.openMathsSection}>
                                        <SVG name="plus" />
                                        <p className="mb-0" >Add Existing Problem Set</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        );
    }
}

const setPercentage = (account) =>{
    if (account?.progress?.percent_complete) {
        return account.progress.percent_complete >= 100 ? 100 : account.progress.percent_complete
    }
    return '';
}

const SequenceButton = ({location, account, subSubtopic, onClick}) =>{
    let isHomeLocation = location.pathname.includes("home");
    return(
        <span className={isHomeLocation ? "sequenceHomeBtn my-2 d-flex p-0" : "sequenceBtn"}>
            {
                (isHomeLocation && account?.progress?.percent_complete >= 100) ?
                    <button className="btn btn-primary" disabled={true}>Sequence</button>
                    :
                    <>
                        {
                            !(isHomeLocation && subSubtopic.isSequencePublish === false) &&
                            <button className="btn btn-primary" disabled={!!account.tutor_login} onClick={onClick}>
                                Sequence
                            </button>
                        }
                    </>
            }
      </span>
    )
}

const mapStateToProps = (state) => ({
    account: state.account,
    assignments: state.assignments,
    problemsets: state.problemsets,
    subSubTopicQuestions: state.questions,
    problemset_questions: state.problemset_questions
})

const mapDispatchToProps = dispatch => {
    return ({
        openDialog: (config, options) => dispatch(dialogActions.open(config, options)),
        createProblemSet: (params) => dispatch(problemSetActions.createProblemSet( params)),
        getProblemSets: (params) => dispatch(problemSetActions.getAllProblemSets(params)),
        updateProblemSetPosition: (problemSets) => dispatch(problemSetActions.updateProblemSetPosition(problemSets)),
        updateProblemSetSatPosition: (problemSets) => dispatch(problemSetActions.updateProblemSetSatPosition(problemSets)),
        addBulkBucketQuestion: (data) => dispatch(bucketActions.addBulkBucketQuestion(data)),
        subSubTopicSequencePublish: (data) => dispatch(subSubTopicActions.subSubTopicSequencePublish( data)),
        getUserProgress: (params) => dispatch(userProgressActions.getUserProgress(params)),
        resetUserProgress: (params) => dispatch(userProgressActions.resetUserProgress(params)),
        getAllUserProblemsetsProgress: (params) => dispatch(userProgressActions.getAllUserProblemSetsProgress(params)),
        saveUserProgress: (params) => dispatch(userProgressActions.saveUserProgress(params)),
        getSubSubTopicQuestions: (params) => dispatch(questionActions.getAll('question_subsubtopics', params)),
        getProblemSetQuestions: (params) => dispatch(problemSetQuestionActions.getAllProblemSetQuestions(params)),
        alertPush: (payload) => dispatch(alertActions.alertPush(payload)),
        alertDelete: () => dispatch(alertActions.alertDelete())
    })
}

export const ProblemSetDialog = withRouter(connect(mapStateToProps, mapDispatchToProps)(ProblemSetModal));
