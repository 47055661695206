import React, {Component} from 'react';
import SortableProblemSetQuestions from './SortableProblemSetQuestions';
import AddQuestion from './AddQuestion';
import {connect} from 'react-redux';
import SVG from '../../../../SVG';
import 'katex/dist/katex.min.css'
import {validSelect} from '../../../../../utilities/selectUtil';
import * as animationActions from '../../../../../modules/animation/action';
import * as problemSetQuestionActions from '../../../../../modules/problemSetQuestion/action';
import './AdminQuestions.scss'
import arrayMove from 'array-move';
import * as alertActions from "../../../../../modules/alert/action";
import * as answerActions from "../../../../../modules/answer/action";

const mapStateToProps = state => {
    return ({
        questions: state.questions,
        answers: state.answers,
        loading: state.animation,

        problemset_questions: state.problemset_questions
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        startAnimation: () => dispatch(animationActions.start()),
        endAnimation: () => dispatch(animationActions.end()),
        createAnswer: (attributes) => dispatch(answerActions.createAnswer(attributes)),
        createMultipleAnswer: (params) => dispatch(answerActions.createMultipleAnswer(params)),
        updateProblemSetQuestionPositions: (questions) => dispatch(problemSetQuestionActions.updateProblemSetQuestionPositions(questions)),
        createProblemSetQuestion: (attributes) => dispatch(problemSetQuestionActions.createProblemSetQuestion(attributes)),
        createMultipleProblemSetQuestion: (attributes) => dispatch(problemSetQuestionActions.createMultipleProblemSetQuestion(attributes)),
        getProblemSetQuestions: (params) => dispatch(problemSetQuestionActions.getAllProblemSetQuestions(params)),
        alertPush: (payload) => dispatch(alertActions.alertPush(payload)),
        alertDelete: () => dispatch(alertActions.alertDelete())
    })
}

class AdminProblemSetQuestions extends Component {
    state = {
        question: "",
        questionId: "",
        formActive: false,
        fromQuestion: false,
        fromQuestionPosition: "",
        // newQuestion: {},
    }

    async componentDidMount() {
        const { topicId, startAnimation, endAnimation, getProblemSetQuestions } = this.props;
        if (validSelect(topicId)) {
            startAnimation();
            await getProblemSetQuestions({ problem_set_id: topicId, position: 'asc' });
            await this.sortQuestionsPosition();
            endAnimation();
        }
    }

    sortQuestionsPosition = async () =>{
        const { problemset_questions, updateProblemSetQuestionPositions } =  this.props;
        const questionPositions = problemset_questions.map((d,index) => index === d.position);
        if(questionPositions.includes(false)){
            await updateProblemSetQuestionPositions({questions: problemset_questions});
        }
    }

    async componentDidUpdate(prevProps) {
        const { startAnimation, endAnimation, topicId, getProblemSetQuestions } = this.props;
        if (topicId !== prevProps.topicId && validSelect(topicId)) {
            startAnimation();
            getProblemSetQuestions({ params: { problem_set_id: topicId, position: 'asc' } });
            endAnimation();
        }
    }

    // Function for question multiple question from Bucket
    createMultipleBucketQuestion = async (newQuestions, questionId, answers, invokedQuestion) => {
        const {
            createMultipleAnswer, problemset_questions: problemSetQuestions, createMultipleProblemSetQuestion,
            startAnimation, endAnimation
        } = this.props;
        startAnimation();

        // Adding from the bucket using the main Create Question button
        let questionPosition =  invokedQuestion ? invokedQuestion.position : problemSetQuestions.length - 1;
        // When new attributes are added to the model, they must be updated here
        const questionParams =  newQuestions.map((question, index)=> this.getNewQuestionParams(question,questionId,questionPosition + index));
        const newQuestionsResponse = await createMultipleProblemSetQuestion({questions: questionParams});
        let questionIdsHash = {};
        newQuestionsResponse.map(d => questionIdsHash[d.previous_id] = d.id);
        const answerParams =  answers?.map((answer)=> this.getNewAnswerParams(answer,questionIdsHash[answer.question_id]));
        await createMultipleAnswer({answers: answerParams});

        // set the position as per the specific question from where new question button is clicked
        if (invokedQuestion) {
            await this.setPositionAfterCreateMultiple(newQuestions);
        }
        this.flashMessage({message: `Questions Created From Bucket`, type: 'success'});
        endAnimation();
    }

    setPositionAfterCreateMultiple = async (newQuestions) =>{
        const { problemset_questions: questions } =  this.props;

        let updatedQuestions = questions;
        for(let i = newQuestions.length; i > 0; i--){
            let lastValue = updatedQuestions[updatedQuestions.length - i];
            updatedQuestions = arrayMove(updatedQuestions, (updatedQuestions).length - i, lastValue.position);
        }
        await this.updateQuestionPositions(updatedQuestions);
    }

    getNewQuestionParams = (question, questionId, position) =>{
        const { section } = this.props;
        return {previous_id: question.id, question: question.question, problem_set_id: questionId,
            rank: question.rank, position: position + 1, question_exp: question.question_exp, help: question.help,
            help_exp: question.help_exp, is_calc: question.is_calc, is_free_response: question.is_free_response,
            exp_position_before_answer: question.exp_position_before_answer, question_type: section
        };
    }

    getNewAnswerParams = (ans, newQuestionId) =>{
        const { answer, next_question, explanation, correct, position } = ans;
        if (ans.is_free_response) {
            return({ answer: 'NA', question_id: newQuestionId, explanation: 'NA', correct: true, position,
                is_free_response: true, free_response_answer: ans.free_response_answer, symbol: ans.symbol,
                is_exact_match: ans.is_exact_match})
        }

        return({ answer, question_id: newQuestionId, next_question, explanation, correct, position,
            is_free_response: false, free_response_answer: '', symbol: ''});
    }

    // Function for question duplication & Bucket insertion
    createQuestionFrom = async (question, questionId, answers, invokedQuestion, isDuplicate = false) => {
        const {
            createMultipleAnswer, problemset_questions: problemSetQuestions, createProblemSetQuestion,
            startAnimation, endAnimation
        } = this.props;
        startAnimation();
        let questionPosition;
        if (invokedQuestion) {
            // Duplication and adding from the bucket under a specific question
            questionPosition = invokedQuestion.position;
        } else {
            // Adding from the bucket using the main Create Question button
            questionPosition = problemSetQuestions.length - 1;
        }
        // When new attributes are added to the model, they must be updated here
        const questionParams = this.getNewQuestionParams(question, questionId, questionPosition);
        const newQuestion = await createProblemSetQuestion(questionParams);
        const answerParams =  answers?.map((answer)=> this.getNewAnswerParams(answer,newQuestion.id));
        await createMultipleAnswer({answers: answerParams});

        if (isDuplicate) {
            await this.setPositionAfterCreate();
            this.flashMessage({message: `Duplicate Question Created`, type: 'success'});
        } else {
            this.flashMessage({message: `Question Created From Bucket`, type: 'success'});
        }

        endAnimation();
    }

    // set the position as per the specific question from where new question button is clicked
    setPositionAfterCreate = async () =>{
        const { problemset_questions } = this.props;
        let lastValue = problemset_questions[problemset_questions.length - 1];
        const updatedQuestions = arrayMove(problemset_questions, (problemset_questions).length - 1, lastValue.position);
        await this.updateQuestionPositions(updatedQuestions)
    }

    updateQuestionPositions = async (questions, type = null) => {
        const { startAnimation, endAnimation, updateProblemSetQuestionPositions } = this.props;
        await startAnimation();
        const updated = await updateProblemSetQuestionPositions({questions: questions});
        await endAnimation();
        return updated;
    }

    // Reset form and data for question creation below a specified question
    resetQuestionData = () => {
        this.setState({ fromQuestion: false, fromQuestionPosition: "" })
    }

    // Send props to question form to create question below a specified question
    createFromQuestion = (questionPosition) => {
        this.setState({ fromQuestion: true, fromQuestionPosition: questionPosition })
    }

    //New Question Create Method
    createAndAddQuestion = async (questionData, ref = false) => {
        const { createProblemSetQuestion, startAnimation, endAnimation } = this.props;
        // newQuestion = await createProblemSetQuestion(questionData);
        startAnimation();
        await createProblemSetQuestion(questionData);

        // set the position as per the specific question from where new question button is clicked
        if (ref) {
            await this.setPositionAfterCreate();
        }
        endAnimation();
        this.flashMessage({message: `Question Created`, type: 'success'});
        // this.setState({ newQuestion })
    }

    resetNewQuestion = () => {
        this.setState({ newQuestion: {} })
    }

    renderLoading() {
        const { loading } = this.props;
        if (loading) {
            return (
                <div className="loading-overlay">
                    <SVG name="spin" />
                </div>
            )
        }

        return null;
    }

    renderQuestions() {
        const { section, problemset_questions } = this.props;
        // const { newQuestion } = this.state;
        if (problemset_questions.length > 0) {
            return (
                <SortableProblemSetQuestions section={section}
                                             // newQuestion={newQuestion}
                                             resetNewQuestion={this.resetNewQuestion}
                                             createMultipleBucketQuestion={this.createMultipleBucketQuestion}
                                             problemset_questions={problemset_questions}
                                             createQuestionFrom={this.createQuestionFrom}
                                             updateQuestionPositions={this.updateQuestionPositions}
                                             createFromQuestion={this.createFromQuestion}/>
            )
        }

        return null;
    }

    flashMessage = (payload, timeout = 5000) => {
        const { alertPush, alertDelete } = this.props;
        alertPush(payload);
        setTimeout(() => alertDelete(),timeout);
    }

    render() {
        const { topicId, loading, topicType, section, problemset_questions } = this.props;
        const { fromQuestion, fromQuestionPosition } = this.state;
        if (!validSelect(topicId)) return null;
        // let classes = ['admin-questions'];
        let classes = [];
        if (loading) {
            classes.push('loading-questions')
        }
        return (
            <div className={classes.join(' ')}>
                {this.renderLoading()}
                <AddQuestion section={section}
                             topicType={topicType}
                             fromQuestion={fromQuestion}
                             position={problemset_questions.length}
                             fromQuestionPosition={fromQuestionPosition}
                             createAndAddQuestion={this.createAndAddQuestion}
                             topicId={topicId}
                             createQuestionFrom={this.createQuestionFrom}
                             createMultipleBucketQuestion={this.createMultipleBucketQuestion}
                             updateQuestionPositions={this.updateQuestionPositions}
                             type="problemSet"
                             resetQuestionData={this.resetQuestionData} />
                {this.renderQuestions()}
                
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminProblemSetQuestions);
