import { RECEIVE_ALL_SAT_TOPICS, RECEIVE_SAT_TOPIC, UPDATE_SAT_TOPIC, REMOVE_SAT_TOPIC } from '../constant';

export const satTopicRedcuer = (state = [], action) => {
    let newState;
    switch (action.type) {
        case RECEIVE_ALL_SAT_TOPICS:
            return Object.assign([], state, action.data)
        case RECEIVE_SAT_TOPIC:
            for(let i = 0; i < state.length; i++) {
                if(action.data.id === state[i].id) {
                    return state;
                }
            }
            return Object.assign([], state, [...state, action.data])
        case UPDATE_SAT_TOPIC:
            newState = state.map(obj => (action.data.id === obj.id) ? action.data : obj);
            return newState;
        case REMOVE_SAT_TOPIC:
            newState = state.filter(obj => (action.data.id !== obj.id));
            return newState;
        default:
            return state;
    }
}