import React, {Component} from 'react';
import Select from 'react-select'

class SelectInput extends Component {
    render() {
        const {options, handler, placeholder, type} = this.props;
        let defaultOption = placeholder;
        if(!defaultOption) defaultOption = `Select ${type}`
        return(
            <Select
                options={options}
                onChange={handler}
                placeholder={defaultOption}/>
        )
    }
}

export default SelectInput;
