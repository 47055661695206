import Switch from "react-switch";
import { calculator, noCalculator } from "../../../../../images/switchImages";
import React from "react";

export const MathSwitch = (props) => {
    const { show, isCalc, onChange, disabled } = props;
    if (show) {
        return (
            <Switch
                checked={isCalc}
                onChange={onChange}
                uncheckedIcon={noCalculator}
                checkedIcon={calculator}
                onColor="#808080"
                height={40}
                width={70}
                disabled={disabled}
                handleDiameter={25} />
        )
    }
    return null;
}

export const FreeResponseSwitch = (props) => {
    const { is_free_response, onChange, disabled } = props;
    return <div className="linkedques_container">
        <label className="mr-2">Is free response?</label>
        <Switch
            checked={is_free_response}
            onChange={onChange}
            uncheckedIcon={<i className="fa fa-times switchIconCls" />}
            offColor="#dc3545"
            checkedIcon={<i className="fa fa-check switchIconCls" />}
            onColor="#28a745"
            height={25}
            width={50}
            disabled={disabled}
            handleDiameter={15} />
    </div>
}

export const HideQuestionSwitch = (props) => {
    const { is_hidden, location, onChange, disabled } = props;
    if (location.pathname.includes("problemset")) {
        return <div className="linkedques_container">
            <label className="mr-2">Hide Question</label>
            <Switch
                checked={is_hidden}
                onChange={onChange}
                uncheckedIcon={<i className="fa fa-eye switchIconCls" />}
                checkedIcon={<i className="fa fa-eye-slash switchIconCls" />}
                onColor="#808080"
                height={25}
                width={50}
                disabled={disabled}
                handleDiameter={15} />
        </div>
    }
    return null;
}