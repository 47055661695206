import * as dbUtil from "../../api/dbUtil";
import {API} from '../../api';
import {RECEIVE_ALL_REPORT_PROBLEMS, SUBMIT_REPORT_PROBLEM} from "../constant";
import { checkSessionExpired, alertPushMessage } from "..";

const receiveSubmitReportProblem = (data) => ({type: SUBMIT_REPORT_PROBLEM, data})
const receiveAll = (data) => ({type: RECEIVE_ALL_REPORT_PROBLEMS, data})

export const submitReportProblem = (attributes) => {
    return (dispatch) => {
        return API.post('/reported_problems', attributes).then(({data}) => {
            dispatch(receiveSubmitReportProblem(data))
            dispatch(alertPushMessage({message: 'Issue Reported', type: "success"}));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}


export const getAllReportedProblems = (params) => {
    return (dispatch) => {
        return dbUtil.fetchMany('reported_problems', params).then(({data}) => {
            dispatch(receiveAll(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}
