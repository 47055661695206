import * as dbUtil from '../../api/dbUtil';
import {API} from '../../api';
import {
    PUBLISH_SAT_QUESTION,
    RECEIVE_ALL_SAT_QUESTIONS,
    RECEIVE_SAT_QUESTION,
    UPDATE_SAT_QUESTION,
    UPDATE_SAT_QUESTION_VIDEO_EXPLANATION
} from "../constant";
import { checkSessionExpired } from "..";

const receiveAll = (data) => ({type: RECEIVE_ALL_SAT_QUESTIONS, data});
const receive = (data) => ({type: RECEIVE_SAT_QUESTION, data});
const updateTest = (data) => ({type: UPDATE_SAT_QUESTION, data});
const satQuestionPublishUnpublish = (data) => ({type: PUBLISH_SAT_QUESTION, data});
const receiveUpdateSatQuestionVideo = (data) => ({type: UPDATE_SAT_QUESTION_VIDEO_EXPLANATION, data});

export const getAllSatQuestion = (params) => {
    return (dispatch) => {
        return dbUtil.fetchMany('sat_questions', params).then(({data}) => {
            dispatch(receiveAll(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getSatQuestion = (id) => {
    return (dispatch) => {
        return dbUtil.fetch('sat_questions',  id).then(({data}) => {
            dispatch(receive(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateSatQuestion = (id, attributes) => {
    return (dispatch) => {
        return dbUtil.update('sat_questions',  id, attributes).then(({data}) => {
            dispatch(updateTest(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const publishUnPublishSatQuestion = (attributes) => {
    return (dispatch) => {
        return API.post('/sat_questions/publish_unpublish',  attributes).then(({data}) => {
            dispatch(satQuestionPublishUnpublish(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateSatQuestionVideo = (attributes) => {
    return (dispatch) => {
        return API.post('/sat_questions/update_video_explanation',  attributes).then(({data}) => {
            dispatch(receiveUpdateSatQuestionVideo(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}


