import React, {Component} from 'react';
import AnswerList from './AnswerList';
import 'katex/dist/katex.min.css'
import ReadOnlyEditor from '../editor/ReadOnlyEditor';
import noCalculator from '../../images/no-calculator.png';
import calculator from '../../images/calculator.png';
import "./Question.scss";

class Question extends Component {
    renderCalcImage() {
        const {question} = this.props;
        if (question.is_math && question.is_calc) {
            return(
                <img src={calculator} alt="calculator"/>
            )
        } else if (question.is_math && !question.is_calc) {
            return (
                <img src={noCalculator} alt="no_calculator"/>
            )
        } else {
            return null;
        }
    }
    render() {
        const {question, goPrevQuestion, selectedAnswer, setSelectedAnswer, currentQuestionProg, answers} = this.props;
        if (!question) return null;
        return (
            <div className="question">
                <div className="toolbar">
                    <div className="help">?</div>
                </div>
                <ReadOnlyEditor content={question.question}/>
                {this.renderCalcImage()}
                <AnswerList
                    answers={answers}
                    selectedAnswer={selectedAnswer}
                    setSelectedAnswer={setSelectedAnswer}
                    goPrevQuestion={goPrevQuestion}
                    currentQuestionProg={currentQuestionProg}/>
            </div>
        )
    }
}

export default Question;
