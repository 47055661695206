import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'katex/dist/katex.min.css'
import Switch from 'react-switch';
import * as dialogActions from '../../../../../modules/dialog/action';
import * as animationActions from '../../../../../modules/animation/action';
import * as answerActions from '../../../../../modules/answer/action';
import {LINK_QUESTION_DIALOG_BOX, CREATE_ANSWER_DIALOG_BOX, LINK_PROBLEM_SET_DIALOG_BOX, PREVIEW_QUESTION_DIALOG_BOX} from '../../../../dialogs/dialogs';
import Editor from '../../../../editor/Editor';
import './AddAnswer.scss';

class AddAnswer extends Component {
    state = {
        nextQuestionId: "",
        correct: false,
        isExactMatch: false,
        formActive: false,
        answerState: {},
        explanationState: {},
        problemState: "",
        free_response_answer: '',
        symbol: '',
        symbolPositionLeft: false
    }

    clearForm() {
        this.setState({
            nextQuestionId: "",
            correct: false,
            isExactMatch: false,
            problemState: "",
            symbolPositionLeft: false
        })
    }

    submit = async (e) => {
        e.preventDefault();
        const { createAndAddAnswer, questionId, answers, startAnimation, endAnimation, section, question } = this.props;
        const { nextQuestionId, correct, answerState, explanationState, problemState, free_response_answer, symbol, isExactMatch, symbolPositionLeft } = this.state;
        startAnimation();
        let data = {}
        if (question && question.is_free_response) {
            data = {
                question_id: questionId,
                correct: true,
                is_exact_match: isExactMatch,
                position: answers.length,
                answer_type: section,
                is_free_response: true,
                free_response_answer,
                symbol,
                answer: 'NA',
                next_question: '',
                explanation: 'NA',
                link_problemset: '',
                symbol_position_left: symbolPositionLeft
            };
        } else {
            data = {
                answer: answerState,
                question_id: questionId,
                next_question: nextQuestionId,
                explanation: explanationState,
                correct: correct,
                position: answers.length,
                answer_type: section,
                link_problemset: problemState,
                is_free_response: false,
                free_response_answer: '',
                symbol: ''
            };
        }
        await createAndAddAnswer(data,{message: 'New answer created', type: 'success'});
        endAnimation();
        this.clearForm();
    }

    // State updates

    handleChange(prop) {
        return ({ target }) => this.setState({ [prop]: target.value })
    }

    swapCorrect = () => {
        const { correct } = this.state;
        this.setState({ correct: !correct })
    }

    swapExactAnswer = () => {
        const { isExactMatch } = this.state;
        this.setState({ isExactMatch: !isExactMatch })
    }

    handleQuestion = (e) => {
        const nextQuestionId = e.value;
        this.setState({ nextQuestionId })
    }

    toggleForm = () => {
        const { formActive } = this.state;
        this.setState({ formActive: !formActive })
    }

    updateAnswerState = (answerState) => {
        this.setState({ answerState })
    }

    updateExplanationState = (explanationState) => {
        this.setState({ explanationState })
    }

    handleLinkedQuestion = (nextQuestionId) => {
        this.setState({ nextQuestionId })
    }
    handlelinkedProblemSet = (problemState) => {
        this.setState({ problemState: problemState._id })
    }

    swapSymbolPosition = () => {
        const { symbolPositionLeft } = this.state;
        this.setState({ symbolPositionLeft: !symbolPositionLeft })
    }

    updateSymbolState = (symbol) => {
        this.setState({ symbol })
    }

    // Dialogs

    openLinkedDialog = () => {
        const { openDialog, questions, section } = this.props;
        openDialog(LINK_QUESTION_DIALOG_BOX, {
            handleLinkedQuestion: this.handleLinkedQuestion,
            questions,
            section
        })
    }

    openCreateDialog = () => {
        const { openDialog, createAndAddAnswer, questionId, answers, question } = this.props;
        openDialog(CREATE_ANSWER_DIALOG_BOX, {
            toggleForm: this.toggleForm,
            createAndAddAnswer,
            questionId,
            answers,
            question
        })
    }

    openLinkedPreview = async () => {
        const { openDialog, questions, getAnswers } = this.props;
        const { nextQuestionId } = this.state;
        const preview = questions.find((question => {
            return question.id === nextQuestionId
        }))
        const updatedAnswers = preview.id ? await getAnswers({ params: { question_id: preview.id } }) :  [];
        openDialog(PREVIEW_QUESTION_DIALOG_BOX, { question: preview.question, previewAnswers: updatedAnswers ?? [] });
    }

    openLinkedProblemSet = async () => {
        const { openDialog, subsubtopics, questions, section, subtopicId, topicType, satProblemSets } = this.props;
        let problemSets = subsubtopics[questions[0].subtopic_id]
        if (section === 'subsubTopic') {
            const index = subsubtopics[subtopicId].findIndex(x => x._id === questions[0].subsubtopic_id)
            problemSets = []
            if (index > -1)
                problemSets.push(subsubtopics[subtopicId][index])

        }
        if (topicType === 'SatTopic') {
            problemSets = [{ problemset: satProblemSets }]
        }
        openDialog(LINK_PROBLEM_SET_DIALOG_BOX, {
            handleLinkedProblemSet: this.handlelinkedProblemSet,
            problemSets
        })
    }

    renderForm() {
        const { loading, section, question } = this.props;
        const { correct, formActive, nextQuestionId, free_response_answer, isExactMatch, symbolPositionLeft } = this.state;
        const disabled = nextQuestionId === "";
        // alert(section)

        let disabledLinking = false;
        if (question.link_right_problemset_id || question.link_right_question_id
            || question.link_wrong_problemset_id || question.link_wrong_question_id) {
            disabledLinking = true;
        }


        if (formActive) {
            if (question && question.is_free_response) {
                return (
                    <div className="add-answer-form">

                        <div className="edit-answer-form">
                            <div className="editor-container w-100">
                                <label className="mr-2">Answer:</label>
                                <input type="text" value={free_response_answer} onChange={(e) => this.setState({ free_response_answer: e.target.value })} />
                            </div>
                            <div className="mt-1 editor-container w-100">
                                <label className="mr-2">Symbol:</label>
                                <Editor updateEditorState={this.updateSymbolState} style={{minHeight: 80}}/>
                            </div>
                        </div>
                        <div className="ques_attributes">
                            <div className="linkedques_container">
                                <label className="mr-2">Symbol Position Left</label>
                                <Switch
                                    checked={symbolPositionLeft}
                                    onChange={this.swapSymbolPosition}
                                    label="Symbol Position Left" />
                            </div>

                            <div className="linkedques_container">
                                <label>Exact Match?</label>
                                <Switch
                                    checked={isExactMatch}
                                    onChange={this.swapExactAnswer}
                                    label="Exact Match?" />
                            </div>
                            <div className="buttons btn_grup justify-content-start">
                                <button className="btn btn-success" disabled={loading} onClick={this.submit}>Create Answer</button>
                                <button className="btn btn-danger" onClick={this.toggleForm}>Cancel</button>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="add-answer-form">

                        <label>Answer</label>
                        <Editor updateEditorState={this.updateAnswerState} style={{minHeight: 100}} />

                        <label className="mt-2">Explanation</label>
                        <Editor updateEditorState={this.updateExplanationState} style={{minHeight: 100}} />

                        {((section.match(/subTopic/g)) || (section.match(/SatQuestionTopic/g))) &&
                            <div className="linkedques_container">
                                <label>Linked Question</label>
                                <button className="btn btn-default" disabled={disabledLinking} onClick={this.openLinkedDialog}>Link Question</button>
                                <button className="btn btn-default" onClick={this.openLinkedPreview} disabled={disabled}>Preview Linked Question</button>
                                <button className="btn btn-default" disabled={disabledLinking} onClick={this.openLinkedProblemSet}>Linked Problem set</button>
                            </div>
                        }
                        <div className="ques_attributes">
                            <div className="linkedques_container">
                                <label>Correct Answer?</label>
                                <Switch
                                    checked={correct}
                                    onChange={this.swapCorrect}
                                    label="Correct Answer?" />
                            </div>
                            <div className="buttons btn_grup justify-content-start">
                                <button className="btn btn-success" disabled={loading} onClick={this.submit}>Create Answer</button>
                                <button className="btn btn-danger" onClick={this.toggleForm}>Cancel</button>
                            </div>
                        </div>
                    </div>
                )
            }

        }

        return (
            <button onClick={this.openCreateDialog} className="toggle" className="btn btn-default btn-primary create_btn" type="button">
                <span className="btnTxt">Create Answer</span>
                <span className="ico ico_plus"/>
            </button>
        );
    }

    render() {
        return (
            <div className="add-answer d-flex align-items-center justify-content-start flex-wrap">
                <h2 className="ansTitle mr-auto my-2">Answers</h2>
                {this.renderForm()}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return ({
        questions: state.questions,
        loading: state.animation,
        problemset_questions: state.problemset_questions,
        topics: state.topics,
        subsubtopics: state.subsubtopics,
        satProblemSets: ownProps.satProblemSets
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        openDialog: (config, options) => dispatch(dialogActions.open(config, options)),
        startAnimation: () => dispatch(animationActions.start()),
        endAnimation: () => dispatch(animationActions.end()),
        getAnswers: (params) => dispatch(answerActions.getAllAnswer(params)),
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAnswer);
