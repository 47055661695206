import React, { Component } from 'react';
import { connect } from 'react-redux';
import BucketQuestions from './BucketQuestions';
import BucketAnswers from './BucketAnswers';
import ClearBucket from './ClearBucket';
import './Bucket.scss';
import BucketProblemSets from './BucketProblemSets';

class Bucket extends Component {
	state = {
		content: 'questions',
	}

	setContent(content) {
		this.setState({ content })
	}

	renderContent() {
		const { content } = this.state;
		const { bucketQuestions, bucketAnswers, bucketProblemSet } = this.props;
		switch (content) {
			case 'questions':
				return <BucketQuestions
					questions={bucketQuestions}
					fncType={'bucketView'} />
			case 'answers':
				return <BucketAnswers
					bucketAnswers={bucketAnswers}
					fncType={'bucketView'} />
			case 'problemSet':
				return <BucketProblemSets
					bucketProblemSet={bucketProblemSet}
					fncType={'bucketView'} />
			default:

		}
	}

	render() {
		const { content } = this.state;
		let qClass;
		let aClass;
		let pClass;
		if (content === 'questions') {
			qClass = "active"
		} else if ((content === 'answers')) {
			aClass = "active";
		} else {
			pClass = 'active';
		}
		return (
			<div className="bucket bucketAdjustCls">
				<div className="bucket-header">
					<div className="links">
						<div className={qClass} onClick={() => this.setContent('questions')}>Questions</div>
						<div className={aClass} onClick={() => this.setContent('answers')}>Answers</div>
						<div className={pClass} onClick={() => this.setContent('problemSet')}>ProblemSet</div>
					</div>
					<ClearBucket />
				</div>
				<div className="bucket-content">
					{this.renderContent()}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return ({
		bucketQuestions: state.bucket.questions,
		bucketAnswers: state.bucket.answers,
		bucketProblemSet: state.bucket.problemSet,
	})
}

export default connect(mapStateToProps, null)(Bucket);
