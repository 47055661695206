import React, {Component} from 'react';
import './QuestionFooter.scss';

class TopicFooter extends Component {
    renderNextButton() {
        const {completed, updateQuestion, submitAnswer, currentQuestionProg, selectedAnswer} = this.props;
        if (!currentQuestionProg.next_question && completed) {
            return (
                <button onClick={updateQuestion}>Finish Topic</button>
            )
        }
        else if(completed) {
            return (
                <button onClick={updateQuestion}>Next Question</button>
            )
        } else if (Object.entries(selectedAnswer).length > 0) {
            return (
                <button onClick={submitAnswer}>Submit Answer</button>
            )
        }
    }

    renderBackButton() {
        const {prevQuestion, goPrevQuestion} = this.props;
        if(prevQuestion) {
            return (
                <button onClick={goPrevQuestion}>Back</button>
            )
        }
    }
    render() {
        return (
            <div className="question-footer">
                {this.renderBackButton()}
                {this.renderNextButton()}
            </div>
        )
    }
}

export default TopicFooter;
