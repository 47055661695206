import * as dbUtil from '../../api/dbUtil';
import { API } from '../../api';
import {
    RECEIVE_ALL_PROBLEM_SET_ANSWERS,
    RECEIVE_PROBLEM_SET_ANSWER,
    REMOVE_PROBLEM_SET_ANSWER,
    UPDATE_PROBLEM_SET_ANSWER
} from "../constant";
import { checkSessionExpired } from "..";

const receiveAll = (data) => ({ type: RECEIVE_ALL_PROBLEM_SET_ANSWERS, data });
const receive = (data) => ({ type: RECEIVE_PROBLEM_SET_ANSWER, data });
const updateAnswer = (data) => ({ type: UPDATE_PROBLEM_SET_ANSWER, data });
const removeAnswer = (data) => ({ type: REMOVE_PROBLEM_SET_ANSWER, data });

export const createProblemSetAnswer = (attributes) => {
    return (dispatch) => {
        return dbUtil.create("problemset_answers",attributes).then(({data}) => {
            dispatch(receive(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getAllProblemSetAnswer = (params) => {
    return (dispatch) => {
        return dbUtil.fetchMany('problemset_answers',params).then(({data}) => {
            dispatch(receiveAll(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateProblemSetAnswer = (id, attributes) => {
    return (dispatch) => {
        return dbUtil.update("problemset_answers",id, attributes).then(({data}) => {
            dispatch(updateAnswer(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateProblemSetAnswerPositions = (answers) => {
    return (dispatch) => {
        return API.put("/problemset_answers/update_positions", answers).then(({data}) => {
            dispatch(receiveAll(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return answers;
        });
    }
}

export const deleteProblemSetAnswer = (id) => {
    return (dispatch) => {
        return dbUtil.destroy("problemset_answers",id).then(({data}) => {
            dispatch(removeAnswer(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}
