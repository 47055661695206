import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as dialogActions from '../../../modules/dialog/action';
import SVG from '../../SVG';
import './Dialog.scss';

const mapDispatchToProps = dispatch => {
    return ({ closeDialog: () => dispatch(dialogActions.close()) });
};

class Dialog extends Component {
    constructor(props) {
        super(props);
        this.close = this.close.bind(this);
        this.closeOnEscape = this.closeOnEscape.bind(this);
    }

    componentDidMount() {
        document.activeElement.blur();
        document.body.style.overflowX = 'hidden';
        document.addEventListener('keyup', this.closeOnEscape);
    }

    componentWillUnmount() {
        document.body.style.overflowX = 'scroll';
        document.removeEventListener('keyup', this.closeOnEscape);
    }

    closeOnEscape({ keyCode }) {
        if (keyCode === 27) {
            this.clearQueryStringAndClose()
        }
    }

    clearQueryStringAndClose = () => {
        const { history } = this.props
        history.replace({search: ""});
        this.close();
    }

    close() {
        this.props.closeDialog();
    }

    renderHeader() {
        const { title } = this.props;
        if (!title) return null;
        const header = (typeof title === 'function') ? title(this.props) : title;
        return (
            <div className="header">
                {header}
            </div>
        )
    }

    render() {
        const { component, className } = this.props;
        const DialogComponent = component;
        return (
            <React.Fragment>
                <div className="shade" onClick={this.clearQueryStringAndClose} />
                <div className={`dialog ${className}`}>
                    <SVG name="close" className="close" onClick={this.props.className === "report-problem" ? this.close : this.clearQueryStringAndClose} />
                    {this.renderHeader()}
                    <div className="body" style={{overflowY: "scroll"}}>
                        <DialogComponent close={this.close} changeTitle={this.changeTitle} {...this.props} />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, mapDispatchToProps)(Dialog));
