import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {validSelect} from '../../../../utilities/selectUtil';
import * as questionActions from '../../../../modules/question/action';
import * as animationActions from '../../../../modules/animation/action';
import ViewActTestQuestions from './ViewActTestQuestions'
import './HomeSatTestQuestions.scss'
import SVG from '../../../SVG';
import IfView from "../../../helper/IfView";

const mapStateToProps = state => {
    return ({
        questions: state.questions,
        loading: state.loading,
        account: state.account
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        startAnimation: () => dispatch(animationActions.start()),
        endAnimation: () => dispatch(animationActions.end()),
        getQuestions: (params) => dispatch(questionActions.getAllQuestions(params))
    })
}

class HomeActTestQuestions extends Component {

    constructor(props) {
        super(props)
        this.state = {
            questions: []
        }
    }

    async componentDidMount() {
        const { getQuestions, topicId,  startAnimation, endAnimation, actType, topicType } = this.props;
        const questionType = topicType === 'HomeActMockTestQuestion' ? 'AdminActMockTest' : 'AdminActRealTest';
        startAnimation()
        await getQuestions({ params: { act_id: topicId, act_type: actType, question_type: questionType }, order_by: { position: 'asc' } });
        endAnimation()

    }

    componentDidUpdate(props) {
        if (this.props.questions !== props.questions) {
            this.setState({ questions: this.props.questions })
        }
    }


    render() {
        const { topicId, topicType, section, loading,  actType } = this.props;
        const { questions } = this.state
        if (!validSelect(topicId)) return null;

        return (
            <div className={`home-questions ${loading ? 'loading-topics' : ''}`}>
                <IfView visible={questions?.length > 0}>
                    <ViewActTestQuestions realTest={topicType === 'HomeActRealTestQuestion'}
                                          topicId={topicId}
                                          topicType={topicType}
                                          actType={actType}
                                          section={section}
                                          questions={questions}
                                          firstQuestion={questions ? questions[0] : ''}/>
                </IfView>
                <IfView visible={loading}>
                    <div className="loading-overlay">
                        <SVG name="spin" />
                    </div>
                </IfView>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeActTestQuestions));
