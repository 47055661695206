const buttonConfig = {
    "image": {
        icon: "add-image",
        placeholder: "Paste the image url...",
        fn: "addImage",
        menuId: 0,
    },
    "video": {
        icon: "add-video",
        placeholder: "Paste the video url...",
        fn: "addVideo",
        menuId: 1,
    }
}

export default buttonConfig;
