import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Switch, BrowserRouter} from 'react-router-dom';
import { UnauthenticatedRoute, PrivateRoute, AdminRoute } from './routes';
import Register from './account/Register';
import Login from './account/Login';
import ForgotPassword from './account/ForgotPassword';
import Home from './home/Home';
import TopicEnvironment from './topics/TopicEnvironment';
import DialogContainer from './dialogs/container/DialogContainer';
import SAT from './sat/SAT';
import Admin from './admin/Admin';
import ResetPassword from './account/ResetPassword';
import './App.scss';
import "./css/vendors.min.css";
import "./css/materialize.min.css";
import "./css/style.min.css";
import "./css/data-tables.min.css";
import "./css/dashboard-modern.css";
import "./css/intro.min.css";
import "./css/main.css";
import "./css/dashboard-duology.css";
import "./scss/custom-design.scss";
import { Alerts } from "./alertMessage";
import PageNotFound from "./PageNotFound";
import * as accountActions from "../modules/account/action";
import AccountChangeAlert from "./helper/AccountChangeAlert";

class App extends Component {

    constructor(props) {
        super(props);
        this.state = { show: false };
        this.showAlertBox = this.showAlertBox.bind(this);
    }

    showAlertBox = (show) => {
        this.setState({ show });
    }

    async componentDidMount() {
        const {account, validateCurrentUser} = this.props;
        if (account.loggedIn) {
            await validateCurrentUser();
        }
    }

    render() {
        const { account } = this.props;
        const { show } = this.state;
        return (
            <BrowserRouter>
                <AccountChangeAlert show={show} showAlertBox={this.showAlertBox}/>
                <DialogContainer show={!show}/>
                <Alerts/>
                <Switch>
                    <AdminRoute path={"/admin"}
                                component={Admin}
                                account={account}/>

                    <PrivateRoute path={"/topic/:topicId"}
                                  component={TopicEnvironment}
                                  account={account}/>

                    <PrivateRoute path={"/sat/:satId"}
                                  component={SAT}
                                  account={account}/>

                    <PrivateRoute path={"/home"}
                                  component={Home}
                                  account={account}/>

                    <UnauthenticatedRoute path={"/register"}
                                          component={Register}
                                          account={account}/>

                    <UnauthenticatedRoute path={"/forgot-password"}
                                          component={ForgotPassword}
                                          account={account}/>

                    <UnauthenticatedRoute path={"/reset-password/:token"}
                                          component={ResetPassword}
                                          account={account}/>

                    <UnauthenticatedRoute exact
                                          path={"/"}
                                          component={Login}
                                          account={account}/>

                    <UnauthenticatedRoute path={"*"}
                                          component={PageNotFound}
                                          account={account}/>

                </Switch>
            </BrowserRouter>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return ({
        validateCurrentUser: () => dispatch(accountActions.validateCurrentUser()),
    })
}

const mapStateToProps = state => {
    return ({
        account: state.account,
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
