import { API } from '../../api';
import {RECEIVE_SAT_PROBLEM_SETS, RESET_SAT_PROBLEM_SETS} from "../constant";
import { checkSessionExpired } from "..";


const receiveSatProblemSets = (data) => ({ type: RECEIVE_SAT_PROBLEM_SETS, data });
export const resetSatProblemSets = () => ({ type: RESET_SAT_PROBLEM_SETS });

export const getSatProblemSets = (id) => {
    return (dispatch) => {
        return API.get(`/sats/test_sats/${id}/problem_sets`).then(({ data }) => {
            dispatch(receiveSatProblemSets(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

