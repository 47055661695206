import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReadOnlyEditor from '../../../editor/ReadOnlyEditor';
import * as answerActions from '../../../../modules/answer/action';
import * as animationActions from '../../../../modules/animation/action';
import * as accountActions from '../../../../modules/account/action';
import './ViewProblemSetQuestions.scss'
import _ from 'lodash'
import { IN_A_ROW_CORRECT_COUNT } from '../../../../constants'
import { checkCorrectOnFreeResponseAnswers } from '../../../../utilities/custom'
import { FreeResponseWithExplanation, QuestionView, ViewFullExplanation } from "./CommonQuestionView";
import IfView from "../../../helper/IfView";
import ReportQuestionClass from "./ReportQuestionClass";
import * as alertActions from "../../../../modules/alert/action";
import * as commonRecords from "../../../../modules/storeTemporary/action";
import * as userProgressActions from "../../../../modules/userProgress/action";
import * as assignmentActions from "../../../../modules/assignment/action";

class HomeProblemSetQuestion extends ReportQuestionClass {
    constructor(props) {
        super(props);

        const { account, firstProblemSetQuestion, setProblemSetProgressBar, problemsetQuestions  } = props;
        const problemSetProgress  =  account?.problemsetProgress ?? null;
        let viewProblemSetQuestion = firstProblemSetQuestion ?? '';
        setProblemSetProgressBar(problemSetProgress);

        //skip the question if question is hidden
        if (viewProblemSetQuestion && viewProblemSetQuestion.is_hidden) {
            viewProblemSetQuestion = _.find(problemsetQuestions, ['position', viewProblemSetQuestion.position + 1])
        }

        // Below 'setCurrentQuestion' Method is used in inherited Class and displayed in Super Parent Container
        this.setCurrentQuestion(viewProblemSetQuestion);
        this.state = {
            viewProblemSetQuestion,
            problemSetCorrectAnswers: problemSetProgress?.total_correct ?? 0,
            time_spent_before_answer_for_problemset: problemSetProgress?.time_spent_before_answer ?? 0,
            time_spent_after_answer_for_problemset:  problemSetProgress.time_spent_after_answer ?? 0,
            answersGiven: [],
            getAnswersValue: '',
            showExplanation: false,
            questions_complete: 0,
            free_response_answer_value: '',
            seconds: 0,
            idleTime: 0,
            view_full_explanation: false
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { account } = this.props;
        if(prevProps.account.problemsetProgress !== account.problemsetProgress){
            this.props.setProblemSetProgressBar(account.problemsetProgress);
        }
    }

    checkAndSkipQuestion = (viewProblemSetQuestion) =>{
        const { problemsetQuestions } = this.props;
        if (viewProblemSetQuestion.is_hidden) {
            let nextProblemSetQuestionData = _.find(problemsetQuestions, ['position', viewProblemSetQuestion.position + 1])
            this.skipToNextQuestion(nextProblemSetQuestionData ? nextProblemSetQuestionData : problemsetQuestions[0])
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.props.clearCurrentQuestion();
        this.props.clearProblemSetProgressBar();
    }

    componentDidMount = async () => {
        // this.checkAndSkipQuestion(this.state.viewProblemSetQuestion);
        super.componentDidMount();
        const { getAnswers, startAnimation, endAnimation } = this.props
        const { viewProblemSetQuestion } = this.state
        if (viewProblemSetQuestion) {
            this.timer = setInterval(() => this.counter(), 1000);
            this.idleInterval = setInterval(() => this.checkIdealTime(), 1000);
            startAnimation()
            await getAnswers({ params: { question_id: viewProblemSetQuestion.id } })
            endAnimation()
        }
    }

    checkAnswer = (answer) => {
        const { answers } = this.props
        const { viewProblemSetQuestion } = this.state;
        let allAnswers = answers[viewProblemSetQuestion.id],  answersGiven = this.state.answersGiven, filteredArr = [];

        if (answersGiven.length === 0) {
            answersGiven.push(answer)
            filteredArr = answersGiven
        } else {
            let checkValue = answersGiven.includes(answer)
            if (checkValue) {
                filteredArr = answersGiven.filter(item => item !== answer)
            } else {
                answersGiven.push(answer)
                filteredArr = answersGiven
            }
        }

        let allCorrect = allAnswers.filter((val) => val.correct === true)
        let allCorrectIds = _.map(allCorrect, (d) => d.id)
        let givenAnswerIds = _.map(filteredArr, (d) => d.id)
        let getCorrect = false
        if (allCorrectIds.length === givenAnswerIds.length) {
            getCorrect = _.isEqual(_.sortBy(allCorrectIds), _.sortBy(givenAnswerIds))
        }

        this.setState({ answersGiven: filteredArr, getAnswersValue: filteredArr.length === 0 ? '' : getCorrect })
    }

    nextProblemSetQuestion = (nextProblemSetQuestionData) => {
        const {
            getAnswers, startAnimation, endAnimation, problemsetQuestions, saveUserProblemSetProgress,
            account, topicId, topicType, satQuestionId, saveUserQuestionsProgress
        } = this.props;

        //skip the question if question is hidden
        if (nextProblemSetQuestionData.is_hidden) {
            nextProblemSetQuestionData = _.find(problemsetQuestions, ['position', nextProblemSetQuestionData.position + 1])
        }

        const { getAnswersValue, problemSetCorrectAnswers, time_spent_after_answer_for_problemset, seconds, viewProblemSetQuestion, answersGiven, free_response_answer_value } = this.state

        let questions_complete = this.state.questions_complete + 1

        let totalCorrect = getAnswersValue ? problemSetCorrectAnswers + 1 : 0

        this.setState({
            viewProblemSetQuestion: nextProblemSetQuestionData,
            answersGiven: [],
            getAnswersValue: '',
            showExplanation: false,
            questions_complete,
            problemSetCorrectAnswers: totalCorrect,
            free_response_answer_value: '',
            seconds: 0,
            time_spent_after_answer_for_problemset: time_spent_after_answer_for_problemset + seconds,
            view_full_explanation: false
        }, async () => {
            // Below 'setCurrentQuestion' Method is used in inherited Class and displayed in Super Parent Container
            this.setCurrentQuestion(nextProblemSetQuestionData);
            if (nextProblemSetQuestionData) {

                // save user problem set progress
                // used a unary plus to convert a string to a number in percent_complete
                let userProblemsetProgress = {
                    user_id: account.id,
                    reference_type: topicType,
                    reference_id: topicId,
                    question_id: nextProblemSetQuestionData.id,
                    total_questions: problemsetQuestions.length,
                    questions_complete,
                    started: questions_complete > 0,
                    percent_complete: + ((questions_complete / problemsetQuestions.length) * 100).toFixed(2),
                    total_correct: totalCorrect,
                    sat_question_id: satQuestionId,
                    time_spent_after_answer: time_spent_after_answer_for_problemset + seconds
                }
                let userQuestionParams = {
                    id: account.userQuestionProgress && account.userQuestionProgress._id,
                    user_id: account.id,
                    question_id: viewProblemSetQuestion.id,
                    reference_type: topicType,
                    reference_id: topicId,
                    time_spent_after_answer: seconds,
                    is_correct: getAnswersValue ? getAnswersValue : false,
                    given_answer_ids: viewProblemSetQuestion.is_free_response ? [] : answersGiven,
                    given_free_response_answer: viewProblemSetQuestion.is_free_response ? free_response_answer_value : ''
                }
                saveUserProblemSetProgress(userProblemsetProgress)
                saveUserQuestionsProgress(userQuestionParams)
                if (totalCorrect === IN_A_ROW_CORRECT_COUNT) {
                    const {location: {search: searchParams}, history, completeAssignment} = this.props;
                    await completeAssignment({type: 'problemset', problem_set_id: topicId});
                    this.flashMessage({message: "Problem Set Completed",type: "success"});
                    const queryString = new URLSearchParams(searchParams);
                    const dashboard = !!(queryString && queryString.get('dashboard'))
                    if (dashboard){
                        return history.push({pathname: '/home', params: { assignment: true}});
                    }
                    let appendString = ''
                    if (queryString?.get('topic') && queryString?.get('subtopic') && queryString?.get('subsubtopic')) {
                        appendString = "?topic=" + queryString.get('topic') + "&subtopic=" + queryString.get('subtopic')
                            + "&subsubtopic=" + queryString.get('subsubtopic')
                    }
                    if ((searchParams).includes('math')) {
                        history.push('/home/math' + appendString)
                    } else if ((searchParams).includes('grammar')) {
                        history.push('/home/grammar' + appendString)
                    } else if ((searchParams).includes('reading')) {
                        history.push('/home/reading' + appendString)
                    } else if ((searchParams).includes('sats')) {
                        history.push('/home/sats?sat=' + queryString.get('sat') + '&sat_question=' + queryString.get('sat_question'))
                    } else if ((searchParams).includes('acts')) {
                        history.push('/home/acts?act=' + queryString.get('act') + '&act_question=' + queryString.get('act_question'))
                    }
                } else {
                    await startAnimation();
                    await getAnswers({ params: { question_id: nextProblemSetQuestionData.id } });
                    await endAnimation();
                }
            }
        })
    }

    finalSubmit = async () => {
        const { topicType, topicId, account, resetUserProblemSetProgress, history } = this.props;
        await resetUserProblemSetProgress({ user_id: account.id, reference_type: topicType, reference_id: topicId });
        this.flashMessage({message: "Problem Set Completed",type: "success"});
        history.push('/home/sats');
    }

    showExplanationAndSaveProblemSetProgress = async (nextProblemSetQuestionData) => {
        const { problemsetQuestions, saveUserProblemSetProgress, account, topicId, topicType, satQuestionId, saveUserQuestionsProgress } = this.props
        const { getAnswersValue, problemSetCorrectAnswers, time_spent_before_answer_for_problemset, seconds, viewProblemSetQuestion, answersGiven, free_response_answer_value, } = this.state

        let questions_complete = this.state.questions_complete + 1
        let totalCorrect = getAnswersValue ? problemSetCorrectAnswers + 1 : 0

        // save user problem set progress
        // used a unary plus to convert a string to a number in percent_complete
        let userProblemsetProgress = {
            user_id: account.id,
            reference_type: topicType,
            reference_id: topicId,
            question_id: nextProblemSetQuestionData.id,
            total_questions: problemsetQuestions.length,
            questions_complete,
            started: questions_complete > 0,
            percent_complete: + ((questions_complete / problemsetQuestions.length) * 100).toFixed(2),
            total_correct: totalCorrect,
            sat_question_id: satQuestionId,
            time_spent_before_answer: time_spent_before_answer_for_problemset + seconds
        }
        let userQuestionParams = {
            user_id: account.id,
            question_id: viewProblemSetQuestion.id,
            reference_type: topicType,
            reference_id: topicId,
            time_spent_before_answer: seconds,
            is_correct: getAnswersValue ? getAnswersValue : false,
            given_answer_ids: viewProblemSetQuestion.is_free_response ? [] : answersGiven,
            given_free_response_answer: viewProblemSetQuestion.is_free_response ? free_response_answer_value : ''
        }
        await saveUserProblemSetProgress(userProblemsetProgress);
        await saveUserQuestionsProgress(userQuestionParams);
        this.setState({ showExplanation: true, seconds: 0, time_spent_before_answer_for_problemset: userProblemsetProgress.time_spent_before_answer })
        this.scrollToCheckButton();
        this.alertAnswerStatus();
    }

    checkfreeResponseAnswer = (e, freeResponseAnswers) => {
        if (e.target.value) {
            let val = e.target.value
            let getCorrect = false
            let sortedFreeResponseAnswers = _.orderBy(freeResponseAnswers, ['is_exact_match'], ['desc'])
            let breakCheck = false
            sortedFreeResponseAnswers.forEach(answer => {
                if (breakCheck === false) {
                    let ans = checkCorrectOnFreeResponseAnswers(val, answer)
                    if (ans) {
                        getCorrect = ans
                        breakCheck = true
                    }
                }
            })
            this.setState({ free_response_answer_value: e.target.value, getAnswersValue: getCorrect })
        } else {
            this.setState({ free_response_answer_value: '', getAnswersValue: '' })
        }
    }

    skipToNextQuestion = (nextProblemSetQuestionData) => {
        const { getAnswers, startAnimation, endAnimation } = this.props
        const { time_spent_after_answer_for_problemset, seconds } = this.state

        this.setState({
            viewProblemSetQuestion: nextProblemSetQuestionData,
            answersGiven: [],
            getAnswersValue: '',
            showExplanation: false,
            free_response_answer_value: '',
            seconds: 0,
            time_spent_after_answer_for_problemset: time_spent_after_answer_for_problemset + seconds
        }, async () => {
            // Below 'setCurrentQuestion' Method is used in inherited Class and displayed in Super Parent Container
            this.setCurrentQuestion(nextProblemSetQuestionData);
            if (nextProblemSetQuestionData) {
                await startAnimation();
                await getAnswers({ params: { question_id: nextProblemSetQuestionData.id } })
                await endAnimation();
            }
        })
    }

    render() {
        const { answers, loading, problemsetQuestions } = this.props;
        const { answersGiven, getAnswersValue, viewProblemSetQuestion, showExplanation, free_response_answer_value, view_full_explanation } = this.state;
        if(!viewProblemSetQuestion){
            return null;
        }
        let nextProblemSetQuestionData = _.find(problemsetQuestions, ['position', viewProblemSetQuestion.position + 1])
        let freeResponseAnswers = [];
        if (viewProblemSetQuestion.is_free_response) {
            if (_.keys(answers).length > 0 && viewProblemSetQuestion && answers[viewProblemSetQuestion.id]) {
                freeResponseAnswers = answers[viewProblemSetQuestion.id].filter(dd => dd.is_free_response === true)
            }
        }



        return (
            <div className={`view-problemset-question--inner view-question--inner ${loading ? 'loading-topics' : ''}`}>
                <IfView visible={viewProblemSetQuestion}>
                    <ReadOnlyEditor viewAble={true} content={viewProblemSetQuestion.question} />
                </IfView>
                <IfView visible={showExplanation && viewProblemSetQuestion.exp_position_before_answer}>
                    <ViewFullExplanation
                        question_exp={viewProblemSetQuestion.question_exp}
                        full_explanation={view_full_explanation}
                        onClick={() => this.handle_view_full_explanation()}/>
                </IfView>

                <div className="view-problemset-question--ans view-question--ans">
                    <IfView visible={viewProblemSetQuestion.is_free_response}>
                        <FreeResponseWithExplanation answers={freeResponseAnswers}
                                                     showExplanation={showExplanation}
                                                     answersValue={getAnswersValue}
                                                     value={free_response_answer_value}
                                                     onChange={(e) => this.checkfreeResponseAnswer(e, freeResponseAnswers)} />
                    </IfView>
                    <QuestionView
                        setQuestion={viewProblemSetQuestion}
                        answers={answers}
                        showExplanation={showExplanation}
                        answersGiven={answersGiven}
                        checkAnswer={this.checkAnswer}
                    />
                </div>
                <IfView visible={showExplanation && !viewProblemSetQuestion.exp_position_before_answer}>
                    <ViewFullExplanation
                        question_exp={viewProblemSetQuestion.question_exp}
                        full_explanation={view_full_explanation}
                        onClick={() => this.handle_view_full_explanation()}/>
                </IfView>
                <div className="mt-4 d-flex justify-content-center w-100 position-relative">
                    <IfView visible={showExplanation}>
                        <IfView visible={(answersGiven?.length > 0 || free_response_answer_value !== '')}>
                            <button onClick={() => this.nextProblemSetQuestion(nextProblemSetQuestionData ? nextProblemSetQuestionData : problemsetQuestions[0])}
                                    className="btn btn-primary btn-lg my-3">Next</button>
                        </IfView>
                        <IfView visible={!(answersGiven?.length > 0 || free_response_answer_value !== '')}>
                            <button disabled={true} className="btn btn-primary btn-lg my-3">Next</button>
                        </IfView>
                    </IfView>
                    <IfView visible={!showExplanation}>
                        <button disabled={getAnswersValue === ''}
                                onClick={() => this.showExplanationAndSaveProblemSetProgress(nextProblemSetQuestionData ? nextProblemSetQuestionData : problemsetQuestions[0])}
                                className="btn btn-primary btn-lg my-3" id="check_button">Check</button>
                    </IfView>
                </div>
            </div >
        );

    }
}

const mapStateToProps = state => {
    return ({
        answers: state.answers,
        loading: state.loading,
        account: state.account,
        currentQuestion: state.temporaryData.currentQuestion
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        startAnimation: () => dispatch(animationActions.start()),
        endAnimation: () => dispatch(animationActions.end()),
        getAnswers: (params) => dispatch(answerActions.getAllAnswer(params)),
        saveUserProblemSetProgress: (params) => dispatch(userProgressActions.saveUserProblemSetProgress(params)),
        resetUserProblemSetProgress: (params) => dispatch(userProgressActions.resetUserProblemSetProgress(params)),
        saveUserQuestionsProgress: (params) => dispatch(accountActions.saveUserQuestionsProgress('/user_questions/save_user_questions_progress', params)),
        // openDialog: (config, options) => dispatch(dialogActions.open(config, options)),
        alertPush: (payload) => dispatch(alertActions.alertPush(payload)),
        alertDelete: () => dispatch(alertActions.alertDelete()),
        setProblemSetProgressBar: (payload)=> dispatch(commonRecords.setProblemSetProgress(payload)),
        clearProblemSetProgressBar: ()=> dispatch(commonRecords.clearProblemSetProgress()),
        setCurrentQuestion: (payload)=> dispatch(commonRecords.setCurrentQuestion(payload)),
        clearCurrentQuestion: ()=> dispatch(commonRecords.clearCurrentQuestion()),
        completeAssignment: (params) => dispatch(assignmentActions.completeAssignment(params))
    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeProblemSetQuestion));
