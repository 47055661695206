import React, { Component } from 'react';
import './PageNotFound.scss';

class PageNotFound extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hidden: true,
            receivedContent: false,
            show: true,
            showEdit: false,
        }
    }


    render() {
        return (
            <div className="row">
                <div className="content-wrapper-before gradient-45deg-indigo-purple"/>
                <div className="col s12">
                    <div className="container">
                        <div className="dash_top_section">
                            <div className="dash_header d-flex flex-wrap align-items-center card">
                                <div className="card-content">
                                    <h2>404 Page Not Found</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PageNotFound;