import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HomeQuestions from '../question/HomeQuestions';
import * as satQuestionActions from '../../../../modules/satQuestion/action';
import * as animationActions from '../../../../modules/animation/action'
import './HomeSatQuestionMain.scss';
import TopicMainClass from "./TopicMainClass";
import * as dialogActions from "../../../../modules/dialog/action";

class HomeSatQuestionMain extends TopicMainClass {

    async componentDidMount() {
        const { match: { params: { satQuestionId } }, getSatQuestions } = this.props;
        let error404 = false, published;
        const satTopicQuestion = await getSatQuestions(satQuestionId).catch((error)=>{
            error404 = error.response?.status === 404;
        });
        published = satTopicQuestion?.isPublish && satTopicQuestion?.topic_published;
        if((!satTopicQuestion?.sat_id && satTopicQuestion?.act_id) || error404 || !published){
            this.props.history.push('/home/not_found');
        }
    }

    componentDidUpdate(prevProps) {
        const { currentQuestion } = this.props;
        if(prevProps.currentQuestion !== currentQuestion){
            this.setState({currentQuestion})
        }
    }

    takeBack = () => {
        const { satQuestions, location } = this.props
        const queryString = new URLSearchParams(location.search);
        const mockQueryString = queryString && queryString.get('mock') ? '&area=mock' : ''
        const dashboard = !!(queryString && queryString.get('dashboard'))
        if (dashboard) {
            return this.props.history.push({pathname: '/home', params: { assignment: true}});
        }
        this.props.history.push('/home/sats?sat=' + satQuestions.sat_id + '&sat_question=' + satQuestions.id + mockQueryString)

    }

    render() {
        const { match: { params: { satQuestionId } }, satQuestions } = this.props;
        const ContainerView = this.ContainerView;
        let topicType = 'HomeSatQuestionTopic';

        return (
            <ContainerView title={satQuestions.title}>
                <HomeQuestions
                    topicType={topicType}
                    topicId={satQuestionId}
                    section="HomeSatQuestionTopic"
                    takeBack={this.takeBack}/>
            </ContainerView>
        );
    }
}

const mapStateToProps = state => {
    return ({
        loading: state.animation,
        satQuestions: state.satQuestions,
        currentQuestion: state.temporaryData.currentQuestion
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        startAnimation: () => dispatch(animationActions.start()),
        endAnimation: () => dispatch(animationActions.end()),
        getSatQuestions: (id) => dispatch(satQuestionActions.getSatQuestion(id)),
        openDialog: (config, options) => dispatch(dialogActions.open(config, options)),
    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeSatQuestionMain));
