import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HomeSatTestQuestions from '../question/HomeSatTestQuestions';
import * as animationActions from '../../../../modules/animation/action'
import * as satActions from '../../../../modules/sat/action';
import './HomeSatTestQuestionMain.scss';
import TopicMainClass from "./TopicMainClass";

class HomeSatTestQuestionMain extends TopicMainClass {

    async componentDidMount() {
        const { match: { params: { satId } }, getSat, topicType } = this.props;
        let error404 = false, published;
        const satTopic = await getSat(satId).catch(error=>{
            error404 = error.response?.status === 404;
        });
        if(topicType === 'HomeSatRealTestQuestion'){
            published = satTopic?.math_no_calc_real_test_publish && satTopic?.math_calc_real_test_publish
        } else {
            published = satTopic?.math_calc_mock_test_publish && satTopic?.math_no_calc_mock_test_publish
        }
        // if test is not published and if Sat Topic is not published than redirect to not found page.
        if(error404 || !published || !satTopic?.isPublish){
            return this.props.history.push('/home/not_found')
        }
    }

    takeBack = () => {
        const { match: { params: { satId } }, topicType } = this.props;
        const area = topicType === 'HomeSatMockTestQuestion' ? 'mock' : 'real';
        this.props.history.push(`/home/sats?sat=${satId}&area=${area}`)
    }

    render() {
        const { match: { params: { satId, satType } }, sats, topicType } = this.props;

        let sat = sats.find(dd => dd.id === satId)
        let typeTitle = satType.includes('Reading')
            ? 'Reading' : satType.includes('Writing')
                ? '(Writing and Language)' : satType.includes('MathNoCalc')
                    ? '(Math - No Calculator)' : '(Math - Calculator)';
        return (
            <this.ContainerView title={sat ? sat.title + ' - ' + typeTitle : ''}>
                <HomeSatTestQuestions topicType={topicType}
                                      topicId={satId}
                                      satType={satType}
                                      section={topicType}/>
            </this.ContainerView>
        );
    }
}

const mapStateToProps = state => {
    return ({
        loading: state.animation,
        sats: state.sats
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        startAnimation: () => dispatch(animationActions.start()),
        endAnimation: () => dispatch(animationActions.end()),
        getSat: (id) => dispatch(satActions.getTestSat(id))
    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeSatTestQuestionMain));
