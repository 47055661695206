import React, {Component} from 'react';
import './Answer.scss';
import ReadOnlyEditor from '../editor/ReadOnlyEditor'
import 'katex/dist/katex.min.css'

const answerMapping = {
    0: "A",
    1: "B",
    2: "C",
    3: "D",
    4: "E",
    5: "F"
}

class Answer extends Component {
    constructor(props) {
        super(props);

        this.setSelectedAnswer = this.setSelectedAnswer.bind(this);
    }

    isCorrectAnswer() {
        const {answer} = this.props;
        return answer.correct;
    }

    renderExplanation() {
        const {answer, currentQuestionProg, selectedAnswer} = this.props;
        if(currentQuestionProg.completed) {
            if (answer.id === selectedAnswer.id || this.isCorrectAnswer) {
                return (
                    <div>
                        <ReadOnlyEditor
                            content={answer.explanation}/>
                    </div>
                )
            }
        }

        return null;
    }

    setSelectedAnswer() {
        const {setSelectedAnswer, answer, currentQuestionProg} = this.props;
        if (!currentQuestionProg.completed) {
            setSelectedAnswer(answer)
        }
    }

    render() {
        const {answer, currentQuestionProg, selectedAnswer, idx} = this.props;
        let classes = ["list-letter"];
        if (currentQuestionProg.selected_answer === answer.id ||
            (selectedAnswer.id === answer.id && !currentQuestionProg.completed)) {
            classes.push('selected')
        }
        return (
            <div className="answer" onClick={this.setSelectedAnswer}>
                <div className={classes.join(' ')}>
                    <span>
                        {answerMapping[idx]}
                    </span>
                </div>
                <ReadOnlyEditor
                    content={answer.answer}/>
                {this.renderExplanation()}
            </div>
        )
    }
}

export default Answer;
