import React, { Component } from 'react';
import './AccountDropdown.scss';

class AccountDropdown extends Component {

    logout = (evt) => {
        evt.stopPropagation();
        this.props.logout();
    }

    render() {
        const { account: {is_student, tutor_login, is_admin}, backToTutor } = this.props;
        return (
            <ul className="dropdown-content" id="profile-dropdown">
                <AdminPanelLink isAdmin={is_admin}/>
                <BackToTutorLink onClick={backToTutor} isTutor={is_student && tutor_login}/>
                <li>
                    <a className="grey-text text-darken-1" onClick={this.logout}>
                        <i className="material-icons">keyboard_tab</i> Logout
                    </a>
                </li>
            </ul >
        );
    }
}

const AdminPanelLink = ({isAdmin}) =>{
    if(isAdmin){
        return(<React.Fragment>
                <li>
                    <a className="grey-text text-darken-1 action" href={"/admin"}>
                        <i className="material-icons">person_outline</i> Admin Panel
                    </a>
                </li>
                <li className="divider"/>
            </React.Fragment>
        )
    }
    return null;
}

const BackToTutorLink = ({isTutor, onClick}) =>{
    if(isTutor){
        return(
                <>
                    <li>
                        <a className="grey-text text-darken-1 action" onClick={onClick}>
                            <i className="material-icons">person_outline</i> Back to Tutor
                        </a>
                    </li>
                    <li className="divider"/>
                </>
        )
    }
    return null;
}


export default AccountDropdown;
