import React, { Component } from 'react';
import SortableTopics from './SortableTopics';
import { connect } from 'react-redux';
import SVG from '../../SVG';
import 'katex/dist/katex.min.css'
import { validSelect } from '../../../utilities/selectUtil';
import * as topicActions from '../../../modules/topic/action';
import * as animationActions from '../../../modules/animation/action';
import './HomeTopics.scss'
import IfView from "../../helper/IfView";

class HomeTopics extends Component {
    state = {
        topic: "",
        topicId: "",
        formActive: false,
        fromTopic: false,
        fromTopicPosition: "",
        newTopic: {},
    }

    async componentDidMount() {
        window.scrollTo(0,0);
        const { getTopics, startAnimation, endAnimation, topicType } = this.props;
        if (validSelect(topicType)) {
            startAnimation();
            await getTopics({ order_by: { position: 'asc' }, isPublish: true });
            endAnimation();
        }
    }

    async componentDidUpdate(prevProps) {
        const { startAnimation, endAnimation, getTopics } = this.props;
        // if (this.props.topicId !== prevProps.topicId && validSelect(this.props.topicId)) {
        if (this.props.topicType !== prevProps.topicType) {
            window.scrollTo(0,0);
            startAnimation();
            await getTopics({ order_by: { position: 'asc' }, isPublish: true });
            endAnimation();
        }
        // }
    }

    render() {
        const { loading, topicType, topics, deleteTopic, updateTopic, title } = this.props;
        const { newTopic } = this.state;
        if (!validSelect(topicType)) return null;

        return (
            <div className={loading ?  'loading-topics' : ''}>
                <IfView visible={loading}>
                    <div className="loading-overlay">
                        <SVG name="spin" />
                    </div>
                </IfView>

                <IfView visible={topics.length > 0}>
                    <div className="row">
                        <div className="content-wrapper-before gradient-45deg-indigo-purple"/>
                        <div className="col s12">
                            <div className="container">
                                <div className="dash_top_section">
                                    <h2 className="dash_title">{title}</h2>
                                    <div className="row vertical-modern-dashboard">
                                        <div className="col-12">
                                            <div className="card animate fadeLeft">
                                                <div className="mgr_sec card-content">
                                                    <SortableTopics
                                                        newTopic={newTopic}
                                                        resetNewTopic={this.resetNewTopic}
                                                        topics={topics}
                                                        createTopicFrom={this.createTopicFrom}
                                                        updateTopicPositions={this.updateTopicPositions}
                                                        createFromTopic={this.createFromTopic}
                                                        deleteTopic={deleteTopic}
                                                        updateTopic={updateTopic}
                                                        topicType={topicType}
                                                        satTopicModal={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </IfView>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return ({
        loading: state.animation,
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        startAnimation: () => dispatch(animationActions.start()),
        endAnimation: () => dispatch(animationActions.end()),
        // getTopic: (id) => dispatch(topicActions.get('topics', id)),
        // createTopic: (attributes) => dispatch(topicActions.create('topics', attributes)),
        updateTopicPositions: (topics) => dispatch(topicActions.updatePositions(topics)),
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeTopics);
