import {API} from '../../api';
import * as dbUtil from "../../api/dbUtil";
import {
    RECEIVE_ALL_USER_SAT_SCORES,
    RECEIVE_ALL_USER_SATS,
    RECEIVE_USER_SAT,
    RECEIVE_USER_SAT_SCORE
} from "../constant";
import { checkSessionExpired } from "..";

const receiveAllUserSats = (data) => ({type: RECEIVE_ALL_USER_SATS, data})
const receiveUserSat = (data) => ({type: RECEIVE_USER_SAT, data})
const receiveAllUserSatsScore = (data) => ({type: RECEIVE_ALL_USER_SAT_SCORES, data})
const receiveUserSatScore = (data) => ({type: RECEIVE_USER_SAT_SCORE, data})

export const getAllUserSat = (attributes) => {
    return (dispatch) => {
        return API.get('/user_sats', attributes).then(({data}) => {
            dispatch(receiveAllUserSats(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const saveUserSat = (attributes) => {
    return (dispatch) => {
        return dbUtil.create('user_sats', attributes).then(({data}) => {
            dispatch(receiveUserSat(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getSatScore = (attributes) => {
    return (dispatch) => {
        return dbUtil.fetchMany('user_sats/get_sat_score', attributes).then(({data}) => {
            dispatch(receiveUserSatScore(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getAllSatsScore = (attributes) => {
    return (dispatch) => {
        return dbUtil.fetchMany('user_sats/get_all_sat_scores', attributes).then(({data}) => {
            dispatch(receiveAllUserSatsScore(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

