import React, { Component } from 'react';
import { PaginationFooter } from '../../../../../utilities/admin.paginationFooter';
import { TYPE_ADMIN } from '../../../../../constants';

class AdminUserList extends Component {

    getAdmins = async (pageNo = this.props.pageNo) => {
        const { fetchAdmins, changePageNo } = this.props;
        await changePageNo(pageNo);
        await fetchAdmins();
    }
    
    deleteAdmin = async ({id, username}, type) => {
        const { deleteAdmin, fetchAdmins } = this.props;
        let res = window.confirm(`Are you sure you want to delete this ${type} ?`);
        if (res) {
            let resp = window.confirm(`Do you really want to delete this ${type} ?`);
            if (resp) {
                const data = await deleteAdmin(id);
                if(data){
                    await fetchAdmins();
                }
            }
        }
        return null
    }

    activateDeactivateAdmin = async ({id, status, username}, type) => {
        const { updateAdminStatus } = this.props;
        const statusName = status ? "deactivate" : "activate";
        let res = window.confirm(`Are you sure you want to (${statusName}) this ${type} ?`);
        if (res) {
            const data = { status: !status };
            await updateAdminStatus(id, data);
        }
    }

    render() {
        const { admins, openDialogBox, createAdmin, addUserRole, limit, pageNo, fetchAdmins } = this.props;

        return(
            <>
                <div className="addbutton text-right pb-2">
                    <button className="btn btn-default btn-primary" onClick={() => openDialogBox({
                        userType: TYPE_ADMIN, createUser: createAdmin, tabLabel: "Create Admin", afterAction: fetchAdmins
                    })}>
                        <span className="btnTxt">Add Admin</span>
                        <i className="ico ico_plus"/>
                    </button>
                </div>

                <div className="col s12 p-0 dataTables_wrapper table-responsive">
                    <table id="pagedata_table" className="dataTable display admins_table">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>User Type</th>
                            <th>Status</th>
                            <th className="actions">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            admins.data && admins.data.length > 0 ?
                                admins.data.map((admin, index) =>
                                    <tr key={index}>
                                        <td>
                                            {admin.username}
                                        </td>
                                        <td>
                                            {admin.email}
                                        </td>
                                        <td>
                                            {admin.userType}
                                        </td>
                                        <td>
                                            <span className={`tblbdg ${admin.status ? 'active' : 'inactive'}`}>
                                                {admin.status ? "Active" : "In-Active"}
                                            </span>
                                        </td>
                                        <td className="actions">
                                            <div className="actn_btngrp display-flex">
                                                <button className={"btn btn-ico"}
                                                        type={"button"}
                                                        onClick={() => this.deleteAdmin(admin, TYPE_ADMIN)}>
                                                    <span className="ico delete"/>
                                                </button>
                                                <button className={"btn btn-ico"}
                                                        type={"button"}
                                                        onClick={() => this.activateDeactivateAdmin(admin, TYPE_ADMIN)}>
                                                    <span className={`ico ${admin.status ? "cross" : "tick"}`}/>
                                                </button>
                                                {
                                                    !admin.is_tutor &&
                                                    <button className={"btn btn-txt btn-default"}
                                                            type={"button"}
                                                            onClick={() => addUserRole(admin, "Tutor")}>
                                                        <span className="txt">Add as tutor</span>
                                                    </button>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                )
                                :
                                <tr>
                                    <td colSpan="6">No Record Found</td>
                                </tr>
                        }
                        </tbody>
                    </table>
                </div>
                <PaginationFooterList getPageData={(data) => this.getAdmins(data)}
                                      pageNo={pageNo}
                                      totalRecords={admins?.count}
                                      limit={limit}/>
            </>
        )

    }
}

const PaginationFooterList = (props) =>{
    const { totalRecords } = props;
    if(totalRecords > 0){
        return(<PaginationFooter {...props} />)
    }
    return null

}

export { AdminUserList };
