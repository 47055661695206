import * as dbUtil from "../../api/dbUtil";
import {API} from '../../api';
import {
    RECEIVE_USER_ACT_TEST_PROGRESS,
    RECEIVE_USER_ACT_TEST_PROGRESSES, RECEIVE_USER_ACT_TEST_SCORE, RECEIVE_USER_ALL_ACT_TEST_SCORES,
    RESET_USER_ACT_TEST_PROGRESS
} from "../constant";
import { checkSessionExpired } from "..";

const receiveUserActTestProgress = (data) => ({type: RECEIVE_USER_ACT_TEST_PROGRESS, data});
const userActTestProgress = (data) => ({type: RECEIVE_USER_ACT_TEST_PROGRESS, data})
const userActTestProgresses = (data) => ({type: RECEIVE_USER_ACT_TEST_PROGRESSES, data})
const receiveSubmitActTest = (data) => ({type: RECEIVE_USER_ACT_TEST_PROGRESS, data})
const receiveResetUserActTestProgress = (data) => ({type: RESET_USER_ACT_TEST_PROGRESS, data})
const receiveUserActTestScore = (data) => ({type: RECEIVE_USER_ACT_TEST_SCORE, data})
const receiveUserActAllTestScores = (data) => ({type: RECEIVE_USER_ALL_ACT_TEST_SCORES, data})

export const saveUserActTestProgress = (attributes) => {
    return (dispatch) => {
        return dbUtil.create('user_act_test_progresses', attributes).then(({data}) => {
            dispatch(receiveUserActTestProgress(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getUserActTestProgress = (attributes) => {
    return (dispatch) => {
        return dbUtil.fetchMany('user_act_test_progresses/get_progress', attributes).then(({data}) => {
            dispatch(userActTestProgress(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getUserAllActsTestProgress = (attributes) => {
    return (dispatch) => {
        return dbUtil.fetchMany('user_act_test_progresses/get_progresses', attributes).then(({data}) => {
            dispatch(userActTestProgresses(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const submitActTest = (attributes) => {
    return (dispatch) => {
        return dbUtil.create('user_act_test_progresses/submit', attributes).then(({data}) => {
            dispatch(receiveSubmitActTest(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const resetUserActTestProgress = (params) => {
    return (dispatch) => {
        return API.delete('/user_act_test_progresses/reset_progress', {params}).then(({data}) => {
            dispatch(receiveResetUserActTestProgress(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}
// ------------- End User All Act Test Progress Actions -------------

// ------------- Start User All Act Test Progress Score Actions -------------
export const getUserActTestScores = (attributes) => {
    return (dispatch) => {
        return dbUtil.fetchMany('user_act_test_progresses/get_act_scores', attributes).then(({data}) => {
            dispatch(receiveUserActTestScore(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getUserAllActTestScores = (attributes) => {
    return (dispatch) => {
        return dbUtil.fetchMany('user_act_test_progresses/get_all_act_scores', attributes).then(({data}) => {
            dispatch(receiveUserActAllTestScores(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}
// ------------- End User All Act Test Progress Score Actions -------------
