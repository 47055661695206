import React, {Component} from 'react';
import {MultipleProblemSetSelection} from "../helper/problemSet";

class BucketProblemSets extends Component {

    state = {
        selectedProblemSetIds: []
    }

    handleFnc = () => {
        const {fncType, createProblemSetData, bucketProblemSet} = this.props
        if (fncType === 'bucketView') {
            return null;
        }
        const {selectedProblemSetIds} = this.state;
        const selectedProblemSets = bucketProblemSet.filter((problemSet) => selectedProblemSetIds.includes(problemSet._id));
        createProblemSetData(selectedProblemSets);
    }

    selectUnselect = (problemSet, remove = false) => {
        const {fncType} = this.props;
        if (fncType === 'bucketView') {
            return null;
        }
        let selectedProblemSetIds = this.state.selectedProblemSetIds;
        if (remove === true) {
            selectedProblemSetIds = selectedProblemSetIds.filter(item => item !== problemSet._id);
        } else {
            selectedProblemSetIds.push(problemSet._id);
        }
        this.setState({selectedProblemSetIds});
    }

    render() {
        const {bucketProblemSet, fncType, loading} = this.props;
        const {selectedProblemSetIds} = this.state;
        if (fncType === 'bucketView') {
            return (<div className="admin-problemSet-popup">
                <MultipleProblemSetSelection problemSets={bucketProblemSet}
                                             selectedProblemSetIds={selectedProblemSetIds}
                                             selectUnselect={this.selectUnselect}/>
            </div>)
        }

        return (
            <div className="admin-problemSet-popup">
                <div className={"header-title-row"}>
                    {
                        bucketProblemSet?.length > 0 &&
                        <div className={"submit-button-div"}>
                            <button onClick={() => this.handleFnc()}
                                    disabled={selectedProblemSetIds.length === 0 || loading}
                                    className="btn btn-sm btn-primary">
                                Add Problem Sets
                            </button>
                        </div>
                    }
                </div>
                <MultipleProblemSetSelection problemSets={bucketProblemSet}
                                             selectedProblemSetIds={selectedProblemSetIds}
                                             selectUnselect={this.selectUnselect}/>
            </div>
        )
    }
}

export default BucketProblemSets;
