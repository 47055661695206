import {
    CREATE_ASSIGNMENT,
    GET_ASSIGNMENTS,
    UPDATE_ASSIGNMENT,
} from '../constant';
const defaultState = [];

export const assignmentReducer = (state = defaultState, action) => {
    switch (action.type) {
        case CREATE_ASSIGNMENT:
            return action.data;
        case GET_ASSIGNMENTS:
            return action.data;
        case UPDATE_ASSIGNMENT:
            if(action.data?.id){
                return state.map(obj => (action.data?.id === obj.id) ? action.data : obj);
            }
            return state;
        default:
            return state;
    }
};
