import {
    LINK_USER_WITH_TUTOR,
    RECEIVE_ALL_ADMIN,
    RECEIVE_ALL_STUDENT,
    RECEIVE_ALL_STUDENT_LISTS,
    RECEIVE_ALL_TUTOR,
    UPDATE_ADMIN,
    UPDATE_STUDENT,
    UPDATE_TUTOR
} from "../constant";

import * as dbUtil from "./../../api/dbUtil"
import {API} from "../../api";
import { checkSessionExpired, alertPushMessage } from "..";

const receiveAllAdmins = (data) => ({ type: RECEIVE_ALL_ADMIN, data});
const receiveAllTutors = (data) => ({ type: RECEIVE_ALL_TUTOR, data});
const receiveAllStudents = (data) => ({ type: RECEIVE_ALL_STUDENT, data});
const receiveAllStudentList = (data) => ({ type: RECEIVE_ALL_STUDENT_LISTS, data});

const updateAdminUser = (data) => ({ type: UPDATE_ADMIN, data});
const updateTutorUser = (data) => ({ type: UPDATE_TUTOR, data});
const updateStudentUser = (data) => ({ type: UPDATE_STUDENT, data});


// ------------------------------- CREATE NEW RECORD ---------------------------
export const createAdmin = (attributes) => {
    return (dispatch) => {
        return dbUtil.create("users/admins", attributes).then(({ data }) => {
            dispatch(alertPushMessage({message: "New Admin added successfully", type: "success"}));
            return true;
        }).catch((error) => {
            dispatch(checkSessionExpired(error.message));            ;
            return false;
        })
    }
}

export const createStudent = (attributes) => {
    return (dispatch) => {
        return dbUtil.create("users/students", attributes).then(({ data }) => {
            dispatch(alertPushMessage({message: "New Student added successfully", type: "success"}));
            return true;
        }).catch((error) => {
            dispatch(checkSessionExpired(error.message));            ;
            return false;
        })
    }
}

export const createTutor = (attributes) => {
    return (dispatch) => {
        return dbUtil.create("users/tutors", attributes).then(({ data }) => {
            dispatch(alertPushMessage({message: "New Tutor added successfully", type: "success"}));
            return true;
        }).catch((error) => {
            dispatch(checkSessionExpired(error.message));            ;
            return false;
        })
    }
}

// fetch all users - admin, student, tutors
export const getAllAdmins = (params) => {
    return (dispatch) => {
        return dbUtil.fetchMany("users/admins", params).then(({ data }) => {
            dispatch(receiveAllAdmins(data));
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getAllStudents = (params) => {
    return (dispatch) => {
        return dbUtil.fetchMany("users/students", params).then(({ data }) => {
            dispatch(receiveAllStudents(data));
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getAllTutors = (params) => {
    return (dispatch) => {
        return dbUtil.fetchMany("users/tutors", params).then(({ data }) => {
            dispatch(receiveAllTutors(data));
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getAllStudentList = (params) => {
    return (dispatch) => {
        return dbUtil.fetchMany("users/students").then(({ data }) => {
            dispatch(receiveAllStudentList(data));
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

// ---------------------- UPDATE USER DATA -------------------------------------

export const updateAdminStatus = (id, attributes) => {
    return (dispatch) => {
        return dbUtil.update('users/admins', id, attributes).then(({ data }) => {
            dispatch(updateAdminUser(data));
            dispatch(alertPushMessage({message: "Status updated successfully", type: "success"}));
        }).catch((error) => {
            dispatch(checkSessionExpired(error.message));            ;
        })
    }
}

export const updateStudentStatus = (id, attributes) => {
    return (dispatch) => {
        return dbUtil.update('users/students', id, attributes).then(({ data }) => {
            dispatch(updateStudentUser(data));
            dispatch(alertPushMessage({message: "Status updated successfully", type: "success"}));
        }).catch((error) => {
            dispatch(checkSessionExpired(error.message));            ;
        })
    }
}

export const updateTutorStatus = (id, attributes) => {
    return (dispatch) => {
        return dbUtil.update('users/tutors', id, attributes).then(({ data }) => {
            dispatch(updateTutorUser(data));
            dispatch(alertPushMessage({message: "Status updated successfully", type: "success"}));
        }).catch((error) => {
            dispatch(checkSessionExpired(error.message));            ;
        })
    }
}

//--------------------- DESTROY USER -----------------------

export const destroyAdmin = (id) => {
    return (dispatch) => {
        return dbUtil.destroy('users/admins', id).then(({ data }) => {
            dispatch(alertPushMessage({message: data.message, type: "success"}));
            return true;
        }).catch((error) => {
            dispatch(checkSessionExpired(error.message));            ;
            return false;
        })
    }
}

export const destroyStudent = (id) => {
    return (dispatch) => {
        return dbUtil.destroy('users/students', id).then(({ data }) => {
            dispatch(alertPushMessage({message: data.message, type: "success"}));
            return true;
        }).catch((error) => {
            dispatch(checkSessionExpired(error.message));            ;
            return false;
        })
    }
}

export const destroyTutor = (id) => {
    return (dispatch) => {
        return dbUtil.destroy('users/tutors', id).then(({ data }) => {
            dispatch(alertPushMessage({message: data.message, type: "success"}));
            return true;
        }).catch((error) => {
            dispatch(checkSessionExpired(error.message));            ;
            return false;
        })
    }
}

const receiveLinkUsers = (data) => {
    return ({
        type: LINK_USER_WITH_TUTOR,
        data
    })
}

export const linkUsers = (attributes) => {
    return (dispatch) => {
        return API.put('/users/link_users', attributes).then(({ data }) => {
            dispatch(receiveLinkUsers(data))
            dispatch(alertPushMessage({message: "Student list updated",type: "success"}));
            return true;
        }).catch((error) => {
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const addRole = (attributes) => {
    return (dispatch) => {
        return API.put('/users/add_role', attributes).then(({ data }) => {
            dispatch(alertPushMessage({message: "Assign role Successfully", type: "success"}));
            dispatch(updateAdminUser(data));
            return true;
        }).catch((error) => {
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const removeRole = (attributes) => {
    return (dispatch) => {
        return API.put('/users/remove_role', attributes).then(({ data }) => {
            dispatch(alertPushMessage({message: "Unassign role Successfully", type: "success"}));
            dispatch(updateAdminUser(data));
            return true;
        }).catch((error) => {
            dispatch(checkSessionExpired(error.message));            ;
            return false;
        });
    }
}
