import { RECEIVE_SAT_PROBLEM_SETS, RESET_SAT_PROBLEM_SETS } from "../constant";

const defaultState = {};
export const satProblemSetReducer = (state = defaultState, action) => {
    switch (action.type) {
        case RECEIVE_SAT_PROBLEM_SETS:
            return { ...state, [action.data.sat_id]: action.data.data };
        case RESET_SAT_PROBLEM_SETS:
            return defaultState;
        default:
            return state;
    }
}
