import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SVG from '../../../../SVG';
import "./AdminSubtopic.scss";
import * as subtopicActions from '../../../../../modules/subTopic/action';
import * as subSubTopicActions from '../../../../../modules/subSubTopic/action';
import AdminSubSubtopics from './AdminSubSubtopics'
import * as dialogActions from '../../../../../modules/dialog/action';
import {Loader, SetScrollPosition} from "../../../../helper/commonMethods";
import AddNewTopic from "../../common/AddNewTopic";
import EditTitle from "../../common/EditTitle";
import * as alertActions from "../../../../../modules/alert/action";
import {PublishUnPublishSequenceButton, PublishUnPublishTopicButton} from "../../common/CommonMethod";

class AdminSubtopic extends Component {
    state = {
        showEdit: false,
        show: false,
        showCreate: false,
        loading: false,
    }

    async componentDidMount() {
        const { subtopic, satTopicModal } = this.props;
        const queryString = new URLSearchParams(this.props.location.search);
        if (!satTopicModal && queryString && queryString.get('subtopic') && (subtopic.id === queryString.get('subtopic'))) {
            await this.getSubSubTopic();
            SetScrollPosition(subtopic.id);
        }
    }

    toggle = (el) => {
        this.setState({ [el]: !this.state[el] })
    }

    goToSubTopic = () => {
        const { subtopic } = this.props;
        this.props.history.push('/admin/' + subtopic.id);
    }

    deleteSubTopic = () => {
        const { deleteSubtopic, subtopic } = this.props;
        let res = window.confirm("Are you sure you want to delete this subtopic?");
        if (res) {
            deleteSubtopic(subtopic.id);
        } else {
            return null;
        }
    }

    getSubSubTopic = async () => {
        const { getSubSubtopics, subtopic, subsubtopics } = this.props;
        const { show } = this.state;
        this.setState({loading: true});
        if (!show && !subsubtopics[subtopic.id]) {
            this.setState({ show: true });
            await getSubSubtopics({ subtopic_id: subtopic.id })
            this.setState({ loading: false });
        } else{
            this.setState({ show: !show, loading: false });
        }
    }

    pubishUnpublishSubtopic() {
        const { subtopic, publishOrUnpublishSubtopic } = this.props;
        if (subtopic.id) {
            publishOrUnpublishSubtopic({ id: subtopic.id, isPublish: !subtopic.isPublish });
        } else {
            alert("Error: No subtopic id selected!!")
        }
    }

    sequencePubishUnpublishSubtopic() {
        const { subtopic, pubishOrUnpublishSubTopicSequence } = this.props;
        if (subtopic.id) {
            pubishOrUnpublishSubTopicSequence({ id: subtopic.id, isSequencePublish: !subtopic.isSequencePublish });
        } else {
            alert("Error: No subtopic id selected!!")
        }
    }

    addSubSubTopics = async (data) => {
        const { createSubSubtopic, subtopic } = this.props;
        await createSubSubtopic({ subtopic_id: subtopic.id, ...data })
    }

    flashMessage = (payload, timeout = 5000) => {
        const {alertPush, alertDelete} = this.props;
        alertPush(payload);
        setTimeout(() => alertDelete(),timeout);
    }

    render() {
        const {
            subtopic, subsubtopics, updateSubSubTopicPositions, satTopicModal, satTopic, updateAfterPublishUnpublish,
            mathTopic, actTopic, updateSubtopic, account
        } = this.props;
        const { show, showEdit, loading } = this.state;

        return (
            <div className={`home-topic p-3 ${show ? 'open' : ''}`}>
                <div className="subtopicHeader" id={`T${subtopic.id}`}>
                    {!showEdit &&
                        <>
                            <span style={{cursor: 'pointer'}} onClick={this.getSubSubTopic} disabled={loading}>
                                {subtopic.title}
                            </span>
                            {
                                satTopicModal === false &&
                                <>
                                    <span className="sequenceBtn">
                                        <button className="btn btn-primary" onClick={this.goToSubTopic} disabled={loading}>Sequence</button>
                                    </span>
                                    <span className="publishBtn mr-auto">
                                        <PublishUnPublishSequenceButton account={account}
                                                                        onClick={() => this.sequencePubishUnpublishSubtopic()}
                                                                        className={"ml-2"}
                                                                        disabled={loading}
                                                                        publish={subtopic?.isSequencePublish}/>
                                    </span>
                                </>
                            }
                            <span className="icon align-right" onClick={this.getSubSubTopic} style={{cursor: "pointer"}} disabled={loading}>
                                Arrow icon
                            </span>
                        </>
                    }
                    <EditTitle onUpdate={updateSubtopic}
                               show={showEdit}
                               topicType={'SubTopic'}
                               flashMessage={this.flashMessage}
                               topic={subtopic}
                               disabled={loading}
                               toggleForm={() => this.toggle('showEdit')} />
                    {
                        satTopicModal === false && !showEdit &&
                            <div className="options my-2">
                                <SVG onClick={() => this.toggle('showEdit')} name="pencil" disabled={loading}/>
                                <SVG onClick={this.deleteSubTopic} name="trash" disabled={loading}/>
                                <PublishUnPublishTopicButton topic={subtopic}
                                                             account={account}
                                                             disabled={loading}
                                                             onClick={() => this.pubishUnpublishSubtopic()}/>
                            </div>
                    }
                </div>
                <div className="subtopic-content">
                    <Loader loading={loading}/>
                    <AdminSubSubtopics show={show}
                                       subsubtopics={subsubtopics[subtopic.id]}
                                       updateSubSubTopicPositions={updateSubSubTopicPositions}
                                       satTopicModal={satTopicModal}
                                       satTopic={satTopic}
                                       updateAfterPublishUnpublish={updateAfterPublishUnpublish}
                                       mathTopic={mathTopic}
                                       actTopic={actTopic}/>

                    {(satTopicModal === false && show) &&
                        <AddNewTopic createTopic={this.addSubSubTopics}
                                     topicType={"Sub-Sub topic"}
                                     disabled={loading}
                                     alignButtonLeft={true} />}
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return ({
        account: state.account,
        subsubtopics: state.subsubtopics,
    })
}
const mapDispatchToProps = (dispatch) => {
    return ({
        updateSubtopic: (id, attributes) => dispatch(subtopicActions.updateSubTopics(id, attributes)),
        deleteSubtopic: (id) => dispatch(subtopicActions.deleteSubTopics(id)),
        createSubSubtopic: (params) => dispatch(subSubTopicActions.createSubSubTopic(params)),
        getSubSubtopics: (params) => dispatch(subSubTopicActions.getAllSubSubTopics(params)),
        updateSubSubTopicPositions: (subsubtopics) => dispatch(subSubTopicActions.updateSubSubTopicPositions(subsubtopics)),
        publishOrUnpublishSubtopic: (data) => dispatch(subtopicActions.publishSubTopic(data)),
        pubishOrUnpublishSubTopicSequence: (data) => dispatch(subtopicActions.publishSubTopicSequence(data)),
        openDialog: (config, options) => dispatch(dialogActions.open(config, options)),
        alertPush: (payload) => dispatch(alertActions.alertPush(payload)),
        alertDelete: () => dispatch(alertActions.alertDelete())
    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminSubtopic));

