import { API } from '../../api';
import {GET_SETTING} from "../constant";
import { checkSessionExpired } from "..";

const receiveSetting = (data) =>  ({ type: GET_SETTING, data });

export const getSetting = (params) => {
  return (dispatch) => {
    return API.get('settings/get_setting', params).then(({ data }) => {
      dispatch(receiveSetting(data));
      return data;
    }).catch((error)=>{
      dispatch(checkSessionExpired(error.message));
      return false;
    });
  }
}

export const setSetting = (params) => {
  return (dispatch) => {
    return API.put("/settings/set_setting", params).then(({ data }) => {
      dispatch(receiveSetting(data));
      return data;
    }).catch((error)=>{
      dispatch(checkSessionExpired(error.message));
      return false;
    });
  }
}