import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { validSelect } from '../../../../utilities/selectUtil';
import * as questionActions from '../../../../modules/question/action';
import * as animationActions from '../../../../modules/animation/action';
import * as problemSetQuestionActions from '../../../../modules/problemSetQuestion/action';
import * as accountActions from '../../../../modules/account/action'
import ViewQuestions from './ViewQuestions'
import './HomeQuestions.scss'
import _ from 'lodash';
import {getUserProgressAndProgressQuestion, Loader} from "../../../helper/commonMethods";
import * as userProgressActions from "../../../../modules/userProgress/action";

const mapStateToProps = state => {
    return ({
        questions: state.questions,
        problemset_questions: state.problemset_questions,
        account: state.account
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        startAnimation: () => dispatch(animationActions.start()),
        endAnimation: () => dispatch(animationActions.end()),
        resetQuestionData: () => dispatch(questionActions.resetQuestionData()),
        getQuestions: (params) => dispatch(questionActions.getAllQuestions(params)),
        getProblemSetQuestions: (params) => dispatch(problemSetQuestionActions.getAllProblemSetQuestions(params)),
        resetProblemSetQuestions: () => dispatch(problemSetQuestionActions.resetProblemSetQuestions()),
        getUserProgress: (params) => dispatch(userProgressActions.getUserProgress(params)),
        getUserProblemSetProgress: (params) => dispatch(userProgressActions.getUserProblemSetProgress(params)),
    })
}

class HomeQuestions extends Component {

    constructor(props) {
        super(props)
        this.state = {
            questions: [],
            mount: false
        }
    }

    async componentDidMount() {
        const {
            getQuestions, topicId, topicType, startAnimation, endAnimation, getUserProgress, getProblemSetQuestions,
            getUserProblemSetProgress, resetQuestionData, resetProblemSetQuestions
        } = this.props;
        await startAnimation();
        await resetQuestionData();
        await getUserProgress({ reference_id: topicId, reference_type: topicType });
        let params;
        if (topicType ==="HomeActQuestionTopic"){
            params = { act_question_id: topicId }
        } else {
            params = { sat_question_id: topicId }
        }
        await getQuestions({ params: params, order_by: { position: 'asc' } });
        if (_.has(this.props.account, 'progress.next_problemset_id') && this.props.account.progress.next_problemset_id) {
            const problem_set_id = this.props.account.progress.next_problemset_id;
            await getUserProblemSetProgress({ reference_id: problem_set_id, reference_type: 'HomeProblemSetQuestions'})
            await resetProblemSetQuestions();
            await getProblemSetQuestions({ problem_set_id: problem_set_id });
        }
        await endAnimation()
    }

    componentDidUpdate(props) {
        if (this.props.questions !== props.questions) {
            this.setState({ questions: this.props.questions })
        }
    }

    render() {
        const { topicId, topicType, section, problemset_questions, getProblemSetQuestions, account, takeBack } = this.props;
        const { questions } = this.state
        if (!validSelect(topicId)) return null;
        const { userProgress, userProgressQuestion } = getUserProgressAndProgressQuestion({account, questions});
        return (
            <div className={`home-questions`}>
                {questions?.length > 0 &&
                    <ViewQuestions topicId={topicId}
                                   topicType={topicType}
                                   section={section}
                                   questions={questions}
                                   userProgress={userProgress}
                                   userProgressQuestion={userProgressQuestion}
                                   problemset_questions={problemset_questions}
                                   firstQuestion={userProgress && userProgressQuestion ? userProgressQuestion : questions ? questions[0] : ''}
                                   firstProblemSetQuestion={problemset_questions ? problemset_questions[0] : ''}
                                   getProblemSetQuestions={getProblemSetQuestions}
                                   takeBack={takeBack} />
                }
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeQuestions));
