import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import SectionQuestions from './SectionQuestions';
import * as satActions from '../../modules/sat/action';
import * as satTopicActions from '../../modules/satTopic/action';
import * as satQuestionActions from '../../modules/satQuestion/action';
import './SAT.scss'

const mapDispatchToProps = dispatch => {
    return({
        getSAT: (id) => dispatch(satActions.getTestSat(id)),
        getSatTopic: (id) => dispatch(satTopicActions.getSatTopic(id)),
        getSatQuestions: (params) => dispatch(satQuestionActions.getAllSatQuestion(params)),
    })
}

const mapStateToProps = state => {
    return({
        readings: state.satQuestions.filter(x => x.type === 'Reading'),
        writings: state.satQuestions.filter(x => x.type === 'Writing'),
        mathCalcs: state.satQuestions.filter(x => x.type === 'MathCalc'),
        mathNoCalcs: state.satQuestions.filter(x => x.type === 'MathNoCalc'),
    })
}

class SAT extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sat: {},
            ready: false,
        }

        this.prepareRoute = this.prepareRoute.bind(this);
    }
    async componentDidMount() {
        const {match: {params: {satId}}, getSAT, getSatQuestions} = this.props;
        await getSatQuestions({params: {sat_id: satId}});
        await getSAT(satId).then(sat => this.setState({sat, ready: true}));
    }

    prepareRoute(id) {
        // Should convert this logic similar to main Topics as not to use underscore id
        const {getSatTopic} = this.props;
        getSatTopic(id).then((topic) => {
            const questions = topic.questions;
            this.props.history.push(`/topic/${id}/question/${questions[0]._id}`)
        })
    }

    render() {
        const {sat, ready} = this.state;
        const {mathCalcs, mathNoCalcs, readings, writings} = this.props;
        const sectionQuestions = [
            readings.filter(x => x.sat_id === sat.id),
            writings.filter(x => x.sat_id === sat.id),
            mathNoCalcs.filter(x => x.sat_id === sat.id),
            mathCalcs.filter(x => x.sat_id === sat.id)
        ];
        const sectionHeaders = ["Reading", "Writing and Language", "Math - No Calculator", "Math - Calculator"]
        if(!ready) return null
        return (
            <div className="sat-page">
                <Link to="/home">Home</Link>
                <h1>{sat.title}</h1>
                <ul className="questions">
                    {sectionQuestions.map((questions, idx) => {
                        return (
                            <li key={"questions" + idx}>
                                <SectionQuestions
                                    questions={questions}
                                    header={sectionHeaders[idx]}
                                    prepareRoute={this.prepareRoute}/>
                            </li>
                        )
                    })}
                </ul>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SAT);
