import {
    CLEAR_ACTS_USER_DATA,
    CLEAR_CURRENT_QUESTION,
    CLEAR_PROBLEM_SET_PROGRESS,
    CLEAR_SATS_USER_DATA,
    SET_ACTS_USER_DATA,
    SET_ASSIGNMENT_DEFAULT_DATA,
    SET_ASSIGNMENT_OPENED_VIEW,
    SET_COMPLETED_ASSIGNMENT_PAGE,
    SET_CURRENT_ASSIGNMENT_PAGE,
    SET_CURRENT_QUESTION,
    SET_PROBLEM_SET_PROGRESS,
    SET_SATS_USER_DATA
} from "../constant";
import { combineReducers } from 'redux';

const assignmentDefaultState = {currentPage: 1, completedPage: 1, openedView: "current"};
const assignmentDataReducer = (state = assignmentDefaultState, action) => {
    switch (action.type) {
        case SET_COMPLETED_ASSIGNMENT_PAGE:
            return {...state, completedPage: action.data};
        case SET_CURRENT_ASSIGNMENT_PAGE:
            return {...state, currentPage: action.data};
        case SET_ASSIGNMENT_OPENED_VIEW:
            return {...state, openedView: action.data};
        case SET_ASSIGNMENT_DEFAULT_DATA:
            return assignmentDefaultState;
        default:
            return state;
    }
}

const progressBarReducer = (state = null, action) => {
    switch (action.type) {
        case SET_PROBLEM_SET_PROGRESS:
            return action.data;
        case CLEAR_PROBLEM_SET_PROGRESS:
            return null;
        default:
            return state;
    }
}

const currentQuestionReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_CURRENT_QUESTION:
            return action.data;
        case CLEAR_CURRENT_QUESTION:
            return {};
        default:
            return state;
    }
}

const satsUserReducer = (state = [], action) => {
    switch (action.type) {
        case SET_SATS_USER_DATA:
            return action.data;
        case CLEAR_SATS_USER_DATA:
            return [];
        default:
            return state;
    }
}

const actsUserReducer = (state = [], action) => {
    switch (action.type) {
        case SET_ACTS_USER_DATA:
            return action.data;
        case CLEAR_ACTS_USER_DATA:
            return [];
        default:
            return state;
    }
}

export const storeTemporaryReducer = combineReducers({
    assignmentData: assignmentDataReducer,
    currentQuestion: currentQuestionReducer,
    progressData: progressBarReducer,
    satsUser: satsUserReducer,
    actsUser: actsUserReducer
});
