import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AdminQuestions from '../question/AdminQuestions';
import * as subtopicActions from '../../../../../modules/subTopic/action';
import * as subSubTopicActions from '../../../../../modules/subSubTopic/action';
import * as testActions from '../../../../../modules/test/action';
import './AdminSubtopicMain.scss';
import SVG from '../../../../SVG';

class AdminSubtopicMain extends Component {
    state = {
        title: "",
        topicTitle: "",
        showTopics: false,
        testId: ""
    }
    async componentDidMount() {
        window.scrollTo(0, 0);
        const { match: { params: { subtopicId } }, getSubtopic, getTest, getSubSubtopics } = this.props;
        await getSubSubtopics({ subtopic_id: subtopicId })
        let test,testId, error404 = false;
        const subtopic = await getSubtopic(subtopicId).then(res => {
            testId = res?.test_id ? res.test_id : subtopicId;
            return res
        }).catch(err => {
            testId = subtopicId;
            return err
        });
        test = await getTest(testId).catch((error) =>{
            error404 = error.response?.status === 404;
        });
        if(error404){
            return this.props.history.push('/admin/not_found')
        }
        this.setState({ subtopicTitle: subtopic?.title, topic: test, topicTitle: test.title, testId })
    }

    takeBack = () => {
        const { match: { params: { subtopicId } } } = this.props
        const { topic } = this.state
        let url = ''
        if (topic) {
            if (topic.type === "Topic") {
                url = "/admin/math-topics"
            } else if (topic.type === "ReadingTopic") {
                url = "/admin/reading-topics"
            } else if (topic.type === "GrammarTopic") {
                url = "/admin/grammar-topics"
            }
        }
        const queryString = '?topic=' + ((topic && topic.id) || '') + '&subtopic=' + (subtopicId || '')
        this.props.history.push(url + queryString)
    }

    toggleForm = () => {
        const { showTopics } = this.state
        this.setState({ showTopics: !showTopics })
    }
    render() {
        const { match: { params: { subtopicId } }, subsubtopics, subtopics } = this.props;
        const { subtopicTitle, topic, topicTitle, showTopics, testId } = this.state;
        let topicType = null;
        if (topic) {
            topicType = topic.type;
        }
        // alert(topicType)
        return (
            <div className="row" >
                <div className="content-wrapper-before gradient-45deg-indigo-purple" />
                <div className="col s12 contentCenter_wrap">
                    <div className="container">
                        <div className="dash_top_section" />
                        <div className="row vertical-modern-dashboard">
                            <div className="col-12">
                                <div className="card animate fadeLeft">
                                    <div className="question_sec card-content">
                                        <div className="dashtitle_sec d-flex flex-wrap align-items-center w-100">
                                            <button onClick={() => this.takeBack()} className="btn btn-default">
                                                <i className="ico backicon" />
                                                <span className="btntxt">Back</span>
                                            </button>
                                        </div>
                                        <div className="quesHeader d-flex flex-wrap align-items-center text-center w-100">
                                            <h1 className=" quesTitle d-inline-block position-relative">
                                                {topicTitle} - {subtopicTitle}
                                            </h1>
											<div className="selTopics_wrap">
                                                <SVG name="hamburger" className="d-inline-flex hamburger" onClick={() => this.toggleForm()} />
                                                {
                                                    showTopics &&
                                                    <div className="sel-topics-list">
                                                        {
                                                            subtopics?.[testId]?.map(item => (
                                                                <>
                                                                    <h2> {item.title} </h2>
                                                                    <ul>
                                                                        {
                                                                            subsubtopics[item.id]?.map(subItems => (
                                                                                <li> {subItems.title} </li>
                                                                            ))
                                                                        }
                                                                    </ul>
                                                                </>
                                                            ))
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        {topicType && <AdminQuestions
                                            topicType={topicType}
                                            topicId={subtopicId}
                                            section="subTopic"
                                            subsubtopics={subsubtopics}
                                        />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        );
    }
}

const mapDispatchToProps = dispatch => {
    return ({
        getSubtopic: (id) => dispatch(subtopicActions.getSubTopic(id)),
        getSubSubtopics: (params) => dispatch(subSubTopicActions.getAllSubSubTopics(params)),
        getTest: (id) => dispatch(testActions.getTest(id))
    })
}

const mapStateToProps = ({ subsubtopics, subtopics }) => ({ subsubtopics, subtopics })

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminSubtopicMain));
