import React, {Component} from 'react';
import SortableQuestions from './SortableQuestions';
import AddQuestion from './AddQuestion';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import SVG from '../../../../SVG';
import 'katex/dist/katex.min.css'
import {validSelect} from '../../../../../utilities/selectUtil';
import * as questionActions from '../../../../../modules/question/action';
import * as answerActions from '../../../../../modules/answer/action';
import * as animationActions from '../../../../../modules/animation/action';
import './AdminQuestions.scss'
import arrayMove from 'array-move';
import * as alertActions from "../../../../../modules/alert/action";
import {Loader} from "../../../../helper/commonMethods";

const mapStateToProps = state => {
    return ({
        questions: state.questions,
        answers: state.answers,
        loading: state.animation,
        problemset_questions: state.problemset_questions
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        startAnimation: () => dispatch(animationActions.start()),
        endAnimation: () => dispatch(animationActions.end()),

        getSubTopicQuestions: (params) => dispatch(questionActions.getAll('question_subsubtopics', params)),
        createSubtopicQuestion: (attributes) => dispatch(questionActions.create('question_subsubtopics', attributes)),
        createMultipleSubtopicQuestion: (attributes) => dispatch(questionActions.createMultiple('question_subsubtopics/create_multiple', attributes)),
        updateQuestionSubsubtopicPosition: (questions, topicType) => dispatch(questionActions.updateQuestionPositions(questions, topicType)),

        createAnswer: (params) => dispatch(answerActions.createAnswer(params)),
        createMultipleAnswer: (params) => dispatch(answerActions.createMultipleAnswer(params)),

        getQuestion: (id) => dispatch(questionActions.getQuestion(id)),
        getQuestions: (params) => dispatch(questionActions.getAllQuestions(params)),
        createQuestion: (attributes) => dispatch(questionActions.createQuestion(attributes)),
        createMultipleQuestion: (attributes) => dispatch(questionActions.createMultipleQuestion(attributes)),
        updateQuestionPositions: (questions, topicType) => dispatch(questionActions.updateQuestionPositions(questions, topicType)),

        alertPush: (payload) => dispatch(alertActions.alertPush(payload)),
        alertDelete: () => dispatch(alertActions.alertDelete())
    })
}

class AdminQuestions extends Component {
    state = {
        question: "",
        questionId: "",
        formActive: false,
        fromQuestion: false,
        fromQuestionPosition: "",
        newQuestion: {},
    }

    async componentDidMount() {
        const { topicId, startAnimation, endAnimation, topicType } = this.props;
        if (validSelect(topicId) && topicType) {
            startAnimation();
            await this.loadQuestions(true);
            endAnimation();
        }
    }

    async componentDidUpdate(prevProps) {
        const { startAnimation, endAnimation } = this.props;
        if (this.props.topicId !== prevProps.topicId && (validSelect(this.props.topicId) || this.props.topicType !== prevProps.topicType)) {
            window.scrollTo(0,0);
            await startAnimation();
            await this.loadQuestions();
            await endAnimation();
        }

    }

    loadQuestions = async (sortQuestions = false) =>{
        const { topicId, getQuestions, getSubTopicQuestions, topicType, satType, actType } = this.props;
        if (topicType !== 'subTopic') {
            let idParams = ''
            if(['SatTopic','ActType'].includes(topicType)){
                idParams = 'test_id'
            }else if(topicType === 'SatQuestionTopic'){
                idParams = 'sat_question_id'
            }else if(topicType === 'ActQuestionTopic'){
                idParams = 'act_question_id'
            } else {
                idParams=  "subtopic_id"
            }
            if (['AdminSatMockTest','AdminSatRealTest'].includes(topicType)) {
                await getQuestions({ params: { sat_id: topicId, sat_type: satType, question_type: topicType }, order_by: { position: 'asc' } });
            } else if (['AdminActMockTest','AdminActRealTest'].includes(topicType)) {
                await getQuestions({ params: { act_id: topicId, act_type: actType, question_type: topicType }, order_by: { position: 'asc' } });
            } else {
                await getQuestions({ params: { [idParams]: topicId }, order_by: { position: 'asc' } });
            }
        } else {
            await getSubTopicQuestions({ params: { subsubtopic_id: topicId }, order_by: { position: 'asc' } });
        }

        if(sortQuestions){
            const { questions } =  this.props;
            const questionPositions = questions.map((d,index) => index === d.position);
            if(questionPositions.includes(false)){
                await this.updateQuestionPosition();
            }
        }
    }

    // Function for Create Multiple Question from Bucket

    createMultipleBucketQuestion = async (newQuestions, topic, answers, invokedQuestion) => {
        const {
            createMultipleQuestion, createMultipleAnswer, startAnimation, endAnimation, questions,
            satType, section, actType, topicType, createMultipleSubtopicQuestion
        } = this.props;
        const newQuestionsCount = newQuestions?.length ?? 0;

        if (["AdminSatMockTest","AdminSatRealTest"].includes(section) && (questions.length + newQuestionsCount) >= 20 && satType && satType === "MathNoCalc") {
            alert("You can't create more than 20 questions in this test!");
        } else if (["AdminSatMockTest","AdminSatRealTest"].includes(section) && (questions.length + newQuestionsCount) >= 38 && satType && satType === "MathCalc") {
            alert("You can't create more than 38 questions in this test!")
        }else if (["AdminActMockTest","AdminActRealTest"].includes(section) && (questions.length + newQuestionsCount) >= 60 && actType && actType === "Mathematics") {
            alert("You can't create more than 60 questions in this test!")
        } else {
            startAnimation();
            let questionPosition = invokedQuestion ? invokedQuestion.position : questions.length - 1;
            const questionParams =  newQuestions.map((question, index)=> this.getNewQuestionParams(question,topic,questionPosition + index));
            let newQuestionsResponse = [];
            if (topicType === 'subTopic') {
                newQuestionsResponse = await createMultipleSubtopicQuestion({questions: questionParams});
            } else if (['SatQuestionTopic','ActQuestionTopic', 'AdminSatMockTest', 'AdminSatRealTest','AdminActMockTest', 'AdminActRealTest'].includes(topicType)) {
                newQuestionsResponse = await createMultipleQuestion({questions: questionParams});
            } else {
                newQuestionsResponse = await createMultipleQuestion({questions: questionParams});
            }
            let questionIdsHash = {};
            newQuestionsResponse.map(d => questionIdsHash[d.previous_id] = d.id);
            const answerParams = answers?.map((answer)=> this.getNewAnswerParams(answer,questionIdsHash[answer.question_id]));
            if(answerParams?.length > 0){
                await createMultipleAnswer({answers: answerParams})
            }

            // set the position as per the specific question from where new question button is clicked
            if (invokedQuestion) {
                await this.setPositionAfterCreateMultiple(newQuestions);
            }
            endAnimation();

            this.flashMessage({message: `Questions Created From Bucket`, type: 'success'});
        }
    }

    getNewQuestionParams = (question, topic, questionPosition) =>{
        const { satType, section, actType, topicType } = this.props;
        // When new attributes are added to the model, they must be updated here
        let questionData = {
            previous_id: question.id,
            question: question.question,
            subtopic_id: topic,
            rank: question.rank,
            position: questionPosition + 1,
            question_exp: question.question_exp,
            help: question.help,
            help_exp: question.help_exp,
            is_calc: question.is_calc,
            is_math: question.is_math,
            is_free_response: question.is_free_response,
            exp_position_before_answer: question.exp_position_before_answer,
            question_type: section
        };

        if (topicType === 'subTopic') {
            questionData.subsubtopic_id = questionData.subtopic_id;
            delete questionData.subtopic_id;
        } else if (topicType === 'SatQuestionTopic') {
            questionData.subtopic_id = null;
            questionData.act_question_id = null;
            questionData.sat_question_id = topic;
        } else if (topicType === 'ActQuestionTopic') {
            questionData.subtopic_id = null
            questionData.sat_question_id = null
            questionData.act_question_id = topic
        } else if (topicType === 'AdminSatMockTest' || topicType === 'AdminSatRealTest') {
            delete questionData.subtopic_id
            questionData.sat_id = topic
            questionData.sat_type = satType
        } else if (topicType === 'AdminActMockTest' || topicType === 'AdminActRealTest') {
            delete questionData.subtopic_id
            questionData.act_id = topic
            questionData.act_type = actType
        }

        return questionData;
    }

    getNewAnswerParams = (ans, newQuestionId) =>{
        const {answer, next_question, explanation, correct, position} = ans;
        let answerData = {};
        if (ans.is_free_response) {
            answerData = {
                answer: 'NA', question_id: newQuestionId, explanation: 'NA', correct: true, position,
                is_free_response: true, free_response_answer: ans.free_response_answer, symbol: ans.symbol,
                is_exact_match: ans.is_exact_match, symbol_position_left: ans.symbol_position_left
            }
        } else {
            answerData = { answer, question_id: newQuestionId, next_question, explanation, correct, position,
                is_free_response: false, free_response_answer: '', symbol: '' };
        }
        return answerData;
    }

    // Function for question duplication & Bucket insertion
    createQuestionFrom = async (question, topic, answers, invokedQuestion, isDuplicate = false) => {
        const { createQuestion, startAnimation, endAnimation, createMultipleAnswer, questions, satType, section, actType, topicType } = this.props;

        if (["AdminSatRealTest","AdminSatMockTest"].includes(section) && questions.length === 20 && satType && satType === "MathNoCalc") {
            alert("You can't create more than 20 questions in this test!")
        } else if (["AdminSatRealTest","AdminSatMockTest"].includes(section) && questions.length === 38 && satType && satType === "MathCalc") {
            alert("You can't create more than 38 questions in this test!")
        }else if (["AdminActMockTest","AdminActRealTest"].includes(section) && questions.length >= 60 && actType && actType === "Mathematics") {
                alert("You can't create more than 60 questions in this test!")
        } else {
            startAnimation();

            // Adding from the bucket using the main Create Question button
            // Duplication and adding from the bucket under a specific question
            let questionPosition = invokedQuestion ? invokedQuestion.position : questions.length - 1;

            // When new attributes are added to the model, they must be updated here
            let questionData = {
                question: question.question,
                subtopic_id: topic,
                rank: question.rank,
                position: questionPosition + 1,
                question_exp: question.question_exp,
                help: question.help,
                help_exp: question.help_exp,
                is_calc: question.is_calc,
                is_math: question.is_math,
                is_free_response: question.is_free_response,
                exp_position_before_answer: question.exp_position_before_answer,
                question_type: section
            };

            let newQuestion = null
            if (topicType === 'subTopic') {
                questionData.subsubtopic_id = questionData.subtopic_id;
                delete questionData.subtopic_id;
                newQuestion = await this.props.createSubtopicQuestion(questionData)
            } else if (topicType === 'SatQuestionTopic') {
                questionData.subtopic_id = null;
                questionData.act_question_id = null;
                questionData.sat_question_id = topic;
                newQuestion = await createQuestion(questionData);
            } else if (topicType === 'ActQuestionTopic') {
                questionData.subtopic_id = null
                questionData.sat_question_id = null
                questionData.act_question_id = topic
                newQuestion = await createQuestion(questionData);
            } else if (topicType === 'AdminSatMockTest' || topicType === 'AdminSatRealTest') {
                delete questionData.subtopic_id
                questionData.sat_id = topic
                questionData.sat_type = satType
                questionData.question_type = section
                newQuestion = await createQuestion(questionData);
            } else if (topicType === 'AdminActMockTest' || topicType === 'AdminActRealTest') {
                delete questionData.subtopic_id
                questionData.act_id = topic
                questionData.act_type = actType
                questionData.question_type = section
                newQuestion = await createQuestion(questionData);
            } else {
                newQuestion = await createQuestion(questionData);
            }
            // Could optimize this with a single api call and iterate on backend
            const answerParams =  answers?.map((answer)=> this.getNewAnswerParams(answer,newQuestion.id));
            if(answerParams?.length > 0){
                await createMultipleAnswer({answers: answerParams})
            }

            endAnimation();

            if (isDuplicate) {
                await this.setPositionAfterCreate();
                this.flashMessage({message: `Duplicate Question Created`, type: 'success'});
            } else {
                this.flashMessage({message: `Question Created From Bucket`, type: 'success'});
            }
        }

        // this.setState({ newQuestion })
    }

    // set the position as per the specific question from where new question button is clicked
    setPositionAfterCreateMultiple = async (newQuestions) =>{
        const { questions } =  this.props;

        let updatedQuestions = questions;
        for(let i = newQuestions.length; i > 0; i--){
            let lastValue = updatedQuestions[updatedQuestions.length - i];
            updatedQuestions = arrayMove(updatedQuestions, (updatedQuestions).length - i, lastValue.position);
        }
        await this.updateQuestionPositions(updatedQuestions);
    }

    // set the position as per the specific question from where new question button is clicked
    setPositionAfterCreate = async () =>{
        const { questions } =  this.props;
        let lastValue = questions[questions.length - 1];
        const updatedQuestions = arrayMove(questions, (questions).length - 1, lastValue.position);
        await this.updateQuestionPositions(updatedQuestions);
    }

    updateQuestionPosition = async () =>{
        const { updateQuestionPositions, questions } = this.props;
        const updatedQuestions = await updateQuestionPositions(questions);
        this.setState({ questions: updatedQuestions })
    }

    flashMessage = (payload, timeout = 5000) => {
        const { alertPush, alertDelete } = this.props;
        alertPush(payload);
        setTimeout(() => alertDelete(),timeout);
    }

    updateQuestionPositions = async (questions, type = null) => {
        const { startAnimation, endAnimation, updateQuestionPositions, topicType } = this.props;
        startAnimation();
        const updated = await updateQuestionPositions({questions: questions}, topicType);
        endAnimation();
        return updated;
    }

    // Reset form and data for question creation below a specified question
    resetQuestionData = () => {
        this.setState({ fromQuestion: false, fromQuestionPosition: "" })
    }

    // Send props to question form to create question below a specified question
    createFromQuestion = (questionPosition) => {
        this.setState({ fromQuestion: true, fromQuestionPosition: questionPosition })
    }

    createAndAddQuestion = async (questionData, ref = false) => {
        //let newQuestion
        const {
            createQuestion, createProblemsetQuestion, createSubtopicQuestion, topicType, section, questions,
            match: { params: { satType, actType } }, startAnimation, endAnimation
        } = this.props;

        if ((section === "AdminSatMockTest" || section === "AdminSatRealTest") && questions.length === 20 && satType && satType === "MathNoCalc") {
            alert("You can't create more than 20 questions in this test!")
        } else if ((section === "AdminSatMockTest" || section === "AdminSatRealTest") && questions.length === 38 && satType && satType === "MathCalc") {
            alert("You can't create more than 38 questions in this test!")
        }else if ((section === "AdminActMockTest" || section === "AdminActRealTest") && questions.length >= 60 && actType && actType === "Mathematics") {
            alert("You can't create more than 60 questions in this test!")
        } else {
            // differentiate for problem sets and subtopics
            startAnimation();
            if (questionData.subtopic_id) {
                if (topicType === 'SatTopic') {
                    questionData.test_id = questionData.subtopic_id
                    questionData.subtopic_id = null
                }
                // newQuestion = await createQuestion(questionData);
                await createQuestion(questionData);
            } else if (topicType === 'SatQuestionTopic') {
                // newQuestion = await createQuestion(questionData);
                await createQuestion(questionData);
            } else if (topicType === 'ActQuestionTopic') {
                await createQuestion(questionData);
            } else if (topicType === 'AdminSatMockTest' || topicType === 'AdminSatRealTest') {
                await createQuestion(questionData);
            } else if (topicType === 'AdminActMockTest' || topicType === 'AdminActRealTest') {
                await createQuestion(questionData);
            } else if (topicType === 'subTopic') {
                // newQuestion = await this.props.createSubtopicQuestion(questionData)
                await createSubtopicQuestion(questionData)
            } else {
                // newQuestion = await createProblemsetQuestion(questionData);
                await createProblemsetQuestion(questionData);
            }

            // set the position as per the specific question from where new question button is clicked
            if (ref) {
                await this.setPositionAfterCreate();
            }
            endAnimation();
            this.flashMessage({message: `Question Created`, type: 'success'});
        }

        // this.setState({ newQuestion })
    }

    resetNewQuestion = () => {
        this.setState({ newQuestion: {} })
    }

    renderLoading() {
        const { loading } = this.props;
        if (loading) {
            return (
                <div className="loading-overlay">
                    <SVG name="spin" />
                </div>
            )
        }

        return null;
    }

    renderQuestions() {
        const { questions, section, subtopicId, topicType, problemsets } = this.props;
        // const { newQuestion } = this.state;
        if (questions.length > 0) {
            return (
                <SortableQuestions problemsets={problemsets}
                                   section={section}
                                   subtopicId={subtopicId}
                                   // newQuestion={newQuestion}
                                   resetNewQuestion={this.resetNewQuestion}
                                   questions={questions}
                                   createQuestionFrom={this.createQuestionFrom}
                                   createMultipleBucketQuestion={this.createMultipleBucketQuestion}
                                   updateQuestionPositions={this.updateQuestionPositions}
                                   createFromQuestion={this.createFromQuestion}
                                   topicType={topicType}/>
            )
        }

        return null;
    }


    render() {
        const { topicId, loading, topicType, section, questions, satType, actType } = this.props;
        const { fromQuestion, fromQuestionPosition } = this.state;
        if (!validSelect(topicId)) return null;
        return (
            <div className={`${loading ? 'loading-questions' : ''}`}>
                <AddQuestion satType={satType}
                             actType={actType}
                             section={section}
                             topicType={topicType}
                             position={questions.length}
                             fromQuestion={fromQuestion}
                             fromQuestionPosition={fromQuestionPosition}
                             createAndAddQuestion={this.createAndAddQuestion}
                             topicId={topicId}
                             createQuestionFrom={this.createQuestionFrom}
                             updateOnDelete={this.updateQuestionPosition}
                             createMultipleBucketQuestion={this.createMultipleBucketQuestion}
                             updateQuestionPositions={this.updateQuestionPositions}
                             type="admin"
                             resetQuestionData={this.resetQuestionData} />
                <Loader loading={loading}/>
                {this.renderQuestions()}
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminQuestions));
