import * as dbUtil from '../../api/dbUtil';
import {API} from '../../api';
import {
    RECEIVE_ACT,
    RECEIVE_ALL_ACTS,
    RECEIVE_ALL_PUBLISH_ACTS,
    REMOVE_ACT,
    UPDATE_ACT,
    UPDATE_USER_ACT_POSITION,
    PUBLISH_ACT,
    RECEIVE_UPDATE_ACT_SCORE,
    RECEIVE_ACT_BUBBLE_SHEET_ANSWER
} from "../constant";
import { checkSessionExpired, alertPushMessage } from "..";

const receiveAll = (data) => ({type: RECEIVE_ALL_ACTS, data});
const updateUserActPosition = (data) => ({type: UPDATE_USER_ACT_POSITION, data});
const receiveAllPublish = (data) => ({ type: RECEIVE_ALL_PUBLISH_ACTS, data });
const receive = (data) => ({type: RECEIVE_ACT, data });
const updateActData = (data) => ({type: UPDATE_ACT, data });
const removeAct = (data) => ({type: REMOVE_ACT, data });
const actPublishUnPublish = (data) => ({ type: PUBLISH_ACT, data });
const receiveUpdateScore = (data) => ({type: RECEIVE_UPDATE_ACT_SCORE, data });
const receiveUpdateBubbleAnswers = (data) => ({type: RECEIVE_ACT_BUBBLE_SHEET_ANSWER, data });

export const getAllTestActs = () => {
    return (dispatch) => dispatch(getAllActs(false));
}

export const getAllRealActs = () => {
    return (dispatch) => dispatch(getAllActs(true));
}

const getAllActs = (real = false) => {
    return (dispatch) => {
        return dbUtil.fetchMany(`acts/${real ? 'real_acts' : 'test_acts'}`).then(({data}) => {
            dispatch(receiveAll(data));
            return data;
        }).catch((error) => {
            dispatch(checkSessionExpired(error.message));
        })
    }
}

export const getCurrentUserActs = (params) => {
    return (dispatch) => {
        return dbUtil.fetchMany('acts_users', params).then(({data}) => {
            dispatch(receiveAllPublish(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getTestAct = (id) => {
    return (dispatch) => dispatch(getAct(id,false));
}

export const getRealAct = (id) => {
    return (dispatch) => dispatch(getAct(id,true));
}

const getAct = (id, real) => {
    return (dispatch) => {
        return dbUtil.fetch(`acts/${real ? 'real_acts' : 'test_acts'}`, id).then(({data}) => {
            dispatch(receive(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateTestAct = (id, attributes) => {
    return (dispatch) => dispatch(updateAct(id, attributes,false));
}

export const updateRealAct = (id, attributes) => {
    return (dispatch) => dispatch(updateAct(id, attributes,true, true));
}

const updateAct = (id, attributes, real, updatedAlert = false) => {
    return (dispatch) => {
        return dbUtil.update(`acts/${real ? 'real_acts' : 'test_acts'}`, id, attributes).then(({data}) => {
            dispatch(updateActData(data));
            if(updatedAlert){
                dispatch(alertPushMessage({message: `${data.title} Act updated Successfully`, type: "success"}));
            }
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const deleteTestAct = (id) => {
    return (dispatch) => dispatch(deleteACT(id,false));
}

export const deleteRealAct = (id) => {
    return (dispatch) => dispatch(deleteACT(id,true));
}

const deleteACT = (id, real = false) => {
    return (dispatch) => {
        return dbUtil.destroy(`acts/${real ? 'real_acts' : 'test_acts'}`, id).then(({data}) => {
            dispatch(removeAct(data));
            dispatch(alertPushMessage({message: `${data.title} Act deleted Successfully`, type: "success"}));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const createTestAct = (attributes) => {
    return (dispatch) => dispatch(createAct(attributes,false));
}

export const createRealAct = (attributes) => {
    return (dispatch) => dispatch(createAct(attributes,true));
}

const createAct = (attributes, real = false) => {
    return (dispatch) => {
        return dbUtil.create(`acts/${real ? 'real_acts' : 'test_acts'}`, attributes).then(({data}) => {
            dispatch(alertPushMessage({message: `${data.title} topic created Successfully`, type: "success"}));
            dispatch(receive(data));
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateActProgressType = (act) => {
    return (dispatch) => {
        dispatch(updateActData({...act, progress_by_problemset: !act.progress_by_problemset}));
        return dbUtil.update(`acts/test_acts`, act.id, {progress_by_problemset: !act.progress_by_problemset}).then(({data}) => {
            dispatch(updateActData(data));
            return data;
        }).catch((error) => {
            dispatch(updateActData(act));
            dispatch(checkSessionExpired(error.message));
        })
    }
}

export const updateActsPositions = (acts) => {
    return (dispatch) => {
        return API.put("/acts/test_acts/update_positions", acts).then(({data}) => {
            dispatch(receiveAll(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return acts;
        });
    }
}

export const updateUserActsPositions = (acts) => {
    return (dispatch) => {
        return API.put("/acts_users/update_positions", acts).then(({data}) => {
            dispatch(updateUserActPosition(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return acts;
        });
    }
}

export const publishUnPublishAct = (attributes) => {
    return (dispatch) => {
        return API.post('/acts/test_acts/publish_unpublish', attributes).then(({data}) => {
            dispatch(actPublishUnPublish(data))
            dispatch(alertPushMessage({message: `Act ${data.title} ${data.isPublish ? 'Published' : 'Unpublished'} successfully`, type: "success"}));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateActScore = (attributes) => {
    return (dispatch) => {
        return API.post('/acts/test_acts/update_score', attributes).then(({data}) => {
            dispatch(receiveUpdateScore(data))
            dispatch(alertPushMessage({message: `'${data.title}' Raw Score Conversion uploaded`, type: "success"}));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateActBubbleAnswers = (attributes) => {
    return (dispatch) => {
        return API.post('/acts/test_acts/update_bubble_answers', attributes).then(({data}) => {
            dispatch(receiveUpdateBubbleAnswers(data))
            dispatch(alertPushMessage({message: `'${data.title}' bubble sheet answer uploaded`, type: "success"}));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}