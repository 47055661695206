import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ASSIGNMENT_DIALOG_BOX } from '../dialogs/dialogs';
import * as alertActions from "../../modules/alert/action";
import * as assignmentActions from "../../modules/assignment/action";
import * as userProgressActions from "../../modules/userProgress/action";

class AssignButton extends Component {

  openDialogBox = (buttonTitle) => {
    const { openDialog } = this.props
    openDialog(ASSIGNMENT_DIALOG_BOX, {
      assignAssignment: this.assignAssignment,
      dismiss: this.dismiss,
      buttonTitle: buttonTitle
    })
  }

  assignAssignment = async (date) => {
    const {
      topicName, topicId, account, createAssignment, referenceType, referenceId, openDialogAfterAssignAssignment,
      close, location, reference_type, resetUserProgress
    } = this.props
    let topicType = '';
    if (location.pathname.includes("math")){
      topicType = "math";
    }else if (location.pathname.includes("grammar")){
      topicType = "grammar";
    }else if(location.pathname.includes("reading")){
      topicType = "reading";
    }else if(location.pathname.includes("acts")){
        topicType = "act";
    }else{
      topicType = "sat"
    }
    const params = {
      reference_type: referenceType,
      reference_id: referenceId,
      user_id: account.id,
      sat_question_id: (["sat_question", "act_question"].includes(topicName) ? topicId : ''),
      subsubtopic_id: (topicName === "subsubtopic" ? topicId : ''),
      subtopic_id: (topicName === "subtopic" ? topicId : ''), 
      assignment_date: date,
      topic_type: topicType 
    }

    const {show, reassign} = this.props;
    if(reassign){
      const assigned = await createAssignment({type: 'reassign', ...params});
      if(assigned){
        await this.flashMessage({message: `Sequence Reassigned. Due date is ${date}`, type: "success"});
        await resetUserProgress({ reference_id: referenceId, reference_type: reference_type, user_id: account.id })
      }
    } else if(show){
      const assigned = await createAssignment({type: 'assign', ...params});
      if(assigned){
        await this.flashMessage({message: `Sequence assigned. Due date is ${date}`, type: "success"});
      }
    }
    if (openDialogAfterAssignAssignment){
      openDialogAfterAssignAssignment()
    }else{
      close() 
    }
   
  }

  dismiss = () => {
    const { openDialogAfterAssignAssignment, close } = this.props
    if (openDialogAfterAssignAssignment){
      openDialogAfterAssignAssignment()
    }else{
      close() 
    }
  }

  flashMessage = (payload, timeout = 5000) => {
    const {alertPush, alertDelete} = this.props;
    alertPush(payload);
    setTimeout(() => alertDelete(),timeout);
  }

  render() {
    const {show, reassign, account: {status}} = this.props;
    if(reassign && status){
      return (<button className="btn btn-default m-2" onClick={()=> this.openDialogBox("Reassign")}>Reassign</button>)
    }else if(show && status){
      return (<button className="btn btn-default m-2" onClick={()=> this.openDialogBox("Assign")}>Assign</button>)
    }
    return null;
  }
}

const mapStateToProps = (state) => {
  return ({
    account: state.account
  })
}

const mapDispatchToProps = dispatch => {
  return ({
    createAssignment: (params) => dispatch(assignmentActions.createAssignment(params)),
    alertPush: (payload) => dispatch(alertActions.alertPush(payload)),
    alertDelete: () => dispatch(alertActions.alertDelete()),
    resetUserProgress: (params) => dispatch(userProgressActions.resetUserProgress(params)),
  })
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssignButton));