import React, { Component } from 'react';
import { connect } from 'react-redux'
import SectionQuestions from './SectionQuestions';
import * as satQuestionActions from '../../../../modules/satQuestion/action';
import './SATItem.scss'
import * as dialogActions from '../../../../modules/dialog/action';
import SVG from '../../../SVG';
import * as XLSX from 'xlsx';
import _ from 'lodash'
import { VIEW_CONVERTED_SCORE_DIALOG_BOX } from '../../../dialogs/dialogs';
import { withRouter } from 'react-router-dom';
import { SetScrollPosition } from "../../../helper/commonMethods";
import * as alertActions from "../../../../modules/alert/action";
import { PublishUnPublishTopicButton, ShowUploads, UploadExcel, checkAndRemoveLastValue } from "../common/CommonMethod";
import EditTitle from "../common/EditTitle";
import Switch from "react-switch";

const mapStateToProps = state => {
    const selectTopics = state?.satTopics?.map(topic => {
        return {
            value: topic.id,
            label: topic.title
        }
    })

    return ({
        selectTopics,
        // readings: state.satQuestions.filter(x => x.type === 'Reading'),
        // writings: state.satQuestions.filter(x => x.type === 'Writing'),
        // mathCalcs: state.satQuestions.filter(x => x.type === 'MathCalc'),
        // mathNoCalcs: state.satQuestions.filter(x => x.type === 'MathNoCalc'),
        satQuestions: state.satQuestions,
        account: state.account
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        getSatQuestions: (params) => dispatch(satQuestionActions.getAllSatQuestion(params)),
        updateSatQuestion: (id, attributes) => dispatch(satQuestionActions.updateSatQuestion( id, attributes)),
        openDialog: (config, options) => dispatch(dialogActions.open(config, options)),
        alertPush: (payload) => dispatch(alertActions.alertPush(payload)),
        alertDelete: () => dispatch(alertActions.alertDelete())
    })
}

class SATItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hidden: true,
            receivedContent: false,
            show: true,
            showEdit: false,
            loading: false
        }

        this.hideContent = this.hideContent.bind(this);
        this.deleteSAT = this.deleteSAT.bind(this);
        this.showScores = this.showScores.bind(this);
    }

    componentDidMount() {
        const { sat, satTopicModal, location } = this.props;
        const queryString = new URLSearchParams(location.search);
        if (!satTopicModal && queryString && queryString.get('sat') && (sat.id === queryString.get('sat'))) {
            this.hideContent();
        }
    }

    toggle = (el) => {
        this.setState({ [el]: !this.state[el] })
    }

    async hideContent() {
        const { sat, getSatQuestions, satQuestions } = this.props;
        const { hidden } = this.state;
        const question = Object.keys(satQuestions);
        const index = question.findIndex(x => x === sat.id);
        this.setState({loading: true});
        if (hidden && index === -1) {
            await getSatQuestions({ params: { sat_id: sat.id } });
            this.setState({ hidden: false, receivedContent: true, loading: false })
        } else{
            this.setState({ hidden: !hidden, loading: false });
        }
    }

    deleteSAT() {
        const { sat, deleteSAT } = this.props;
        let res = window.confirm("Are you sure you want to delete this SAT?");
        if (res) {
            deleteSAT(sat.id);
        } else {
            return null
        }
    }

    publishUnPublishSat() {
        const { sat, publishUnPublishSat } = this.props;
        if (sat.id) {
            publishUnPublishSat({ id: sat.id, isPublish: !sat.isPublish });
        } else {
            alert("Error: No SAT id selected!!")
        }
    }

    uploadSatRawScoreConversion = (event) => {
        const { sat, updateScore } = this.props
        let f = event.target.files[0]
        const reader = new FileReader();
        reader.onload = async (evt) => { // evt = on_file_select event
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'array' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            /* Update state */
            let newData = data.split('\n')
            let parsedData = _.compact(_.map(newData, (value) => value !== '' ? value.split(',') : ''))
            updateScore({ id: sat.id, score: parsedData });
        };
        reader.readAsArrayBuffer(f);
    }


    uploadSatBubbleSheetScore = (event) => {
        const { sat, updateBubbleAnswers } = this.props;
        let f = event.target.files[0];
        const reader = new FileReader();
        reader.onload = async (evt) => { // evt = on_file_select event
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'array' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            /* Update state */
            let newData = data.split('\n')
            let parsedData = _.compact(_.map(newData, (value) => value !== '' ?  checkAndRemoveLastValue(value.split(',')) : ''));
            await updateBubbleAnswers({ id: sat.id, bubble_sheet_answers: parsedData });
        };
        reader.readAsArrayBuffer(f);
    }

    showScores = (score, title, columns) => {
        const { openDialog } = this.props
        return openDialog(VIEW_CONVERTED_SCORE_DIALOG_BOX, {title, score, columns})
    }

    toggleProgressByProblemSet = async () =>{
        const { sat, updateSatProgressType } = this.props;
        const message = `Progress Show by ${!!sat.progress_by_problemset ? "Sequence" : "ProblemSet"}`;
        this.flashMessage({message: message, type: 'success'});
        await updateSatProgressType(sat)
    }

    renderButtons() {
        const { show, showEdit } = this.state;
        const { sat, account } = this.props;
        if (show && !showEdit) {
            return (
                <>
                    <div className="options selectButtonsCls">
                        <SVG onClick={() => this.toggle('showEdit')} name="pencil" />
                        <SVG onClick={this.deleteSAT} name="trash" />
                        <PublishUnPublishTopicButton account={account}
                                                     topic={sat}
                                                     onClick={() => this.publishUnPublishSat()}/>

                        {
                            account.is_super_admin &&
                            <div className="sequenceProblemSetProgressSwitch d-flex align-items-center ml-2">
                                <Switch checked={sat?.progress_by_problemset}
                                        onChange={()=> this.toggleProgressByProblemSet()}
                                        uncheckedIcon={<i className="swtch_txt unchecked">ProblemSet</i>}
                                        checkedIcon={<i className="swtch_txt checked">Sequence</i>}
                                        offColor="#00C789"
                                        onColor="#F06150"
                                        height={32}
                                        width={50}
                                        handleDiameter={24}/>
                                <label className="font-weight-bold ml-2">{sat?.progress_by_problemset ? "(ProblemSet)" : "(Sequence)"} </label>
                            </div>
                        }

                        <div className="view-uploaded-document">
                            <ShowUploads onViewButton={this.showScores}
                                         account={account}
                                         title={`${sat.title} raw converted scores`}
                                         record={sat.score}
                                         columns={4}
                                         ViewButtonTitle={'View Uploaded Score'} />
                            <ShowUploads onViewButton={this.showScores}
                                         account={account}
                                         title={`${sat.title} bubble sheet answers`}
                                         record={sat.bubble_sheet_answers}
                                         ViewButtonTitle={'View Sheet Answers'} />
                        </div>
                    </div >
                    {
                        account.is_super_admin &&
                        <div className="options selectButtonsCls divider-bottom align-items-center flex-wrap">
                            <span className="upload-document-span">Upload Document</span>
                            <UploadExcel onUpload={(e) => this.uploadSatRawScoreConversion(e)}
                                         uploadTitle={'Raw Score Conversion'}/>
                            <UploadExcel onUpload={(e) => this.uploadSatBubbleSheetScore(e)}
                                         uploadTitle={'Bubble Sheet Answer'}/>
                        </div>
                    }
                </>
            )
        }

        return null;
    }

    hiddenContent() {
        const {
            sat, selectTopics, updateSatQuestion, satQuestions, satTopicModal, satTopic, fromPopUp, mathTopic, actTopic,
            updateAfterSequencePublishUnpublish,  updateAfterPublishUnpublish, updateSAT
        } = this.props;
        const { hidden, showEdit, loading } = this.state;
        const sectionQuestions = [
            satQuestions?.[sat.id]?.filter(x => x.sat_id === sat.id && x.type === 'Reading'),
            satQuestions?.[sat.id]?.filter(x => x.sat_id === sat.id && x.type === 'Writing'),
            satQuestions?.[sat.id]?.filter(x => x.sat_id === sat.id && x.type === 'MathNoCalc'),
            satQuestions?.[sat.id]?.filter(x => x.sat_id === sat.id && x.type === 'MathCalc')
        ];
        const sectionHeaders = ["Reading", "Writing and Language", "Math - No Calculator", "Math - Calculator"];
        if(loading){
            return (
                <React.Fragment>
                    <div className="setTopBorder"/>
                    <div className="questions mt-5" style={{height: 120}}>
                        <div className="loading-overlay">
                            <SVG name="spin" />
                        </div>
                    </div>
                </React.Fragment>
            )
        }

        if (!hidden) {
            return (
                <React.Fragment>
                    {/*SAT Editable Form */}
                    <EditTitle onUpdate={updateSAT}
                               show={showEdit}
                               topicType={'SAT'}
                               flashMessage={this.flashMessage}
                               topic={sat}
                               toggleForm={() => this.toggle('showEdit')} />
                    {/**/}
                    {(satTopicModal === false) && this.renderButtons()}
                    <div className="questions">
                        {sectionQuestions.map((questions, idx) => {
                            return (
                                <SectionQuestions fromPopUp={fromPopUp}
                                                  sat={sat}
                                                  satTopic={satTopic}
                                                  satTopicModal={satTopicModal}
                                                  questions={questions}
                                                  header={sectionHeaders[idx]}
                                                  selectTopics={selectTopics}
                                                  openDialog={this.openDialog}
                                                  updateSatQuestion={updateSatQuestion}
                                                  key={idx}
                                                  mathTopic={mathTopic}
                                                  updateAfterSequencePublishUnpublish={updateAfterSequencePublishUnpublish}
                                                  actTopic={actTopic}
                                                  updateAfterPublishUnpublish={updateAfterPublishUnpublish}
                                                  updateSAT={updateSAT}
                                                  flashMessage={this.flashMessage}
                                                  isLastIndex={(sectionQuestions.length === (idx + 1))}/>
                            )
                        })}
                    </div>
                </React.Fragment>
            )
        }

        return null;
    }

    openDialog = (config, options) =>{
        const { sat, satTopicModal, openDialog } = this.props;
        const queryString = new URLSearchParams(this.props.location.search);
        if (!satTopicModal && queryString && queryString.get('sat') && (sat.id === queryString.get('sat'))) {
            if(queryString.get('sat_question')){
                SetScrollPosition(queryString.get('sat_question'), window.innerHeight/2);
            } else {
                SetScrollPosition(sat.id, window.innerHeight/2);
            }
        }
        openDialog(config,options);
    }

    flashMessage = (payload, timeout = 5000) => {
        const { alertPush, alertDelete } = this.props;
        alertPush(payload);
        setTimeout(() => alertDelete(),timeout);
    }

    render() {
        const { sat } = this.props;
        const { hidden } = this.state
        return (
            <div className="home-sat-item">
                <div className={`satView_header d-flex align-items-center flex-wrap w-100 ${hidden ? "" : "open"}`}>
                    <div className="hide-menu" onClick={this.hideContent}>
                        <h2 className="sat_title">{sat.title}</h2>
                    </div>
                    <span className="icon ml-auto" onClick={this.hideContent} style={{ cursor: "pointer" }}>Arrow icon</span>
                </div>
                {this.hiddenContent()}
            </div >
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SATItem));
