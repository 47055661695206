import * as dbUtil from '../../api/dbUtil';
import { API } from '../../api';
import {
    RECEIVE_ALL_TOPICS,
    RECEIVE_TOPIC,
    UPDATE_TOPIC,
    REMOVE_TOPIC,
    PUBLISH_TOPIC
} from '../constant'
import {
    alertPushMessage,
    checkSessionExpired,
    grammerTopicPublishUnpublish,
    readingTopicPublishUnpublish
} from '../index'

const receiveAll = (data) => ({ type: RECEIVE_ALL_TOPICS, data });
const receive = (data) => ({ type: RECEIVE_TOPIC, data });
const updateTest = (data) => ({ type: UPDATE_TOPIC, data });
const removeTest = (data) => ({ type: REMOVE_TOPIC, data });
const topicPublishUnpublish = (data) => ({ type: PUBLISH_TOPIC, data });

export const getAllTopics = (params) => {
    return (dispatch) => {
        return dbUtil.fetchMany('topics', params).then(({ data }) => {
            dispatch(receiveAll(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getTopic = (id) => {
    return (dispatch) => {
        return dbUtil.fetch('topics', id).then(({ data }) => {
            dispatch(receive(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateTopic = (id, attributes) => {
    return (dispatch) => {
        return dbUtil.update('topics', id, attributes).then(({ data }) => {
            dispatch(updateTest(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const deleteTopic = (id) => {
    return (dispatch) => {
        return dbUtil.destroy('topics', id).then(({ data }) => {
            dispatch(removeTest(data));
            dispatch(alertPushMessage({message: `${data.title} topic deleted Successfully`, type: "success"}));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const createTopic = (attributes) => {
    return (dispatch) => {
        return dbUtil.create('topics', attributes).then(({ data }) => {
            dispatch(alertPushMessage({message: `${data.title} topic created Successfully`, type: "success"}));
            dispatch(receive(data));
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const publishOrUnPublishTopic = (attributes) => {
    return (dispatch) => {
        return API.post('/topics/publish_unpublish',  attributes).then(({ data }) => {
            if (data.type === "GrammarTopic") {
                dispatch(grammerTopicPublishUnpublish(data))
            } else if (data.type === "Topic") {
                dispatch(topicPublishUnpublish(data))
            } else if (data.type === "ReadingTopic") {
                dispatch(readingTopicPublishUnpublish(data))
            }
            dispatch(alertPushMessage({message: `'${data.title}' Topic ${!data.isPublish ? 'Published' : 'Unpublished'}`, type: "success"}));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updatePositions = (topics) => {
    return (dispatch) => {
        return API.put("/tests/update_positions", topics).then(({ data }) => {
            dispatch(receiveAll(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}
