import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HomeSidebar from './HomeSidebar';
import HomeContent from './HomeContent';
import Navbar from './Navbar';
import "./Home.scss"
import { Helmet } from "react-helmet";
import Footer from '../Footer';
import * as accountActions from "../../modules/account/action";
import * as config from "../../config";

const mapStateToProps = state => {
    return ({
        account: state.account,
        dialog: state.dialog,
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        validateCurrentUser: () => dispatch(accountActions.validateCurrentUser()),
        backToTutorLogin: (history) => dispatch(accountActions.backToTutorLogin(history)),
    })
}


class Home extends Component {

    backToTutor = async () => {
        const { backToTutorLogin, history, validateCurrentUser } = this.props;
        await backToTutorLogin(history);
        await validateCurrentUser();
    }


    render() {
        const { account, match, dialog } = this.props;
        return (
            <>
                {/* vertical-layout vertical-menu-collapsible page-header-dark vertical-modern-menu preload-transitions 2-columns */}
                <div className={`pageMain_body${dialog && dialog.open ? " modal-open" : ""}`} data-open="click" data-menu="vertical-modern-menu" data-col="2-columns">
                    <Helmet>
                        <script src={`${config.appUrl()}/js/vendors.min.js`}/>
                        <script src={`${config.appUrl()}/js/plugins.min.js`}/>
                    </Helmet>
                    <Navbar account={account}
                            match={match}
                            backToTutor={this.backToTutor} />

                    <HomeSidebar account={account}
                                 match={match}
                                 backToTutor={this.backToTutor} />

                    <HomeContent match={match}
                                 account={account} />
                   <Footer />
                </div>
            </>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
