import {
    createStyles,
    SnackbarContent,
    withStyles,
} from '@material-ui/core';
import classNames from 'classnames';
import * as React from 'react';

const styles = (theme) => createStyles({
    snackbar: {
        position: 'relative',
        zIndex: 10000 + 2,
        display: 'flex',
        margin: 10,
        backgroundColor: 'transparent',
    },
    error: {
        background: 'rgba(240, 97, 80, 0.8) !important',
    },
    success: {
        background: 'rgba(0, 199, 137, 0.8) !important',
    },
    warning: {
        background: 'rgba(255, 235, 59, 0.8) !important',
        color: "black"
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
});

class AlertMessageComponent extends React.Component {
    render() {
        const { classes, message, type } = this.props;
        const cx = classNames(classes[type], classes.snackbar);

        return (
            <SnackbarContent
                className={classNames(classes[type], cx)}
                message={
                    <span id="client-snackbar" className={classes.message}>
                    {message}
                  </span>}
            />
        );
    }
}

export const AlertMessage = withStyles(styles, { withTheme: true })(AlertMessageComponent);