import React, {Component} from "react";
import SVG from "../../../../SVG";
import {connect} from "react-redux";
import "./UnlinkedAnswers.scss";
import {PROBLEM_SET_MATCH} from "../../../../../constants";

class UnlinkedAnswers extends Component {
  state = {
    unlinked: 0,
    linked: 0,
  };
  componentDidMount() {
    const { questions, question, problemset_questions } = this.props;
    let updatedQuestion;
    if (question.subtopic_id) {
      updatedQuestion = questions[question.position];
    } else {
      updatedQuestion = problemset_questions[question.position];
    }
    
    const unlinked = updatedQuestion?.total_unlinked_answers ?? 0;
    const linked = (updatedQuestion?.total_answers ?? 0) - unlinked;
    this.setState({ unlinked, linked, updatedQuestion });
  }

  componentDidUpdate(prevProps) {
    const { questions, question } = this.props;
    const { updatedQuestion } = this.state;
    const nextQuestion = questions[question.position];
    if (!nextQuestion) return;
    if (
      updatedQuestion?.total_answers !== nextQuestion.total_answers ||
      updatedQuestion?.total_unlinked_answers !==
        nextQuestion?.total_unlinked_answers
    ) {
      const unlinked = nextQuestion.total_unlinked_answers;
      const linked = nextQuestion.total_answers - unlinked;
      this.setState({ unlinked, linked, updatedQuestion: nextQuestion });
    } 
  }

  render() {
    if(window.location.href.match(PROBLEM_SET_MATCH)) return null;
    const {unlinked, linked} = this.state;
    return (
        <div className="unlinked-answers">
          <RenderIcon type={linked} svgName={"checkmark"}/>
          <RenderIcon type={unlinked} svgName={"error-x"}/>
        </div>
    );
  }
}

const RenderIcon = ({type, svgName}) =>{
  if (type === 0) return null;
  return (
      <div className={svgName}>
        <p>{type}</p>
        <SVG name={svgName} />
      </div>
  );
}

const mapStateToProps = ({ questions, problemset_questions }) => ({
  questions,
  problemset_questions,
});

export default connect(mapStateToProps, null)(UnlinkedAnswers);
