import * as dbUtil from '../../api/dbUtil';
import { RECEIVE_ALL_SAT_TOPICS, RECEIVE_SAT_TOPIC, REMOVE_SAT_TOPIC, UPDATE_SAT_TOPIC } from "../constant";
import { checkSessionExpired } from "..";

const receiveAll = (data) => ({ type: RECEIVE_ALL_SAT_TOPICS, data });
const receive = (data) => ({ type: RECEIVE_SAT_TOPIC, data });
const updateTest = (data) => ({ type: UPDATE_SAT_TOPIC, data });
const removeTest = (data) => ({ type: REMOVE_SAT_TOPIC, data })

export const getAllSatTopics = (params) => {
    return (dispatch) => {
        return dbUtil.fetchMany('sat_topics', params).then(({data}) => {
            dispatch(receiveAll(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getSatTopic = (id) => {
    return (dispatch) => {
        return dbUtil.fetch('sat_topics', id).then(({data}) => {
            dispatch(receive(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateSatTopic = (id, attributes) => {
    return (dispatch) => {
        return dbUtil.update('sat_topics', id, attributes).then(({data}) => {
            dispatch(updateTest(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const deleteSatTopic = (id) => {
    return (dispatch) => {
        return dbUtil.destroy('sat_topics', id).then(({data}) => {
            dispatch(removeTest(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const createSatTopic = (attributes) => {
    return (dispatch) => {
        return dbUtil.create('sat_topics', attributes).then(({data}) => {
            dispatch(receive(data))
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}
