import React from 'react';
import {Route, Redirect} from 'react-router-dom';

function PrivateRoute ({component: Component, account, ...rest}) {
  return (
      <Route {...rest} render={(props) =>
          !account.loggedIn ?
              <Redirect to={{pathname: '/', state: {from: props.location}}} />
              :
              <Component {...props} />}
      />
  )
}

export { PrivateRoute };
