import React, { Component } from 'react';
import BucketAnswer from './BucketAnswer';

class BucketAnswers extends Component {
    createAnswer(answer) {
        const { createAndAddAnswer, close, questionId, answers, question } = this.props;

        if(question.is_free_response === answer.is_free_response){
            let answerData = {};
            if (answer.is_free_response) {
                answerData = {
                    answer: 'NA',
                    question_id: questionId,
                    explanation: 'NA',
                    position: answers.length,
                    free_response_answer: answer.free_response_answer,
                    symbol: answer.symbol,
                    correct: answer.correct,
                    is_free_response: answer.is_free_response,
                    is_exact_match: answer.is_exact_match,
                    symbol_position_left: answer.symbol_position_left
                };
            } else {
                answerData = {
                    answer: answer.answer,
                    question_id: questionId,
                    explanation: answer.explanation,
                    position: answers.length
                };
            }
    
            createAndAddAnswer(answerData,{message: 'Answer created from bucket', type: 'success'});
            close();
        }else{            
            if(answer.is_free_response){
                alert('Note: A free response Answer can only be added to a free response Question.') 
            }else{
                alert('Note: A Non-free response Answer can only be added to a non-free response Question.')
            }
        }

        
    }

    async handleFnc(bucketAnswer) {
        const { fncType } = this.props;
        switch (fncType) {
            case 'bucketView':
                return null;
            case 'createAnswer':
                this.createAnswer(bucketAnswer);
                break;
            default:
                break;
        }
    }

    render() {
        const { bucketAnswers } = this.props;
        return (
            <div>
                <ul>
                    {bucketAnswers.length === 0 && <div>No Record Found</div>}
                    {bucketAnswers.map(answer => {
                        return (
                            <li
                                onClick={() => this.handleFnc(answer)}
                                key={answer.id}>
                                <BucketAnswer answer={answer} />
                            </li>
                        )
                    })}
                </ul>
            </div>
        );
    }
}

export default BucketAnswers;
