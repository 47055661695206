import React, { Component } from 'react';
import { RichUtils, EditorState } from 'draft-js';
import presetFontSizes from './presetFontSizes';
import SVG from '../../../SVG';
import { getResetStateForMultiOptionStyle } from '../../editorUtils';
import "./FontSizeSelection.scss";

class FontSizeSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentFontSize: "16",
            displayDropdown: false,
        }
    }

    handleChange = (currentFontSize) => {
        this.setState({currentFontSize})
        this.handleClose();
    }

    handleClose = () => {
        this.setState({ displayDropdown: !this.state.displayDropdown });
    }

    applyFontSize = () => {
        const { currentFontSize } = this.state;
        const { editorState, onChange, type } = this.props;

        const nextContentState = getResetStateForMultiOptionStyle(editorState, type);
        const nextEditorState = EditorState.push(editorState, nextContentState);
        onChange(RichUtils.toggleInlineStyle(nextEditorState, `${type}_${currentFontSize}`))
    }

    render() {
        const { currentFontSize, displayDropdown } = this.state;
        return (
            <div className="font-selection">
                <div className="current-size" >
                    <p onClick={this.applyFontSize}>
                        {currentFontSize}
                    </p>
                    <div className="vert-divider"/>
                    <SVG onClick={this.handleClose} name="down"/>
                </div>
                <FontList
                    currentFontSize={currentFontSize}
                    displayDropdown={displayDropdown}
                    handleChange={this.handleChange}/>
            </div>
        );
    }
}

const FontList = ({handleChange, displayDropdown}) => {
    if (displayDropdown) {
        return (
            <ul className="dropdown">
                {presetFontSizes.map(fontSize => {
                    return (
                        <li key={`FONTSIZE_${fontSize}`} onClick={() => handleChange(fontSize)}>
                            {fontSize}
                        </li>
                    )
                })}
            </ul>
        )
    }

    return null;
}

export default FontSizeSelection;
