import React, { Component } from 'react';
import { PaginationFooter } from '../../../../../utilities/admin.paginationFooter'
import { TYPE_TUTOR} from '../../../../../constants'

class TutorUserList extends Component {

    getTutorsPageData = async (pageNo = this.props.pageNo) => {
        const { fetchTutors, changePageNo } = this.props;
        await changePageNo(pageNo)
        await fetchTutors();
    }

    deleteTutor = async ({id, username, is_admin, is_super_admin}) => {
        if(is_super_admin) return null;
        const { deleteAdmin, deleteTutor, fetchAdmins, fetchTutors } = this.props;
        let res = window.confirm(`Are you sure you want to delete ${username} ?`);
        if (res) {
            let resp = window.confirm(`Do you really want to delete ${username} ?`);
            if (resp) {
                if (is_admin) {
                    const data = await deleteAdmin(id);
                    if(data){
                        await fetchAdmins();
                        await fetchTutors();
                    }
                } else {
                    const data = await deleteTutor(id);
                    if(data){
                        await fetchTutors();
                    }
                }
            }
        }
        return null
    }

    activateDeactivateTutor = async ({id, status, username, is_admin, is_super_admin}) => {
        if(is_super_admin) return null;
        const { updateAdminStatus, updateTutorStatus } = this.props;
        const statusName = status ? "deactivate" : "activate";
        let res = window.confirm(`Are you sure you want to (${statusName}) ${username}?`);
        if (res) {
            const data = {status: !status};
            if (is_admin) {
                await updateAdminStatus(id, data);
            } else {
                await updateTutorStatus(id, data);
            }
        } else {
            return null
        }
    }

    render() {
        const {
            createTutor, tutors, openDialogBox, linkStudentWithTutor, removeUserRole, limit, pageNo, fetchTutors
        } = this.props;

        return (
            <>
                <div className="addbutton text-right pb-2">
                    <button className={"btn btn-default btn-primary"}
                            onClick={() => openDialogBox({
                                userType: TYPE_TUTOR, tabLabel: "Create Tutor", createUser: createTutor, afterAction: fetchTutors
                            })}>
                        <span className="btnTxt">Create Tutor</span>
                        <i className="ico ico_plus"/>
                    </button>
                </div>
                <div className="col s12 p-0 dataTables_wrapper table-responsive">
                    <table id="pagedata_table3" className="dataTable display tutors_table">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>User Type</th>
                            <th>Status</th>
                            <th>Linked Students</th>
                            <th className="actions" colSpan={"2"}>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            tutors.data && tutors.data.length > 0 ?
                                tutors.data.map((tutor, i) => {
                                    let linkedStudents = tutor.linked_students;
                                    return <tr key={i}>
                                        <td>{tutor.username}</td>
                                        <td>{tutor.email}</td>
                                        <td>{tutor.userType}</td>
                                        <td><span className={`tblbdg ${tutor.status ? 'active' : 'inactive'}`}>{tutor.status ? "Active" : "In-Active"}</span></td>
                                        <td>
                                            {
                                                linkedStudents && linkedStudents.length ?
                                                    <span className="font-weight-bold text-primary cursorCls"
                                                          onClick={() => linkStudentWithTutor(tutor.id, linkedStudents)}>
                                                        {linkedStudents && linkedStudents.length}
                                                    </span>
                                                    :
                                                    '-'
                                            }
                                        </td>
                                        <td className="actions" colSpan={"2"}>
                                            <div className="actn_btngrp display-flex">
                                                <button className="btn btn-ico"
                                                        type="button"
                                                        disabled={tutor.is_super_admin}
                                                        onClick={() => this.deleteTutor(tutor)}>
                                                    <span className="ico delete"/>
                                                </button>
                                                <button className="btn btn-ico" type="button"
                                                        disabled={tutor.is_super_admin}
                                                        onClick={() => this.activateDeactivateTutor(tutor)}>
                                                    <span className={`ico ${tutor.status ? "cross" : "tick"}`}/>
                                                </button>
                                                <a className={"btn btn-ico modal-trigger"}
                                                   href={"#link_modal"}
                                                   role={"button"}
                                                   onClick={() => linkStudentWithTutor(tutor.id, linkedStudents)}>
                                                    <span className="ico link"/>
                                                </a>
                                                <RemoveAsTutor is_admin={tutor.is_admin}
                                                               onClick={() => removeUserRole(tutor, "Tutor")} />
                                            </div>
                                        </td>
                                    </tr>;
                                })
                                :
                                <tr>
                                    <td colSpan={"6"}>No Record Found</td>
                                </tr>

                        }
                        </tbody>
                    </table>
                </div>
                <PaginationFooterList getPageData={(data) => this.getTutorsPageData(data)}
                                      pageNo={pageNo}
                                      totalRecords={tutors?.count}
                                      limit={limit}/>
            </>

        );
    }
}

const RemoveAsTutor = ({is_admin = false, onClick}) => {
    if (is_admin) {
        return (
            <button className="btn btn-txt btn-default" type="button" onClick={() => onClick()}>
                <span className="txt">Remove as tutor</span>
            </button>
        )
    }
    return null;
}

const PaginationFooterList = (props) => {
    if (props?.totalRecords > 0) {
        return (<PaginationFooter {...props} />)
    }
    return null

}

export {TutorUserList};
