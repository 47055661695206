import React, {Component} from 'react';
import {connect} from 'react-redux';
import AnswerEditor from './AnswerEditor';
import * as answerActions from '../../../../../modules/answer/action';
import * as questionActions from '../../../../../modules/question/action';
import * as bucketActions from '../../../../../modules/bucket/action';
import * as animationActions from '../../../../../modules/animation/action';
import * as problemSetQuestionActions from '../../../../../modules/problemSetQuestion/action';
import 'katex/dist/katex.min.css'
import './AdminAnswer.scss'
import * as alertActions from "../../../../../modules/alert/action";

class AdminAnswer extends Component {
    state = {
        formActive: false,
        infoActive: false,
    }

    toggleForm = () => {
        const { formActive } = this.state;
        this.setState({ formActive: !formActive })
    }

    toggleInfo = () => {
        const { infoActive } = this.state;
        this.setState({ infoActive: !infoActive })
    }

    duplicateAnswer = async () => {
        const { answer, createAndAddAnswer, allAnswers, startAnimation, endAnimation } = this.props;
        const questionAnswers = allAnswers[answer.question_id];
        const answerData = {
            answer: answer.answer,
            question_id: answer.question_id,
            next_question: answer.next_question_id,
            explanation: answer.explanation,
            correct: false,
            position: questionAnswers.length
        }
        startAnimation();
        await createAndAddAnswer(answerData,{message: 'Duplicate answer created', type: 'success'});
        endAnimation();
    }

    bucketAnswerIsPresent(answer) {
        const { bucketAnswers } = this.props;
        for (let i = 0; i < bucketAnswers.length; i++) {
            if (bucketAnswers[i].id === answer.id) {
                return true;
            }
        }

        return false;
    }

    addToBucket = () => {
        const { addBucketAnswer, answer, startAnimation, endAnimation } = this.props;
        if (!this.bucketAnswerIsPresent(answer)) {
            startAnimation();
            addBucketAnswer(answer);
            this.flashMessage({message: `Answer added to Bucket`, type: 'success'});
            endAnimation();
        } else {
            this.flashMessage({message: `Answer already added in bucket`, type: 'warning'});
        }
        return null;
    }

    deleteAnswer = async () => {
        const { deleteAnswer, answer, startAnimation, endAnimation } = this.props;
        let res = window.confirm("Are you sure you want to delete this answer?");
        if (res) {
            startAnimation();
            await deleteAnswer(answer.id);
            // if(section === 'problemSet'){
            //     await getProblemSetQuestion(answer.question_id)
            // }else{
            //     await getQuestion(answer.question_id)
            // }

            this.toggleForm();
            // postDeleteUpdate(answer);
            this.flashMessage({message: `Answer deleted`, type: 'success'});
            endAnimation();
        } else {
            return null;
        }
    }

    flashMessage = (payload, timeout = 5000) => {
        const { alertPush, alertDelete } = this.props;
        alertPush(payload);
        setTimeout(() => alertDelete(),timeout);
    }

    render() {
        const { answer, postDeleteUpdate, postAnswerUpdate, section, subtopicId, topicType, satProblemSets, question } = this.props;
        const { formActive, infoActive } = this.state;
        return (
            <div className="admin-answer">
                <AnswerEditor question={question}
                              postAnswerUpdate={postAnswerUpdate}
                              postDeleteUpdate={postDeleteUpdate}
                              formActive={formActive}
                              infoActive={infoActive}
                              addToBucket={this.addToBucket}
                              duplicateAnswer={this.duplicateAnswer}
                              deleteAnswer={this.deleteAnswer}
                              toggleForm={this.toggleForm}
                              toggleInfo={this.toggleInfo}
                              answer={answer}
                              subtopicId={subtopicId}
                              topicType={topicType}
                              satProblemSets={satProblemSets}
                              section={section} />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return ({
        allAnswers: state.answers,
        bucketAnswers: state.bucket.answers,
        loading: state.animation,
        satProblemSets: ownProps.satProblemSets
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        deleteAnswer: (id) => dispatch(answerActions.deleteAnswer(id)),
        getQuestion: (id) => dispatch(questionActions.getQuestion(id)),
        addBucketAnswer: (data) => dispatch(bucketActions.addBucketAnswer(data)),
        startAnimation: () => dispatch(animationActions.start()),
        endAnimation: () => dispatch(animationActions.end()),
        getProblemSetQuestion: (params) => dispatch(problemSetQuestionActions.getProblemSetQuestion(params)),
        alertPush: (payload) => dispatch(alertActions.alertPush(payload)),
        alertDelete: () => dispatch(alertActions.alertDelete())
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminAnswer);
