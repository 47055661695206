import React from "react";
import PropTypes from 'prop-types';

class PaginationLimit extends React.Component  {

    constructor(props) {
        super(props);
        this.state = { isOpen: false, options: [] };
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { isOpen } = this.state;
        if(prevState.isOpen !== isOpen){
            /* Hide options if clicked on outside of element */
            if(isOpen){
                document.addEventListener('mousedown', this.handleClickOutside);
            } else {
                document.removeEventListener('mousedown', this.handleClickOutside);
            }
        }
    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({isOpen: false});
        }
    }

    toggling = () =>{
        const { isOpen } = this.state;
        this.setState({isOpen: !isOpen})
    }

    changeLimit = (value) =>{
        const { changeLimit, limit } = this.props;
        this.toggling();
        if(limit !== value){
            changeLimit(value);
        }
    }

    render () {
        const { isOpen } = this.state;
        const { totalRecord, limit, options } = this.props;
        if( totalRecord > 0){
            return (
                <div className={"appCus_pagination_limit d-inline-flex align-items-center"}>
                    <span className="title">Items Per Page</span>
                    <div ref={this.wrapperRef}>
                        <div onClick={this.toggling} className="limit-header">
                            {limit} <i className="fa fa-angle-down"/>
                        </div>
                        {isOpen && (
                            <div className="limit-list-container">
                                <ul className="limit-list">
                                    {
                                        options?.map((value)=>{
                                                return(
                                                    <li className={limit === value ? "limit-item active" : "limit-item"} onClick={() => this.changeLimit(value)}
                                                        key={value}>
                                                        <div className="limit-value">{value}</div>
                                                    </li>
                                                )
                                        })
                                    }
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            );
        }
        return null;
    }
}

PaginationLimit.propTypes = {
    totalRecord: PropTypes.number,
    limit: PropTypes.number,
    options: PropTypes.array,
    changeLimit: PropTypes.func.isRequired
}

PaginationLimit.defaultProps = {
    totalRecord: 0,
    limit: 10,
    options: [5,10,15,20,25,50],
    changeLimit: () => {}
}


export default PaginationLimit;

