import {
    RECEIVE_ALL_QUESTION_PROGS,
    RECEIVE_QUESTION_PROG,
    UPDATE_QUESTION_PROG,
    REMOVE_QUESTION_PROG
} from '../constant';

export const questionProgReducer = (state = [], action) => {
    let newState;
    switch (action.type) {
        case RECEIVE_ALL_QUESTION_PROGS:
            return action.data;
        case RECEIVE_QUESTION_PROG:
            return Object.assign([], state, [...state, action.data])
        case UPDATE_QUESTION_PROG:
            newState = state.map(obj => (action.data.id === obj.id) ? action.data : obj);
            return newState;
        case REMOVE_QUESTION_PROG:
            newState = state.filter(obj => (action.data.id !== obj.id));
            return newState;
        default:
            return state;
    }
}