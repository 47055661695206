import React from "react";
import _ from "lodash";
import calculator from "../../images/calculator.png";
import noCalculator from "../../images/no-calculator.png";
import SVG from "../SVG";
import {IN_A_ROW_CORRECT_COUNT} from "../../constants";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export function SetScrollPosition(id, marginTop = 100){
    window.scrollTo(0, 0);
    let element = document.getElementById(`T${id}`);
    if(element){
        let totalOffset = element.getBoundingClientRect().top + document.body.scrollTop;
        if ((window.innerHeight - marginTop) > totalOffset) totalOffset = marginTop;
        window.scrollTo(0, (totalOffset - marginTop));
    }
}

const defaultFunctions = () => {};
export const EmailInput = ({title, name, value, placeholder, errorMessage, onChange, onBlur = defaultFunctions}) => {
    return (
        <div className="row">
            <div className="form-group col-12">
                <label className="input-label">{title}</label>
                <div className="input-group">
                    <div className="input-group-addon">
                        <span className="inptIcon email"/>
                    </div>
                    <input name={name}
                           value={value}
                           type={"email"}
                           placeholder={placeholder}
                           className={"form-control"}
                           onChange={onChange}
                           onBlur={onBlur}/>
                </div>
                <div className="errorCls">{errorMessage}</div>
            </div>
        </div>
    )
}

export const TextInput = ({title, name, value, placeholder, errorMessage, onChange,onBlur = defaultFunctions}) => {
    return (
        <div className="row">
            <div className="form-group col-12">
                <label className="input-label">{title}</label>
                <div className="input-group">
                    <input name={name}
                           value={value}
                           type={"text"}
                           placeholder={placeholder}
                           className={"form-control"}
                           onChange={onChange}
                           onBlur={onBlur}/>
                </div>
                <div className="errorCls">{errorMessage}</div>
            </div>
        </div>
    )
}

export const PasswordInput = ({title, name, value, placeholder, errorMessage, onChange, onBlur = defaultFunctions}) => {
    return (
        <div className="row">
            <div className="form-group col-12">
                <label className="input-label">{title}</label>
                <div className="input-group">
                    <div className="input-group-addon">
                        <span className="inptIcon password"/>
                    </div>
                    <input name={name}
                           value={value}
                           type={"password"}
                           placeholder={placeholder}
                           className={"form-control"}
                           onChange={onChange}
                           onBlur={onBlur}/>
                </div>
                <div className="errorCls">{errorMessage}</div>
            </div>
        </div>
    )
}

export const getUserProgressAndProgressQuestion = ({account,questions}) =>{
    let userProgress = '', userProgressQuestion = '';
    if (_.keys(account.progress).length > 0) {
        userProgress = account.progress;
        userProgressQuestion = _.find(questions, (qq) => qq.id === userProgress.question_id);
    }
    return ({userProgress,userProgressQuestion})
}

export const getProblemSetProgress = ({account, problemset_questions}) =>{
    let correctAnswers = 0, problemSetProgress;
    if (account?.problemsetProgress?.question_id) {
        problemSetProgress = (_.find(problemset_questions, (dd) => dd.id === account.problemsetProgress.question_id));
    }
    if(problemSetProgress){
        correctAnswers = account?.problemsetProgress?.total_correct ?? 0;
    }
    return({problemSetCorrectAnswers: correctAnswers});
}

export const SetModalTopScroll = () =>{
    let object = document.getElementsByClassName("body")[0];
    object.scrollTo(0,0);
}

export const RenderCalcImage = ({question = {}}) => {
    if (question.is_calc === true) {
        return(<img className="calc-image" src={calculator} alt="calculator_question" />);
    } else if (question.is_calc === false){
        return(<img className="calc-image" src={noCalculator} alt="no_calculator_question" />);
    }
    return null;
}

export const RenderFreeResponseIcon = ({ question = {}}) => {
    if (question.is_free_response) {
        return (<span className="text-success ml-2"> (Free Response) </span>);
    }
    return null;
}

export const RenderHiddenQuestionIcon = ({question  = {}, location: {pathname}}) => {
    if (question.is_hidden && pathname.includes("problemset")) {
        return (<span className="ml-2"><i className="fa fa-eye-slash" /></span>);
    }
    return null;
}

export const Loader = ({loading}) =>{
    if (loading){
        return(
            <div className="loading-overlay">
                <SVG name="spin" />
            </div>
        )
    }
    return null;
}

export const ShowDragHandleButton = ({value, onCLick, show, disabled,  type = ''}) =>{
    if(show){
        return(
            <div className="add-sat p-0 ml-2">
                <div className={`newActForm d-flex w-100 flex-wrap justify-content-end`}>
                    <button type="button" className="btn btn-default btn-primary" onClick={() => onCLick()} disabled={disabled}>
                        <span className="btnTxt">{value ? 'Submit New Order' : `Reorder ${type}s`}</span>
                        {disabled && <i className="fa fa-refresh fa-spin" style={{ marginLeft: "5px" }}/>}
                    </button>
                </div>
            </div>
        )
    }
    return null;
}

export const NewTopicButton = ({onCLick, show= false, disabled = false, topicType = ''}) => {
    if(show){
        return (
            <div className="add-sat p-0">
                <div className={`newActForm d-flex w-100 flex-wrap justify-content-end`}>
                    <button type="button" className="btn btn-default btn-primary" onClick={() => onCLick()} disabled={disabled}>
                        <span className="btnTxt">Create {topicType}</span>
                        <i className="ico ico_plus" style={{marginLeft: "5px"}}/>
                    </button>
                </div>
            </div>
        )
    }
    return null;
}

export const SequenceProblemSetProgress = ({sequenceProgress, problemSetProgress, showProgressByProblemSet}) =>{
    if(sequenceProgress && !showProgressByProblemSet) {
        return(<PercentageProgress progress={sequenceProgress.percent_complete}/>);
    }
    if(problemSetProgress && showProgressByProblemSet) {
        return(<PercentageProgress progress={problemSetProgress}/>);
    }
    return null;
}

const PercentageProgress = ({progress}) =>{
    return(
        <>
            <span className="percentAboveCls"> {Math.round(progress) > 100 ? 100 : Math.round(progress)}% </span>
            <span className="progressVerticalCls" style={{ height: (progress > 100 ? 100 : progress) + "%" }}> </span>
        </>
    )
};

export function getAssignmentProgressData(assignments) {
    let problemSetAssignments = assignments && assignments.filter(({assignmentable_type}) => assignmentable_type === "ProblemSet");
    problemSetAssignments = problemSetAssignments ?? [];
    let assignmentIds = [],assignmentProgress = {};
    // eslint-disable-next-line array-callback-return
    problemSetAssignments.map(({assignmentable_id, progress}) => {
        assignmentIds.push(assignmentable_id);
        assignmentProgress[assignmentable_id] = 0 ;
        if(progress && progress.total_correct){
            if(progress.total_correct >= IN_A_ROW_CORRECT_COUNT){
                assignmentProgress[assignmentable_id] = 100;
            } else {
                assignmentProgress[assignmentable_id] = (progress.total_correct/IN_A_ROW_CORRECT_COUNT) * 100;
            }
        }
    });
    return({assignmentProgress, assignmentIds});
}

export const SectionProgressDiv = ({progress, topicId, questionType}) =>{

    if(progress && progress[topicId] && progress[topicId][questionType] > 0){
        const sectionProgress = progress[topicId][questionType];
        return(
            <div className="ml-3 satTypeProgressCls">
                <div className="progress">
                    <span>{`${sectionProgress}% complete`}</span>
                    <div className="progress-bar" role="progressbar" aria-valuenow={sectionProgress} aria-valuemin="0" aria-valuemax="100"
                         style={{ width: `${sectionProgress}%`}}>
                    </div>
                </div>
            </div>
        )
    }
    return null;
}

export const CircularProgressBar = ({percentage}) => {
    percentage = percentage > 0 ? percentage : 0;
    percentage = percentage > 100 ? 100 : percentage;
    return (
            <CircularProgressbar
                value={percentage}
                text={`${percentage}%`}
                styles={buildStyles({
                    strokeLinecap: 'round',
                    textSize: '30px',
                    pathTransitionDuration: 0.5,
                    pathColor: `#00C789`,
                    textColor: '#00C789',
                    trailColor: '#d6d6d6',
                    backgroundColor: '#00C789',
                })}
            />
    );
}
