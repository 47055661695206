import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Switch} from 'react-router-dom';
import PropsRoute from '../PropsRoute';
import Dashboard from './Dashboard';
import * as topicActions from '../../modules/topic/action';
import * as grammarTopicActions from '../../modules/grammarTopic/action';
import * as readingTopicActions from '../../modules/readingTopic/action';
import './HomeContent.scss'
import HomeSATs from './sat/HomeSATs'
import HomeTopics from './topics/HomeTopics'
import * as subtopicActions from '../../modules/subTopic/action';
import * as subSubTopicActions from '../../modules/subSubTopic/action';
import * as problemSetActions from '../../modules/problemSet/action';
import HomeSatQuestionMain from './topics/topic/HomeSatQuestionMain'
import HomeProblemSetDetail from './topics/problemSets/HomeProblemSetDetail'
import HomeSubtopicMain from './topics/topic/HomeSubtopicMain'
import HomeSubsubtopicMain from './topics/topic/HomeSubSubtopicMain'
import HomeSatTestQuestionMain from './topics/topic/HomeSatTestQuestionMain'
import StudentList from './users/StudentList';
import HomeActs from './acts/HomeActs';
import HomeActQuestionMain from './topics/topic/HomeActQuestionMain';
import HomeActTestQuestionMain from './topics/topic/HomeActTestQuestionMain';
import PageNotFound from "../PageNotFound";

class HomeContent extends Component {
    render() {
        const {
            match, getMathTopics, getGrammarTopics, getReadingTopics, mathTopics, readingTopics, grammarTopics,
            getSubtopics, getSubSubtopics, getProblemSets, account
        } = this.props;
        return (
            <div id="main" className="homePage_content">
                <Switch>
                    <PropsRoute path={`/home/not_found`} exact
                                component={PageNotFound}/>

                    {
                        !account.is_student &&
                        <PropsRoute exact
                                    path={`${match.path}/students`}
                                    component={StudentList}/>
                    }


                    {
                        (account.is_student || account.is_super_admin || account.is_admin) &&
                            <>
                                <PropsRoute path={`${match.path}`} exact
                                            component={Dashboard} />

                                <PropsRoute path={`${match.path}/sats`} exact
                                            component={HomeSATs}/>

                                <PropsRoute path={`${match.path}/acts`} exact
                                            component={HomeActs}/>

                                <PropsRoute path={`${match.path}/math`} exact
                                            component={HomeTopics}
                                            getTopics={getMathTopics}
                                            topics={mathTopics}
                                            topicType={'Topic'}
                                            title='Math'
                                            getSubtopics={getSubtopics}
                                            getSubSubtopics={getSubSubtopics}
                                            getProblemSets={getProblemSets}/>

                                <PropsRoute path={`${match.path}/grammar`} exact
                                            component={HomeTopics}
                                            getTopics={getGrammarTopics}
                                            topics={grammarTopics}
                                            title='Grammar'
                                            topicType={'GrammarTopic'}
                                            getSubtopics={getSubtopics}
                                            getSubSubtopics={getSubSubtopics}/>

                                <PropsRoute path={`${match.path}/reading`} exact
                                            component={HomeTopics}
                                            getTopics={getReadingTopics}
                                            topics={readingTopics}
                                            topicType={'ReadingTopic'}
                                            title='Reading'
                                            getSubtopics={getSubtopics}
                                            getSubSubtopics={getSubSubtopics}/>

                                {
                                    !(account.is_student && account.tutor_login) &&
                                        <>
                                            <PropsRoute exact
                                                        path={`${match.path}/sat/:satQuestionId`}
                                                        component={HomeSatQuestionMain}
                                                        topicType={'HomeSatQuestionTopic'}
                                                        key={`${match.path}/sat/:satQuestionId`}/>

                                            <PropsRoute exact
                                                        path={`${match.path}/act/:actQuestionId`}
                                                        component={HomeActQuestionMain}
                                                        topicType={'HomeActQuestionTopic'}
                                                        key={`${match.path}/act/:actQuestionId`}/>

                                            <PropsRoute exact
                                                        path={`${match.path}/problemset/:problemset/:satQuestionId`}
                                                        component={HomeProblemSetDetail} />

                                            <PropsRoute exact
                                                        path={`${match.path}/math/subsubtopic/:subsubtopicId/:subtopicId`}
                                                        topic_type={"Topic"}
                                                        component={HomeSubsubtopicMain}
                                                        topicType={`HomeSubsubTopic`}
                                                        key={`${match.path}/math/subsubtopic/:subsubtopicId/:subtopicId`}/>

                                            <PropsRoute exact
                                                        path={`${match.path}/grammar/subsubtopic/:subsubtopicId/:subtopicId`}
                                                        component={HomeSubsubtopicMain}
                                                        topicType={`HomeSubsubTopic`}
                                                        topic_type={"GrammarTopic"}
                                                        key={`${match.path}/grammar/subsubtopic/:subsubtopicId/:subtopicId`}/>

                                            <PropsRoute exact
                                                        path={`${match.path}/reading/subsubtopic/:subsubtopicId/:subtopicId`}
                                                        component={HomeSubsubtopicMain}
                                                        topicType={`HomeSubsubTopic`}
                                                        topic_type={"ReadingTopic"}
                                                        key={`${match.path}/reading/subsubtopic/:subsubtopicId/:subtopicId`}/>

                                            <PropsRoute exact
                                                        path={`${match.path}/math/:subtopicId/:topicId`}
                                                        component={HomeSubtopicMain}
                                                        topic_type={"Topic"}
                                                        topicType={`HomeSubTopic`}
                                                        key={`${match.path}/math/:subtopicId/:topicId`}/>

                                            <PropsRoute exact
                                                        path={`${match.path}/grammar/:subtopicId/:topicId`}
                                                        component={HomeSubtopicMain}
                                                        topic_type={"GrammarTopic"}
                                                        topicType={`HomeSubTopic`}
                                                        key={`${match.path}/grammar/:subtopicId/:topicId`}/>

                                            <PropsRoute exact
                                                        path={`${match.path}/reading/:subtopicId/:topicId`}
                                                        component={HomeSubtopicMain}
                                                        topic_type={"ReadingTopic"}
                                                        topicType={`HomeSubTopic`}
                                                        key={`${match.path}/reading/:subtopicId/:topicId`}/>

                                            <PropsRoute exact
                                                        path={`${match.path}/sat-test/:satId/mock/:satType`}
                                                        component={HomeSatTestQuestionMain}
                                                        topicType={`HomeSatMockTestQuestion`}
                                                        key={`${match.path}/sat-test/:satId/mock/:satType`}/>

                                            <PropsRoute exact
                                                        path={`${match.path}/sat-test/:satId/real/:satType`}
                                                        component={HomeSatTestQuestionMain}
                                                        topicType={`HomeSatRealTestQuestion`}
                                                        key={`${match.path}/sat-test/:satId/real/:satType`}/>

                                            <PropsRoute exact
                                                        path={`${match.path}/act-test/:actId/mock/:actType`}
                                                        component={HomeActTestQuestionMain}
                                                        topicType={`HomeActMockTestQuestion`}
                                                        key={`${match.path}/ast-test/:actId/mock/:actType`}/>

                                            <PropsRoute exact
                                                        path={`${match.path}/act-test/:actId/real/:actType`}
                                                        component={HomeActTestQuestionMain}
                                                        topicType={`HomeActRealTestQuestion`}
                                                        key={`${match.path}/ast-test/:actId/real/:actType`}/>

                                        </>
                                }

                            </>

                    }


                    <PropsRoute path={`${match.path}/*`} exact
                                component={PageNotFound}/>

                </Switch>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return ({
        account: state.account,
        mathTopics: state.topics,
        satTopics: state.satTopics,
        grammarTopics: state.grammarTopics,
        readingTopics: state.readingTopics,
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        // getSatTopics: (params) => dispatch(satTopicActions.getAll('sat_topics', params)),
        getMathTopics: (params) => dispatch(topicActions.getAllTopics(params)),
        getGrammarTopics: (params) => dispatch(grammarTopicActions.getAllGrammarTopics(params)),
        getReadingTopics: (params) => dispatch(readingTopicActions.getAllReadingTopics(params)),

        getSubtopics: (params) => dispatch(subtopicActions.getAllSubTopics(params)),
        getSubSubtopics: (params) => dispatch(subSubTopicActions.getAllSubSubTopics(params)),
        getProblemSets: (params) => dispatch(problemSetActions.getAllProblemSets(params)),

    })
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeContent);
