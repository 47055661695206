import React, { Component } from 'react';
import { connect } from 'react-redux';
import Editor from '../../../../editor/Editor';
import EditQuestionRank from './EditQuestionRank';
import * as questionActions from '../../../../../modules/question/action';
import * as problemSetQuestionActions from '../../../../../modules/problemSetQuestion/action';
import * as animationActions from '../../../../../modules/animation/action';
import "./EditQuestion.scss";
import { withRouter } from 'react-router';
import { FreeResponseSwitch, HideQuestionSwitch, MathSwitch } from "./Common";
import * as alertActions from "../../../../../modules/alert/action";

class EditQuestion extends Component {
    state = {
        editorState: {},
        rank: "",
        isCalc: false,
        is_free_response: false,
        is_hidden: false
    }

    componentDidMount() {
        const { question } = this.props;
        this.setState({
            rank: question.rank,
            isCalc: question.is_calc,
            is_free_response: question && question.is_free_response ? question.is_free_response : false,
            is_hidden: question && question.is_hidden ? question.is_hidden : false
        })
    }

    updateQuestion = async (e) => {
        e.preventDefault();
        const {
            startAnimation, endAnimation, updateQuestion, question, toggleForm,
            updateProblemSetQuestion, updateSubsubTopicQuestion
        } = this.props;
        const { editorState, rank, isCalc, is_free_response, is_hidden } = this.state;
        const data = { question: editorState, rank, is_calc: isCalc, is_free_response, is_hidden };
        startAnimation();
        if (question.problem_set_id) {
            await updateProblemSetQuestion(question.id, data);
        } else if (question.subsubtopic_id) {
            await updateSubsubTopicQuestion(question.id, data);
        } else {
            await updateQuestion(question.id, data);
        }
        this.flashMessage({message: `Question updated`, type: 'success'});
        // postQuestionUpdate(updatedQuestion)
        toggleForm();
        endAnimation()
    }

    flashMessage = (payload, timeout = 5000) => {
        const { alertPush, alertDelete } = this.props;
        alertPush(payload);
        setTimeout(() => alertDelete(),timeout);
    }

    updateEditorState = (editorState) => {
        this.setState({ editorState })
    }

    updateRank = (rank) => {
        this.setState({ rank })
    }

    render() {
        const { toggleForm, question, location, loading: disabled } = this.props;
        const { is_free_response, is_hidden, isCalc } = this.state;
        return (
            <div className="edit-question">
                <Editor content={question.question}
                        disabled={disabled}
                        updateEditorState={this.updateEditorState} />

                <div className="ques_attributes">
                    <EditQuestionRank hidden={location.pathname.includes("problemset")}
                                      rank={question.rank}
                                      disabled={disabled}
                                      updateRank={this.updateRank}/>

                    <MathSwitch show={question.is_math} isCalc={isCalc}
                                disabled={disabled}
                                onChange={() => this.setState({ isCalc: !isCalc })}/>

                    <FreeResponseSwitch is_free_response={is_free_response}
                                        disabled={disabled}
                                        onChange={() => this.setState({ is_free_response: !is_free_response })}/>

                    <HideQuestionSwitch is_hidden={is_hidden} location={location}
                                        disabled={disabled}
                                        onChange={() => this.setState({ is_hidden: !is_hidden })}/>

                    <div className="buttons btn_grup">
                        <button className="btn btn-success" onClick={this.updateQuestion} disabled={disabled}>Save</button>
                        <button className="btn btn-default" onClick={() => toggleForm()} disabled={disabled}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return ({
        loading: state.animation
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        startAnimation: () => dispatch(animationActions.start()),
        endAnimation: () => dispatch(animationActions.end()),
        updateQuestion: (id, attributes) => dispatch(questionActions.updateQuestion(id, attributes)),
        updateSubsubTopicQuestion: (id, attributes) => dispatch(questionActions.update('question_subsubtopics', id, attributes)),
        updateProblemSetQuestion: (id, attributes) => dispatch(problemSetQuestionActions.updateProblemSetQuestion(id, attributes)),
        alertPush: (payload) => dispatch(alertActions.alertPush(payload)),
        alertDelete: () => dispatch(alertActions.alertDelete())
    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditQuestion));