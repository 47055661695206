import React, {Component} from 'react';
import {connect} from 'react-redux';
import AdminSubtopics from './AdminSubtopics';
import EditTitle from "../../common/EditTitle";
import * as subtopicActions from '../../../../../modules/subTopic/action';
import * as topicActions from '../../../../../modules/topic/action';
import SVG from '../../../../SVG';
import "./AdminTopic.scss";
import {PROBLEM_SETS_DIALOG_BOX} from '../../../../dialogs/dialogs';
import * as dialogActions from '../../../../../modules/dialog/action';
import * as problemSetActions from '../../../../../modules/problemSet/action';
import {withRouter} from 'react-router-dom';
import AddNewTopic from "../../common/AddNewTopic";
import * as alertActions from "../../../../../modules/alert/action";
import {PublishUnPublishTopicButton} from "../../common/CommonMethod";
import {Loader} from "../../../../helper/commonMethods";

class AdminTopic extends Component {
    state = {
        show: false,
        showEdit: false,
        showCreate: false,
        loading: false,
    }

    async componentDidMount() {
        const { topic, satTopicModal, location: {search} } = this.props;
        
        const queryString = new URLSearchParams(search);
        if (!satTopicModal && queryString && queryString.get('topic') && (topic.id === queryString.get('topic'))) {
            await this.getSubtopics();
        }
    }

    toggle = (el) => {
        this.setState({ [el]: !this.state[el] })
    }

    deleteTopic = () => {
        const { deleteTopic, topic } = this.props;
        let res = window.confirm("Are you sure you want to delete this topic?");
        if (res) {
            deleteTopic(topic.id);
        } else {
            return null;
        }
    }

    publishUnPublishTopic() {
        const { topic, publishOrUnPublishTopic, topicType } = this.props;
        if (topic.id) {
            publishOrUnPublishTopic({ id: topic.id, isPublish: !topic.isPublish, topicType });
        } else {
            alert("Error: No topic id selected!!")
        }
    }

    renderButtons() {
        const { show, showEdit, loading } = this.state;
        const { topic, account } = this.props;
        if (show && !showEdit) {
            return (
                <div className="options topic_options">
                    <SVG onClick={() => this.toggle('showEdit')} name="pencil" disabled={loading}/>
                    <SVG onClick={this.deleteTopic} name="trash" disabled={loading}/>
                    <PublishUnPublishTopicButton topic={topic}
                                                 account={account}
                                                 disabled={loading}
                                                 onClick={() => this.publishUnPublishTopic()} />
                </div>
            )
        }

        return null;
    }


    openSatProblemSetDialog = async () => {
        const { openDialog, topic, topicType, getProblemSets } = this.props;
        const subtopics = topic?.subtopics
        if (topic?.subtopics?.[0]) {
            subtopics[0].id = topic?.subtopics[0]._id
        }
        const satTopicId = topic?.subtopics?.length > 0 ? topic?.subtopics?.[0]._id : topic.id
        await getProblemSets({ sattopic_id: satTopicId, topicType })
        this.setState({ satTopic: topic })
        const { satTopic } = this.state;
        openDialog(PROBLEM_SETS_DIALOG_BOX, { satTopic, subtopics: topic?.subtopics, topicType });
        // this.setState({show:true})
        this.toggle('show');
    }

    getSubtopics = async () => {
        const { getSubtopics, topic, subtopics } = this.props;
        const { show } = this.state;
        this.setState({loading: true});
        if (!show && !subtopics[topic.id]) {
            this.setState({ show: true });
            await getSubtopics({ test_id: topic.id });
            this.setState({ loading: false });
        } else{
            this.setState({ show: !show, loading: false });
        }
    }

    getFunctionAccToTopic = async () => {
        const { topicType } = this.props;
        if (topicType === 'SatTopic') {
            await this.openSatProblemSetDialog();
        } else {
            await this.getSubtopics();
        }
    }

    renderForm() {
        const { topic, updateTopic, satTopicModal } = this.props;
        const { showEdit, loading } = this.state;

        if (!showEdit) {
            return (
                <div className="topicTitle ">
                    <span className="ico_arrow" onClick={this.getFunctionAccToTopic} disabled={loading}/>
                    <p onClick={this.getFunctionAccToTopic} className="titleTxt d-flex flex-wrap" disabled={loading}>
                        {topic.title}
                    </p>
                    {satTopicModal === false && this.renderButtons()}
                </div>
            )
        } else {
            return (
                <div className="topicTitle ">
                    <span className="ico_arrow" onClick={this.getFunctionAccToTopic}/>
                    <EditTitle topic={topic}
                               onUpdate={updateTopic}
                               flashMessage={this.flashMessage}
                               show={true}
                               topicType={"Topic"}
                               toggleForm={() => this.toggle('showEdit')} />
                </div>
            )
        }
    }

    createSubtopic = async (data) => {
        const { createSubtopic, topic } = this.props;
        await createSubtopic({ test_id: topic.id, ...data })
    }

    flashMessage = (payload, timeout = 5000) => {
        const {alertPush, alertDelete} = this.props;
        alertPush(payload);
        setTimeout(() => alertDelete(),timeout);
    }

    render() {
        const { topic, subtopics, updateSubTopicPositions, topicType, satTopicModal, satTopic, updateAfterPublishUnpublish, mathTopic, actTopic } = this.props;
        const { show, loading } = this.state;
        return (
            <div className={`home-topic ${show ? "open" : ''}`}>
                {this.renderForm()}
                <div className="topic-content"> 
                {(topicType !== 'SatTopic') &&
                    <div className="topic-content-inner">
                        <Loader loading={loading}/>
                        <AdminSubtopics show={show}
                                        subtopics={subtopics[topic.id]}
                                        updateSubTopicPositions={updateSubTopicPositions}
                                        satTopicModal={satTopicModal}
                                        satTopic={satTopic}
                                        updateAfterPublishUnpublish={updateAfterPublishUnpublish}
                                        mathTopic={mathTopic}
                                        actTopic={actTopic}/>
                        {satTopicModal === false &&
                            <div className="form-container">
                                {!!show && <AddNewTopic createTopic={this.createSubtopic}
                                                         topicType={"Subtopic"}
                                                         disabled={loading}
                                                         alignButtonLeft={true}/>}
                            </div>
                        }

                    </div>
                }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return ({
        account: state.account,
        subtopics: state.subtopics,
    })
}

const mapDispatchToProps = (dispatch) => {
    return ({
        getProblemSets: (params) => dispatch(problemSetActions.getAllProblemSets(params)),
        getSubtopics: (params) => dispatch(subtopicActions.getAllSubTopics(params)),
        createSubtopic: (params) => dispatch(subtopicActions.createSubTopics(params)),
        // updateTopic: (id, attributes) => dispatch(topicActions.update('topics', id, attributes)),
        // deleteTopic: (id) => dispatch(topicActions.destroy('topics', id)),
        updateSubTopicPositions: (subtopics) => dispatch(subtopicActions.updateSubTopicPositions(subtopics)),
        openDialog: (config, options) => dispatch(dialogActions.open(config, options)),
        publishOrUnPublishTopic: (data) => dispatch(topicActions.publishOrUnPublishTopic(data)),
        alertPush: (payload) => dispatch(alertActions.alertPush(payload)),
        alertDelete: () => dispatch(alertActions.alertDelete())
    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminTopic));
