import React, {Component} from 'react';
import EditFlexEditor from './EditFlexEditor';
import ReadOnlyEditor from '../../../../editor/ReadOnlyEditor';
import "./FlexEditor.scss";


class FlexEditor extends Component {
    state = {
        formActive: false,
        content: "",
    }

    componentDidMount() {
        const { content } = this.props;
        this.setState({ content })
    }

    componentDidUpdate(prevProps) {
        const { content } = this.props;
        const { formActive } = this.state;
        if (prevProps.content !== content) {
            this.setState({ content })
        }

        if (!this.props.infoActive && formActive) {
            this.toggleForm();
        }
    }

    toggleForm = () => {
        const { formActive } = this.state;
        this.setState({ formActive: !formActive })
    }

    updateContent = (content) => {
        this.setState({ content })
    }

    renderEditor() {
        const { question, contentType, postQuestionUpdate, flashMessage } = this.props;
        const { formActive, content } = this.state;
        if (formActive) {
            return (
                <div>
                    <EditFlexEditor postQuestionUpdate={postQuestionUpdate}
                                    question={question}
                                    content={content}
                                    toggleForm={this.toggleForm}
                                    flashMessage={flashMessage}
                                    updateContent={this.updateContent}
                                    contentType={contentType} />
                </div>
            )
        } else {
            return (
                <div className={"flex-editor-show"}>
                    <ReadOnlyEditor content={content} />
                </div>
            )
        }
    }

    renderButton() {
        const { contentType, copyQuestionToExp } = this.props;
        switch (contentType) {
            case "question_exp":
                return (
                    <button className="btn btn-default mr-3" onClick={copyQuestionToExp}>Copy Question</button>
                )
            default:
                return null;
        }
    }

    render() {
        const { infoActive, contentType, question, contentTitle, content } = this.props;
        if (!infoActive) return null;
        return (
            <div className="flex-editor help_editor my-3">
                <div className="header d-flex align-items-center justify-content-start flex-wrap">
                    <h3 className="title">
                        {contentTitle}
                        {contentType==="question_exp" && question.exp_position_before_answer ? <span className=" ml-2 text-success">(Show before answer options)</span>: ''}
                    </h3>
                </div>
                {this.renderEditor()}
                <div className={`btn_grup ${content ? 'mt-2' : ''}`}>
                        {this.renderButton()}
                        <button className="btn btn-default" onClick={this.toggleForm}>Edit</button>
                </div>
            </div>
        );
    }
}

export default FlexEditor;
