//--------------------------------- FLASH ALERT START ---------------------------------
export const ALERT_PUSH = 'ALERT_PUSH';
export const ALERT_DELETE = 'ALERT_DELETE';
export const ALERT_DELETE_BY_INDEX = 'ALERT_DELETE_BY_INDEX';
//---------------------------------  FLASH ALERT END  ---------------------------------

//--------------------------------- EVENT ACTION START ---------------------------------
export const LOGIN_USER = 'LOGIN_USER';
export const STUDENT_LOGIN = 'STUDENT_LOGIN';
export const STUDENT_UNLINKED = 'STUDENT_UNLINKED';
export const LOGIN_BACK_FROM_STUDENT = 'LOGIN_BACK_FROM_STUDENT';
export const LOGOUT_USER = 'LOGOUT_USER';
//---------------------------------  EVENT ACTION END  ---------------------------------

//--------------------------------- USER START ---------------------------------
export const SET_USER_DATA = 'SET_USER_DATA';
export const RESET_USER_DATA = 'RESET_USER_DATA';
export const LOG_OUT_USER = 'LOG_OUT_USER';
export const RECEIVE_ALL_ADMIN = 'RECEIVE_ALL_ADMIN';
export const UPDATE_ADMIN = 'UPDATE_ADMIN';
export const RECEIVE_ALL_TUTOR = 'RECEIVE_ALL_TUTOR';
export const UPDATE_TUTOR = 'UPDATE_TUTOR';
export const RECEIVE_ALL_STUDENT_LISTS = 'RECEIVE_ALL_STUDENT_LISTS';
export const RECEIVE_ALL_STUDENT = 'RECEIVE_ALL_STUDENT';
export const UPDATE_STUDENT = 'UPDATE_STUDENT';
export const LINK_USER_WITH_TUTOR = "LINK_USER_WITH_TUTOR";
//--------------------------------- USER END ---------------------------------

//--------------------------------- STUDENT LIST ---------------------------------
export const GET_MY_STUDENTS = "GET_MY_STUDENTS";
//--------------------------------- STUDENT LIST ---------------------------------

//--------------------------------- USER STATS START ---------------------------------
export const GET_USER_CORRECT_INCORRECT_QUESTIONS = "GET_USER_CORRECT_INCORRECT_QUESTIONS";
export const GET_TIME_SPENT_BY_USER = "GET_TIME_SPENT_BY_USER";
export const GET_AVG_TIME_SPENT_BY_USER = "GET_AVG_TIME_SPENT_BY_USER";
export const SAVE_USER_QUESTIONS_PROGRESS = "SAVE_USER_QUESTIONS_PROGRESS";
//--------------------------------- USER STATS END ---------------------------------

//--------------------------------------  USER PROGRESS START -----------------------------------
export const SAVE_USER_PROGRESS = "SAVE_USER_PROGRESS";
export const GET_USER_PROGRESS = "GET_USER_PROGRESS";
export const RESET_USER_PROGRESS = "RESET_USER_PROGRESS";
export const SAVE_USER_PROBLEM_SET_PROGRESS = "SAVE_USER_PROBLEM_SET_PROGRESS";
export const GET_USER_PROBLEM_SET_PROGRESS = "GET_USER_PROBLEM_SET_PROGRESS";
export const RESET_USER_PROBLEM_SET_PROGRESS = "RESET_USER_PROBLEM_SET_PROGRESS";
export const GET_ALL_USER_PROBLEM_SETS_PROGRESS = "GET_ALL_USER_PROBLEM_SETS_PROGRESS";
export const GET_USER_SATS_PROGRESS = "GET_USER_SATS_PROGRESS";
export const GET_USER_ACTS_PROGRESS = "GET_USER_ACTS_PROGRESS";
//--------------------------------------  USER PROGRESS END -----------------------------------

//--------------------------------- ASSIGNMENT START ---------------------------------
export const CREATE_ASSIGNMENT = 'CREATE_ASSIGNMENT';
export const GET_ASSIGNMENTS = 'GET_ASSIGNMENTS';
export const UPDATE_ASSIGNMENT = 'UPDATE_ASSIGNMENT';
//---------------------------------  ASSIGNMENT END ---------------------------------


//--------------------------------- ANIMATION START ---------------------------------
export const START_ANIMATION = "START_ANIMATION";
export const END_ANIMATION = "END_ANIMATION";
//--------------------------------- ANIMATION END ---------------------------------

//--------------------------------- DIALOG BOX START ---------------------------------
export const OPEN_DIALOG_BOX = "OPEN_DIALOG_BOX";
export const CLOSE_DIALOG_BOX = "CLOSE_DIALOG_BOX";
//--------------------------------- DIALOG BOX END ---------------------------------

//--------------------------------- ACT PROBLEM SET START ---------------------------------
export const RECEIVE_ACT_PROBLEM_SETS = "RECEIVE_ACT_PROBLEM_SETS";
export const RESET_ACT_PROBLEM_SETS = "RESET_ACT_PROBLEM_SETS";
//--------------------------------- ACT PROBLEM SET END ---------------------------------

//--------------------------------- SAT PROBLEM SET START ---------------------------------
export const RECEIVE_SAT_PROBLEM_SETS = "RECEIVE_SAT_PROBLEM_SETS";
export const RESET_SAT_PROBLEM_SETS = "RESET_SAT_PROBLEM_SETS";
//--------------------------------- SAT PROBLEM SET END ---------------------------------

//-------------------------------------- ACT START-----------------------------------
export const RECEIVE_ALL_ACTS = "RECEIVE_ALL_ACTS";
export const RECEIVE_ACT = "RECEIVE_ACT";
export const UPDATE_ACT = "UPDATE_ACT";
export const REMOVE_ACT = "REMOVE_ACT";
export const PUBLISH_ACT = "PUBLISH_ACT";
export const RECEIVE_ALL_PUBLISH_ACTS = "RECEIVE_ALL_PUBLISH_ACTS";
export const RECEIVE_UPDATE_ACT_SCORE = "RECEIVE_UPDATE_ACT_SCORE";
export const RECEIVE_ACT_BUBBLE_SHEET_ANSWER = "RECEIVE_ACT_BUBBLE_SHEET_ANSWER";
export const UPDATE_USER_ACT_POSITION = "UPDATE_USER_ACT_POSITION";
//-------------------------------------- ACT END -----------------------------------

//-------------------------------------- SAT START -----------------------------------
export const RECEIVE_ALL_SATS = "RECEIVE_ALL_SATS";
export const RECEIVE_SAT = "RECEIVE_SAT";
export const UPDATE_SAT = "UPDATE_SAT";
export const REMOVE_SAT = "REMOVE_SAT";
export const PUBLISH_SAT = "PUBLISH_SAT";
export const RECEIVE_ALL_PUBLISH_SATS = "RECEIVE_ALL_PUBLISH_SATS";
export const RECEIVE_UPDATE_SAT_SCORE = "RECEIVE_UPDATE_SAT_SCORE";
export const RECEIVE_SAT_BUBBLE_SHEET_ANSWER = "RECEIVE_SAT_BUBBLE_SHEET_ANSWER";
export const UPDATE_USER_SAT_POSITION = "UPDATE_USER_SAT_POSITION";
//-------------------------------------- SAT END -----------------------------------

//-------------------------------------- BUCKET START -----------------------------------
export const ADD_BUCKET_QUESTION = "ADD_BUCKET_QUESTION";
export const ADD_BUCKET_ANSWER = "ADD_BUCKET_ANSWER";
export const CLEAR_QUESTION_BUCKET = "CLEAR_QUESTION_BUCKET";
export const CLEAR_ANSWER_BUCKET = "CLEAR_ANSWER_BUCKET";
export const CLEAR_BUCKET = "CLEAR_BUCKET";
export const ADD_BULK_QUESTION = "ADD_BULK_QUESTION";
export const ADD_BUCKET_PROBLEM_SET = "ADD_BUCKET_PROBLEM_SET";
export const CLEAR_BUCKET_PROBLEM_SET = "CLEAR_BUCKET_PROBLEM_SET";
//-------------------------------------- BUCKET END -----------------------------------

//-------------------------------------- STORE TEMPORARY START -----------------------------------
export const SET_COMPLETED_ASSIGNMENT_PAGE = "SET_COMPLETED_ASSIGNMENT_PAGE";
export const SET_CURRENT_ASSIGNMENT_PAGE = "SET_CURRENT_ASSIGNMENT_PAGE";
export const SET_ASSIGNMENT_OPENED_VIEW = "SET_ASSIGNMENT_OPENED_VIEW";
export const SET_ASSIGNMENT_DEFAULT_DATA = "SET_ASSIGNMENT_DEFAULT_DATA";
export const SET_CURRENT_QUESTION = "SET_CURRENT_QUESTION";
export const CLEAR_CURRENT_QUESTION = "CLEAR_CURRENT_QUESTION";
export const SET_PROBLEM_SET_PROGRESS = "SET_PROBLEM_SET_PROGRESS";
export const CLEAR_PROBLEM_SET_PROGRESS = "CLEAR_PROBLEM_SET_PROGRESS";
export const SET_SATS_USER_DATA = "SET_SATS_USER_DATA";
export const CLEAR_SATS_USER_DATA = "CLEAR_SATS_USER_DATA";
export const SET_ACTS_USER_DATA = "SET_ACTS_USER_DATA";
export const CLEAR_ACTS_USER_DATA = "CLEAR_ACTS_USER_DATA";
//-------------------------------------- STORE TEMPORARY END -----------------------------------

//-------------------------------------- PROBLEM SET START -----------------------------------
export const RECEIVE_ALL_PROBLEM_SET = "RECEIVE_ALL_PROBLEM_SET";
export const RECEIVE_PROBLEM_SET = "RECEIVE_PROBLEM_SET";
export const UPDATE_PROBLEM_SET = "UPDATE_PROBLEM_SET";
export const REMOVE_PROBLEM_SET = "REMOVE_PROBLEM_SET";
export const LINK_PROBLEM_SET_TO_SAT = "LINK_PROBLEM_SET_TO_SAT"
export const PUBLISH_UNPUBLISH_PROBLEM_SET = "PUBLISH_UNPUBLISH_PROBLEM_SET";
export const RECEIVE_UPDATED_PROBLEM_SET_NOTES = "RECEIVE_UPDATED_PROBLEM_SET_NOTES";
export const RECEIVE_MULTIPLE_PROBLEM_SET = "RECEIVE_MULTIPLE_PROBLEM_SET";
//-------------------------------------- PROBLEM SET END -----------------------------------

//-------------------------------------- PROBLEM SET ANSWER START -----------------------------------
export const RECEIVE_ALL_PROBLEM_SET_ANSWERS = "RECEIVE_ALL_PROBLEM_SET_ANSWERS";
export const RECEIVE_PROBLEM_SET_ANSWER = "RECEIVE_PROBLEM_SET_ANSWER";
export const UPDATE_PROBLEM_SET_ANSWER = "UPDATE_PROBLEM_SET_ANSWER";
export const REMOVE_PROBLEM_SET_ANSWER = "REMOVE_PROBLEM_SET_ANSWER";
//--------------------------------------  PROBLEM SET ANSWER END  -----------------------------------

//-------------------------------------- PROBLEM SET QUESTION START -----------------------------------
export const RECEIVE_ALL_PROBLEM_SET_QUESTIONS = "RECEIVE_ALL_PROBLEM_SET_QUESTIONS";
export const RECEIVE_PROBLEM_SET_QUESTION = "RECEIVE_PROBLEM_SET_QUESTION";
export const UPDATE_PROBLEM_SET_QUESTION = "UPDATE_PROBLEM_SET_QUESTION";
export const REMOVE_PROBLEM_SET_QUESTION = "REMOVE_PROBLEM_SET_QUESTION";
export const RECEIVE_MULTIPLE_PROBLEM_SET_QUESTION = "RECEIVE_MULTIPLE_PROBLEM_SET_QUESTION";
export const RESET_PROBLEM_SET_QUESTION = "RESET_PROBLEM_SET_QUESTION";
//--------------------------------------  PROBLEM SET QUESTION END  -----------------------------------

//--------------------------------------  QUESTION START  -----------------------------------
export const RECEIVE_ALL_QUESTIONS = "RECEIVE_ALL_QUESTIONS";
export const RECEIVE_QUESTION = "RECEIVE_QUESTION";
export const RECEIVE_MULTIPLE_QUESTION = "RECEIVE_MULTIPLE_QUESTION";
export const UPDATE_QUESTION = "UPDATE_QUESTION";
export const REMOVE_QUESTION = "REMOVE_QUESTION";
export const RESET_QUESTION_DATA = "RESET_QUESTION_DATA";
//--------------------------------------  QUESTION END  -----------------------------------

//--------------------------------------  SAT TEST QUESTION START  -----------------------------------
export const RECEIVE_ALL_SAT_REAL_TEST_QUESTIONS = "RECEIVE_ALL_SAT_REAL_TEST_QUESTIONS";
export const RECEIVE_ALL_SAT_MOCK_TEST_QUESTIONS = "RECEIVE_ALL_SAT_MOCK_TEST_QUESTIONS";
//--------------------------------------   SAT TEST QUESTION END  -----------------------------------

//--------------------------------------  SAT TEST QUESTION START  -----------------------------------
export const RECEIVE_ALL_ACT_REAL_TEST_QUESTIONS = "RECEIVE_ALL_ACT_REAL_TEST_QUESTIONS";
export const RECEIVE_ALL_ACT_MOCK_TEST_QUESTIONS = "RECEIVE_ALL_ACT_MOCK_TEST_QUESTIONS";
//--------------------------------------   ACT TEST QUESTION END  -----------------------------------

//--------------------------------------  ANSWER START  -----------------------------------
export const RECEIVE_ALL_ANSWERS = "RECEIVE_ALL_ANSWERS";
export const RECEIVE_MULTIPLE_ANSWER = "RECEIVE_MULTIPLE_ANSWER";
export const RECEIVE_ANSWER = "RECEIVE_ANSWER";
export const UPDATE_ANSWER = "UPDATE_ANSWER";
export const REMOVE_ANSWER = "REMOVE_ANSWER";
//--------------------------------------  ANSWER END  -----------------------------------

//--------------------------------------  ACT PROGRESS START  -----------------------------------
export const RECEIVE_USER_ACT_TYPE_PROGRESS = "RECEIVE_USER_ACT_TYPE_PROGRESS";
export const RECEIVE_USER_ACT_PROBLEM_SET_TYPE_PROGRESS = "RECEIVE_USER_ACT_PROBLEM_SET_TYPE_PROGRESS";
export const RECEIVE_USER_ACT_SEQUENCES_PROGRESS = "RECEIVE_USER_ACT_SEQUENCES_PROGRESS";
//--------------------------------------  ACT PROGRESS END  -----------------------------------

//--------------------------------------  SAT PROGRESS START  -----------------------------------
export const RECEIVE_USER_SAT_TYPE_PROGRESS = "RECEIVE_USER_SAT_TYPE_PROGRESS";
export const RECEIVE_USER_SAT_PROBLEM_SET_TYPE_PROGRESS = "RECEIVE_USER_SAT_PROBLEM_SET_TYPE_PROGRESS";
export const RECEIVE_USER_SAT_SEQUENCES_PROGRESS = "RECEIVE_USER_SAT_SEQUENCES_PROGRESS";
//--------------------------------------  SAT PROGRESS END  -----------------------------------

//--------------------------------------  SAT QUESTION START  -----------------------------------
export const RECEIVE_ALL_SAT_QUESTIONS = "RECEIVE_ALL_SAT_QUESTIONS";
export const RECEIVE_SAT_QUESTION = "RECEIVE_SAT_QUESTION";
export const UPDATE_SAT_QUESTION = "UPDATE_SAT_QUESTION";
export const PUBLISH_SAT_QUESTION = "PUBLISH_SAT_QUESTION";
export const UPDATE_SAT_QUESTION_VIDEO_EXPLANATION = "UPDATE_SAT_QUESTION_VIDEO_EXPLANATION";
//--------------------------------------  SAT QUESTION END  -----------------------------------

//--------------------------------------  ACT QUESTION START  -----------------------------------
export const RECEIVE_ALL_ACT_QUESTIONS = "RECEIVE_ALL_ACT_QUESTIONS";
export const RECEIVE_ACT_QUESTION = "RECEIVE_ACT_QUESTION";
export const UPDATE_ACT_QUESTION = "UPDATE_ACT_QUESTION";
export const PUBLISH_ACT_QUESTION = "PUBLISH_ACT_QUESTION";
export const UPDATE_ACT_QUESTION_VIDEO_EXPLANATION = "UPDATE_ACT_QUESTION_VIDEO_EXPLANATION";
//--------------------------------------  ACT QUESTION END  -----------------------------------

//--------------------------------------  USER SAT START  -----------------------------------
export const RECEIVE_USER_SAT = "RECEIVE_USER_SAT";
export const RECEIVE_ALL_USER_SATS = "RECEIVE_ALL_USER_SATS";
export const RECEIVE_USER_SAT_SCORE = "RECEIVE_USER_SAT_SCORE";
export const RECEIVE_ALL_USER_SAT_SCORES = "RECEIVE_ALL_USER_SAT_SCORES";
//--------------------------------------  USER SAT END  -----------------------------------

//--------------------------------------  USER ACT START  -----------------------------------
export const RECEIVE_USER_ACT = "RECEIVE_USER_ACT";
export const RECEIVE_ALL_USER_ACTS = "RECEIVE_ALL_USER_ACTS";
export const RECEIVE_USER_ACT_SCORE = "RECEIVE_USER_ACT_SCORE";
export const RECEIVE_ALL_USER_ACT_SCORES = "RECEIVE_ALL_USER_ACT_SCORES";
//--------------------------------------  USER ACT END  -----------------------------------

//--------------------------------------  USER SAT TEST PROGRESS START  -----------------------------------
export const RECEIVE_USER_SAT_TEST_PROGRESS = "RECEIVE_USER_SAT_TEST_PROGRESS";
export const RECEIVE_USER_SAT_TEST_PROGRESSES = "RECEIVE_USER_SAT_TEST_PROGRESSES";
export const RESET_USER_SAT_TEST_PROGRESS = "RESET_USER_SAT_TEST_PROGRESS";
export const RECEIVE_USER_SAT_TEST_SCORE = "RECEIVE_USER_SAT_TEST_SCORE";
export const RECEIVE_USER_ALL_SAT_TEST_SCORES = "RECEIVE_USER_ALL_SAT_TEST_SCORES";
//--------------------------------------  USER SAT TEST PROGRESS END  -----------------------------------

//--------------------------------------  USER ACT TEST PROGRESS START  -----------------------------------
export const RECEIVE_USER_ACT_TEST_PROGRESS = "RECEIVE_USER_ACT_TEST_PROGRESS";
export const RECEIVE_USER_ACT_TEST_PROGRESSES = "RECEIVE_USER_ACT_TEST_PROGRESSES";
export const RESET_USER_ACT_TEST_PROGRESS = "RESET_USER_ACT_TEST_PROGRESS";
export const RECEIVE_USER_ACT_TEST_SCORE = "RECEIVE_USER_ACT_TEST_SCORE";
export const RECEIVE_USER_ALL_ACT_TEST_SCORES = "RECEIVE_USER_ALL_ACT_TEST_SCORES";
//--------------------------------------  USER ACT TEST PROGRESS END  -----------------------------------

//--------------------------------------  DELETED RECORD START -----------------------------------
export const RECEIVE_DELETED_RECORDS = "RECEIVE_DELETED_RECORDS";
export const DELETED_RESTORE_RECORD = "DELETED_RESTORE_RECORD";
export const RESET_DELETED_RECORD = "RESET_DELETED_RECORD";
export const RESET_ALL_DELETED_RECORD = "RESET_ALL_DELETED_RECORD";
//--------------------------------------  DELETED RECORD END  -----------------------------------

//--------------------------------------  REPORTED PROBLEM START  -----------------------------------
export const RECEIVE_ALL_REPORT_PROBLEMS = 'RECEIVE_ALL_REPORT_PROBLEMS'
export const SUBMIT_REPORT_PROBLEM = "SUBMIT_REPORT_PROBLEM";
//--------------------------------------  REPORTED PROBLEM END  -----------------------------------

//--------------------------------------  GRAMMAR TOPICS START  -----------------------------------
export const RECEIVE_ALL_GRAMMAR_TOPICS = "RECEIVE_ALL_GRAMMAR_TOPICS";
export const RECEIVE_GRAMMAR_TOPIC = "RECEIVE_GRAMMAR_TOPIC";
export const UPDATE_GRAMMAR_TOPIC = "UPDATE_GRAMMAR_TOPIC";
export const REMOVE_GRAMMAR_TOPIC = "REMOVE_GRAMMAR_TOPIC";
export const PUBLISH_GRAMMAR_TOPIC = "PUBLISH_GRAMMAR_TOPIC"
//--------------------------------------  GRAMMAR TOPICS  END  -----------------------------------

//--------------------------------------  READING TOPICS START  -----------------------------------
export const RECEIVE_ALL_READING_TOPICS = "RECEIVE_ALL_READING_TOPICS";
export const RECEIVE_READING_TOPIC = "RECEIVE_READING_TOPIC";
export const UPDATE_READING_TOPIC = "UPDATE_READING_TOPIC";
export const REMOVE_READING_TOPIC = "REMOVE_READING_TOPIC";
export const PUBLISH_READING_TOPIC = "PUBLISH_READING_TOPIC";
//--------------------------------------  READING TOPICS  END  -----------------------------------

//--------------------------------------  SAT TOPICS START  -----------------------------------
export const RECEIVE_ALL_SAT_TOPICS = "RECEIVE_ALL_SAT_TOPICS";
export const RECEIVE_SAT_TOPIC = "RECEIVE_SAT_TOPIC";
export const UPDATE_SAT_TOPIC = "UPDATE_SAT_TOPIC";
export const REMOVE_SAT_TOPIC = "REMOVE_SAT_TOPIC";
//--------------------------------------  SAT TOPICS  END  -----------------------------------

//--------------------------------------  TOPICS START  -----------------------------------
export const RECEIVE_ALL_TOPICS = "RECEIVE_ALL_TOPICS";
export const RECEIVE_TOPIC = "RECEIVE_TOPIC";
export const UPDATE_TOPIC = "UPDATE_TOPIC";
export const REMOVE_TOPIC = "REMOVE_TOPIC";
export const PUBLISH_TOPIC = "PUBLISH_TOPIC";
//--------------------------------------  TOPICS  END  -----------------------------------

//--------------------------------------  SETTING START  -----------------------------------
export const GET_SETTING = "GET_SETTING";
//--------------------------------------  SETTING  END  -----------------------------------

//--------------------------------------  TEST START  -----------------------------------
export const RECEIVE_ALL_TESTS = "RECEIVE_ALL_TESTS";
export const RECEIVE_TEST = "RECEIVE_TEST";
export const UPDATE_TEST = "UPDATE_TEST";
export const REMOVE_TEST = "REMOVE_TEST";
//--------------------------------------  TEST  END  -----------------------------------

//--------------------------------------  SUB TOPIC START  -----------------------------------
export const RECEIVE_ALL_SUBTOPICS = "RECEIVE_ALL_SUBTOPICS";
export const RECEIVE_SUBTOPIC = "RECEIVE_SUBTOPIC";
export const UPDATE_SUBTOPIC = "UPDATE_SUBTOPIC";
export const REMOVE_SUBTOPIC = "REMOVE_SUBTOPIC";
export const PUBLISH_SUB_TOPIC = "PUBLISH_SUB_TOPIC";
export const PUBLISH_SUB_TOPIC_SEQUENCE = 'PUBLISH_SUB_TOPIC_SEQUENCE';
//--------------------------------------  SUB TOPIC  END  -----------------------------------

//--------------------------------------  SUB SUB TOPIC START  -----------------------------------
export const RECEIVE_ALL_SUB_SUB_TOPICS = "RECEIVE_ALL_SUB_SUB_TOPICS";
export const RECEIVE_SUB_SUB_TOPICS = "RECEIVE_SUB_SUB_TOPICS";
export const UPDATE_SUB_SUB_TOPICS = "UPDATE_SUB_SUB_TOPICS";
export const REMOVE_SUB_SUB_TOPICS = "REMOVE_SUB_SUB_TOPICS";
export const PUBLISH_SUB_SUB_TOPIC = "PUBLISH_SUB_SUB_TOPIC";
export const PUBLISH_SUB_SUB_TOPIC_SEQUENCE = 'PUBLISH_SUB_SUB_TOPIC_SEQUENCE';
//--------------------------------------  SUB SUB TOPIC END  -----------------------------------

//--------------------------------------  QUESTION PROG START  -----------------------------------
export const RECEIVE_ALL_QUESTION_PROGS = "RECEIVE_ALL_QUESTION_PROGS";
export const RECEIVE_QUESTION_PROG = "RECEIVE_QUESTION_PROG";
export const UPDATE_QUESTION_PROG = "UPDATE_QUESTION_PROG";
export const REMOVE_QUESTION_PROG = "REMOVE_QUESTION_PROG";
//--------------------------------------  QUESTION PROG END  -----------------------------------


//--------------------------------------  TEST PROG START  -----------------------------------
export const RECEIVE_ALL_TEST_PROGS = "RECEIVE_ALL_TEST_PROGS";
export const RECEIVE_TEST_PROG = "RECEIVE_TEST_PROG";
export const UPDATE_TEST_PROG = "UPDATE_TEST_PROG";
export const REMOVE_TEST_PROG = "REMOVE_TEST_PROG";
//--------------------------------------  TEST PROG END  -----------------------------------
