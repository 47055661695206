import React, { Component } from 'react';
import { connect } from 'react-redux'
import SectionQuestions from './SectionQuestions';
import * as satQuestionActions from '../../../modules/satQuestion/action';
import * as satProgressActions from '../../../modules/satProgress/action';
import * as satProblemSetActions from '../../../modules/satProblemSet/action';
import './SATItem.scss'
import * as dialogActions from '../../../modules/dialog/action';
import _ from 'lodash'
import { withRouter } from 'react-router-dom';
import Switch from 'react-switch';
import IfElseView from "../../helper/IfElseView";
import IfView from "../../helper/IfView";
import { CircularProgressBar, SetScrollPosition } from "../../helper/commonMethods";
import * as alertActions from "../../../modules/alert/action";
import SVG from "../../SVG";
import * as satActions from "../../../modules/sat/action";
import * as userSatActions from "../../../modules/userSat/action";
import * as userSatTestProgressActions from "../../../modules/userSatTestProgress/action";
import * as assignmentActions from "../../../modules/assignment/action";
import {FORM_DIALOG_BOX} from "../../dialogs/dialogs";

const mapStateToProps = state => {
    const selectTopics = state?.satTopics?.map(topic => {
        return {
            value: topic.id,
            label: topic.title
        }
    })

    return ({
        selectTopics,
        satQuestions: state.satQuestions,
        account: state.account,
        satSequencesProgress: state.satProgress.sequences,
        satSequenceTypeProgress: state.satProgress.sequenceType,
        satProblemSetTypeProgress: state.satProgress.problemSetType,
        userSatsData: state.userSats.data,
        userSatsScores: state.userSats.scores,
        satTestScores: state.userSatTestProgress.scores,
        userSatsProgress: state.userProgresses.sats,
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        getSat: (id) => dispatch(satActions.getTestSat(id)),
        updateSAT: (id, attributes) => dispatch(satActions.updateRealSAT(id, attributes)),
        deleteSAT: (id) => dispatch(satActions.deleteRealSAT(id)),
        getSatQuestions: (params) => dispatch(satQuestionActions.getAllSatQuestion(params)),
        updateSatQuestion: (id, attributes) => dispatch(satQuestionActions.updateSatQuestion( id, attributes)),
        openDialog: (config, options) => dispatch(dialogActions.open(config, options)),
        getUserSatData: (params) => dispatch(userSatActions.getSatScore(params)),
        saveUserSatData: (params) => dispatch(userSatActions.saveUserSat(params)),
        getUserAllSatsTestProgress: (params) => dispatch(userSatTestProgressActions.getUserAllSatsTestProgress(params)),
        getUserSatSequencesProgress: (params) => dispatch(satProgressActions.getUserSatSequencesProgress(params)),
        getUserSatSequenceTypeProgress: (params) => dispatch(satProgressActions.getUserSatSequenceTypeProgress(params)),
        getUserSatProblemSetTypeProgress: (params) => dispatch(satProgressActions.getUserSatProblemSetTypeProgress(params)),
        createAssignment: (params) => dispatch(assignmentActions.createAssignment(params)),
        getSatProblemSets: (id) => dispatch(satProblemSetActions.getSatProblemSets(id)),
        alertPush: (payload) => dispatch(alertActions.alertPush(payload)),
        alertDelete: () => dispatch(alertActions.alertDelete())
    })
}

class SATItem extends Component {
    constructor(props) {
        super(props);
        const { sat, showDragHandle, userSatsData } = this.props;
        let setData = userSatsData.find(dd => dd.sat_id === sat.id)
        this.state = {
            hidden: true,
            receivedContent: false,
            bubbleSheet: { 'Reading': false, 'Writing': false, 'MathNoCalc': false, 'MathCalc': false },
            // selectWrong: false,
            selectWrong: { 'Reading': false, 'Writing': false, 'MathNoCalc': false, 'MathCalc': false },
            // selectUncertain: false,
            selectUncertain: { 'Reading': false, 'Writing': false, 'MathNoCalc': false, 'MathCalc': false },
            bubbleSheetAnswer: setData && setData.bubbleSheetAnswer
                ? JSON.parse(setData.bubbleSheetAnswer) : { 'Reading': {}, 'Writing': {}, 'MathNoCalc': {}, 'MathCalc': {} },
            wrongSelectedQuestions: setData && setData.selectedWrong
                ? JSON.parse(setData.selectedWrong) : { 'Reading': [], 'Writing': [], 'MathNoCalc': [], 'MathCalc': [] },
            uncertainSelectedQuestions: setData && setData.selectedUncertain
                ? JSON.parse(setData.selectedUncertain) : { 'Reading': [], 'Writing': [], 'MathNoCalc': [], 'MathCalc': [] },
            isMock: false,
            isTest: false,
            gradeSubmitted: setData && setData.grade_submitted ? setData.grade_submitted : { 'Reading': false, 'Writing': false, 'MathNoCalc': false, 'MathCalc': false },
            timed: (setData &&  setData.timed && !(_.isEmpty(setData.timed))) ? setData.timed : { 'Reading': false, 'Writing': false, 'MathNoCalc': false, 'MathCalc': false },
            grading_mode: false,
            satProgressLoad: false,
            loading: false,
            showDragHandle: showDragHandle ?? false
        }
        
        this.hideContent = this.hideContent.bind(this);
    }

    async componentDidMount() {
        const { sat, location: {search: searchParams} } = this.props;
        const queryString = new URLSearchParams(searchParams);
        if (queryString && queryString.get('sat') && (sat.id === queryString.get('sat'))) {
            await this.hideContent();
        }
        if ((queryString?.get('area') === 'mock' || queryString?.get('area') === 'real') && queryString?.get('sat') === sat.id) {
            this.setState({ isMock: queryString?.get('area') === 'mock', isTest: true });
            await this.loadSatTestProgress();
        }
    }

    componentDidUpdate(props) {
        const { userSatsData, showDragHandle } = this.props;
        if (showDragHandle !== props.showDragHandle) {
            this.setState({showDragHandle});
        }
        if (userSatsData !== props.userSatsData) {
            const { sat } = this.props;
            let setData = userSatsData.find(dd => dd.sat_id === sat.id)
            this.setState({
                bubbleSheetAnswer: setData && setData.bubbleSheetAnswer
                    ? JSON.parse(setData.bubbleSheetAnswer) : { 'Reading': {}, 'Writing': {}, 'MathNoCalc': {}, 'MathCalc': {} },
                wrongSelectedQuestions: setData && setData.selectedWrong
                    ? JSON.parse(setData.selectedWrong) : { 'Reading': [], 'Writing': [], 'MathNoCalc': [], 'MathCalc': [] },
                uncertainSelectedQuestions: setData && setData.selectedUncertain
                    ? JSON.parse(setData.selectedUncertain) : { 'Reading': [], 'Writing': [], 'MathNoCalc': [], 'MathCalc': [] },
                gradeSubmitted: setData && setData.grade_submitted ? setData.grade_submitted : { 'Reading': false, 'Writing': false, 'MathNoCalc': false, 'MathCalc': false },
                timed: (setData &&  setData.timed && !(_.isEmpty(setData.timed))) ? setData.timed : { 'Reading': false, 'Writing': false, 'MathNoCalc': false, 'MathCalc': false }
            })
        }
    }

    toggle = (el) => {
        this.setState({ [el]: !this.state[el] })
    }

    async hideContent() {
        const { hidden, showDragHandle } = this.state;
        const { sat } = this.props;
        if(showDragHandle || sat.type === "RealSat") return null;
        if(hidden) {
            await this.setState({loading: true});
            const {
                sat, getSat, getSatQuestions, satQuestions, getUserSatSequencesProgress,
                getUserSatSequenceTypeProgress, getSatProblemSets, getUserSatProblemSetTypeProgress
            } = this.props;
            await getSat(sat.id);
            await getUserSatSequencesProgress({sat_id: sat.id});
            await getUserSatSequenceTypeProgress({sat_id: sat.id});
            if(sat.progress_by_problemset){
                await getSatProblemSets(sat.id);
                await getUserSatProblemSetTypeProgress({sat_id: sat.id});
            }
            const question = Object.keys(satQuestions);
            const index = question.findIndex(x => x === sat.id);
            if (index === -1) {
                await getSatQuestions({params: {sat_id: sat.id}});
            }
        }
        await this.setState({ hidden: !hidden, loading: false });
    }

    gradingModeToggleSwitch = () => {
        const { grading_mode } = this.state;
        this.setState({ grading_mode: !grading_mode });
    }

    hiddenContent(recordHash) {
        const {
            selectWrong, selectUncertain, wrongSelectedQuestions, uncertainSelectedQuestions, isMock, gradeSubmitted,
            timed, grading_mode, hidden, isTest, bubbleSheet, bubbleSheetAnswer, loading, showDragHandle
        } = this.state;
        const {
            sat, selectTopics, updateSatQuestion, satSequencesProgress, getUserSatSequencesProgress,
            getUserSatSequenceTypeProgress
        } = this.props;
        const { sectionQuestions, sectionHeaders, correctTestQuestions, uncertainTestQuestions } =  recordHash;

        if(showDragHandle) return null;
        if(loading){
            return (
                <React.Fragment>
                    <div className="setTopBorder"/>
                    <div className="questions mt-5" style={{height: 120}}>
                        <div className="loading-overlay">
                            <SVG name="spin" />
                        </div>
                    </div>
                </React.Fragment>
            )
        }

        if(hidden) return null;
        if (!hidden) {
            return (
                <React.Fragment>
                    <div className="setTopBorder"/>
                    <div className={"questions"}>
                        {
                            _.map(sectionQuestions, (questions, questionType) => {
                                return (
                                    <SectionQuestions isMock={isMock}
                                                      isTest={isTest}
                                                      sat={sat}
                                                      questions={questions}
                                                      header={sectionHeaders[questionType]}
                                                      selectTopics={selectTopics}
                                                      openDialog={this.openDialog}
                                                      updateSatQuestion={updateSatQuestion}
                                                      satSequencesProgress={satSequencesProgress && satSequencesProgress[sat.id]}
                                                      questionType={questionType}

                                                      bubbleSheet={bubbleSheet}
                                                      bubbleSheetAnswer={bubbleSheetAnswer}
                                                      selectBubbleSheet={this.selectBubbleSheet}
                                                      submitBubbleSheet={this.submitBubbleSheet}

                                                      selectWrong={selectWrong}
                                                      selectUncertain={selectUncertain}

                                                      getWrongSelectedQuestions={this.getWrongSelectedQuestions}
                                                      wrongSelectedQuestions={wrongSelectedQuestions}

                                                      getUncertainSelectedQuestions={this.getUncertainSelectedQuestions}
                                                      uncertainSelectedQuestions={uncertainSelectedQuestions}

                                                      correctTestQuestions={correctTestQuestions}
                                                      uncertainTestQuestions={uncertainTestQuestions}

                                                      getUserSatSequencesProgress={getUserSatSequencesProgress}
                                                      getUserSatSequenceTypeProgress={getUserSatSequenceTypeProgress}
                                                      selectionWrongArea={this.selectionWrongArea}
                                                      selectionUncertainArea={this.selectionUncertainArea}
                                                      submitSelectWrong={this.submitSelectWrong}
                                                      submitSelectUncertain={this.submitSelectUncertain}
                                                      gradeSubmitted={gradeSubmitted}
                                                      saveTimedUnTimed={this.saveTimedUnTimed}
                                                      timed={timed}
                                                      grading_mode={grading_mode}
                                                      key={questionType}
                                                      isLastIndex={ questionType === 'MathCalc'}
                                    />)
                            })
                        }
                    </div>
                </React.Fragment>
            )
        }
    }

    openDialog = (config,options) =>{
        const { sat, openDialog } = this.props;
        const queryString = new URLSearchParams(this.props.location.search);
        if (queryString && queryString.get('sat') && (sat.id === queryString.get('sat'))) {
            if(queryString.get('sat_question')){
                SetScrollPosition(queryString.get('sat_question'), window.innerHeight/2);
            } else {
                SetScrollPosition(sat.id, window.innerHeight/2);
            }
        }
        openDialog(config,options);
    }

    selectionWrongArea = (type) => {
        const { userSatsData, sat } =  this.props;
        let setData = userSatsData.find(dd => dd.sat_id === sat.id);
        this.setState({
            selectWrong: { ...this.state.selectWrong, [type]: !this.state.selectWrong[type] },
            wrongSelectedQuestions: setData?.selectedWrong
                ? JSON.parse(setData.selectedWrong) : { 'Reading': [], 'Writing': [], 'MathNoCalc': [], 'MathCalc': [] }
        })
    }

    selectionUncertainArea = (type) => {
        const { userSatsData, sat } =  this.props;
        const { selectUncertain } =  this.state;
        let setData = userSatsData.find(dd => dd.sat_id === sat.id);
        this.setState({
            selectUncertain: { ...selectUncertain[type], [type]: !selectUncertain[type] },
            uncertainSelectedQuestions: setData?.selectedUncertain
                ? JSON.parse(setData.selectedUncertain) : { 'Reading': [], 'Writing': [], 'MathNoCalc': [], 'MathCalc': [] }
        })
    }

    submitSelectWrong = async (type) => {
        const { wrongSelectedQuestions, gradeSubmitted, selectWrong } = this.state;
        const { sat, saveUserSatData, getUserSatData } = this.props
        await saveUserSatData({ selectedWrong: JSON.stringify(wrongSelectedQuestions), sat_id: sat.id, grade_submitted: { ...gradeSubmitted, [type]: selectWrong[type] } })
        await getUserSatData({ sat_id: sat.id})
        await this.flashMessage({message: "Wrong question submitted", type: 'success'});
        // Comment this feature as per client requirement on 09-12-2021
        // await this.props.createAssignment({ selectedWrong: JSON.stringify(wrongSelectedQuestions), user_id: account.id, topic_type: 'sat' })

        this.setState({ selectWrong: { 'Reading': false, 'Writing': false, 'MathNoCalc': false, 'MathCalc': false } })
    }

    submitSelectUncertain = async () => {
        const { uncertainSelectedQuestions } = this.state;
        const { sat, saveUserSatData } = this.props;
        await saveUserSatData({ selectedUncertain: JSON.stringify(uncertainSelectedQuestions), sat_id: sat.id});
        await this.flashMessage({message: "Mark uncertain submitted", type: 'success'});
        this.setState({ selectUncertain: { 'Reading': false, 'Writing': false, 'MathNoCalc': false, 'MathCalc': false } });
    }

    saveTimedUnTimed = (type) => {
        const { timed } = this.state;
        const { sat, saveUserSatData } = this.props;
        saveUserSatData({ timed: {...timed, [type]: !timed[type]}, sat_id: sat.id });
    }

    toggleSwitch = () => {
        this.setState({ isMock: !this.state.isMock });
    }

    getWrongSelectedQuestions = (questionId, type) => {
        const wrongSelectedQuestions = this.getQuestionValue(type, questionId, this.state.wrongSelectedQuestions);
        this.setState({ wrongSelectedQuestions })
    }

    getUncertainSelectedQuestions = (questionId, type) => {
        const uncertainSelectedQuestions = this.getQuestionValue(type, questionId, this.state.uncertainSelectedQuestions);
        this.setState({ uncertainSelectedQuestions })
    }

    getQuestionValue = (type, questionId, questions) =>{
        if (questions[type].length === 0) {
            questions[type].push(questionId)
        } else {
            let checkValue = questions[type].includes(questionId)
            if (checkValue) {
                    questions[type] =  questions[type].filter(item => item !== questionId)
            } else {
                questions[type].push(questionId)
            }
        }
        return (questions);
    }

    selectBubbleSheet = (type) => {
        const { userSatsData, sat } =  this.props;
        let setData = userSatsData.find(dd => dd.sat_id === sat.id);
        this.setState({
            bubbleSheet: { ...this.state.bubbleSheet[type], [type]: !this.state.bubbleSheet[type] },
            bubbleSheetAnswer: setData && setData.bubbleSheetAnswer ? JSON.parse(setData.bubbleSheetAnswer) : {
                'Reading': {}, 'Writing': {}, 'MathNoCalc': {}, 'MathCalc': {}
            }
        });
    }

    toggleRealMockSwitch = () => {
        const { sat } = this.props;
        const realTestPublish = sat?.math_no_calc_real_test_publish && sat?.math_calc_real_test_publish,
            mockTestPublish = sat?.math_no_calc_mock_test_publish && sat?.math_calc_mock_test_publish;
        if (realTestPublish && mockTestPublish){
            this.setState({ isMock: !this.state.isMock });
        } else if(mockTestPublish){
            this.setState({ isMock: true });
        } else if(realTestPublish){
            this.setState({ isMock: false });
        }
    }

    toggleTestSwitch = () => {
        const { sat } = this.props;
        const realTestPublish = sat?.math_no_calc_real_test_publish && sat?.math_calc_real_test_publish,
            mockTestPublish = sat?.math_no_calc_mock_test_publish && sat?.math_calc_mock_test_publish;
        if (realTestPublish && mockTestPublish){
            this.setState({ isTest: !this.state.isTest, isMock: false  });
        } else if(mockTestPublish){
            this.setState({ isTest: !this.state.isTest, isMock: true  });
        } else if(realTestPublish){
            this.setState({ isTest: !this.state.isTest, isMock: false  });
        }
        return this.loadSatTestProgress();
    }

    loadSatTestProgress = async () =>{
        const { satProgressLoad } =  this.state;
        if(satProgressLoad) return true;
        const { getUserAllSatsTestProgress, sat } = this.props;
        await getUserAllSatsTestProgress({ sat_id: sat.id });
        this.setState({satProgressLoad: true});
    }

    submitBubbleSheet = async ({ questionType, bubbleSheetAnswer, wrongSelectedQuestions }) => {
        const {gradeSubmitted} = this.state;
        const { sat, saveUserSatData, getUserSatData } = this.props;
        await saveUserSatData({
            bubbleSheetAnswer: JSON.stringify(bubbleSheetAnswer),
            selectedWrong: JSON.stringify(wrongSelectedQuestions),
            sat_id: sat.id,
            grade_submitted: {...gradeSubmitted, [questionType]: this.state.bubbleSheet[questionType]}
        });
        await getUserSatData({ sat_id: sat.id});
        await this.flashMessage({message: "Bubble Sheet submitted", type: 'success'});
        this.setState({bubbleSheet: {'Reading': false, 'Writing': false, 'MathNoCalc': false, 'MathCalc': false}})
    }

    flashMessage = (payload, timeout = 5000) => {
        const {alertPush, alertDelete} = this.props;
        alertPush(payload);
        setTimeout(() => alertDelete(),timeout);
    }

    deleteSat = () =>{
        const { sat, deleteSAT } = this.props;
        let res = window.confirm(`Are you sure you want to delete SAT '${sat.title}'?`)
        if (res) {
            deleteSAT(sat.id);
        }
    }

    updateSat = () => {
        const { sat, openDialog } = this.props;
        const { math, verbal } = sat.marks;
        const numberInput = {type: "number", pattern: /^[1-9][0-9]?$|^[1-7][0-9][0-9]?$|^800?$|^0$/}
        openDialog(FORM_DIALOG_BOX, {
            title: ()=> "Edit SAT",
            options: {
                "Title": {type: "text", name: "title", value: sat.title, pattern: /^\S.*/},
                "Verbal": {...numberInput, name: "verbal", value: verbal.toString(), group: "group1"},
                "Math": {...numberInput, name: "math",  value: math.toString(), group: "group1"},
            },
            createAction: (params) => this.updateSAT(params)
        });
    }

    updateSAT(params) {
        const { sat, updateSAT } = this.props;
        const oldParams = this.parseParams({title: sat.title, ...sat.marks});
        const newParams = this.parseParams(params);
        if(!_.isEqual(oldParams,newParams)){
            updateSAT(sat.id,newParams);
        }
    }

    parseParams = ({title, verbal, math}) =>{
        return({title, marks: {verbal: parseInt(verbal), math: parseInt(math)} })
    }

    render() {
        const { sat, account, satProblemSetTypeProgress, satSequenceTypeProgress, userSatsScores, userSatsProgress } = this.props;
        const { selectWrong, selectUncertain, bubbleSheet, hidden, showDragHandle } = this.state;
        const recordHash = GetRecordHash(this);
        return (
            <div className={
                Object.values(selectWrong).includes(true) || Object.values(selectUncertain).includes(true) || Object.values(bubbleSheet).includes(true) ?
                    "home-sat-item backDropShownCls" : 'home-sat-item'
            }>
                <div className={`satView_header d-flex align-items-center flex-wrap w-100 ${hidden ? "" : "open"}`}>
                    <div className="hide-menu" onClick={this.hideContent} style={{marginRight: 5}}>
                        <h2 className="sat_title">{sat.title}</h2>
                    </div>

                    <SatHeaderView recordHash={recordHash}
                                   userSatsScores={userSatsScores}
                                   userSatsProgress={userSatsProgress}
                                   gradingModeToggleSwitch={this.gradingModeToggleSwitch}
                                   toggleRealMockSwitch={this.toggleRealMockSwitch}
                                   toggleTestSwitch={this.toggleTestSwitch}
                                   satProblemSetTypeProgress={satProblemSetTypeProgress}
                                   satSequenceTypeProgress={satSequenceTypeProgress}/>

                    {
                        sat.type === "RealSat" && !account.tutur_login &&
                        <>
                          <span className="ml-auto margin-right-15">
                            <div className="row">
                              <SVG onClick={() => this.updateSat()} name="pencil" className="div_icon"/>
                              <SVG onClick={() => this.deleteSat()} name="trash" className="div_icon"/>
                            </div>
                          </span>
                        </>
                    }

                    {
                        !showDragHandle && sat.type === "TestSat" &&
                        <span className="icon ml-auto" onClick={this.hideContent} style={{ cursor: "pointer" }}>Arrow icon</span>
                    }
                </div>
                {this.hiddenContent(recordHash)}
            </div>

        );
    }
}

const GetRecordHash = ({state, props}) =>{
    const { isMock, isTest, grading_mode, hidden, receivedContent } = state;
    const { sat, satQuestions, satTestScores} = props;
    const currentSatQuestions = satQuestions?.[sat.id];
    let ReadingArr = [], WritingArr = [], MathNoCalcArr = [], MathCalcArr = [];
    if(currentSatQuestions){
        ReadingArr = currentSatQuestions.filter(x => x.sat_id === sat.id && x.type === 'Reading');
        WritingArr  = currentSatQuestions.filter(x => x.sat_id === sat.id && x.type === 'Writing');
        MathNoCalcArr = currentSatQuestions.filter(x => x.sat_id === sat.id && x.type === 'MathNoCalc');
        MathCalcArr  = currentSatQuestions.filter(x => x.sat_id === sat.id && x.type === 'MathCalc');
    }

    const sectionQuestions = { Reading: ReadingArr, Writing: WritingArr, MathNoCalc: MathNoCalcArr, MathCalc: MathCalcArr};
    const sectionHeaders = { Reading: "Reading", Writing: "Writing and Language", MathNoCalc: "Math - No Calculator", MathCalc: "Math - Calculator"};
    let totalTestMathScore = 0, correctTestQuestions = [], uncertainTestQuestions = [];

    const satTestProgressData = satTestScores.find(dd => dd.sat_id === sat.id && dd.real_test === !isMock);
    if(satTestProgressData && isTest){
        totalTestMathScore = satTestProgressData.math_score;
        correctTestQuestions = satTestProgressData.correct_question_ids;
        uncertainTestQuestions = satTestProgressData.uncertain_question_ids;
    }

    return({
        isMock, satQuestions, sat, grading_mode, totalTestMathScore, hidden, sectionQuestions, sectionHeaders,
        correctTestQuestions, uncertainTestQuestions, receivedContent, isTest
    });
}

const SatHeaderView = (props) =>{
    const {
        recordHash: {
            isMock,  sat, grading_mode, totalTestMathScore, hidden, isTest
        }, gradingModeToggleSwitch, toggleRealMockSwitch, toggleTestSwitch, satProblemSetTypeProgress,
        satSequenceTypeProgress, userSatsScores, userSatsProgress
    } = props;

    const satScore = userSatsScores.find(dd => dd.sat_id === sat.id);
    let totalVerbalScore = 0, totalMathScore = 0, grandScore = 0;

    if(sat.type === "RealSat"){
        const {verbal, math} = sat.marks;
        totalVerbalScore = verbal;
        totalMathScore   = math;
        grandScore       = totalMathScore + totalVerbalScore;
    } else if(satScore){
        totalVerbalScore = satScore.total_verbal_score;
        totalMathScore   = satScore.total_math_score;
        grandScore       = satScore.grand_score;
    }
    let verbalScore = totalVerbalScore, mathScore = totalMathScore, compositeScore = grandScore,
        testMathScore = totalTestMathScore;
    const compositeScoreArray = [totalVerbalScore, totalMathScore];

    if(!(sat?.score?.length > 0) && sat.type !== "RealSat"){
        verbalScore = `${totalVerbalScore}/96`;
        mathScore = `${totalMathScore}/58`;
        compositeScore = `${grandScore}/154`;
        testMathScore = `${totalTestMathScore}/58`;
    }

    let overAllPercentage;
    if(sat.progress_by_problemset){
        overAllPercentage = satProblemSetTypeProgress && satProblemSetTypeProgress[sat.id] && satProblemSetTypeProgress[sat.id]['OverAll'];
    } else {
        overAllPercentage = satSequenceTypeProgress && satSequenceTypeProgress[sat.id] && satSequenceTypeProgress[sat.id]['OverAll'];
    }

    if(!overAllPercentage){
        try {
            let data = userSatsProgress.find(data => data['sat_id'] === sat.id);
            if(data){
                overAllPercentage = Number(data['percentage']);
            }
        } catch (e) {
            overAllPercentage = 0;
        }
    }
    overAllPercentage = overAllPercentage ?? 0;

    const realTestPublish = sat?.math_no_calc_real_test_publish && sat?.math_calc_real_test_publish,
          mockTestPublish = sat?.math_no_calc_mock_test_publish && sat?.math_calc_mock_test_publish;

    return(
        <>
            <IfView visible={(isTest ? (totalTestMathScore !== 0) : (totalVerbalScore !== 0 || totalMathScore !== 0 || grandScore !== 0 ))}>
                <div className="bar-divider"/>
                    <div className="selectButtonsClass">
                        <div className="overAllScoreCls d-inline-flex align-items-start ml-5 px-4">
                            <div className="scoresVal">
                                <IfElseView condition={isTest}>
                                    <span> <b>Math:</b> { totalTestMathScore === 0 ? '-' : testMathScore} </span>
                                    <>
                                        <span> <b>Verbal:</b> {totalVerbalScore === 0 ? '-' : verbalScore} </span>
                                        <span> <b>Math:</b> {totalMathScore === 0 ? '-' : mathScore} </span>
                                        <span> <b>Composite:</b> {compositeScoreArray.includes(0) ? '-' : compositeScore} </span>
                                    </>
                                </IfElseView>
                            </div>
                        </div>
                    </div>
            </IfView>
            <IfView visible={!isTest && overAllPercentage > 0}>
                <div className="bar-divider"/>
                <div className="d-inline-flex align-items-center" style={{width: 40, height: 40}}>
                    <CircularProgressBar percentage={overAllPercentage} />
                </div>
            </IfView>
            <IfView visible={!hidden && (realTestPublish || mockTestPublish)}>
                <div className="bar-divider"/>
                <div className="hdr_real_test_switch">
                    <div className={"header-div"}>
                        <h5 className="d-inline-block text-nowrap">Test mode:</h5>
                    </div>
                    <Switch checked={isTest}
                            onChange={toggleTestSwitch}
                            onColor="#00C789"
                            className="ml-2"
                            height={32}
                            width={50}
                            handleDiameter={24}/>
                </div>
            </IfView>
            <IfView visible={(!hidden && isTest && (realTestPublish || mockTestPublish))}>
                <div className="bar-divider"/>
                <div className="hdr_switch">
                    <Switch checked={isMock}
                            onChange={toggleRealMockSwitch}
                            uncheckedIcon={<i className="swtch_txt unchecked">Mock</i>}
                            checkedIcon={<i className="swtch_txt checked">Real</i>}
                            offColor="#00C789"
                            onColor="#F06150"
                            height={32}
                            width={50}
                            handleDiameter={24}
                            disabled={!(realTestPublish && mockTestPublish)}/>
                    <label className="font-weight-bold ml-2">{isMock ? "(Mock)" : "(Real)"} </label>
                </div>
            </IfView>
            <IfView visible={!hidden && !isTest}>
                <div className="bar-divider"/>
                <div className="d-inline-flex align-items-center" style={{order: 2}}>
                    <h5 className="d-inline-block text-nowrap">Grading mode:</h5>
                    <Switch checked={grading_mode}
                            onChange={gradingModeToggleSwitch}
                            onColor="#00C789"
                            className="ml-2"
                            height={32}
                            width={50}
                            handleDiameter={24}/>
                </div>
            </IfView>
        </>
    )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SATItem));
