import React from 'react';
import {Route, Redirect} from 'react-router-dom';

function AdminRoute ({component: Component, account, ...rest}) {
  return (
      <Route
        {...rest}
        render={(props) =>
            !account.is_admin ?
                <Redirect to={{pathname: '/home', state: {from: props.location}}} />
                :
                <Component {...props} />}
      />
  )
}

export { AdminRoute };
