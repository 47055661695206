import React, { Component } from 'react';
import { ACT_SECTION_DIALOG_BOX } from '../../dialogs/dialogs';
import * as actTestQuestionActions from '../../../modules/actTestQuestion/action';
import * as animationActions from '../../../modules/animation/action';
import * as userActTestProgressActions from "../../../modules/userActTestProgress/action";
import { toWords } from '../../../utilities/custom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Switch from 'react-switch';
import IfView from "../../helper/IfView";
import IfElseView from "../../helper/IfElseView";
import BubbleSheet from "../../admin/content/common/bubbleSheet/BubbleSheet";
import DropDown, { DropDownOption } from "../../helper/Dropdown/DropDown";
import {
    getAssignmentProgressData,
    SectionProgressDiv,
    SequenceProblemSetProgress
} from "../../helper/commonMethods";

class SectionQuestions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            question: {},
            formActive: false,
            topic: "",
        }

        this.toggleForm = this.toggleForm.bind(this);
        this.handler = this.handler.bind(this);
        this.updateQuestion = this.updateQuestion.bind(this);
    }

    showQuestion(question) {
        const { formActive } = this.state;
        this.setState({ question })
        if (formActive) this.toggleForm();
    }

    toggleForm() {
        const { formActive, question } = this.state;
        if (formActive) {
            this.setState({ formActive: false, title: question.title, topic: question.topic })
        } else {
            this.setState({ formActive: true, title: "", topic: "" })
        }
    }

    handler(e) {
        const linkedTopicId = e.value;
        this.setState({ topic: linkedTopicId });
    }

    handleChange(prop) {
        return ({ target }) => this.setState({ [prop]: target.value })
    }

    updateQuestion(e) {
        // e.preventDefault();
        const { updateActQuestion } = this.props;
        const { topic, question } = this.state;
        const data = { topic };
        updateActQuestion(question.id, data).then(question => {
            this.setState({ question });
        });
    }

    openDialogBox(question) {
        const { selectTopics, act, isMock, isTest } = this.props
        this.setState({ question, topic: question.topic })
        return this.props.openDialog(ACT_SECTION_DIALOG_BOX, {
            act,
            question,
            title: `${act.title} ${toWords(question.type)} Question #${question.position + 1}`,
            handler: this.handler,
            selectTopics,
            updateQuestion: this.updateQuestion,
            isMock,
            isTest,
            afterReset: this.afterReset,
            openDialogAfterAssignAssignment: this.openDialogAfterAssignAssignment
        })
    }

    openDialogAfterAssignAssignment = () => {
        this.openDialogBox(this.state.question)
    }

    async componentDidUpdate(prevProps) {
        const { isMock, isTest } = this.props;
        if(isTest !== prevProps.isTest || isMock !== prevProps.isMock ){
            await this.fetchTestQuestions();
        }
    }

    fetchTestQuestions = async () =>{
        const { questions, startAnimation, endAnimation, act, getRealTestQuestions, getMockTestQuestions, isMock, isTest } = this.props;
        if(isTest){
            let act_type = questions[0].type;
            startAnimation();
            if(!isMock){
                await getRealTestQuestions({ params: { act_id: act.id, act_type: act_type, question_type: 'AdminActRealTest' }});
            } else {
                await getMockTestQuestions({ params: { act_id: act.id, act_type: act_type, question_type: 'AdminActMockTest' } });
            }
            endAnimation();
        }
    }

    async componentDidMount() {
        const { questions } = this.props
        const queryString = new URLSearchParams(this.props.location.search);
        const getQuestion = questions.find(dd => dd.id === queryString.get('act_question'))
        if (queryString && queryString.get('act_question') && getQuestion) {
            this.openDialogBox(getQuestion)
        }
        await this.fetchTestQuestions();
    }

    actTypeTestFlow = (header,type) => {
        const { history, act } = this.props
        history.push(`/home/act-test/${act.id}/${type}/${header}`)
    }

    openMockRefDialogBox = (key) => {
        const { questions } = this.props;
        let questionData = questions.find((dd, kk) => kk === key)
        this.openDialogBox(questionData)
    }

    resetActTest = async (actId, userId, type, real_test) => {
        const { startAnimation, endAnimation, resetUserActTestProgress, getUserActTestScores } = this.props
        let res = window.confirm("Are you sure you want to reset whole test?");
        if (res) {
            startAnimation();
            await resetUserActTestProgress({ act_id: actId, act_type: type, real_test });
            await getUserActTestScores({ act_id: actId });
            endAnimation();
        } else {
            return null
        }
    }

    afterReset = () => {
        const { act, getUserActSequencesProgress, getUserActSequenceTypeProgress } = this.props;
        getUserActSequencesProgress({act_id: act.id})
        getUserActSequenceTypeProgress({act_id: act.id})
    }

    toggleSwitch = (type) => {
        const { saveTimedUnTimed } = this.props;
        saveTimedUnTimed(type);
    }

    render() {
        const {
            questions, questionType, header, selectWrong, selectUncertain, getWrongSelectedQuestions, wrongSelectedQuestions,
            getUncertainSelectedQuestions, uncertainSelectedQuestions, act, isMock, correctTestQuestions,
            uncertainTestQuestions, actTestProgress, account, actSequencesProgress, timed, grading_mode, isLastIndex,
            actRealTestQuestions, actMockTestQuestions, isTest, bubbleSheet, submitBubbleSheet, selectBubbleSheet,
            gradeSubmitted, selectionWrongArea, selectionUncertainArea, submitSelectWrong, submitSelectUncertain,
            bubbleSheetAnswer, actProblemSets, actSequenceTypeProgress, actProblemSetTypeProgress, assignments
        } = this.props;

        let correct = questions.length - (wrongSelectedQuestions[questionType]).length;
        if(bubbleSheet[questionType] === true){
            return(
                <BubbleSheet account={account}
                             questions={questions}
                             questionType={questionType}
                             bubbleSheetAnswer={bubbleSheetAnswer}
                             submitBubbleSheet={submitBubbleSheet}
                             selectBubbleSheet={selectBubbleSheet}
                             topic={act}
                             wrongSelectedQuestions={wrongSelectedQuestions}
                             gradeSubmitted={gradeSubmitted}
                             selectWrong={selectWrong}
                             correct={correct}
                             topicType={"ACT"}/>
            )
        }


        let testCondition = isTest && (questionType !== "Mathematics")

        let correctMock = 0;
        const actTestQuestions = isMock ? actMockTestQuestions : actRealTestQuestions;
        actTestQuestions && actTestQuestions[act.id] && actTestQuestions[act.id][questionType] && (actTestQuestions[act.id][questionType]).map((dd) => {
                if (correctTestQuestions.includes(dd.id)) { correctMock++ }
                return ''
        });

        let getProgress = actTestProgress.find(dd => (dd.act_type === questionType && dd.real_test === !isMock && dd.act_id === act.id));

        let setShowProgressIndexForMock = [], assignedProblemSetProgress = {}, totalAssignedProblemSets = 0,
            topicProblemSetProgress = 0;
        const { assignmentProgress, assignmentIds } = getAssignmentProgressData(assignments);
        questions.map((question, k) => {
            actSequencesProgress && actSequencesProgress.map(dd => {
                if (dd.reference_id === question.id) {
                    setShowProgressIndexForMock.push({ index: k, percent: dd.percent_complete })
                }
                return ''
            })
            if(act.progress_by_problemset){
                let problemSetProgress = 0;
                let problemSets = actProblemSets && actProblemSets[act.id] && actProblemSets[act.id][question.id];
                problemSets = (problemSets && problemSets.filter((pb)=> assignmentIds.includes(pb._id))) ?? [];
                let totalProblemSets = problemSets?.length ?? 0;
                if(totalProblemSets){
                    problemSets.map(({_id}) => {
                        problemSetProgress += (assignmentProgress[_id] ?? 0);
                        totalAssignedProblemSets +=1;
                        return '';
                    });
                    topicProblemSetProgress += problemSetProgress;
                    problemSetProgress = problemSetProgress/totalProblemSets;
                }
                assignedProblemSetProgress[question.id] = problemSetProgress;
            }
            return ''
        });

        if(totalAssignedProblemSets > 0 && topicProblemSetProgress > 0){
            topicProblemSetProgress = Math.round((topicProblemSetProgress/totalAssignedProblemSets));
        }

        return (
            <div className={testCondition ? "mockEnabledCls" : `ques_set marginBottom20 ${selectWrong[questionType] || selectUncertain[questionType] ? "selectSectionCls" : ''}`}>
                <IfView visible={(selectWrong[questionType])}>
                    <div className="submitCancelCls d-flex w-100 justify-content-center position-relative">
                        <button onClick={() => submitSelectWrong(questionType)} className="btn btn-light btn-default m-2">
                            <i className="fa fa-check text-success mr-1" />Submit</button>
                        <button onClick={() => selectionWrongArea(questionType)} className="btn btn-light btn-default m-2">
                            <i className="fa fa-times text-danger mr-1" />Cancel</button>
                    </div>
                </IfView>

                <IfView visible={(selectUncertain[questionType])}>
                    <div className="submitCancelCls d-flex w-100 justify-content-center position-relative">
                        <button onClick={() => submitSelectUncertain(questionType)} className="btn btn-light btn-default m-2">
                            <i className="fa fa-check text-success mr-1" />Submit</button>
                        <button onClick={() => selectionUncertainArea(questionType)} className="btn btn-light btn-default m-2">
                            <i className="fa fa-times text-danger mr-1" />Cancel</button>
                    </div>
                </IfView>

                <h3 className="satQuesIdTitleCls d-flex align-items-center">{`${header}:`}
                    <IfView visible={!isTest}>
                        <span className="scoreOfHeadersCls mx-2 mt-1">
                            {correct && (gradeSubmitted[questionType] || selectWrong[questionType]) ? correct + '/' : '__/'}
                            {questions.length}
                            {/* {act && act.score && act.score.length > 0
                            ? questionType === "Reading" || questionType === "Writing" ? '40' : questions.length
                            : questions.length} */}
                        </span>
                    </IfView>

                    <IfView visible={isTest && questionType === "Mathematics"}>
                        <span className="scoreOfHeadersCls">
                            {getProgress && getProgress.submitted ? correctMock : '__'}/{actTestQuestions && actTestQuestions[act.id] && actTestQuestions[act.id][questionType] && (actTestQuestions[act.id][questionType]).length}
                        </span>
                        <button disabled={!!account.tutor_login}
                                onClick={() => this.actTypeTestFlow(questionType,isMock? 'mock' : 'real')}
                                className="btn btn-sm btn-success ml-2">
                            {getProgress ? getProgress.submitted ? 'Review Test' : 'Continue' : 'Take Test'}
                        </button>
                        <IfView visible={((account.is_admin) && getProgress && getProgress.submitted)}>
                            <button disabled={!!account.tutor_login}
                                    onClick={() => this.resetActTest(act.id, account.id, questionType, !isMock)}
                                    className={"ml-2 btn btn-sm btn-danger"}>
                                Reset Test
                            </button>
                        </IfView>
                    </IfView>

                    <IfElseView condition={act.progress_by_problemset}>
                        <IfView visible={topicProblemSetProgress > 0}>
                            <div className="ml-3 satTypeProgressCls">
                                <div className="progress">
                                    <span>{topicProblemSetProgress + "% complete"}</span>
                                    <div className="progress-bar" role="progressbar" aria-valuenow={topicProblemSetProgress}
                                         aria-valuemin="0" aria-valuemax="100"
                                         style={{ width: `${topicProblemSetProgress}%`}}>
                                    </div>
                                </div>
                            </div>
                        </IfView>
                        <IfElseView condition={act.progress_by_problemset}>
                            <SectionProgressDiv progress={actProblemSetTypeProgress} topicId={act.id} questionType={questionType}/>
                            <SectionProgressDiv progress={actSequenceTypeProgress} topicId={act.id} questionType={questionType}/>
                        </IfElseView>
                    </IfElseView>

                    <IfView visible={(!(!grading_mode || ((selectWrong[questionType] || selectUncertain[questionType]) || isTest)))}>
                        <div className="timedSwitch d-flex align-items-center ml-2">
                            <h5>Timed</h5>
                            <Switch
                                checked={timed[questionType]}
                                onChange={() => this.toggleSwitch(questionType)}
                                className="pl-2"
                                onColor="#00C789"
                                height={32}
                                width={50}
                                handleDiameter={24} />
                        </div>
                    </IfView>
                    <IfView visible={(!(!grading_mode || ((selectWrong[questionType] || selectUncertain[questionType]) || isTest)))}>
                        <div className="btn_grup ml-auto pl-3">
                            <DropDown title={'Action'} icon={'fa fa-ellipsis-v'}>
                                <DropDownOption onClick={() => selectBubbleSheet(questionType)}>
                                    Open Sheet
                                </DropDownOption>
                                <DropDownOption disabled={!!account.tutor_login} onClick={() => selectionWrongArea(questionType)}>
                                    Select Wrong
                                </DropDownOption>
                                <DropDownOption disabled={!!account.tutor_login} onClick={() => selectionUncertainArea(questionType)}>
                                    Select Uncertain
                                </DropDownOption>
                            </DropDown>
                            {/*<button disabled={!!account.tutor_login} onClick={() => selectBubbleSheet(questionType)} className="btn btn-danger">Open Sheet</button>*/}
                            {/*<button disabled={!!account.tutor_login} onClick={() => selectionWrongArea(questionType)} className="btn btn-danger">Select Wrong</button>*/}
                            {/*<button disabled={!!account.tutor_login} onClick={() => selectionUncertainArea(questionType)} className="btn btn-warning">Select Uncertain</button>*/}
                        </div>
                    </IfView>
                </h3>

                <IfElseView condition={isTest}>
                    <ul className={getProgress && getProgress.submitted ? "" : "mockEnabledCls"}>
                        {actTestQuestions && actTestQuestions[act.id] && actTestQuestions[act.id][questionType] && actTestQuestions[act.id][questionType].map((q, key) => {
                            const mockClasses = ["question"];
                            if (questionType === "Mathematics") {
                                if (getProgress && getProgress.submitted) {
                                    if (!(correctTestQuestions.includes(q.id))) {
                                        mockClasses.push('activeWrongLightCls')
                                    }
                                    if (correctTestQuestions.includes(q.id)) {
                                        mockClasses.push('activeCorrectLightCls')
                                    }
                                    if (uncertainTestQuestions.includes(q.id)) {
                                        mockClasses.push('activeUncertainLightCls')
                                    }
                                }
                            }

                            let result = setShowProgressIndexForMock.find(d => d.index === key)
                            return (
                                <li className={mockClasses.join(" ")}
                                    onClick={() => this.openMockRefDialogBox(key)}
                                    key={q.id}>
                                    <span className="textShowIndCls">{key + 1}</span>
                                    <PercentageView percent={result?.percent} />
                                </li>
                            )
                        })}
                    </ul>
                    <ul>

                    {questions?.map((question) => {
                        const classes = ["question"];
                        if (wrongSelectedQuestions[question.type].includes(question.id)) {
                            classes.push('activeWrongCls')
                        }
                        if (uncertainSelectedQuestions[question.type].includes(question.id)) {
                            classes.push('activeUncertainCls')
                        }

                        let sequenceProgress = actSequencesProgress && actSequencesProgress.find(dd => dd.reference_id === question.id)

                        return (
                            <li className={classes.join(" ")} onClick={() =>
                                selectWrong[questionType] ? getWrongSelectedQuestions(question.id, question.type)
                                    : selectUncertain[questionType] ? getUncertainSelectedQuestions(question.id, question.type)
                                        : this.openDialogBox(question)}
                                key={question.id} id={`T${question.id}`}>
                                <span className="textShowIndCls">{question.position + 1}</span>
                                <SequenceProblemSetProgress sequenceProgress={sequenceProgress}
                                                            showProgressByProblemSet={act.progress_by_problemset}
                                                            problemSetProgress={assignedProblemSetProgress[question.id]} />
                            </li>
                        )
                    })}
                </ul>
                </IfElseView>
                {!testCondition && !isLastIndex && <div className="setBottomBorder"/>}
            </div>
        );
    }
}

const PercentageView = ({percent}) =>{
    if (percent){
        percent = percent > 100 ? 100 : percent;
        return(
            <>
                <span className="percentAboveCls"> {Math.round(percent)}% </span>
                <span className="progressVerticalCls" style={{height: percent + "%"}}/>
            </>
        )
    }
    return null;
}

const mapStateToProps = state => {
    return ({
        account: state.account,
        assignments: state.assignments,
        actRealTestQuestions: state.actTestQuestions.real,
        actMockTestQuestions: state.actTestQuestions.mock,
        actTestProgress: state.userActTestProgress.data,
        actSequenceTypeProgress: state.actProgress.sequenceType,
        actProblemSetTypeProgress: state.actProgress.problemSetType,
        actProblemSets: state.actProblemSets,
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        getMockTestQuestions: (params) => dispatch(actTestQuestionActions.getAllActMockTestQuestions(params)),
        getRealTestQuestions: (params) => dispatch(actTestQuestionActions.getAllActRealTestQuestions(params)),
        resetUserActTestProgress: (params) => dispatch(userActTestProgressActions.resetUserActTestProgress(params)),
        getUserActTestScores: (params) => dispatch(userActTestProgressActions.getUserActTestScores(params)),
        startAnimation: () => dispatch(animationActions.start()),
        endAnimation: () => dispatch(animationActions.end()),
    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SectionQuestions));
