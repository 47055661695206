import React from "react";

export const MultipleProblemSetSelection = ({problemSets,selectUnselect,selectedProblemSetIds}) =>{
    if (!problemSets?.length) {
        return (<div className="mt-2">No record Found</div>)
    }

    return(
        problemSets.map((problemSet) => {
            const selected = selectedProblemSetIds.includes(problemSet._id);
            return(
                <div key={problemSet._id}
                     className={`subtopic-form problem-set-div ${selected ? 'selected-problem-set' : ''}`}
                     style={{ cursor: "pointer" }}
                     onClick={() => selectUnselect(problemSet,selected)}>
                    <span>{problemSet.title}</span>
                </div>
            )
        })
    )
}
