import React, { Component } from 'react';

// Draft JS
import { EditorState, convertFromRaw } from 'draft-js'
import katex from 'katex'
import Editor, { composeDecorators } from 'draft-js-plugins-editor'
import createImagePlugin from 'draft-js-image-plugin'
import createKatexPlugin from 'draft-js-katex-plugin'
import createMathjaxPlugin from 'draft-js-mathjax-plugin';
import createAlignmentPlugin from 'draft-js-alignment-plugin';
import createFocusPlugin from 'draft-js-focus-plugin';
import createResizeablePlugin from 'draft-js-resizeable-plugin';
import createVideoPlugin from 'draft-js-video-plugin';

// Custom Plugins
import createHighlightPlugin from './plugins/highlightPlugin';
import createTextColorPlugin from './plugins/textColorPlugin';
import createFontSizePlugin from './plugins/fontSizePlugin';

// CSS
import 'draft-js/dist/Draft.css';
import 'draft-js-image-plugin/lib/plugin.css'
import "./ReadOnlyEditor.scss"
import {tipDecorator} from "./plugins/tooltip/tooltipDecorator";

const mathjaxConfig = {
    showProcessingMessages: false,
    showMathMenu: false,
    showMathMenuMSIE: false,
    preview: 'none',
}

class ReadOnlyEditor extends Component {

    constructor(props) {
        super(props);

        // Instantiate Editor
        let editor;
        if (props.content) {
            editor = EditorState.createWithContent(convertFromRaw(JSON.parse(props.content)))
        } else {
            editor = EditorState.createEmpty()
        }

        this.state = {
            editorState: editor,
        }

        // Plugins
        const katexPlugin = createKatexPlugin({ katex });
        const mathjaxPlugin = createMathjaxPlugin(mathjaxConfig);
        const focusPlugin = createFocusPlugin();
        const alignmentPlugin = createAlignmentPlugin();
        const resizeablePlugin = createResizeablePlugin();
        const highlightPlugin = createHighlightPlugin();
        const textColorPlugin = createTextColorPlugin();
        const fontSizePlugin = createFontSizePlugin();

        const decorator = composeDecorators(
            resizeablePlugin.decorator,
            alignmentPlugin.decorator,
            focusPlugin.decorator,
        )

        const videoPlugin = createVideoPlugin({ decorator });
        const imagePlugin = createImagePlugin({ decorator });

        // Add plugins here to load into editor
        this.plugins = [
            imagePlugin,
            katexPlugin,
            mathjaxPlugin,
            focusPlugin,
            alignmentPlugin,
            resizeablePlugin,
            highlightPlugin,
            videoPlugin,
            textColorPlugin,
            fontSizePlugin,
        ];
    }

    componentDidUpdate(prevProps) {
        if (prevProps.content !== this.props.content) {
            if (this.props.content) {
                let getData = JSON.parse(this.props.content)
                const editorState = EditorState.createWithContent(convertFromRaw(getData));
                this.setState({ editorState })
            } else {
                const editorState = EditorState.createEmpty()
                this.setState({ editorState })
            }
        }
    }

    // Allows Mathjax to render in ReadOnly mode
    handleChange = (editorState) => {
        this.setState({ editorState })
    }

    focus = () => {
        this.editor.focus();
    }

    render() {
        const { editorState } = this.state;
        const { viewAble, style } = this.props;
        return (
            <div>
                <div className={viewAble && viewAble === true ? "student-section-editor-container" : "read-editor-container"}
                    onClick={this.focus} style={style}>
                    <Editor
                        editorState={editorState}
                        onChange={this.handleChange}
                        readOnly={true}
                        decorators={tipDecorator}
                        plugins={this.plugins}
                        ref={(element) => { this.editor = element; }} />
                </div>
            </div>
        );
    }
}

export default ReadOnlyEditor;
