import * as dbUtil from "../../api/dbUtil";
import {API} from '../../api';
import {
    RECEIVE_USER_ALL_SAT_TEST_SCORES,
    RECEIVE_USER_SAT_TEST_PROGRESS,
    RECEIVE_USER_SAT_TEST_PROGRESSES,
    RECEIVE_USER_SAT_TEST_SCORE,
    RESET_USER_SAT_TEST_PROGRESS
} from "../constant";
import { checkSessionExpired } from "..";

const receiveUserSatTestProgress = (data) => ({type: RECEIVE_USER_SAT_TEST_PROGRESS, data})
const userSatTestProgress = (data) => ({type: RECEIVE_USER_SAT_TEST_PROGRESS, data})
const userSatTestProgresses = (data) => ({type: RECEIVE_USER_SAT_TEST_PROGRESSES, data})
const receiveSubmitSatTest = (data) => ({type: RECEIVE_USER_SAT_TEST_PROGRESS, data})
const receiveResetUserSatTestProgress = (data) => ({type: RESET_USER_SAT_TEST_PROGRESS, data})
const receiveUserSatTestScore = (data) => ({type: RECEIVE_USER_SAT_TEST_SCORE, data})
const receiveUserSatAllTestScores = (data) => ({type: RECEIVE_USER_ALL_SAT_TEST_SCORES, data})

export const saveUserSatTestProgress = (attributes) => {
    return (dispatch) => {
        return dbUtil.create('user_sat_test_progresses', attributes).then(({data}) => {
            dispatch(receiveUserSatTestProgress(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getUserSatTestProgress = (attributes) => {
    return (dispatch) => {
        return dbUtil.fetchMany('user_sat_test_progresses/get_progress', attributes).then(({data}) => {
            dispatch(userSatTestProgress(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getUserAllSatsTestProgress = (attributes) => {
    return (dispatch) => {
        return dbUtil.fetchMany('user_sat_test_progresses/get_progresses', attributes).then(({data}) => {
            dispatch(userSatTestProgresses(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}


export const submitSatTest = (attributes) => {
    return (dispatch) => {
        return dbUtil.create('user_sat_test_progresses/submit', attributes).then(({data}) => {
            dispatch(receiveSubmitSatTest(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}


export const resetUserSatTestProgress = (params) => {
    return (dispatch) => {
        return API.delete('/user_sat_test_progresses/reset_progress', {params}).then(({data}) => {
            dispatch(receiveResetUserSatTestProgress(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

// ------------- End User All Sat Test Progress Actions -------------

// ------------- Start User All Sat Test Progress Score Actions -------------
export const getUserSatTestScores = (attributes) => {
    return (dispatch) => {
        return dbUtil.fetchMany('user_sat_test_progresses/get_sat_scores', attributes).then(({data}) => {
            dispatch(receiveUserSatTestScore(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}


export const getUserAllSatTestScores = (attributes) => {
    return (dispatch) => {
        return dbUtil.fetchMany('user_sat_test_progresses/get_all_sat_scores', attributes).then(({data}) => {
            dispatch(receiveUserSatAllTestScores(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

// ------------- End User All Sat Test Progress Score Actions -------------

