import * as dbUtil from '../../api/dbUtil';
import { API } from '../../api';
import {
    RECEIVE_ALL_ANSWERS, RECEIVE_ANSWER, RECEIVE_MULTIPLE_ANSWER, REMOVE_ANSWER, UPDATE_ANSWER
} from "../constant";
import { checkSessionExpired } from "..";

const receiveAll = (data) => ({ type: RECEIVE_ALL_ANSWERS, data });
const receive = (data) => ({ type: RECEIVE_ANSWER, data });
const receiveMultiple = (data) => ({ type: RECEIVE_MULTIPLE_ANSWER, data });
const updateAnswerData = (data) => ({ type: UPDATE_ANSWER, data });
const removeAnswer = (data) => ({ type: REMOVE_ANSWER, data });

export const createAnswer = (attributes) => {
    return (dispatch) => {
        return dbUtil.create('answers', attributes).then(({data}) => {
            dispatch(receive(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const createMultipleAnswer = (attributes) => {
    return (dispatch) => {
        return dbUtil.create('answers/create_multiple', attributes).then(({data}) => {
            dispatch(receiveMultiple(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getAllAnswer = (params) => {
    return (dispatch) => {
        return dbUtil.fetchMany('answers', params).then(({data}) => {
            dispatch(receiveAll(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateAnswer = (id, attributes) => {
    return (dispatch) => {
        return dbUtil.update('answers', id, attributes).then(({data}) => {
            dispatch(updateAnswerData(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateAnswerPositions = (answers) => {
    return (dispatch) => {
        return API.put("/answers/update_positions", answers).then(({data}) => {
            dispatch(receiveAll(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return answers;
        });
    }
}

export const deleteAnswer = (id) => {
    return (dispatch) => {
        return dbUtil.destroy('answers', id).then(({data}) => {
            dispatch(removeAnswer(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}
