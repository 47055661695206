import {
    RECEIVE_ALL_TEST_PROGS, RECEIVE_TEST_PROG, UPDATE_TEST_PROG, REMOVE_TEST_PROG
} from '../constant';

export const testProgReducer = (state = [], action) => {
    let newState;
    switch (action.type) {
        case RECEIVE_ALL_TEST_PROGS:
            return action.data;
        case RECEIVE_TEST_PROG:
            return Object.assign([], state, [...state, action.data])
        case UPDATE_TEST_PROG:
            newState = state.map(obj => (action.data.id === obj.id) ? action.data : obj);
            return newState;
        case REMOVE_TEST_PROG:
            newState = state.filter(obj => (action.data.id !== obj.id));
            return newState;
        default:
            return state;
    }
}
