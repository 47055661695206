import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddNewTopic from "../common/AddNewTopic";
import SATList from './SATList';
import * as satActions from '../../../../modules/sat/action';
import * as satTopicActions from '../../../../modules/satTopic/action';
import './AdminSATs.scss';

const mapStateToProps = state => {
    return ({
        sats: state.sats,
        satTopics: state.satTopics,
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        getSATs: () => dispatch(satActions.getAllTestSats()),
        createSAT: (attributes) => dispatch(satActions.createTestSat(attributes)),
        updateSAT: (id, attributes) => dispatch(satActions.updateTestSAT(id, attributes)),
        deleteSAT: (id) => dispatch(satActions.deleteTestSAT(id)),
        getSatTopics: () => dispatch(satTopicActions.getAllSatTopics()),
        publishUnPublishSat: (data) => dispatch(satActions.publishUnPublishSat(data)),
        updateScore: (data) => dispatch(satActions.updateSatScore(data)),
        updateSatProgressType: (sat) => dispatch(satActions.updateSatProgressType(sat)),
        updateSatsPositions: (sats) => dispatch(satActions.updateSatsPositions(sats)),
        updateBubbleAnswers: (data) => dispatch(satActions.updateSatBubbleAnswers(data))
    })
}

class AdminSATs extends Component {
    componentDidMount() {
        window.scrollTo(0,0);
        const { getSATs } = this.props;
        getSATs();
        // getSatTopics();
    }

    render() {
        const {
            sats, createSAT, deleteSAT, updateSAT, updateSatsPositions, publishUnPublishSat, updateScore,
            updateBubbleAnswers, updateSatProgressType
        } = this.props;
        return (
            <div className="row">
                <div className="content-wrapper-before gradient-45deg-indigo-purple"/>
                <div className="col s12">
                    <div className="container">
                        <div className="dash_top_section">
                            <div className="dash_header d-flex flex-wrap align-items-center">
                                <h2 className="dash_title mr-auto w-auto my-2">SATs</h2>
                                <AddNewTopic createTopic={createSAT} topicType={'SAT'}/>
                            </div>
                            <div className="row vertical-modern-dashboard">
                                <div className="col s12 satAct_content_sec">
                                    <SATList satTopicModal={false}
                                             satTopic={''}
                                             sats={sats}
                                             updateSAT={updateSAT}
                                             deleteSAT={deleteSAT}
                                             updateScore={updateScore}
                                             updateSatProgressType={updateSatProgressType}
                                             updateBubbleAnswers={updateBubbleAnswers}
                                             publishUnPublishSat={publishUnPublishSat}
                                             updateSatsPositions={updateSatsPositions} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminSATs);
