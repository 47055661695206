import React, { Component } from 'react';
import AdminSubSubtopic from './AdminSubSubTopic';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import "./AdminSubtopics.scss"

const DragHandle = SortableHandle(() =>
    <span className="sortable-handle">::</span>
)

const SortableItem = SortableElement(({
    subSubtopic, updateSubSubtopic, deleteSubSubtopic,satTopicModal, satTopic, updateAfterPublishUnpublish, mathTopic, actTopic
}) => {
    return (
        <li key={subSubtopic._id} className="sub-sub-topic-list-element">
            {satTopicModal === false && <DragHandle />}
            <AdminSubSubtopic
                updateSubSubtopic={updateSubSubtopic}
                deleteSubSubtopic={deleteSubSubtopic}
                subSubtopic={subSubtopic} 
                satTopicModal={satTopicModal}
                satTopic={satTopic}
                updateAfterPublishUnpublish = {updateAfterPublishUnpublish}
                mathTopic={mathTopic}
                actTopic={actTopic}
                />
        </li>
    )
})


const SortableList = SortableContainer(({
    subsubtopics, updateSubSubtopic, deleteSubSubtopic,satTopicModal, satTopic,updateAfterPublishUnpublish, mathTopic, actTopic
}) => {
    return (
        <ul className="sortable-admin-topic-list">
            {subsubtopics.map((subSubtopic, index) => {
                return (
                    <SortableItem
                        key={subSubtopic._id}
                        index={index}
                        subSubtopic={subSubtopic}
                        id={subSubtopic._id}
                        updateSubSubtopic={updateSubSubtopic}
                        deleteSubSubtopic={deleteSubSubtopic} 
                        satTopicModal={satTopicModal}
                        satTopic={satTopic}
                        updateAfterPublishUnpublish = {updateAfterPublishUnpublish}
                        mathTopic={mathTopic}
                        actTopic={actTopic}
                        />
                )
            })}
        </ul>
    )
})

class AdminSubSubtopics extends Component {

    state = {
        subsubtopics: [],
    }

    componentDidMount() {

        const { subsubtopics } = this.props;
        this.setState({ subsubtopics })
    }


    onSortEnd = async (options, mouseEvent, subsubtopics = this.props.subsubtopics) => {
        const { updateSubSubTopicPositions } = this.props;
        const { oldIndex, newIndex } = options;
        if(oldIndex === newIndex) return null;
        const updatedSubsubTopics = arrayMove(subsubtopics, oldIndex, newIndex)
        const updatedSubsubTopicData = {
            subsubtopics: updatedSubsubTopics,
            _id: updatedSubsubTopics[0].subtopic_id
        }
        const x = await updateSubSubTopicPositions(updatedSubsubTopicData);
        this.setState({ subsubtopics: x })
    }
    render() {
        const { subsubtopics, show, updateSubSubtopic, deleteSubSubtopic , satTopicModal, satTopic,updateAfterPublishUnpublish, mathTopic, actTopic} = this.props;
        if (!show || !subsubtopics) return null;

        return (
            <div className="sortable-topics">
                <SortableList
                    useDragHandle
                    onSortEnd={this.onSortEnd}
                    subsubtopics={subsubtopics}
                    updateSubSubtopic={updateSubSubtopic}
                    deleteSubSubtopic={deleteSubSubtopic} 
                    satTopicModal={satTopicModal}
                    satTopic={satTopic}
                    updateAfterPublishUnpublish = {updateAfterPublishUnpublish}
                    mathTopic={mathTopic}
                    actTopic={actTopic}
                    helperClass='sortableHelper'
                    lockAxis="y"
                    useWindowAsScrollContainer={true}
                    />
            </div>
        );
    }
}

export default AdminSubSubtopics;
