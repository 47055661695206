import {RECEIVE_ALL_TESTS, RECEIVE_TEST, UPDATE_TEST, REMOVE_TEST} from '../constant';

// Not considering creation of new test
export const testReducer = (state = [], action) => {
    let newState;
    switch (action.type) {
        case RECEIVE_ALL_TESTS:
            return Object.assign([], state, action.data)
        case RECEIVE_TEST:
            for(let i = 0; i < state.length; i++) {
                if(action.data.id === state[i].id) {
                    return state;
                }
            }
            return Object.assign([], state, [...state, action.data])
        case UPDATE_TEST:
            newState = state.map(obj => (action.data.id === obj.id) ? action.data : obj);
            return newState;
        case REMOVE_TEST:
            newState = state.filter(obj => (action.data.id !== obj.id));
            return newState;
        default:
            return state;
    }
}
