import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HomeSubtopicQuestions from '../question/HomeSubtopicQuestions';
import * as subtopicActions from '../../../../modules/subTopic/action';
import * as animationActions from '../../../../modules/animation/action'
import './HomeSubtopicMain.scss';
import TopicMainClass from "./TopicMainClass";
import * as dialogActions from "../../../../modules/dialog/action";

class HomeSubtopicMain extends TopicMainClass {

    state = {
        topicId: ""
    }

    async componentDidMount() {
        const { match: { params: { subtopicId, topicId } }, getSubtopic, topic_type } = this.props;
        let error404 = false, published;
        const subTopic = await getSubtopic(subtopicId).catch(error=>{
            error404 = error.response?.status === 404;
        });

        published = subTopic?.isPublish && subTopic?.isSequencePublish && subTopic?.topic_published;
        const isMatchTopicId =  topicId === subTopic?.test_id;
        if(error404 || !published || !isMatchTopicId || subTopic?.topic_type !== topic_type){
            return this.props.history.push('/home/not_found')
        }
        this.setState({topicId});
    }

    componentDidUpdate(prevProps) {
        const { currentQuestion } = this.props;
        if(prevProps.currentQuestion !== currentQuestion){
            this.setState({currentQuestion});
        }
    }

    takeBack = () => {
        const { location, history } = this.props;
        const { topicId } = this.state;
        const queryString = new URLSearchParams(location.search);
        const dashboard = !!(queryString && queryString.get('dashboard'))
        if (dashboard) {
            return history.push({pathname: '/home', params: { assignment: true}});
        }

        let appendString = topicId && topicId !== '' ? `?topic=${topicId}` : '';

        if ((location.pathname).includes('/home/math')) {
             history.push(`/home/math${appendString}`);
        } else if ((location.pathname).includes('/home/grammar')) {
             history.push(`/home/grammar${appendString}`);
        } else if ((location.pathname).includes('/home/reading')) {
             history.push(`/home/reading${appendString}`);
        }
    }

    render() {
        const { match: { params: { subtopicId, topicId } }, subtopics, topicType, topic_type } = this.props;

        let subtopic = ''
        if (subtopics && subtopics[topicId] && subtopics[topicId]) {
            subtopic = subtopics[topicId].find(dd => dd.id === subtopicId)
        }

        return (
            <this.ContainerView title={subtopic.title} showIcon={topic_type === "Topic"}>
                <HomeSubtopicQuestions topicType={topicType}
                                       topicId={subtopicId}
                                       section={topicType}
                                       takeBack={this.takeBack}/>
            </this.ContainerView>
        );
    }
}

const mapStateToProps = state => {
    return ({
        loading: state.animation,
        subtopics: state.subtopics,
        currentQuestion: state.temporaryData.currentQuestion
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        startAnimation: () => dispatch(animationActions.start()),
        endAnimation: () => dispatch(animationActions.end()),
        getSubtopic: (id) => dispatch(subtopicActions.getSubTopic(id)),
        openDialog: (config, options) => dispatch(dialogActions.open(config, options)),
    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeSubtopicMain));

