import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HomeSubsubtopicQuestions from '../question/HomeSubsubtopicQuestions';
import * as subSubTopicActions from '../../../../modules/subSubTopic/action';
import * as animationActions from '../../../../modules/animation/action'
import './HomeSubsubtopicMain.scss';
import TopicMainClass from "./TopicMainClass";
import * as dialogActions from "../../../../modules/dialog/action";

class HomeSubsubtopicMain extends TopicMainClass {

	async componentDidMount() {
		const { match: { params: { subsubtopicId, subtopicId } }, getSubSubtopic, topic_type } = this.props;
		let error404 = false;
		const subSubTopic = await getSubSubtopic(subsubtopicId).catch(error=>{
			error404 = error.response?.status === 404;
		});

		const published  = subSubTopic?.isPublish && subSubTopic?.isSequencePublish && subSubTopic?.subtopic_published && subSubTopic?.topic_published;
		const isMatchSubtopicId = subSubTopic?.subtopic_id === subtopicId;
		if(error404 || !published || subSubTopic?.topic_type !== topic_type || !isMatchSubtopicId){
			return this.props.history.push('/home/not_found')
		}
	}

	componentDidUpdate(prevProps) {
		const { currentQuestion } = this.props;
		if(prevProps.currentQuestion !== currentQuestion){
			this.setState({currentQuestion})
		}
	}

	takeBack = () => {
		const { location } = this.props
		const queryString = new URLSearchParams(this.props.location.search);
		const dashboard = !!(queryString && queryString.get('dashboard'))
		if (dashboard) {
			return this.props.history.push({pathname: '/home', params: { assignment: true}});
		}
		let appendString = ''
		if (queryString && queryString.get('topic') && queryString.get('subtopic') && queryString.get('subsubtopic')) {
			appendString = "?topic=" + queryString.get('topic')
				+ "&subtopic=" + queryString.get('subtopic')
				+ "&subsubtopic=" + queryString.get('subsubtopic')
		}

		if ((location.pathname).includes('/home/math')) {
			this.props.history.push('/home/math' + appendString)
		} else if ((location.pathname).includes('/home/grammar')) {
			this.props.history.push('/home/grammar' + appendString)
		} else if ((location.pathname).includes('/home/reading')) {
			this.props.history.push('/home/reading' + appendString)
		}
	}

	render() {
		const { match: { params: { subsubtopicId, subtopicId } }, subsubtopics, topicType, topic_type } = this.props;

		let subsubtopic = '';
		if (subsubtopics && subsubtopics[subtopicId] && subsubtopics[subtopicId]) {
			subsubtopic = subsubtopics[subtopicId].find(dd => dd._id === subsubtopicId)
		}

		return (
			<this.ContainerView title={subsubtopic.title} show={topic_type === "Topic"}>
				<HomeSubsubtopicQuestions topicType={topicType}
										  topicId={subsubtopicId}
										  section={topicType}
										  takeBack={this.takeBack}/>
			</this.ContainerView>
		);
	}
}

const mapStateToProps = state => {
	return ({
		loading: state.animation,
		subsubtopics: state.subsubtopics,
		currentQuestion: state.temporaryData.currentQuestion
	})
}

const mapDispatchToProps = dispatch => {
	return ({
		startAnimation: () => dispatch(animationActions.start()),
		endAnimation: () => dispatch(animationActions.end()),
		getSubSubtopic: (id) => dispatch(subSubTopicActions.getSubSubTopic(id)),
		openDialog: (config, options) => dispatch(dialogActions.open(config, options)),
	})
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeSubsubtopicMain));

