import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddNewTopic from '../common/AddNewTopic';
import ACTList from './ACTList';
import * as actActions from '../../../../modules/act/action';
import '../sat/AdminSATs.scss';


const mapStateToProps = state => {
    return ({
        acts: state.acts,
        // satTopics: state.satTopics,
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        createAct: (attributes) => dispatch(actActions.createTestAct(attributes)),
        getACTs: () => dispatch(actActions.getAllTestActs()),
        deleteACT: (id) => dispatch(actActions.deleteTestAct(id)),
        pubishOrUnpublishACT: (data) => dispatch(actActions.publishUnPublishAct(data)),
        updateScore: (data) => dispatch(actActions.updateActScore(data)),
        updateActProgressType: (act) => dispatch(actActions.updateActProgressType(act)),
        updateACT: (id, attributes) => dispatch(actActions.updateTestAct(id, attributes)),
        updateActsPositions: (acts) => dispatch(actActions.updateActsPositions(acts)),
        updateBubbleAnswers: (data) => dispatch(actActions.updateActBubbleAnswers( data)),
    })
}

class AdminACTs extends Component {
    async componentDidMount() {
        window.scrollTo(0,0);
        const { getACTs } = this.props;
        getACTs();
    }

    render() {
        const {
            acts, createAct, deleteACT, updateACT, updateActsPositions, pubishOrUnpublishACT, updateScore,
            updateBubbleAnswers, updateActProgressType
        } = this.props;
        return (
            <div className="row">
                <div className="content-wrapper-before gradient-45deg-indigo-purple"/>
                <div className="col s12">
                    <div className="container">
                        <div className="dash_top_section">
                            <div className="dash_header d-flex flex-wrap align-items-center">
                                <h2 className="dash_title mr-auto w-auto my-2">ACTs</h2>
                                <AddNewTopic createTopic={createAct} topicType={"ACT"} />
                            </div>
                            <div className="row vertical-modern-dashboard">
                                <div className="col s12 satAct_content_sec">
                                    <ACTList actTopicModal={false}
                                             actTopic={''}
                                             acts={acts}
                                             updateActProgressType={updateActProgressType}
                                             updateACT={updateACT}
                                             deleteACT={deleteACT}
                                             updateScore={updateScore}
                                             updateBubbleAnswers={updateBubbleAnswers}
                                             pubishOrUnpublishACT={pubishOrUnpublishACT}
                                             updateActsPositions={updateActsPositions} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminACTs);
