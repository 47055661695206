import React, { Component } from 'react';
import ReadOnlyEditor from '../editor/ReadOnlyEditor';

class BucketQuestion extends Component {
    render() {
        const { question } = this.props;
        return (
            <div>
                <ReadOnlyEditor content={question.question} />
                {question.is_free_response && <span className="text-success"> (Free Response) </span>}
            </div>
        );
    }
}

export default BucketQuestion;
