import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as dialogActions from '../../../../modules/dialog/action';
import * as alertActions from "../../../../modules/alert/action";
import './AdminUsers.scss';
import * as userActions from "../../../../modules/user/action";
import { Helmet } from "react-helmet";
import { ADD_USER_DIALOG_BOX, LINK_TUTOR_WITH_STUDENT_DIALOG_BOX } from "../../../dialogs/dialogs";
import { AdminUserList } from "./Admin";
import { StudentUserList } from "./Student";
import { TutorUserList } from "./Tutor";
import * as config from "../../../../config";

class AdminUsers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            serviceMessage: '',
            limit: config.tablePageLimit(),
            adminPageNo: 1,
            studentPageNo: 1,
            tutorPageNo: 1
        }

        this.openDialogBox = this.openDialogBox.bind(this);
        this.removeUserRole =this.removeUserRole.bind(this);
        this.addUserRole = this.addUserRole.bind(this);
        this.linkStudentWithTutor = this.linkStudentWithTutor.bind(this);
    }

    async componentDidMount() {
        window.scrollTo(0,0);
        const { account, history } = this.props;
        // this page is visible only if user is super admin
        if(account.userType !== "Super Admin") {
            return history.push('/admin/not_found');
        }

        await this.fetchAdmins();
        await this.fetchStudents();
        await this.fetchTutors();
    }

    fetchAdmins = async () =>{
        const { getAdmins } = this.props;
        const { limit, adminPageNo } = this.state;
        await getAdmins({ offset: adminPageNo, limit });
    }

    fetchStudents = async () =>{
        const { getStudents } = this.props;
        const { limit, studentPageNo } = this.state;
        await getStudents({ offset: studentPageNo, limit });
    }

    fetchTutors = async () =>{
        const { getTutors } = this.props;
        const { limit, tutorPageNo } = this.state;
        await getTutors({ offset: tutorPageNo, limit });
    }

    fetchAdminAndTutor = async () =>{
        await this.fetchTutors();
    }

    linkStudentWithTutor = (id, linked_users) => {
        const { openDialog, linkUsers } = this.props
        return openDialog(LINK_TUTOR_WITH_STUDENT_DIALOG_BOX, {
            title: `Link Tutor with Students`,
            tutorId: id,
            linkUsers,
            linked_users
        })
    }

    addUserRole = async ({id: userId, username}, role) => {
        const { addRole } = this.props;
        let res = window.confirm(`Are you sure you want to add as ${role}?`)
        if (res) {
            let data = await addRole({ user_id: userId, role: role });
            if(data){
                await this.fetchTutors();
            }
        }
    }

    removeUserRole = async ({id: userId, username}, role) => {
        const { removeRole } = this.props;
        let res = window.confirm(`Are you sure you want to remove as ${role}?`)
        if (res) {
            let data = await removeRole({ user_id: userId, role: role })
            if(data){
                await this.fetchTutors();
            }
        }
    }

    openDialogBox = (options) => {
        const { openDialog, alertPushMessage } =  this.props;
        return openDialog(ADD_USER_DIALOG_BOX, { flashMessage: alertPushMessage, status: true, ...options});
    }

    render() {
        const {
            admins, students, tutors, createAdmin, createStudent, createTutor, deleteAdmin, deleteStudent, deleteTutor,
            updateAdminStatus, updateStudentStatus, updateTutorStatus
        } = this.props;
        const { serviceMessage, limit, adminPageNo, studentPageNo, tutorPageNo } = this.state;
        return (
            <div className="row">
                <div className="content-wrapper-before gradient-45deg-indigo-purple"/>
                <div className="col s12">
                    <div className="container">
                        <div className="dash_top_section">
                            <h2 className="dash_title">Users</h2>
                            <div className="row vertical-modern-dashboard">
                                <div className="col s12 ">
                                    <div className="card animate fadeLeft">
                                        <div className="users_sec card-content p-0">
                                            {serviceMessage &&
                                            <div className="serviceMessage">
                                                <div className="errorCls errCommonCls">
                                                    {serviceMessage}
                                                </div>
                                            </div>
                                            }
                                            <>
                                                <div className="usersTabs_wrap">
                                                    <div className="col s12 p-0">
                                                        <ul className="tabs nav-tabs">
                                                            <li className="tab col nav-item"><a className="active" href="#tab1_admins">Admins</a></li>
                                                            <li className="tab col nav-item"><a href="#tab2_students">Students</a></li>
                                                            <li className="tab col nav-item"><a href="#tab3_tutors">Tutors</a></li>
                                                        </ul>
                                                    </div>
                                                    <div id="tab1_admins" className="col s12 tabs_content">
                                                        <AdminUserList admins={admins}
                                                                       createAdmin={createAdmin}
                                                                       fetchAdmins={this.fetchAdmins}
                                                                       openDialogBox={this.openDialogBox}
                                                                       addUserRole={this.addUserRole}
                                                                       limit={limit}
                                                                       pageNo={adminPageNo}
                                                                       changePageNo={(adminPageNo)=> this.setState({adminPageNo})}
                                                                       deleteAdmin={deleteAdmin}
                                                                       updateAdminStatus={updateAdminStatus}/>
                                                    </div>
                                                    <div id="tab2_students" className="col s12 tabs_content">
                                                        <StudentUserList students={students}
                                                                         createStudent={createStudent}
                                                                         fetchStudents={this.fetchStudents}
                                                                         fetchTutors={this.fetchTutors}
                                                                         openDialogBox={this.openDialogBox}
                                                                         limit={limit}
                                                                         pageNo={studentPageNo}
                                                                         changePageNo={(studentPageNo)=> this.setState({studentPageNo})}
                                                                         deleteStudent={deleteStudent}
                                                                         updateStudentStatus={updateStudentStatus}/>
                                                    </div>
                                                    <div id="tab3_tutors" className="col s12 tabs_content">
                                                        <TutorUserList tutors={tutors}
                                                                       createTutor={createTutor}
                                                                       fetchAdmins={this.fetchAdmins}
                                                                       fetchTutors={this.fetchTutors}
                                                                       openDialogBox={this.openDialogBox}
                                                                       removeUserRole={this.removeUserRole}
                                                                       linkStudentWithTutor={this.linkStudentWithTutor}
                                                                       limit={limit}
                                                                       pageNo={tutorPageNo}
                                                                       changePageNo={(tutorPageNo)=> this.setState({tutorPageNo})}
                                                                       deleteAdmin={deleteAdmin}
                                                                       updateAdminStatus={updateAdminStatus}
                                                                       deleteTutor={deleteTutor}
                                                                       updateTutorStatus={updateTutorStatus}/>
                                                    </div>
                                                </div >
                                                <Helmet>
                                                    <script src={`${config.appUrl()}/js/custom-script.min.js`}/>
                                                </Helmet>
                                            </>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = state => {
    return ({
        account: state.account,
        admins: state.users.admins,
        students: state.users.students,
        tutors: state.users.tutors
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        createAdmin: (attributes) => dispatch(userActions.createAdmin(attributes)),
        createTutor: (attributes) => dispatch(userActions.createTutor(attributes)),
        createStudent: (attributes) => dispatch(userActions.createStudent(attributes)),

        getAdmins: (params) => dispatch(userActions.getAllAdmins(params)),
        getStudents: (params) => dispatch(userActions.getAllStudents(params)),
        getTutors: (params) => dispatch(userActions.getAllTutors(params)),

        deleteAdmin: (id) => dispatch(userActions.destroyAdmin(id)),
        deleteStudent: (id) => dispatch(userActions.destroyStudent(id)),
        deleteTutor: (id) => dispatch(userActions.destroyTutor(id)),

        updateAdminStatus: (id, attributes) => dispatch(userActions.updateAdminStatus(id, attributes)),
        updateStudentStatus: (id, attributes) => dispatch(userActions.updateStudentStatus(id, attributes)),
        updateTutorStatus: (id, attributes) => dispatch(userActions.updateTutorStatus(id, attributes)),

        linkUsers: (params) => dispatch(userActions.linkUsers(params)),
        addRole: (params) => dispatch(userActions.addRole(params)),
        removeRole: (params) => dispatch(userActions.removeRole(params)),

        openDialog: (config, options) => dispatch(dialogActions.open(config, options)),
        alertPushMessage: (payload) => dispatch(alertActions.alertPushMessage(payload)),
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsers);
