import React, {Component} from "react";
import SVG from "../../../../SVG";

class BubbleSheet extends Component {

    constructor(props) {
        super(props);
        const { topicType, topic, bubbleSheetAnswer, wrongSelectedQuestions, questionType } = props;
        let questionTypes = ['Reading', 'Writing', 'MathNoCalc', 'MathCalc', 'Writing And Language', 'Math No Calc', 'Math Calc'];
        let bubbleSheetAnswerRecord =  { 'Reading': {}, 'Writing': {}, 'MathNoCalc': {}, 'MathCalc': {} };
        if(topicType === 'ACT') {
            bubbleSheetAnswerRecord =  { 'English': {}, 'Mathematics': {}, 'Reading': {}, 'Science': {} };
            questionTypes = ['English', 'Mathematics', 'Reading', 'Science', 'Math'];
        }

        const submitButtonTitle = 'Submit';
        // const submitButtonTitle = bubbleSheetAnswer[topicType] === {} ? 'Submit' : 'Re-Submit';

        if (topic?.bubble_sheet_answers?.length > 0) {
            bubbleSheetAnswerRecord = bubbleSheetAnswers({
                parsedData: topic.bubble_sheet_answers, bubbleSheetAnswerRecord, topicType, questionTypes
            });
        }

        let ulColumn = 5;
        if(topicType === 'SAT' && questionType === 'Reading') {
            ulColumn = 4;
        }

        this.state = {
            bubbleSheetAnswer, wrongSelectedQuestions, bubbleSheetAnswerRecord, submitButtonTitle, ulColumn
        };
    }

    bubbleOptions({options = 4, startOptionArray = ['A','B','C','D','E']}) {
        let result = [];
        for(let i = 0; i < options; i++ ){
            result.push(startOptionArray[i]);
        }
        return result;
    }

    setAnswerRecords = async () =>{
        const { questionType, topicType, bubbleSheetAnswer, questions, submitBubbleSheet } = this.props;
        let { wrongSelectedQuestions } = this.props;
        await questions?.map((question, index) => {
            const questionNumber = index + 1;
            const value = bubbleSheetAnswer[questionType][`${questionNumber}`];
            let { bubbleSheetAnswerRecord } =  this.state, correct = false;
            if(topicType === 'SAT' && ((questionType === 'MathNoCalc' && questionNumber >= 16) || (questionType === 'MathCalc' && questionNumber >= 31))){
                if(checkFreeResponseSheetAnswer(value, bubbleSheetAnswerRecord[questionType][`${questionNumber}`])){
                    correct = true;
                }
            } else {
                if(bubbleSheetAnswerRecord[questionType][`${questionNumber}`] && bubbleSheetAnswerRecord[questionType][`${questionNumber}`][0] === value){
                    correct = true;
                }
            }

            if (wrongSelectedQuestions[questionType].length === 0) {
                if(correct === false){
                    wrongSelectedQuestions[questionType].push(question.id)
                }
            } else {
                let checkValue = wrongSelectedQuestions[questionType].includes(question.id);
                if (checkValue && correct === true) {
                    wrongSelectedQuestions[questionType] = wrongSelectedQuestions[questionType].filter(item => item !== question.id)
                } else if (checkValue === false && correct === false) {
                    wrongSelectedQuestions[questionType].push(question.id)
                }
            }
        });
        return submitBubbleSheet({questionType, bubbleSheetAnswer, wrongSelectedQuestions });
    }

    RenderSheetQuestion = ({ questionNumber }) =>{
        const { questionType, topicType, bubbleSheetAnswer, account } = this.props;

        if(topicType === 'SAT' && ((questionType === 'MathNoCalc' && questionNumber >= 16) || (questionType === 'MathCalc' && questionNumber >= 31))){
            let value = bubbleSheetAnswer[questionType][`${questionNumber}`]
            return (
                <div className={"free-response-input-span"}>
                    <input value={value ?? ''}
                           disabled={!!account.tutor_login}
                           onChange={(event) => {
                               this.setBubbleSheetAnswers({questionNumber, value: event.target.value});
                           }}/>
                </div>
            )
        }

        let options = 4 , startOptionArray = ['A', 'B', 'C', 'D', 'E'];
        if(topicType === 'ACT'){
            options = questionType === 'Mathematics' ? 5 : 4;
            startOptionArray = (questionNumber % 2 === 0) ? ['F', 'G', 'H', 'J', 'K'] : ['A', 'B', 'C', 'D', 'E'];
        }

        const bubbleOptions = this.bubbleOptions({options, startOptionArray});
        return bubbleOptions.map((key) => {
            let selectName = bubbleSheetAnswer[questionType][`${questionNumber}`]
            return (
                <span className={`options ${selectName === key ? 'selected-bubble' : ''}`}
                      key={key}
                      onClick={() => this.setBubbleSheetAnswers({questionNumber, value: key}) }>
                    {key}
                </span>
            )
        })
    }

    setBubbleSheetAnswers = ({ questionNumber, value }) => {
        const { questionType, account } = this.props;
        if(!!account.tutor_login) return null;
        let bubbleSheetAnswer = this.state.bubbleSheetAnswer;
        bubbleSheetAnswer[questionType][`${questionNumber}`] = value;
        this.setState({bubbleSheetAnswer});
    }

    submit = () =>{
        const { account } = this.props;
        if(!!account.tutor_login){
            return window.alert("You don't have permission to submit");
        }
        let strConfirm = window.confirm("Are you sure you want to submit?");
        if (strConfirm !== true) return true;
        return this.setAnswerRecords();
    }

    cancel = () =>{
        if(this.props.bubbleSheetAnswer !== this.state.bubbleSheetAnswer){
            let strConfirm = window.confirm("Are you sure you want to cancel?");
            if (strConfirm !== true) return true;
        }
        const { questionType, selectBubbleSheet } = this.props;
        return selectBubbleSheet(questionType);
    }

    render() {
        const { account, questions, questionType, gradeSubmitted, selectWrong, correct } = this.props;
        const { submitButtonTitle, ulColumn } = this.state;
        return (
        <div className={'ques_set marginBottom20 selectSectionCls bubble-sheet-div'}>
            {
                !!account.tutor_login ?
                    <>
                        <SVG name="close" className="close" onClick={() => this.cancel()} />
                    </>
                    :
                    <div className="submitCancelCls d-flex w-100 justify-content-center position-relative">
                        <button onClick={() => this.submit()}
                                className="btn btn-light btn-default m-2">
                            <i className="fa fa-check text-success mr-1"/>{submitButtonTitle}
                        </button>
                        <button onClick={() => this.cancel()}
                                className="btn btn-light btn-default m-2">
                            <i className="fa fa-times text-danger mr-1"/>Cancel
                        </button>
                    </div>
            }

            <h3 className="satQuesIdTitleCls d-flex align-items-center mb-2">
                {`${questionType}:`}
                <span className="scoreOfHeadersCls mx-2 mt-1">
                    {correct && (gradeSubmitted[questionType] || selectWrong[questionType]) ? correct + '/' : '__/'}
                    {questions.length}
                </span>
            </h3>
            <ul className={`bubble-sheet-ul ul-column-${ulColumn}`}>
                {
                    questions?.map((question, index) => {
                        const {id,position} = question;
                        return (
                            <li className={'bubbleSheet'} key={id} id={`T${id}`}>
                                <span className="textShowIndCls" key={id} style={{minWidth: 30}}>{position + 1}</span>
                                <this.RenderSheetQuestion questionNumber={index + 1}/>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
        )
    }
}

export default BubbleSheet;


function bubbleSheetAnswers({parsedData,bubbleSheetAnswerRecord, topicType, questionTypes}) {
    let key = '';
    try{
        parsedData && parsedData.forEach(data => {
            if(data.length){
                const dataTitle = validateName(data[0]);
                if (questionTypes.includes(dataTitle) && topicType === 'SAT'){
                    switch (dataTitle) {
                        case 'Writing':
                        case 'Writing And Language': {
                            key = 'Writing'
                            break;
                        }
                        case 'MathNoCalc':
                        case 'Math No Calc': {
                            key = 'MathNoCalc'
                            break;
                        }
                        case 'MathCalc':
                        case 'Math Calc': {
                            key = 'MathCalc'
                            break;
                        }

                        default: {
                            key = 'Reading'
                            break;
                        }
                    }
                } else if (questionTypes.includes(dataTitle) && topicType === 'ACT'){
                    switch (dataTitle) {
                        case 'English': {
                            key = 'English'
                            break;
                        }
                        case 'Math':
                        case 'Mathematics': {
                            key = 'Mathematics'
                            break;
                        }
                        case 'Science': {
                            key = 'Science'
                            break;
                        }
                        default: {
                            key = 'Reading'
                            break;
                        }
                    }
                } else {
                    bubbleSheetAnswerRecord[`${key}`][`${data[0]}`] = [];
                    for(let i = 1; i < data.length; i++){
                        if(data[i]){
                            bubbleSheetAnswerRecord[`${key}`][`${data[0]}`].push(data[i]);
                        }
                    }
                }
            }
        });
    } catch (e){

    }

    return bubbleSheetAnswerRecord;
}

function validateName(str){
    if (!str) return '';
    return str.split(' ').map(function(word){
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join(' ');
}

function checkFreeResponseSheetAnswer(value, option){
    if (value && option) {
        let getCorrect = false, breakCheck = false;
        option.forEach(answer => {
            if (breakCheck === false) {
                let ans = false;
                if(answer.includes("-") || answer.includes('–')){
                    ans = validateInRange(value, answer);
                } else {
                    ans = checkCorrectOnFreeResponseAnswers(value, answer)
                }
                if (ans) {
                    getCorrect = ans;
                    breakCheck = true;
                }
            }
        })

        return getCorrect;
    }

    return false
}

const checkCorrectOnFreeResponseAnswers = (valAnswer, freeResponseAnswer) => {
    let sendRes = false, trimedValAnswer = valAnswer.trim()
    if (!(trimedValAnswer).startsWith(",") && !(trimedValAnswer).endsWith(",")) {
        let val = replaceAll(trimedValAnswer, ',', '')
        let calculatedFreeResponseAnswer = freeResponseAnswer && evaluate(freeResponseAnswer)
        if (val === freeResponseAnswer) {
            return true
        } else if (evaluate(val) === calculatedFreeResponseAnswer){
            return true
        }
    }
    return sendRes;
}

const validateInRange = (valAnswer,option) =>{
    let result = false;
    try {
        option = option.includes("-") ? option.split('-') :  option.split('–');
        result = ((Number(valAnswer) >= Number(option[0])) && (Number(valAnswer) <= Number(option[1])));
    } catch (e) {

    }
    return result;
}

const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

/* Define functin to find and replace specified term with replacement string */
const replaceAll = (str, term, replacement) => {
    return str.replace(new RegExp(escapeRegExp(term), 'g'), replacement);
}


const evaluate = (val) =>{
    let calculateAns = val
    if (val.includes('+') || val.includes('-') ||  Number.isNaN(parseFloat(calculateAns))){
        return val;
    } else if (val.includes('/') && (val.split('/').length === 2)) {
        let splitVal = val.split('/')
        if (splitVal[0] !== '' && splitVal[1] !== '') {
            calculateAns = splitVal[0] / splitVal[1]
        }
    } else if (val.includes('*') && (val.split('*').length === 2)) {
        let splitVal = val.split('*')
        let res = splitVal.every((e) => parseFloat(e))
        if (res) {
            let validArr = splitVal.map((e) => parseFloat(e))
            calculateAns = multiply(validArr)
        }
    }
    return  parseFloat(parseFloat(calculateAns).toFixed(2))
}

export const multiply = (array) => {
    var sum = 1;
    for (var i = 0; i < array.length; i++) {
        sum = sum * array[i];
    }
    return sum;
}
