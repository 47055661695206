import React, { Component } from 'react';
import { connect } from 'react-redux';
import Editor from '../../../../editor/Editor';
import * as questionActions from '../../../../../modules/question/action';
import * as problemSetQuestionActions from '../../../../../modules/problemSetQuestion/action';
import Switch from 'react-switch';

const mapDispatchToProps = dispatch => {
    return ({
        updateQuestion: (id, attributes) => dispatch(questionActions.updateQuestion(id, attributes)),
        updateSubsubTopicQuestion: (id, attributes) => dispatch(questionActions.update('question_subsubtopics', id, attributes)),
        updateProblemSetQuestion: (id, attributes) => dispatch(problemSetQuestionActions.updateProblemSetQuestion(id, attributes)),

    })
}

class EditFlexEditor extends Component {
    constructor(props){
        super(props)
        this.state = {
            editorState: {},
            showExpBeforeAnswerOptions: props.question.exp_position_before_answer
        }
    
    }


    updateQuestion = async (e) => {
        e.preventDefault();
        const {
            updateQuestion, question, toggleForm, updateContent, contentType, updateProblemSetQuestion,
            updateSubsubTopicQuestion, flashMessage
        } = this.props;
        const { editorState, showExpBeforeAnswerOptions } = this.state;
        const data = { [contentType]: editorState, exp_position_before_answer: showExpBeforeAnswerOptions};
        // let updatedQuestion
        if (question.problem_set_id) {
            // updatedQuestion = await updateProblemSetQuestion(question.id, data);
            await updateProblemSetQuestion(question.id, data);
        } else if (question.subsubtopic_id) {
            // updatedQuestion = await updateSubsubTopicQuestion(question.id, data);
            await updateSubsubTopicQuestion(question.id, data);
        } else {
            // updatedQuestion = await updateQuestion(question.id, data);
            await updateQuestion(question.id, data);
        }
        switch (contentType) {
            case "help": {
                flashMessage({message: `Question Help updated`, type: 'success'})
                break;
            }
            case "help_exp": {
                flashMessage({message: `Help Explanation updated`, type: 'success'})
                break;
            }
            case "question_exp": {
                flashMessage({message: `Question Explanation updated`, type: 'success'})
                break;
            }
            default:
                break;
        }
        // postQuestionUpdate(updatedQuestion)
        updateContent(editorState);
        toggleForm();
    }

    updateEditorState = (editorState) => {
        this.setState({ editorState })
    }

    toggleExpPosition = () => {
        const { showExpBeforeAnswerOptions } = this.state;
        this.setState({ showExpBeforeAnswerOptions: !showExpBeforeAnswerOptions })
    }

    // Allow update of Calc or No-Calc questions
    renderExpPositionSwitch() {
        const { showExpBeforeAnswerOptions } = this.state;
        return (
            <div className="linkedques_container">
                <label className="mr-2">Show before answers options</label>
                <Switch
                checked={showExpBeforeAnswerOptions}
                onChange={this.toggleExpPosition}
                uncheckedIcon={<i className="fa fa-times switchIconCls" />}
                checkedIcon={<i className="fa fa-check switchIconCls" />}
                onColor="#28a745"
                height={25}
                width={50}
                handleDiameter={15}/>
            </div>
            
        )
    }

    render() {
        const { toggleForm, content, contentType} = this.props;
        return (
            <div className="edit-question">
                <Editor content={content} updateEditorState={this.updateEditorState} />
                <div className="ques_attributes">
                    {contentType==="question_exp" && this.renderExpPositionSwitch()}
                    <div className="buttons btn_grup">
                        <button className="btn btn-success" onClick={this.updateQuestion}>Save</button>
                        <button className="btn btn-danger" onClick={() => toggleForm()}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(null, mapDispatchToProps)(EditFlexEditor);
