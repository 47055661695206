import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import * as reportProblemActions from '../../../../modules/reportedProblem/action'
import './ReportedProblems.scss';
import {PaginationFooter} from "../../../../utilities/admin.paginationFooter";
import PaginationLimit from "../../../../utilities/admin.paginationLimit";

class ReportedProblems extends Component {

    constructor(props) {
        super(props);
        this.state = {
            offset: 1,
            limit: 10
        }
    }

    async componentDidMount() {
        const { getReportProblem, account, history } = this.props;
        if(account.is_super_admin){
            const { limit } = this.state;
            await getReportProblem({ offset: 1, limit });
        } else {
            return history.push('/admin/not_found');
        }
    }

    componentDidUpdate() {
        const {reportedProblems} = this.props;
        if (reportedProblems && reportedProblems.length > 0) {
            const { pageNo, limit } = this.state;
            let startIndex = ((pageNo - 1) * limit)
            if (reportedProblems.length <= startIndex) {
                this.setState({pageNo: pageNo - 1})
            }
        }
    }

    openUrl = (url) =>{
        this.props.history.push(url);
    }

    fetchReportProblem = async ({offset, limit}) =>{
        const { getReportProblem } = this.props;
        await getReportProblem({ offset, limit });
        this.setState({offset,limit})
    }

    render() {
        const { limit, offset } =  this.state;
        const { reportedProblems } =  this.props;
        return (
            <div className="row">
                <div className="content-wrapper-before gradient-45deg-indigo-purple"/>
                <div className="col s12">
                    <div className="container">
                        <div className="dash_top_section">
                            <h2 className="dash_title">Reported Problems</h2>
                            <div className="row vertical-modern-dashboard">
                                <div className="col s12">
                                    <div className="card animate fadeLeft">
                                        <div className="users_sec card-content p-2 pt-4 pb-4">
                                            <div className="col s12 tabs_content">
                                                <div className="right-align">
                                                    <PaginationLimit changeLimit={(limit) => this.fetchReportProblem({offset: 1, limit})}
                                                                     totalRecord={reportedProblems.count}
                                                                     limit={limit}/>
                                                </div>
                                                <div className="col s12 p-0 dataTables_wrapper table-responsive">
                                                    <table id="pagedata_table" className="reported-problem-table dataTable display admins_table m-0">
                                                        <thead>
                                                        <tr>
                                                            <th>Student</th>
                                                            <th>Error Type</th>
                                                            <th>Comment</th>
                                                            <th>Question Type</th>
                                                            <th>Question/ProblemSet Title</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <ProblemList reportedProblems={reportedProblems} openUrl={this.openUrl}/>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="appCus_pagination d-inline-flex w-100">
                                                    {
                                                        reportedProblems.count > 0 &&
                                                        <PaginationFooter getPageData={(offset) => this.fetchReportProblem({offset, limit})}
                                                                          pageNo={offset}
                                                                          limitChangeAble={true}
                                                                          totalRecords={reportedProblems.count}
                                                                          limit={limit}/>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const ProblemList = ({reportedProblems: {data, count}, openUrl}) =>{

    if(count > 0){
        return data.map((problem,index)=>{
            const {error_problem, comment, student_email, question_data: {type, title, url, error} } = problem;
            return(<tr key={index}>
                <td>{student_email}</td>
                <td className={"justify-content-start"}>
                    <ul>
                        {error_problem && error_problem.map((error, index)=>{
                            return <li key={error}><b>{index + 1})</b> {error}</li>
                        })}
                    </ul>
                </td>
                <td>{comment}</td>
                {
                    error ?
                        <>
                            <td colSpan={3}>{error}</td>
                        </>
                        :
                        <>
                            <td>{type}</td>
                            <td>{title}</td>
                            <td>
                                <button onClick={() => openUrl(url)} className="btn btn-default btn-sm"><span className="btntxt">View</span></button>
                            </td>
                        </>
                }
            </tr>);
        })

    } else {
        return(<tr><td colSpan='5'><span className={"d-flex align-items-center justify-content-center"}>No Record Found</span></td></tr>);
    }
}

const mapStateToProps = state => {
    return ({
        account: state.account,
        reportedProblems: state.reportedProblems,
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        getReportProblem: (params) => dispatch(reportProblemActions.getAllReportedProblems(params))
    })
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportedProblems));