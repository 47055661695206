import * as config from "../../config";
import {ALERT_DELETE, ALERT_DELETE_BY_INDEX, ALERT_PUSH} from "../constant";

export const alertPush = (payload) => {
    return ({
        type: ALERT_PUSH,
        payload,
    })
}

export const alertDelete = () => ({
    type: ALERT_DELETE,
});

export const alertDeleteByIndex = (index) => ({
    type: ALERT_DELETE_BY_INDEX,
    index,
});

export const alertPushMessage = (payload) => {
    return (dispatch) => {
        dispatch(alertPush(payload));
        setTimeout(()=>dispatch(alertDelete()), config.msAlertDisplayTime());
    }
}

