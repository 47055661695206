import React, {Component} from 'react';
import {Route, withRouter} from 'react-router-dom';
import TopicContainer from './TopicContainer';

class TopicEnvironment extends Component {
    render() {
        return (
            <div className="topic-env">
                <Route path='/topic/:topicId/question/:questionId?' exact
                       component={TopicContainer}/>
            </div>
        );
    }
}

export default withRouter(TopicEnvironment);
