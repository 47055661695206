import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReadOnlyEditor from '../../../editor/ReadOnlyEditor';
import * as answerActions from '../../../../modules/answer/action';
import * as animationActions from '../../../../modules/animation/action';
import * as accountActions from '../../../../modules/account/action';
import './ViewQuestions.scss';
import _ from 'lodash';
import { IN_A_ROW_CORRECT_COUNT } from '../../../../constants';
import { checkCorrectOnFreeResponseAnswers } from '../../../../utilities/custom'
import IfView from "../../../helper/IfView";
import { FreeResponseWithExplanation, getFreeResponseAnswers, QuestionView, ViewFullExplanation } from "./CommonQuestionView";
import ReportQuestionClass from "./ReportQuestionClass";
import IfElseView from "../../../helper/IfElseView";
import * as alertActions from "../../../../modules/alert/action";
import * as commonRecords from "../../../../modules/storeTemporary/action";
import * as userProgressActions from "../../../../modules/userProgress/action";
import * as assignmentActions from "../../../../modules/assignment/action";

class ViewQuestions extends ReportQuestionClass {

    constructor(props) {
        super(props)
        const {firstQuestion, account, userProgress, userProgressQuestion } = props;
        const accountProgress = account?.progress;
        const insideQuestions = !(_.has(account, 'progress.next_problemset_id') && accountProgress?.next_problemset_id);
        let nextLinkQuestionId = '', questions_complete = userProgress?.questions_complete ?? 0;
        if (!insideQuestions){
            nextLinkQuestionId = userProgressQuestion && firstQuestion ? firstQuestion.id : '';
        }

        this.state = {
            viewQuestion: firstQuestion ?? {},
            insideQuestions: insideQuestions,
            questions_complete: questions_complete,
            satSequenceCorrectAnswers: accountProgress?.total_correct ?? 0,
            time_spent_before_answer_for_sequence: accountProgress?.time_spent_before_answer ?? 0,
            time_spent_after_answer_for_sequence:  accountProgress?.time_spent_after_answer ?? 0,
            time_spent_before_answer_for_problemset: 0,
            time_spent_after_answer_for_problemset: 0,
            problemSetCorrectAnswers: 0,
            free_response_answer_value: '',
            seconds: 0,
            idleTime: 0,
            view_full_explanation: false,
            showExplanation: false,
            viewProblemSetQuestion: '',
            answersGiven: [],
            getAnswersValue: '',
            nextLinkQuestionId: nextLinkQuestionId,
            skipProblemSetIfComplete: true,
            problemSetId: ''
        }

    }

    async componentDidMount() {
        super.componentDidMount();
        const { getAnswers, startAnimation, endAnimation } = this.props;
        const { viewQuestion } = this.state;
        if (viewQuestion) {
            this.setCurrentQuestion(viewQuestion);
            this.idleInterval = setInterval(() => this.checkIdealTime(), 1000);
            this.timer = setInterval(() => this.counter(), 1000);
            await startAnimation();
            await getAnswers({ params: { question_id: viewQuestion.id } });
            await endAnimation();
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.props.clearCurrentQuestion()
    }

    async componentDidUpdate(props, prevState, snapshot) {
        const {
            getAnswers, startAnimation, endAnimation, problemset_questions, account, resetUserProblemsetProgress
        } = this.props;
        const classObject = this;
        if (problemset_questions.length > 0 && problemset_questions !== props.problemset_questions) {
            const { skipProblemSetIfComplete, nextLinkQuestionId, problemSetId } =  this.state;
            let { problemSetQuestion, problemSetCorrectAnswers } = this.getProblemSetProgress();
            if(problemSetCorrectAnswers >= IN_A_ROW_CORRECT_COUNT && skipProblemSetIfComplete === true){
                this.setState({
                    viewProblemSetQuestion: '',
                    getAnswersValue: '',
                    insideQuestions: true,
                    problemSetCorrectAnswers: problemSetCorrectAnswers,
                    showExplanation: false,
                    free_response_answer_value: '',
                    view_full_explanation: false,
                }, async () => {
                    if(this.checkNextQuestionExist()){
                        await this.fetchNextSequenceQuestion(nextLinkQuestionId);
                    } else {
                        await this.sequenceComplete();
                    }
                })
            } else {
                problemSetCorrectAnswers = problemSetCorrectAnswers < IN_A_ROW_CORRECT_COUNT ? problemSetCorrectAnswers : 0;
                problemSetQuestion = this.checkAndSkipProblemSetQuestion(problemSetQuestion, problemset_questions);
                this.setState({
                    insideQuestions: false,
                    viewProblemSetQuestion: problemSetQuestion,
                    problemSetCorrectAnswers,
                    answersGiven: [],
                    getAnswersValue: '',
                    free_response_answer_value: ''
                }, async () => {
                    startAnimation()
                    if(problemSetCorrectAnswers === 0){
                        await resetUserProblemsetProgress({ user_id: account.id, reference_type: 'HomeProblemSetQuestions', reference_id: problemSetId });
                    }
                    await getAnswers({ params: { question_id: problemSetQuestion.id } })
                    endAnimation();
                })
            }
        }
    }

    sequenceComplete = async () =>{
        const { topicType, topicId, account, satQuestions, questions, actQuestions, saveUserProgress } = this.props;
        // const { time_spent_after_answer_for_sequence } = this.state;
        let userProgress = {
            user_id: account.id,
            reference_type: topicType,
            reference_id: topicId,
            question_id: null,
            total_questions: questions.length,
            questions_complete: questions.length,
            started: false,
            percent_complete: 100,
            sat_id: satQuestions?.sat_id,
            // time_spent_after_answer: time_spent_after_answer_for_sequence,
            act_id: actQuestions?.act_id,
        }
        await saveUserProgress(userProgress);
        await this.redirectAfterComplete();
    }

    fetchNextSequenceQuestion = async (id) => {
        const { questions, getAnswers, startAnimation, endAnimation } = this.props;
        const { time_spent_after_answer_for_sequence, seconds } = this.state;
        const nextQuestion = questions.filter((question) => question.id === id)[0]
        let questions_complete = this.state.questions_complete + 1;
        this.setCurrentQuestion(nextQuestion);
        this.setState({
                viewQuestion: nextQuestion, answersGiven: [], questions_complete,
                getAnswersValue: '', showExplanation: false, free_response_answer_value: '', seconds: 0,
                time_spent_after_answer_for_sequence: time_spent_after_answer_for_sequence + seconds,
                view_full_explanation: false
            },
            async () => {
                if (nextQuestion) {
                    // used a unary plus to convert a string to a number in percent_complete
                    await startAnimation()
                    await getAnswers({ params: { question_id: nextQuestion.id } })
                    await endAnimation()
                }
            })
    }

    nextSequenceQuestion = async (id) => {
        const { state, props } = this;
        const { questions, getAnswers, startAnimation, endAnimation, saveUserProgress, saveUserQuestionsProgress } = props;
        const { time_spent_after_answer_for_sequence, seconds } = state;
        const nextQuestion = questions.filter((question) => question.id === id)[0]
        let questions_complete = this.state.questions_complete + 1;

        this.setCurrentQuestion(nextQuestion);
        this.setState({
                viewQuestion: nextQuestion, answersGiven: [], questions_complete,
                getAnswersValue: '', showExplanation: false, free_response_answer_value: '', seconds: 0,
                time_spent_after_answer_for_sequence: time_spent_after_answer_for_sequence + seconds,
                view_full_explanation: false
            },
            async () => {
                if (nextQuestion) {
                    // save user progress
                    // used a unary plus to convert a string to a number in percent_complete
                    const {userProgress, userQuestionParams} = this.nextQuestionParams({questions_complete, nextQuestion, state});
                    await startAnimation()
                    await saveUserProgress(userProgress);
                    await saveUserQuestionsProgress(userQuestionParams);
                    await getAnswers({ params: { question_id: nextQuestion.id } })
                    await endAnimation()
                }
            })
    }

    fetchNextProblemSetQuestion = async (problemSetId) => {
        const { account, startAnimation, getUserProblemsetProgress, getProblemSetQuestions, endAnimation, saveUserQuestionsProgress, topicType, topicId, satQuestions, actQuestions } = this.props
        const { seconds, viewQuestion, getAnswersValue, answersGiven, free_response_answer_value } = this.state
        let userQuestionParams = {
            id: account?.userQuestionProgress?._id,
            user_id: account.id,
            question_id: viewQuestion.id,
            reference_type: topicType,
            reference_id: topicId,
            sat_id: satQuestions.sat_id,
            time_spent_after_answer: seconds,
            is_correct: getAnswersValue ? getAnswersValue : false,
            given_answer_ids: viewQuestion.is_free_response ? [] : answersGiven,
            given_free_response_answer: viewQuestion.is_free_response ? free_response_answer_value : '',
            act_id: actQuestions.act_id,
        };

        this.setState({ showExplanation: false, seconds: 0, time_spent_before_answer_for_problemset: 0, time_spent_after_answer_for_problemset: 0, view_full_explanation: false, skipProblemSetIfComplete: false, problemSetId }, async () => {
            await startAnimation()
            await getUserProblemsetProgress({ reference_id: problemSetId, reference_type: 'HomeProblemSetQuestions', user_id: account.id })
            await getProblemSetQuestions({ problem_set_id: problemSetId, position: 'asc' })
            await saveUserQuestionsProgress(userQuestionParams)
            await endAnimation();
        })

    }

    checkAnswer = (answer) => {
        const { answers } = this.props;
        const { insideQuestions, viewQuestion, viewProblemSetQuestion } = this.state;
        let allAnswers = answers[insideQuestions ? viewQuestion.id : viewProblemSetQuestion.id],
            answersGiven = this.state.answersGiven, filteredArr;

        if (answersGiven.length === 0) {
            answersGiven.push(answer)
            filteredArr = answersGiven;
        } else {
            if (answersGiven.includes(answer)) {
                filteredArr = answersGiven.filter(item => item !== answer)
            } else {
                answersGiven.push(answer)
                filteredArr = answersGiven;
            }
        }

        let allCorrect = allAnswers.filter((val) => val.correct === true);
        let allCorrectIds = _.map(allCorrect, (d) => d.id);
        let givenAnswerIds = _.map(filteredArr, (d) => d.id);
        let getCorrect = false;
        if (allCorrectIds.length === givenAnswerIds.length) {
            getCorrect = _.isEqual(_.sortBy(allCorrectIds), _.sortBy(givenAnswerIds));
        }

        this.setState({ answersGiven: filteredArr, getAnswersValue: filteredArr.length === 0 ? '' : getCorrect })
    }

    nextProblemSetQuestion = async (nextProblemSetQuestionData) => {
        const { nextLinkQuestionId } = this.state;
        const {
            getAnswers, startAnimation, endAnimation, saveUserProblemsetProgress, saveUserQuestionsProgress, account,
            completeAssignment, problemset_questions
        } = this.props;
        let problemSetQuestion = this.checkAndSkipProblemSetQuestion(nextProblemSetQuestionData, problemset_questions);
        const {
            userProblemSetProgress, userQuestionParams, totalCorrect
        } = this.nextProblemSetQuestionParams(problemSetQuestion);
        await startAnimation();
        await saveUserProblemsetProgress(userProblemSetProgress);
        await saveUserQuestionsProgress(userQuestionParams);
        await endAnimation();
        if (totalCorrect >= IN_A_ROW_CORRECT_COUNT) {
            this.setState({
                viewProblemSetQuestion: '',
                getAnswersValue: '',
                insideQuestions: true,
                problemSetCorrectAnswers: account?.problemsetProgress?.total_correct ?? 0,
                showExplanation: false,
                free_response_answer_value: '',
                view_full_explanation: false
            }, async () => {
                await startAnimation();
                await completeAssignment({type: 'problemset', problem_set_id: problemSetQuestion.problem_set_id});
                await this.fetchNextSequenceQuestion(nextLinkQuestionId);
                await endAnimation();
            })
        } else {
            this.setState({
                viewProblemSetQuestion: problemSetQuestion,
                problemSetCorrectAnswers: totalCorrect,
                answersGiven: [],
                getAnswersValue: '',
                showExplanation: false,
                free_response_answer_value: '',
                seconds: 0,
                time_spent_after_answer_for_problemset: userProblemSetProgress.time_spent_after_answer,
                view_full_explanation: false
            }, async () => {
                if (problemSetQuestion) {
                    await startAnimation();
                    await getAnswers({ params: { question_id: problemSetQuestion.id } });
                    await endAnimation();
                }
            })
        }

    }

    finalSubmit = async () => {
        const {
            saveUserProgress, startAnimation, endAnimation, saveUserQuestionsProgress
        } = this.props;
        const { userProgress, userQuestionParams } = this.getFinalUserAndQuestionParams();
        await startAnimation();
        await saveUserProgress(userProgress);
        await saveUserQuestionsProgress(userQuestionParams);
        await endAnimation();
        await this.redirectAfterComplete();
    }

    redirectAfterComplete = async () =>{
        const { satQuestions, location, actQuestions, history, completeAssignment } = this.props;
        if (location.pathname.includes("act")) {
            await completeAssignment({type: 'act', sat_question_id :actQuestions.id})
        } else {
            await completeAssignment({type: 'sat', sat_question_id :satQuestions.id})
        }
        const queryString = new URLSearchParams(location.search);
        const dashboard = !!(queryString && queryString.get('dashboard'));
        if (dashboard) {
            await this.flashMessage({message: "Sequence Assignment Completed",type: "success"});
            return history.push({pathname: '/home', params: { assignment: true, completeAssignment: true}});
        }
        await this.flashMessage({message: "Sequence Completed",type: "success"});
        if (location.pathname.includes("act")) {
            return history.push(`/home/acts?act=${actQuestions.act_id}&act_question=${actQuestions.id}`);
        }
        history.push(`/home/sats?sat=${satQuestions.sat_id}&sat_question=${satQuestions.id}`);
    }

    showExplanationAndSaveProblemSetProgress = async (nextProblemSetQuestionData) => {
        const { startAnimation, endAnimation, saveUserProblemsetProgress, saveUserQuestionsProgress } = this.props;
        await startAnimation();
        const {userProblemSetProgress , userQuestionParams} = this.questionAndProblemSetProgressParams(nextProblemSetQuestionData);
        await saveUserProblemsetProgress(userProblemSetProgress);
        await saveUserQuestionsProgress(userQuestionParams);
        await endAnimation();
        this.setState({ showExplanation: true, seconds: 0, time_spent_before_answer_for_problemset: userProblemSetProgress.time_spent_before_answer })
        this.scrollToCheckButton()
        this.alertAnswerStatus();
    }

    showExplanationAndSaveQuestionProgress = async () => {
        const {
            viewQuestion, getAnswersValue, satSequenceCorrectAnswers, time_spent_before_answer_for_sequence, seconds,
            answersGiven, free_response_answer_value
        } = this.state
        const {
            questions, saveUserProgress, topicType, topicId, account, satQuestions, saveUserQuestionsProgress,
            actQuestions, startAnimation, endAnimation
        } = this.props;
        await startAnimation();
        let totalCorrect = getAnswersValue ? satSequenceCorrectAnswers + 1 : satSequenceCorrectAnswers;
        let questions_complete = this.state.questions_complete;
        let nextQuestion = '', problemSetId = '', nextLinkQuestionId = '';

        if(getAnswersValue === true){
            if (viewQuestion?.link_right_question_id) {
                nextQuestion = questions.filter((question) => question.id === viewQuestion.link_right_question_id)[0]
                nextLinkQuestionId = viewQuestion.link_right_question_id;
            }
             problemSetId = viewQuestion?.link_right_problemset_id ?? '';
        } else if(getAnswersValue === false){
            if (viewQuestion?.link_wrong_question_id) {
                nextQuestion = questions.filter((question) => question.id === viewQuestion.link_wrong_question_id)[0]
                nextLinkQuestionId = viewQuestion.link_wrong_question_id;
            }
            problemSetId = viewQuestion?.link_wrong_problemset_id ?? '';
        }

        if (problemSetId === ''){
            questions_complete += 1;
        }

        // save user progress
        // used a unary plus to convert a string to a number in percent_complete
        let userProgress = {
            user_id: account.id,
            reference_type: topicType,
            reference_id: topicId,
            question_id: nextQuestion?.id ?? null,
            total_questions: questions.length,
            questions_complete: questions_complete,
            started: questions_complete > 0,
            percent_complete: + ((questions_complete / questions.length ) * 100).toFixed(2),
            sat_id: satQuestions.sat_id,
            total_correct: totalCorrect,
            next_problemset_id: problemSetId,
            time_spent_before_answer: time_spent_before_answer_for_sequence + seconds,
            act_id: actQuestions.act_id,
            next_link_question_id: nextLinkQuestionId
        }
        let userQuestionParams = {
            user_id: account.id,
            question_id: viewQuestion.id,
            reference_type: topicType,
            reference_id: topicId,
            sat_id: satQuestions.sat_id,
            time_spent_before_answer: seconds,
            is_correct: getAnswersValue ? getAnswersValue : false,
            given_answer_ids: viewQuestion.is_free_response ? [] : answersGiven,
            given_free_response_answer: viewQuestion.is_free_response ? free_response_answer_value : '',
            act_id: actQuestions.act_id,
        };

        await saveUserProgress(userProgress);
        await saveUserQuestionsProgress(userQuestionParams);
        await endAnimation();
        this.setState({ showExplanation: true, nextLinkQuestionId, satSequenceCorrectAnswers: totalCorrect, seconds: 0, time_spent_before_answer_for_sequence: userProgress.time_spent_before_answer })
        this.scrollToCheckButton()
        this.alertAnswerStatus();
    }

    checkFreeResponseAnswer = (e, freeResponseAnswers) => {
        if (e.target.value) {
            let val = e.target.value, getCorrect = false,  breakCheck = false;
            let sortedFreeResponseAnswers = _.orderBy(freeResponseAnswers, ['is_exact_match'], ['desc']);
            sortedFreeResponseAnswers.forEach(answer => {
                if (breakCheck === false) {
                    let ans = checkCorrectOnFreeResponseAnswers(val, answer);
                    if (ans) {
                        getCorrect = ans;
                        breakCheck = true;
                    }
                }
            })
            this.setState({ free_response_answer_value: e.target.value, getAnswersValue: getCorrect });
        } else {
            this.setState({ free_response_answer_value: '', getAnswersValue: '' });
        }
    }

    checkNextQuestionExist = () =>{
        const { nextLinkQuestionId } = this.state;
        const { questions } = this.props;
        return !!(questions.filter((question) => question.id === nextLinkQuestionId)[0]);
    }

    nextProblemSetQuestionExist = () => {
        const { getAnswersValue, problemSetCorrectAnswers } = this.state
        let totalCorrect = getAnswersValue ? problemSetCorrectAnswers + 1 : 0
        return (totalCorrect >= IN_A_ROW_CORRECT_COUNT);
    }

    problemSetFinalSubmit = async (nextProblemSetQuestionData) => {
        const { startAnimation, endAnimation, saveUserProblemsetProgress, saveUserQuestionsProgress, completeAssignment } = this.props
        const { userQuestionParams, userProblemSetProgress } =  this.nextProblemSetQuestionParams(nextProblemSetQuestionData);
        await startAnimation();
        await completeAssignment({type: 'problemset', problem_set_id: nextProblemSetQuestionData.problem_set_id});
        await saveUserProblemsetProgress(userProblemSetProgress);
        await saveUserQuestionsProgress(userQuestionParams);
        await endAnimation();
        return this.finalSubmit();
    }

    getFinalUserAndQuestionParams = () => {
        const { topicType, topicId, account, satQuestions, questions, actQuestions } = this.props;
        const { time_spent_after_answer_for_sequence, seconds, viewQuestion, getAnswersValue, answersGiven, free_response_answer_value } = this.state;
        let userProgress = {
            user_id: account.id,
            reference_type: topicType,
            reference_id: topicId,
            question_id: null,
            total_questions: questions.length,
            questions_complete: questions.length,
            started: false,
            percent_complete: 100,
            sat_id: satQuestions.sat_id,
            time_spent_after_answer: time_spent_after_answer_for_sequence + seconds,
            act_id: actQuestions.act_id,
        }

        let userQuestionParams = {
            id: account.userQuestionProgress && account.userQuestionProgress._id,
            user_id: account.id,
            question_id: viewQuestion.id,
            reference_type: topicType,
            reference_id: topicId,
            sat_id: satQuestions.sat_id,
            time_spent_after_answer: seconds,
            is_correct: getAnswersValue ? getAnswersValue : false,
            given_answer_ids: viewQuestion.is_free_response ? [] : answersGiven,
            given_free_response_answer: viewQuestion.is_free_response ? free_response_answer_value : '',
            act_id: actQuestions.act_id,
        }

        return ({userProgress, userQuestionParams});
    }

    nextProblemSetQuestionParams = (problemSetQuestion) => {
        const { getAnswersValue, problemSetCorrectAnswers, time_spent_after_answer_for_problemset, seconds, viewProblemSetQuestion, answersGiven, free_response_answer_value } = this.state;
        const { problemset_questions, topicId, account, location } = this.props;
        let totalCorrect = getAnswersValue ? problemSetCorrectAnswers + 1 : 0;
        let questions_complete = this.questionsComplete();

        const userProblemSetProgress = {
            user_id: account.id,
            reference_type: 'HomeProblemSetQuestions',
            reference_id: problemSetQuestion.problem_set_id,
            question_id: problemSetQuestion.id,
            total_questions: problemset_questions.length,
            questions_complete,
            started: questions_complete > 0,
            percent_complete: + ((questions_complete / problemset_questions.length) * 100).toFixed(2),
            total_correct: totalCorrect,
            sat_question_id: location.pathname.includes("sat") ? topicId : '',
            time_spent_after_answer: time_spent_after_answer_for_problemset + seconds,
            act_question_id: location.pathname.includes("act") ? topicId : '',
        }
        const userQuestionParams = {
            id: account.userQuestionProgress && account.userQuestionProgress._id,
            user_id: account.id,
            question_id: viewProblemSetQuestion.id,
            reference_type: "HomeProblemSetQuestions",
            reference_id: problemSetQuestion.problem_set_id,
            time_spent_after_answer: seconds,
            is_correct: getAnswersValue ? getAnswersValue : false,
            given_answer_ids: viewProblemSetQuestion.is_free_response ? [] : answersGiven,
            given_free_response_answer: viewProblemSetQuestion.is_free_response ? free_response_answer_value : ''
        }
        return ({userProblemSetProgress, userQuestionParams, totalCorrect});
    }


    questionAndProblemSetProgressParams = (nextProblemSetQuestionData) =>{
        const {
            getAnswersValue, problemSetCorrectAnswers, time_spent_before_answer_for_problemset, seconds,
            viewProblemSetQuestion, answersGiven, free_response_answer_value
        } = this.state
        const { problemset_questions, topicId, account, location } = this.props;

        let totalCorrect = getAnswersValue ? problemSetCorrectAnswers + 1 : 0;
        let questions_complete = this.questionsComplete(1);

        const userProblemSetProgress = {
            user_id: account.id,
            reference_type: 'HomeProblemSetQuestions',
            reference_id: nextProblemSetQuestionData.problem_set_id,
            question_id: nextProblemSetQuestionData.id,
            total_questions: problemset_questions.length,
            questions_complete,
            started: questions_complete > 0,
            percent_complete: + ((questions_complete / problemset_questions.length) * 100).toFixed(2),
            total_correct: totalCorrect,
            sat_question_id: location.pathname.includes("sat") ? topicId : '',
            time_spent_before_answer: time_spent_before_answer_for_problemset + seconds,
            act_question_id: location.pathname.includes("act") ? topicId : '',
        }

        let userQuestionParams = {
            user_id: account.id,
            question_id: viewProblemSetQuestion.id,
            reference_type: "HomeProblemSetQuestions",
            reference_id: nextProblemSetQuestionData.problem_set_id,
            time_spent_before_answer: seconds,
            is_correct: getAnswersValue ? getAnswersValue : false,
            given_answer_ids: viewProblemSetQuestion.is_free_response ? [] : answersGiven,
            given_free_response_answer: viewProblemSetQuestion.is_free_response ? free_response_answer_value : ''
        }

        return({userQuestionParams, userProblemSetProgress})
    }

    nextQuestionParams = ({questions_complete, nextQuestion, state = this.state}) =>{
        const { questions, topicType, topicId, account, satQuestions, actQuestions } = this.props;
        const { time_spent_after_answer_for_sequence, seconds, getAnswersValue, answersGiven, free_response_answer_value, viewQuestion } = state;
        let userProgress = {
            user_id: account.id,
            reference_type: topicType,
            reference_id: topicId,
            question_id: nextQuestion.id,
            total_questions: questions.length,
            questions_complete,
            started: questions_complete > 0,
            percent_complete: + ((questions_complete / questions.length) * 100).toFixed(2),
            sat_id: satQuestions.sat_id,
            time_spent_after_answer: time_spent_after_answer_for_sequence + seconds,
            act_id: actQuestions.act_id,
        }
        let userQuestionParams = {
            id: account?.userQuestionProgress?._id,
            user_id: account.id,
            question_id: viewQuestion.id,
            reference_type: topicType,
            reference_id: topicId,
            sat_id: satQuestions.sat_id,
            time_spent_after_answer: seconds,
            is_correct: getAnswersValue ? getAnswersValue : false,
            given_answer_ids: viewQuestion.is_free_response ? [] : answersGiven,
            given_free_response_answer: viewQuestion.is_free_response ? free_response_answer_value : '',
            act_id: actQuestions.act_id,
        }

        return ({userQuestionParams, userProgress });
    }

    render() {
        const { answers, loading, problemset_questions } = this.props
        const {
            viewQuestion, answersGiven, getAnswersValue, insideQuestions, viewProblemSetQuestion,
            showExplanation, free_response_answer_value, view_full_explanation
        } = this.state;

        if (insideQuestions && viewQuestion) {
            let freeResponseAnswers = getFreeResponseAnswers({answers: answers,question: viewQuestion});
            return (
                <div className={`view-question--inner ${loading ? 'loading-topics' : ''}`}>
                    <IfView visible={viewQuestion}>
                        <ReadOnlyEditor viewAble={true} content={viewQuestion.question} />
                    </IfView>

                    <IfView visible={showExplanation && viewQuestion.exp_position_before_answer}>
                        <ViewFullExplanation question_exp={viewQuestion.question_exp}
                                             full_explanation={view_full_explanation}
                                             onClick={() => this.handle_view_full_explanation()}/>
                    </IfView>

                    <div className="view-question--ans">
                        <IfView visible={viewQuestion.is_free_response}>
                            <FreeResponseWithExplanation answers={freeResponseAnswers}
                                showExplanation={showExplanation}
                                answersValue={getAnswersValue}
                                value={free_response_answer_value}
                                onChange={(e) => this.checkFreeResponseAnswer(e, freeResponseAnswers)} />
                        </IfView>

                        <QuestionView setQuestion={viewQuestion}
                                      answers={answers}
                                      showExplanation={showExplanation}
                                      answersGiven={answersGiven}
                                      checkAnswer={this.checkAnswer}/>

                    </div>

                    <IfView visible={showExplanation && !viewQuestion.exp_position_before_answer}>
                        <ViewFullExplanation
                            question_exp={viewQuestion.question_exp}
                            full_explanation={view_full_explanation}
                            onClick={() => this.handle_view_full_explanation()}
                        />
                    </IfView>

                    <div className="mt-4 d-flex justify-content-center w-100 position-relative">
                        {/* <button onClick={() => alert('Work in progress!!')} className="btn btn-secondary mr-4">Previous</button> */}
                        <IfElseView condition={showExplanation}>
                            <div /* If Part */>
                                <IfElseView condition={viewQuestion && getAnswersValue === ''}>
                                    <div /* If Part */>
                                        <button disabled={true} className="btn btn-primary btn-lg my-3">
                                            {viewQuestion.link_right_question_id === null && viewQuestion.link_right_problemset_id === null
                                            && viewQuestion.link_wrong_question_id === null && viewQuestion.link_wrong_problemset_id === null
                                                ? 'Submit' : 'Next'}
                                        </button>
                                    </div>
                                    <div /* Else Part */>
                                        <IfElseView condition={viewQuestion && getAnswersValue === true}>
                                            <div /* If Part */>
                                                <IfElseView condition={viewQuestion?.link_right_problemset_id}>
                                                    <div /* If Part */>
                                                        <button onClick={() => this.fetchNextProblemSetQuestion(viewQuestion.link_right_problemset_id)}
                                                                className="btn btn-primary btn-lg my-3">Next</button>
                                                    </div>
                                                    <div /* Else Part */>
                                                        <IfElseView condition={viewQuestion?.link_right_question_id}>
                                                            <div /* If Part */>
                                                                <button onClick={() => this.nextSequenceQuestion(viewQuestion.link_right_question_id)}
                                                                        className="btn btn-primary btn-lg my-3">Next</button>
                                                            </div>
                                                            <div /* Else Part */>
                                                                <button onClick={() => this.finalSubmit()}
                                                                        className="btn btn-primary btn-lg my-3">Submit</button>
                                                            </div>
                                                        </IfElseView>
                                                    </div>
                                                </IfElseView>
                                            </div>
                                            <div /* Else Part */>
                                                <IfElseView condition={viewQuestion?.link_wrong_problemset_id}>
                                                    <div /* If Part */>
                                                        <button onClick={() => this.fetchNextProblemSetQuestion(viewQuestion.link_wrong_problemset_id)}
                                                                className="btn btn-primary btn-lg my-3">Next</button>
                                                    </div>
                                                    <div /* Else Part */>
                                                        <IfElseView condition={viewQuestion?.link_wrong_question_id}>
                                                            <div /* If Part */>
                                                                <button onClick={() => this.nextSequenceQuestion(viewQuestion.link_wrong_question_id)}
                                                                        className="btn btn-primary btn-lg my-3">Next</button>
                                                            </div>
                                                            <div /* Else Part */>
                                                                <button onClick={() => this.finalSubmit()}
                                                                        className="btn btn-primary btn-lg my-3">Submit</button>
                                                            </div>
                                                        </IfElseView>
                                                    </div>
                                                </IfElseView>
                                            </div>
                                        </IfElseView>
                                    </div>
                                </IfElseView>
                            </div>
                            <div /* Else Part */>
                                <button
                                    disabled={getAnswersValue === ''}
                                    onClick={() => this.showExplanationAndSaveQuestionProgress()} className="btn btn-primary btn-lg my-3"
                                    id="check_button">Check</button>
                            </div>
                        </IfElseView>
                    </div>
                </div>
            );
        } else if(!insideQuestions && viewProblemSetQuestion) {
            let nextProblemSetQuestionData = problemset_questions.length > 0 ? _.find(problemset_questions, ['position', viewProblemSetQuestion.position + 1]) : ''

            let freeResponseAnswers = getFreeResponseAnswers({answers: answers,question: viewProblemSetQuestion});
            return (
                <div className={`view-question--inner ${loading ? 'loading-topics' : ''}`}>
                    <IfView visible={viewProblemSetQuestion}>
                        <ReadOnlyEditor viewAble={true} content={viewProblemSetQuestion.question} />
                    </IfView>
                    <IfView visible={showExplanation && viewProblemSetQuestion.exp_position_before_answer}>
                        <ViewFullExplanation question_exp={viewProblemSetQuestion.question_exp}
                                             full_explanation={view_full_explanation}
                                             onClick={() => this.handle_view_full_explanation()}/>
                    </IfView>

                    <div className="view-question--ans">
                        <IfView visible={viewProblemSetQuestion.is_free_response}>
                            <FreeResponseWithExplanation answers={freeResponseAnswers}
                                showExplanation={showExplanation}
                                answersValue={getAnswersValue}
                                value={free_response_answer_value}
                                onChange={(e) => this.checkFreeResponseAnswer(e, freeResponseAnswers)} />
                        </IfView>

                        <QuestionView setQuestion={viewProblemSetQuestion}
                                      answers={answers}
                                      showExplanation={showExplanation}
                                      answersGiven={answersGiven}
                                      checkAnswer={this.checkAnswer}/>
                    </div>

                    <IfView visible={showExplanation && !viewProblemSetQuestion.exp_position_before_answer}>
                        <ViewFullExplanation question_exp={viewProblemSetQuestion.question_exp}
                                             full_explanation={view_full_explanation}
                                             onClick={() => this.handle_view_full_explanation()}/>
                    </IfView>

                    <div className="mt-4 d-flex justify-content-center w-100 position-relative">
                        {/* <button onClick={() => alert('Work in progress!!')} className="btn btn-secondary mr-4">Previous</button> */}
                        <IfElseView condition={showExplanation}>
                            <div /*If View*/>
                                <IfElseView condition={answersGiven.length > 0 || free_response_answer_value !== ''}>
                                    <div /*If View*/>
                                        <IfElseView condition={this.nextProblemSetQuestionExist() && !(this.checkNextQuestionExist())}>
                                            <button
                                                onClick={() => this.problemSetFinalSubmit(nextProblemSetQuestionData ? nextProblemSetQuestionData : problemset_questions[0])}
                                                className="btn btn-primary btn-lg my-3">Submit
                                            </button>
                                            <button
                                                onClick={() => this.nextProblemSetQuestion(nextProblemSetQuestionData ? nextProblemSetQuestionData : problemset_questions[0])}
                                                className="btn btn-primary btn-lg my-3">Next
                                            </button>
                                        </IfElseView>
                                    </div>
                                    <div /*Else View*/>
                                        <button disabled={true} className="btn btn-primary btn-lg my-3">Next</button>
                                    </div>
                                </IfElseView>
                            </div>
                            <div /*Else View*/>
                                <button disabled={getAnswersValue === ''}
                                        onClick={() => this.showExplanationAndSaveProblemSetProgress(nextProblemSetQuestionData ? nextProblemSetQuestionData : problemset_questions[0])}
                                        className="btn btn-primary btn-lg my-3" id="check_button">Check
                                </button>
                            </div>
                        </IfElseView>
                    </div>
                </div >
            );
        }
        return (<div className={"view-question--inner"}/>)
    }
}

const mapStateToProps = state => {
    return ({
        answers: state.answers,
        loading: state.loading,
        account: state.account,
        satQuestions: state.satQuestions,
        actQuestions: state.actQuestions,
        currentQuestion: state.temporaryData.currentQuestion
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        startAnimation: () => dispatch(animationActions.start()),
        endAnimation: () => dispatch(animationActions.end()),
        getAnswers: (params) => dispatch(answerActions.getAllAnswer(params)),
        saveUserProgress: (params) => dispatch(userProgressActions.saveUserProgress(params)),
        resetUserProgress: (params) => dispatch(userProgressActions.resetUserProgress(params)),
        getUserProblemsetProgress: (params) => dispatch(userProgressActions.getUserProblemSetProgress(params)),
        saveUserProblemsetProgress: (params) => dispatch(userProgressActions.saveUserProblemSetProgress(params)),
        resetUserProblemsetProgress: (params) => dispatch(userProgressActions.resetUserProblemSetProgress(params)),
        saveUserQuestionsProgress: (params) => dispatch(accountActions.saveUserQuestionsProgress('/user_questions/save_user_questions_progress', params)),
        alertPush: (payload) => dispatch(alertActions.alertPush(payload)),
        alertDelete: () => dispatch(alertActions.alertDelete()),
        setCurrentQuestion: (payload)=> dispatch(commonRecords.setCurrentQuestion(payload)),
        clearCurrentQuestion: ()=> dispatch(commonRecords.clearCurrentQuestion()),
        completeAssignment: (params) => dispatch(assignmentActions.completeAssignment(params))
    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewQuestions));
