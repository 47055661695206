
export const randomString = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

// util function to convert the input to string type
export const convertToString = (input) => {
    if (input) {
        if (typeof input === "string") {
            return input;
        }
        return String(input);
    }
    return '';
}

// camelcase to word
export const toWords = (input) => {
    input = convertToString(input);
    var regex = /[A-Z\xC0-\xD6\xD8-\xDE]?[a-z\xDF-\xF6\xF8-\xFF]+|[A-Z\xC0-\xD6\xD8-\xDE]+(?![a-z\xDF-\xF6\xF8-\xFF])|\d+/g;
    return input.match(regex).join(' ');
}

export const capFirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

/* Define functin to find and replace specified term with replacement string */
export const replaceAll = (str, term, replacement) => {
    return str.replace(new RegExp(escapeRegExp(term), 'g'), replacement);
}

export const multiply = (array) => {
    var sum = 1;
    for (var i = 0; i < array.length; i++) {
        sum = sum * array[i];
    }
    return sum;
}

export const patternCheck = (x) => {
    return x.match(/(?=.)^\$?(([1-9][0-9]{0,2}(,[0-9]{3})*)|0)?(\.[0-9]{1,2})?$/);
}

export const checkCorrectOnFreeResponseAnswers = (valAnswer, freeResponseAnswer) => {
    let sendRes = false
    let trimedValAnswer = valAnswer.trim()
    if (!(trimedValAnswer).startsWith(",") && !(trimedValAnswer).endsWith(",")) {
        if ((freeResponseAnswer && freeResponseAnswer.is_exact_match) === true){
            if (trimedValAnswer===freeResponseAnswer.free_response_answer){
                return true 
            }
        } else {
            let val = replaceAll(trimedValAnswer, ',', '');
            let simpleFreeResponse = replaceAll(freeResponseAnswer.free_response_answer, ',', '');
            if (val === freeResponseAnswer.free_response_answer || val === simpleFreeResponse) {
                return true
            }
            let calculatedFreeResponseAnswer = freeResponseAnswer && evaluate(freeResponseAnswer.free_response_answer)
            if (evaluate(val) === calculatedFreeResponseAnswer){
                return true
            }
        }
    }
    return sendRes
  }

const evaluate = (val) =>{
    let calculateAns = val;
    if (!validateOperatorCondition(val,"+") || !validateOperatorCondition(val,"+") || Number.isNaN(parseFloat(calculateAns))){
        return val;
    } else if (val.includes('/') && (val.split('/').length === 2)) {
        let splitVal = val.split('/')
        if (splitVal[0] !== '' && splitVal[1] !== '') {
            calculateAns = splitVal[0] / splitVal[1]
        }
    } else if (val.includes('*') && (val.split('*').length === 2)) {
        let splitVal = val.split('*')
        let res = splitVal.every((e) => parseFloat(e))
        if (res) {
            let validArr = splitVal.map((e) => parseFloat(e))
            calculateAns = multiply(validArr)
        }
    }
    return  parseFloat(parseFloat(calculateAns).toFixed(2))
}

// this function return true if symbol is not exist or if symbol exist but only first place
function validateOperatorCondition(val,operator){
    if(val.includes(operator)){
        return !!(val.startsWith(operator) && val.split(operator).length === 2);
    }
    return true;
}
