import React from 'react';
import noCalcImg from './no-calculator.png';
import calcImg from './calculator.png';

export const calculator = (
    <img className="calc" src={calcImg} alt="calculator_question"/>
);

export const noCalculator = (
    <img className="calc" src={noCalcImg} alt="no_calculator_question"/>
)
