import axios from 'axios';
import {apiUrl} from '../config';
import store from "../modules/store";

const buildRequest = (request) => {
    const {body, method, url , params} = request;
    const contentType = body instanceof FormData ? 'multipart/form-data' : 'application/json';
    let headers = {'content-type': contentType};
    const { account } = store.getState();
    headers['authorization'] = account.auth;
    headers["app-version"] = "1"
    headers['timeZoneName'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const baseUrl = apiUrl();
    return ({baseURL: baseUrl, data: body, headers, method, url, params: params});
};

export const defaultResponse = {
    status: 500,
    data: {
        error: 'Server error',
    },
};

export const formatError = (responseError) => {
    const response = responseError.response || defaultResponse;
    const errors = response.data && (response.data.errors || [response.data.error]);
    return {
        code: response.status,
        message: errors,
    };
};

export const makeRequest = async (request) => {
    const requestConfig = buildRequest(request);
    return new Promise((resolve, reject) => {
        const axiosRequest = axios(requestConfig);
        axiosRequest.then(resolve)
            .catch((error) => {
                reject(formatError(error));
            });
    });
};
