import {
    RECEIVE_ALL_SATS,
    RECEIVE_SAT,
    UPDATE_SAT,
    REMOVE_SAT,
    PUBLISH_SAT,
    RECEIVE_ALL_PUBLISH_SATS,
    RECEIVE_UPDATE_SAT_SCORE,
    RECEIVE_SAT_BUBBLE_SHEET_ANSWER,
    UPDATE_USER_SAT_POSITION
} from '../constant';

// Not considering creation of new test
export const satReducer = (state = [], action) => {
    let newState;
    switch (action.type) {
        case RECEIVE_ALL_SATS:
            return Object.assign([], state, action.data)
        case RECEIVE_ALL_PUBLISH_SATS:
            return action.data
        case RECEIVE_SAT:
            for (let i = 0; i < state.length; i++) {
                if (action.data.id === state[i].id) {
                    newState = state.map(obj => (action.data.id === obj.id) ? action.data : obj);
                    return newState;
                }
            }
            return Object.assign([], state, [...state, action.data])
        case UPDATE_SAT:
        case PUBLISH_SAT:
        case RECEIVE_UPDATE_SAT_SCORE:
        case RECEIVE_SAT_BUBBLE_SHEET_ANSWER:
            newState = state.map(obj => (action.data.id === obj.id) ? action.data : obj);
            return newState;
        case REMOVE_SAT:
            newState = state.filter(obj => (action.data.id !== obj.id));
            return newState;
        case UPDATE_USER_SAT_POSITION:
            let data = action?.data ?? [];
            let stateData = {};
            state.map((sat) => stateData[sat.id] = sat)
            newState = data.map((obj)=> stateData[obj.sat_id]);
            return newState;
        default:
            return state;
    }
}
