import React, {Component} from 'react';
import AdminSubtopic from './AdminSubtopic';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import './AdminSubtopics.scss';

const DragHandle = SortableHandle(() =>
    <span className="sortable-handle">::</span>
)

const SortableItem = SortableElement(({ 
    subtopic, updateSubtopic, deleteSubtopic, satTopicModal, satTopic, updateAfterPublishUnpublish, mathTopic, actTopic
}) => {
    return (
        <li key={subtopic.id} className="sub-topic-list-element">
            {satTopicModal === false && <DragHandle />}
            <AdminSubtopic updateSubtopic={updateSubtopic}
                           deleteSubtopic={deleteSubtopic}
                           subtopic={subtopic}
                           satTopicModal={satTopicModal}
                           satTopic={satTopic}
                           updateAfterPublishUnpublish={updateAfterPublishUnpublish}
                           mathTopic={mathTopic}
                           actTopic={actTopic}/>
        </li>
    )
})


const SortableList = SortableContainer(({
    subtopics, updateSubtopic, deleteSubtopic, satTopicModal, satTopic, updateAfterPublishUnpublish, mathTopic, actTopic
}) => {
    return (
        <ul className="sortable-home-topic-list">
            {subtopics.map((subtopic, index) => {
                return (
                    <SortableItem key={subtopic.id}
                                  index={index}
                                  subtopic={subtopic}
                                  id={subtopic.id}
                                  updateSubtopic={updateSubtopic}
                                  deleteSubtopic={deleteSubtopic}
                                  satTopicModal={satTopicModal}
                                  satTopic={satTopic}
                                  updateAfterPublishUnpublish={updateAfterPublishUnpublish}
                                  mathTopic={mathTopic}
                                  actTopic={actTopic}/>
                )
            })}
        </ul>
    )
})

class AdminSubtopics extends Component {

    state = {
        subtopics: [],
    }

    componentDidMount() {

        const { subtopics } = this.props;
        this.setState({ subtopics })
    }


    onSortEnd = async (options, mouseEvent, subtopics = this.props.subtopics) => {
        const { updateSubTopicPositions } = this.props;
        const { oldIndex, newIndex } = options;
        if(oldIndex === newIndex) return null;
        const updatedSubTopics = arrayMove(subtopics, oldIndex, newIndex)
        const updatedSubTopicData = {subtopics: updatedSubTopics, _id: updatedSubTopics[0].test_id};
        const x = await updateSubTopicPositions(updatedSubTopicData);
        this.setState({ subtopics: x });
    }
    render() {
        const { subtopics, show, updateSubtopic, deleteSubtopic, satTopicModal, satTopic, updateAfterPublishUnpublish, mathTopic, actTopic} = this.props;
        if (!show || !subtopics) return null;
        return (
            <div className="sortable-topics">
                <SortableList
                    useDragHandle
                    onSortEnd={this.onSortEnd}
                    subtopics={subtopics}
                    updateSubtopic={updateSubtopic}
                    deleteSubtopic={deleteSubtopic}
                    satTopicModal={satTopicModal}
                    satTopic={satTopic}
                    updateAfterPublishUnpublish={updateAfterPublishUnpublish}
                    mathTopic={mathTopic}
                    actTopic={actTopic}
                    helperClass='sortableHelper'
                    lockAxis="y"
                    useWindowAsScrollContainer={true}
                />
            </div>
        );
    }
}

export default AdminSubtopics;
