import React, { Component } from 'react';
import SwitchButton from "react-switch";
import PropTypes from 'prop-types';
import "./index.scss";

class Switch extends Component {

    render() {
        const { checked, options, onChange, onColor, offColor, marginLeft, marginRight } = this.props;
        return (
            <>
                <div className={`switchButtonDiv d-flex align-items-center ml-${marginLeft} mr-${marginRight}`}>
                    <i className={`switch_txt checked mr-1`}>{options[0]}</i>
                    <SwitchButton checked={checked === options[1]}
                                  onChange={()=> onChange(options[0] === checked ? options[1] : options[0])}
                                  offColor={offColor}
                                  onColor={onColor}
                                  height={32}
                                  width={50}
                                  handleDiameter={24}/>
                    <i className={`switch_txt unchecked ml-1`}>{options[1]}</i>
                </div>
            </>
        )
    }
}

Switch.propTypes = {
    checked: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onColor: PropTypes.string,
    offColor: PropTypes.string,
    marginLeft: PropTypes.number,
    marginRight: PropTypes.number,
}

Switch.defaultProps = {
    checked: "Checked",
    options: ["Checked","Unchecked"],
    onColor: "#F06150",
    offColor: "#00C789",
    onChange: (selected) => {},
    marginLeft: 2,
    marginRight: 2,
}

export default Switch;