import {
    ADD_BUCKET_ANSWER,
    ADD_BUCKET_PROBLEM_SET,
    ADD_BUCKET_QUESTION,
    ADD_BULK_QUESTION,
    CLEAR_ANSWER_BUCKET,
    CLEAR_BUCKET,
    CLEAR_BUCKET_PROBLEM_SET,
    CLEAR_QUESTION_BUCKET
} from "../constant";
import {combineReducers} from 'redux';

const bucketAnswerReducer = (state = [], action) => {
    switch (action.type) {
        case ADD_BUCKET_ANSWER:
            return Object.assign([], state, [...state, action.data])
        case CLEAR_ANSWER_BUCKET:
            break;
        case CLEAR_BUCKET:
            return [];
        default:
            return state;
    }
}

const bucketProblemSetReducer = (state = [], action) => {
    switch (action.type) {
        case ADD_BUCKET_PROBLEM_SET:
            return Object.assign([], state, [...state, action.data])
        case CLEAR_BUCKET_PROBLEM_SET:
            break;
        case CLEAR_BUCKET:
            return [];
        default:
            return state;
    }
}

const bucketQuestionReducer = (state = [], action) => {
    switch (action.type) {
        case ADD_BUCKET_QUESTION:
            return Object.assign([], state, [...state, action.data])
        case CLEAR_QUESTION_BUCKET:
            break;
        case CLEAR_BUCKET:
            return [];
        case ADD_BULK_QUESTION: 
        return Object.assign([], state, [...state, ...action.data])
        default:
            return state;
    }
}

export const bucketReducer = combineReducers({
    questions: bucketQuestionReducer,
    answers: bucketAnswerReducer,
    problemSet: bucketProblemSetReducer
});
