import { combineReducers } from "redux";
import {
    RECEIVE_USER_ACT_PROBLEM_SET_TYPE_PROGRESS,
    RECEIVE_USER_ACT_SEQUENCES_PROGRESS,
    RECEIVE_USER_ACT_TYPE_PROGRESS
} from "../constant";

const sequenceTypeProgressReducer = (state = {}, action) => {
    switch (action.type) {
        case RECEIVE_USER_ACT_TYPE_PROGRESS:
            return { ...state, [action.data.act_id]: action.data.data };
        default:
            return state;
    }
}

const problemSetTypeProgressReducer = (state = {}, action) => {
    switch (action.type) {
        case RECEIVE_USER_ACT_PROBLEM_SET_TYPE_PROGRESS:
            return { ...state, [action.data.act_id]: action.data.data };
        default:
            return state;
    }
}

const sequencesProgressReducer = (state = {}, action) => {
    switch (action.type) {
        case RECEIVE_USER_ACT_SEQUENCES_PROGRESS:
            return { ...state, [action.data.act_id]: action.data.data };
        default:
            return state;
    }
}

export const actProgressReducer = combineReducers({
    sequenceType: sequenceTypeProgressReducer,
    problemSetType: problemSetTypeProgressReducer,
    sequences: sequencesProgressReducer,
});
