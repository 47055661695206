import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import SVG from '../SVG';
import AccountDropdown from './AccountDropdown';
import * as accountActions from '../../modules/account/action';
import './AccountMenu.scss';

class AccountMenu extends Component {
    state = {
        showDropdown: false,
    }

    logout = (evt) => {
        this.hideDropdown();
        this.props.logout();
        // this.props.history.push('/')
    }

    toggleMenu = () => {
        const {showDropdown} = this.state;
        this.setState({showDropdown: !showDropdown});
    }

    hideDropdown = () => {
        this.setState({showDropdown: false});
    }

    renderDropdown() {
        const {account, match, backToTutor} = this.props;
        const {showDropdown} = this.state;
        if(!showDropdown) return null;
        return (
            <AccountDropdown
                hideDropdown={this.hideDropdown}
                showDropdown={showDropdown}
                logout={this.logout}
                account={account}
                match={match}
                backToTutor={backToTutor}/>
        )
    }

    render() {
        const {account} = this.props;
        return (
            <div className="account-menu" ref="test">
                <div className="user-account" onClick={this.toggleMenu}>
                    <h2 className="username" >
                        {account.username}
                    </h2>
                    <SVG name="down"/>
                </div>
                {this.renderDropdown()}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return({
        account: state.account,
    })
}

const mapDispatchToProps = dispatch => {
    return({
        logout: () => dispatch(accountActions.logout()),
    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountMenu));
