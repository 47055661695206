import React, { Component } from "react";
import ReadOnlyEditor from "../../../../editor/ReadOnlyEditor";
import { PROBLEM_SET_MATCH } from "../../../../../constants";

class AdminAnswerContent extends Component {
    render() {
        const { answer } = this.props;
        return (
            <div className="admin-answer-content">
                {
                    answer.is_free_response ?
                        <div className="flex-editor help_editor my-3">
                            <ul>
                                <LabelTitleView type="Label" title='Answer' value={`${answer?.free_response_answer ?? ''}`} />
                                <LabelTitleView type="Editor" title='Symbol' value={answer?.symbol} />
                                <LabelTitleView type="Label" title='Exact Match?' value={`${answer?.is_exact_match}`} />
                            </ul>
                        </div>
                        :
                        <>
                            <EditorTitleContent title={'Explanation'} content={answer.explanation} />
                            {
                                !window.location.href.match(PROBLEM_SET_MATCH) ?
                                    <>
                                        <EditorTitleContent title={'Linked Question'}
                                                            content={answer.next_question.question} />
                                        <ParagraphTitleContent title={'Linked Problem Set'}
                                                               content={`${answer.link_problemset?.title ? answer.link_problemset?.title : ''}`} />
                                    </>
                                    :
                                    null
                            }
                        </>
                }
            </div>
        );
    }
}

const EditorTitleContent = ({ title, content }) => {
    return (
        <EditorMainView title={title}>
            {content && content !== "NA" && <ReadOnlyEditor content={content} />}
        </EditorMainView>
    )
}

const ParagraphTitleContent = ({ title, content }) => {
    return (
        <EditorMainView title={title}>
            <p>{content}</p>
        </EditorMainView>
    )
}

const LabelTitleView = ({ title, value, type = 'Label' }) => {
    return (
        <li>
            <label>
                <b>{title}:</b>
            </label>
             {type === 'Editor' ? <ReadOnlyEditor viewAble={true} content={value}/> : <p>{value}</p>}
        </li>
    )
}

const EditorMainView = ({ title, children }) => {
    return (
        <div className="flex-editor help_editor my-3">
            <div className="header d-flex align-items-center justify-content-start flex-wrap">
                <h3 className="title">{title}</h3>
            </div>
            {children}
        </div>
    )
}

export default AdminAnswerContent;