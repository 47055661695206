import React, { Component } from 'react';

// DraftJS
import {EditorState, convertToRaw, convertFromRaw, RichUtils, SelectionState} from 'draft-js';
import katex from 'katex';
import DraftEditor, { composeDecorators } from 'draft-js-plugins-editor';
import createImagePlugin from 'draft-js-image-plugin';
import createVideoPlugin from 'draft-js-video-plugin';
import createMathjaxPlugin from 'draft-js-mathjax-plugin';
import createAlignmentPlugin from 'draft-js-alignment-plugin';
import createFocusPlugin from 'draft-js-focus-plugin';
import createResizeablePlugin from 'draft-js-resizeable-plugin';
import createToolbarPlugin, { Separator } from 'draft-js-static-toolbar-plugin';
import createKatexPlugin from 'draft-js-katex-plugin';
import {
    ItalicButton,
    BoldButton,
    UnderlineButton,
    UnorderedListButton,
    OrderedListButton,
    BlockquoteButton,
} from 'draft-js-buttons';

// Custom Plugins
import createHighlightPlugin from '../../plugins/highlightPlugin';
import AddContentButton from '../../buttons/AddContentButton';
import keyBindings from './toolTipKeyBindings';
import ColorPicker from '../../plugins/picker/ColorPicker';
import createTextColorPlugin from '../../plugins/textColorPlugin';
import FontSizeSelection from '../../plugins/selection/FontSizeSelection';
import createFontSizePlugin from '../../plugins/fontSizePlugin';

// CSS
import "./ToolTipEditor.scss";
import 'draft-js/dist/Draft.css';
import 'draft-js-image-plugin/lib/plugin.css'
import 'draft-js-alignment-plugin/lib/plugin.css';
import 'draft-js-static-toolbar-plugin/lib/plugin.css';

const katexTheme = {
    saveButton: 'katexTooltipSaveButton',
    removeButton: 'katexTooltipRemoveButton',
    panel: 'katexTooltipPanel',
    texValue: 'katexTooltipTex',
    buttons: 'katexTooltipPanel',
    insertButton: 'katexToolTipInsertButton'
};

export default class ToolTipEditor extends Component {

    constructor(props) {
        super(props);

        // Instantiate Editor
        const editor = this.setEditor(props);
        this.state = {
            editorState: editor,
            openMenu: null,
            focusBlockId: undefined,
            katexButtonClick: false,
        }

        // Initialize plugins
        const mathjaxPlugin = createMathjaxPlugin();
        const katexPlugin = createKatexPlugin({ katex, katexTheme });
        const alignmentPlugin = createAlignmentPlugin();
        const focusPlugin = createFocusPlugin();
        const toolbarPlugin = createToolbarPlugin();
        const resizeablePlugin = createResizeablePlugin();
        const textColorPlugin = createTextColorPlugin();
        const fontSizePlugin = createFontSizePlugin();
        const highlightPlugin = createHighlightPlugin();

        const decorator = composeDecorators(
            alignmentPlugin.decorator,
            resizeablePlugin.decorator,
            focusPlugin.decorator,
        )

        const imagePlugin = createImagePlugin({ decorator });
        this.imagePlugin = imagePlugin;
        const videoPlugin = createVideoPlugin({ decorator });
        this.videoPlugin = videoPlugin;

        // Special components exported from plugins
        this.PluginComponents = {
            KatexButton: katexPlugin.InsertButton,
            AlignmentTool: alignmentPlugin.AlignmentTool,
            Toolbar: toolbarPlugin.Toolbar,
        }

        // Add plugins here to load into editor
        this.plugins = [
            imagePlugin,
            katexPlugin,
            mathjaxPlugin,
            focusPlugin,
            alignmentPlugin,
            resizeablePlugin,
            toolbarPlugin,
            videoPlugin,
            highlightPlugin,
            textColorPlugin,
            fontSizePlugin
        ];
    }

    setEditor = (props) =>{
        if (props.content) {
            return(EditorState.createWithContent(convertFromRaw(JSON.parse(props.content))));
        }
        return(EditorState.createEmpty());
    }

    componentDidMount() {
        let katexButton = document.getElementsByClassName("katexToolTipInsertButton");
        for (var i = 0; i < katexButton.length; i++) {
            katexButton[i].addEventListener('click', this.katexButtonClick.bind(this), false);
        }
    }

    async katexButtonClick(){
        await this.setState({katexButtonClick: true});
    }

    // Update Editor state and data to saved in DB
    handleChange = async (editorState) => {
        await this.setFocusBlockId(editorState);
        const { updateEditorState } = this.props;
        const data = this.convertContent(editorState);
        updateEditorState(data);
        const newEditorState = await this.setSelectionState(editorState);
        this.setState({ editorState: newEditorState });
    }

    // Save a raw string to the db model
    convertContent(editorState) {
        const content = editorState.getCurrentContent()
        return JSON.stringify(convertToRaw(content))
    }

    setFocusBlockId = async (newEditorState) =>{
        let { focusBlockId, editorState } = this.state;
        if(newEditorState === editorState) return null;
        if (focusBlockId) {
            focusBlockId = editorState.getSelection().getStartKey();
        } else {
            const content = editorState.getCurrentContent();
            const rawContent = convertToRaw(content);
            focusBlockId = rawContent.blocks.at(-1).key;
        }
        await this.setState({focusBlockId})
    }

    setSelectionState = async (editorState) =>{
        const { focusBlockId, katexButtonClick } = this.state;
        if (katexButtonClick === false) return editorState;
        let newBlockId = `${focusBlockId}`;
        const content = editorState.getCurrentContent();
        const rawContent = convertToRaw(content);
        const blocks = rawContent.blocks;
        for(let x = 0; x< blocks.length; x++){
            if(katexButtonClick && blocks[x]?.key === focusBlockId && blocks[x+1]?.type === 'atomic' && blocks[x+2]){
                newBlockId = blocks[x+2].key;
                break;
            }
        }
        await this.setState({katexButtonClick: false})
        if(newBlockId !== focusBlockId && newBlockId){
            const selectionState = new SelectionState({
                anchorKey: newBlockId,
                anchorOffset: 0,
                focusKey: newBlockId,
                focusOffset: 0,
            });
            return EditorState.forceSelection(editorState,selectionState);
        }
        return editorState;
    }

    focus = () => {
        this.editor.focus();
    }

    // Custom function to map any key binding
    // Add additional keyBinds to keyBindings file
    myKeyBindingFn = (e) => {
        const { editorState } = this.state;
        return keyBindings(editorState, e);
    }

    // Handle shortcut commands and update editor
    handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.handleChange(newState);
            return 'handled';
        }
        return 'not-handled';
    }

    // Handle only 1 toolbar menu at a time
    openMenuToggle = (menuId) => {
        this.setState({ openMenu: menuId })
    }

    render() {
        const { editorState, openMenu } = this.state;
        const { KatexButton, AlignmentTool, Toolbar } = this.PluginComponents;
        return (
            <div className="tooltip-editor-main-container">
                <div className="tooltip-editor-container">
                    <div className="d-flex flex-wrap center-align">
                        <div className="tooltip-editor-buttons">
                            <KatexButton/>
                            <AddContentButton
                                editorState={editorState}
                                onChange={this.handleChange}
                                modifier={this.imagePlugin.addImage}
                                type={"image"}
                                openMenu={openMenu}
                                openMenuToggle={this.openMenuToggle}/>
                            <AddContentButton
                                editorState={editorState}
                                onChange={this.handleChange}
                                modifier={this.videoPlugin.addVideo}
                                type={"video"}
                                openMenu={openMenu}
                                openMenuToggle={this.openMenuToggle}/>
                            <ColorPicker
                                type={"TEXT"}
                                editorState={editorState}
                                onChange={this.handleChange}/>
                            <ColorPicker
                                type={"HIGHLIGHT"}
                                editorState={editorState}
                                onChange={this.handleChange}/>
                            <FontSizeSelection
                                type={"FONTSIZE"}
                                editorState={editorState}
                                onChange={this.handleChange}/>
                        </div>
                        <div className='flex-item'>
                            <Toolbar>
                                {
                                    (externalProps) => (
                                        <React.Fragment>
                                            <BoldButton {...externalProps} />
                                            <ItalicButton {...externalProps} />
                                            <UnderlineButton {...externalProps} />
                                            <Separator {...externalProps} />
                                            <UnorderedListButton {...externalProps} />
                                            <OrderedListButton {...externalProps} />
                                            <BlockquoteButton {...externalProps} />
                                        </React.Fragment>
                                    )
                                }
                            </Toolbar>
                        </div>
                    </div>
                    <div className="tooltip-editor" onClick={this.focus}>
                        <DraftEditor
                            handleKeyCommand={this.handleKeyCommand}
                            keyBindingFn={this.myKeyBindingFn}
                            editorState={editorState}
                            onChange={this.handleChange}
                            plugins={this.plugins}
                            ref={(element) => {
                                this.editor = element;
                            }}/>
                        <AlignmentTool/>
                    </div>
                </div>
            </div>
        );
    }
}
