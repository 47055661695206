import React, { Component } from 'react';
import {REPORT_PROBlEM_DIALOG_BOX} from "../../../dialogs/dialogs";
import {Loader, RenderCalcImage} from "../../../helper/commonMethods";

export default class TopicMainClass extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            currentQuestion: null,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    currentQuestion = (currentQuestion) =>{
        // this method is used to et child class
        if(this.state.currentQuestion !== currentQuestion){
            this.setState({currentQuestion});
        }
    }

    openReportProblemDialogBox = (question) => {
        return this.props.openDialog(REPORT_PROBlEM_DIALOG_BOX, {
            question
        })
    }

    ContainerView = ({title,children, showIcon = true}) =>{
        const { currentQuestion } = this.state;
        const { loading } = this.props;
        return(
            <div className="row">
                <div className="content-wrapper-before gradient-45deg-indigo-purple"/>
                <div className="col s12">
                    <div className="container">
                        <div className="dash_top_section">
                            <div className="row vertical-modern-dashboard">
                                <div className="col-12">
                                    <div className="card animate fadeLeft">
                                        <div className="question_sec card-content">
                                            <div className="question-title-div align-items-center w-100">
                                                <div className="col d-flex back-button">
                                                    <button onClick={() => this.takeBack()} className="btn btn-default">
                                                        <i className="ico backicon"/>
                                                        <span className="btntxt">Back</span>
                                                    </button>
                                                </div>
                                                <div className="col d-flex justify-content-center">
                                                    <h3>
                                                        {title}
                                                    </h3>
                                                </div>
                                                <div className="col d-flex justify-content-end report-problem">
                                                { !!currentQuestion &&
                                                    <>
                                                        {showIcon && <RenderCalcImage question={currentQuestion}/>}
                                                        <button className="btn btn-default" onClick={() => this.openReportProblemDialogBox(currentQuestion)}>Report problem</button>
                                                    </>
                                                }
                                                </div>
                                            </div>
                                            <div className="quesHeader d-flex flex-wrap align-items-center text-center w-100"/>
                                            {children}
                                            <Loader loading={loading}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }


}


