import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AdminQuestions from '../question/AdminQuestions';
import * as subtopicActions from '../../../../../modules/subTopic/action';
import * as subSubTopicActions from '../../../../../modules/subSubTopic/action';
import * as testActions from '../../../../../modules/test/action';
import * as problemSetActions from '../../../../../modules/problemSet/action';
import './AdminSubtopicMain.scss';
import SVG from '../../../../SVG';

class AdminSubSubtopicMain extends Component {
	state = {
		title: "",
		topicTitle: "",
		showTopics: false,
		testId: ""
	}
	async componentDidMount() {
		window.scrollTo(0, 0);
		let error404;
		const { match: { params: { subsubtopicId, subtopicId } }, getSubSubtopic, getSubtopic, getTest, getProblemSets } = this.props;
		const subsubtopic = await getSubSubtopic(subsubtopicId).catch((error)=>{
			error404 = error.response?.status === 404;
		});
		const subtopic = await getSubtopic(subtopicId).catch((error)=>{
			error404 = (error404 || error.response?.status === 404);
		});
		if(error404){
			return this.props.history.push('/admin/not_found')
		}
		const test = await getTest(subtopic.test_id);
		await getProblemSets({ subsubtopic_id: subsubtopicId })
		this.setState({
			subtopicTitle: subtopic.title,
			topic: test,
			topicTitle: test.title,
			subsubtopicTitle: subsubtopic.title,
			testId: subtopic.test_id
		})
	}

	takeBack = () => {
		const { match: { params: { subsubtopicId, subtopicId } } } = this.props
		const { topic } = this.state
		let url = ''
		if (topic) {
			if (topic.type === "Topic") {
				url = "/admin/math-topics"
			} else if (topic.type === "ReadingTopic") {
				url = "/admin/reading-topics"
			} else if (topic.type === "GrammarTopic") {
				url = "/admin/grammar-topics"
			}
		}
		const queryString = '?topic=' + ((topic && topic.id) || '') + '&subtopic=' + (subtopicId || '') + '&subsubtopic=' + (subsubtopicId || '')
		this.props.history.push(url + queryString)
	}

	toggleForm = () => {
		const { showTopics } = this.state
		this.setState({ showTopics: !showTopics })
	}

	render() {
		const { match: { params: { subsubtopicId, subtopicId } }, subtopics, subsubtopics, problemsets } = this.props;
		const { subtopicTitle, topicTitle, subsubtopicTitle, showTopics, testId } = this.state;
		let topicType = 'subTopic';

		// const testIndex = tests.findIndex(x=>x.id === testId)
		return (
			<div className="row" >
				<div className="content-wrapper-before gradient-45deg-indigo-purple" />
				<div className="col s12 contentCenter_wrap">
					<div className="container">
						<div className="dash_top_section" />
						<div className="row vertical-modern-dashboard">
							<div className="col-12">
								<div className="card animate fadeLeft">
									<div className="question_sec card-content">
										<div className="dashtitle_sec d-flex flex-wrap align-items-center w-100">
											<button onClick={() => this.takeBack()} className="btn btn-default">
												<i className="ico backicon" />
												<span className="btntxt">Back</span>
											</button>
										</div>
										<div className="quesHeader d-flex flex-wrap align-items-center text-center w-100">
											<h1 className=" quesTitle d-inline-block position-relative">
												{topicTitle} - {subtopicTitle} - {subsubtopicTitle}
											</h1>
											<div className="selTopics_wrap">
												<SVG name="hamburger" className="d-inline-flex hamburger" onClick={() => this.toggleForm()} />
												{
													showTopics &&
													<div className="sel-topics-list">
														{
															subtopics[testId].map(item => (
																<>
																	<h2> {item.title} </h2>
																	<ul>
																		{
																			subsubtopics[item.id]?.map(subItems => (
																				<li> {subItems.title} </li>
																			))
																		}
																	</ul>
																</>
															))
														}
													</div>
												}
											</div>
										</div>
										<AdminQuestions
											topicType={topicType}
											topicId={subsubtopicId}
											section={"subsubTopic"}
											subtopicId={subtopicId}
											problemsets={problemsets[subsubtopicId]}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div >
			</div >
		);
	}
}

const mapStateToProps = ({ subsubtopics, subtopics, tests, problemsets }) => ({ subsubtopics, subtopics, tests, problemsets })

const mapDispatchToProps = dispatch => {
	return ({
		getSubSubtopic: (id) => dispatch(subSubTopicActions.getSubSubTopic(id)),
		getSubtopic: (id) => dispatch(subtopicActions.getSubTopic(id)),
		getTest: (id) => dispatch(testActions.getTest(id)),
		getProblemSets: (params) => dispatch(problemSetActions.getAllProblemSets(params)),
	})
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminSubSubtopicMain));
