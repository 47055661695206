import React, { Component } from 'react';
import ReadOnlyEditor from '../../editor/ReadOnlyEditor'

class LinkedQuestionDialog extends Component {
    state = {
        linkedQuestion: {},
        ready: false,
        questions: [],
    }

    componentDidMount() {
        let updatedQuestions;
        const { questions, currentQuestion } = this.props;
        updatedQuestions = questions.filter(x => x.id !== currentQuestion)
        this.setState({ questions: updatedQuestions })
    }

    linkQuestion = async (questionId) => {
        const { handleLinkedQuestion, close } = this.props;
        await handleLinkedQuestion(questionId);
        await close();
    }

    render() {
        const { question, questionType } = this.props;
        const { questions } = this.state;

        let selectedId = questionType === "Right" ? question?.link_right_question_id : question?.link_wrong_question_id;
        return (
            <div className={'link-question-main-div'}>
                <ul>
                    <QuestionList onClick={this.linkQuestion}
                                  questions={questions}
                                  selectedId={selectedId}/>
                </ul>
            </div>
        );

    }
}

const QuestionList = ({questions, onClick, selectedId }) =>{
    if (!questions?.length) {
        return (<div>No Question Found</div>)
    }

    return(questions?.map((question, index) => {
        return (
            <li onClick={() => onClick(question.id)} key={index}>
                {selectedId === question.id && <i className="fa fa-check text-primary" />}
                <div>
                    <ReadOnlyEditor content={question.question} />
                    {question.is_free_response && <span className="text-success"> (Free Response) </span> }
                </div>
            </li>
        );
    }));
}

export { LinkedQuestionDialog };
