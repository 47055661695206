import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HomeQuestions from '../question/HomeQuestions';
import * as actQuestionActions from '../../../../modules/actQuestion/action';
import * as animationActions from '../../../../modules/animation/action'
import './HomeSatQuestionMain.scss';
import TopicMainClass from "./TopicMainClass";
import * as dialogActions from "../../../../modules/dialog/action";

class HomeActQuestionMain extends TopicMainClass {

    async componentDidMount() {
        const { match: { params: { actQuestionId } }, getActQuestions } = this.props;
        let error404 = false, published;
        const actTopicQuestion = await getActQuestions(actQuestionId).catch(error=> {
            error404 = error.response?.status === 404;
        });
        published = actTopicQuestion?.isPublish && actTopicQuestion?.topic_published;
        if((actTopicQuestion?.sat_id && !actTopicQuestion?.act_id) || error404 || !published){
            return this.props.history.push('/home/not_found')
        }
    }

    componentDidUpdate(prevProps) {
        const { currentQuestion } = this.props;
        if(prevProps.currentQuestion !== currentQuestion){
            this.setState({currentQuestion})
        }
    }

    takeBack = () => {
        const { actQuestions, location } = this.props
        const queryString = new URLSearchParams(location.search);
        const mockQueryString = queryString && queryString.get('mock') ? '&area=mock' : '';
        const dashboard = !!(queryString && queryString.get('dashboard'))
        if (dashboard) {
            return this.props.history.push({pathname: '/home', params: { assignment: true}});
        }
        this.props.history.push('/home/acts?act=' + actQuestions.act_id + '&act_question=' + actQuestions.id + mockQueryString)
    }

    render() {
        const { match: { params: { actQuestionId } }, actQuestions } = this.props;
        const ContainerView = this.ContainerView;
        let topicType = 'HomeActQuestionTopic';
        // alert(topicType)
        return (
            <ContainerView title={actQuestions.title}>
                <HomeQuestions topicType={topicType}
                               topicId={actQuestionId}
                               section="HomeActQuestionTopic"
                               takeBack={this.takeBack}/>
            </ContainerView>
        );
    }
}

const mapStateToProps = state => {
    return ({
        loading: state.animation,
        actQuestions: state.actQuestions,
        currentQuestion: state.temporaryData.currentQuestion
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        startAnimation: () => dispatch(animationActions.start()),
        endAnimation: () => dispatch(animationActions.end()),
        getActQuestions: (id) => dispatch(actQuestionActions.getActQuestion(id)),
        openDialog: (config, options) => dispatch(dialogActions.open(config, options)),
    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeActQuestionMain));
