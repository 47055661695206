import { draw } from "patternomaly";
import _ from "lodash";

export const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const getSatScoreData = (userSatScores, timedType, satScoreType, data) => {
    const verbalColor = {light: '#fbc1d5', dark: '#f56597'},
        mathColor = {light: '#85e0fe', dark: '#0199cb'},
        compositeColor = {light: '#c1f29d', dark: '#66cc1c'};

    let verbalArr = [], mathArr = [], grandArr = [], labels = [], verbalBackgroundColor = [],
        mathsBackgroundColor = [], compositeBackgroundColor = [];

    if(satScoreType === "Percentile"){
        let verbalArr = {user: [], net: []}, mathArr = {user: [], net: []}, grandArr = {user: [], net: []},
            verbalBackgroundColor = {user: [], net: []}, mathsBackgroundColor = {user: [], net: []},
            compositeBackgroundColor = {user: [], net: []},
            percentData = {records: data, selectedColumn: 0, valueColumn: 1, value: 0};


        // eslint-disable-next-line array-callback-return
        userSatScores && userSatScores.map((tt) => {
            if (["Timed", "Untimed"].includes(timedType)) {
                if (tt.timed) {
                    const verbalScore = tt.timed["Reading"] && tt.timed["Writing"], mathScore = tt.timed["MathNoCalc"] && tt.timed["MathCalc"];
                    if ((verbalScore && timedType === 'Timed') || (!verbalScore && timedType === 'Untimed')) {
                        percentData = {...percentData, selectedColumn: 0,value: tt.total_verbal_score};
                        verbalArr.net.push(getPercentage({...percentData, valueColumn: 1}))
                        verbalArr.user.push(getPercentage({...percentData, valueColumn: 2}))
                    } else {
                        verbalArr.user.push(0)
                        verbalArr.net.push(0)
                    }
                    if ((mathScore && timedType === 'Timed') || (!mathScore && timedType === 'Untimed')) {
                        percentData = {...percentData, selectedColumn: 3,value: tt.total_math_score};
                        mathArr.net.push(getPercentage({...percentData, valueColumn: 4}))
                        mathArr.user.push(getPercentage({...percentData, valueColumn: 5}))
                    } else {
                        mathArr.user.push(0)
                        mathArr.net.push(0)
                    }
                    if ((!Object.values(tt.timed).includes(false) && timedType === 'Timed') || (!Object.values(tt.timed).includes(true) && timedType === 'Untimed')) {
                        percentData = {...percentData, selectedColumn: 6,value: tt.grand_score};
                        grandArr.net.push(getPercentage({...percentData, valueColumn: 7}))
                        grandArr.user.push(getPercentage({...percentData, valueColumn: 8}))
                    } else {
                        grandArr.user.push(0)
                        grandArr.net.push(0)
                    }
                    labels.push(`${tt.sat_title}${tt.type === "RealSat" ? '*' : ''}`)

                    verbalBackgroundColor.user.push(timedType === 'Timed' ? draw('diagonal', verbalColor.dark) : verbalColor.dark)
                    mathsBackgroundColor.user.push(timedType === 'Timed' ? draw('diagonal', mathColor.dark) : mathColor.dark)
                    compositeBackgroundColor.user.push(timedType === 'Timed' ?  draw('diagonal', compositeColor.dark) : compositeColor.dark)

                    verbalBackgroundColor.net.push(timedType === 'Timed' ? draw('diagonal', verbalColor.light) : verbalColor.light)
                    mathsBackgroundColor.net.push(timedType === 'Timed' ? draw('diagonal', mathColor.light) : mathColor.light)
                    compositeBackgroundColor.net.push(timedType === 'Timed' ?  draw('diagonal', compositeColor.light) : compositeColor.light)
                }
            } else {
                labels.push(`${tt.sat_title}${tt.type === "RealSat" ? '*' : ''}`)

                percentData = {...percentData, value: tt.total_verbal_score,selectedColumn: 0};
                verbalArr.net.push(getPercentage({...percentData, valueColumn: 1}))
                verbalArr.user.push(getPercentage({...percentData, valueColumn: 2}))

                percentData = {...percentData, value: tt.total_math_score, selectedColumn: 3};
                mathArr.net.push(getPercentage({...percentData, valueColumn: 4}))
                mathArr.user.push(getPercentage({...percentData, valueColumn: 5}))

                percentData = {...percentData, value: tt.grand_score, selectedColumn: 6};
                grandArr.net.push(getPercentage({...percentData, valueColumn: 7}))
                grandArr.user.push(getPercentage({...percentData, valueColumn: 8}))

                verbalBackgroundColor.user.push(tt.timed && (tt.timed["Reading"] && tt.timed["Writing"]) ? draw('diagonal', verbalColor.dark) : verbalColor.dark)
                mathsBackgroundColor.user.push(tt.timed && (tt.timed["MathNoCalc"] && tt.timed["MathCalc"]) ? draw('diagonal', mathColor.dark) : mathColor.dark)
                compositeBackgroundColor.user.push(tt.timed && (Object.values(tt.timed).includes(false)) ? compositeColor.dark : draw('diagonal', compositeColor.dark))

                verbalBackgroundColor.net.push(tt.timed && (tt.timed["Reading"] && tt.timed["Writing"]) ? draw('diagonal', verbalColor.light) : verbalColor.light)
                mathsBackgroundColor.net.push(tt.timed && (tt.timed["MathNoCalc"] && tt.timed["MathCalc"]) ? draw('diagonal', mathColor.light) : mathColor.light)
                compositeBackgroundColor.net.push(tt.timed && (Object.values(tt.timed).includes(false)) ? compositeColor.light : draw('diagonal', compositeColor.light))
            }
        })


        const scoreDataSets = [
            {
                label: 'Verbal User %',
                backgroundColor: verbalBackgroundColor.user,
                borderColor: verbalColor.dark,
                data: verbalArr.user,
                stack: "Verbal",
            },
            {
                label: 'Verbal Net Rep %',
                backgroundColor: verbalBackgroundColor.net,
                borderColor: verbalColor.light,
                data: verbalArr.net,
                stack: "Verbal",
            },
            {
                label: 'Math User %',
                backgroundColor: mathsBackgroundColor.user,
                borderColor: mathColor.dark,
                data: mathArr.user,
                stack: "Math",
            },
            {
                label: 'Math Net Rep %',
                backgroundColor: mathsBackgroundColor.net,
                borderColor: mathColor.light,
                data: mathArr.net,
                stack: "Math",
            },
            {
                label: 'Composite User %',
                backgroundColor: compositeBackgroundColor.user,
                borderColor: compositeColor.dark,
                data: grandArr.user,
                stack: "Composite",
            },
            {
                label: 'Composite Net Rep %',
                backgroundColor: compositeBackgroundColor.net,
                borderColor: compositeColor.light,
                data: grandArr.net,
                stack: "Composite",
            }
        ];
        return ({labels: labels, datasets: scoreDataSets});
    }

    // eslint-disable-next-line array-callback-return
    userSatScores && userSatScores.map((tt) => {
        if (["Timed", "Untimed"].includes(timedType)) {
            if (tt.timed) {
                const verbalScore = tt.timed["Reading"] && tt.timed["Writing"], mathScore = tt.timed["MathNoCalc"] && tt.timed["MathCalc"];
                if ((verbalScore && timedType === 'Timed') || (!verbalScore && timedType === 'Untimed')) {
                    verbalArr.push(tt.total_verbal_score)
                } else {
                    verbalArr.push(0)
                }
                if ((mathScore && timedType === 'Timed') || (!mathScore && timedType === 'Untimed')) {
                    mathArr.push(tt.total_math_score)
                } else {
                    mathArr.push(0)
                }
                if ((!Object.values(tt.timed).includes(false) && timedType === 'Timed') || (!Object.values(tt.timed).includes(true) && timedType === 'Untimed')) {
                    grandArr.push(tt.grand_score)
                } else {
                    grandArr.push(0)
                }
                labels.push(`${tt.sat_title}${tt.type === "RealSat" ? '*' : ''}`)
                verbalBackgroundColor.push(timedType === 'Timed' ? draw('diagonal', verbalColor.dark) : verbalColor.dark)
                mathsBackgroundColor.push(timedType === 'Timed' ? draw('diagonal', mathColor.dark) : mathColor.dark)
                compositeBackgroundColor.push(timedType === 'Timed' ?  draw('diagonal', compositeColor.dark) : compositeColor.dark)
            }
        } else {
            verbalArr.push(tt.total_verbal_score)
            mathArr.push(tt.total_math_score)
            grandArr.push(tt.grand_score)
            labels.push(`${tt.sat_title}${tt.type === "RealSat" ? '*' : ''}`)
            verbalBackgroundColor.push(tt.timed && (tt.timed["Reading"] && tt.timed["Writing"]) ? draw('diagonal', verbalColor.dark) : verbalColor.dark)
            mathsBackgroundColor.push(tt.timed && (tt.timed["MathNoCalc"] && tt.timed["MathCalc"]) ? draw('diagonal', mathColor.dark) : mathColor.dark)
            compositeBackgroundColor.push(tt.timed && (Object.values(tt.timed).includes(false)) ? '#66cc1c' : draw('diagonal', '#66cc1c'))
        }
    })
    const scoreDataSets = [
        {
            label: 'Verbal',
            backgroundColor: verbalBackgroundColor,
            borderColor: verbalColor.dark,
            data: verbalArr,
            xAxisID: "bar-x-axis",
            yAxisID: "bar-y-axis",
        }, {
            label: 'Math',
            backgroundColor: mathsBackgroundColor,
            borderColor: mathColor.dark,
            data: mathArr,
            xAxisID: "bar-x-axis",
            yAxisID: "bar-y-axis",
        },
        {
            label: 'Composite',
            backgroundColor: compositeBackgroundColor,
            borderColor: compositeColor.dark,
            data: grandArr,
            xAxisID: "bar-x-axis",
            yAxisID: "bar-y-axis",
        }]
    return ({labels: labels, datasets: scoreDataSets});
}

export const getActScoreData = (userActScores, timedType, actScoreType, data) => {
    let englishArr = [], mathematicsArr = [], readingArr = [], scienceArr = [], actGrandArr = [], actLabels = [],
        englishBackgroundColor = [], mathematicsBackgroundColor = [], readingBackgroundColor = [],
        scienceBackgroundColor = [], actCompositeBackgroundColor = [],
        percentData = {records: data, selectedColumn: 0, valueColumn: 1, value: 0};

    // eslint-disable-next-line array-callback-return
    userActScores && userActScores.map((tt) => {
        if (["Timed", "Untimed"].includes(timedType)) {
            if (tt.timed) {
                if ((tt.timed["English"] && timedType === 'Timed') || (!tt.timed["English"] && timedType === 'Untimed')) {
                    percentData = {...percentData, selectedColumn: 0,valueColumn: 1, value: tt.total_english_score}
                    if(actScoreType === "Percentile"){
                        englishArr.push(getPercentage(percentData))
                    } else {
                        englishArr.push(tt.total_english_score)
                    }
                } else {
                    englishArr.push(0)
                }
                if ((tt.timed["Mathematics"] && timedType === 'Timed') || (!tt.timed["Mathematics"] && timedType === 'Untimed')) {
                    percentData = {...percentData, selectedColumn: 2,valueColumn: 3, value: tt.total_math_score}
                    if(actScoreType === "Percentile"){
                        mathematicsArr.push(getPercentage(percentData))
                    } else {
                        mathematicsArr.push(tt.total_math_score)
                    }
                } else {
                    mathematicsArr.push(0)
                }
                if ((tt.timed["Reading"] && timedType === 'Timed') || (!tt.timed["Reading"] && timedType === 'Untimed')) {
                    percentData = {...percentData, selectedColumn: 4,valueColumn: 5, value: tt.total_reading_score}
                    if(actScoreType === "Percentile"){
                        readingArr.push(getPercentage(percentData))
                    } else {
                        readingArr.push(tt.total_reading_score)
                    }
                } else {
                    readingArr.push(0)
                }
                if ((tt.timed["Science"] && timedType === 'Timed') || (!tt.timed["Science"] && timedType === 'Untimed')) {
                    percentData = {...percentData, selectedColumn: 6,valueColumn: 7, value: tt.total_science_score}
                    if(actScoreType === "Percentile"){
                        scienceArr.push(getPercentage(percentData))
                    } else {
                        scienceArr.push(tt.total_science_score)
                    }
                } else {
                    scienceArr.push(0)
                }
                if ((!Object.values(tt.timed).includes(false) && timedType === 'Timed') || (!Object.values(tt.timed).includes(true) && timedType === 'Untimed')) {
                    percentData = {...percentData, selectedColumn: 8,valueColumn: 9, value: tt.grand_score}
                    if(actScoreType === "Percentile"){
                        actGrandArr.push(getPercentage(percentData))
                    } else {
                        actGrandArr.push(tt.grand_score)
                    }
                } else {
                    actGrandArr.push(0)
                }
                actLabels.push(`${tt.act_title}${tt.type === "RealAct" ? '*' : ''}`)
                englishBackgroundColor.push(timedType === 'Timed' ? draw('diagonal', '#f56597') : '#f56597')
                mathematicsBackgroundColor.push(timedType === 'Timed' ? draw('diagonal', '#0199cb') : '#0199cb')
                readingBackgroundColor.push(timedType === 'Timed' ? draw('diagonal', '#37ced3') : '#37ced3')
                scienceBackgroundColor.push(timedType === 'Timed' ? draw('diagonal', '#a8324b') : '#a8324b')
                actCompositeBackgroundColor.push(timedType === 'Timed' && !(Object.values(tt.timed).includes(false)) ?  draw('diagonal', '#66cc1c') : '#66cc1c')
            }
        } else {
            if(actScoreType === "Percentile"){
                englishArr.push(getPercentage({...percentData, selectedColumn: 0,valueColumn: 1, value: tt.total_english_score}))
                mathematicsArr.push(getPercentage({...percentData, selectedColumn: 2, valueColumn: 3, value: tt.total_math_score}))
                readingArr.push(getPercentage({...percentData, selectedColumn: 4, valueColumn: 5, value: tt.total_reading_score}))
                scienceArr.push(getPercentage({...percentData, selectedColumn: 6, valueColumn: 7, value: tt.total_science_score}))
                actGrandArr.push(getPercentage({...percentData, selectedColumn: 8, valueColumn: 9, value: tt.grand_score}))
            } else {
                englishArr.push(tt.total_english_score)
                mathematicsArr.push(tt.total_math_score)
                readingArr.push(tt.total_reading_score)
                scienceArr.push(tt.total_science_score)
                actGrandArr.push(tt.grand_score)
            }
            actLabels.push(`${tt.act_title}${tt.type === "RealAct" ? '*' : ''}`)
            englishBackgroundColor.push(tt.timed && tt.timed["English"] ? draw('diagonal', '#f56597') : '#f56597')
            mathematicsBackgroundColor.push(tt.timed && tt.timed["Mathematics"] ? draw('diagonal', '#0199cb') : '#0199cb')
            readingBackgroundColor.push(tt.timed && tt.timed["Reading"] ? draw('diagonal', '#37ced3') : '#37ced3')
            scienceBackgroundColor.push(tt.timed && tt.timed["Science"] ? draw('diagonal', '#a8324b') : '#a8324b')
            actCompositeBackgroundColor.push(tt.timed && (Object.values(tt.timed).includes(false)) ? '#66cc1c' : draw('diagonal', '#66cc1c'))
        }
    })
    const actScoreDataSets = [
        {
            label: 'English',
            backgroundColor: englishBackgroundColor,
            borderColor: '#f56597',
            data: englishArr,
            xAxisID: "bar-x-axis",
            yAxisID: "bar-y-axis",
        },
        {
            label: 'Mathematics',
            backgroundColor: mathematicsBackgroundColor,
            borderColor: '#0199cb',
            data: mathematicsArr,
            xAxisID: "bar-x-axis",
            yAxisID: "bar-y-axis",
        },
        {
            label: 'Reading',
            backgroundColor: readingBackgroundColor,
            borderColor: '#37ced3',
            data: readingArr,
            xAxisID: "bar-x-axis",
            yAxisID: "bar-y-axis",
        },
        {
            label: 'Science',
            backgroundColor: scienceBackgroundColor,
            borderColor: '#a8324b',
            data: scienceArr,
            xAxisID: "bar-x-axis",
            yAxisID: "bar-y-axis",
        },
        {
            label: 'Composite',
            backgroundColor: actCompositeBackgroundColor,
            borderColor: '#66cc1c',
            data: actGrandArr,
            xAxisID: "bar-x-axis",
            yAxisID: "bar-y-axis",
        }]
    return ({labels: actLabels, datasets: actScoreDataSets});
}

export const getProgressData = (progressData, type = 'Sat') => {
    // User ACT's Progress Data
    let progressLabels = [], progressPercentage = [];
    progressData && progressData.map((topic) => {
        if (type === 'Sat') {
            progressLabels.push(topic.sat_title)
        } else {
            progressLabels.push(topic.act_title)
        }
        progressPercentage.push(topic.percentage)
    })

    const progressDataSets = [{
        label: `${type} Progress`,
        backgroundColor: '#0199cb',
        borderColor: '#0199cb',
        data: progressPercentage,
        xAxisID: "bar-x-axis",
        yAxisID: "bar-y-axis",
    }];

    return ({labels: progressLabels, datasets: progressDataSets})
}

export const getFinalTestScoreData = (testScores, test_type = 'All', type = 'sat') => {
    let realTestMathArr = [], mockTestMathArr = [], testLabel = [];
    if (testScores) {
        var satTestScoresData = _.mapValues(_.groupBy(testScores, `${type}_id`),
            testList => testList.map(test => _.omit(test, `${type}_id`)));
        _.each(satTestScoresData, (scores, question_id) => {
            if (scores) {
                let title;
                scores && scores.map(tt => {
                    if (tt.real_test) {
                        realTestMathArr.push(test_type === "Mock" ? 0 : tt.math_score);
                    } else {
                        mockTestMathArr.push(test_type === "Real" ? 0 : tt.math_score);
                    }
                    title = type === 'sat' ? tt.sat_title : tt.act_title;
                    return title;
                })
                testLabel.push(title);
            }
        });
    }

    const satTestScoreDataSets = [
        {
            label: 'Real Test',
            backgroundColor: '#0199cb',
            borderColor: '#0199cb',
            data: realTestMathArr,
            xAxisID: "bar-x-axis",
            yAxisID: "bar-y-axis",
        },
        {
            label: 'Mock Test',
            backgroundColor: "#f56597",
            borderColor: "#f56597",
            data: mockTestMathArr,
            xAxisID: "bar-x-axis",
            yAxisID: "bar-y-axis",
        }
    ];
    return ({labels: testLabel, datasets: satTestScoreDataSets});
}

export const getCorrectIncorrectData = (correctIncorrectData) => {
    let correct = [], incorrect = [], complete = [], correctIncorrectLabels = [], dataSets = [];
    correctIncorrectData && correctIncorrectData.data && correctIncorrectData.data.map((tt) => {
        correctIncorrectLabels.push(correctIncorrectData.data.length < 6 ? tt.week_range : correctIncorrectData.data.length < 13 ? monthNames[tt.month - 1] : tt.date)
        correct.push(tt.total_correct)
        incorrect.push(tt.total_incorrect)
        complete.push(tt.total_correct + tt.total_incorrect)
    })

   dataSets.push({
       label: "Correct",
       backgroundColor: '#0199cb',
       borderColor: '#0199cb',
       data: correct,
       xAxisID: "bar-x-axis",
       yAxisID: "bar-y-axis",
   });
   dataSets.push({
       label: "Incorrect",
       backgroundColor: '#f56597',
       borderColor: '#f56597',
       data: incorrect,
       xAxisID: "bar-x-axis",
       yAxisID: "bar-y-axis",
   });
   dataSets.push({
       label: "Total",
       backgroundColor: '#66cc1c',
       borderColor: '#66cc1c',
       data: complete,
       xAxisID: "bar-x-axis",
       yAxisID: "bar-y-axis",
   });
   return ({labels: correctIncorrectLabels, datasets: dataSets});
}

export const getTimeSpentData = (timeSpentByUserData) => {
    let labels = [], totalTimeSpent = [], dataSets = [];

    timeSpentByUserData && timeSpentByUserData.data.map((tt) => {
        labels.push(timeSpentByUserData.data.length < 6 ? tt.week_range : timeSpentByUserData.data.length < 13 ? monthNames[tt.month - 1] : tt.date)
        totalTimeSpent.push(tt.total_time_spent)
    })

    dataSets.push({
        label: "Total time",
        backgroundColor: '#0199cb',
        borderColor: '#0199cb',
        data: totalTimeSpent,
        xAxisID: "bar-x-axis",
        yAxisID: "bar-y-axis",
    });
    return ({labels: labels, datasets: dataSets});
}

export const getAvgTimeSpentData = (avgTimeSpentByUserData) => {
    // User average Time Spent By user Data
    let avgTimeSpentBeforeAnswer = [], avgTimeSpentAfterAnswer = [], labels = [], dataSets = [];
    avgTimeSpentByUserData && avgTimeSpentByUserData.data.map((tt) => {
        labels.push(avgTimeSpentByUserData.data.length < 6 ? tt.week_range : avgTimeSpentByUserData.data.length < 13 ? monthNames[tt.month - 1] : tt.date)
        avgTimeSpentBeforeAnswer.push(tt.before_answer)
        avgTimeSpentAfterAnswer.push(tt.after_wrong_answer)
    })
    dataSets.push({
        label: "Before Answer",
        backgroundColor: '#0199cb',
        borderColor: '#0199cb',
        data: avgTimeSpentBeforeAnswer,
        xAxisID: "bar-x-axis",
        yAxisID: "bar-y-axis",
    });
    dataSets.push({
        label: "After Wrong Answer",
        backgroundColor: '#f56597',
        borderColor: '#f56597',
        data: avgTimeSpentAfterAnswer,
        xAxisID: "bar-x-axis",
        yAxisID: "bar-y-axis",
    });
    return ({labels: labels, datasets: dataSets});
}

const getPercentage = ({records, selectedColumn, valueColumn, value}) =>{
    let answer = 0;
    try {
        _.each(records, (record, index) => {
            if (parseInt(record[selectedColumn]) === value) {
                answer = parseInt(record[valueColumn]);
            }
        });
    } catch (e) {
    }
    return answer;
}
