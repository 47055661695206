import { API } from '../../api';
import {
    RECEIVE_USER_SAT_PROBLEM_SET_TYPE_PROGRESS,
    RECEIVE_USER_SAT_SEQUENCES_PROGRESS,
    RECEIVE_USER_SAT_TYPE_PROGRESS
} from "../constant";
import { checkSessionExpired } from "..";

// ------------- Start User Sat Sequence SatQuestions Type Progress Actions -------------
const receiveUserSatSequenceTypeProgress = (data) => ({ type: RECEIVE_USER_SAT_TYPE_PROGRESS, data });

export const getUserSatSequenceTypeProgress = (attributes) => {
    return (dispatch) => {
        return API.post('/user_progresses/get_sat_type_progress', attributes).then(({ data }) => {
            dispatch(receiveUserSatSequenceTypeProgress(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}
// ------------- End User Sat Sequence SatQuestions Type Progress Actions -------------

// ------------- Start User Sat ProblemSet SatQuestions Type Progress Actions -------------
const receiveUserSatProblemSetTypeProgress = (data) => ({ type: RECEIVE_USER_SAT_PROBLEM_SET_TYPE_PROGRESS, data });

export const getUserSatProblemSetTypeProgress = (attributes) => {
    return (dispatch) => {
        return API.post('/user_progresses/get_sat_problem_set_type_progress', attributes).then(({ data }) => {
            dispatch(receiveUserSatProblemSetTypeProgress(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}
// ------------- End User Sat ProblemSets SatQuestions Type Progress Actions -------------

// ------------- Start User Sat SatQuestions Sequence Actions -------------
const receiveUserSatSequencesProgress = (data) => ({ type: RECEIVE_USER_SAT_SEQUENCES_PROGRESS, data });

export const getUserSatSequencesProgress = (attributes) => {
    return (dispatch) => {
        return API.post('/user_progresses/get_user_sat_sequences_progress', attributes).then(({ data }) => {
            dispatch(receiveUserSatSequencesProgress(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

// ------------- End User Sat SatQuestions Sequence Actions -------------
