import * as dbUtil from '../../api/dbUtil';
import {API} from '../../api';
import {
    RECEIVE_ALL_QUESTIONS,
    RECEIVE_MULTIPLE_QUESTION,
    RECEIVE_QUESTION,
    REMOVE_QUESTION,
    UPDATE_QUESTION,
    RESET_QUESTION_DATA,
} from "../constant";
import { checkSessionExpired } from "..";

const receiveAll = (data) => ({type: RECEIVE_ALL_QUESTIONS, data});
const receive = (data) => ({ type: RECEIVE_QUESTION, data });
const receiveMultiple = (data) => ({type: RECEIVE_MULTIPLE_QUESTION, data });
const updateQuestionData = (data) => ({ type: UPDATE_QUESTION, data });
const removeQuestion = (data) => ({ type: REMOVE_QUESTION, data });
export const resetQuestionData = () =>  ({ type: RESET_QUESTION_DATA })

// --------------------------------- Question -----------------------------
export const getAll = (endpoint, params) => {
    return (dispatch) => {
        return dbUtil.fetchMany(endpoint, params).then(({data}) => {
            dispatch(receiveAll(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const get = (endpoint, id) => {
    return (dispatch) => {
        return dbUtil.fetch(endpoint, id).then(({data}) => {
            dispatch(receive(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const create = (endpoint, attributes) => {
    return (dispatch) => {
        return dbUtil.create(endpoint, attributes).then(({data}) => {
            dispatch(receive(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const createMultiple = (endpoint, attributes) => {
    return (dispatch) => {
        return dbUtil.create(endpoint, attributes).then(({ data }) => {
            dispatch(receiveMultiple(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const update = (endpoint, id, attributes) => {
    return (dispatch) => {
        return dbUtil.update(endpoint, id, attributes).then(({data}) => {
            dispatch(updateQuestionData(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateQuestionPositions = (questions, topicType) => {
    return (dispatch) => {
        const url = topicType !== 'subTopic' ? "/questions/update_positions" : "/question_subsubtopics/update_positions"
        return API.put(url, questions).then(({data}) => {
            dispatch(receiveAll(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const destroy = (endpoint, id) => {
    return (dispatch) => {
        return dbUtil.destroy(endpoint, id).then(({ data }) => {
            dispatch(removeQuestion(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

// --------------------------------- Question -----------------------------

export const getAllQuestions = (params) => {
    return (dispatch) => {
        return dbUtil.fetchMany('questions', params).then(({data}) => {
            dispatch(receiveAll(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getQuestion = (id) => {
    return (dispatch) => {
        return dbUtil.fetch('questions', id).then(({data}) => {
            dispatch(receive(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const createQuestion = (attributes) => {
    return (dispatch) => {
        return dbUtil.create('questions', attributes).then(({data}) => {
            dispatch(receive(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const createMultipleQuestion = (attributes) => {
    return (dispatch) => {
        return dbUtil.create('questions/create_multiple', attributes).then(({data}) => {
            dispatch(receiveMultiple(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateQuestion = (id, attributes) => {
    return (dispatch) => {
        return dbUtil.update('questions', id, attributes).then(({data}) => {
            dispatch(updateQuestionData(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const deleteQuestion = (id) => {
    return (dispatch) => {
        return dbUtil.destroy('questions', id).then(({data}) => {
                dispatch(removeQuestion(data));
                return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

// --------------------------------------------  QUESTION   -------------------------------------------

export const linkProblemSet = (data) => {
    return (dispatch) => {
        return API.post('/questions/link_problemset', data).then(({data}) => {
            dispatch(updateQuestionData(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const linkQuestion = (data) => {
    return (dispatch) => {
        return API.post('/questions/link_question', data).then(({data}) => {
            dispatch(updateQuestionData(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const unLinkProblemSet = (data) => {
    return (dispatch) => {
        return API.post('/questions/unlink_problemset', data).then(({data}) => {
            dispatch(updateQuestionData(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const unLinkQuestion = (data) => {
    return (dispatch) => {
        return API.post('/questions/unlink_question', data).then(({data}) => {
            dispatch(updateQuestionData(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}
