import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AdminQuestions from '../topics/question/AdminQuestions';
import * as actActions from '../../../../modules/act/action';
import '../sat/AdminSatTestMain.scss';

class AdminActTestMain extends Component {
    state = {
        title: "",
        actType: "",
        actId: ""
    }
    async componentDidMount() {
        window.scrollTo(0, 0);
        let error404;
        const { match: { params: { actId, actType } }, getAct } = this.props;
        await getAct(actId).then((dd) => {
            let typeTitle = actType.includes('English')
                ? 'English' : actType.includes('Math')
                    ? 'Math' : actType.includes('Reading')
                        ? 'Reading' : 'Science'
            this.setState({
                title: dd.title + " - " + typeTitle,
                actType,
                actId
            })
        }).catch(error=>{
            error404 = error.response?.status === 404;
        });
        if(error404){
            return this.props.history.push('/admin/not_found')
        }
    }

    takeBack = () => {
        const { match: { params: { actId } } } = this.props;
        this.props.history.push('/admin/acts?act=' + actId)
    }

    render() {
        const { title, actId, actType } = this.state;
        const { topicType } = this.props

        return (
            <div className="row" >
                <div className="content-wrapper-before gradient-45deg-indigo-purple"/>
                <div className="col s12 contentCenter_wrap">
                    <div className="container">
                        <div className="dash_top_section"/>
                        <div className="row vertical-modern-dashboard">
                            <div className="col-12">
                                <div className="card animate fadeLeft">
                                    <div className="question_sec card-content">
                                        <div className="dashtitle_sec d-flex flex-wrap align-items-center w-100">
                                            <button onClick={() => this.takeBack()} className="btn btn-default">
                                                <i className="ico backicon"/>
                                                <span className="btntxt">Back</span>
                                            </button>
                                        </div>
                                        <div className="quesHeader d-flex flex-wrap align-items-center text-center w-100">
                                            <h1 className=" quesTitle d-inline-block position-relative">
                                                {title ? title : ''}
                                            </h1>
                                        </div>

                                        {topicType && <AdminQuestions
                                            topicType={topicType}
                                            topicId={actId}
                                            actType={actType}
                                            section={topicType}
                                        />}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        );
    }
}

const mapDispatchToProps = dispatch => {
    return ({
        getAct: (id) => dispatch(actActions.getTestAct(id))
    })
}

const mapStateToProps = ({ acts }) => ({ acts })

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminActTestMain));
