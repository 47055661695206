import {
    RECEIVE_ALL_GRAMMAR_TOPICS,
    RECEIVE_GRAMMAR_TOPIC,
    UPDATE_GRAMMAR_TOPIC,
    REMOVE_GRAMMAR_TOPIC,
    PUBLISH_GRAMMAR_TOPIC
} from '../constant';

export const grammarTopicReducer = (state = [], action) => {
    let newState;
    switch (action.type) {
        case RECEIVE_ALL_GRAMMAR_TOPICS:
            return action.data
        case RECEIVE_GRAMMAR_TOPIC:
            for (let i = 0; i < state.length; i++) {
                if (action.data.id === state[i].id) {
                    return state;
                }
            }
            return Object.assign([], state, [...state, action.data])
        case UPDATE_GRAMMAR_TOPIC:
            newState = state.map(obj => (action.data.id === obj.id) ? action.data : obj);
            return newState;
        case REMOVE_GRAMMAR_TOPIC:
            newState = state.filter(obj => (action.data.id !== obj.id));
            return newState;
        case PUBLISH_GRAMMAR_TOPIC:
            newState = state.map(obj => (action.data.id === obj.id) ? action.data : obj);
            return newState;
        default:
            return state;
    }
}
