import { OPEN_DIALOG_BOX, CLOSE_DIALOG_BOX } from '../constant';

export const dialogReducer = (state = {}, action) => {
    const { type, config, options } = action;
    switch(type) {
        case OPEN_DIALOG_BOX:
            return {
                open: true,
                properties: {
                    ...config,
                    ...options,
                },
            }
        case CLOSE_DIALOG_BOX:
            return {
                open: false,
                properties: null,
            }
        default:
            return state;
    }
}
