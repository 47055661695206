import React, {Component} from 'react';
import Answer from './Answer';
import "./AnswerList.scss";

class AnswerList extends Component {
    render() {
        const {answers, selectedAnswer, setSelectedAnswer, currentQuestionProg} = this.props;

        return (
            <ol className="answer-list">
                {answers.map((answer, idx) => {
                    return (
                        <li key={answer.id}>
                            <Answer
                                answer={answer}
                                selectedAnswer={selectedAnswer}
                                setSelectedAnswer={setSelectedAnswer}
                                currentQuestionProg={currentQuestionProg}
                                idx={idx}/>
                        </li>
                    )
                })}
            </ol>
        );
    }
}

export default AnswerList;
