import React, { Component } from 'react';
import ACTItem from './ACTItem';
import "../sat/SortableSAT.scss";
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";
import arrayMove from "array-move";

const DragHandle = SortableHandle(() =>
    <span className="sortable-handle">::</span>
)

const SortableItem = SortableElement(({ act, showDragHandle = false, loading = false }) => {
    return (
        <li className="sortable-sat w-100" id={`T${act.id}`} style={{ listStyle: "none", display: "flex", alignItems: "flex-start" }}>
            <div className="card animate fadeLeft w-100">
                <div className="users_sec card-content p-0 d-flex">
                    {showDragHandle === true && loading === false && <DragHandle />}
                    <ACTItem act={act}
                             showDragHandle={showDragHandle}/>
                </div>
            </div>
        </li>
    )
})

const SortableList = SortableContainer((props) => {
    const { acts } = props;
    return (
        <ul className="sortable-admin-sat-list pl-0 w-100">
            {acts.map((act, index) => {
                return (
                    <SortableItem key={act.id}
                                  index={index}
                                  act={act}
                                  id={act.id}
                                  {...props}/>
                )
            })}
        </ul>
    )
})

class SortableHomeACT extends Component {
    state = {
        acts: [],
    }

    componentDidMount() {
        const { acts } = this.props;
        this.setState({ acts })
    }


    // React Sortable Method used during mouse events to sort the list
    // Added functionality to this method in order to handle the question addition, deletion, and manipulation
    // mouseEvent is a param automatically passed in by React Sortable when moving questions
    // => we add the param here so we are able to set questions dynamically
    // When sorting the list, local state questions are used.
    // When adding to the list, a new copy of the questions state is used (see f(x)sortableAddQuestion)
    onSortEnd = async (options, mouseEvent, acts = this.props.acts) => {
        const { updateUserActsPositions } = this.props;
        const { oldIndex, newIndex } = options;
        if(oldIndex === newIndex) return null;
        const updatedSats = arrayMove(acts, oldIndex, newIndex)
        const actData = await updateUserActsPositions(updatedSats);
        this.setState({ acts: actData })
    }

    render() {
        return (
            <div className="sortable-sats">
                <SortableList useDragHandle
                              {...this.props}
                              onSortEnd={this.onSortEnd}
                              helperClass='sortableHelper'
                              lockAxis="y"
                              useWindowAsScrollContainer={true}/>
            </div>
        );
    }
}

export default SortableHomeACT;
