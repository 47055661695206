import React, { Component } from 'react';
import AdminAnswerContent from './AdminAnswerContent';
import EditAnswer from './EditAnswer';
import ReadOnlyEditor from '../../../../editor/ReadOnlyEditor';
import SVG from '../../../../SVG';
import "./AnswerEditor.scss";
import { PROBLEM_SET_MATCH } from '../../../../../constants';

class AnswerEditor extends Component {
    render() {
        const { toggleInfo, answer, deleteAnswer, duplicateAnswer, addToBucket, question, toggleForm, infoActive,
            postAnswerUpdate, formActive, section, subtopicId, satProblemSets, topicType } = this.props;
        return (
            <div className="answer-editor">
                <div className="answer-display mb-1" onClick={toggleInfo}>
                    {answer.is_free_response && <span className="mr-2">{answer.free_response_answer}</span>}
                    {(!answer.is_free_response && answer.answer !== "NA") && <ReadOnlyEditor content={answer.answer} />}
                    <span className={answer.correct ? "text-info" : "text-danger"}>
                        Answer: {answer.correct ? 'True' : 'False'}
                    </span>
                    {answer?.is_exact_match && <span className="mx-2">(Exact match)</span>}
                    <SvgContent hide={window.location.href.match(PROBLEM_SET_MATCH)} answer={answer} />
                </div>
                <RenderContent answer={answer} postAnswerUpdate={postAnswerUpdate} toggleForm={toggleForm}
                    formActive={formActive} section={section}
                    subtopicId={subtopicId} satProblemSets={satProblemSets} topicType={topicType}
                    question={question} infoActive={infoActive} />
                <div className="answer-buttons">
                    {!answer.is_free_response && <button className="btn btn-default mr-1" onClick={duplicateAnswer}>Duplicate</button>}
                    <button className="btn btn-default mr-1" onClick={addToBucket}>Add to Bucket</button>
                    <button className="btn btn-danger mr-1" onClick={deleteAnswer}>Delete</button>
                    {infoActive && <button className="btn btn-default" onClick={toggleForm}>Edit</button>}
                </div>
            </div>
        );
    }
}

const SvgContent = ({ answer, hide }) => {
    if (hide) return null;
    let svgImage = 'error-x', svgClass = ['ml-1','status-image', 'error'];
    if (answer.next_question._id || answer.link_problemset?._id) {
        svgImage = 'checkmark';
        svgClass = ['status-image', 'checkmark'];
    }
    return <SVG name={svgImage} className={svgClass.join(' ')} />;
}

const RenderContent = (props) => {
    const {
        answer, postAnswerUpdate, toggleForm, formActive, section,
        subtopicId, satProblemSets, topicType, question, infoActive
    } = props;
    if(!infoActive) return null;

    if (formActive)
        return (<EditAnswer question={question}
                            answer={answer}
                            toggleForm={toggleForm}
                            postAnswerUpdate={postAnswerUpdate}
                            subtopicId={subtopicId}
                            section={section}
                            satProblemSets={satProblemSets}
                            topicType={topicType}/>);

    return <AdminAnswerContent answer={answer} toggleForm={toggleForm} />
}

export default AnswerEditor;