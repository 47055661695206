import * as dbUtil from '../../api/dbUtil';
import { API } from '../../api';
import {
    PUBLISH_GRAMMAR_TOPIC,
    RECEIVE_ALL_GRAMMAR_TOPICS,
    RECEIVE_GRAMMAR_TOPIC,
    REMOVE_GRAMMAR_TOPIC,
    UPDATE_GRAMMAR_TOPIC
} from "../constant";
import { checkSessionExpired, alertPushMessage } from "..";

const receiveAll = (data) => ({type: RECEIVE_ALL_GRAMMAR_TOPICS, data})
const receive = (data) => ({type: RECEIVE_GRAMMAR_TOPIC, data})
const updateTest = (data) => ({type: UPDATE_GRAMMAR_TOPIC, data})
const removeTest = (data) => ({type: REMOVE_GRAMMAR_TOPIC, data})
export const grammerTopicPublishUnpublish = (data) => ({ type: PUBLISH_GRAMMAR_TOPIC, data });


export const getAllGrammarTopics = (params) => {
    return (dispatch) => {
        return dbUtil.fetchMany('grammar_topics', params).then(({data}) => {
            dispatch(receiveAll(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateGrammarTopic = (id, attributes) => {
    return (dispatch) => {
        return dbUtil.update('grammar_topics', id, attributes).then(({data}) => {
            dispatch(updateTest(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const deleteGrammarTopic = (id) => {
    return (dispatch) => {
        return dbUtil.destroy('grammar_topics', id).then(({data}) => {
            dispatch(removeTest(data));
            dispatch(alertPushMessage({message: `${data.title} topic deleted Successfully`, type: "success"}));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const createGrammarTopic = (attributes) => {
    return (dispatch) => {
        return dbUtil.create('grammar_topics', attributes).then(({data}) => {
            dispatch(alertPushMessage({message: `${data.title} topic created Successfully`, type: "success"}));
            dispatch(receive(data));
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateGrammarTopicPositions = (topics) => {
    return (dispatch) => {
        return API.put("/tests/update_positions", topics).then(({ data }) => {
            dispatch(receiveAll(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return topics;
        });
    }
}