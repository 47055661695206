import React, { Component } from 'react';
import '../sat/HomeSATList.scss';
import SortableHomeACT from './SortableACT'

class ACTList extends Component {
    render() {
        const { acts } = this.props;
        if(acts.length > 0){
            return(<SortableHomeACT {...this.props}/>);
        }

        return null;
    }
}

export default ACTList;
