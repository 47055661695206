import * as dbUtil from '../../api/dbUtil';
import { API } from '../../api';
import {
    PUBLISH_READING_TOPIC,
    RECEIVE_ALL_READING_TOPICS,
    RECEIVE_READING_TOPIC,
    REMOVE_READING_TOPIC,
    UPDATE_READING_TOPIC
} from "../constant";
import { checkSessionExpired, alertPushMessage } from "..";

const receiveAll = (data) => ({     type: RECEIVE_ALL_READING_TOPICS,data })
const receive = (data) => ({     type: RECEIVE_READING_TOPIC,     data })
const updateTest = (data) => ({     type: UPDATE_READING_TOPIC,  data })
const removeTest = (data) => ({     type: REMOVE_READING_TOPIC, data })
export const readingTopicPublishUnpublish = (data) => ({ type: PUBLISH_READING_TOPIC, data })

export const getAllReadingTopics = (params) => {
    return (dispatch) => {
        return dbUtil.fetchMany('reading_topics', params).then(({data}) => {
            dispatch(receiveAll(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateReadingTopic = (id, attributes) => {
    return (dispatch) => {
        return dbUtil.update('reading_topics', id, attributes).then(({data}) => {
            dispatch(updateTest(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const deleteReadingTopic = (id) => {
    return (dispatch) => {
        return dbUtil.destroy('reading_topics', id).then(({data}) => {
            dispatch(removeTest(data));
            dispatch(alertPushMessage({message: `${data.title} topic deleted Successfully`, type: "success"}));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const createReadingTopic = (attributes) => {
    return (dispatch) => {
        return dbUtil.create('reading_topics', attributes).then(({data}) => {
            dispatch(alertPushMessage({message: `${data.title} topic created Successfully`, type: "success"}));
            dispatch(receive(data));
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateReadingTopicPositions = (topics) => {
    return (dispatch) => {
        return API.put("/tests/update_positions", topics).then(({ data }) => {
            dispatch(receiveAll(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return topics;
        });
    }
}