import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReadOnlyEditor from '../../editor/ReadOnlyEditor';
import * as settingActions from "../../../modules/setting/action";

class IntroVideoModal extends Component {

  constructor(props) {
    super(props)
    const { setting } = this.props;
    this.state = {
      editorState: setting && setting.introduction_video ? setting.introduction_video : null,
      showIntroVideo: false,
    }
  }

  async componentDidMount() {
    const { getSetting } = this.props
    await getSetting()
  }

  render() {
    const { setting} = this.props
      return (
        <div className="videoExpCls mt-4">
          <div className="view-question--inner">
            {setting && setting.introduction_video ? <ReadOnlyEditor viewAble={true} content={setting.introduction_video} /> : <i>No videos available.</i>}
          </div>
        </div>
      )
  }
}

const mapStateToProps = state => {
  return ({
    setting: state.setting
  })
}

const mapDispatchToProps = dispatch => {
  return ({
    getSetting: () => dispatch(settingActions.getSetting())
  })
}

export const IntroVideoDialog = connect(mapStateToProps, mapDispatchToProps)(IntroVideoModal);