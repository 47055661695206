import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import AdminProblemSet from "./AdminProblemSet";
import arrayMove from "array-move";
import './AdminproblemSetDetail.scss'
import { connect } from 'react-redux'

const DragHandle = SortableHandle(() => (
  <span className="sortable-handle">::</span>
));

const SortableList = SortableContainer((
    {problemsets, updateProblemSet, deleteProblemSet, close, topicType, selectedId, account, homePath, sat, allIds,
      updateAfterPublishUnpublish, question, updateAfterSequencePublishUnpublish, subSubtopic, openDialog,  act,
      openDialogAfterAssignAssignment, selectedProblemSets, checkUnCheckProblemSet, assignments
}) => {
  return (
    <ul className={homePath ? "sortable-admin-topic-list m-0 p-0" : "sortable-admin-topic-list"} key="problemSets">
      {problemsets && problemsets.map((problemSet, index) => {
        return (
          <SortableItem
            allIds={allIds}
            sat={sat}
            key={`sorted${problemSet._id}`}
            index={index}
            problemSet={problemSet}
            id={problemSet._id}
            problemsets={problemsets}
            updateProblemSet={updateProblemSet}
            deleteProblemSet={deleteProblemSet}
            close={close}
            topicType={topicType}
            selectedId={selectedId}
            account={account}
            homePath={homePath}
            updateAfterPublishUnpublish={updateAfterPublishUnpublish}
            question={question}
            updateAfterSequencePublishUnpublish={updateAfterSequencePublishUnpublish}
            subSubtopic={subSubtopic}
            openDialog={openDialog}
            selectedProblemSets={selectedProblemSets}
            checkUnCheckProblemSet={checkUnCheckProblemSet}
            openDialogAfterAssignAssignment={openDialogAfterAssignAssignment}
            assignments={assignments}
            act={act}
          />
        );
      })}
    </ul>
  );
});

const SortableItem = SortableElement(
  ({ problemSet, close, updateProblemSet, deleteProblemSet, topicType, problemsets, selectedId,
    account, homePath, key, sat, allIds, updateAfterPublishUnpublish, question, updateAfterSequencePublishUnpublish, subSubtopic,
    openDialog, openDialogAfterAssignAssignment, act, selectedProblemSets, checkUnCheckProblemSet, assignments
  }) => {
    const studentSection = (account.userType === "Student" || homePath);
    const assigned = homePath && assignments.map((assignment) => assignment.assignmentable_id).includes(problemSet._id);
    return (
      <li className={`sortable-topic sortableModal_listele ${studentSection ? (assigned ? 'student-problem-set-assignment-view' : 'student-problem-set-view') : ''}`}
          id={`Pro${problemSet._id}`} key={key}>
        {!studentSection && <DragHandle />}
        <AdminProblemSet allIds={allIds}
                         sat={sat}
                         close={close}
                         updateProblemSet={updateProblemSet}
                         deleteProblemSet={deleteProblemSet}
                         problemSet={problemSet}
                         allProblemsets={problemsets}
                         topicType={topicType}
                         selectedId={selectedId}
                         updateAfterPublishUnpublish={updateAfterPublishUnpublish}
                         question={question}
                         updateAfterSequencePublishUnpublish={updateAfterSequencePublishUnpublish}
                         subSubtopic={subSubtopic}
                         openDialog={openDialog}
                         openDialogAfterAssignAssignment={openDialogAfterAssignAssignment}
                         selectedProblemSets={selectedProblemSets}
                         checkUnCheckProblemSet={checkUnCheckProblemSet}
                         act={act} />
      </li>
    );
  }
);

class AdminProblemSets extends Component {
  state = {
    problemsets: [],
  };

  componentDidMount() {
    const { problemsets } = this.props;
    this.setState({ problemsets });
  }

  componentWillReceiveProps(props) {
    const { problemsets } = props;
    this.setState({ problemsets });
  }

  onSortEnd = async (
    options,
    mouseEvent,
    problemsets = this.state.problemsets
  ) => {
    const { updateProblemSetPosition, updateProblemSetSatPosition, topicType, selectedId } = this.props;
    const { oldIndex, newIndex } = options;
    if(oldIndex === newIndex) return null;
    const updatedProblemSets = arrayMove(problemsets, oldIndex, newIndex);
    // const updateProblemSetPositionSet = {
    //   problem_sets: updatedProblemSets,
    //   _id: updatedProblemSets[0].subsubtopic_id
    // }
    if (topicType === 'SatQuestionTopic') {
      await updateProblemSetSatPosition({ problem_sets: updatedProblemSets, _id: selectedId });
    } else {
      await updateProblemSetPosition({ problem_sets: updatedProblemSets, _id: selectedId });
    }
    // this.setState({ problemsets: updateProblemSetPositionSet.problem_sets })
  };

  render() {
    const { problemsets } = this.state;
    const {
      updateProblemSet, deleteProblemSet, close, topicType, selectedId, account, location, sat, allIds,
      updateAfterPublishUnpublish, question, updateAfterSequencePublishUnpublish, subSubtopic, openDialog,
      openDialogAfterAssignAssignment, act, selectedProblemSets, checkUnCheckProblemSet, assignments
    } = this.props
    if (!problemsets) return null;
    return (
      <div className="sortable-topics">
        <SortableList allIds={allIds}
                      sat={sat}
                      useDragHandle
                      onSortEnd={this.onSortEnd}
                      problemsets={(location.pathname).includes('home') ? problemsets.filter(dd => dd.isPublish === true) : problemsets}
                      updateProblemSet={updateProblemSet}
                      deleteProblemSet={deleteProblemSet}
                      close={close}
                      topicType={topicType}
                      selectedId={selectedId}
                      account={account}
                      homePath={(location.pathname).includes('home')}
                      updateAfterPublishUnpublish={updateAfterPublishUnpublish}
                      question={question}
                      updateAfterSequencePublishUnpublish={updateAfterSequencePublishUnpublish}
                      subSubtopic={subSubtopic}
                      openDialog={openDialog}
                      openDialogAfterAssignAssignment={openDialogAfterAssignAssignment}
                      act={act}
                      helperClass='sortableModalDrag_topic'
                      selectedProblemSets={selectedProblemSets}
                      checkUnCheckProblemSet={checkUnCheckProblemSet}
                      assignments={assignments}
                      lockAxis="y"/>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return ({
    account: state.account,
    assignments: state.assignments
  })
}

export default withRouter(connect(mapStateToProps, null)(AdminProblemSets))
