import  { getDefaultKeyBinding, KeyBindingUtil } from 'draft-js';

const {hasCommandModifier} = KeyBindingUtil;

// Key bindings will set to the first instance set.
// Add new keycodes for binding support in editor
const keyBindings = (editorState, e) => {

    // Text Highlighter
    if (e.keyCode === 72 && hasCommandModifier(e)) {
        return 'highlight';
    }

    // MathJax Repo shortcuts, unable to run more than 1 keyBindingFn
    if (e.keyCode === /* m */ 77 && hasCommandModifier(e)) {
        return 'insert-texblock'
    }

    if (e.key === /* $ */ '$' /* && hasCommandModifier(e)*/) {
        const c = editorState.getCurrentContent()
        const s = editorState.getSelection()
        if (!s.isCollapsed()) return 'insert-inlinetex'
        const bk = s.getStartKey()
        const b = c.getBlockForKey(bk)
        const offset = s.getStartOffset() - 1
        if (b.getText()[offset] === '\\') {
            return `insert-char-${e.key}`
        }

        return 'insert-inlinetex'
     }

     if (e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
        const d = e.key === 'ArrowRight' ? 'r' : 'l'
        const s = editorState.getSelection()
        const c = editorState.getCurrentContent()
        if (!s.isCollapsed()) { return undefined }
        const offset = s.getStartOffset()
        const blockKey = s.getStartKey()
        const cb = c.getBlockForKey(blockKey)
        if (cb.getLength() === offset && d === 'r') {
            const b = c.getBlockAfter(blockKey)
            if (b && b.getType() === 'atomic' && b.getData().get('mathjax')) { return `update-texblock-${d}-${b.getKey()}` }
        }
        if (offset === 0 && d === 'l') {
            const b = c.getBlockBefore(blockKey)
            if (b && b.getType() === 'atomic' && b.getData().get('mathjax')) { return `update-texblock-${d}-${b.getKey()}` }
        }
        const ek = cb.getEntityAt(offset - (e.key === 'ArrowLeft' ? 1 : 0))
        if (ek && c.getEntity(ek).getType() === 'INLINETEX') {
            return `update-inlinetex-${d}-${ek}`
        }
    }

    return getDefaultKeyBinding(e);
}

export default keyBindings;
