export default (style = {}) => {
    const customStyleMap = {
        'TEXT_transparent': {
            color: 'transparent',
        },
        'TEXT_#17252a': {
            color: '#17252a',
        },
        'TEXT_#ffffff': {
            color: '#ffffff',
        },
        'TEXT_#b80000': {
            color: '#b80000',
        },
        'TEXT_#db3e00': {
            color: '#db3e00',
        },
        'TEXT_#fccb00': {
            color: '#fccb00',
        },
        'TEXT_#008b02': {
            color: '#008b02',
        },
        'TEXT_#006B76': {
            color: '#006B76',
        },
        'TEXT_#1273de': {
            color: '#1273de',
        },
        'TEXT_#004dcf': {
            color: '#004dcf',
        },
        'TEXT_#5300eb': {
            color: '#5300eb',
        },
        'TEXT_#eb9694': {
            color: '#eb9694',
        },
        'TEXT_#fad0c3': {
            color: '#fad0c3',
        },
        'TEXT_#fef3bd': {
            color: '#fef3bd',
        },
        'TEXT_#c1e1c5': {
            color: '#c1e1c5',
        },
        'TEXT_#bedadc': {
            color: '#bedadc',
        },
        'TEXT_#c4def6': {
            color: '#c4def6',
        },
        'TEXT_#bed3f3': {
            color: '#bed3f3',
        },
        'TEXT_#d4c4fb': {
            color: '#d4c4fb',
        },
        'TEXT_#0099cc': {
            color: '#0099cc',
        },
        'TEXT_#ff6699': {
            color: '#ff6699',
        },
        'TEXT_#9966ff': {
            color: '#9966ff',
        },
        'TEXT_#66cc00': {
            color: '#66cc00',
        },
        'TEXT_#18ac04': {
            color: '#18ac04'
        }
    }

    return {
        customStyleMap,
    };
};
