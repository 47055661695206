import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AdminQuestions from '../question/AdminQuestions';
import * as actQuestionActions from '../../../../../modules/actQuestion/action';
import * as problemSetActions from '../../../../../modules/problemSet/action';
import './AdminSubtopicMain.scss';

class AdminActQuestionMain extends Component {
    state = {
        title: "",
        topicTitle: "",
        showTopics: false,
        testId: "",
        actId: ""
    }
    async componentDidMount() {
        window.scrollTo(0, 0);
        const { match: { params: { actQuestionId } }, getActQuestions } = this.props;
        let  error404 = false;
        const actTopic = await getActQuestions(actQuestionId).catch((error)=>{
            error404 = error.response?.status === 404;
        });
        const { getActProblemSets } = this.props
        await getActProblemSets({ act_question_id: actQuestionId });
        if((actTopic?.sat_id && !actTopic?.act_id) || error404){
            this.props.history.push('/admin/not_found');
        }
        this.setState({ topicTitle: actTopic?.title, actId: actTopic?.act_id })
    }

    takeBack = () => {
        const { match: { params: { actQuestionId } } } = this.props;
        const { actId } = this.state
        this.props.history.push('/admin/acts?act=' + actId + '&act_question=' + actQuestionId)
    }

    toggleForm = () => {
        const { showTopics } = this.state
        this.setState({ showTopics: !showTopics })
    }
    render() {
        const { match: { params: { actQuestionId } }, problemsets } = this.props;
        const { topicTitle } = this.state;
        let topicType = 'ActQuestionTopic';

        // alert(topicType)
        return (
            <div className="row" >
                <div className="content-wrapper-before gradient-45deg-indigo-purple"/>
                <div className="col s12 contentCenter_wrap">
                    <div className="container">
                        <div className="dash_top_section"/>
                        <div className="row vertical-modern-dashboard">
                            <div className="col-12">
                                <div className="card animate fadeLeft">
                                    <div className="question_sec card-content">
                                        <div className="dashtitle_sec d-flex flex-wrap align-items-center w-100">
                                            <button onClick={() => this.takeBack()} className="btn btn-default">
                                                <i className="ico backicon"/>
                                                <span className="btntxt">Back</span>
                                            </button>
                                        </div>
                                        <div className="quesHeader d-flex flex-wrap align-items-center text-center w-100">
                                            <h1 className=" quesTitle d-inline-block position-relative">
                                                {topicTitle}
                                            </h1>
                                        </div>

                                        <AdminQuestions
                                            topicType={topicType}
                                            topicId={actQuestionId}
                                            section="ActQuestionTopic"
                                            problemsets={problemsets[actQuestionId]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        );
    }
}

const mapDispatchToProps = dispatch => {
    return ({
        getActQuestions: (id) => dispatch(actQuestionActions.getActQuestion(id)),
        getActProblemSets: (params) => dispatch(problemSetActions.getActProblemSets(params)),
    })
}

const mapStateToProps = ({ subsubtopics, subtopics, problemsets }) => ({ subsubtopics, subtopics, problemsets })

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminActQuestionMain));
