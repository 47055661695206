import * as dbUtil from '../../api/dbUtil';
import {
    RECEIVE_ALL_TEST_PROGS,
    RECEIVE_TEST_PROG,
    UPDATE_TEST_PROG,
    REMOVE_TEST_PROG
} from '../constant'
import { checkSessionExpired } from "..";

const receiveAll = (data) => ({type: RECEIVE_ALL_TEST_PROGS, data});
const receive = (data) => ({type: RECEIVE_TEST_PROG, data})
const updateTest = (data) => ({type: UPDATE_TEST_PROG, data});
const removeTest = (data) => ({type: REMOVE_TEST_PROG, data});

export const getAllTestProg = (params) => {
    return (dispatch) => {
        return dbUtil.fetchMany('test_progs', params).then(({data}) => {
            dispatch(receiveAll(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getTestProg = (id) => {
    return (dispatch) => {
        return dbUtil.fetch('test_progs', id).then(({data}) => {
            dispatch(receive(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateTestProg = (id, attributes) => {
    return (dispatch) => {
        return dbUtil.update('test_progs', id, attributes).then(({data}) => {
            dispatch(updateTest(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const deleteTestProg = (id) => {
    return (dispatch) => {
        return dbUtil.destroy('test_progs', id).then(({data}) => {
                dispatch(removeTest(data));
                return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const createTestProg = (attributes) => {
    return (dispatch) => {
        return dbUtil.create('test_progs', attributes).then(({data}) => {
            dispatch(receive(data))
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const findOrCreateTestProg = (attributes) => {
    return (dispatch) => {
        return dbUtil.findOrCreate('test_progs', attributes).then(({data}) => {
            dispatch(receive(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}
