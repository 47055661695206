import React, {Component} from 'react';
import '../sat/AdminSATList.scss';
import SortableACT from './SortableACT'

class ACTList extends Component {
  render() {
    const {
      acts, deleteACT, updateACT, updateActsPositions, pubishOrUnpublishACT, actTopicModal, updateScore,
      updateBubbleAnswers, updateActProgressType
    } = this.props;

    if(acts && acts.length > 0){
      return (
          <SortableACT updateScore={updateScore}
                       updateBubbleAnswers={updateBubbleAnswers}
                       actTopicModal={actTopicModal}
                       acts={acts}
                       updateActsPositions={updateActsPositions}
                       updateACT={updateACT}
                       updateActProgressType={updateActProgressType}
                       deleteACT={deleteACT}
                       pubishOrUnpublishACT={pubishOrUnpublishACT}/>
      );
    }
    return null;
  }
}

export default ACTList;
