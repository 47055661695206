import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Editor from '../../../../editor/Editor';
import EditQuestionRank from './EditQuestionRank';
import "./CreateQuestionForm.scss";
import {FreeResponseSwitch, MathSwitch} from "./Common";

class CreateQuestionForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editorState: null,
            rank: "",
            isCalc: false,
            questions: this.props.questions,
            is_free_response: false
        }

        this.updateEditorState = this.updateEditorState.bind(this);
        this.submit = this.submit.bind(this);
        this.updateRank = this.updateRank.bind(this);
    }

    componentWillReceiveProps(props) {
        if (props.questions) {
            this.setState({ questions: props.questions })
        }
    }

    submit(e) {
        e.preventDefault();
        const { createAndAddQuestion, topicId, fromQuestion, fromQuestionPosition, resetQuestionData, toggleForm, section, satType, actType } = this.props;
        const { editorState, rank, isCalc, questions, is_free_response } = this.state;
        const question = editorState;

        let position = questions.length;
        if (fromQuestion) {
            position = fromQuestionPosition + 1;
        }
        let data;
        if (section === 'problemSet') {
            data = {
                question,
                problem_set_id: topicId,
                rank,
                position,
                is_calc: isCalc,
                question_type: section,
                is_free_response
            };
        } else {
            data = {
                question,
                rank,
                position,
                is_calc: isCalc,
                question_type: section,
                is_free_response
            };
            if (section === 'subsubTopic') {
                data.subsubtopic_id = topicId
            } else if (section === 'SatQuestionTopic') {
                data.sat_question_id = topicId
            } else if (section === 'ActQuestionTopic') {
                data.act_question_id = topicId
            } else if (section === "AdminSatMockTest" || section === "AdminSatRealTest") {
                data.sat_id = topicId
                data.sat_type = satType
            } else if (section === "AdminActMockTest" || section === "AdminActRealTest") {
                data.act_id = topicId
                data.act_type = actType
            } else {
                data.subtopic_id = topicId
            }
        }

        if (fromQuestion) {
            toggleForm();
        } else {
            resetQuestionData();
        }
        createAndAddQuestion(data, fromQuestion);
    }

    updateEditorState(editorState) {
        this.setState({ editorState })
    }

    updateRank(rank) {
        this.setState({ rank })
    }

    render() {
        const { toggleForm, fromQuestion, location, topicType} = this.props;
        const { isCalc, is_free_response} = this.state;
        return (
            <div className="create-question">
                <div className="create-question-form">
                    <Editor updateEditorState={this.updateEditorState}
                            fromQuestion={fromQuestion}/>
                    {/*  <div className="attributes linkedques_container"> */}
                    <div className="ques_attributes">
                        <EditQuestionRank updateRank={this.updateRank}
                                          hidden={location.pathname.includes("problemset")}/>

                        <MathSwitch
                            show={topicType === "Topic" || topicType === "ProblemSet"}
                            isCalc={isCalc}
                            onChange={() => this.setState({isCalc: !isCalc})}
                        />

                        <FreeResponseSwitch
                            is_free_response={is_free_response}
                            onChange={() => this.setState({is_free_response: !is_free_response})}
                        />

                        <div className="buttons btn_grup">
                            <button className="btn btn-danger" onClick={toggleForm}>Cancel</button>
                            <button className="btn btn-primary" onClick={this.submit}>Create Question</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(CreateQuestionForm);
