import React, { Component } from 'react';
import { connect } from 'react-redux';
import BucketQuestions from '../../bucket/BucketQuestions';

const mapStateToProps = state => {
    return ({
        bucketQuestions: state.bucket.questions,
    })
}

class CreateQuestionModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formActive: false,
        }

        this.toggleForm = this.toggleForm.bind(this);
        this.toggleBucket = this.toggleBucket.bind(this);
    }

    toggleBucket() {
        const { bucketActive } = this.state;
        this.setState({ bucketActive: !bucketActive });
    }

    toggleForm() {
        const { toggleForm, close } = this.props;
        const { formActive } = this.state;
        if (formActive) {
            this.setState({ formActive: false })
        } else {
            // Sends props down to create form
            toggleForm();
            close();
            this.setState({ formActive: true })
        }
    }

    renderContent() {
        const { close, topicId, bucketQuestions, invokedQuestion, clickFnc, fncType, createMultiple } = this.props;
        const { bucketActive } = this.state;
        if (bucketActive) {
            return (
                <React.Fragment>
                    <BucketQuestions questions={bucketQuestions}
                                     clickFnc={clickFnc}
                                     fncType={fncType}
                                     topicId={topicId}
                                     close={close}
                                     createMultiple={createMultiple}
                                     invokedQuestion={invokedQuestion} />
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <button className="btn btn-default" onClick={this.toggleForm}>Create new</button>
                    <button className="btn btn-default" onClick={this.toggleBucket}>Create from bucket</button>
                </React.Fragment>
            )
        }
    }

    render() {
        return (
            <React.Fragment>
                <div>
                    {this.renderContent()}
                </div>
            </React.Fragment>
        )
    }
}

export const CreateQuestionDialog = connect(mapStateToProps, null)(CreateQuestionModal);
