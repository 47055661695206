import { Component } from "react";
import PropTypes from 'prop-types';

class IfElseView extends Component {
    render() {
        const { condition, children } = this.props;
        if (!(children && children[0] && children[1])) return null;
        if (condition) return children[0];
        return children[1];
    }
}

IfElseView.propTypes = {
    children: PropTypes.any,
    condition: PropTypes.any
}

export default IfElseView;