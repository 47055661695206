import _ from "lodash";
import ReadOnlyEditor from "../../editor/ReadOnlyEditor";
import Editor from "../../editor/Editor";
import React from "react";

export const RestartSequenceBtn = ({account, onClick}) =>{
    if(account.progress?.percent_complete >= 100){
        return(
            <button disabled={!!account.tutor_login} className="btn btn-danger ml-4 mr-4" onClick={onClick} >
                Restart
            </button>
        )
    }
    return null;
}

export const ResetSequenceBtn = ({account, onClick}) =>{
    if(account.is_admin && _.keys(account.progress).length > 0){
        return(
            <button className="btn btn-danger resetProgressAdminCls" onClick={onClick}>
                Reset
            </button>
        )
    }
    return null;
}

export const SequenceProgressBar = ({account,percentage}) =>{
    if(parseInt(percentage) > 0  && _.keys(account.progress).length > 0){
        return(
            <div className="progress">
                <span>{percentage + "% complete"}</span>
                <div className="progress-bar" role="progressbar" aria-valuenow={percentage}
                     aria-valuemin="0" aria-valuemax="100" style={{ width: percentage + "%" }}/>
            </div>
        )
    }
    return null;
}

export const VideoExplanation = ({video_mock_explanation , video_explanation, questionPublish, isMock}) =>{
    if(video_mock_explanation || video_explanation){
        return(
            <>
                <div className={`${questionPublish ? 'explanation-row-divider' : ''}`}/>
                <div className="videoExpCls mt-4">
                    <h5 className="title my-2 pr-1">Video Explanations:</h5>
                    <span className="videoSectionCls">
                        <div className="adjustVideoViewCls">
                            <VideoExplanationView video={isMock ? video_mock_explanation : video_explanation}/>
                        </div>
                    </span>
                </div>
            </>
        )
    }
    return null;
}

export const VideoExplanationView = ({video}) =>{
    if(video){
        return(<ReadOnlyEditor viewAble={true} content={video} />);
    }
    return(<i>No videos available.</i>)
}

export const PublishSequenceButton = ({account, publish, onClick, className = ''}) =>{
    if(account.is_super_admin){
        return(
            <button onClick={() => onClick()} className={`btn btn-${publish? 'danger' : 'info'} ${className}`}>
                {publish ? "Unpublish" : "Publish"}
            </button>
        )
    }
    return null;
}

export const AddVideoExplanation = ({title, showExplanation, videoExplanation, showHideVideoSection, updateEditorState, submitAddVideExplanation }) =>{
    if(showExplanation){
        return(
            <div>
                <h5>{title}</h5>
                <div className="videoEditorcls">
                    <Editor content={videoExplanation} updateEditorState={updateEditorState} />
                    <div className="buttons mt-1">
                        <button className="btn btn-sm btn-primary" onClick={() => submitAddVideExplanation()}>
                            Submit
                        </button>
                        <button className="btn btn-sm btn-danger ml-2" onClick={() => showHideVideoSection()}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <span>{/* Else Part */}
            <button className="btn btn-sm btn-primary" onClick={() => showHideVideoSection()}>
                {`${videoExplanation ? 'Show' : 'Add'} Explanation ${title.includes("Mock") ? 'Mock': ''} Videos`}
            </button>
      </span>
    )
}
