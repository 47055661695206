import React, { Component } from 'react';
import Bucket from '../../bucket/Bucket';

class BucketDialog extends Component {
    render() {
        return (
            <Bucket />
        );
    }
}

export { BucketDialog };
