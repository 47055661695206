import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import Select from './../helper/Select';
import Switch from './../helper/Switch';

class SharedGraph extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedYear: this.props.selectedYear,
      selectedMonth: this.props.selectedMonth,
      monthOptions: this.props.monthOptions,
      yearOptions: this.props.yearOptions,
      type: "weekly",
      start_date: '',
      end_date: '',
    }
  }

  componentDidMount() {
    const { filters, onChangeFilterAction, account } = this.props
    const { selectedYear } = this.state
    if (filters) {
      this.monthFilter()
      this.yearFilter()
      const curr = new Date(); // get current date
      const first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
      const last = first + 6; // last day is the first day + 6
      const lastday = new Date(curr.setDate(last))
      const end_date = new Date(curr.setDate(last))
      lastday.setDate(lastday.getDate() - 34);
      const start_date = lastday.toISOString().split('T')[0];

      this.setState({ start_date: start_date, end_date: end_date })
      onChangeFilterAction({ user_id: account.id, start_date: start_date, end_date: end_date, selected_year: selectedYear, type: "weekly" });
    }

  }

  onYearChangeHandler = async ({ value }) => {
    const { account, onChangeFilterAction } = this.props
    this.monthFilter(value);
    const monthOptions = this.getSelectedYearMonths(value);
    const { type } = this.state;
    let selectedMonth;
    if(monthOptions.includes(this.state.selectedMonth)){
      selectedMonth = this.state.selectedMonth;
    } else {
      selectedMonth = monthOptions[monthOptions.length - 1];
    }
    const year = value;
    await onChangeFilterAction({ user_id: account.id, selected_month: selectedMonth, selected_year: year, type: type })
    this.setState({ selectedYear: year,  selectedMonth: selectedMonth});
    this.monthFilter();
  }

  onMonthChangeHandler = async ({ value }) => {
    const { account, onChangeFilterAction } = this.props;
    const { selectedYear, type } = this.state;
    const month = value
    await onChangeFilterAction({ user_id: account.id, selected_month: month, selected_year: selectedYear, type: type })
    this.setState({ selectedMonth: month })
  }

  monthFilter(selectedYear =  this.state.selectedYear) {
    const monthOptions = this.getSelectedYearMonths(selectedYear);
    this.setState({ monthOptions: monthOptions.map(d => ({ value: d, label: d })) })
  }

  getSelectedYearMonths = (selectedYear =  this.state.selectedYear) =>{
      const { monthNames } = this.props;
      let today = new Date();
      let selectedDate = today
      if (selectedYear < today.getFullYear()) {
        selectedDate = new Date(selectedYear, 11, 1);
      }
      let startOfYear = new Date(selectedDate.getFullYear(), 0, 1);
      let monthOptions = []
      while (startOfYear <= selectedDate) {
        monthOptions.push(monthNames[startOfYear.getMonth()])
        startOfYear = new Date(startOfYear.setMonth(startOfYear.getMonth() + 1));
      }
      return(monthOptions);
  }

  yearFilter() {
    const { selectedYear } = this.state
    let startYear = 2020;
    let yearOptions = []
    while (startYear <= selectedYear) {
      yearOptions.push(startYear);
      startYear++;
    }
    this.setState({ yearOptions: yearOptions.map(d => ({ value: d, label: d })) })
  }

  onTypeChangeHandler = async (e) => {
    const { account, onChangeFilterAction } = this.props
    const { selectedMonth, selectedYear, start_date, end_date } = this.state
    let graph_data_type = e.value;
    this.setState({ type: graph_data_type });
    await onChangeFilterAction({ user_id: account.id, start_date: start_date, end_date: end_date, selected_year: (graph_data_type === "monthly" ? selectedYear : null), selected_month: (graph_data_type === "daily" ? selectedMonth : null), type: graph_data_type });
  }

  onWeekChangeHandler = (direction) => {
    const { account, onChangeFilterAction } = this.props
    const { selectedYear, start_date, end_date } = this.state
    let firstDay, lastDay;
    if (direction === "next") {
      const date = new Date(end_date)
      date.setDate(date.getDate() + 1)
      firstDay = date.toISOString().split('T')[0];
      date.setDate(date.getDate() + 34);
      lastDay = date.toISOString().split('T')[0];
    } else {
      const date = new Date(start_date)
      date.setDate(date.getDate() - 1)
      lastDay = date.toISOString().split('T')[0];
      date.setDate(date.getDate() - 34);
      firstDay = date.toISOString().split('T')[0];
    }

    this.setState({ start_date: firstDay, end_date: lastDay });
    onChangeFilterAction({ user_id: account.id, start_date: firstDay, end_date: lastDay, selected_year: selectedYear, type: 'weekly' })

  }

  onSelectTimedFilter = (e) => {
    const { onChangeFilterAction, topic_type } = this.props
    onChangeFilterAction(topic_type, e.value)
  }

  onSelectTestFilter = (e) => {
    const { onChangeFilterAction, topic_type } = this.props
    onChangeFilterAction(topic_type, e.value)
  }

  render() {
    const { data, title, yAxisTitle, filters, options, timed_type, test_type, toggleOptions, onToggleChange} = this.props;
    const { type } = this.state;
    return (
      <>
        <div className="card-header">
          <div className={"section_title d-flex align-items-center"}>
            <div className="section_title">
              {title}
            </div>
            <div className="section_title">
              { toggleOptions && <Switch {...toggleOptions} marginLeft={6} onChange={(selected)=> onToggleChange(selected)}/> }
            </div>
          </div>
          {filters &&
           <div className="d-flex justify-content-end w-100 ">
            <div className="w-25">
              <Select autosize={true} options={[{ value: "monthly", label: "Monthly" }, { value: "weekly", label: "Weekly" }, { value: "daily", label: "Daily" }]} value={{ value: this.state.type, label: this.state.type.charAt(0).toUpperCase() + this.state.type.slice(1) }} onChange={this.onTypeChangeHandler} />
            </div>
            {
              type !== "weekly" &&
              <div className="w-25">
                <Select autosize={true} options={this.state.yearOptions} value={{ value: this.state.selectedYear, label: this.state.selectedYear }} onChange={this.onYearChangeHandler} />
              </div>
            }
            {
              type === "daily" &&
              <div className="w-25">
                <Select autosize={true} options={this.state.monthOptions} value={{ value: this.state.selectedMonth, label: this.state.selectedMonth }} onChange={this.onMonthChangeHandler} />
              </div>
            }
            {
              type === "weekly" &&
               <div className="graphArrowsControl d-flex justify-content-center w-100 " >
                 <button className="btn btn-light mr-1" onClick={() => this.onWeekChangeHandler("previous")} >
                   <i className="fa fa-arrow-left" aria-hidden="true"/>
                 </button>
                 <button className="btn btn-light" onClick={() => this.onWeekChangeHandler("next")} >
                   <i className="fa fa-arrow-right" aria-hidden="true"/>
                 </button>
               </div>
            }
           </div>
          }
          {timed_type ? <div className="d-flex justify-content-end w-100 ">
           <div className="w-25">
              <Select autosize={true} options={[{ value: "All", label: "All" }, { value: "Timed", label: "Timed" }, { value: "Untimed", label: "Untimed" }]} value={{ value: timed_type, label: timed_type }} onChange={this.onSelectTimedFilter} />
            </div> </div>: ""}
          {test_type ? <div className="d-flex justify-content-end w-100 ">
            <div className="w-25">
              <Select autosize={true} options={[{ value: "All", label: "All" }, { value: "Real", label: "Real" }, { value: "Mock", label: "Mock" }]} value={{ value: test_type, label: test_type }} onChange={this.onSelectTestFilter} />
            </div> </div>: ""}
        </div>
        <div className="sec_content graph_container">
          <div className="graph_wrap">
            {/* <!-- put your graph here --> */}
            <Bar
              data={data}
              height={100}

              options={{
                legend: {
                  position: 'bottom',
                  labels: {
                    boxWidth: 20,
                    fontSize: 15
                  }
                },
                interaction: {
                  intersect: false,
                },
                scales: {
                  xAxes: [{
                    barPercentage: data && data.labels.length < 2 ? 0.2 : data.labels.length < 4 ? 0.4 : 0.8,
                    id: "bar-x-axis",
                  }],
                  yAxes: [
                    {
                      stacked: false,
                      ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: options && options.yAsixMax,
                        stepSize:  options && options.stepSize,
                      },
                      time: {
                        unit: 'minute'
                      },
                      scaleLabel: {
                        display: true,
                        labelString: yAxisTitle,
                        fontSize: 15
                      },
                      id: "bar-y-axis",
                    },
                    {
                      display: false,
                      stacked: true,
                      ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: options && options.yAsixMax,
                        stepSize:  options && options.stepSize,
                      },
                      scaleLabel: {
                        display: true,
                        labelString: yAxisTitle,
                        fontSize: 15
                      },
                      id: "stack-bar-y-axis",
                    }
                  ]
                }
              }}
            />
          </div>
        </div>
      </>
    )

  }

}
export default SharedGraph