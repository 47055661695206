import {LOG_OUT_USER, RESET_USER_DATA} from './constant';
import { combineReducers } from 'redux';
import {
    accountReducer,
    alertReducer,
    userReducer,
    actReducer,
    satReducer,
    actProblemSetReducer,
    satProblemSetReducer,
    dialogReducer,
    bucketReducer,
    storeTemporaryReducer,
    animationReducer,
    problemSetReducer,
    problemSetQuestionReducer,
    problemSetAnswerReducer,
    answerReducer,
    questionReducer,
    actTestQuestionReducer,
    satTestQuestionReducer,
    satProgressReducer,
    actProgressReducer,
    satQuestionReducer,
    actQuestionReducer,
    userActReducer,
    userSatReducer,
    userProgressReducer,
    userSatTestProgressReducer,
    userActTestProgressReducer,
    softDeletedReducer,
    reportedProblemReducer,
    grammarTopicReducer,
    readingTopicReducer,
    satTopicRedcuer,
    topicReducer,
    settingReducer,
    testReducer,
    subSubTopicReducer,
    subTopicReducer,
    questionProgReducer,
    testProgReducer,
    assignmentReducer,
    studentReducer
} from './index'

const allReducers = combineReducers({
    tests: testReducer,
    assignments: assignmentReducer,
    topics: topicReducer,
    subtopics: subTopicReducer,
    subsubtopics: subSubTopicReducer,
    questions: questionReducer,
    answers: answerReducer,
    testProgs: testProgReducer,
    questionProgs: questionProgReducer,
    sats: satReducer,
    satQuestions: satQuestionReducer,
    satTopics: satTopicRedcuer,
    grammarTopics: grammarTopicReducer,
    readingTopics: readingTopicReducer,
    dialog: dialogReducer,
    account: accountReducer,
    bucket: bucketReducer,
    animation: animationReducer,
    problemsets: problemSetReducer,
    problemset_questions: problemSetQuestionReducer,
    problemset_answers: problemSetAnswerReducer,
    users: userReducer,
    satProgress: satProgressReducer,
    satProblemSets: satProblemSetReducer,
    setting: settingReducer,
    students: studentReducer,
    acts: actReducer,
    actQuestions: actQuestionReducer,
    actProgress: actProgressReducer,
    actProblemSets: actProblemSetReducer,
    softDeleted: softDeletedReducer,
    alerts: alertReducer,
    temporaryData: storeTemporaryReducer,
    reportedProblems: reportedProblemReducer,
    userActs: userActReducer,
    userSats: userSatReducer,
    userProgresses: userProgressReducer,
    userSatTestProgress: userSatTestProgressReducer,
    userActTestProgress: userActTestProgressReducer,
    actTestQuestions: actTestQuestionReducer,
    satTestQuestions: satTestQuestionReducer
})

const rootReducer = (state, action) => {
    if (action.type === LOG_OUT_USER || action.type === RESET_USER_DATA) {
        state = { account: { loggedIn: false } };
    }
    return allReducers(state, action);
}

export default rootReducer;
