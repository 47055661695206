import React from "react";
import _ from "lodash";
import ReadOnlyEditor from "../../../editor/ReadOnlyEditor";
import IfElseView from "../../../helper/IfElseView";

export const FreeResponse = ({ show, answers, value, submitted, onChange }) => {
    if (show && answers && answers.length > 0) {
        let symbol_left = answers && answers[0].symbol_position_left, symbol = answers && answers[0].symbol;
        let leftSideSymbol = symbol_left && symbol && symbol !== '', rightSideSymbol = !symbol_left && symbol && symbol !== '';
        let inputSymbolClass = leftSideSymbol ? 'leftSideSymbol' : rightSideSymbol ? 'rightSideSymbol' : 'symbolNotPresent';
        return (
            <div className="free-response-answer-cls row">
                <div className={'freeAnsCls'}>
                    <SymbolVisible visible={leftSideSymbol} className={"symbolCls mr-1"} symbol={symbol}/>
                    <div className="answer-input-div">
                        <input disabled={submitted}
                               className={`freeRespAnsInputCls form-control ${inputSymbolClass}`}
                               type="text"
                               value={value}
                               onChange={onChange}/>
                    </div>
                    <SymbolVisible visible={rightSideSymbol} className={"symbolCls"} symbol={symbol}/>
                </div>
            </div>
        )
    }
    return null;
}

export const FreeResponseWithExplanation = ({ showExplanation, answers, answersValue, value, onChange }) => {
    if (answers && answers.length > 0) {
        let symbol_left = answers && answers[0].symbol_position_left, symbol = answers && answers[0].symbol,
            spanClassName = (showExplanation ? answersValue === true ? "symbolCls text-success " : 'symbolCls text-danger' : "symbolCls"),
            divClassName = showExplanation ? answersValue === true ? "rightFreeAns" : 'wrongFreeAns' : 'freeAnsCls';
        let leftSideSymbol = symbol_left && symbol && symbol !== '', rightSideSymbol = !symbol_left && symbol && symbol !== '';
        let inputSymbolClass = leftSideSymbol ? 'leftSideSymbol' : rightSideSymbol ? 'rightSideSymbol' : 'symbolNotPresent';
        return (
            <div className="free-response-answer-cls row">
                <div className={`row ${divClassName}`}>
                    <SymbolVisible visible={leftSideSymbol} className={`${spanClassName} mr-1 ${inputSymbolClass}`} symbol={symbol}/>
                    <div className="answer-input-div">
                            <input disabled={showExplanation}
                                   className={`freeRespAnsInputCls form-control ${inputSymbolClass}`}
                                   type="text"
                                   value={value}
                                   onChange={onChange}/>
                    </div>
                    <SymbolVisible visible={rightSideSymbol} className={`${spanClassName} ${inputSymbolClass}`} symbol={symbol}/>
                    <div className="symbol-icon">
                        <i className={!showExplanation ? '' : `fa ${answersValue === true ? 'fa-check text-success' : 'fa-times text-danger'} ml-5`} />
                    </div>
                </div>
            </div>
        )
    }
    return null;
}

export const InstructionView = ({ visible, question, onClick }) => {
    if (visible) {
        return (<div className="instructionCls sat-test-center-container">
            <h3 className="text-center mb-4">Instructions</h3>
            <ul>
                <li>"Save & Next" button is to save answers.</li>
                <li>"Submit whole test" button is to submit the test and you can't change answers once submitted.</li>
                <li>You can also Mark and Unmark a question as Uncertain</li>
                <li>You can go to any question by clicking on the question number.</li>
                <li>Empty Dot above question number is for the question not attempted yet.</li>
                <li>Black Dot above question number is for the question which is saved.</li>
                <li>Light Green color of the question number is the current question on which you are working.</li>
                <li>Yellow Box at the corner of the question number is the question which you have marked uncertain.</li>
            </ul>
            <button onClick={onClick} className="btn btn-success btn-lg my-3 float-right">
                {question && question.position === 0 ? "Let's Go" : "Continue"}
            </button>
        </div>)
    }
    return null;
}

export const SubmitQuestionView = ({ submitted }) => {
    if (submitted) {
        return (
            <div className="mt-4 d-flex justify-content-center w-100 position-relative">
                <i>You have already submitted this test.</i>
            </div>
        )
    }
    return null;
}

export const FreeResponseAnswerView = ({ answers, question, answersGiven, submitted, checkAnswer, jValue = 65 }) => {
    if (question.is_free_response) return null;
    return (
        <ul>
            {_.keys(answers).length > 0 && question && answers[question.id] && answers[question.id].map((answer, index) => {
                if (answer.is_free_response === false) {
                    return (
                        <li className={answersGiven.findIndex(ans => ans === answer.id) > -1 ? 'active' : ''}
                            onClick={() => { submitted ? console.log('Test already submitted!!') : checkAnswer(answer.id) }} key={index}>
                            <div className="cell ques_listno">
                                <span >{String.fromCharCode(index + jValue)}</span>
                            </div>
                            <div className="cell">
                                <div className="ans-content"><ReadOnlyEditor content={answer.answer} /></div>
                            </div>
                        </li>
                    )
                }
            })
            }
        </ul>
    )
}

export const SaveAndNextQuestion = ({ submitted, currentQuestion, nextQuestionData, answersGiven, answerValue, nextQuestion }) => {
    const nextQuestionId = nextQuestionData?.id, markUncertain = currentQuestion?.markUncertain === true;
    return (
        <div className="mt-4 d-flex justify-content-between w-100 position-relative sat-test-center-container">
            <button disabled={!!submitted}
                onClick={() => nextQuestion(nextQuestionId ?? null, !(markUncertain), false)}
                className="btn btn-warning my-3">
                {currentQuestion && currentQuestion.markUncertain && currentQuestion.markUncertain === true ? "Remove from Uncertain" : "Mark Uncertain"}
            </button>
            <IfElseView condition={(answersGiven.length > 0 || answerValue !== '')} >
                <button disabled={!!submitted} /*If Condition true*/
                        onClick={() => nextQuestion((nextQuestionId ?? null), !!(markUncertain), true)}
                    // onClick={() => nextQuestion(nextQuestionData && nextQuestionData.id ? nextQuestionData.id : null,
                    //    !!(currentQuestion && currentQuestion.markUncertain && currentQuestion.markUncertain === true), true)}
                    className="btn btn-primary my-3">
                    {nextQuestionData && nextQuestionData.id ? "Save & Next" : "Save"}
                </button>
                <button disabled={true} className="btn btn-primary my-3" /*If Condition failed*/>
                    {nextQuestionData && nextQuestionData.id ? "Save & Next" : "Save"}
                </button>
            </IfElseView>
        </div>
    )
}

export const GotoQuestionView = ({ allQuestions, previousQuestion, testProgress, nextQuestion, question, goToQuestion = () => { } }) => {
    let previousQuestionId = previousQuestion && previousQuestion.id, nextQuestionId = nextQuestion && nextQuestion.id;
    return (
        <div className="list-all-sat-test-questions mx-4">
            <ul className="p-0 mt-2">
                <li className={previousQuestionId ? 'question arrowCls' : 'question arrowCls disabledLiCls'}
                    onClick={() => goToQuestion(previousQuestionId)}
                    key='previous' >
                    <i className="fa fa-arrow-left" />
                </li>
                {/*Question List start*/}
                {
                    allQuestions.map((currentQuestion, index) => {
                        let listClasses = getClassListName(testProgress, currentQuestion, question);
                        return (<li className={listClasses} onClick={() => goToQuestion(currentQuestion.id)}
                                    key={`${question.id}-${index}`}>{index + 1}</li>)
                    })
                }
                {/*Question List End*/}
                <li className={nextQuestionId ? 'question arrowCls' : 'question arrowCls disabledLiCls'}
                    onClick={() => goToQuestion(nextQuestionId)}
                    key='next' >
                    <i className="fa fa-arrow-right" /></li>
            </ul>
        </div>
    )
}

function getClassListName(testProgress, currentQuestion, question) {
    let listClasses = 'question', listQues = testProgress.find(mm => mm.questionId === currentQuestion.id)
    if (listQues && listQues.isComplete) {
        listClasses += ' completeCls';
    }
    if (question.id === currentQuestion.id) {
        listClasses += ' active';
    }
    if (listQues && listQues.markUncertain && listQues.markUncertain === true) {
        listClasses += ' questionUncertainCls';
    }
    return listClasses;
}

const SymbolVisible = ({ visible, className, symbol }) => {
    if (visible){
        return (
            <div className="answer-symbol-div">
                <span className={className}>
                    <ReadOnlyEditor viewAble={true} content={symbol}/>
                </span>
            </div>
        );
    }
    return null;
}

export const getFreeResponseAnswers = ({question, answers}) =>{
    let freeResponseAnswers = [];
    if (question?.is_free_response) {
        if (_.keys(answers).length > 0 && question && answers[question.id]) {
            freeResponseAnswers = answers[question.id].filter(dd => dd.is_free_response === true)
        }
    }
    return freeResponseAnswers;
}

export class ViewFullExplanation extends React.Component {

    constructor(props) {
        super(props);
        this.state = { divHeight: 0 };
        this.containerRef = React.createRef();
    }

    componentDidUpdate() {
        try {
            const {height} = this.containerRef.current.getBoundingClientRect();
            const {divHeight} = this.state;
            if(height !== divHeight){
                this.setState({divHeight: height});
            }
        } catch (e) {
            // console.log(e,"Error")
        }
    }


    render() {
        const {question_exp, onClick, full_explanation} = this.props;
        const { divHeight } = this.state;
        let exp_style = full_explanation ? {} : divHeight ? { maxHeight: "300px", overflow: "hidden" } : {};
        if (question_exp) {
            return (
                <div className="problemset-question-explanation question-explanation explanation-collapsed">
                    {
                        (!full_explanation && divHeight >= 215) &&
                        <button className="btn btn-default" onClick={onClick}>
                            View full explanation
                        </button>
                    }
                    <div className={"explanationContent d-flex w-100 position-relative"} style={exp_style}>
                        <h2 className="expl_title w-100 position-relative d-inline-block">Answer Explanation</h2>
                        <div className="cell d-inline-flex align-items-start h-100 ico_cell">
                        <span className="iconwrap">
                            <i className="fa fa-lightbulb-o" />
                        </span>
                        </div>
                        <div ref={this.containerRef} className="cell d-inline-flex align-items-start h-100">
                            <ReadOnlyEditor content={question_exp} />
                        </div>
                    </div>
                </div>
            )
        }
        return false;
    }
}

export const QuestionView = ({ setQuestion, answers, showExplanation, answersGiven, checkAnswer }) => {

    if (setQuestion.is_free_response) return null;
    return (
        <ul>
            {_.keys(answers).length > 0 && setQuestion && answers[setQuestion.id] && answers[setQuestion.id].map((answer, index) => {
                if (answer.is_free_response === false) {
                    const answerExpCheck = JSON.parse(answer.explanation);
                    const answerExpCheckLength = answerExpCheck?.blocks?.length;
                    const showAnswerExplanation = (answerExpCheck?.blocks[0] && answerExpCheck.blocks[0].text) || answerExpCheckLength > 1
                    let correctAnswer = answer.correct, answersGivenTrue = answersGiven.findIndex(ans => ans.id === answer.id) > -1;
                    return (
                        <li className={showExplanation ? (correctAnswer ? (answersGivenTrue ? 'selectedRightAns' : "rightAns") : answersGivenTrue ? "wrongAns" : '') : (answersGivenTrue ? 'active' : '')}
                            onClick={() => showExplanation ? console.log('Answers Checked!!') : checkAnswer(answer)} key={index}>
                            <div className="cell ques_listno">
                                <span >{String.fromCharCode(index + 65)}</span>
                            </div>
                            <div className="cell">
                                <div className="ans-content"><ReadOnlyEditor content={answer.answer} /></div>
                                {showExplanation && showAnswerExplanation ?
                                    <div className="ans-explanation"><ReadOnlyEditor content={answer.explanation} /></div>
                                    :
                                    ""
                                }
                            </div>
                        </li>
                    )
                }
            })
            }
        </ul>
    )
}