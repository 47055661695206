import React from "react";
import _ from "lodash";
import {IN_A_ROW_CORRECT_COUNT} from "../../../../constants";
import * as XLSX from "xlsx";

export const PublishUnPublishTopicButton = ({ account, topic, onClick, disabled = false }) =>{
    if(account.is_super_admin){
      return(
          <button onClick={() => onClick()} className={`btn btn-sm ${topic?.isPublish ? 'btn-danger' : 'btn-info'}`} disabled={disabled}>
              {topic?.isPublish ? "Unpublish" : "Publish"}
          </button>
      )
    }
    return null;
}

export const PublishUnPublishSequenceButton = ({account, publish, onClick, className = '', disabled = false}) =>{
    if(account.is_super_admin){
        return(
            <button onClick={() => onClick()} className={`btn btn-${publish? 'danger' : 'info'} ${className}`} disabled={disabled}>
                {publish ? "Unpublish" : "Publish"}
            </button>
        )
    }
    return null;
}

export const PublishUnPublishTestButton = ({publish, onClick}) =>{
        return(
            <button onClick={() => onClick()} className={`btn btn-${publish? 'danger' : 'info'}`}>
                {publish ? "Unpublish" : "Publish"}
            </button>
        )
}

export const UploadExcel = ({uploadTitle, onUpload}) =>{
    return(
        <>
            <div className="input-group ml-3 scoreConversionCls">
                {
                    uploadTitle &&
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="scoreUpload">{uploadTitle}</span>
                    </div>
                }
                <div className="custom-file cursorCls">
                    <input onClick={(event) => {event.target.value = null}}
                           onChange={onUpload}
                           type="file" accept=".xlsx,.xls,.csv" className="custom-file-input"
                           id="inputGroupFile01" aria-describedby="scoreUpload"/>
                    <label className="custom-file-label" htmlFor="inputGroupFile01">Upload Excel</label>
                </div>
            </div>
        </>
    )
}


export const sheetParsedData = (result) =>{
    const wb = XLSX.read(result, { type: 'array' });
    const excelSheet = wb.Sheets[wb.SheetNames[0]];
    const data = XLSX.utils.sheet_to_csv(excelSheet, { header: 1 });
    let newData = data.split('\n')
    return(_.compact(_.map(newData, (value) => value !== '' ?  checkAndRemoveLastValue(value.split(',')) : '')));
}

export function checkAndRemoveLastValue(splitValue) {
    if(splitValue[splitValue.length - 1] === ''){
        splitValue.pop();
        return checkAndRemoveLastValue(splitValue);
    }
    return splitValue;
}

export const ShowUploads = ({account, onViewButton, record, columns = 5, title, ViewButtonTitle, disabled}) =>{
    if (account.is_super_admin && record && record.length > 0) {
        return(<button onClick={()=> onViewButton(record, title, columns)} className="btn btn-info ml-2" disabled={disabled}>
            {ViewButtonTitle}
        </button>)
    }
    return null;
}

export const ProblemSetProgressBar = ({progress,notCorrect, className = '', showMaxCorrectTick = false}) =>{
    if(progress){
        return(<div className={`d-flex justify-content-start align-items-center flex-wrap position-relative ${className}`}>
            {progress && progress.total_correct
                ? _.times(progress?.total_correct >= IN_A_ROW_CORRECT_COUNT ? IN_A_ROW_CORRECT_COUNT : progress.total_correct, (key) => {
                    return <span key={key} className="psIconCls active" />
                }) : ''}
            {notCorrect ? _.times(notCorrect, (key) => {
                return <span key={key} className="psIconCls" />
            }) : ''}
            {showMaxCorrectTick && progress?.total_correct >= IN_A_ROW_CORRECT_COUNT && <i className="fa fa-check-square-o checkMarkTrue ml-2"/>}
        </div>);
    }
    return null;
}