import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as accountActions from '../../modules/account/action';
import "./Login.scss";
import AccountTemplate from "./AccountTemplate";
import { EmailInput } from "../helper/commonMethods";
import { ForgotPasswordButtonTitle, ForgotPasswordTitle } from "./AccountConstant";
import * as animationActions from "../../modules/animation/action";

const mapStateToProps = state => {
    return ({
        loading: state.animation,
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        forgotPassword: (data) => dispatch(accountActions.forgotPassword(data)),
        startAnimation: () => dispatch(animationActions.start()),
        endAnimation: () => dispatch(animationActions.end()),
    })
}

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            serviceMessage: '',
        }

        this.forgotPasswordAction = this.forgotPasswordAction.bind(this);
        this.validate = this.validate.bind(this);
    }

    validate() {
        const { email } = this.state;
        return email.length > 0
    }

    async forgotPasswordAction(e) {
        e.preventDefault();
        const { email } = this.state;
        if (this.validate()) {
            const { startAnimation, endAnimation, forgotPassword, history } =  this.props;
            await startAnimation();
            const successMessage = await forgotPassword({ email });
            if(successMessage){
                history.push({ pathname: '/', successMessage: successMessage});
            }
            await endAnimation();
        } else {
            this.setState({ serviceMessage: 'Please enter email' })
        }
    }

    handleChange(prop) {
        return ({ target }) => this.setState({ [prop]: target.value, serviceMessage: '' })
    }

    render() {
        const { email, serviceMessage } = this.state;
        const { loading } = this.props;

        return (
            <AccountTemplate title={ForgotPasswordTitle} serviceMessage={serviceMessage} haveAccount={true}
                             buttonTitle={ForgotPasswordButtonTitle} newAccountLink={true} loader={loading}
                             onSubmit={this.forgotPasswordAction}>
                <EmailInput
                    title="Email"
                    name="email"
                    value={email}
                    placeholder="abc@example.com"
                    onChange={this.handleChange('email')}
                />
            </AccountTemplate>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);