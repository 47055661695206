import React, {Component} from 'react';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import ACTItem from './ACTItem';
import "../sat/SortableSAT.scss";


const DragHandle = SortableHandle(() =>
    <span className="sortable-handle">::</span>
)

const SortableItem = SortableElement(({
                                          act, deleteACT, updateACT, pubishOrUnpublishACT, actTopicModal, actTopic,
                                          updateScore, fromPopUp, mathTopic, updateAfterSequencePublishUnpublish,
                                          satTopic, updateAfterPublishUnpublish, updateBubbleAnswers, updateActProgressType
}) => {
    return (
        <li className="sortable-sat w-100" id={`T${act.id}`} style={{ listStyle: "none", display: "flex", alignItems: "flex-start" }}>
            <div className="card animate fadeLeft w-100">
                <div className="users_sec card-content p-0 d-flex">
                    {actTopicModal === false && <DragHandle />}
                    <ACTItem
                        fromPopUp={fromPopUp}
                        actTopicModal={actTopicModal}
                        actTopic={actTopic}
                        act={act}
                        deleteACT={deleteACT}
                        updateACT={updateACT}
                        updateActProgressType={updateActProgressType}
                        pubishOrUnpublishACT={pubishOrUnpublishACT}
                        updateScore={updateScore}
                        updateBubbleAnswers={updateBubbleAnswers}
                        mathTopic={mathTopic}
                        updateAfterSequencePublishUnpublish={updateAfterSequencePublishUnpublish}
                        satTopic={satTopic}
                        updateAfterPublishUnpublish={updateAfterPublishUnpublish}
                    />
                </div>
            </div>
        </li>
    )
})

const SortableList = SortableContainer(({
                                            acts, deleteACT, updateACT, pubishOrUnpublishACT, actTopicModal, actTopic,
                                            updateScore, fromPopUp, mathTopic, updateAfterSequencePublishUnpublish,
                                            satTopic, updateAfterPublishUnpublish, updateBubbleAnswers, updateActProgressType
}) => {
    return (
        <ul className="sortable-admin-sat-list pl-0 w-100">
            {acts.map((act, index) => {
                return (
                    <SortableItem actTopicModal={actTopicModal}
                                  actTopic={actTopic}
                                  key={act.id}
                                  index={index}
                                  act={act}
                                  id={act.id}
                                  deleteACT={deleteACT}
                                  updateACT={updateACT}
                                  updateActProgressType={updateActProgressType}
                                  pubishOrUnpublishACT={pubishOrUnpublishACT}
                                  updateScore={updateScore}
                                  updateBubbleAnswers={updateBubbleAnswers}
                                  fromPopUp={fromPopUp}
                                  mathTopic={mathTopic}
                                  updateAfterSequencePublishUnpublish={updateAfterSequencePublishUnpublish}
                                  satTopic={satTopic}
                                  updateAfterPublishUnpublish={updateAfterPublishUnpublish}/>
                )
            })}
        </ul>
    )
})

class SortableAdminACT extends Component {
    state = {
        acts: [],
    }

    componentDidMount() {
        const { acts } = this.props;
        this.setState({ acts })
    }


    // React Sortable Method used during mouse events to sort the list
    // Added functionality to this method in order to handle the question addition, deletion, and manipulation
    // mouseEvent is a param automatically passed in by React Sortable when moving questions
    // => we add the param here so we are able to set questions dynamically
    // When sorting the list, local state questions are used.
    // When adding to the list, a new copy of the questions state is used (see f(x)sortableAddQuestion)
    onSortEnd = async (options, mouseEvent, acts = this.props.acts) => {
        const { updateActsPositions } = this.props;
        const { oldIndex, newIndex } = options;
        if(oldIndex === newIndex) return null;
        const updatedSats = arrayMove(acts, oldIndex, newIndex)
        const x = await updateActsPositions({acts: updatedSats});
        this.setState({ acts: x })
    }

    render() {
        const {
            acts, deleteACT, updateACT, pubishOrUnpublishACT, actTopicModal, actTopic, updateScore, fromPopUp,
            mathTopic, updateAfterSequencePublishUnpublish, satTopic, updateAfterPublishUnpublish, updateBubbleAnswers,
            updateActProgressType
        } = this.props;
        return (
            <div className="sortable-sats">
                <SortableList useDragHandle
                              onSortEnd={this.onSortEnd}
                              acts={acts}
                              deleteACT={deleteACT}
                              updateACT={updateACT}
                              updateActProgressType={updateActProgressType}
                              pubishOrUnpublishACT={pubishOrUnpublishACT}
                              actTopicModal={actTopicModal}
                              actTopic={actTopic}
                              satTopic={satTopic}
                              updateScore={updateScore}
                              updateBubbleAnswers={updateBubbleAnswers}
                              fromPopUp={fromPopUp}
                              mathTopic={mathTopic}
                              updateAfterSequencePublishUnpublish={updateAfterSequencePublishUnpublish}
                              updateAfterPublishUnpublish={updateAfterPublishUnpublish}
                              helperClass='sortableHelper'
                              lockAxis="y"
                              useWindowAsScrollContainer={true}/>
            </div>
        );
    }
}

export default SortableAdminACT;
