import {
    RECEIVE_ALL_PROBLEM_SET_QUESTIONS,
    RECEIVE_PROBLEM_SET_QUESTION,
    UPDATE_PROBLEM_SET_QUESTION,
    REMOVE_PROBLEM_SET_QUESTION,
    RECEIVE_MULTIPLE_PROBLEM_SET_QUESTION,
    RESET_PROBLEM_SET_QUESTION
} from '../constant';

const defaultState = [];
export const problemSetQuestionReducer = (state = defaultState, action) => {
    let newState;
    switch (action.type) {
        case RECEIVE_ALL_PROBLEM_SET_QUESTIONS:
            return action.data;
        case RECEIVE_MULTIPLE_PROBLEM_SET_QUESTION:
            newState = Object.assign([], state);
            for (let data of action.data){
                newState =  Object.assign([], newState, [...newState, data])
            }
            return newState;
        case RECEIVE_PROBLEM_SET_QUESTION:
            newState = Object.assign([], state);
            for(let i = 0; i < newState.length; i++) {
                if(action.data.id === newState[i].id) {
                    newState[i] = action.data
                    return newState;
                }
            }
            return Object.assign([], state, [...state, action.data])
        case UPDATE_PROBLEM_SET_QUESTION:
            newState = state.map(obj => (action.data.id === obj.id) ? action.data : obj);
            return newState;
        case REMOVE_PROBLEM_SET_QUESTION:
            newState = state.filter(obj => (action.data.id !== obj.id));
            return newState;
        case RESET_PROBLEM_SET_QUESTION:
            return defaultState;
        default:
            return state;
    }
}

