import React, { Component } from 'react';
import { connect } from 'react-redux';
import { validateInputs } from '../../utilities/validation'
import AccountTemplate from "./AccountTemplate";
import { LoginButtonTitle, LoginSubTitle, LoginTitle } from "./AccountConstant";
import { EmailInput, PasswordInput } from "../helper/commonMethods";
import * as animationActions from "../../modules/animation/action";
import { login } from "../../modules";

const mapDispatchToProps = dispatch => {
    return ({
        login: (account) => dispatch(login(account)),
        startAnimation: () => dispatch(animationActions.start()),
        endAnimation: () => dispatch(animationActions.end()),
    })
}

const mapStateToProps = state => {
    return ({
        loading: state.animation,
    })
}

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            serviceMessage: '',
            errEmail: '',
            errPassword: '',
            successMessage: props.location && props.location.successMessage
        }

        this.loginUser = this.loginUser.bind(this);
        this.validate = this.validate.bind(this);
    }

    validate() {
        const { email, password } = this.state;
        let errEmail = '', errPassword = '', successMessage = '', isErrorPresent = false;

        if (validateInputs("email", email) === 'empty') {
            errEmail = 'Please enter email';
            isErrorPresent = true;
        } else if (validateInputs("email", email) === false) {
            errEmail = 'Invalid email';
            isErrorPresent = true;
        }
        if (password.length < 1) {
            errPassword = 'Please enter password';
            isErrorPresent = true;
        }
        this.setState({ errEmail, errPassword, successMessage })

        return !isErrorPresent;
    }

    async loginUser(e) {
        e.preventDefault();
        const { email, password } = this.state;
        if (this.validate()) {
            const { startAnimation, login, endAnimation } =  this.props;
            await startAnimation();
            const data = await login({email: email.trim(), password});
            if(data){
                if (data.userType === "Tutor") {
                    return this.props.history.push('/home/students');
                }
                this.props.history.push('/home');
            }
            await endAnimation();
        }
    }

    handleChange(event) {
        let errorKey = ''
        if (event.target.name === "email") {
            errorKey = 'errEmail'
        }
        if (event.target.name === "password") {
            errorKey = 'errPassword'
        }
        this.setState({ [event.target.name]: event.target.value, serviceMessage: '', successMessage: '', [errorKey]: '' });
    }

    render() {
        const { email, password, serviceMessage, successMessage, errEmail, errPassword } = this.state;
        const { loading } = this.props;
        return (
            <AccountTemplate title={LoginTitle} buttonTitle={LoginButtonTitle} subtitle={LoginSubTitle}
                serviceMessage={serviceMessage} successMessage={successMessage}
                forgotPassword={true} newAccountLink={true} onSubmit={this.loginUser} loader={loading}>
                <EmailInput
                    title="Email"
                    name="email"
                    value={email}
                    errorMessage={errEmail}
                    placeholder="abc@example.com"
                    onChange={(e) => (this.handleChange(e))}
                />
                <PasswordInput
                    title="Password"
                    name="password"
                    value={password}
                    errorMessage={errPassword}
                    placeholder="Enter your password"
                    onChange={(e) => (this.handleChange(e))}
                />
            </AccountTemplate>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);