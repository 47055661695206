import {
    ADD_BUCKET_ANSWER,
    ADD_BUCKET_PROBLEM_SET,
    ADD_BUCKET_QUESTION,
    ADD_BULK_QUESTION,
    CLEAR_ANSWER_BUCKET,
    CLEAR_BUCKET,
    CLEAR_BUCKET_PROBLEM_SET,
    CLEAR_QUESTION_BUCKET
} from "../constant";

export const addBucketQuestion = (data) =>  ({ type: ADD_BUCKET_QUESTION, data });
export const addBucketAnswer = (data) =>  ({ type: ADD_BUCKET_ANSWER, data })
export const clearQuestionBucket = () => ({ type: CLEAR_QUESTION_BUCKET })
export const clearAnswerBucket = () => ({ type: CLEAR_ANSWER_BUCKET })
export const clearBucket = () => ({ type: CLEAR_BUCKET })
export const addBulkBucketQuestion = (data) =>  ({ type: ADD_BULK_QUESTION, data })
export const addBucketProblemSet = (data) =>  ({ type: ADD_BUCKET_PROBLEM_SET, data })
export const clearBucketProblemSet = () =>  ({ type: CLEAR_BUCKET_PROBLEM_SET })
