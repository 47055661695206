import React, { Component } from 'react';
import SelectInput from '../sat/SelectInput';
import { EXISTING_PROBLEM_SET_DIALOG_BOX, ACT_SECTION_DIALOG_BOX } from '../../../dialogs/dialogs';
import { connect } from 'react-redux';
import { toWords } from '../../../../utilities/custom'
import * as problemSetActions from '../../../../modules/problemSet/action'
import { withRouter } from 'react-router-dom';
import {PublishUnPublishTestButton} from "../common/CommonMethod";

class SectionQuestions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            question: {},
            formActive: false,
            topic: "",
            actTopicModal: this.props.actTopicModal ? this.props.actTopicModal : false
        }

        this.toggleForm = this.toggleForm.bind(this);
        this.handler = this.handler.bind(this);
        this.updateQuestion = this.updateQuestion.bind(this);
    }

    componentDidMount() {
        const { questions, actTopicModal } = this.props
        const queryString = new URLSearchParams(this.props.location.search);
        const getQuestion = questions.find(dd => dd.id === queryString.get('act_question'))
        if (!actTopicModal && queryString && queryString.get('act_question') && getQuestion) {
            this.openDialogBox(getQuestion)
        }
    }

    showQuestion(question) {
        const { formActive } = this.state;
        this.setState({ question })
        if (formActive) this.toggleForm();
    }

    toggleForm() {
        const { formActive, question } = this.state;
        if (formActive) {
            this.setState({ formActive: false, title: question.title, topic: question.topic })
        } else {
            this.setState({ formActive: true, title: "", topic: "" })
        }
    }

    renderQuestion() {
        const { question } = this.state;
        if (Object.entries(question).length === 0 && question.constructor === Object) return null;
        return null
    }

    handler(e) {
        const linkedTopicId = e.value;
        this.setState({ topic: linkedTopicId });
    }

    handleChange(prop) {
        return ({ target }) => this.setState({ [prop]: target.value })
    }

    updateQuestion(e) {
        // e.preventDefault();
        const { updateActQuestion } = this.props;
        const { topic, question } = this.state;
        const data = { topic };
        updateActQuestion(question.id, data).then(question => {
            this.setState({ question })
        });
    }

    async openDialogBox(question, Existing = false) {
        const { selectTopics, openDialog, getActProblemSets, problemsets, actTopic, act, mathTopic, updateAfterSequencePublishUnpublish, satTopic, updateAfterPublishUnpublish} = this.props
        const { actTopicModal } = this.state
        this.setState({ question, topic: question.topic })
        if (actTopicModal === true && Existing === false) {
            await getActProblemSets({ act_question_id: question.id })
            // satTopic is now that question of sat from which you want to add the problem set.
            openDialog(EXISTING_PROBLEM_SET_DIALOG_BOX, {
                act,
                question,
                problemsets,
                actTopic,
                updateAfterPublishUnpublish: updateAfterPublishUnpublish ? updateAfterPublishUnpublish : this.updateAfterPublishUnpublish,
                mathTopic,
                updateAfterSequencePublishUnpublish,
                satTopic
            })
        } else {
            openDialog(ACT_SECTION_DIALOG_BOX, {
                act,
                question,
                //title: `${(question.title).split('_')[0]} ${toWords(question.type)} Question #${question.position + 1}`,
                title: `${act.title} ${toWords(question.type)} Question #${question.position + 1}`,
                handler: this.handler,
                selectTopics,
                updateQuestion: this.updateQuestion,
                updateAfterPublishUnpublish: this.updateAfterPublishUnpublish
            })
            this.setState({ actTopicModal: false })
        }
    }

    updateAfterPublishUnpublish = (question, Existing = false) => {
        const { actQuestions } = this.props
        let getUpdatedQuestion = actQuestions[question.act_id].filter((data) => data.id === question.id)
        if (Existing) {
            this.openDialogBox(getUpdatedQuestion[0], Existing)
        } else {
            this.openDialogBox(getUpdatedQuestion[0])
        }
    }

    renderForm() {
        const { selectTopics } = this.props;
        const { question } = this.state;
        const placeholder = question.topic.title;
        const type = "Topic"
        return (
            <div>
                <form onSubmit={this.updateQuestion}>
                    <SelectInput
                        options={selectTopics}
                        handler={this.handler}
                        placeholder={placeholder}
                        type={type} />
                    <button>Save Changes</button>
                </form>
                <button onClick={this.toggleForm}>Cancel</button>
            </div>
        )
    }

    renderContent() {
        const { formActive } = this.state;
        if (formActive) {
            return this.renderForm()
        } else {
            this.renderQuestion();
        }
    }

    actTypeTest = (header,testType) => {
        const { history, act } = this.props
        let actType = header.includes('English')
            ? 'English' : header.includes('Mathematics')
                ? 'Mathematics' : header.includes('Reading')
                    ? 'Reading' : 'Science'
        history.push(`/admin/act-test/${act.id}/${testType}/${actType}`)
    }

    publishUnPublishActTest = async (act_type, type) => {
        const { updateACT, act: {id: act_id, math_mock_test_publish, math_real_test_publish }, flashMessage } = this.props;
        if (act_type === "Mathematics"){
            if(type === 'real'){
                const updatedData = await updateACT(act_id, {math_real_test_publish: !math_real_test_publish});
                if(updatedData){
                    flashMessage({message: `Mathematics Real Test ${!math_real_test_publish ? 'Published' : 'Unpublished'}`, type: 'success'});
                }
            } else if(type === 'mock') {
                const updatedData = await updateACT(act_id, {math_mock_test_publish: !math_mock_test_publish});
                if(updatedData){
                    flashMessage({message: `Mathematics Mock Test ${!math_mock_test_publish ? 'Published' : 'Unpublished'}`, type: 'success'});
                }
            }
        }
    }

    render() {
        const { questions, header, fromPopUp, act, isLastIndex } = this.props;
        const { question } = this.state;

        let actType = header.includes('English')
        ? 'English' : header.includes('Mathematics')
            ? 'Mathematics' : header.includes('Reading')
                ? 'Reading' : 'Science'

        return (
            <div className="ques_set marginBottom20">
                <div className="satQuesIdTitleCls d-flex align-items-center">
                    <span className="mr-2 d-inline-block">{header}</span>
                    {
                        (actType === 'Mathematics' && fromPopUp !== true) ?
                            <>
                                <button onClick={() => this.actTypeTest(header,'real')} className="btn btn-sm btn-success mr-2">
                                    Real Test
                                </button>
                                <PublishUnPublishTestButton publish={act?.math_real_test_publish}
                                                            onClick={()=> this.publishUnPublishActTest(actType,"real")}/>
                                <button onClick={() => this.actTypeTest(header,'mock')} className="btn btn-sm btn-success mr-2">
                                    Mock Test
                                </button>
                                <PublishUnPublishTestButton publish={act?.math_mock_test_publish}
                                                            onClick={()=> this.publishUnPublishActTest(actType,"mock")}/>
                            </>
                            : ""
                    }
                </div>
                <ul>
                    {questions?.map(q => {
                        return (
                            <li className={`question ${question.id === q.id ? 'active' : ''}`}
                                onClick={() => this.openDialogBox(q)}
                                key={q.id} id={`T${q.id}`}>
                                {q.position + 1}
                            </li>
                        )
                    })}
                </ul>
                {!isLastIndex && <div className="setBottomBorder"/>}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return ({
        actQuestions: state.actQuestions,
        problemsets: state.problemsets
    })
}

const mapDispatchToProps = (dispatch) => {
    return ({
        getActProblemSets: (params) => dispatch(problemSetActions.getActProblemSets(params)),
    })
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SectionQuestions));
