import React, { Component } from 'react';
import Dialog from './Dialog';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
    dialog: state.dialog,
});

class DialogContainer extends Component {
    render() {
        const { dialog, show } = this.props;
        if (dialog && dialog.open && show) {
            return <Dialog {...dialog.properties} />
        }
        return null;
    }
}

export default connect(mapStateToProps, null)(DialogContainer);
