import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HomeProblemSetQuestions from '../question/HomeProblemSetQuestions';
import * as problemSetActions from '../../../../modules/problemSet/action';
import * as animationActions from '../../../../modules/animation/action'
import './HomeProblemSetDetail.scss';
import _ from 'lodash'
import * as subSubTopicActions from "../../../../modules/subSubTopic/action";
import * as satQuestionActions from "../../../../modules/satQuestion/action";
import * as actQuestionActions from "../../../../modules/actQuestion/action";
import { ProblemSetProgressBar } from "../../../admin/content/common/CommonMethod";
import {Loader, RenderCalcImage} from "../../../helper/commonMethods";
import { IN_A_ROW_CORRECT_COUNT } from "../../../../constants";
import {REPORT_PROBlEM_DIALOG_BOX} from "../../../dialogs/dialogs";
import * as dialogActions from "../../../../modules/dialog/action";
import * as commonRecords from "../../../../modules/storeTemporary/action";

class HomeProblemSetDetail extends Component {

    constructor(props) {
        super(props);
        let showIcon = true
        const { location: {search: locationPath} } = this.props;
        if (locationPath.includes('grammar') || locationPath.includes('reading')) {
            showIcon = false;
        }
        this.state = { title: "", showIcon: showIcon};
    }


    async componentDidMount() {
        window.scrollTo(0, 0);
        const {
            match: { params: { problemset: problemSetId, satQuestionId: questionId } }, getProblemSet,
            getSubSubtopic, getSatQuestions, getActQuestions, location
        } = this.props;

        let error404 = false, topicPublish = false;
        const problemSet = await getProblemSet(problemSetId).catch(error=>{
            error404 = error.response?.status === 404;
        });

        const title = problemSet.title;
        const queryString = new URLSearchParams(location.search);
        const dashboard = !!(queryString && queryString.get('dashboard'))
        if (dashboard) {
            topicPublish = true;
        } else if(problemSet?.subsubtopic_id.includes(questionId)){
            // check if (Math, Grammar or Reading) topic is published
            topicPublish = await getSubSubtopic(questionId).then((subSubTopic)=>{
                return subSubTopic?.isPublish && subSubTopic?.subtopic_published && subSubTopic?.topic_published;
            }).catch(error=>{
                error404 = error404 ||  error.response?.status === 404;
            });
        } else if(problemSet?.act_question_id.includes(questionId)){
            // check if Act topic is published
            topicPublish = await getActQuestions(questionId).then((topic)=>{
                return topic?.topic_published;
            }).catch(error=>{
                error404 = error404 ||  error.response?.status === 404;
            });
        } else if(problemSet?.sat_question_id.includes(questionId)){
            // check if sat topic is published
            topicPublish = await getSatQuestions(questionId).then((topic)=>{
                return topic?.topic_published;
            }).catch(error=>{
                error404 = error404 ||  error.response?.status === 404;
            });
        }

        if(error404 || !topicPublish){
            return this.props.history.push('/home/not_found')
        }
        this.setState({title})
    }

    takeBack = () => {
        const { location, history } = this.props
        const queryString = new URLSearchParams(location.search);
        const dashboard = !!(queryString && queryString.get('dashboard'))
        if (dashboard) {
            return this.props.history.push({pathname: '/home', params: { assignment: true}});
        }
        let appendString = ''
        if (queryString && queryString.get('topic') && queryString.get('subtopic') && queryString.get('subsubtopic')) {
            appendString = "?topic=" + queryString.get('topic')
                + "&subtopic=" + queryString.get('subtopic')
                + "&subsubtopic=" + queryString.get('subsubtopic')
        }
        if ((location.search).includes('math')) {
            history.push('/home/math' + appendString)
        } else if ((location.search).includes('grammar')) {
            history.push('/home/grammar' + appendString)
        } else if ((location.search).includes('reading')) {
            history.push('/home/reading' + appendString)
        } else if ((location.search).includes('acts')) {
            history.push('/home/acts?act=' + queryString.get('act') + '&act_question=' + queryString.get('act_question'))
        } else if ((location.search).includes('sats')) {
            history.push('/home/sats?sat=' + queryString.get('sat') + '&sat_question=' + queryString.get('sat_question'))
        }
    }

    componentWillUnmount() {
        this.props.clearCurrentQuestion();
        this.props.clearProblemSetProgressBar();
    }

    openReportProblemDialogBox = (question) => {
        return this.props.openDialog(REPORT_PROBlEM_DIALOG_BOX, {
            question
        })
    }

    render() {
        const {
            match: { params: { problemset: topicId , satQuestionId } }, problemsets, loading,
            temporaryData: {currentQuestion, progressData: problemSetProgress}
        } = this.props;
        const { title, showIcon } = this.state;

        let notCorrect = null;
        if(problemSetProgress){
            notCorrect = problemSetProgress ? (IN_A_ROW_CORRECT_COUNT - problemSetProgress.total_correct) : '';
        }

        let getProblemSet = problemsets && problemsets[satQuestionId] && _.find(problemsets[satQuestionId], (dd) => dd._id === topicId);

        return(
            <div className="row">
                <div className="content-wrapper-before gradient-45deg-indigo-purple"/>
                <div className="col s12">
                    <div className="container">
                        <div className="dash_top_section">
                            <div className="row vertical-modern-dashboard">
                                <div className="col-12">
                                    <div className="card animate fadeLeft">
                                        <div className="question_sec card-content">
                                            <div className="question-title-div align-items-center w-100">
                                                <div className="col d-flex back-button">
                                                    <button onClick={() => this.takeBack()} className="btn btn-default">
                                                        <i className="ico backicon"/>
                                                        <span className="btntxt">Back</span>
                                                    </button>
                                                </div>
                                                <div className="col d-flex justify-content-center">
                                                    <h3>
                                                        {getProblemSet?.title ?? title}
                                                    </h3>
                                                </div>
                                                <ProblemSetProgressBar notCorrect={notCorrect}
                                                                       progress={problemSetProgress}
                                                                       className={''}/>
                                                <div className="col d-flex justify-content-end report-problem">
                                                {
                                                    !!currentQuestion &&
                                                        <>
                                                            { showIcon && <RenderCalcImage question={currentQuestion}/> }
                                                            <button className="btn btn-default" onClick={() => this.openReportProblemDialogBox(currentQuestion)}>Report problem</button>
                                                        </>
                                                }
                                                </div>
                                            </div>
                                            <div className="quesHeader d-flex flex-wrap align-items-center text-center w-100"/>
                                            <HomeProblemSetQuestions topicType={"HomeProblemSetQuestions"}
                                                                     topicId={topicId}
                                                                     satQuestionId={satQuestionId}
                                                                     section="HomeProblemSetQuestions"
                                                                     takeBack={this.takeBack}/>
                                            <Loader loading={loading}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return ({
        loading: state.animation,
        problemsets: state.problemsets,
        temporaryData: state.temporaryData
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        startAnimation: () => dispatch(animationActions.start()),
        endAnimation: () => dispatch(animationActions.end()),
        getProblemSet: (id) => dispatch(problemSetActions.getProblemSet(id)),
        getSatQuestions: (id) => dispatch(satQuestionActions.getSatQuestion(id)),
        getActQuestions: (id) => dispatch(actQuestionActions.getActQuestion(id)),
        getSubSubtopic: (id) => dispatch(subSubTopicActions.getSubSubTopic(id)),
        openDialog: (config, options) => dispatch(dialogActions.open(config, options)),
        clearCurrentQuestion: ()=> dispatch(commonRecords.clearCurrentQuestion()),
        clearProblemSetProgressBar: ()=> dispatch(commonRecords.clearProblemSetProgress())
    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeProblemSetDetail));
