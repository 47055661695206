import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SVG from '../../../../SVG';
import "./AdminSubtopic.scss"
import * as subSubTopicActions from '../../../../../modules/subSubTopic/action';
import * as dialogActions from '../../../../../modules/dialog/action';
import * as problemSetActions from '../../../../../modules/problemSet/action'
import { EXISTING_PROBLEM_SET_DIALOG_BOX, PROBLEM_SETS_DIALOG_BOX } from '../../../../dialogs/dialogs';
import { SetScrollPosition } from "../../../../helper/commonMethods";
import EditTitle from "../../common/EditTitle";
import * as alertActions from "../../../../../modules/alert/action";
import {PublishUnPublishTopicButton} from "../../common/CommonMethod";

class AdminSubSubtopic extends Component {
    state = {
        showEdit: false,
        problemsets: [],
    }

    componentDidMount() {
        const { subSubtopic, satTopicModal } = this.props
        const queryString = new URLSearchParams(this.props.location.search);
        if (!satTopicModal && queryString && queryString.get('subsubtopic') && subSubtopic._id === queryString.get('subsubtopic')) {
            this.openProblemSetDialog(subSubtopic);
            SetScrollPosition(subSubtopic._id);
        }
    }

    toggle = (el) => {
        this.setState({ [el]: !this.state[el] })
    }

    async openProblemSetDialog(subSubtopic, Existing = false) {
        const { openDialog, getProblemSets, problemsets, satTopicModal, satTopic, updateAfterPublishUnpublish, mathTopic, actTopic } = this.props;
        await getProblemSets({ subsubtopic_id: (subSubtopic && subSubtopic._id) })
        if (satTopicModal === true && Existing === false) {
            openDialog(EXISTING_PROBLEM_SET_DIALOG_BOX, {
                subSubtopic,
                problemsets,
                satTopic,
                updateAfterPublishUnpublish: updateAfterPublishUnpublish,
                updateAfterSequencePublishUnpublish: this.updateAfterSequencePublishUnpublish,
                mathTopic,
                actTopic,
            })
        } else {
            openDialog(PROBLEM_SETS_DIALOG_BOX, {
                subSubtopic,
                problemsets,
                title: subSubtopic.title,
                updateAfterSequencePublishUnpublish: this.updateAfterSequencePublishUnpublish
            })
        }

    }

    updateAfterSequencePublishUnpublish = (subSubtopic, Existing = false) => {
        const updated_subSubtopic = this.props.subSubtopic._id === subSubtopic._id ? this.props.subSubtopic : subSubtopic
        if (Existing) {
            this.openProblemSetDialog(updated_subSubtopic, Existing)
        } else {
            this.openProblemSetDialog(updated_subSubtopic)
        }
    }

    renderForm() {
        const { subSubtopic, updateSubSubtopic } = this.props;
        const { showEdit } = this.state;
        if (!showEdit) {
            return (
                <p onClick={() => this.openProblemSetDialog(subSubtopic)} id={`T${subSubtopic._id}`}>
                    {subSubtopic.title}
                </p>
            )
        }

        return (
                <EditTitle onUpdate={updateSubSubtopic}
                           show={showEdit}
                           topicType={'SubSubTopic'}
                           flashMessage={this.flashMessage}
                           topic={subSubtopic}
                           toggleForm={() => this.toggle('showEdit')}/>
        )
    }

    deleteSubSubTopic = () => {
        const { deleteSubSubTopic, subSubtopic } = this.props;
        let res = window.confirm("Are you sure you want to delete this sub subtopic?");
        if (res) {
            deleteSubSubTopic(subSubtopic._id);
        } else {
            return null;
        }
    }

    publishUnPublishSubSubTopic() {
        const { subSubtopic, subSubTopicPublishUnPublish } = this.props;
        if (subSubtopic._id) {
            subSubTopicPublishUnPublish({ id: subSubtopic._id, isPublish: !subSubtopic.isPublish });
        } else {
            alert("Error: No subsubtopic id selected!!")
        }
    }

    renderButtons() {
        const { showEdit } = this.state;
        const { account, subSubtopic } = this.props
        if (!showEdit) {
            return (
                <div className="options my-2">
                    <SVG onClick={() => this.toggle('showEdit')} name="pencil" />
                    <SVG onClick={this.deleteSubSubTopic} name="trash" />
                    <PublishUnPublishTopicButton topic={subSubtopic}
                                                 onClick={() => this.publishUnPublishSubSubTopic()}
                                                 account={account} />
                </div>
            )
        }

        return null;
    }

    flashMessage = (payload, timeout = 5000) => {
        const {alertPush, alertDelete} = this.props;
        alertPush(payload);
        setTimeout(() => alertDelete(),timeout);
    }

    render() {
        const { satTopicModal } = this.props
        return (
            <div className="admin-subtopic">
                {this.renderForm()}
                {satTopicModal === false && this.renderButtons()}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return ({
        account: state.account
    })
}


const mapDispatchToProps = (dispatch) => {
    return ({
        getProblemSets: (params) => dispatch(problemSetActions.getAllProblemSets(params)),
        openDialog: (config, options) => dispatch(dialogActions.open(config, options)),
        updateSubSubtopic: (_id, attributes) => dispatch(subSubTopicActions.updateSubSubTopic(_id, attributes)),
        deleteSubSubTopic: (id) => dispatch(subSubTopicActions.deleteSubSubTopic(id)),
        subSubTopicPublishUnPublish: (data) => dispatch(subSubTopicActions.subSubTopicPublish(data)),
        alertPush: (payload) => dispatch(alertActions.alertPush(payload)),
        alertDelete: () => dispatch(alertActions.alertDelete())
    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminSubSubtopic));

