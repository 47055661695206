import {
    RECEIVE_ALL_QUESTIONS,
    RECEIVE_QUESTION,
    UPDATE_QUESTION,
    REMOVE_QUESTION,
    RECEIVE_MULTIPLE_QUESTION,
    RESET_QUESTION_DATA
} from '../constant';

const defaultState = [];
export const questionReducer = (state = defaultState, action) => {
    let newState;
    switch (action.type) {
        case RECEIVE_ALL_QUESTIONS:
            return action.data;
        case RECEIVE_MULTIPLE_QUESTION:
            newState = Object.assign([], state)
            for(let data of action.data){
                newState =  Object.assign([], newState, [...newState, data])
            }
            return newState;
        case RECEIVE_QUESTION:
            return Object.assign([], state, [...state, action.data])
        case UPDATE_QUESTION:
            newState = state.map(obj => (action.data.id === obj.id) ? action.data : obj);
            return newState;
        case REMOVE_QUESTION:
            newState = state.filter(obj => (action.data.id !== obj.id));
            return newState;
        case RESET_QUESTION_DATA:
            return defaultState;
        default:
            return state;
    }
}
