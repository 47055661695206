import * as dbUtil from '../../api/dbUtil';
import { API } from '../../api';
import {
    RECEIVE_SAT,
    RECEIVE_ALL_SATS,
    RECEIVE_ALL_PUBLISH_SATS,
    REMOVE_SAT,
    UPDATE_SAT,
    UPDATE_USER_SAT_POSITION,
    PUBLISH_SAT,
    RECEIVE_UPDATE_SAT_SCORE,
    RECEIVE_SAT_BUBBLE_SHEET_ANSWER
} from "../constant";

import { checkSessionExpired, alertPushMessage } from "..";

const receiveAll = (data) => ({type: RECEIVE_ALL_SATS, data});
const updateUserSatPosition = (data) => ({type: UPDATE_USER_SAT_POSITION, data});
const receiveAllPublish = (data) => ({ type: RECEIVE_ALL_PUBLISH_SATS, data });
const receive = (data) => ({type: RECEIVE_SAT, data });
const updateSatData = (data) => ({type: UPDATE_SAT, data });
const removeSat = (data) => ({type: REMOVE_SAT, data });
const satPublishUnPublish = (data) => ({ type: PUBLISH_SAT, data });
const receiveUpdateScore = (data) => ({type: RECEIVE_UPDATE_SAT_SCORE, data });
const receiveUpdateBubbleAnswers = (data) => ({type: RECEIVE_SAT_BUBBLE_SHEET_ANSWER, data });

export const getAllTestSats = () => {
    return (dispatch) => dispatch(getSats(false));
}

export const getAllRealSats = () => {
    return (dispatch) => dispatch(getSats(true));
}

const getSats = (real = false) => {
    return (dispatch) => {
        return dbUtil.fetchMany(`sats/${real ? 'real_sats' : 'test_sats'}`).then(({data}) => {
            dispatch(receiveAll(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getCurrentUserSats = (params) => {
    return (dispatch) => {
        return dbUtil.fetchMany('sats_users', params).then(({data}) => {
            dispatch(receiveAllPublish(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getTestSat = (id) => {
    return (dispatch) => dispatch(getSat(id, false));
}

export const getRealSat = (id) => {
    return (dispatch) => dispatch(getSat(id, true));
}

const getSat = (id, real = false) => {
    return (dispatch) => {
        return dbUtil.fetch(`sats/${real ? 'real_sats' : 'test_sats'}`, id).then(({data}) => {
            dispatch(receive(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateTestSAT = (id, attributes) => {
    return (dispatch) => dispatch(updateSat(id, attributes, false));
}

export const updateRealSAT = (id, attributes) => {
    return (dispatch) => dispatch(updateSat(id, attributes, true, true));
}

const updateSat = (id, attributes, real = false, updatedAlert = false) => {
    return (dispatch) => {
        return dbUtil.update(`sats/${real ? 'real_sats' : 'test_sats'}`, id, attributes).then(({data}) => {
            dispatch(updateSatData(data));
            if(updatedAlert){
                dispatch(alertPushMessage({message: `${data.title} Sat updated Successfully`, type: "success"}));
            }
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const deleteTestSAT = (id) => {
    return (dispatch) => dispatch(deleteSat(id, false));
}

export const deleteRealSAT = (id) => {
    return (dispatch) => dispatch(deleteSat(id, true));
}

const deleteSat = (id, real = false) =>{
    return (dispatch) => {
        return dbUtil.destroy(`sats/${real ? 'real_sats' : 'test_sats'}`, id).then(({data}) => {
            dispatch(removeSat(data));
            dispatch(alertPushMessage({message: `${data.title} Sat deleted Successfully`, type: "success"}));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const createTestSat = (attributes) => {
    return (dispatch) => dispatch(createSat(attributes, false));
}

export const createRealSat = (attributes) => {
    return (dispatch) => dispatch(createSat(attributes, true));
}

const createSat = (attributes, real = false) =>{
    return (dispatch) => {
        return dbUtil.create(`sats/${real ? 'real_sats' : 'test_sats'}`, attributes).then(({data}) => {
            dispatch(receive(data))
            dispatch(alertPushMessage({message: `${data.title} topic created Successfully`, type: "success"}));
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateSatProgressType = (sat) => {
    return (dispatch) => {
        dispatch(updateSatData({...sat, progress_by_problemset: !sat.progress_by_problemset}));
        return dbUtil.update('sats/test_sats', sat.id, {progress_by_problemset: !sat.progress_by_problemset}).then(({data}) => {
            dispatch(updateSatData(data));
            return data;
        }).catch((error) => {
            dispatch(updateSatData(sat));
            dispatch(checkSessionExpired(error.message));
        })
    }
}

export const updateSatsPositions = (sats) => {
    return (dispatch) => {
        return API.put("/sats/test_sats/update_positions", sats).then(({data}) => {
            dispatch(receiveAll(data))
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return sats;
        });
    }
}

export const updateUserSatsPositions = (sats) => {
    return (dispatch) => {
        return API.put("/sats_users/update_positions", sats).then(({data}) => {
            dispatch(updateUserSatPosition(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return sats;
        });
    }
}

export const publishUnPublishSat = (attributes) => {
    return (dispatch) => {
        return API.post('/sats/test_sats/publish_unpublish', attributes).then(({data}) => {
            dispatch(satPublishUnPublish(data));
            dispatch(alertPushMessage({message: `Sat ${data.title} ${data.isPublish ? 'Published' : 'Unpublished'} successfully`, type: "success"}));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateSatScore = (attributes) => {
    return (dispatch) => {
        return API.post('/sats/test_sats/update_score', attributes).then(({data}) => {
            dispatch(receiveUpdateScore(data));
            dispatch(alertPushMessage({message: `'${data.title}' Raw Score Conversion uploaded`, type: "success"}));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateSatBubbleAnswers = (attributes) => {
    return (dispatch) => {
        return API.post('/sats/test_sats/update_bubble_answers', attributes).then(({data}) => {
            dispatch(receiveUpdateBubbleAnswers(data))
            dispatch(alertPushMessage({message: `'${data.title}' bubble sheet answer uploaded`, type: "success"}));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}
