import * as dbUtil from '../../api/dbUtil';
import {
    RECEIVE_ALL_QUESTION_PROGS,
    RECEIVE_QUESTION_PROG,
    UPDATE_QUESTION_PROG,
    REMOVE_QUESTION_PROG
} from '../constant'
import { checkSessionExpired } from "..";

const receiveAll = (data) => ({type: RECEIVE_ALL_QUESTION_PROGS, data});
const receive = (data) => ({type: RECEIVE_QUESTION_PROG, data});
const updateQuestion = (data) => ({type: UPDATE_QUESTION_PROG, data});
const removeQuestion = (data) => ({type: REMOVE_QUESTION_PROG, data});

export const getAllQuestionProg = (params) => {
    return (dispatch) => {
        return dbUtil.fetchMany('question_progs', params).then(({data}) => {
            dispatch(receiveAll(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const getQuestionProg = (id) => {
    return (dispatch) => {
        return dbUtil.fetch('question_progs', id).then(({data}) => {
            dispatch(receive(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const updateQuestionProg = (id, attributes) => {
    return (dispatch) => {
        return dbUtil.update('question_progs', id, attributes).then(({data}) => {
            dispatch(updateQuestion(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const destroyQuestionProg = (id) => {
    return (dispatch) => {
        return dbUtil.destroy('question_progs', id).then(({data}) => {
                dispatch(removeQuestion(data));
                return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const createQuestionProg = (attributes) => {
    return (dispatch) => {
        return dbUtil.create('question_progs', attributes).then(({data}) => {
            dispatch(receive(data))
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}

export const findOrCreateQuestionProg = (attributes) => {
    return (dispatch) => {
        return dbUtil.findOrCreate('question_progs', attributes).then(({data}) => {
            dispatch(receive(data));
            return data;
        }).catch((error)=>{
            dispatch(checkSessionExpired(error.message));
            return false;
        });
    }
}
