import {combineReducers} from "redux";
import {
    RECEIVE_ALL_ADMIN, UPDATE_ADMIN, RECEIVE_ALL_TUTOR, UPDATE_TUTOR, RECEIVE_ALL_STUDENT, UPDATE_STUDENT,
    RECEIVE_ALL_STUDENT_LISTS, LINK_USER_WITH_TUTOR
} from "../constant";

const defaultState = {data: [], count: 0};

const adminReducer = (state = defaultState, action) => {
    switch (action.type) {
        case RECEIVE_ALL_ADMIN:
            return action.data;
        case UPDATE_ADMIN:
        case UPDATE_TUTOR:
        case LINK_USER_WITH_TUTOR:
            let data = state.data.map(user => user.id === action.data.id ? action.data : user)
            return({...state, ...{data: data}});
        default:
            return state;
    }
}

const tutorReducer = (state = defaultState, action) => {
    switch (action.type) {
        case RECEIVE_ALL_TUTOR:
            return action.data;
        case UPDATE_TUTOR:
        case UPDATE_ADMIN:
        case LINK_USER_WITH_TUTOR:
            let data = state.data.map(user => user.id === action.data.id ? action.data : user)
            return({...state, ...{data: data}});
        default:
            return state;
    }
}

const studentReducer = (state = defaultState, action) => {
    switch (action.type) {
        case RECEIVE_ALL_STUDENT:
            return action.data;
        case UPDATE_STUDENT:
            let data = state.data.map(user => user.id === action.data.id ? action.data : user)
            return({...state, ...{data: data}});
        default:
            return state;
    }
}

const allStudentReducer = (state = defaultState, action) => {
    switch (action.type) {
        case RECEIVE_ALL_STUDENT_LISTS:
            return action.data;
        case UPDATE_STUDENT:
            let data = state.data.map(user => user.id === action.data.id ? action.data : user)
            return({...state, ...{data: data}});
        default:
            return state;
    }
}

export const userReducer = combineReducers({
    admins: adminReducer,
    tutors: tutorReducer,
    students: studentReducer,
    allStudents: allStudentReducer
});
