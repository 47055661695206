import React, { Component } from 'react';
import { connect } from 'react-redux';
import HomeSubtopics from './HomeSubtopics';
import * as subtopicActions from '../../../../modules/subTopic/action';
import * as topicActions from '../../../../modules/topic/action';
import "./HomeTopic.scss";
import { PROBLEM_SETS_DIALOG_BOX } from '../../../dialogs/dialogs';
import * as dialogActions from '../../../../modules/dialog/action';
import * as problemSetActions from '../../../../modules/problemSet/action'
import { withRouter } from 'react-router-dom';

class HomeTopic extends Component {
    state = {
        show: false,
    }

    toggle = (el) => {
        this.setState({ [el]: !this.state[el] })
    }

    deleteTopic = () => {
        const { deleteTopic, topic } = this.props;
        let res = window.confirm("Are you sure you want to delete this topic?");
        if (res) {
            deleteTopic(topic.id);
        } else {
            return null;
        }
    }

    pubishUnpublishTopic() {
        const { topic, publishOrUnpublishTopic, topicType } = this.props;
        if (topic.id) {
            publishOrUnpublishTopic({ id: topic.id, isPublish: !topic.isPublish, topicType });
        } else {
            alert("Error: No topic id selected!!")
        }
    }


    openSatProblemSetDialog = async () => {
        const { openDialog, topic, topicType, getProblemSets } = this.props;
        const subtopics = topic?.subtopics
        if (topic?.subtopics?.[0]) {
            subtopics[0].id = topic?.subtopics[0]._id
        }
        const satTopicId = topic?.subtopics?.length > 0 ? topic?.subtopics?.[0]._id : topic.id
        await getProblemSets({ sattopic_id: satTopicId, topicType })
        this.setState({ satTopic: topic })
        const { satTopic } = this.state
        openDialog(PROBLEM_SETS_DIALOG_BOX, {
            satTopic,
            subtopics: topic?.subtopics,
            topicType

        })
        // this.setState({show:true})
        this.toggle('show');
    }

    getSubtopics = async () => {
        const { getSubtopics, topic } = this.props;
        await getSubtopics({ test_id: topic.id, isPublish: true });
        this.toggle('show');
    }

    getFunctionAccToTopic = async () => {
        const { topicType } = this.props
        if (topicType === 'SatTopic') {
            this.openSatProblemSetDialog()
        } else {
            this.getSubtopics()
        }
    }

    renderForm() {
        const { topic } = this.props;
        return (
            <div className="topicTitle ">
                <span className="ico_arrow" onClick={this.getFunctionAccToTopic}/>
                <p className="topicTitle" onClick={this.getFunctionAccToTopic}>
                    {topic.title}
                </p>
            </div>
        )
    }

    createSubtopic = async (data) => {
        const { createSubtopic, topic } = this.props;
        await createSubtopic({ test_id: topic.id, ...data })
    }

    componentDidMount() {
        const { topic } = this.props
        const queryString = new URLSearchParams(this.props.location.search);
        if (queryString && queryString.get('topic') && (topic.id === queryString.get('topic'))) {
            this.getFunctionAccToTopic();
        }
    }


    render() {
        const { topic, subtopics, updateSubTopicPositions, topicType, satTopicModal, satTopic } = this.props;
        const { show } = this.state;
        return (
            <div className={`home-topic ${show ? "open" : ''}`}>
                {this.renderForm()}

                {(topicType !== 'SatTopic') &&
                    <div className="topic-content">
                        <HomeSubtopics show={show}
                                       subtopics={subtopics[topic.id]}
                                       updateSubTopicPositions={updateSubTopicPositions}
                                       satTopicModal={satTopicModal}
                                       satTopic={satTopic}/>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return ({
        account: state.account,
        subtopics: state.subtopics,
    })
}

const mapDispatchToProps = (dispatch) => {
    return ({
        getProblemSets: (params) => dispatch(problemSetActions.getAllProblemSets(params)),
        getSubtopics: (params) => dispatch(subtopicActions.getAllSubTopics(params)),
        createSubtopic: (params) => dispatch(subtopicActions.createSubTopics(params)),
        // updateTopic: (id, attributes) => dispatch(topicActions.update('topics', id, attributes)),
        // deleteTopic: (id) => dispatch(topicActions.destroy('topics', id)),
        updateSubTopicPositions: (subtopics) => dispatch(subtopicActions.updateSubTopicPositions(subtopics)),
        openDialog: (config, options) => dispatch(dialogActions.open(config, options)),
        publishOrUnpublishTopic: (data) => dispatch(topicActions.publishOrUnPublishTopic(data)),
    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeTopic));
