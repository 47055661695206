import React, { Component } from 'react';
import { connect } from 'react-redux';
import "./Login.scss";
import AccountTemplate from "./AccountTemplate";
import { SignUpButtonTitle, SignUpTitle } from "./AccountConstant";
import { EmailInput, PasswordInput, TextInput } from "../helper/commonMethods";
import * as animationActions from "../../modules/animation/action";
import * as accountActions from "../../modules/account/action";

const mapDispatchToProps = dispatch => {
    return ({
        registerAccount: (attributes) => dispatch(accountActions.registerAccount(attributes)),
        startAnimation: () => dispatch(animationActions.start()),
        endAnimation: () => dispatch(animationActions.end()),
    })
}

const mapStateToProps = state => {
    return ({
        loading: state.animation,
    })
}

class Register extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            confirmPassword: "",
            code: "",
            serviceMessage: ''
        }

        this.registerUser = this.registerUser.bind(this);
        this.validate = this.validate.bind(this);
    }

    validate() {
        const { email, password, confirmPassword } = this.state;
        return email.length > 0 && password.length > 0 && confirmPassword === password
    }

    trimValues = async () =>{
        let { email, code } = this.state;
        await this.setState({email: email.trim(), code: code.trim()});
    }

    async registerUser(e) {
        e.preventDefault();
        await this.trimValues();
        let { password, email, code } = this.state;
        if (this.validate()) {
            const { startAnimation, history, registerAccount, endAnimation } =  this.props;
            await startAnimation();
            const successMessage = await registerAccount({password, email, code, userType: "Student", status: true});
            if(successMessage){
                history.push({ pathname: '/', successMessage: successMessage});
            }
            await endAnimation();
        } else {
            this.setState({ serviceMessage: 'Please enter valid email and password & confirm password should match.' })
        }
    }

    handleChange(prop) {
        return ({ target }) => this.setState({ [prop]: target.value, serviceMessage: '' })
    }

    render() {
        const { email, password, confirmPassword, code, serviceMessage } = this.state;
        const { loading } = this.props;

        return (
            <AccountTemplate title={SignUpTitle} buttonTitle={SignUpButtonTitle} serviceMessage={serviceMessage}
                             haveAccount={true} loader={loading} onSubmit={this.registerUser}>
                <EmailInput
                    title="Email"
                    name="email"
                    value={email}
                    placeholder="abc@example.com"
                    onChange={this.handleChange('email')}
                />
                <PasswordInput
                    title="Password"
                    name="password"
                    value={password}
                    placeholder="Password"
                    onChange={this.handleChange('password')}
                />
                <PasswordInput
                    title="Confirm Password"
                    name="confirmPassword"
                    value={confirmPassword}
                    placeholder="Confirm Password"
                    onChange={this.handleChange('confirmPassword')}
                />
                <TextInput
                    title="Code"
                    name="code"
                    value={code}
                    placeholder="Code"
                    onChange={this.handleChange('code')}
                />
            </AccountTemplate>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);