import React, {Component} from 'react';
import SortableTopics from './SortableTopics';
import {connect} from 'react-redux';
import SVG from '../../../SVG';
import 'katex/dist/katex.min.css'
import {validSelect} from '../../../../utilities/selectUtil';
import * as topicActions from '../../../../modules/topic/action';
import * as grammarTopicActions from '../../../../modules/grammarTopic/action';
import * as readingTopicActions from '../../../../modules/readingTopic/action';
// import * as answerActions from '../../../../../actions/answerActions';
import * as animationActions from '../../../../modules/animation/action';
import * as dialogActions from '../../../../modules/dialog/action';
import './AdminTopics.scss'
import AddNewTopic from "../common/AddNewTopic";

class AdminTopics extends Component {
    state = {
        topic: "",
        topicId: "",
        formActive: false,
        fromTopic: false,
        fromTopicPosition: "",
        newTopic: {},
    }

    async componentDidMount() {
        window.scrollTo(0,0);
        const { getTopics, startAnimation, endAnimation, topicType } = this.props;
        if (validSelect(topicType)) {
            startAnimation();
            await getTopics({ order_by: { position: 'asc' } });
            endAnimation();
        }
    }

    async componentDidUpdate(prevProps) {
        const { startAnimation, endAnimation } = this.props;
        // if (this.props.topicId !== prevProps.topicId && validSelect(this.props.topicId)) {
        if (this.props.topicType !== prevProps.topicType) {
            window.scrollTo(0,0);
            startAnimation();
            await this.props.getTopics();
            endAnimation();
        }
        // }
    }

    updateTopicPositions = async (topics, type = null) => {
        const {
            startAnimation, endAnimation, updateTopicPositions, topicType, updateGrammarTopicPositions,
            updateReadingTopicPositions
        } = this.props;
        startAnimation();
        if (topicType === 'GrammarTopic') {
            await updateGrammarTopicPositions({topics})
        } else if (topicType === 'ReadingTopic') {
            await updateReadingTopicPositions({topics})
        } else {
            await updateTopicPositions({topics});
        }
        endAnimation();
    }

    // Reset form and data for topic creation below a specified topic
    resetTopicData = () => {
        this.setState({ fromTopic: false, fromTopicPosition: "" })
    }

    // Send props to topic form to create topic below a specified topic
    createFromTopic = (topicPosition) => {
        this.setState({ fromTopic: true, fromTopicPosition: topicPosition })
    }

    createAndAddTopic = async (topicData) => {
        const { createTopic } = this.props;
        const newTopic = await createTopic(topicData);
        this.setState({ newTopic })
    }

    resetNewTopic = () => {
        this.setState({ newTopic: {} })
    }

    renderLoading() {
        const { loading } = this.props;
        if (loading) {
            return (
                <div className="loading-overlay">
                    <SVG name="spin" />
                </div>
            )
        }

        return null;
    }

    renderTopics() {
        const { topics, topicType, deleteTopic, updateTopic } = this.props;
        const { newTopic } = this.state;
        if (topics.length > 0) {
            return (
                <SortableTopics newTopic={newTopic}
                                resetNewTopic={this.resetNewTopic}
                                topics={topics}
                                updateTopicPositions={this.updateTopicPositions}
                                createFromTopic={this.createFromTopic}
                                deleteTopic={deleteTopic}
                                updateTopic={updateTopic}
                                topicType={topicType}
                                satTopicModal={false}/>
            )
        }
        return null;
    }

    render() {
        const { loading, topicType, title } = this.props;
        if (!validSelect(topicType)) return null;
        return (
            <div className={`row ${loading ? 'loading-topics' : ''}`}>
                <div className="content-wrapper-before gradient-45deg-indigo-purple"/>
                <div className="col s12">
                    <div className="container">
                        <div className="dash_top_section">
                             <div className="dash_header d-flex flex-wrap align-items-center">
                                <h2 className="dash_title mr-auto w-auto my-2">{title}</h2>
                                <AddNewTopic createTopic={this.createAndAddTopic} topicType={"Topic"} />
                             </div>
                            <div className="row vertical-modern-dashboard">
                                <div className="col-12 satAct_content_sec mgrSec_wrap ">
                                    <div className="card animate fadeLeft">
                                        <div className="mgr_sec card-content">
                                            {this.renderLoading()}
                                            {this.renderTopics()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return ({
        loading: state.animation,
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        startAnimation: () => dispatch(animationActions.start()),
        endAnimation: () => dispatch(animationActions.end()),
        // getTopic: (id) => dispatch(topicActions.get('topics', id)),
        // createTopic: (attributes) => dispatch(topicActions.create('topics', attributes)),
        updateTopicPositions: (topics) => dispatch(topicActions.updatePositions(topics)),
        updateGrammarTopicPositions: (topics) => dispatch(grammarTopicActions.updateGrammarTopicPositions(topics)),
        updateReadingTopicPositions: (topics) => dispatch(readingTopicActions.updateReadingTopicPositions(topics)),
        openDialog: (config, options) => dispatch(dialogActions.open(config, options))
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminTopics);
