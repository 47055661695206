import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./HomeSubtopic.scss";
import * as subtopicActions from '../../../../modules/subTopic/action';
import * as subSubTopicActions from '../../../../modules/subSubTopic/action';
import * as dialogAction from '../../../../modules/dialog/action';
import HomeSubSubtopics from './HomeSubSubtopics'
import AssignButton from '../../AssignButton';
import {
    getProblemSetProgress,
    getUserProgressAndProgressQuestion,
    SetScrollPosition
} from "../../../helper/commonMethods";
import IfView from "../../../helper/IfView";
import _ from "lodash";
import * as questionActions from "../../../../modules/question/action";
import * as problemSetQuestionActions from "../../../../modules/problemSetQuestion/action";
import {IN_A_ROW_CORRECT_COUNT} from "../../../../constants";
import * as userProgressActions from "../../../../modules/userProgress/action";


class HomeSubtopic extends Component {
    state = {
        show: false,
        showCreate: false,
    }

    async componentDidMount() {
        const { subtopic } = this.props
        const queryString = new URLSearchParams(this.props.location.search);
        if (queryString && queryString.get('subtopic') && (subtopic.id === queryString.get('subtopic'))) {
            await this.getSubSubTopic();
            SetScrollPosition(subtopic.id);
        }
    }

    toggle = (el) => {
        this.setState({ [el]: !this.state[el] })
    }

    goToSubTopic = async () =>{
        const { subtopic, location, getUserProgress, getQuestions, getUserProblemsetProgress, getProblemSetQuestions, resetUserProgress, account } = this.props;
        await getUserProgress({ reference_id: subtopic.id, reference_type: 'HomeSubTopic', user_id: account.id });
        const problemSetId = account?.progress?.next_problemset_id;
        const insideProblemSet = !!(_.has(account, 'progress.next_problemset_id') && problemSetId);
        if (insideProblemSet) {
            await getQuestions({ params: { subtopic_id: subtopic.id }, order_by: { position: 'asc' } });
            await getProblemSetQuestions({ problem_set_id: account.progress.next_problemset_id });
            await getUserProblemsetProgress({ reference_id: problemSetId, reference_type: 'HomeProblemSetQuestions', user_id: account.id })
            const { problemset_questions, questions } = this.props;
            if(problemset_questions.length > 0 && questions?.length > 0){
                const { account } = this.props;
                const {userProgress, userProgressQuestion} = getUserProgressAndProgressQuestion({account,questions});
                if(userProgress && (userProgressQuestion === '' || !userProgressQuestion)) {
                    let { problemSetCorrectAnswers } = getProblemSetProgress({account, problemset_questions});
                    if(problemSetCorrectAnswers >= IN_A_ROW_CORRECT_COUNT){
                        await resetUserProgress({ reference_id: subtopic.id, reference_type: 'HomeSubTopic', user_id: account.id })
                    }
                }
            }
        }

        let appendQueryString = '';
        if (subtopic.test_id) {
            appendQueryString = "?topic=" + subtopic.test_id
        }

        if (location.pathname.includes("home/math")) {
            this.props.history.push('/home/math/' + subtopic.id + '/' + subtopic.test_id + appendQueryString);
        } else if (location.pathname.includes("home/grammar")) {
            this.props.history.push('/home/grammar/' + subtopic.id + '/' + subtopic.test_id + appendQueryString);
        } else if (location.pathname.includes("home/reading")) {
            this.props.history.push('/home/reading/' + subtopic.id + '/' + subtopic.test_id + appendQueryString);
        }
    }


    getSubSubTopic = async () => {
        const { getSubSubtopics, subtopic } = this.props;
        await getSubSubtopics({ subtopic_id: subtopic.id, isPublish: true })
        this.toggle('show');
    }

    render() {
        const {
            subtopic, subsubtopics, updateSubSubTopicPositions, satTopicModal, satTopic, account, location, openDialog,
            closeDialog, assignments
        } = this.props;
        const { show } = this.state;
        return (
            <div className={`home-topic ${show ? "open" : ""}`}>
                <div className="subtopicHeader">
                    <span className="subtopicTitle" style={{ cursor: 'pointer' }} onClick={this.getSubSubTopic}>
                        {subtopic.title}
                    </span>
                    <IfView visible={satTopicModal === false}>
                        <span className="sequenceBtn">
                            <IfView visible={!(location.pathname.includes("home") && subtopic.isSequencePublish === false)}>
                                <button className="btn btn-primary" disabled={!!account.tutor_login} onClick={this.goToSubTopic}>
                                    Sequence
                                </button>
                            </IfView>
                        </span>
                        <span className="assign-btn mr-auto">
                            <AssignButton referenceType={"subtopic"}
                                          reference_type={"HomeSubTopic"}
                                          reassign={account.tutor_login && subtopic.isSequencePublish === true && (account?.progress?.percent_complete >= 100) && (assignments?.filter(assignment => (assignment.assignmentable_id === subtopic.id &&  assignment.status === "completed")).length > 0)}
                                          show={account.tutor_login && subtopic.isSequencePublish === true && !(assignments.map((assignment) => assignment.assignmentable_id).includes(subtopic.id))}
                                          referenceId={subtopic.id}
                                          topicName="subtopic"
                                          topicId={subtopic.id}
                                          account={account}
                                          openDialog={openDialog}
                                          close={closeDialog}/>
                        </span>
                    </IfView>
                    <span className="icon align-right" onClick={this.getSubSubTopic} style={{cursor:"pointer"}}>Arrow icon</span>
                </div>

                <div className="subtopic-content" id={`T${subtopic.id}`}>
                    <HomeSubSubtopics
                        show={show}
                        subsubtopics={subsubtopics[subtopic.id]}
                        updateSubSubTopicPositions={updateSubSubTopicPositions}
                        satTopicModal={satTopicModal}
                        satTopic={satTopic}
                        topicId={subtopic.test_id}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return ({
        account: state.account,
        assignments: state.assignments,
        subsubtopics: state.subsubtopics,
        questions: state.questions,
        problemset_questions: state.problemset_questions,
    })
}

const mapDispatchToProps = (dispatch) => {
    return ({
        updateSubtopic: (id, attributes) => dispatch(subtopicActions.updateSubTopics(id, attributes)),
        getSubSubtopics: (params) => dispatch(subSubTopicActions.getAllSubSubTopics(params)),
        updateSubSubTopicPositions: (subsubtopics) => dispatch(subSubTopicActions.updateSubSubTopicPositions(subsubtopics)),
        getQuestions: (params) => dispatch(questionActions.getAllQuestions(params)),
        getProblemSetQuestions: (params) => dispatch(problemSetQuestionActions.getAllProblemSetQuestions(params)),
        getUserProblemsetProgress: (params) => dispatch(userProgressActions.getUserProblemSetProgress(params)),
        getUserProgress: (params) => dispatch(userProgressActions.getUserProgress(params)),
        resetUserProgress: (params) => dispatch(userProgressActions.resetUserProgress(params)),
        openDialog: (config, options) => dispatch(dialogAction.open(config, options)),
        closeDialog: () => dispatch(dialogAction.close())

    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeSubtopic));

