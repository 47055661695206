import React, { Component } from 'react';
import { NewForm } from "../../../utilities/newForm";

class FormDialog extends Component {

  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  submit = async (params) => {
    const { createAction, close } = this.props;
      createAction(params);
      close();
  }

  cancel = () =>{
    const { close } = this.props;
    close();
  }


  render() {
    const { options } = this.props;
    return(<NewForm options={options} onSubmit={this.submit} onCancel={this.cancel}/>);
  }
}


export { FormDialog };
