import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { validSelect } from '../../../../utilities/selectUtil';
import * as questionActions from '../../../../modules/question/action';
import * as animationActions from '../../../../modules/animation/action';
import * as problemSetQuestionActions from '../../../../modules/problemSetQuestion/action';
import * as userProgressActions from "../../../../modules/userProgress/action";
import ViewSubsubtopicQuestions from './ViewSubsubtopicQuestions';
import './ViewSubsubtopicQuestions.scss';
import SVG from '../../../SVG';
import _ from 'lodash';
import IfView from "../../../helper/IfView";
import {getUserProgressAndProgressQuestion} from "../../../helper/commonMethods";


const mapStateToProps = state => {
    return ({
        questions: state.questions,
        loading: state.loading,
        problemset_questions: state.problemset_questions,
        account: state.account
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        startAnimation: () => dispatch(animationActions.start()),
        endAnimation: () => dispatch(animationActions.end()),
        getQuestions: (params) => dispatch(questionActions.getAll('question_subsubtopics', params)),
        getProblemSetQuestions: (params) => dispatch(problemSetQuestionActions.getAllProblemSetQuestions(params)),
        getUserProgress: (params) => dispatch(userProgressActions.getUserProgress(params)),
        getUserProblemsetProgress: (params) => dispatch(userProgressActions.getUserProblemSetProgress(params)),
    })
}

class HomeSubSubTopicQuestions extends Component {

    constructor(props) {
        super(props)
        this.state = {
            questions: []
        }
    }

    async componentDidMount() {
        const {
            getQuestions, topicId, topicType, startAnimation, endAnimation, account, getUserProgress,
            getUserProblemsetProgress, getProblemSetQuestions
        } = this.props;
        startAnimation()
        await getUserProgress({ reference_id: topicId, reference_type: topicType, user_id: account.id })
        await getQuestions({ params: { subsubtopic_id: topicId }, order_by: { position: 'asc' } });
        if (_.has(this.props.account, 'progress.next_problemset_id') && this.props.account.progress.next_problemset_id) {
            const problem_set_id = this.props.account.progress.next_problemset_id;
            await getUserProblemsetProgress({ reference_id: problem_set_id, reference_type: 'HomeProblemSetQuestions', user_id: account.id })
            await getProblemSetQuestions({ problem_set_id: problem_set_id });
        }
        endAnimation()

    }

    componentDidUpdate(props) {
        if (this.props.questions !== props.questions) {
            this.setState({ questions: this.props.questions })
        }
    }

    render() {
        const { topicId, topicType, section, loading, problemset_questions, getProblemSetQuestions, account, takeBack } = this.props;
        const { questions } = this.state
        if (!validSelect(topicId)) return null;
        const {userProgress, userProgressQuestion} = getUserProgressAndProgressQuestion({account,questions});
        return (
            <div className={`home-questions ${loading ? 'loading-topics' : ''}`}>
                <IfView visible={!!(questions && questions.length > 0)}>
                    <ViewSubsubtopicQuestions
                        topicId={topicId}
                        topicType={topicType}
                        section={section}
                        questions={questions}
                        userProgress={userProgress}
                        userProgressQuestion={userProgressQuestion}
                        problemset_questions={problemset_questions}
                        firstQuestion={userProgress && userProgressQuestion ? userProgressQuestion : questions ? questions[0] : ''}
                        firstProblemSetQuestion={problemset_questions ? problemset_questions[0] : ''}
                        getProblemSetQuestions={getProblemSetQuestions}
                        takeBack={takeBack}/>
                </IfView>
                <IfView visible={loading}>
                    <div className="loading-overlay">
                        <SVG name="spin" />
                    </div>
                </IfView>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeSubSubTopicQuestions));
