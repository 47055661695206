import {
    CLEAR_ACTS_USER_DATA,
    CLEAR_CURRENT_QUESTION,
    CLEAR_PROBLEM_SET_PROGRESS,
    CLEAR_SATS_USER_DATA,
    SET_ACTS_USER_DATA,
    SET_ASSIGNMENT_DEFAULT_DATA,
    SET_ASSIGNMENT_OPENED_VIEW,
    SET_COMPLETED_ASSIGNMENT_PAGE,
    SET_CURRENT_ASSIGNMENT_PAGE,
    SET_CURRENT_QUESTION,
    SET_PROBLEM_SET_PROGRESS,
    SET_SATS_USER_DATA
} from "../constant";

export const setAssignmentDefaultData = () =>  ({ type: SET_ASSIGNMENT_DEFAULT_DATA });
export const setAssignmentData = (type,data) => {
    let dataType = "";
    switch (type) {
        case 'completedPageNumber':
            dataType = SET_COMPLETED_ASSIGNMENT_PAGE;
            break;
        case 'currentPageNumber':
            dataType = SET_CURRENT_ASSIGNMENT_PAGE;
            break;
        case 'openedView':
            dataType = SET_ASSIGNMENT_OPENED_VIEW;
            break;
        default:
            break;
    }
    return ({type: dataType, data})
}

export const setCurrentQuestion = (data) => ({ type: SET_CURRENT_QUESTION, data });
export const clearCurrentQuestion = () => ({ type: CLEAR_CURRENT_QUESTION });
export const setProblemSetProgress = (data) => ({ type: SET_PROBLEM_SET_PROGRESS, data });
export const clearProblemSetProgress = () => ({ type: CLEAR_PROBLEM_SET_PROGRESS });
export const setSatsUserData = (data) => ({ type: SET_SATS_USER_DATA, data });
export const clearSatsUserData = () => ({ type: CLEAR_SATS_USER_DATA });
export const setActsUserData = (data) => ({ type: SET_ACTS_USER_DATA, data });
export const clearActsUserData = () => ({ type: CLEAR_ACTS_USER_DATA });
