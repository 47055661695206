import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReadOnlyEditor from '../../../editor/ReadOnlyEditor';
import * as answerActions from '../../../../modules/answer/action';
import * as animationActions from '../../../../modules/animation/action';
import * as accountActions from '../../../../modules/account/action';
import './ViewSubtopicQuestions.scss'
import _ from 'lodash'
import { IN_A_ROW_CORRECT_COUNT } from '../../../../constants'
import { checkCorrectOnFreeResponseAnswers } from '../../../../utilities/custom'
import IfView from "../../../helper/IfView";
import {
    FreeResponseWithExplanation,
    getFreeResponseAnswers,
    QuestionView,
    ViewFullExplanation
} from "./CommonQuestionView";
import ReportQuestionClass from "./ReportQuestionClass";
import IfElseView from "../../../helper/IfElseView";
import * as alertActions from "../../../../modules/alert/action";
import * as commonRecords from "../../../../modules/storeTemporary/action";
import * as userProgressActions from "../../../../modules/userProgress/action";
import * as assignmentActions from "../../../../modules/assignment/action";

class ViewSubtopicQuestions extends ReportQuestionClass {

    constructor(props) {
        super(props)

        const {firstQuestion, account, userProgress, userProgressQuestion } = props;
        const accountProgress = account?.progress;
        const insideQuestions = !(_.has(account, 'progress.next_problemset_id') && accountProgress?.next_problemset_id);
        let nextLinkQuestionId = '', questions_complete = userProgress?.questions_complete ?? 0;
        if (!insideQuestions){
            nextLinkQuestionId = userProgressQuestion && firstQuestion ? firstQuestion.id : '';
        }

        this.state = {
            viewQuestion: firstQuestion ?? {},
            insideQuestions: insideQuestions,
            questions_complete: questions_complete,
            satSequenceCorrectAnswers: accountProgress?.total_correct ?? 0,
            time_spent_before_answer_for_sequence: accountProgress?.time_spent_before_answer ?? 0,
            time_spent_after_answer_for_sequence:  accountProgress?.time_spent_after_answer ?? 0,
            time_spent_before_answer_for_problemset: 0,
            time_spent_after_answer_for_problemset: 0,
            problemSetCorrectAnswers: 0,
            free_response_answer_value: '',
            seconds: 0,
            idleTime: 0,
            view_full_explanation: false,
            showExplanation: false,
            viewProblemSetQuestion: '',
            answersGiven: [],
            getAnswersValue: '',
            nextLinkQuestionId: nextLinkQuestionId,
            skipProblemSetIfComplete: true,
            problemSetId: ''
        }
    }

    async componentDidMount() {
        super.componentDidMount();
        const { getAnswers, startAnimation, endAnimation } = this.props;
        const { viewQuestion } = this.state;
        if (viewQuestion) {
            this.setCurrentQuestion(viewQuestion);
            this.idleInterval = setInterval(() => this.checkIdealTime(), 1000);
            this.timer = setInterval(() => this.counter(), 1000);
            startAnimation()
            await getAnswers({ params: { question_id: viewQuestion.id } })
            endAnimation()
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.props.clearCurrentQuestion()
    }

    componentDidUpdate = async (props) => {
        const { getAnswers, startAnimation, endAnimation, account, problemset_questions, resetUserProblemSetProgress } = this.props
        const { skipProblemSetIfComplete, nextLinkQuestionId, problemSetId } =  this.state;
        if ((problemset_questions).length > 0 && (problemset_questions !== props.problemset_questions)) {
            let { problemSetQuestion, problemSetCorrectAnswers } = this.getProblemSetProgress();
            if(problemSetCorrectAnswers >= IN_A_ROW_CORRECT_COUNT && skipProblemSetIfComplete === true){
                this.setState({
                    viewProblemSetQuestion: '',
                    getAnswersValue: '',
                    insideQuestions: true,
                    problemSetCorrectAnswers: problemSetCorrectAnswers,
                    showExplanation: false,
                    free_response_answer_value: '',
                    view_full_explanation: false,
                }, async () => {
                    if(this.checkNextQuestionExist()){
                        await this.fetchNextSequenceQuestion(nextLinkQuestionId);
                    } else {
                        await this.sequenceComplete();
                    }
                })
            } else {
                problemSetCorrectAnswers = problemSetCorrectAnswers < IN_A_ROW_CORRECT_COUNT ? problemSetCorrectAnswers : 0;
                problemSetQuestion = this.checkAndSkipProblemSetQuestion(problemSetQuestion, problemset_questions);
                this.setState({
                    insideQuestions: false,
                    viewProblemSetQuestion: problemSetQuestion,
                    problemSetCorrectAnswers: problemSetCorrectAnswers,
                    answersGiven: [],
                    getAnswersValue: '',
                    free_response_answer_value: ''
                }, async () => {
                    startAnimation();
                    if(problemSetCorrectAnswers === 0){
                        await resetUserProblemSetProgress({ user_id: account.id, reference_type: 'HomeProblemSetQuestions', reference_id: problemSetId })
                    }
                    await getAnswers({ params: { question_id: problemSetQuestion.id } })
                    endAnimation();
                })
            }
        }
    }

    fetchNextSequenceQuestion = async (id) => {
        const { questions, getAnswers, startAnimation, endAnimation  } = this.props;
        const { time_spent_after_answer_for_sequence, seconds } = this.state;
        const nextQuestion = questions.filter((question) => question.id === id)[0]
        let questions_complete = this.state.questions_complete + 1
        this.setCurrentQuestion(nextQuestion);
        this.setState({
                viewQuestion: nextQuestion, answersGiven: [], questions_complete,
                getAnswersValue: '', showExplanation: false, free_response_answer_value: '',
                seconds: 0,
                time_spent_after_answer_for_sequence: time_spent_after_answer_for_sequence + seconds,
                view_full_explanation: false
            },
            async () => {
                if (nextQuestion) {
                    await startAnimation();
                    await getAnswers({ params: { question_id: nextQuestion.id } });
                    await endAnimation();
                }
            });
    }

    nextSequenceQuestion = async (id) => {
        const { props, state } = this;
        const { questions, getAnswers, startAnimation, endAnimation, saveUserProgress, saveUserQuestionsProgress } = props;
        const { time_spent_after_answer_for_sequence, seconds } = state;
        const nextQuestion = questions.filter((question) => question.id === id)[0]
        let questions_complete = this.state.questions_complete + 1
        this.setCurrentQuestion(nextQuestion);
        this.setState({
            viewQuestion: nextQuestion, answersGiven: [], questions_complete,
            getAnswersValue: '', showExplanation: false, free_response_answer_value: '',
            seconds: 0,
            time_spent_after_answer_for_sequence: time_spent_after_answer_for_sequence + seconds,
            view_full_explanation: false
        },
            async () => {
                if (nextQuestion) {
                    // save user progress      
                    // used a unary plus to convert a string to a number in percent_complete                
                    let {userProgress, userQuestionParams} = this.nextQuestionParams({nextQuestion, questions_complete, state});
                    await startAnimation();
                    await saveUserProgress(userProgress);
                    await saveUserQuestionsProgress(userQuestionParams);
                    await getAnswers({ params: { question_id: nextQuestion.id } });
                    await endAnimation();
                }
            })

    }

    fetchNextProblemSetQuestion = async (problemSetId) => {
        const { account, startAnimation, getUserProblemSetProgress, getProblemSetQuestions, endAnimation, saveUserQuestionsProgress, topicType, topicId } = this.props
        const { seconds, viewQuestion, getAnswersValue, answersGiven, free_response_answer_value } = this.state
        let userQuestionParams = {
            id: account?.userQuestionProgress?._id,
            user_id: account.id,
            question_id: viewQuestion.id,
            reference_type: topicType,
            reference_id: topicId,
            time_spent_after_answer: seconds,
            is_correct: getAnswersValue ? getAnswersValue : false,
            given_answer_ids: viewQuestion.is_free_response ? [] : answersGiven,
            given_free_response_answer: viewQuestion.is_free_response ? free_response_answer_value : '',
            view_full_explanation: false
        }
        this.setState({ showExplanation: false, problemSetCorrectAnswers: 0, seconds: 0, time_spent_before_answer_for_problemset: 0, time_spent_after_answer_for_problemset: 0, skipProblemSetIfComplete: false, problemSetId }, async () => {
            await startAnimation();
            await getUserProblemSetProgress({ reference_id: problemSetId, reference_type: 'HomeProblemSetQuestions', user_id: account.id })
            await getProblemSetQuestions({ problem_set_id: problemSetId, position: 'asc' })
            await saveUserQuestionsProgress(userQuestionParams);
            await endAnimation();
        })
    }

    checkAnswer = (answer) => {
        const { answers } = this.props
        const { insideQuestions, viewQuestion, viewProblemSetQuestion } = this.state
        let allAnswers = answers[insideQuestions? viewQuestion.id : viewProblemSetQuestion.id],
            answersGiven = this.state.answersGiven, filteredArr;

        if (answersGiven.length === 0) {
            answersGiven.push(answer);
            filteredArr = answersGiven;
        } else {
            let checkValue = answersGiven.includes(answer);
            if (checkValue) {
                filteredArr = answersGiven.filter(item => item !== answer);
            } else {
                answersGiven.push(answer);
                filteredArr = answersGiven;
            }
        }

        const allCorrect = allAnswers.filter((val) => val.correct === true)
        const allCorrectIds = _.map(allCorrect, (d) => d.id)
        const givenAnswerIds = _.map(filteredArr, (d) => d.id)
        let getCorrect = (allCorrectIds.length === givenAnswerIds.length) ?
            _.isEqual(_.sortBy(allCorrectIds), _.sortBy(givenAnswerIds)) : false;

        this.setState({ answersGiven: filteredArr, getAnswersValue: filteredArr.length === 0 ? '' : getCorrect })
    }

    nextProblemSetQuestion = async (nextProblemSetQuestionData) => {
        const { nextLinkQuestionId } = this.state;
        const {
            getAnswers, startAnimation, endAnimation, saveUserProblemSetProgress, saveUserQuestionsProgress,
            completeAssignment, problemset_questions
        } = this.props

        // Always save user problem set progress
        // used a unary plus to convert a string to a number in percent_complete
        let problemSetQuestion = this.checkAndSkipProblemSetQuestion(nextProblemSetQuestionData, problemset_questions);
        const  {
            userProblemSetProgress, userQuestionParams, totalCorrect
        } = this.nextProblemSetQuestionParams(problemSetQuestion);
        await startAnimation();
        await saveUserProblemSetProgress(userProblemSetProgress);
        await saveUserQuestionsProgress(userQuestionParams);
        await endAnimation();
        if (totalCorrect >= IN_A_ROW_CORRECT_COUNT) {
            this.setState({
                viewProblemSetQuestion: '',
                getAnswersValue: '',
                insideQuestions: true,
                problemSetCorrectAnswers: this.props.account?.problemsetProgress?.total_correct ?? 0,
                showExplanation: false,
                free_response_answer_value: '',
                view_full_explanation: false
            }, async () => {
                await startAnimation();
                await completeAssignment({type: 'problemset', problem_set_id: problemSetQuestion.problem_set_id});
                await this.fetchNextSequenceQuestion(nextLinkQuestionId)
                await endAnimation();
            })
        } else {
            this.setState({
                viewProblemSetQuestion: problemSetQuestion,
                problemSetCorrectAnswers: totalCorrect,
                answersGiven: [],
                getAnswersValue: '',
                showExplanation: false,
                free_response_answer_value: '',
                seconds: 0,
                time_spent_after_answer_for_problemset: userProblemSetProgress.time_spent_after_answer,
                view_full_explanation: false
            }, async () => {
                if (problemSetQuestion) {
                    await startAnimation();
                    await getAnswers({ params: { question_id: problemSetQuestion.id } })
                    await endAnimation();
                }
            })
        }

    }

    sequenceComplete = async () => {
        const { saveUserProgress, startAnimation, endAnimation, topicType, topicId, account, questions } = this.props
        const userProgress = {
            user_id: account.id,
            reference_type: topicType,
            reference_id: topicId,
            question_id: null,
            total_questions: questions.length,
            questions_complete: questions.length,
            started: false,
            percent_complete: 100
        };
        await startAnimation();
        await saveUserProgress(userProgress);
        await endAnimation();
        await this.redirectAfterComplete();
    }

    finalSubmit = async () => {
        const {
            saveUserProgress, startAnimation, endAnimation, saveUserQuestionsProgress
        } = this.props;
        const {userProgress,userQuestionParams } = this.finalSubmitParams();
        await startAnimation();
        await saveUserProgress(userProgress);
        await saveUserQuestionsProgress(userQuestionParams);
        await endAnimation();
        await this.redirectAfterComplete();
    }

    redirectAfterComplete = async () =>{
        const { startAnimation, endAnimation, location, completeAssignment, topicId } = this.props;
        const queryString = new URLSearchParams(location.search);
        const dashboard = !!(queryString && queryString.get('dashboard'));
        await startAnimation();
        if ((location.pathname).includes('/home/math')) {
            await completeAssignment({type: "home", subtopic_id: topicId})
        } else if ((location.pathname).includes('/home/grammar')) {
            await completeAssignment({type: "grammar", subtopic_id: topicId})
        } else if ((location.pathname).includes('/home/reading')) {
            await completeAssignment({type: "reading", subtopic_id: topicId})
        }
        await endAnimation();
        if (dashboard) {
            await this.flashMessage({message: "Sequence Assignment Completed",type: "success"});
            return this.props.history.push({pathname: '/home', params: { assignment: true, completeAssignment: true}});
        }
        await this.flashMessage({message: "Sequence Completed",type: "success"});
        let appendString = '';
        if (queryString?.get('topic')) {
            appendString = "?topic=" + queryString.get('topic')
        }

        if ((location.pathname).includes('/home/math')) {
            this.props.history.push('/home/math' + appendString)
        } else if ((location.pathname).includes('/home/grammar')) {
            this.props.history.push('/home/grammar' + appendString)
        } else if ((location.pathname).includes('/home/reading')) {
            this.props.history.push('/home/reading' + appendString)
        }

    }

    showExplanationAndSaveProblemSetProgress = async (nextProblemSetQuestionData) => {
        const { startAnimation, endAnimation, saveUserProblemSetProgress, saveUserQuestionsProgress } = this.props;
        const {
            userProblemSetProgress: {time_spent_before_answer}, userProblemSetProgress, userQuestionParams
        } = this.problemSetProgressParams(nextProblemSetQuestionData);
        await startAnimation();
        await saveUserProblemSetProgress(userProblemSetProgress)
        await saveUserQuestionsProgress(userQuestionParams);
        await endAnimation();
        this.setState({ showExplanation: true, seconds: 0, time_spent_before_answer_for_problemset: time_spent_before_answer });
        this.scrollToCheckButton();
        this.alertAnswerStatus();
    }

    showExplanationAndSaveQuestionProgress = async () => {
        const { startAnimation, endAnimation, saveUserProgress, saveUserQuestionsProgress } = this.props;
        const {
            userProgress, userQuestionParams, nextLinkQuestionId, totalCorrect
        } = this.saveQuestionProgressParams();
        await startAnimation();
        await saveUserProgress(userProgress);
        await saveUserQuestionsProgress(userQuestionParams);
        await endAnimation();
        this.setState({ showExplanation: true, nextLinkQuestionId, sequenceCorrectAnswers: totalCorrect, seconds: 0, time_spent_before_answer_for_sequence: userProgress.time_spent_before_answer })
        this.scrollToCheckButton();
        this.alertAnswerStatus();
    }

    checkFreeResponseAnswer = (e, freeResponseAnswers) => {
        if (e.target.value) {
            let val = e.target.value, getCorrect = false, breakCheck = false,
                sortedFreeResponseAnswers = _.orderBy(freeResponseAnswers, ['is_exact_match'], ['desc']);
            sortedFreeResponseAnswers.forEach(answer => {
                if (breakCheck === false) {
                    let ans = checkCorrectOnFreeResponseAnswers(val, answer);
                    if (ans) {
                        getCorrect = ans;
                        breakCheck = true;
                    }
                }
            })
            this.setState({ free_response_answer_value: e.target.value, getAnswersValue: getCorrect });
        } else {
            this.setState({ free_response_answer_value: '', getAnswersValue: '' });
        }
    }

    checkNextQuestionExist = () =>{
        const { nextLinkQuestionId } = this.state;
        const { questions } = this.props;
        return !!(questions.filter((question) => question.id === nextLinkQuestionId)[0]);
    }

    nextProblemSetQuestionExist = () => {
        const { getAnswersValue, problemSetCorrectAnswers } = this.state;
        let totalCorrect = getAnswersValue ? problemSetCorrectAnswers + 1 : 0;
        return (totalCorrect >= IN_A_ROW_CORRECT_COUNT);
    }

    problemSetFinalSubmit = async (nextProblemSetQuestionData) => {
        const { startAnimation, endAnimation, saveUserProblemSetProgress, saveUserQuestionsProgress, completeAssignment} = this.props;
        const {
            userQuestionParams, userProblemSetProgress
        } =  this.nextProblemSetQuestionParams(nextProblemSetQuestionData);
        await startAnimation();
        await completeAssignment({type: 'problemset', problem_set_id: nextProblemSetQuestionData.problem_set_id});
        await saveUserProblemSetProgress(userProblemSetProgress);
        await saveUserQuestionsProgress(userQuestionParams);
        await endAnimation()
        return this.finalSubmit();
    }

    nextQuestionParams = ({questions_complete, nextQuestion, state =  this.state}) =>{
        const { questions, topicType, topicId, account } = this.props;
        const { time_spent_after_answer_for_sequence, seconds, getAnswersValue, answersGiven, free_response_answer_value, viewQuestion } = state;
        let userProgress = {
            user_id: account.id,
            reference_type: topicType,
            reference_id: topicId,
            question_id: nextQuestion.id,
            total_questions: questions.length,
            questions_complete,
            started: questions_complete > 0,
            percent_complete: + ((questions_complete / questions.length) * 100).toFixed(2),
            time_spent_after_answer: time_spent_after_answer_for_sequence + seconds
        }

        let userQuestionParams = {
            id: account.userQuestionProgress && account.userQuestionProgress._id,
            user_id: account.id,
            question_id: viewQuestion.id,
            reference_type: topicType,
            reference_id: topicId,
            time_spent_after_answer: seconds,
            is_correct: getAnswersValue ? getAnswersValue : false,
            given_answer_ids: viewQuestion.is_free_response ? [] : answersGiven,
            given_free_response_answer: viewQuestion.is_free_response ? free_response_answer_value : ''
        }
        return({userProgress, userQuestionParams})
    }

    nextProblemSetQuestionParams = (problemSetQuestion) =>{
        const { getAnswersValue, problemSetCorrectAnswers, time_spent_after_answer_for_problemset, seconds, viewProblemSetQuestion, answersGiven, free_response_answer_value } = this.state;
        const { problemset_questions, topicId, account } = this.props;

        let totalCorrect = getAnswersValue ? problemSetCorrectAnswers + 1 : 0,
            questions_complete = this.questionsComplete();

        let userProblemSetProgress = {
            user_id: account.id,
            reference_type: 'HomeProblemSetQuestions',
            reference_id: problemSetQuestion.problem_set_id,
            question_id: problemSetQuestion.id,
            total_questions: problemset_questions.length,
            questions_complete,
            started: questions_complete > 0,
            percent_complete: + ((questions_complete / problemset_questions.length) * 100).toFixed(2),
            total_correct: totalCorrect,
            subtopic_id: topicId,
            time_spent_after_answer: time_spent_after_answer_for_problemset + seconds
        };

        const userQuestionParams = {
            id: account?.userQuestionProgress?._id,
            user_id: account.id,
            question_id: viewProblemSetQuestion.id,
            reference_type: "HomeProblemSetQuestions",
            reference_id: problemSetQuestion.problem_set_id,
            time_spent_after_answer: seconds,
            is_correct: getAnswersValue ? getAnswersValue : false,
            given_answer_ids: viewProblemSetQuestion.is_free_response ? [] : answersGiven,
            given_free_response_answer: viewProblemSetQuestion.is_free_response ? free_response_answer_value : ''
        };

        return({userProblemSetProgress, userQuestionParams, totalCorrect});
    }

    finalSubmitParams = () => {
        const { topicType, topicId, account, questions } = this.props
        const { time_spent_after_answer_for_sequence, seconds, viewQuestion, getAnswersValue, answersGiven, free_response_answer_value } = this.state

        const userProgress = {
            user_id: account.id,
            reference_type: topicType,
            reference_id: topicId,
            question_id: null,
            total_questions: questions.length,
            questions_complete: questions.length,
            started: false,
            percent_complete: 100,
            time_spent_after_answer: time_spent_after_answer_for_sequence + seconds
        };

        const userQuestionParams = {
            id: account?.userQuestionProgress?._id,
            user_id: account.id,
            question_id: viewQuestion.id,
            reference_type: topicType,
            reference_id: topicId,
            time_spent_after_answer: seconds,
            is_correct: getAnswersValue ? getAnswersValue : false,
            given_answer_ids: viewQuestion.is_free_response ? [] : answersGiven,
            given_free_response_answer: viewQuestion.is_free_response ? free_response_answer_value : ''
        }
        return({userQuestionParams, userProgress});
    }

    problemSetProgressParams = (nextProblemSetQuestionData) => {
        const { getAnswersValue, problemSetCorrectAnswers, time_spent_before_answer_for_problemset, seconds, viewProblemSetQuestion, answersGiven, free_response_answer_value } = this.state
        const { problemset_questions, topicId, account } = this.props
        let totalCorrect = getAnswersValue ? problemSetCorrectAnswers + 1 : 0,
            questions_complete = this.questionsComplete(1);
        let userProblemSetProgress = {
            user_id: account.id,
            reference_type: 'HomeProblemSetQuestions',
            reference_id: nextProblemSetQuestionData.problem_set_id,
            question_id: nextProblemSetQuestionData.id,
            total_questions: problemset_questions.length,
            questions_complete,
            started: questions_complete > 0,
            percent_complete: + ((questions_complete / problemset_questions.length) * 100).toFixed(2),
            total_correct: totalCorrect,
            sat_question_id: topicId,
            time_spent_before_answer: time_spent_before_answer_for_problemset + seconds
        };

        const userQuestionParams = {
            user_id: account.id,
            question_id: viewProblemSetQuestion.id,
            reference_type: "HomeProblemSetQuestions",
            reference_id: nextProblemSetQuestionData.problem_set_id,
            time_spent_before_answer: seconds,
            is_correct: getAnswersValue ? getAnswersValue : false,
            given_answer_ids: viewProblemSetQuestion.is_free_response ? [] : answersGiven,
            given_free_response_answer: viewProblemSetQuestion.is_free_response ? free_response_answer_value : ''
        };

        return({userProblemSetProgress,userQuestionParams})
    }

    saveQuestionProgressParams = () =>{
        const { questions, topicType, topicId, account } = this.props;
        const {
            viewQuestion, getAnswersValue, sequenceCorrectAnswers, time_spent_before_answer_for_sequence,
            seconds, answersGiven, free_response_answer_value
        } = this.state;

        let totalCorrect = getAnswersValue ? sequenceCorrectAnswers + 1 : sequenceCorrectAnswers,  nextQuestion = '',
            problemSetId = '', nextLinkQuestionId = '', questions_complete = this.state.questions_complete;

        if(getAnswersValue === true){
            if (viewQuestion?.link_right_question_id) {
                nextQuestion = questions.filter((question) => question.id === viewQuestion.link_right_question_id)[0]
                nextLinkQuestionId = viewQuestion.link_right_question_id;
            }
            problemSetId = viewQuestion?.link_right_problemset_id ?? '';
        } else if(getAnswersValue === false){
            if (viewQuestion?.link_wrong_question_id) {
                nextQuestion = questions.filter((question) => question.id === viewQuestion.link_wrong_question_id)[0]
                nextLinkQuestionId = viewQuestion.link_wrong_question_id;
            }
            problemSetId = viewQuestion?.link_wrong_problemset_id ?? '';
        }

        if (problemSetId === ''){
            questions_complete += 1;
        }

        const userProgress = {
            user_id: account.id,
            reference_type: topicType,
            reference_id: topicId,
            question_id: nextQuestion?.id ?? null,
            total_questions: questions.length,
            questions_complete,
            started: questions_complete > 0,
            percent_complete: + ((questions_complete / questions.length) * 100).toFixed(2),
            total_correct: totalCorrect,
            next_problemset_id: problemSetId,
            time_spent_before_answer: time_spent_before_answer_for_sequence + seconds
        }
        const userQuestionParams = {
            user_id: account.id,
            question_id: viewQuestion.id,
            reference_type: topicType,
            reference_id: topicId,
            time_spent_before_answer: seconds,
            is_correct: getAnswersValue ? getAnswersValue : false,
            given_answer_ids: viewQuestion.is_free_response ? [] : answersGiven,
            given_free_response_answer: viewQuestion.is_free_response ? free_response_answer_value : ''
        }
        return({nextLinkQuestionId,totalCorrect, userQuestionParams, userProgress});
    }

    render() {
        const { answers, loading, problemset_questions } = this.props
        const {
            viewQuestion, answersGiven, getAnswersValue, insideQuestions, viewProblemSetQuestion,
            showExplanation, free_response_answer_value, view_full_explanation
        } = this.state

        if (insideQuestions && viewQuestion) {
            let freeResponseAnswers = getFreeResponseAnswers({answers: answers,question: viewQuestion});
            return (
                <div className={`view-question--inner ${loading ? 'loading-topics' : ''}`}>
                    <IfView visible={viewQuestion}>
                        <ReadOnlyEditor viewAble={true} content={viewQuestion.question} />
                    </IfView>
                    <IfView visible={showExplanation && viewQuestion.exp_position_before_answer}>
                        <ViewFullExplanation
                            question_exp={viewQuestion.question_exp}
                            full_explanation={view_full_explanation}
                            onClick={() => this.handle_view_full_explanation()}
                        />
                    </IfView>

                    <div className="view-question--ans">
                        <IfView visible={viewQuestion.is_free_response}>
                            <FreeResponseWithExplanation answers={freeResponseAnswers}
                                showExplanation={showExplanation}
                                answersValue={getAnswersValue}
                                value={free_response_answer_value}
                                onChange={(e) => this.checkFreeResponseAnswer(e, freeResponseAnswers)} />
                        </IfView>

                        <QuestionView
                            setQuestion={viewQuestion}
                            answers={answers}
                            showExplanation={showExplanation}
                            answersGiven={answersGiven}
                            checkAnswer={this.checkAnswer}
                        />

                    </div>
                    <IfView visible={showExplanation && !viewQuestion.exp_position_before_answer}>
                        <ViewFullExplanation
                            question_exp={viewQuestion.question_exp}
                            full_explanation={view_full_explanation}
                            onClick={() => this.handle_view_full_explanation()}
                        />
                    </IfView>

                    <div className="mt-4 d-flex justify-content-center w-100 position-relative">
                        {/* <button onClick={() => alert('Work in progress!!')} className="btn btn-secondary mr-4">Previous</button> */}
                        <IfElseView condition={showExplanation}>
                            <div /* If Part */>
                                <IfElseView condition={viewQuestion && getAnswersValue === ''}>
                                    <div /* If Part */>
                                        <button disabled={true} className="btn btn-primary btn-lg my-3">
                                            {viewQuestion.link_right_question_id === null && viewQuestion.link_right_problemset_id === null
                                            && viewQuestion.link_wrong_question_id === null && viewQuestion.link_wrong_problemset_id === null
                                                ? 'Submit' : 'Next'}
                                        </button>
                                    </div>
                                    <div /* Else Part */>
                                        <IfElseView condition={viewQuestion && getAnswersValue === true}>
                                            <div /* If Part */>
                                                <IfElseView condition={viewQuestion && viewQuestion.link_right_problemset_id}>
                                                    <div /* If Part */>
                                                        <button onClick={() => this.fetchNextProblemSetQuestion(viewQuestion.link_right_problemset_id)}
                                                                className="btn btn-primary btn-lg my-3">Next</button>
                                                    </div>
                                                    <div /* Else Part */>
                                                        <IfElseView condition={viewQuestion && viewQuestion.link_right_question_id}>
                                                            <div /* If Part */>
                                                                <button onClick={() => this.nextSequenceQuestion(viewQuestion.link_right_question_id)}
                                                                        className="btn btn-primary btn-lg my-3">Next</button>
                                                            </div>
                                                            <div /* Else Part */>
                                                                <button onClick={() => this.finalSubmit()}
                                                                        className="btn btn-primary btn-lg my-3">Submit</button>
                                                            </div>
                                                        </IfElseView>
                                                    </div>
                                                </IfElseView>
                                            </div>
                                            <div /* Else Part */>
                                                <IfElseView condition={viewQuestion && viewQuestion.link_wrong_problemset_id}>
                                                    <div /* If Part */>
                                                        <button onClick={() => this.fetchNextProblemSetQuestion(viewQuestion.link_wrong_problemset_id)}
                                                                className="btn btn-primary btn-lg my-3">Next</button>
                                                    </div>
                                                    <div /* Else Part */>
                                                        <IfElseView condition={viewQuestion && viewQuestion.link_wrong_question_id}>
                                                            <div /* If Part */>
                                                                <button onClick={() => this.nextSequenceQuestion(viewQuestion.link_wrong_question_id)}
                                                                        className="btn btn-primary btn-lg my-3">Next</button>
                                                            </div>
                                                            <div /* Else Part */>
                                                                <button onClick={() => this.finalSubmit()}
                                                                        className="btn btn-primary btn-lg my-3">Submit</button>
                                                            </div>
                                                        </IfElseView>
                                                    </div>
                                                </IfElseView>
                                            </div>
                                        </IfElseView>
                                    </div>
                                </IfElseView>
                            </div>
                            <div /* Else Part */>
                                <button
                                    disabled={getAnswersValue === ''}
                                    onClick={() => this.showExplanationAndSaveQuestionProgress()} className="btn btn-primary btn-lg my-3"
                                    id="check_button">Check</button>
                            </div>
                        </IfElseView>
                    </div>
                </div>
            );
        } else if(!insideQuestions && viewProblemSetQuestion) {
            let nextProblemSetQuestionData = problemset_questions.length > 0 ? _.find(problemset_questions, ['position', viewProblemSetQuestion.position + 1]) : ''

            let freeResponseAnswers = getFreeResponseAnswers({answers: answers,question: viewProblemSetQuestion});
            return (
                <div className={`view-question--inner ${loading ? 'loading-topics' : ''}`}>
                    <IfView visible={viewProblemSetQuestion}>
                        <ReadOnlyEditor viewAble={true} content={viewProblemSetQuestion.question} />
                    </IfView>
                    <IfView visible={showExplanation && viewProblemSetQuestion.exp_position_before_answer}>
                        <ViewFullExplanation
                            question_exp={viewProblemSetQuestion.question_exp}
                            full_explanation={view_full_explanation}
                            onClick={() => this.handle_view_full_explanation()}
                        />
                    </IfView>

                    <div className="view-question--ans">
                        <IfView visible={viewProblemSetQuestion.is_free_response}>
                            <FreeResponseWithExplanation answers={freeResponseAnswers}
                                showExplanation={showExplanation}
                                answersValue={getAnswersValue}
                                value={free_response_answer_value}
                                onChange={(e) => this.checkFreeResponseAnswer(e, freeResponseAnswers)} />
                        </IfView>

                        <QuestionView
                            setQuestion={viewProblemSetQuestion}
                            answers={answers}
                            showExplanation={showExplanation}
                            answersGiven={answersGiven}
                            checkAnswer={this.checkAnswer}
                        />

                    </div>
                    <IfView visible={showExplanation && !viewProblemSetQuestion.exp_position_before_answer}>
                        <ViewFullExplanation
                            question_exp={viewProblemSetQuestion.question_exp}
                            full_explanation={view_full_explanation}
                            onClick={() => this.handle_view_full_explanation()}
                        />
                    </IfView>

                    <div className="mt-4 d-flex justify-content-center w-100 position-relative">
                        <IfElseView condition={showExplanation}>
                            <div /*If View*/>
                                <IfElseView condition={answersGiven.length > 0 || free_response_answer_value !== ''}>
                                    <div /*If View*/>
                                        <IfElseView condition={this.nextProblemSetQuestionExist() && !(this.checkNextQuestionExist())}>
                                            <button
                                                onClick={() => this.problemSetFinalSubmit(nextProblemSetQuestionData ? nextProblemSetQuestionData : problemset_questions[0])}
                                                className="btn btn-primary btn-lg my-3">Submit
                                            </button>
                                            <button
                                                onClick={() => this.nextProblemSetQuestion(nextProblemSetQuestionData ? nextProblemSetQuestionData : problemset_questions[0])}
                                                className="btn btn-primary btn-lg my-3">Next
                                            </button>
                                        </IfElseView>
                                    </div>
                                    <div /*Else View*/>
                                        <button disabled={true} className="btn btn-primary btn-lg my-3">Next</button>
                                    </div>
                                </IfElseView>
                            </div>
                            <div /*Else View*/>
                                <button disabled={getAnswersValue === ''}
                                        onClick={() => this.showExplanationAndSaveProblemSetProgress(nextProblemSetQuestionData ? nextProblemSetQuestionData : problemset_questions[0])}
                                        className="btn btn-primary btn-lg my-3" id="check_button">Check
                                </button>
                            </div>
                        </IfElseView>
                    </div>
                </div>
            );
        }

        return (<div className={"view-question--inner"}/>)
    }
}

const mapStateToProps = state => {
    return ({
        answers: state.answers,
        loading: state.loading,
        account: state.account,
        currentQuestion: state.temporaryData.currentQuestion
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        startAnimation: () => dispatch(animationActions.start()),
        endAnimation: () => dispatch(animationActions.end()),
        getAnswers: (params) => dispatch(answerActions.getAllAnswer(params)),
        saveUserProgress: (params) => dispatch(userProgressActions.saveUserProgress(params)),
        resetUserProgress: (params) => dispatch(userProgressActions.resetUserProgress(params)),
        getUserProblemSetProgress: (params) => dispatch(userProgressActions.getUserProblemSetProgress(params)),
        saveUserProblemSetProgress: (params) => dispatch(userProgressActions.saveUserProblemSetProgress(params)),
        resetUserProblemSetProgress: (params) => dispatch(userProgressActions.resetUserProblemSetProgress(params)),
        saveUserQuestionsProgress: (params) => dispatch(accountActions.saveUserQuestionsProgress('/user_questions/save_user_questions_progress', params)),
        alertPush: (payload) => dispatch(alertActions.alertPush(payload)),
        alertDelete: () => dispatch(alertActions.alertDelete()),
        setCurrentQuestion: (payload)=> dispatch(commonRecords.setCurrentQuestion(payload)),
        clearCurrentQuestion: ()=> dispatch(commonRecords.clearCurrentQuestion()),
        completeAssignment: (params) => dispatch(assignmentActions.completeAssignment(params))
    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewSubtopicQuestions));
