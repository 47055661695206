import ReactTooltip from "react-portal-tooltip";
import React from "react";
import ToolTipReadOnlyEditor from "./ToolTipReadOnlyEditor";
import "./tooltipDecorator.scss";

let ClassObjectObjectHash = {};
function findToolTipEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges(
        (character) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === 'TOOLTIP'
            );
        },
        callback
    );
}

class ToolTipComponent extends React.Component {
    constructor(props) {
        super(props);
        let uniqId = (Math.random() + 1).toString(36).substring(3);
        this.uniqId = uniqId;
        this.state = {
            show: false, uniqId,
            containerRect: {top: 0, right: 0, bottom: 0, left: 0},
            arrowPosition: "top"
        }
        ClassObjectObjectHash[uniqId] = this;
        this.containerRef = React.createRef();
        this.getRectsInterval = undefined;
        this.toggleTooltip = this.toggleTooltip.bind(this);
    }

    toggleTooltip() {
        const {show} = this.state;
        if(show === false){
            const allObjects = Object.entries(ClassObjectObjectHash)
            allObjects.map(([key,object]) => {
                if(this.uniqId !== key) {
                    object.setState({show: false});
                    object.stopFetchCoordinate()
                }
            });
            this.startFetchCoordinate();
        } else {
            this.stopFetchCoordinate();
        }
        this.setState({show: !show})
    }

    startFetchCoordinate = () =>{
        this.getRectsInterval = setInterval(() => {
            try {
                const { top, right, bottom, left } = this.containerRef.current.getBoundingClientRect();
                if(JSON.stringify({ top, right, bottom, left }) !== JSON.stringify(this.state.containerRect)){
                    if (top > 300) {
                        this.setState({arrowPosition: 'top', containerRect: {top, right, bottom, left}})
                    } else {
                        this.setState({arrowPosition: 'bottom', containerRect: {top, right, bottom, left}})
                    }
                }
            } catch (e) {

            }
        }, 10);
    }

    stopFetchCoordinate = () =>{
        clearInterval(this.getRectsInterval);
    }

    componentWillUnmount() {
        this.stopFetchCoordinate();
        delete ClassObjectObjectHash[this.uniqId];
    }

    render() {
        const { props } = this;
        const { uniqId, show, arrowPosition } = this.state;
        const {tooltip} = props.contentState.getEntity(props.entityKey).getData();
        return(
            <>
                {/* eslint-disable-next-line no-script-url */}
                <a  id={`TOOLTIP${uniqId}`}
                    className="tooltip-tag"
                    onClick={this.toggleTooltip}
                    ref={this.containerRef}>
                      {props.children}
                </a>
                {
                    show ?
                        <ReactTooltip className="border-radius-10 tooltip-z-index" active={show} position={arrowPosition} arrow="center"
                                      parent={`#TOOLTIP${uniqId}`} tooltipTimeout={0}>
                            <div className={"right-align"}>
                                <button onClick={() => this.setState({show: false})}>X</button>
                            </div>
                            <div className="tooltip-main-div">
                                <ToolTipReadOnlyEditor
                                    viewAble={true}
                                    content={tooltip}
                                />
                            </div>
                        </ReactTooltip>
                        :
                        ""
                }
            </>
        )
    }
}

export const tipDecorator = [{
    strategy: findToolTipEntities,
    component: ToolTipComponent,
}];